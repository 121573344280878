import React from 'react'
import styled from 'styled-components'
import PlaysOutline from '../../../../../components/Images/PlayOutlineIcon'
import { numFormatter } from '../../../../../lib/utils'
import Calendar from '../../../../../components/Images/CalendarOutlineIcon'
import Clock from '../../../../../components/Images/Clock'
import { Box, Text} from '../../../../../designSystem'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  colorMapperForVoaStatus,
  getTimeFromSeconds,
  mapForUItoBEStatus,
} from '../../../../../lib/utils'

dayjs.extend(customParseFormat)

const SNO = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--Text500);
  @media (max-width:600px){
    width: unset;
    height: unset;
    color: var(--Text300);
    font-size: 10px;
    line-height: 12px;
    padding: 0;
    margin-bottom: 8px;
    font-weight: 500;
  }
`
const HandleContainer = styled(SNO)`
  display: none;
  padding-left: 0px;
`

const Container = styled.div`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--Dove);
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
  &:hover ${SNO} {
    display: none;
  }

  &:hover ${HandleContainer} {
    display: flex;
  }
  @media (max-width:600px){
    flex-direction: column;
    align-items: unset;
    width : 100vw;
    padding: 12px 16px;
  }
`
const EpisodeName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 6px;
`
const EpisodeInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  display: flex;
  align-items: center;
`
const Thumbnail = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  margin-left: 16px;
`
const ThumbnailDiv = styled.div`
  width: 8%;
`
const Details = styled.div`
`
const PlaysIcon = styled(PlaysOutline)`
  margin-right: 4px;
`
const ClockIcon = styled(Clock)`
  margin-right: 4px;
`
const InfoSpan = styled.span`
  // margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${({ golden }) => golden ? 'var(--Corn800)' : 'var(--Text500)'};
`
const CalendarIcon = styled(Calendar)`
  margin-right: 4px;
  fill: ${({ golden }) => golden ? 'var(--Corn800)' : '#0D1536'};
`
const Status = styled.div`
  min-width: 30%;
  text-align: right;
  margin-left: auto;
  @media (max-width:600px){
    text-align: left;
    margin-left: unset;
    margin-top: 5px;
  }
`
const StatusTag = styled.div`
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  padding: 6px 12px;
  display: inline-block;
`
const SelectedStatusTag = styled(Status)`
  border-radius: 24px;
  padding: 6px 12px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  background-color: ${({ bgColor }) => bgColor || 'rgba(242,198,2,0.1)'};
  color: ${({ color }) => color || 'rgba(242,198,2,0.1)'};
  border: ${({ borderColor }) =>
    `solid 1px ${borderColor}` || 'solid 1px rgba(242,198,2,0.2)'};
`

function SingleEpisode({
  dragHandle,
  handleOnClick,
  data: {
    duration,
    audio_available,
    natural_sequence_number,
    story_title,
    chapter_details: { chapter_title, episode_tag, audio_status } = {},
  } = {},
}) {
  const isMobile = window.screen.width < 600
  return (
    <Container onClick={handleOnClick}>
      <SNO>
        {isMobile && 'Episode '}{natural_sequence_number}
      </SNO>
      {!isMobile && <HandleContainer onClick={e => e.stopPropagation()}>
        {dragHandle()}
      </HandleContainer>}
      <Box variant="flex" flexDirection="column" justifyContent="flex-start" ml={[7,0]}>
        <Text variant="titleSecondary">
          {story_title || chapter_title || ''}
        </Text>
        {duration && <Box variant="flex" mt={5} justifyContent="flex-start">
          {duration && <Text variant="caption" color="var(--Text500)">{getTimeFromSeconds(duration)}</Text>}
        </Box>}
      </Box>
      {!episode_tag && !audio_available && (
        <Status>
          <StatusTag>Audio yet to be uploaded</StatusTag>
        </Status>
      )}
      {!episode_tag && audio_available && audio_status && (
        <Status>
          <SelectedStatusTag {...colorMapperForVoaStatus(audio_status)}>
            {mapForUItoBEStatus[audio_status] || audio_status}
          </SelectedStatusTag>
        </Status>
      )}
      {episode_tag && !audio_available && (
        <Status>
          <StatusTag>
            {episode_tag === 'under_review' && 'Under Review'}
            {episode_tag === 'script_updated' && 'Audio yet to be uploaded'}
          </StatusTag>
        </Status>
      )}
      {episode_tag && audio_available && (
        <Status>
          <StatusTag>
            {episode_tag === 'under_review' && 'Under Review'}
            {episode_tag === 'script_updated' && 'Script Updated'}
          </StatusTag>
        </Status>
      )}
    </Container>
  )
}

export default SingleEpisode
