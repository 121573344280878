import React from 'react'
import styled from 'styled-components'
import PlaysOutline from '../../../../../components/Images/PlayOutlineIcon'
import Calendar from '../../../../../components/Images/CalendarOutlineIcon'
import { Box, Text, Button, Image} from '../../../../../designSystem'
import Clock from '../../../../../components/Images/Clock'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  numFormatter,
  getTimeFromSeconds,
  mapForUItoBEStatus,
  colorMapperForVoaStatus,
} from '../../../../../lib/utils'

dayjs.extend(customParseFormat)

const SNO = styled.div`
  // width: 5%;
  // padding: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--Text500);
  // padding-left: 8px;
  @media (max-width:600px){
    width: unset;
    height: unset;
    color: var(--Text300);
    font-size: 10px;
    line-height: 12px;
    padding: 0;
    margin-bottom: 8px;
    font-weight: 500;
  }
`
const HandleContainer = styled(SNO)`
  display: none;
  padding-left: 0px;
`
const Container = styled.div`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--Dove);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  justify-content: flex-start;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
  &:hover ${SNO} {
    display: none;
  }
 
  &:hover ${HandleContainer} {
    display: flex;
  }
  @media (max-width:600px){
    flex-direction: column;
    align-items: unset;
    width : 100vw;
    padding: 12px 16px;
  }
`
const EpisodeName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 6px;
`
const EpisodeInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  display: flex;
  align-items: center;
`
const Thumbnail = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
`
const ThumbnailDiv = styled.div`
  width: 8%;
`
const Details = styled.div`
`
const PlaysIcon = styled(PlaysOutline)`
  margin-right: 4px;
`
const ClockIcon = styled(Clock)`
  margin-right: 4px;
`
const InfoSpan = styled.span`
  // margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${({ golden }) => golden ? 'var(--Corn800)' : 'var(--Text500)'};
`
const CalendarIcon = styled(Calendar)`
  margin-right: 4px;
  fill: ${({ golden }) => golden ? 'var(--Corn800)' : '#0D1536'};
`
const Status = styled.div`
  min-width: 30%;
  text-align: right;
  margin-left: auto;
  @media (max-width:600px){
    text-align: left;
    margin-left: unset;
    margin-top: 8px;
  }
`
const StatusTag = styled.div`
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  padding: 6px 12px;
  display: inline-block;
  color: ${({ styleObj={} }) => styleObj.color || 'var(--Corn800)'};
  border: ${({ styleObj={} }) => styleObj.border || 'solid 1px rgba(242,198,2,0.2)'};
  background-color: ${({ styleObj={} }) => styleObj.bgColor || 'rgba(242,198,2,0.1)'};
`
const Dot = styled.span`
  font-size: 28px;
  line-height: 20px;
  font-weight: 500;
  padding: 0 8px;
`
const AudioAvailableEpisodeTagStatusDisplayMapping = {
  'under_review': {
    statusText: "Under Review",
    color:'var(--Corn800)',
    bgColor: 'rgba(242,198,2,0.1)',
    border: 'solid 1px rgba(242,198,2,0.2)'
  },
  approved_by_editor: {
    statusText: 'Approved by Editor',
    color: 'var(--Corn800)',
    bgColor: 'rgba(242,198,2,0.1)',
    border: 'solid 1px rgba(242,198,2,0.2)',
  },
  approved_by_editor: {
    statusText: 'Approved by Editor',
    color: 'var(--Corn800)',
    bgColor: 'rgba(242,198,2,0.1)',
    border: 'solid 1px rgba(242,198,2,0.2)',
  },
  'issues to be resolved': {
    statusText: "Issues To Be Resolved",
    color:'var(--Punch800)',
    bgColor: 'rgba(221, 54, 35, 0.1)',
    border: 'solid 1px rgba(221, 54, 35, 0.2)'
  },
  'resolved': {
    statusText: "Resolved",
    color:'var(--Azure800)',
    bgColor: 'rgba(6, 138, 226, 0.1)',
    border: 'solid 1px rgba(6, 138, 226, 0.2)'
  },
  to_be_localized: {
    statusText: 'To Be Localized',
    color: 'var(--crimson)',
    borderColor: 'rgba(247, 115, 123, 0.2)',
    bgColor: 'rgba(247, 115, 123, 0.1)',
  },
  script_updated: {
    statusText: 'Audio yet to be uploaded',
    color: 'var(--Corn800)',
    bgColor: 'rgba(242,198,2,0.1)',
    border: 'solid 1px rgba(242,198,2,0.2)'
  },
}

const AudioNotAvailableEpisodeTagStatusDisplayMapping = {
  'under_review': {
    statusText: "Under Review",
    color:'var(--Corn800)',
    bgColor: 'rgba(242,198,2,0.1)',
    border: 'solid 1px rgba(242,198,2,0.2)'
  },
  'issues to be resolved': {
    statusText: "Issues To Be Resolved",
    color:'var(--Punch800)',
    bgColor: 'rgba(221, 54, 35, 0.1)',
    border: 'solid 1px rgba(221, 54, 35, 0.2)'
  },
  'resolved': {
    statusText: "Resolved",
    color:'var(--Azure800)',
    bgColor: 'rgba(6, 138, 226, 0.1)',
    border: 'solid 1px rgba(6, 138, 226, 0.2)'
  },
  "script_updated": {
    statusText: "Script Updated",
    color:'var(--Lime800)',
    bgColor: 'rgba(121,186,16,0.1)',
    border: 'solid 1px rgba(121,186,16,0.2)'
  },
}

const SingleEpisode = ({ handleOnClick, dragHandle, moderation_required, data: { duration, schedule_date, audio_available, stats: {total_plays} = {}, natural_sequence_number, image_url, thumbnail, story_title, chapter_details: {chapter_title, episode_tag, audio_status} = {} } = {} }) => {
  const parsedScheduled = dayjs(schedule_date, 'DD MMM YYYY, hh:mm A')
  const current = dayjs()
  const hasScheduledDatePassed = parsedScheduled.isBefore(current)
  const date = schedule_date ? schedule_date.split(',')[0].trim() : ''
  const time = schedule_date ? schedule_date.split(',')[1].trim() : ''
  const isMobile = window.screen.width < 600
  return (
    <Container onClick={handleOnClick} >
      <SNO>
        {isMobile && 'Episode '} {natural_sequence_number}
      </SNO>
      {!isMobile && <HandleContainer onClick={e => e.stopPropagation()}>
        {dragHandle()}
      </HandleContainer>}
      <Box variant="flex" flexDirection="column" justifyContent="flex-start" ml={[7,0]}>
        <Text variant="titleSecondary">
          {story_title || chapter_title || ''}
        </Text>
        {(total_plays || date || duration) && (<Box variant="flex" mt={5} justifyContent="flex-start">
          {/* {!!total_plays && <PlaysIcon />} */}
          {!!total_plays && <Text variant="caption" color='var(--Text500)'>{numFormatter(total_plays)}</Text>}
          {!!total_plays && date && <Dot> &middot; </Dot>}
          {hasScheduledDatePassed && date && <Text variant="caption" color="var(--Text500)">{date}</Text>}
          {!hasScheduledDatePassed && date && (<><Text variant="caption" color="var(--Corn800)" golden={1} /><InfoSpan golden={1} >Scheduled for {date} at {time}</InfoSpan></>)}
          {(!!total_plays || date) && duration && <Dot> &middot; </Dot>}
          {/* {duration && <ClockIcon/>} */}
          {duration && <Text variant="caption" color="var(--Text500)">{getTimeFromSeconds(duration)}</Text>}
        </Box>)}
      </Box>
      {!episode_tag && !audio_available && (
        <Status>
          <StatusTag>Audio yet to be uploaded</StatusTag>
        </Status>
      )}
      {!episode_tag &&
        audio_available &&
        audio_status !== 'approved' &&
        !!audio_status && (
          <Status>
            <StatusTag styleObj={colorMapperForVoaStatus(audio_status)}>
              {mapForUItoBEStatus[audio_status] || audio_status}
            </StatusTag>
          </Status>
        )}
      {episode_tag && !audio_available && (
        <Status>
          <StatusTag
            styleObj={
              AudioAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {}
            }
          >
            {
              (AudioAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {})
                .statusText
            }
          </StatusTag>
        </Status>
      )}
      {episode_tag && audio_available && (
        <Status>
          <StatusTag
            styleObj={AudioAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {}}
          >
            {(AudioAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {})['statusText']}
          </StatusTag>
        </Status>)}
      {episode_tag && audio_available && 
      <Status>
        <StatusTag
          styleObj={AudioNotAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {}}
        >
          {(AudioNotAvailableEpisodeTagStatusDisplayMapping[episode_tag] || {})['statusText']}
        </StatusTag>
      </Status>}
    </Container>
  )
}

export default SingleEpisode
