import React from 'react'
import PrivatePage from '../../components/PrivatePage'
import SubHeader from '../../components/WithSubHeaderBG'
import styled from 'styled-components'

const Container = styled.div`
  padding: 48px;
  background-color: var(--Dove);
  margin-bottom: 96px;
`
const Heading = styled.h1`
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--Text500);
`
const NList = styled.ol`
  margin-left: 24px;
`
const UList = styled.ul`
  margin-left: 24px;
`
const LI = styled.li`
  font-size: 16px;
  line-height: 2.15;
  text-align: left;
  color: var(--Text500);
`
const ExampleBlock = styled.div`
  margin: 20px 0;
`
const Text = styled.p`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 16px;
`
const SubHeading = styled.h2`
  margin: 24px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
`


const Terms = ({ dismissTerms }) => {
  const isMobile = window.screen.width < 768
  return (
      <SubHeader showBackButton padding={isMobile ? "0" : "336"} onClickBackButton={dismissTerms}>
        <Container>
          <Heading>TERMS OF USE</Heading>
          <Text>This is an electronic record under the Information Technology Act, 2000 and rules there under. Therefore, no signature is required to make the Terms of Use binding on the User. These Terms of Use along with the [Privacy Policy] have been formulated as required under Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011.</Text>
          <Text>
          These Terms of Use (“Terms”) govern your use of our website/platform located at studio.pocketfm.in, also referred to as ‘Pocket Studio’ (the “Platform”) made available by Pocket FM Pvt. Ltd. (“Pocket FM”, “Company”, “we”, “us” and “our”), a private limited company established under the laws of India and the owner of the mobile app ‘Pocket FM’ and the website at www.pocketfm.in (the “Other Platforms”) that have been integrated with the Platform.
          </Text>
          <Text>These Terms are a legal agreement between You (defined below) and Pocket FM stating the terms that govern your use of the Platform (defined below). If you do not agree to these Terms, you are not entitled to use the Services (defined below) and any use thereafter shall be unauthorized. This electronic record is generated by a computer system and does not require any physical or digital signatures.</Text>
          <Text>
          The term “Writer” refers to anyone who willingly joins the Platform for creating Published Work (defined below). The terms “User”, “you” and “your” refer to the user of the Platform, and unless mentioned otherwise, includes a Writer.
          </Text>
          <Text>
          The Company facilitates a User to read and/or upload original literary works in different languages (“Published Work”), read/listen to Published Work and other literary/audio works published by Company (“Company Content”), in various languages all either on the Platform or through the Other Platforms of the Company (“Services”). Published Work and Company Content shall together be referred to as “Content”.
          </Text>
          <Text>The Company also facilitates certain writing programs and provides any Writer an opportunity to earn a certain sum of money (“Reward”) along with additional opportunities to convert their Published Work into audiobooks, based on the condition that such Published Work has been approved and signed with the Company (“Approved Published Works”). If a Published Work meets all the criteria of being an Approved Published Work as per the Company’s requirements, a team from Pocket Studio will contact the Writer to discuss the details further at their contact details mentioned in their Pocket Studio account.</Text>
          <Text>
          By browsing through the Platform and availing the Services, You agree to be bound by these Terms read along with the Privacy Policy and represent that You are above 18 years of age and/or have the authority to enter into a binding contract with Company. If You are under 18 years of age, You must obtain consent from Your parent(s) or legal guardian(s) who will be responsible for your acceptance and compliance of these Terms.  If You do not have consent from Your parent(s) or legal guardian(s), You must stop using/accessing the Platform immediately.
          </Text>
          <SubHeading>I - &nbsp;&nbsp; USER OBLIGATIONS</SubHeading>
          <Text>By using the Services, User agrees to abide by the below obligations: </Text>
          <NList>
            <LI><strong>Accuracy:</strong> To provide complete and accurate information while registering on the Platform and to update the details / contact Company if there is any change in such information. To link pen names with the User accounts, if any, while submitting Published Works. Further, User must not impersonate any other person.</LI>
            <LI><strong>Confidentiality:</strong> To maintain confidentiality of the User’s account details and be responsible for any use of the Services through the User’s account.</LI>
            <LI><strong>Ownership:</strong> To ensure that the Published Works uploaded are authored and fully owned by the User, are original works i.e., never published earlier via any medium in any part of the world, and are not plagiarized works, i.e., do not violate any third-party intellectual property rights, including copyrights, privacy rights and publicity rights.</LI>
            <LI><strong>Trademark and Design:</strong> To not use, misuse or misappropriate the Company-owned trademark ‘Pocket FM’ and/or ’Pocket Studio’ or any logo or design of the Company for any unauthorized purpose.</LI>
            <LI><strong>Content Guidelines:</strong> To ensure the Published Works / Approved Published Works do not violate the conditions mentioned in ‘Content Guidelines’ below.</LI>
            <LI><strong>Reproduction:</strong> To not reproduce any Published Works/ Approved Published Works of another User from the Platform and publish it in any other platform without authority, whether for commercial gain or otherwise.</LI>
            <LI>
              Permissions: To grant Company:
              <NList>
                <LI>permission to publicly display User’s name/username/pen names to attribute the Published Works uploaded on the Platform;</LI>
                <LI>a worldwide and non-exclusive and royalty-free right and license for the Company to use, distribute, disseminate, transmit the Published Works and its derivatives through the Platform; and</LI>
                <LI>right to showcase any of your Published Works to a third party for the purposes of any potential business collaboration solely with the Company without prior intimation to you.</LI>
              </NList>
            </LI>
            <LI><strong>Illegal Activities:</strong> Not use the Services to perform any illegal activities nor solicit the performance of any illegal or any activity which leads to violation of third party’s rights.</LI>
            <LI><strong>Virus:</strong> To not upload any material which contains software viruses, or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource used to provide the Services.</LI>
            <LI><strong>Advertise:</strong> To not advertise or solicit any products or services commercially or otherwise on the Platform.</LI>
            <LI><strong>Security:</strong> a) Not probe, scan or test the vulnerability of the Platform b) not disrupt or breach the security or authentication measures or circumvent the navigational structure, with respect to the Platform or the network c) Not use any manual or automated software, devices or other processes to "crawl" or "spider" any part of the Platform d) Not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software to modify or interfere with the Services or in any manner to gain undue advantage from the Services or features e) Not place an unreasonable burden on the Company’s infrastructure.</LI>
            <LI><strong>Unfair Conduct:</strong> Not engage in any unfair conduct such as using unauthorized means to gain rewards offered on the Platform or engage in any other fraudulent/wrongful activity on the Platform.</LI>
            <LI><strong>Access:</strong> To access and use the Services solely for the User’s / Writer’s personal purposes and to not access the Platform or obtain the Published Works through any other means other than as permitted.</LI>
            <LI><strong>User Data:</strong> Not trace any information pertaining to another User or exploit any such information, including storing and collecting the same.</LI>
          </NList>
          <Text>In short, be a good citizen; interact, connect, and treat others with respect; participate on the Platform fairly; keep your contributions relevant; and use your account correctly!</Text>
          <SubHeading>II -&nbsp;&nbsp; CONTENT GUIDELINES</SubHeading>
          <Text>In addition to the above User Obligations, the following are the additional guidelines to be followed by the Writers with respect to their Published Works. All Published Works uploaded on the Platform must follow the following Content guidelines:</Text>
          <NList>
            <LI><strong>Not be objectionable or unlawful:</strong> Do not put-up Published Works which are grossly harmful, misrepresenting, infringing, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, breaches another's privacy or publicity rights, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever.</LI>
            <LI><strong>Not be against national interest:</strong> Do not put-up Published Works that can threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</LI>
            <LI><strong>Terrorism:</strong> Do not put-up Published Works which promotes, encourages, glorifies, or incites acts of international or domestic terrorism, or which supports or celebrates terrorist organisations, their leaders or associated violent activities, or which is designed to motivate individuals to join terrorist organisations.</LI>
            <LI><strong>Protect minors:</strong> Do not put-up Published Works which can harm minors in any way. Do not put content in the Published Works that sexualises minors or facilitates or promotes child sexual abuse in any way. The possession, distribution and solicitation of child sexual abuse material is a serious crime under the applicable law.</LI>
            <LI><strong>Not be misleading/offensive:</strong> Do not put-up Published Works which deceives or defrauds or misleads a reader about the origin of the Content or communicates any information which is grossly offensive or menacing in nature.</LI>
            <LI><strong>Suicide and self-harm:</strong> Do not put content in the Published Works that promotes or glorifies suicide or self-harm. This includes content that encourages others to harm themselves (e.g., posting content that promotes an eating disorder).</LI>
            <LI><strong>Gore and mutilation:</strong> Do not put content in the Published Works that depicts or promotes severe bodily injury, torture or mutilation of humans or animals.</LI>
            <LI><strong>Illegal drugs:</strong> Do not put content in the Published Works that depict illegal drug use and descriptions of how to manufacture or procure illegal drugs.</LI>
          </NList>
          <Text>
          If any Published Works including any Approved Published Works is reported to be violative of any of the aforesaid Content Guidelines and if the Company is in agreement with the complaint made against the Published Works, the same shall be removed / taken down from the Platform without assigning any reasons or notice to the Writer, and the Company may follow-up their actions with a strong warning to the Writer. A repeat violation of the said Content Guidelines by the same Writer may result in termination of the Writer’s account from the Platform. All the conditions mentioned in this section are applicable for the Approved Published Works also.
          </Text>
          <Text>
          The above Content Guidelines together with the User Guidelines apply to your use of the Platform and will help you and the rest of the community to have an enjoyable experience at the Platform. As a User, you play an important part in keeping our community safe for the other Users. We greatly appreciate your help in ensuring that our Platform remain positive and welcoming.
          </Text>
          <SubHeading>III -&nbsp;&nbsp; RIGHTS OF THE COMPANY</SubHeading>
          <Text>
          User acknowledges the following rights of the Company:
          </Text>
          <NList>
            <LI><strong>Content Removal:</strong> Company has the right to remove any Published Works/ Approved Published Works which it believes is infringing or violates Content Guidelines, as per its discretion and without assigning any reasons to the Writer or as required under the applicable laws.</LI>
            <LI><strong>Temporary Suspension or Termination:</strong> Company has the right to restrict/suspend/terminate the account of any User to access all or part of the Services, at its discretion, including for violation by the User of these Terms. User is responsible for any activity that occurs through their account. We can terminate a User account or place it on hold to protect you, Company or its partners from identity theft or other fraudulent activity.</LI>
            <LI><strong>Intellectual Property Rights over Names and Marks:</strong> Company maintains and asserts intellectual property ownership and rights over the brand logos, trademarks, brand names, service marks, domain names, and trade dress created by and developed by the Company on the Platform or Other Platforms.</LI>
            <LI><strong>Company Content:</strong> The copyright in the Company Content either belongs to the Company or its business partners, including licensors or assignors. No rights therein transfer to the Users/ Writers except for the purpose of legitimate use of the Service in accordance with these Terms.</LI>
            <LI><strong>Data from Users / Writers:</strong> Company collects, uses, stores and processes the personal data of the Users / Writers in accordance with the Privacy Policy.</LI>
            <LI><strong>Legal disclosure:</strong> Company may disclose the details of any User or any other details regarding the Published Works/ Approved Published Works or take any other action as required under law or a lawful order by government agencies who are authorized to investigate any cyber security incidents.</LI>
            <LI><strong>Security measures:</strong> Company puts in place enhanced security and technical measures from time to time to prevent and tackle violation of copyrights of Users / Writers or third parties, as suitable.</LI>
          </NList>
          <SubHeading>IV -&nbsp;&nbsp; COMPANY - AN INTERMEDIARY</SubHeading>
          <NList>
            <LI><strong>Users are in control of Published Works:</strong> Company through its Platform receives, stores and transmits Published Works created by Writers solely for the benefits of the Users. Users remain the sole authors and owners of their Published Works. Further, Company does not control or restrict the publishing or reading of the Published Works nor modifies the same prior to upload of the same on the Platform.</LI>
            <LI><strong>Company an ‘intermediary’ and no liability:</strong> Company is an ‘intermediary’ as defined under the Information Technology Act, 2000 and its Rules and is not responsible for the Published Works uploaded on the Platform.</LI>
            <LI><strong>Company does not monitor all Published Works subjectively:</strong> Company also does not track and monitor the content of the Published Works. Only when a Published Work is objectively vetted by Company’s automated software applications, Company engages its team of editors / senior writers to subjectively analyse the Published Work merely from the perspective of qualifying criteria of the Company to become Approved Published Work. </LI>
            <LI><strong>Duty to act under the applicable law:</strong> Company, as an intermediary, has the duty to take necessary action against any Published Works/Approved Published Works under the Information Technology Act, 2000 and its Rules in the event the Published Work / Approved Published Work is infringing any third-party intellectual property right, defamatory, misrepresentative, or violative of User Obligations or the Content Guidelines mentioned above, AND the same is brought to the Company’s notice by a formal written communication. The User shall abide by such actions taken by the Company. </LI>
          </NList>
          <SubHeading>V -&nbsp;&nbsp; LIABILITY</SubHeading>
          <NList>
            <LI><strong>No warranty of any kind:</strong> All Services and Published Works offered on the Platform are offered on an "as is" basis without any warranty whatsoever, either express or implied. The Company/Platform does not implicitly or explicitly support or endorse any of Published Works, third-party content or service on the Platform. The Company/Platform does not warrant that the functions and Services contained in the Platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks involved with the User's use of the Platform. To the fullest extent permitted by the applicable law, the Company disclaims any and all warranties express or implied about the Services. </LI>
            <LI><strong>User liable for breach:</strong> You are solely responsible to the Company and to any third party for any breach of User obligations under the Terms and for the consequences (including any loss or damage which the Company or its affiliates or its Users may suffer for any such breach).</LI>
            <LI><strong>Indemnity:</strong> User expressly agrees to indemnify and hold harmless the Company/Platform, for its use of any Published Works/Inputs provided to it or published on the Platform by the User. The Company reserves its right to defend itself in any such legal disputes that may arise, and recover the costs incurred in such proceedings from the User.</LI>
            <LI><strong>No Indirect Liability:</strong> Company disclaims any and all indirect, special, incidental, consequential or punitive damages, losses (suffered due to for any lost profits, lost revenues, lost business opportunities, diminution in value, including any alleged loss or diminution in value of personal information), costs towards any User or third party arising from the provision of Services to User or use of the Platform by others.</LI>
            <LI><strong>Company’s Limitation of Liability for Approved Published Works:</strong> The Company disclaims all liabilities in relation to the accuracy, originality, and authorship of Approved Published Works.</LI>
            <LI><strong>Class Action Waiver:</strong> WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, where permitted under the applicable law, unless both you and Company agree otherwise, the court may not consolidate more than one person's claims with your claims and may not otherwise preside over any form of a representative or class proceeding.</LI>
          </NList>
          <SubHeading>VI -&nbsp;&nbsp; GRIEVANCE OFFICER</SubHeading>
          <NList>
            <LI>If any User is affected by any Published Works/ Approved Published Works which violates these Term of Use, including User Obligations or the Content Guidelines, the User can write their concerns to the Company’s Grievance Officer using their own registered account.  Company will endeavour to resolve the concerns within thirty (15) working days from the date of the receipt of the concerns by the Grievance Officer.</LI>
            <LI>If any person becomes aware of any Published Works / Approved Published Works which violate these Terms, such person can contact the Company’s Grievance Officer with the following details:
              <UList>
                <LI>
                The complainant’s name and contact details such as address, telephone number and valid email address.
                </LI>
                <LI>
                Description of the Published Works/ Approved Published Works which are violating the Terms.
                </LI>
                <LI>The nature of complaint against the Content Published Works/ Approved Published Works.</LI>
                <LI>The details of the URL where such Content Published Works/ Approved Published Works are hosted.</LI>
                <LI>Supporting documents/sources, if applicable, to substantiate the complaint.</LI>
                <LI>Complaint document to be duly signed physically or by electronic signature.</LI>
              </UList>
            </LI>
          </NList>
          <SubHeading>VII -&nbsp;&nbsp; MISCELLANEOUS</SubHeading>
          <NList>
            <LI><strong>Modification to the Terms:</strong> The Company reserves the sole and exclusive right to unilaterally amend or modify these Terms and such amendments or modifications shall come into effect immediately. We will notify you of such changes before the same apply to you. If the User continues to use the Platform following such a change, the User will be deemed to have consented to any and all amendments/modifications made to the Terms.</LI>
            <LI><strong>Notice of Take Down:</strong> For doing any formal written communication with the Company’s Grievance Officer, an email could be sent to care@pocketfm.in.</LI>
            <LI><strong>Governing Law and Disputes:</strong> The Users expressly agree that the relationship between Users and the Company are governed by the laws, rules and regulations of India. Notwithstanding the foregoing, you agree that Pocket FM has the right to bring any proceedings before any court/forum of competent jurisdiction and you irrevocably submit to the jurisdiction of such courts or forum.</LI>
            <LI><strong>Conflict:</strong> If there is any inconsistency between the Terms and the terms and conditions of any specific contract separately executed between a User and the Company, the terms of the specific contract shall prevail. In case of any conflict arising in the interpretation of the Terms in English and Terms in any other language as it may be made available in on the Platform, the terms of the English version shall prevail.</LI>
            <LI><strong>Assignment:</strong> Company may assign or transfer this agreement with you including its associated rights and obligations at any time and you agree to cooperate with the Company in connection with such an assignment or transfer.</LI>
            <LI><strong>Survival:</strong> If any provision or provisions of these Terms shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</LI>
          </NList>
        </Container>
      </SubHeader>
  )
}

export default Terms
