import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Upload from '../../components/GenericUpload'
import { post } from '../../lib/api'

import { Box ,Flex } from '../../designSystem'
import CardBoard from './images/CardBoard'
import AssessmentUploadComponent from './AssessmentUploadComponent'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ImgContainer = styled.div`
  width: 25%;
  svg {
    width: 90%;
  }
  @media (max-width: 900px){
    display: none;
  }
`

const btn = css`
  border-radius: 4px;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  color: var(--bg-alt);
  cursor: pointer;
  max-width: fit-content;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  border: none;
  background: linear-gradient(215deg, #0068e1, #b0d5ff);
`
const DownLoadLink = styled.a`
  height: fit-content;
`
const DownloadButton = styled.button`
  ${btn}
  padding: .8rem 3.1rem;
  align-self: flex-end;
  margin: auto;
`
const UploadButton = styled.button`
  ${btn}
  padding: .8rem 8rem;
  align-self: flex-end;
  margin: 2rem;
  font-size: 1.5rem;
  margin-top: 0px;
  margin-left: 1.5rem;
  @media(max-width: 600px){
    padding: .8rem 3rem;
    margin: .5rem;
    margin-bottom: 2rem;
  }
`

const AssessmentDetails = styled.div`
  width: 74%;
  @media(max-width: 600px){
    width: 98%;
    margin: 0px auto;
    padding: 0%;
  }
`
const AssessmentHeading = styled.h3`
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.2;
  max-width: fit-content;
  margin-bottom: 1.6rem;
  background: linear-gradient(215deg, #0068e1, #b0d5ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div {
    width: 50%;
    padding: 2rem;
    @media(max-width: 600px){
      width: 100%;
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

`
const AssessmentInstruction = styled.h4`
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin: 1.6rem auto;
`
const AssessmentInstructionSubHeading = styled.p`
  font-family: Inter;
  font-size: 1rem;
  line-height: 1.2;
  text-align: left;
  color: #000;
`
const UploadHeading = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  width: 98%;
  margin: 0px auto;
`

const LeftContainer = styled.div``
const RightContainer = styled.div`
  padding: 2rem;
  @media(max-width: 600px){
    padding: 1rem;
  }
`

const ErrorMessage = styled.p`
  color: var(--crimson);
  margin: 2rem;
`
const UploadContainer = styled.span` 
  text-align: center;
  width:98%;
  margin: 0px auto;
`
const BtnContainer = styled.div`
   @media(max-width: 600px){
   display: flex;
   margin: 0px;
   padding: 0px;
   justify-content: center;
   }
`
export default function Assessment({ setAssessmentStatus }) {
  const [assesmentUrl, setAssessmentUrl] = useState('')
  const [error, setError] = useState(false)

  const handleFileChange = (url) => {
    setAssessmentUrl(url)
  }
  const handleUpload = async () => {
    if (assesmentUrl.length === 0) {
      return setError(true)
    }
    post(`/user_api/upload_creator_assessment`, {
      data: {
        assessment_doc_url: assesmentUrl,
      },
    }).then((response) => {
      setAssessmentStatus(true)
    })
  }

  return (
    <>
      <Container>
        <ImgContainer>
          <CardBoard />
        </ImgContainer>
        <AssessmentDetails>
          <FlexContainer>
            <LeftContainer>
              <AssessmentHeading>Submit your Assessment to become a Pro Author</AssessmentHeading>
              <AssessmentInstruction>
                Assessment Instructions
              </AssessmentInstruction>
              <AssessmentInstructionSubHeading>
                Download the instruction page and follow the same for the
                assessment.
                </AssessmentInstructionSubHeading>
            </LeftContainer>
            <RightContainer>
              <DownLoadLink
                href={
                  'https://d31b0xt3oaqqjh.cloudfront.net/assesment_doc/a85a6df504209cbbca0f8d72ae8e35cae85d2e36.pdf'
                }
                download="Assessment"
                target="_blank"
              >
                <BtnContainer>
                  <DownloadButton> Download </DownloadButton>
                </BtnContainer>
              </DownLoadLink>
            </RightContainer>
          </FlexContainer>
          <Box mx={[ '.5rem','2rem']}>
            <UploadHeading>Upload your Assessment</UploadHeading>
            <Flex my={'1.5rem'} alignItems="center">
              <UploadContainer width="100%" textAlign={'center'}>
              <Upload
                imageUrl={assesmentUrl}
                name="assesment_doc"
                hideInputOnValue={true}
                showProgress={true}
                placeholder="Select your file"
                subLabel="or drop File here"
                renderComp={AssessmentUploadComponent}
                fileTypeTags="file"
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, application/pdf"
                onChange={handleFileChange}
                hasError={error}
                extraParams={{
                  file_dir: 'assesment_doc',
                  title: 'assesment_doc',
                }}
                cloud_dir_file={'assesment_doc'}
              />
              </UploadContainer>
            </Flex>
          </Box>
          {error && <ErrorMessage>Please upload your assesment first </ErrorMessage>}
          <BtnContainer>
           <UploadButton onClick={handleUpload}> Submit</UploadButton>
          </BtnContainer> 
        </AssessmentDetails>
      </Container>
    </>
  )
}
