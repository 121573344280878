import React from 'react'

const Exclamation = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.6737 22.3339L17.2824 5.10066C16.873 4.42176 16.1262 4 15.3334 4C14.5406 4 13.7938 4.42176 13.3844 5.10071L2.99309 22.3339C2.56999 23.0356 2.55747 23.9147 2.96038 24.6282C3.36339 25.3417 4.1227 25.7849 4.94207 25.7849H25.7247C26.5441 25.7849 27.3034 25.3417 27.7065 24.6282C28.1094 23.9146 28.0968 23.0355 27.6737 22.3339ZM26.2703 23.8172C26.1593 24.0135 25.9503 24.1356 25.7247 24.1356H4.94207C4.71649 24.1356 4.50745 24.0136 4.39656 23.8172C4.28563 23.6208 4.28909 23.3787 4.40552 23.1856L14.7969 5.9524C14.9096 5.76552 15.1152 5.64939 15.3334 5.64939C15.5516 5.64939 15.7572 5.76552 15.8699 5.9524L26.2612 23.1856C26.3777 23.3788 26.3812 23.6208 26.2703 23.8172Z" fill="#F2C602"/>
    <path d="M15.3409 10.786C14.7135 10.786 14.2239 11.1227 14.2239 11.7195C14.2239 13.5404 14.4381 16.1571 14.4381 17.9781C14.4381 18.4524 14.8513 18.6513 15.341 18.6513C15.7082 18.6513 16.2285 18.4524 16.2285 17.9781C16.2285 16.1571 16.4427 13.5405 16.4427 11.7195C16.4427 11.1227 15.9377 10.786 15.3409 10.786Z" fill="#F2C602"/>
    <path d="M15.3563 19.7071C14.683 19.7071 14.178 20.2427 14.178 20.8854C14.178 21.5128 14.683 22.0637 15.3563 22.0637C15.9837 22.0637 16.5193 21.5128 16.5193 20.8854C16.5193 20.2427 15.9836 19.7071 15.3563 19.7071Z" fill="#F2C602"/>
  </svg>
)
export default Exclamation
