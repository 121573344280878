import styled from 'styled-components'
import CrossIcon from '../Images/CrossIcon'
import DownloadWithoutCircle from '../Images/DownloadWithoutCircle'

const BasicDiv = styled.div`
  padding: 24px;
  margin-bottom: 8px;
  background-color: var(--Dove);
  @media (max-width: 600px) {
    width: 100vw;
  }
`
const InvalidDetailsDiv = styled(BasicDiv)`
  color: red;
  margin-bottom: 16px;
`
const BasicDetailsDiv = styled(BasicDiv)`
  position: relative;
`
const ProfileImageDiv = styled.div`
  position: absolute;
  right: 16px;
  top: 7px;
  height: 112px;
  width: 112px;
  border-radius: 50%;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--LD5);

  img {
    height: 112px;
    width: 112px;
    border-radius: 50%;
  }
  .Upload input {
    display: none;
  }

  @media (max-width: 600px) {
    margin-right: 30%;
  }
`
const EditIconDiv = styled.div`
  position: absolute;
  top: -75px;
  right: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: var(--Dove);
  }
`
const SectionHeading = styled.div`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const BankDetailsDiv = styled(BasicDiv)``
const DocumentsDiv = styled(BasicDiv)``
const AdditionalVOADetailsDiv = styled(BasicDiv)``
const AddressDiv = styled(BasicDiv)`
  margin-bottom: 24px;
`

const VoaProfessionalDetailsDiv = styled(BasicDiv)``

const VoaVoiceSampleDetailsDiv = styled(BasicDiv)``

const ActionButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
  @media (max-width: 600px) {
    margin-right: 4px;
  }
`
const SaveButton = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  width: 200px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`
const LabelDiv = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: var(--Text700);
  margin-bottom: 8px;
  margin-top: 8px;
`

const DetailLabel = styled.label`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: var(--storm-text-storm-500);
`

const RequiredField = styled.sup`
  color: red;
  font-size: 14px;
`

const DetailDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const OtherDiv = styled.div`
  display: flex;
  width: 30%;
  margin-top: 16px;
  margin-bottom: 10px;
  input {
    width: 400px;
  }
`

const DetailsContainer = styled.div`
  width: 95%;
  margin: 0px auto;
`

const DetailsHeadingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
`
const DetailsInputContainer = styled.div`
`

const AddMoreMainDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddMore = styled.button`
  padding: 12px 20px;
  border-radius: 4px;
  border: 2px solid var(--azure);
  color: var(--azure);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ;

  &:disabled{
    cursor: not-allowed;
    color: var(--silver-500);
    border-color: var(--silver-300);
  }
`

const VoiceSampleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
`

const StyledCrossIcon = styled(CrossIcon)`
  fill: var(--DarkGrey900);
  height: 22px;
  width: 22px;
  cursor: pointer;
  position: absolute;
  left:-28px ;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 4px;
`

const SampleRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin: 16px auto;
  align-items: center;
`

const DownloadIcon = styled(DownloadWithoutCircle)``

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  svg{
    margin-right: 4px ;
  }
`

export {
  DownloadIcon,
  DownloadLink,
  InvalidDetailsDiv,
  DetailLabel,
  SampleRow,
  StyledCrossIcon,
  VoiceSampleDiv,
  DetailsInputContainer,
  DetailDiv,
  AddMore,
  AddMoreMainDiv,
  BasicDetailsDiv,
  ProfileImageDiv,
  EditIconDiv,
  SectionHeading,
  BankDetailsDiv,
  DocumentsDiv,
  AdditionalVOADetailsDiv,
  AddressDiv,
  VoaProfessionalDetailsDiv,
  ActionButtonBox,
  SaveButton,
  LabelDiv,
  RequiredField,
  OtherDiv,
  VoaVoiceSampleDetailsDiv,
  DetailsContainer,
  DetailsHeadingContainer,
}
