import React, { useState, useEffect,useContext } from "react";
import styled from "styled-components";
import GirlWriterIcon from "./GirlWriter.svg";
import { Formik, Form, FieldArray } from "formik";
import { Box } from "../../designSystem";
import { FloatingInput, Input, CheckboxInput } from "../FormikInputs";
import * as Yup from "yup";
import { get, post } from "../../lib/api";
import { withRouter } from "react-router-dom";
import CrossIcon from "../../components/Images/CrossIcon";
import { saveSession, getTokenAndUid } from "../../lib/utils";
import GenericError from "../GenericError";
import { menuItemObj } from "../../components/PrivatePage";
import { AccountContext } from '../../providers/accountProvider'
const StyledCrossIcon = styled(CrossIcon)`
  fill: var(--DarkGrey900);
  height: 9px;
  width: 9px;
  cursor: pointer;
`;
const MainDiv = styled.div`
  background: linear-gradient(
    106deg,
    var(--storm-text-storm-700) -1%,
    #6d7ab1 108%
  );
`;
const WriterLabelDiv = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${GirlWriterIcon});
  background-position: right;
  background-repeat: no-repeat;
`;
const ProfessionalWriterDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px;
`;
const ProfessionalWriterProgramLabel = styled.label`
  width: 412px;
  height: 96px;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  @media (max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const ApplicationFormLabel = styled.label`
  width: 412px;
  height: 24px;
  font-size: 20px;
  margin: 16px 0 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  @media (max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const WriterDetailDiv = styled.div`
  padding: 0px 48px 48px 48px;
`;
const DetailsTitle = styled.div`
  width: 476px;
  height: 32px;
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--strom-text-strom-700);
  margin-top: 48px;
  @media(max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const DetailLabel = styled.label`
  width: 476px;
  height: 28px;
  margin: 8px 0 16px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: var(--storm-text-storm-500);
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 2px;
  margin: 58px 0 24px;
  background-color: var(--Grey900);
`;
const ContinueButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
`;
const OtherDiv = styled.div`
  display: flex;
  width: 30%;
  margin-top: 16px;
  input {
    width: 400px;
  }
`;
const DetailDiv = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  flex-wrap:wrap;
`;
const PublishContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
const AddMore = styled.div`
  padding: 12px 20px;
  border-radius: 4px;
  border: solid 2px var(--silver-300); 
  color: var(--silver-500);
  font-size: 14px;
  font-weight: bold;
  float: right;
  cursor: pointer;
`;
const BackDiv = styled.div`
  padding: 12px 20px;
  border-radius: 4px;
  border: ${({uid})=>uid?'none;':'solid 2px var(--silver-300);'};
  color: var(--silver-500);
  font-size: 14px;
  font-weight: bold;
  float: right;
  cursor: pointer;
`;
const RequiredField = styled.span`
  color: red;
  font-size: 19px;
`;
const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InvalidDetailsDiv = styled.div`
  color: red;
`;
const OuterDiv=styled.div`
margin:0px 200px;
background: white;
@media (max-width : 900px){
  margin: 0px;
}
`
const OuterMainDiv=styled.div`
background-color: var(--Grey900);
`
function WriterBasicDetails({ history }) {
  const [apiError, setApiError] = useState({
    show: "",
    message: "",
  });
  const { user,onUpdateUser } = useContext(AccountContext)
  const [errorMessage, setErrorMessage] = useState("");
  const [userDetails, setUserDetails] = useState();
  const { uid, token } = getTokenAndUid();
  useEffect(() => {
    if (uid) {
      get("/user_api/user.get_creator_details?is_novel=0").then((res) => {
        setUserDetails(res.result.professional_creator_details);
      });
    } else setUserDetails('{}');
  }, []);
  const addValues = (object, otherKey) => {
    let value = [];
    for (let [key] of Object.entries(object)) {
      if (key !== "other" && key !== otherKey) {
        if (object[key] !== "") {
          value.push(key);
        }
      }
    }
    return value;
  };
  const addLanguages = (object, otherKey) => {
    let value = "";
    for (let [key] of Object.entries(object)) {
      if (key !== "other" && key !== otherKey) {
        if (object[key] !== "") {
          if (value === "") value += key;
          else value += "," + key;
        }
      }
    }
    return value;
  };
  const getObject = (type, value, typeObject, other) => {
    value.forEach((elm) => {
      typeObject[elm] = type + "." + elm;
    });
    if (other !== "") {
      typeObject["other"] = type + "." + "other";
    }
    return typeObject;
  };
  return (
    <>
    <OuterMainDiv>
    <OuterDiv>
      <MainDiv>
        <WriterLabelDiv>
          <ProfessionalWriterDiv>
            <ProfessionalWriterProgramLabel>
              Professional Writer<br></br>
              Program
            </ProfessionalWriterProgramLabel>
            <ApplicationFormLabel>Application Form</ApplicationFormLabel>
          </ProfessionalWriterDiv>
        </WriterLabelDiv>
      </MainDiv>
      <WriterDetailDiv>
        {userDetails && (
          <Formik
            initialValues={{
              writer_association_number:
                userDetails["writer_association_number"] || "",
              year_of_exp: userDetails["years_of_exp"] || "",
              about_you:
                (userDetails &&
                  userDetails.professional_creator_details?.about_you) ||
                "",
              genres: (userDetails!=="{}" &&
                getObject(
                  "genres",
                  userDetails.professional_creator_details.genres,
                  {
                    romantic: "",
                    horror: "",
                    comedy: "",
                    drama: "",
                    fantasy: "",
                    action: "",
                    thriller: "",
                    other: "",
                  },
                  userDetails.professional_creator_details.other_genres
                )) || {
                romantic: "",
                horror: "",
                comedy: "",
                drama: "",
                fantasy: "",
                action: "",
                thriller: "",
                other: "",
              },
              othergenres:
                (userDetails!=='{}' &&
                  userDetails.professional_creator_details.other_genres) ||
                "",
              language: (userDetails!=='{}' &&
                getObject(
                  "language",
                  userDetails.professional_creator_details.languages,
                  {
                    hindi: "",
                    english: "",
                    tamil: "",
                    telegu: "",
                    malayalam: "",
                    kannada:"",
                    other: "",
                  },
                  userDetails.professional_creator_details.other_languages
                )) || {
                hindi: "",
                english: "",
                tamil: "",
                telegu: "",
                malayalam: "",
                other: "",
              },
              otherLanguage:
                (userDetails!=='{}' &&
                  userDetails.professional_creator_details.other_languages) ||
                "",
              workExperience: (userDetails!=='{}' &&
                getObject(
                  "workExperience",
                  userDetails.professional_creator_details.work_exp,
                  {
                    webSeries: "",
                    tvSerials: "",
                    films: "",
                    newsPaperMagazines: "",
                    audioPlatform: "",
                    other: "",
                  },
                  userDetails.professional_creator_details.other_exp
                )) || {
                webSeries: "",
                tvSerials: "",
                films: "",
                newsPaperMagazines: "",
                audioPlatform: "",
                other: "",
              },
              otherExperience:
                (userDetails!=='{}' &&
                  userDetails.professional_creator_details.other_exp) ||
                "",
              onlinePublishedContent: userDetails!=='{}' && userDetails.professional_creator_details.online_published_content ||[
                {
                  title: "",
                  link: "",
                },
              ],
            }}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                account_type: "Author",
                user_id: uid || history.location.info.uid,
                languages: addLanguages(values.language, "otherLanguage"),
                professional_creator: 1,
                writer_association_number: values.writer_association_number,
                years_of_exp: values.year_of_exp,
                professional_creator_details: {
                  online_published_content: values.onlinePublishedContent,
                  genres: addValues(values.genres, "othergenres"),
                  other_genres: values.othergenres,
                  work_exp: addValues(values.workExperience, "otherExperience"),
                  other_exp: values.otherExperience,
                  languages: addValues(values.language, "otherLanguage"),
                  other_languages: values.otherLanguage,
                  about_you: values.about_you,
                },
                device_id : localStorage.getItem('uuid')
              };
              if (
                data.years_of_exp === "" ||
                (data.professional_creator_details.genres.length===0 && data.professional_creator_details.other_genres === "") ||
                (data.professional_creator_details.languages.length===0 && data.professional_creator_details.other_languages === "") ||
                (data.professional_creator_details.work_exp.length===0 && data.professional_creator_details.other_exp === "")
              ) {
                setErrorMessage("Please Enter Required Fields");
                return
              } else setErrorMessage("");
              post("/user_api/user.create_creator_account?is_novel=0", {
                data: data,
                headers: {
                  uid: uid || history.location.info.uid,
                  "access-token": uid || history.location.info.token,
                },
              })
                .then(() => {
                    get('/user_api/user.get_creator_details?is_novel=0').then(res => {
                        onUpdateUser(res.result)
                    })
                  history.location.info &&
                    saveSession({
                      ...history.location.info,
                      name: history.location.info.fullname,
                    });
                  history.push('/home');
                })
                .catch((error) => {
                  //setIsRegisterProcessing(false)
                  console.log(error);
                  setApiError({
                    show: true,
                    message:
                      "Something went wrong while setting up your account.",
                  });
                });
            }}
          >
            {({ values = {}, isSubmitting }) => (
              <Form>
                <GenericError
                  show={apiError.show}
                  onClose={() => setApiError({ ...apiError, show: false })}
                  message={apiError.message}
                />
                <DetailsTitle>Basic Details</DetailsTitle>
                <DetailLabel>help us let you know</DetailLabel>
                <Box
                  variant="flex"
                  flexDirection={["column", "row"]}
                  mt={16}
                  mb={16}
                >
                  <Box width={["100%", "49%"]} mb={'1rem'}>
                    <FloatingInput
                      type="text"
                      name="writer_association_number"
                      label="Referral code (Mobile number)"
                      size="lg"
                    />
                  </Box>
                  <Box width={["100%", "49%"]}>
                    <FloatingInput
                      type="number"
                      name="year_of_exp"
                      label="Experience In Years"
                      size="lg"
                      required
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={["column", "row"]}>
                  <Input
                    type="text"
                    asTextArea
                    name="about_you"
                    placeholder="About You"
                  />
                </Box>
                <DetailsTitle>
                  Genres<RequiredField>*</RequiredField>
                </DetailsTitle>
                <DetailLabel>Which style you write in</DetailLabel>
                <DetailDiv>
                  <CheckboxInput
                    name="genres.romantic"
                    label="Romantic"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="genres.horror"
                    label="Horror"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="genres.comedy"
                    label="Comedy"
                  ></CheckboxInput>
                  <CheckboxInput name="genres.drama" label="Drama"></CheckboxInput>
                  <CheckboxInput
                    name="genres.fantasy"
                    label="Fantasy"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="genres.action"
                    label="Action"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="genres.thriller"
                    label="Thriller"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="genres.other"
                    label="Other"
                  ></CheckboxInput>
                </DetailDiv>
                {values.genres.other !== "" && (
                  <OtherDiv>
                    <FloatingInput
                      type="text"
                      name="othergenres"
                      label="Other genres"
                      size="lg"
                      border
                    />
                  </OtherDiv>
                )}
                <DetailsTitle>
                  Languages<RequiredField>*</RequiredField>
                </DetailsTitle>
                <DetailLabel>Your language proficiencies</DetailLabel>
                <DetailDiv>
                  <CheckboxInput
                    name="language.hindi"
                    label="Hindi"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="language.english"
                    label="English"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="language.tamil"
                    label="Tamil"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="language.telegu"
                    label="Telegu"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="language.kannada"
                    label="Kannada"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="language.other"
                    label="Other"
                  ></CheckboxInput>
                </DetailDiv>
                {values.language.other !== "" && (
                  <OtherDiv>
                    <FloatingInput
                      type="text"
                      name="otherLanguage"
                      label="Other Languages"
                      size="lg"
                      border
                    />
                  </OtherDiv>
                )}
                <DetailsTitle>
                  Work Experience<RequiredField>*</RequiredField>
                </DetailsTitle>
                <DetailLabel>
                  Your past experience in the industries
                </DetailLabel>
                <DetailDiv>
                  <CheckboxInput
                    name="workExperience.webSeries"
                    label="Web Series"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="workExperience.tvSerials"
                    label="TV Serials"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="workExperience.films"
                    label="Films"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="workExperience.newsPaperMagazines"
                    label="News Paper/Magazines"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="workExperience.audioPlatform"
                    label="Audio Platform"
                  ></CheckboxInput>
                  <CheckboxInput
                    name="workExperience.other"
                    label="Other"
                  ></CheckboxInput>
                </DetailDiv>
                {values.workExperience.other !== "" && (
                  <OtherDiv>
                    <FloatingInput
                      type="text"
                      name="otherExperience"
                      label="Other Experiences"
                      size="lg"
                      border
                    />
                  </OtherDiv>
                )}
                <DetailsTitle>Online Published Content</DetailsTitle>
                <DetailLabel>
                  Share link for your published content online
                </DetailLabel>
                <FieldArray
                  name="onlinePublishedContent"
                  render={(arrayHelpers) => (
                    <>
                      {values.onlinePublishedContent.map((elm, index) => (
                        <>
                          <PublishContentDiv key={index}>
                            {index > 0 && (
                              <StyledCrossIcon
                                onClick={() => arrayHelpers.remove(index)}
                              ></StyledCrossIcon>
                            )}
                            <Box
                              variant="flex"
                              flexDirection={["column", "row"]}
                              mt={16}
                              mb={16}
                            >
                              <Box width={["100%", "49%"]} mb={'1rem'}>
                                <FloatingInput
                                  type="text"
                                  name={`onlinePublishedContent[${index}].title`}
                                  label="Title"
                                  size="lg"
                                />
                              </Box>
                              <Box width={["100%", "49%"]}>
                                <FloatingInput
                                  type="text"
                                  name={`onlinePublishedContent[${index}].link`}
                                  label="Link (optional)"
                                  size="lg"
                                />
                              </Box>
                            </Box>
                          </PublishContentDiv>
                        </>
                      ))}
                      <AddMore
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({ title: "", link: "" })
                        }
                      >
                        Add More
                      </AddMore>
                      {/* <button
                   type="button"
                   onClick={() => arrayHelpers.push({ title: '', link: '' })}
                 >
                   +
                 </button> */}
                    </>
                  )}
                />
                {/*            
              {values.onlinePublishedContent.map(({titleKey,linkKey},index)=>(
                <Box
                variant="flex"
                flexDirection={["column", "row"]}
                mt={16}
                mb={16}
                key={index}
              >
                <Box width={["100%", "49%"]}>
                  <FloatingInput
                    type="text"
                    name={titleKey+index}
                    label="Title"
                    size="lg"
                  />
                </Box>
                <Box width={["100%", "49%"]}>
                  <FloatingInput
                    type="number"
                    name={linkKey+index}
                    label="Link (optional)"
                    size="lg"
                  />
                </Box>
              </Box>
              ))}  */}
                <HorizontalBar></HorizontalBar>
                {errorMessage !== "" && (
                  <InvalidDetailsDiv>{errorMessage}</InvalidDetailsDiv>
                )}
                <FooterDiv>
               <BackDiv uid={uid} onClick={() => history.goBack()}>{!uid &&'Back'}</BackDiv>
                  <ContinueButton type="submit">
                    {uid && 'Edit Application'}
                    {!uid && 'Submit Application'}
                  </ContinueButton>
                </FooterDiv>
              </Form>
            )}
          </Formik>
        )}
      </WriterDetailDiv>
      </OuterDiv>
      </OuterMainDiv>
    </>
  );
}

export default withRouter(WriterBasicDetails);
