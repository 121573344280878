import React, { useEffect, useState } from 'react'
import DropDownSelect from './index'
import { get } from '../../../lib/api'

const DependentDropDown = ({ dependentOn, language, is_novel=0, ...props }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (!dependentOn || !language) return
    get('/content_api/topic.get_child_topics', {
      params: {
        is_novel: is_novel,
        topic_type: dependentOn,
        language,
      },
    }).then((response) => {
      if (response.result.length > 0) setOptions(response.result[0].sub_topics)
      else setOptions([])
    })
  }, [dependentOn, language])

  return <DropDownSelect selectOptions={options} dependentOn={dependentOn} resetKey={language} {...props} />
}
// TODO : make it reusable, check better way to pass dependent variables
export default DependentDropDown
