import React from 'react'

const ReplyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#0D1536"
      fillOpacity=".65"
      d="M17.688 12.11c-.437-1.176-1.062-2.162-1.915-3.002-2.144-2.1-4.954-2.457-6.224-2.52V3.9c0-.315-.25-.567-.562-.567-.125 0-.25.042-.354.126L1.868 9.171c-.23.189-.27.546-.083.798.02.02.041.042.041.063l6.807 5.732c.104.105.27.147.416.126.146-.021.271-.084.375-.21.083-.105.125-.231.125-.357v-2.856c1.311-.336 5.246-.86 7.744 3.906.083.189.27.294.478.294.188 0 .354-.084.458-.252.063-.084.104-.21.104-.336 0-1.47-.208-2.814-.645-3.969zM8.612 7.554c.104.105.25.168.417.168.374 0 3.705-.021 5.995 2.205 1.04 1.028 1.727 2.393 2.04 4.052-1.915-2.31-4.247-2.814-5.891-2.814-.708 0-1.291.084-1.686.168-.417.084-.646.168-.646.168-.229.084-.374.294-.374.525v2.058L3.096 9.591l5.35-4.494v2.058c0 .147.062.294.166.399z"
    />
  </svg>
)

export default ReplyIcon
