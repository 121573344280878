import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorPage from '../ErrorPage'
import { BUGSNAG_KEY } from '../../config'

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  autoDetectErrors: false,
  autoTrackSessions: false,
  onError: function (event) {
    let uid = window.localStorage.getItem('uid') || ''
    let name = window.localStorage.getItem('name') || ''
    event.setUser(uid, '', name)
  }
})


const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)


const ErrorBoundaryWrapper = ({ children, FallbackComponent = ErrorPage }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  )
}

export const notifyBugsnag = (error, info) => {
  if (info) {
    const cb = function (event) {
      event.addMetadata('info', info)
    }
    Bugsnag.addOnError(cb)
    Bugsnag.notify(new Error(error))
    Bugsnag.removeOnError(cb)
    return
  }
  Bugsnag.notify(new Error(error))
}

export default ErrorBoundaryWrapper