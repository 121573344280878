import React from "react";
import SubHeader from "../../../../components/WithSubHeaderBG";
import { Formik, Form } from "formik";
import {
  FloatingInput,
  Input,
  Upload,
  DropDownSelect,
  SearchableInput,
} from "../../../../components/FormikInputs";
import * as Yup from "yup";
import styled from "styled-components";
import { post } from "../../../../lib/api";
import Tooltip from "../../../../components/Tooltip";
import queryString from "query-string";
import { get as getFromLocal } from "../../../../lib/storage";
import { FileUploadComponent } from "../Unpublished/Edit/addEditEpisode";
import { menuItemObj } from "../../../../components/PrivatePage";
import { Box, Text, Button } from "../../../../designSystem";
import {
  FormActionDiv,
} from "../Unpublished/New/styles";
import { getTokenAndUid } from "../../../../lib/utils";
import DependentDropDown from "../../../../components/FormikInputs/DropDownSelect/DropDown";
import InfoIcon from "../../../../components/Images/InfoIcon";

const FileLabelDownloadable = styled.a.attrs((props) => ({
  href: props.href,
  download: props.download,
}))`
border: solid 2px var(--storm-text-storm-500);
width: 300px;
text-align: center;
padding: 11px;
border-radius: 6px;
}
`;
const InfoImage = styled(InfoIcon)`
  width: 24px;
  height: 24px;
`;
const DownloadFileDiv = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: solid 2px var(--Grey900);
  background-color: var(--Grey300);
  display: flex;
  gap: 14px;
`;
const DownloadText = styled.label`
  font-size: 16px;
`;
const getBookTypeObjFromCategory = (id) => {
  if (!id) return {};
  const allTopics = JSON.parse(getFromLocal("allTopics")) || [];
  const selectedTopic =
    allTopics.find(
      (topicObj) => !!topicObj.sub_topics.find((topic) => topic.topic_id === id)
    ) || {};
  return selectedTopic;
};
export const getBookTypeIdFromCategory = (id) => {
  return getBookTypeObjFromCategory(id).topic_id || "";
};
export const getBookTypeNameFromCategory = (id) => {
  return getBookTypeObjFromCategory(id).type || "";
};

const NewConcept = ({
  location: { search },
  history,
  data = {},
  mode,
  _onSubmit,
}) => {
  const qParams = queryString.parse(search);
  const { process } = qParams;
  const createBookMode = process === "createNewConcept";
  const file_url = "https://cms-data-static.s3.ap-southeast-1.amazonaws.com/concept_note.docx";
  const isMobile = window.screen.width < 600;
  return (
    <SubHeader
      title={createBookMode ? "Submit New Audiobook" : "Edit Audiobook"}
      showBackButton
    >
      <Formik
        initialValues={{
          title: data["title"] || "",
          language: data["language"] || "",
          description: data["description"] || "",
          file_url: data["file_url"] || "",
          category: (data["category_details"] || {}).topic_id || "",
          tags:"" 
        }}
        validationSchema={Yup.object({
          title: Yup.string().required("Required"),
          language: Yup.string().required("Required"),
          category: Yup.string().required("Required"),
          file_url: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (_onSubmit) {
            _onSubmit(values, setSubmitting);
            return;
          }
          const formData = values;
          if (mode === "edit") formData.concept_id = data.concept_id;
          let createBookApi = "/content_api/concept_notes/";
          post(createBookApi, { data: formData }).then((response) => {
            setSubmitting(false);
            const { concept_id } = response.result;
            history.push(
              `${menuItemObj.audiobooks}/concept-detail/${concept_id}?process=concept-detail`
            );
          });
        }}
      >
        {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
          <Form autoComplete="off">
            <Box
              variant="flexCard"
              p="16px"
              borderTopLeftRadius="4px"
              borderTopRightRadius="4px"
              flexDirection={["column", "row"]}
            >
              <Text variant="heading">
                {createBookMode ? "Submit New Audiobook" : "Edit Audiobook"}
              </Text>
            </Box>
            <Box variant="hr" />
            <Box p={[16, 24]} mb={32} bg="surface.foreground">
              <Box variant="flex" flexDirection={["column", "row"]}>
                <Box width={["100%"]}>
                  {isMobile && (
                    <Text variant="titleSecondary" my={16}>
                      Audiobook Details
                    </Text>
                  )}
                  <FloatingInput
                    label="Title"
                    name="title"
                    type="text"
                    hasError={submitCount > 0 && errors["title"]}
                    placeholder="Example - Kitni Mohabatt hai - कितनी मोहबत्त है"
                    infoTitle="Give a title to this audiobook"
                  />
                  <Box mt={16}>
                    <DropDownSelect
                      label="Language"
                      name="language"
                      selectByName
                      infoTitle="Add the language of this audiobook"
                      placeholder="Select a Language"
                      hasError={submitCount > 0 && errors["category"]}
                      selectOptions={[
                        { id: 1, name: "hindi" },
                        { id: 2, name: "tamil" },
                        { id: 3, name: "bengali" },
                        { id: 4, name: "malayalam" },
                        { id: 5, name: "telugu" },
                        { id: 6, name: "marathi" },
                        { id: 7, name: "kannada" },
                        { id: 8, name: "english" },
                        { id: 9, name: "spanish" },
                      ]}
                      idKey="id"
                      nameKey="name"
                    />
                  </Box>
                  <Box mt={16}>
                    <DependentDropDown
                      label="Category"
                      name="category"
                      hasError={submitCount > 0 && errors["category"]}
                      dependentOn="audiobook_series"
                      infoTitle="Select the category of this audiobook"
                      placeholder="Choose a Category"
                      idKey="topic_id"
                      nameKey="topic_name"
                      language={values["language"]}
                      tooltip={{
                        title: !values["language"]
                          ? "Please select a Language"
                          : "",
                        placement: "top",
                      }}
                    />
                  </Box>

                  <Box mt={16}>
                  {/* <FloatingInput
                    label="Tags"
                    name="tags"
                    type="text"
                    hasError={submitCount > 0 && errors["tags"]}
                    placeholder="Select Tags"
                  /> */}
                    {/* <SearchableInput
                      label="Tags - optional"
                      name="tags"
                      selectKey="tag_id"
                      nameKey="tag_name"
                      infoTitle="You can select multiple tags"
                      placeholder="Select Tags"
                      apiUrl={
                        values["category"]
                          ? `/content_api/tag.get_topic_tags?topic_id=${values["category"]}`
                          : null
                      }
                      tooltip={{
                        title: !values["category"]
                          ? "Please select a category first"
                          : "",
                        placement: "top",
                      }}
                    /> */}
                  </Box>
                  <Upload
                    label="Concept Doc File"
                    name="file_url"
                    hasError={submitCount > 0 && errors['file_url']}
                    hideInputOnValue={true}
                    showProgress={true}
                    placeholder="Drop your doc file here to upload or click to select"
                    renderComp={FileUploadComponent}
                    fileTypeTags="file"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc"
                    extraParams={{
                      file_dir: "concept",
                      title: "concept",
                    }}
                    cloud_dir_file={"concept"}
                    // uploadedFileMeta={{words: '10,000 words', size: '456 KB'}}
                  />
                  <Box mt={16}>
                    <DownloadFileDiv>
                      <InfoImage></InfoImage>
                      <DownloadText>
                        Make sure to upload your concept in correct format with
                        all the necessary details to incrase the chances of
                        getting accepted.
                      </DownloadText>
                      <FileLabelDownloadable
                        href={file_url}
                        download={file_url}
                      >
                        Download Sample Concept
                      </FileLabelDownloadable>
                    </DownloadFileDiv>
                  </Box>
                </Box>
              </Box>

              <Input
                asTextArea
                infoTitle="Description in language selected is required"
                label={`Description ${
                  values["language"] ? " in " + values["language"] : ""
                }`}
                name="description"
                hasError={submitCount > 0 && errors["description"]}
                placeholder="Write a description regarding this audiobook"
              />
              <Box variant="hr" my={16} />
              <FormActionDiv>
                <Tooltip
                  title={isValid ? "" : "Enter all the details to continue"}
                  arrow
                >
                  <Button
                    width={["100%", "unset"]}
                    type={isSubmitting ? "button" : "submit"}
                    isLoading={isSubmitting}
                    // disabled={
                    //   values["title"] === "" ||
                    //   values["language"] === "" ||
                    //   values["category"] === "" ||
                    //   values["file_url"] === ""
                    // }
                  >
                    Continue
                  </Button>
                </Tooltip>
              </FormActionDiv>
            </Box>
          </Form>
        )}
      </Formik>
    </SubHeader>
  );
};

export default NewConcept;
