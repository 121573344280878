import React, { useState } from "react";
import styled from "styled-components";
import DateTimeSelect from "../../../../../components/DateTimeSelector";
import Upload from "../../../../../components/GenericUpload";
import Loader from "../../../../../components/Loader";
import moment from "moment";
import { post, del } from "../../../../../lib/api";
import DropDownSelect from "../../../../../components/GenericDropDownSelect";
import { checkAspectRatioMatch } from "../../../../../lib/utils";
import { HexInput } from "../../../../../components/FormComponents";
import MessagePopup from "../../../../../components/MessagePopup";
const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  margin-bottom: 8px;
`;
const EditContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
`;
const EditTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-bottom: 16px;
`;
const HR = styled.div`
  height: 1px;
  background-color: #e0e2eb;
  margin-bottom: 24px;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeftSection = styled.div`
  width: 49%;
`;
const RightSection = styled.div`
  // width: 49%;
`;

const InputLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
`;
const Input = styled.input`
  border-radius: 4px;
  border: solid 1px #e0e2eb;
  background-color: #f0f1f5;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  width: 100%;

  &:focus {
    outline: none;
    border: solid 2px var(--Azure500);
    padding: 11px 15px;
    background-color: var(--Dove);
  }
`;
const ImageUploadDiv = styled.div`
  margin-top: 24px;
  width: 484px;
  height: 177px;
  border-radius: 4px;
  box-shadow: inset 0 0 32px 0 rgba(0, 0, 0, 0.08);
  border: dashed 1px var(--Azure500);
  background-color: var(--Grey100);
  display: flex;
  align-items: center;
`;
const ThumbnailPlaceHolder = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text300);
  text-align: center;
  flex: 1;
`;
const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`;
const CardContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
`;
const MainTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`;
const BigTitle = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
`;
const SubTitle = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`;
const ImageDiv = styled.div``;
const Image = styled.img`
  width: 348px;
  height: 128px;
  border-radius: 4px;
`;
const ActionButtons = styled.div`
  background-color: #f6f7f9;
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
`;
const CancelButton = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
  cursor: pointer;
  padding: 16px 40px;
`;
const BannerTimeDisplay = styled.div`
  display: flex;
  margin-top: auto;
`;
const TimeDisplay = styled.div`
  margin-right: 20px;
`;
const TimeLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`;
const TimeValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
`;
const SaveButton = styled.div`
  background-color: ${({ active }) => (active ? "var(--Azure500)" : "#e6e6e6")};
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  padding: 16px 50px;
  cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};
  color: ${({ active }) => (active ? "var(--Dove)" : "var(--Text100)")};
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeftDiv = styled.div`
  width: 25%;
`;
const RightDiv = styled.div`
  width: 70%;
`;
const DeleteDiv = styled.div`
  flex: 5;
`;
const DeleteButton = styled.div`
  color: var(--Punch500);
  padding: 16px 32px;
  border-radius: 4px;
  background-color: rgba(221, 54, 35, 0.1);
  width: 110px;
  font-weight: 600;
  cursor: pointer;
`;

const ImageUploadComponent = ({ isLoading, imageUrl, placeholder }) => (
  <ImageUploadDiv>
    {isLoading && <Loader size="md" />}
    {!isLoading && imageUrl && <Thumbnail src={imageUrl} alt="" />}
    {!isLoading && !imageUrl && placeholder && (
      <ThumbnailPlaceHolder>
        {placeholder || "Click here to upload or drag your file"}
      </ThumbnailPlaceHolder>
    )}
  </ImageUploadDiv>
);

const Card = ({
  onClick,
  data: {
    banner_title,
    contest_type,
    banner_image_url,
    starttime,
    endtime,
  } = {},
}) => {
  return (
    <CardContainer onClick={onClick}>
      <Details>
        <MainTitle>Banner</MainTitle>
        <BigTitle>{banner_title}</BigTitle>
        <SubTitle>Showing at {contest_type}</SubTitle>
        {/* <DateTimeDisplay>Timer ends on {moment(endtime).format('DD MMM YYYY, hh:mm A')}</DateTimeDisplay> */}
        <BannerTimeDisplay>
          <TimeDisplay>
            <TimeLabel>Starts at</TimeLabel>
            <TimeValue>
              {moment(starttime).format("DD MMM YYYY, hh:mm A")}
            </TimeValue>
          </TimeDisplay>
          <TimeDisplay>
            <TimeLabel>Ends at</TimeLabel>
            <TimeValue>
              {moment(endtime).format("DD MMM YYYY, hh:mm A")}
            </TimeValue>
          </TimeDisplay>
        </BannerTimeDisplay>
      </Details>
      <ImageDiv>
        <Image src={banner_image_url} />
      </ImageDiv>
    </CardContainer>
  );
};

const AddEdit = ({ data, cancelCallback, book_id, created_by, refreshCB }) => {
  const [popupDetails, setPopupDetails] = useState({
    show: false,
    confirmWarning: false,
    confirmText: "",
    messageTitle: "",
    messageBody: "",
    selectOptionBeforeSave: false,
    selectOptions: [],
    onConfirm: () => null,
  });
  const [defaultValues, setDefaultValues] = useState({
    banner_title: data["banner_title"] || "",
    language: data["language"] || "",
    contest_type: data["contest_type"] || "",
    banner_hex_color: data["banner_hex_color"] || "",
    banner_image_url: data["banner_image_url"] || "",
    starttime: data["starttime"] || "",
    endtime: data["endtime"] || "",
  });

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.name]: e.target.value });
  };
  const handleHexChange = ({ name, value }) => {
    setDefaultValues({ ...defaultValues, [name]: value });
  };
  const handleDateChange = (date, which) => {
    const varToUpdate = which === "start" ? "starttime" : "endtime";
    setDefaultValues({ ...defaultValues, [varToUpdate]: date });
  };
  const handleFileChange = (imageUrl) => {
    setDefaultValues({ ...defaultValues, banner_image_url: imageUrl });
  };
  const handleCategoryChange = (contest_type) => {
    setDefaultValues({ ...defaultValues, contest_type });
  };
  const checkBannerValidity = () => {
    if (defaultValues.banner_title=== "" || defaultValues.language=== "" || defaultValues.contest_type=== "" || defaultValues.banner_hex_color=== "" || defaultValues.banner_image_url=== "" || defaultValues.starttime=== "" || defaultValues.endtime=== "") return false;
    else return true;
  };
  const bannerDelete = () => {
    del(`/content_api/banners/${data["banner_id"]}/`,{
      data:{
        entity_id:book_id
      }}).then(() => {
      refreshCB();
      cancelCallback();
    });
  };
  const onDeleteButtonClick = () => {
    setPopupDetails({
      show: true,
      confirmWarning: true,
      confirmText: "Delete Banner",
      messageTitle: "Delete this Banner",
      messageBody: "Are you sure you want to Delete this Banner ?",
      selectOptionBeforeSave: false,
      selectOptions: [],
      onConfirm: bannerDelete,
    });
  };
  const handleSubmit = () => {
    const formData = { ...defaultValues };
    if (formData.endtime) {
      formData.endtime = moment(formData.endtime).toISOString();
    }
    if (formData.starttime) {
      formData.starttime = moment(formData.starttime).toISOString();
    }
    if (formData.banner_hex_color && !formData.banner_hex_color.includes("#")) {
      formData.banner_hex_color = "#" + formData.banner_hex_color;
    }
    formData.on_click_url = `pocketfm://open?entity_id=${book_id}&entity_type=book`;
    formData.banner_desc = "This is Smaple Banner secondary title";
    formData.entity_id = book_id;
    formData.on_click_url = `pocketfm://open?entity_type=novel&entity_id=${book_id}`;
    if(data)
     formData.banner_id=data['banner_id'];
    post("/content_api/banners/", {
      data: formData,
    }).then(() => {
      refreshCB();
      cancelCallback();
    });
  };
  const {
    banner_title,
    language,
    contest_type,
    banner_hex_color,
    banner_image_url,
    starttime,
    endtime,
  } = defaultValues;
  return (
    <Container>
      <EditContainer>
        <MessagePopup
          show={popupDetails.show}
          onClose={() => setPopupDetails({ ...popupDetails, show: false })}
          onConfirm={popupDetails.onConfirm}
          confirmWarning={popupDetails.confirmWarning}
          cancelText="Cancel"
          confirmText={popupDetails.confirmText}
          messageTitle={popupDetails.messageTitle}
          takeFeedback={popupDetails.takeFeedback}
          messageBody={popupDetails.messageBody}
          selectOptionBeforeSave={popupDetails.selectOptionBeforeSave}
          selectOptions={popupDetails.selectOptions}
        />
        <EditTitle>Banner</EditTitle>
        <HR />
        <FlexContainer>
          <LeftSection>
            <InputLabel>Banner Title</InputLabel>
            <Input
              name="banner_title"
              placeholder="Set banner title"
              value={banner_title}
              onChange={handleChange}
            />
            <DropDownSelect
              label="Show Banner at"
              name="contest_type"
              placeholder="Click to select"
              selectOptions={[{ id: "novels", name: "Novels" }]}
              idKey="id"
              nameKey="name"
              value={contest_type}
              onChange={handleCategoryChange}
            />
            <DateTimeSelect
              label="Start Time"
              name="starttime"
              id={"some-really-random-id-13446345"}
              val={starttime}
              endTimeValue={endtime}
              onChange={(date) => handleDateChange(date, "start")}
            />
            <DateTimeSelect
              label="End Time"
              name="endtime"
              id={"some-really-random-id-13443434532"}
              val={endtime}
              startTimeValue={starttime}
              onChange={(date) => handleDateChange(date, "end")}
              disabled={starttime===''}
            />
            <FlexDiv>
              <LeftDiv>
                <HexInput
                  name="banner_hex_color"
                  value={banner_hex_color}
                  onChange={handleHexChange}
                />
              </LeftDiv>
              <RightDiv>
                <InputLabel>Language</InputLabel>
                <Input
                  name="language"
                  value={language}
                  placeholder="Set language"
                  onChange={handleChange}
                />
              </RightDiv>
            </FlexDiv>
          </LeftSection>
          <RightSection>
            <Upload
              imageUrl={banner_image_url}
              name="banner_image_url"
              placeholder="Upload Banner Image (996x336)"
              renderComp={ImageUploadComponent}
              dimensionErrorMsg={
                "Please upload an image of specified dimensions"
              }
              fileTypeTags="image"
              extraParams={{ title: book_id || "general" }}
              onChange={handleFileChange}
              imageValidator={(w, h) => checkAspectRatioMatch(w, h, [83, 28])}
            />
          </RightSection>
        </FlexContainer>
      </EditContainer>
      <ActionButtons>
        <DeleteDiv>
          {data.banner_image_url !== "" && (
            <DeleteButton onClick={onDeleteButtonClick}>Delete</DeleteButton>
          )}
        </DeleteDiv>
        <CancelButton onClick={cancelCallback}>Cancel</CancelButton>
        <SaveButton  active={checkBannerValidity()} onClick={handleSubmit}>Save</SaveButton>
      </ActionButtons>
    </Container>
  );
};

const Banner = (props) => {
  const [mode, setMode] = useState("view");
  const handleCancel = () => {
    setMode("view");
  };

  if (props.data.banner_image_url === "" && props.isShowAdd) {
    return <AddEdit {...props} cancelCallback={props.cancelAdd} />;
  }
  if (props.data.banner_image_url === "") {
    return null;
  }
  return mode === "view" ? (
    <Card {...props} onClick={() => setMode("edit")} />
  ) : (
    <AddEdit {...props} cancelCallback={handleCancel} />
  );
};

export default Banner;
