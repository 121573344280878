import React from 'react'
import queryString from 'query-string'
import PrivatePage from '../../../components/PrivatePage'
import List from './List'
import AuditionShowDetails from './Auditions/Submit'
import PublishedShowDetails from './Published/ShowDetails'
import RecordingShowDetails from './Recording/ShowDetails'

const getDetailsComponent  = tab => {
  let Comp
  switch (tab) {
    case 'auditions':
      Comp = AuditionShowDetails
      break
    case 'published':
      Comp = PublishedShowDetails
      break
    case 'recording-and-production':
      Comp = RecordingShowDetails
      break
    default:
      Comp = () => {return null}
  }
  return Comp
}

const Audiobooks = props => {
  const { location: { search } } = props
  const qParams = queryString.parse(search)
  const { tab, id } = qParams
  const DetailsComponent = getDetailsComponent(tab)
  return (
    <PrivatePage>
      {tab && id ? <DetailsComponent /> : <List />}
    </PrivatePage>
  )
}

export default Audiobooks
