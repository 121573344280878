import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ActionButtonBox, BackButton, NextButton } from './index'
import Loader from '../../../components/Loader'
import { get, post } from '../../../lib/api'
import { getTokenAndUid, getNameFromUrl } from '../../../lib/utils'
import { FloatingInput, Upload } from '../../../components/FormikInputs'
import FileIcon from '../../../components/Images/File'
import CrossIcon from '../../../components/Images/SmallCross'
import { mock } from './mock'
import CheckedIcon from '../../../components/Images/Checked'
import { Box } from '../../../designSystem'


const Container = styled.div`
  padding: 48px 24px;
  margin-bottom: 24px;
  background-color: var(--Dove);

  @media (max-width: 600px) {
    padding: 32px 16px;
  }
`
const StepTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: var(--Text500);
  margin-bottom: 48px;
`
const SubSectionTitle = styled.div`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 8px;
`
const InlineInfoText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 16px;
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: unset;
  }
`
const InputContainer = styled.div`
  width: 49%;
  @media (max-width: 600px) {
    width: 100%;
  }
`
const FileContainer = styled.div`
  padding: 14px;
  border-radius: 4px;
  border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : 'dashed 1px var(--Azure500)'};
  background-color: var(--Grey300);
`
const FilePlaceHolder = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Azure500);
`
const ProgressDiv = styled.div`
  padding: 14px 16px;
  background-color: var(--Grey100);
  border-radius: 4px;
  display: flex;
  align-items: center;
`
const ProgressPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
  margin-right: 10px;
`
const LinearProgress = styled.div`
  width: calc(100% - 140px);
  height: 8px;
  border-radius: 2px;
  background-color: var(--Grey900);
  overflow: hidden;
  margin-right: 16px;
`
const ProgressBar = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: var(--Azure500);
`
const StyledCrossIcon = styled(CrossIcon)`
  z-index: 2;
  cursor: pointer;
`
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;
`
const FileNameDiv = styled.div`
  display: flex;
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  &:hover ${HoverActions} {
    display: flex;
  }
`
const FileIconDiv = styled.div`
  margin-right: 8px;
`

const FileDetailsDiv = styled.div``

const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const DownloadButton = styled.a`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const DownloadPdfButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const UploadButton = styled.button`
  outline: none;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  background: none;
  cursor: pointer;
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  width: 200px;

  @media (max-width: 600px) {
    width: 100%;
  }
`
const HelpText = styled.div`
  font-size: 20px;
  line-height: 1.6;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 24px;
`
const AgreeCTA = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  
  margin-right: 16px;

  span {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: var(--Text500);
  }
  span > span {
    text-decoration: underline;
    color: var(--azure);
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 16px;
  }
`
const ConfirmAndSubmit = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const RadioIcon = styled.div`
  margin-bottom: 20px;
  width: 16px;
  height: 16px;
  border: solid 1px var(--Text500);
  padding: 3px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ active }) =>
  active ? 'var(--Text500)' : 'var(--Grey300)'};  
`
const HR = styled.div`
  background-color: var(--Grey900);
  height: 2px;
  margin-bottom: 24px;
  margin-top: 24px;
`
const UploadLabel = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 4px;

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`
const UploadSubLabel = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 8px;
`

export const FileUploadComponent = ({
  readOnly,
  isLoading,
  progress,
  imageUrl,
  placeholder,
  handleReUpload = () => null,
  title,
  hasError,
  cancelRequest,
  privateDownload
}) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const handlePrivateDownload = () => {
    if (!imageUrl) return
    const extension = imageUrl.split('.').pop()
    get('/upload/user_docs', {
      params: {
        base_url: imageUrl
      }
    }).then(response => {
      const { signed_url: link } = response
      let hiddenElement = document.createElement('a')
      hiddenElement.href = link
      hiddenElement.target = '_blank'
      hiddenElement.download = 'doc.' + extension
      hiddenElement.click()
      setIsProcessing(false)
    })
  }
  return (
    <>
      {!imageUrl && !isLoading && (
        <FileContainer hasError={hasError}>
          {placeholder && <FilePlaceHolder>{placeholder}</FilePlaceHolder>}
        </FileContainer>
      )}
      {isLoading && (
        <ProgressDiv>
          <LinearProgress>
            <ProgressBar value={progress >= 100 ? 100 : progress} />
          </LinearProgress>
          <ProgressPercent>
            {(progress >= 100 ? '100' : progress.toFixed(0)) + '% Uploaded'}
          </ProgressPercent>
          <StyledCrossIcon onClick={cancelRequest}/>
        </ProgressDiv>
      )}
      {imageUrl && !isLoading && (
        <FileNameDiv>
          <FileIconDiv>
            <FileIcon />
          </FileIconDiv>
          <FileDetailsDiv>
            <FileName>{getNameFromUrl(imageUrl, title)}</FileName>
          </FileDetailsDiv>
          <HoverActions>
            {!privateDownload && <DownloadButton href={imageUrl} download type="button">
              Download
            </DownloadButton>}
            {privateDownload && <DownloadPdfButton onClick={handlePrivateDownload} type="button">
              {isProcessing ? 'Downloading...' : 'Download'}
            </DownloadPdfButton>}
            {!readOnly && <UploadButton type="button" onClick={handleReUpload}>
              Upload
            </UploadButton>}
          </HoverActions>
        </FileNameDiv>
      )}
    </>
  )
}

const Step = ({ setCurrentStep, id, resetSteps, showTerms, reappliedProgramId }) => {
  const [data, setData] = useState(null)
  const [isAgreed, setIsAgreed] = useState(false)

  useEffect(() => {
    const { uid } = getTokenAndUid()
    get(`/user_api/user_bank_accounts/${uid}/?is_novel=0`).then(response => {
      setData(response.result[0] || {})
    })
  }, [])

  return !data ? <Loader /> : <Formik
      initialValues={{
        account_name: data['account_name'] || '',
        bank_name: data['bank_name'] || '',
        account_no: data['account_no'] || '',
        ifsc_code: data['ifsc_code'] || '',
        cancelled_cheque_url: data['cancelled_cheque_url'] || '',
        pan_card_url: data['pan_card_url'] || '',
      }}
      validationSchema={Yup.object({
        account_name: Yup.string().required('Required'),
        bank_name: Yup.string().required('Required'),
        account_no: Yup.string().required('Required'),
        ifsc_code: Yup.string().required('Required'),
        cancelled_cheque_url: Yup.string().required('Required'),
        pan_card_url: Yup.string().required('Required')
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (!isAgreed) {
          setSubmitting(false)
          return
        }
        const { account_id } = data
        const { uid } = getTokenAndUid()
        const { pan_card_url,  cancelled_cheque_url, ...formValues} = values
        let formData = { ...formValues, account_id, uid }
        formData['pan_card_url'] = uid + '/' + pan_card_url.split('/').pop()
        formData['cancelled_cheque_url'] = uid + '/' + cancelled_cheque_url.split('/').pop()
        const formDataForProgram = {
          uid,
          entity_type: "book",
          entity_id: id,
          program_type: "writer_benefit"
        }
        if (reappliedProgramId) {
          formDataForProgram['program_id'] = reappliedProgramId
          formDataForProgram['status'] = 'pending'
        }
        post('/user_api/user_bank_accounts/?is_novel=0', { data: formData })
          .then(() => {
            return post('/content_api/book.creator_ben_prog/', {
              data: formDataForProgram
            })
          }).then(() => {
            setSubmitting(false)
            setCurrentStep(2)
          })
      }}
    >
      {({ isValid, isSubmitting, submitCount, errors }) => (
        <Form>
      <Container>
        <StepTitle>Application</StepTitle>
        <HelpText>
        You will be eligible for a reward of Rs. 5000/- once you have signed an exclusive contract with Pocket FM
        </HelpText>
        <SubSectionTitle>Bank Details</SubSectionTitle>
        <InlineInfoText>This is the bank account in which you will recieve your payments</InlineInfoText>
  
        <FlexContainer>
          <Box width={["100%","49%"]} mb={16}>
            <FloatingInput
              type="text"
              name="account_name"
              label="Full Name ( Account Owner )"
              size="lg"
            />
          </Box>
  
          <Box width={["100%","49%"]} mb={16}>
            <FloatingInput
              type="text"
              name="bank_name"
              label="Bank Name"
              size="lg"
            />
          </Box>
          
        </FlexContainer>
  
        <FlexContainer>
          <Box width={["100%","49%"]} mb={16}>
            <FloatingInput
              type="text"
              name="account_no"
              label="Account Number"
              size="lg"
            />
          </Box>
  
          <Box width={["100%","49%"]} mb={16}>
            <FloatingInput
              type="text"
              name="ifsc_code"
              label="IFSC Code"
              size="lg"
            />
          </Box>
          
        </FlexContainer>
        
      <SubSectionTitle>Documents</SubSectionTitle>
        <FlexContainer>
          <InputContainer>
            <UploadLabel>Passbook Copy or Cancelled Cheqeue</UploadLabel>
            <UploadSubLabel>Passbook Copy or Cancelled Cheqeue to confirm your bank details</UploadSubLabel>
            <Upload
              // label="Cancelled Cheqeue"
              name="cancelled_cheque_url"
              privateDownload
              hasError={submitCount > 0 && errors['cancelled_cheque_url']}
              hideInputOnValue={true}
              showProgress={true}
              placeholder="Drop your doc file here to upload or click to select"
              renderComp={FileUploadComponent}
              fileTypeTags="user_doc"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
              extraParams={{
                title: "Bank-Document"
              }}
            />
          </InputContainer>
          <InputContainer>
            <UploadLabel>Pan Card</UploadLabel>
            <UploadSubLabel>Pan card is required for tax purposes, if you do not provide the pan card, 20% tax will be deducted</UploadSubLabel>
            <Upload
              // label="Pan Card"
              name="pan_card_url"
              privateDownload
              hasError={submitCount > 0 && errors['pan_card_url']}
              hideInputOnValue={true}
              showProgress={true}
              placeholder="Drop your doc file here to upload or click to select"
              renderComp={FileUploadComponent}
              fileTypeTags="user_doc"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
              extraParams={{
                title: "Pan Card",
              }}
            />
          </InputContainer>
        </FlexContainer>
        <HR />
        <ConfirmAndSubmit>
          <AgreeCTA onClick={() => setIsAgreed(!isAgreed)} active={isAgreed}>
            <RadioIcon active={isAgreed}>
              <CheckedIcon />
            </RadioIcon>
            <span>
            I agree to the <span onClick={showTerms}>terms and conditions</span> and confirm that this is my original novel and is not published on any other online/offline platform
            </span>
          </AgreeCTA>
          {!isSubmitting && <SaveButton type="submit" isValid={isValid && isAgreed}>
          Submit Application
          </SaveButton>}
          {isSubmitting && <SaveButton type="button" isValid={isValid && isAgreed}>
              <Loader invertColor inline size="sm" />
          </SaveButton>}
        </ConfirmAndSubmit>
      </Container>
    </Form>
  )}
  </Formik>
}

export default Step
