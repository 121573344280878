import React from 'react'
import PrivatePage from '../PrivatePage'
import EditWriterProfileUi from './EditWriterProfileUi'
import EditWriterProfileContainer from './EditWriterProfileContainer';
const EditWriterProfile = ({...props}) => {
  
  return (
    <PrivatePage>
       <EditWriterProfileContainer  {...props}>
         <EditWriterProfileUi  />
        </EditWriterProfileContainer>
    </PrivatePage>
  )
}

export default EditWriterProfile
