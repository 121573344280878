import React, { useState, useEffect } from 'react'

import BackButton from '../../components/Images/BackButton'
import Loader from '../../components/Loader'
import { Text } from '../../designSystem'
import { get } from '../../lib/api'
import { get as getFromLocal, unset } from '../../lib/storage'
import IndividualEpisode from '../../components/IndividualEpisode'
import { Header } from '../../../src/pages/Shows/Audiobooks/Published/ShowDetails/AssetManagement/styled'
import TaskList from '../../components/TaskList'

function TaskDetail({ task_id, auditionbook }) {
  const [showTaskDetail, setShowTaskDetail] = useState(true)
  const [taskDetails, setTaskDetails] = useState({})

  const queryParams = new URLSearchParams(window.location.search)
  const tab = queryParams.get('tab')
  const id = queryParams.get('id')
  const [isLoading, setLoading] = useState(false)
  const locate = {
    pathName: window.location.pathname,
    tab,
    id,
  }
  const redirectUrl = `${locate.pathName}?tab=${locate.tab}&id=${locate.id}`
  const fetchTaskDetail = () => {
    setLoading(true)
    get(`/content_api/assets_management/tasks/${task_id}/`)
      .then((response) => {
        setTaskDetails(response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }
  useEffect(() => {
    fetchTaskDetail()
  }, [])
  const onBack = () => {
    unset('asset_id')
    setShowTaskDetail(false)
  }

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && showTaskDetail && (
        <Header>
          <BackButton onClick={onBack} />
          <Text variant="titleSecondary">{taskDetails?.title}</Text>
        </Header>
      )}
      {!isLoading &&
        showTaskDetail &&
        taskDetails?.assets?.map((taskDetail) => (
          <div key={taskDetail?.entity}>
            <IndividualEpisode
              taskDetail={taskDetail?.entity}
              asset_id={taskDetail?.asset_id}
              task_id={task_id}
              locate={locate}
              redirectUrl={redirectUrl}
              fetchTaskDetail={fetchTaskDetail}
              mode={
                taskDetail.asset_id === getFromLocal('asset_id')
                  ? 'edit'
                  : 'view'
              }
            />
          </div>
        ))}
      {!isLoading && !showTaskDetail && (
        <TaskList showTask={false} auditionbook={auditionbook} />
      )}
    </>
  )
}

export default TaskDetail
