import React from 'react'

const NovelsIcon = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.8073 1.23804H6.37249C4.79019 1.23804 3.50293 2.51021 3.50293 4.07397V20.1443C3.50293 21.8502 4.90723 23.238 6.63336 23.238H18.8073C19.1915 23.238 19.5029 22.9302 19.5029 22.5505C19.5029 22.1709 19.1915 21.863 18.8073 21.863H6.63336C5.67441 21.863 4.89423 21.092 4.89423 20.1443C4.89423 19.1966 5.67441 18.4255 6.63336 18.4255H18.8073C19.1915 18.4255 19.5029 18.1177 19.5029 17.738V1.92554C19.5029 1.54587 19.1915 1.23804 18.8073 1.23804ZM9 5.625C9 5.27982 9.27982 5 9.625 5H13.375C13.7202 5 14 5.27982 14 5.625C14 5.97018 13.7202 6.25 13.375 6.25H9.625C9.27982 6.25 9 5.97018 9 5.625ZM7.625 8C7.27982 8 7 8.27982 7 8.625C7 8.97018 7.27982 9.25 7.625 9.25H15.375C15.7202 9.25 16 8.97018 16 8.625C16 8.27982 15.7202 8 15.375 8H7.625Z" fill="white" fillOpacity="0.99"/>
        <path d="M18.8073 19.4568H6.63336C6.24915 19.4568 5.93771 19.7646 5.93771 20.1443C5.93771 20.524 6.24915 20.8318 6.63336 20.8318H18.8073C19.1915 20.8318 19.5029 20.524 19.5029 20.1443C19.5029 19.7646 19.1915 19.4568 18.8073 19.4568Z" fill="white" fillOpacity="0.99"/>
    </svg>
    
)

export default NovelsIcon
