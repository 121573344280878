import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import {
  FloatingInput,
  Input,
  Upload,
} from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import { post } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { Popup } from '../../../../../components/Popup'
import Dustbin from '../../../../../components/Images/Dustbin'
import CrossIcon from '../../../../../components/Images/CrossIcon'
import {
  objectCloneWithTopLevelNullRemoved,
  getTokenAndUid,
} from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import { isAdminAccess } from '../../../../../config'
import {
  FileUploadComponent,
  ScriptEditComponent,
} from '../../Unpublished/Edit/addEditEpisode'
import { Box, Text, Button } from '../../../../../designSystem'
import QCNoteTable from '../../Published/ShowDetails/qcNotes'

const StyledCrossIcon = styled(CrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const NewEpisodeContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  background-color: var(--Dove);
  margin-bottom: 8px;
`
const Details = styled.div`
  padding: 28px 24px 24px 24px;
`
const Actions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  background-color: var(--Grey100);
`
const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Punch500);
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 15px;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const InputContainer = styled.div``
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;
`
const DeleteHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const DeleteSpan = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
`
const DeleteBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`
const LeftDiv = styled.div`
  width: 49%;
`
const RightDiv = styled.div`
  width: 49%;
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
`

const AddEditEpisode = ({
  mode,
  book_id,
  show_id,
  // creator_id,
  thumbnail,
  successCallback = () => null,
  handleCancel,
  data = {},
}) => {
  const [isDeletePopupShown, setDeletePopupShow] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [audioDuration, updateAudioDuration] = useState('')
  const [notesAudioDuration, updateNotesAudioDuration] = useState('')
  const { uid } = getTokenAndUid()

  useEffect(() => {
    if (data?.chapter_details?.media_url) {
      let audio = new Audio()
      audio.src = data?.chapter_details?.media_url
      audio.addEventListener(
        'loadedmetadata',
        function () {
          updateAudioDuration(Math.ceil(audio.duration))
        },
        false
      )
    }
  }, [])

  const handleDelete = () => {
    const payload = {}
    if (data['chapter_details']) {
      const { book_id, chapter_id } = data['chapter_details']
      payload.chapter_details = {
        book_id,
        chapter_id,
        is_disabled: 1,
        status: 'pending',
      }
      payload['book_id'] = book_id
    }
    if (data['story_details']) {
      const { story_id } = data['story_details']
      payload.story_details = {
        story_id,
        show_id: show_id || data.show_id,
        is_disabled: 1,
        status: 'pending',
        // created_by: data.story_details.created_by || creator_id,
        created_by:
          data?.story_details?.created_by ||
          data?.chapter_details?.created_by ||
          uid,
      }
      payload['show_id'] = show_id || data.show_id
    }
    payload['view'] = isAdminAccess ? 'cms' : 'dashboard'

    post('/content_api/book.delete_episode', {
      data: payload,
    })
      .then(() => {
        setDeletePopupShow(false)
        successCallback()
      })
      .catch((error) => {
        setDeletePopupShow(false)
        throw error
      })
  }

  const initialTitle =
    (data['story_details'] || {})['story_title'] ||
    (data['chapter_details'] || {})['chapter_title'] ||
    ''
  const initialDescription =
    (data['story_details'] || {})['story_desc'] ||
    (data['chapter_details'] || {})['description'] ||
    ''

  return (
    <Popup show className="right-drawer-episode-form" close={handleCancel}>
      <Box
        boxShadow="0 8px 16px 0 rgba(73, 83, 89, 0.16)"
        bg="surface.foreground"
        mb={8}
        borderRadius={4}
      >
        <Box variant="flex" p={20}>
          <StyledCrossIcon onClick={handleCancel} />
        </Box>
        <GenericError show={apiError} onClose={() => toggleApiError(false)} />
        <Formik
          initialValues={{
            story_title: initialTitle,
            file_url: (data.chapter_details || {}).file_url || '',
            media_url:
              (data.story_details || data.chapter_details || {}).media_url ||
              '',
            comment: (data.creator_note || {}).comment || '',
            story_desc: initialDescription,
            audio_note: (data.ad || {}).media_url || '',
          }}
          validationSchema={Yup.object({
            story_title: Yup.string().required('Required'),
            media_url: Yup.string().required('Required'),
            // comment: Yup.string().required('Required')
          })}
          isInitialValid={mode === 'edit'}
          onSubmit={(values, { setSubmitting }) => {
            const imageUrl = thumbnail || data.thumbnail
            let api = '/content_api/book.create_episode'
            const {
              story_title,
              media_url,
              comment,
              story_desc,
              file_url,
              audio_note,
            } = values
            if (!media_url) {
              setSubmitting(false)
              return
            }
            let story_details = {
              show_id: show_id || data.show_id,
              story_id: data.story_id || null,
              // created_by: (data.story_details || {}).created_by || creator_id,
              created_by:
                uid ||
                (data.story_details || data?.chapter_details || {}).created_by,
              story_title,
              story_desc,
              audio_status:
                data?.story_details?.audio_status ||
                data?.chapter_details?.audio_status ||
                'pending',
              s3_unique_key: media_url ? media_url.split('/').pop() : null,
              image_s3_unique_key: imageUrl ? imageUrl.split('/').pop() : null,
              status: 'approved',
            }

            if (
              data?.story_details?.media_url !== media_url &&
              data?.chapter_details?.media_url !== media_url &&
              (data?.story_details?.audio_status === 'issues_in_raw' ||
                data?.chapter_details?.audio_status === 'issues_in_raw')
            ) {
              story_details.audio_status = 'issues_resolved_in_raw'
            }
            if (audioDuration) {
              story_details.duration = media_url
                ? Math.ceil(audioDuration)
                : null
            }

            story_details = objectCloneWithTopLevelNullRemoved(story_details)

            let chapter_details = data['chapter_details'] || {}

            chapter_details = {
              book_id,
              ...chapter_details,
              chapter_title: story_title,
              description: story_desc,
              file_url,
              content_unchanged: 1,
            }
            chapter_details['qc_details'] =
              data?.chapter_details?.qc_details || []
            let chapterAndStory = {
              chapter_details,
              story_details,
              book_id: chapter_details.book_id,
              show_id: story_details.show_id,
              view: isAdminAccess ? 'cms' : 'dashboard',
            }
            if (data.story_id) {
              api = '/content_api/book.update_episode'
              const creator_note = {
                comment_creator_uid: isAdminAccess
                  ? (data.story_details || {}).created_by || uid
                  : uid,
                // ? (data.story_details || {}).created_by || creator_id

                story_id: data.story_id,
                comment,
                is_creator_note: true,
                entity_type: 'story',
              }
              if ((data['creator_note'] || {})['comment_id']) {
                creator_note.comment_id = (data['creator_note'] || {})[
                  'comment_id'
                ]
                if ((data['creator_note'] || {})['comment'] !== comment) {
                  chapterAndStory.creator_note = creator_note
                }
              } else {
                if (comment) {
                  chapterAndStory.creator_note = creator_note
                }
              }
            }
            if (mode === 'edit') {
              if (!story_details['story_id'] && !media_url) {
                delete chapterAndStory.story_details
                // delete chapterAndStory.show_id
              }
              if (!chapter_details['chapter_id'] && !file_url) {
                delete chapterAndStory.chapter_details
                // delete chapterAndStory.book_id
              }
            } else {
              if (
                chapterAndStory.story_details.s3_unique_key &&
                !chapterAndStory.story_details.duration
              ) {
                setSubmitting(false)
                return
              }
              if (!media_url) {
                delete chapterAndStory.story_details
                // delete chapterAndStory.show_id
              }
              if (!file_url) {
                delete chapterAndStory.chapter_details
                story_details['audio_flow_eligible'] = false
                // delete chapterAndStory.book_id
              }
            }
            let audioRepetionWarning
            post(api, { data: chapterAndStory })
              .then((response) => {
                if (response?.warning_message) {
                  audioRepetionWarning = {
                    isWarning: true,
                    msg: response.warning_message,
                  }
                }
                if (mode !== 'edit' && comment) {
                  const {
                    story_details: {
                      story_info: { story_id },
                    },
                  } = response.result
                  if (story_id) {
                    api = '/content_api/book.update_episode'
                    const commentFormData = {
                      creator_note: {
                        comment_creator_uid: isAdminAccess
                          ? // ? (data.story_details || {}).created_by || creator_id
                            (data.story_details || {}).created_by || uid
                          : uid,
                        story_id,
                        comment,
                        is_creator_note: true,
                        entity_type: 'story',
                      },
                      view: chapterAndStory.view,
                      show_id: chapterAndStory.show_id,
                    }
                    post(api, { data: commentFormData })
                  }
                }

                if (audio_note) {
                  let audioNotePayload = {
                    ad_title: "Creator's Note",
                    media_url: audio_note,
                    image_url: imageUrl || null,
                    ad_name: 'creator_note',
                    preferred_play_time: 1,
                    ad_type: 'audio',
                    skipable: true,
                    skip_duration: 5,
                    show_cta: false,
                    pref_entity_type: 'story',
                    props: {},
                  }
                  if (notesAudioDuration) {
                    audioNotePayload.duration = Math.ceil(notesAudioDuration)
                  }
                  if (!data['story_id']) {
                    const {
                      story_details: {
                        story_info: { story_id },
                      },
                    } = response.result
                    audioNotePayload['pref_entity_id'] = story_id
                    audioNotePayload['mapped_entity_id'] = story_id
                  } else {
                    audioNotePayload['pref_entity_id'] = data['story_id']
                    audioNotePayload['mapped_entity_id'] = data['story_id']
                    if (data['ad']['ad_id']) {
                      audioNotePayload = data['ad']
                      audioNotePayload.media_url = audio_note
                    }
                    if (notesAudioDuration) {
                      audioNotePayload.duration = Math.ceil(notesAudioDuration)
                    }
                  }

                  post('/content_api/ads.create_update_ad', {
                    data: audioNotePayload,
                  })
                }
              })
              .then(() => {
                setSubmitting(false)
                successCallback({
                  ...values,
                  audioWarningMessage: audioRepetionWarning,
                })
              })
              .catch((error) => {
                setSubmitting(false)
                throw error
              })
          }}
        >
          {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
            <Form>
              <Popup
                show={isDeletePopupShown}
                close={() => setDeletePopupShow(false)}
                renderActions={
                  <>
                    <button
                      className="action-button"
                      type="button"
                      onClick={() => setDeletePopupShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="action-button danger"
                      type="button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </>
                }
              >
                <Box
                  variant="flex"
                  alignItems="center"
                  mb={8}
                  justifyContent="flex-start"
                >
                  <Dustbin />
                  <Text variant="titleSecondary" ml={8}>
                    Delete this episode?
                  </Text>
                </Box>
                <Text variant="caption" lineHeight={1.8} fontWeight={500}>
                  Are you sure you want to permanently delete this episode, once
                  an episode is deleted, you can’t undo it.
                </Text>
              </Popup>
              <Box pt={28} pl={24} pr={24} pb={24}>
                <Text variant="titleSecondary">
                  Episode {data['natural_sequence_number'] || 1}
                </Text>
                <Box variant="hr" my={16} />
                <Box variant="flex" flexDirection="column">
                  <Box width="100%">
                    <Box>
                      <FloatingInput
                        label="Title"
                        name="story_title"
                        hasError={submitCount > 0 && errors['story_title']}
                        infoTitle="Give a title to this episode"
                        disabled={
                          !isAdminAccess && mode !== 'add' && !!initialTitle
                        }
                        type="text"
                        placeholder="Write something that describes this episode"
                      />
                    </Box>
                    <Box mt={16}>
                      <FloatingInput
                        asTextArea
                        rowCount={4}
                        disabled={
                          !isAdminAccess &&
                          mode !== 'add' &&
                          !!initialDescription
                        }
                        infoTitle=" Add the description of this episode"
                        label="Description"
                        name="story_desc"
                        placeholder="Description of this episode"
                      />
                    </Box>

                    <Box mt={16}>
                      <FloatingInput
                        asTextArea
                        rowCount={4}
                        infoTitle="Add a note for this episode"
                        label="Creator’s Note"
                        hasError={submitCount > 0 && errors['comment']}
                        name="comment"
                        placeholder="Creator's Note of this episode"
                      />
                    </Box>
                  </Box>
                  <Box variant="flex" flexDirection="column" width="100%">
                    {(isAdminAccess || mode === 'edit') && (
                      <ScriptEditComponent
                        label="Script Doc File"
                        name="file_url"
                        resourceUrl={values.file_url}
                        readOnly={1}
                        handleOpenEditor={() => null}
                        title={values.story_title}
                      />
                    )}
                    <Upload
                      label="Audiobook File"
                      name="media_url"
                      hasError={submitCount > 0 && errors.media_url}
                      hideInputOnValue
                      showProgress
                      audioStatus={
                        data?.story_details?.audio_status ||
                        data?.chapter_details?.audio_status ||
                        null
                      }
                      isDownloadOptionRemoved={data?.status}
                      maxFileSize={314572800}
                      placeholder="Drop your audio file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      fileTypeTags="media"
                      handleDurationUpdate={(duration) =>
                        updateAudioDuration(duration)
                      }
                      // this is the callback to store audio duration in state values.
                      // this is not being stored in formik state
                      accept="audio/mpeg, audio/mp3, audio/ogg, audio/wav"
                      extraParams={{
                        title: values.story_title || 'recording story',
                        ...(data.story_id && { story_id: data.story_id }),
                      }}
                      uploadedFileMeta={{ size: '' }}
                    />
                    {(data.story_details || data.chapter_details || {})
                      .media_url &&
                      ((data.chapter_details || {}).qc_details || [])?.length >
                        0 && (
                        <QCNoteTable
                          qcNotes={
                            (data.chapter_details || {}).qc_details || []
                          }
                        />
                      )}
                    <Upload
                      label="Creator Note Audio"
                      bottomInfoLabel="Duration of Creator’s Note must be within 20 to 120 seconds"
                      name="audio_note"
                      hideInputOnValue
                      showProgress
                      placeholder="Drop your audio file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      fileTypeTags="media"
                      audioLengthValidator={(duration) =>
                        duration > 19 && duration < 120
                      }
                      audioDurationErrorMsg="Duration of Creator’s Note must be within 20 to 120 seconds"
                      handleDurationUpdate={(duration) =>
                        updateNotesAudioDuration(duration)
                      }
                      // this is the callback to store audio duration in state values.
                      // this is not being stored in formik state
                      accept="audio/mpeg, audio/mp3"
                      extraParams={{
                        title: 'audio-note',
                      }}
                      uploadedFileMeta={{ size: '' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Actions>
                {/* {mode === 'edit' && <DeleteButton type="button">Delete</DeleteButton>} */}
                {mode === 'edit' && isAdminAccess && (
                  <DeleteButton
                    type="button"
                    onClick={() => setDeletePopupShow(true)}
                    type="button"
                  >
                    Delete
                  </DeleteButton>
                )}
                <CancelButton type="button" onClick={handleCancel}>
                  Cancel
                </CancelButton>
                {!isSubmitting && (
                  <SaveButton type="submit" isValid={isValid}>
                    Save
                  </SaveButton>
                )}
                {isSubmitting && (
                  <SaveButton type="button" isValid={isValid}>
                    <Loader invertColor inline size="sm" />
                  </SaveButton>
                )}
              </Actions>
            </Form>
          )}
        </Formik>
      </Box>
    </Popup>
  )
}

export default AddEditEpisode
