
import React from 'react'

export default function Index() {
  return (
    
<svg width="293" height="106" viewBox="0 0 293 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.976 0h109.939a5 5 0 0 1 5 5v87.429a5 5 0 0 0 5 5h111.077a5 5 0 0 0 3.823-1.778l33.298-39.51a5 5 0 0 0-.061-6.515L241.196 6.389C239.016 3.9 240.784 0 244.094 0c1.098 0 2.143.468 2.874 1.287l43.154 48.346a5 5 0 0 1 .104 6.538l-40.203 48.038a5 5 0 0 1-3.834 1.791H10.938c-4.283 0-6.584-5.033-3.782-8.272l35.984-41.59a5 5 0 0 0-.008-6.554L7.204 8.282C4.389 5.046 6.687 0 10.976 0z" fill="#fff"/>
    <path d="M175.133 62.632a4.66 4.66 0 0 1 3.296-1.365H188.5a4.661 4.661 0 0 1 4.661 4.661v2.982h-2.607v-2.982a2.055 2.055 0 0 0-2.054-2.054h-10.071a2.057 2.057 0 0 0-2.054 2.054v2.982h-2.607v-2.982a4.66 4.66 0 0 1 1.365-3.296zm11.853-4.862a6.338 6.338 0 1 1-7.043-10.54 6.338 6.338 0 0 1 7.043 10.54zm-1.448-8.374a3.735 3.735 0 0 0-4.713.464 3.732 3.732 0 1 0 4.713-.464z" fill="url(#dlla7a9kca)" stroke="#000" strokeWidth=".75"/>
    <path d="M193.161 34.036v.375h5.41a2.982 2.982 0 0 1 2.983 2.982v35.25a2.984 2.984 0 0 1-2.983 2.982h-30.214a2.984 2.984 0 0 1-2.982-2.982v-35.25a2.983 2.983 0 0 1 2.982-2.982h5.411V32.357a2.983 2.983 0 0 1 2.982-2.982h13.429a2.982 2.982 0 0 1 2.982 2.982v1.679zm-16.411-2.054h-.375v7.464h14.179v-7.464H176.75zm21.821 41.036h.375v-36h-5.785v5.035h-19.393v-5.035h-5.786v36h30.589z" fill="url(#1k50d4xw7b)" stroke="#000" strokeWidth=".75"/>
    <path d="m67.542 66.752.003.002c1.658 1.17 3.634 1.746 5.902 1.746 2.269 0 4.24-.575 5.89-1.747 1.655-1.168 2.905-2.85 3.764-5.015.868-2.175 1.294-4.774 1.294-7.784 0-2.993-.425-5.575-1.294-7.732-.86-2.157-2.11-3.83-3.766-4.99-1.657-1.161-3.628-1.732-5.888-1.732s-4.23.57-5.888 1.732c-1.646 1.16-2.897 2.833-3.765 4.989-.86 2.15-1.285 4.73-1.294 7.731v.002c0 3 .42 5.595 1.28 7.77v.002c.868 2.164 2.118 3.849 3.762 5.026zM77.03 61.1c-.906 1.542-2.092 2.256-3.582 2.256-1.008 0-1.863-.326-2.59-.982-.733-.67-1.33-1.692-1.764-3.11-.423-1.412-.636-3.178-.627-5.308v-.001c.008-3.194.5-5.54 1.414-7.096.907-1.552 2.09-2.268 3.567-2.268.998 0 1.848.329 2.576.994l.002.002c.733.662 1.33 1.675 1.764 3.086.431 1.402.654 3.158.654 5.28 0 3.221-.492 5.583-1.414 7.147zm22.795-20.73v-.5H94.503l-.123.08-6.74 4.33-.23.147V50.114l.77-.492 5.732-3.661v22.023h5.912V40.37z" fill="url(#gcofb9mupc)" stroke="url(#t9nnp48sjd)"/>
    <defs>
        <linearGradient id="dlla7a9kca" x1="193.536" y1="45.785" x2="169.664" y2="67.031" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="1k50d4xw7b" x1="201.929" y1="29" x2="154.84" y2="67.417" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="gcofb9mupc" x1="99.324" y1="40" x2="71.914" y2="76.923" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="t9nnp48sjd" x1="99.324" y1="40" x2="71.914" y2="76.923" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
    </defs>
</svg>

  )
}
