import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import Axios from 'axios'

import MyEditor, {
  getContent,
  handleRedo,
  handleUndo,
  isContentChanged,
  isRedoEmpty,
  isUndoEmpty,
  resetIsContentChanged,
} from './TinyMCEEditor'

import { EditorContainer, EditorHolder, SummaryBox } from './styles'
import { get } from '../../lib/storage'
import CommentBox from '../CommentBox'
import { get as getApi, post } from '../../lib/api'
import CommentBoxList from '../CommentBoxList'
import WordCount from '../Images/WordCount'
import MyTooltip from '../Tooltip'
import { Popup } from '../Popup'

import {
  CommentsFileCdn,
  CommentsFilGetPreSignedUrl,
  formDataCalculateForAws,
  getHashFromFileLink,
  paramsCal,
} from './helper'

const MessageHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const MessageSpan = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: capitalize;
  color: var(--Text700);
`

const MessageBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`

function TextEditor({
  setContent,
  updateWordCount,
  content,
  chapterId,
  initialContent,
  bookId,
  episodeTag,
  setEditorToolbarChange,
  setCommentedFileUrl,
  commentedFileUrl,
  isContentChangedValid,
  originalFileUrl,
}) {
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [commentedList, setCommentedList] = useState([])
  const [allCommentsList, setallCommentsList] = useState([])
  const [profileName, setProfileName] = useState()
  const [profileImage, setProfileImage] = useState()
  const [addComment, setAddComment] = useState(false)
  const [clickedCommentId, setClickedCommentId] = useState('')
  const [popup, setPopup] = useState(false)
  const [showSingleCommentPopUp, setShowSingleCommentPopUp] = useState({})
  const [showCommentIcon, setShowCommentIcon] = useState(false)


  const showCommentIconRef = useRef()
  const scollToRef = useRef()

  const location = useLocation()
  const handleMouseUp = () => {
    if (showCommentIcon && episodeTag) {
      if (isContentChangedValid) {
        setAddComment(false)
        return
      }

      const editor = window.tinymce.activeEditor
      const selectedValue = editor.selection.getContent()
      const selectedValueOne = editor.selection.getContent({ format: 'text' })
      const selectedCommentNodeCheck =
        editor.selection.getNode().nodeName === 'SPAN'
      const selectedIndex = selectedValue.indexOf('highlight')
      const selectedValuelength =
        selectedValue.split('<p>').length - 1 <= 1 ||
        (selectedValue.split('<p>').length - 1 === 2 &&
          selectedValue.includes('<p>&nbsp;</p>'))
      const checkselectedPara = !!(
        (selectedValue.includes('<p>') && selectedValue.includes('<h')) ||
        selectedValue.includes('<br')
      )
      let finalSelectedValue = selectedValue
      const correction = {
        '<p>&nbsp;</p>': '',
        '<p>': '',
        '</p>': '',
        '<div>': '',
        '</div>': '',
      }
      Object.keys(correction).forEach((key) => {
        finalSelectedValue = finalSelectedValue?.replaceAll(
          key,
          correction[key],
        )
      })
      const innerHTML = initialContent
      const checkIndex =
        innerHTML.indexOf(finalSelectedValue.trim()) === -1 &&
        innerHTML.indexOf(selectedValueOne) === -1
      if (
        selectedValue.length > 0 &&
        !selectedCommentNodeCheck &&
        selectedIndex === -1 &&
        selectedValuelength &&
        !checkselectedPara &&
        !checkIndex
      ) {
        setShowCommentBox(false)
        if (innerHTML.indexOf(finalSelectedValue.trim()) !== -1) {
          setSelectedValue(finalSelectedValue.trim())
        } else if (innerHTML.indexOf(selectedValueOne) !== -1) {
          setSelectedValue(selectedValueOne)
        }
        setAddComment(true)
      }
      if (selectedValue.length === 0) {
        setAddComment(false)
      }
    }
  }

  const filterCommentsLists = (htmlString = '', allCommentsFetched = []) => {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(htmlString, 'text/html')
    let list = []
    list = allCommentsFetched.map((ele) => {
      const commentNode = htmlDoc.getElementById(ele?._id)

      if (
        (commentNode?.nodeName !== 'SPAN' ||
          commentNode?.innerText !== ele?.props?.selected_text) &&
        ele.status !== 'deleted'
      ) {
        return { ...ele, status: 'resolved' }
      }
      return ele
    })
    // filter comments with deleted comments
    const filteredComments = list?.filter((comment) => {
      if (comment.status !== 'deleted') {
        return comment
      }
      return null
    })
    
    const replyFilteredComments = [...filteredComments]
    // filtering replying comments
    const finalCommentList = replyFilteredComments?.map((ele) => {
      const filteredListComment = ele?.child_comments?.filter(
        (elem) => elem.status !== 'deleted',
      )
      return { ...ele, child_comments: filteredListComment }
    })

    return { list, finalCommentList }
  }

  // function only to get comments list
  const getCommentsOnly = (CommentedInnerHtml = '') => {
    const pathName = location.pathname.split('/')[2]
    if (chapterId && pathName === 'audiobooks' && content) {
      setShowCommentIcon(true)
      getApi(`/content_api/book.get_chapter_comments?chapter_id=${chapterId}`)
        .then((res) => {
          const fetchedList = res?.result
          const contentToFilter = CommentedInnerHtml.length
            ? CommentedInnerHtml
            : content
          const { list, finalCommentList } = filterCommentsLists(
            contentToFilter,
            fetchedList,
          )
          setallCommentsList([...list])
          setCommentedList(finalCommentList)
        })
        .catch((error) => {
          throw error
        })
    }
  }

  const updateCommentsFile = (innerHTML = '') => {
    const editor = window.tinymce.activeEditor
    let CommentedInnerHtml = editor.getContent()

    if (innerHTML) CommentedInnerHtml = innerHTML
    const file = new Blob([CommentedInnerHtml], {
      type: 'text/plain;charset=utf-8',
    })
    // upload to s3
    // calculating params
    const params = paramsCal('chapter_comment', chapterId, chapterId)
    let url = ''
    const getPreSignedUrl = CommentsFilGetPreSignedUrl
    getApi(getPreSignedUrl, { params })
      .then((response) => {
        const { formData, prefix, suffix } = formDataCalculateForAws(response)
        formData.append('file', file)
        const cdn = CommentsFileCdn
        post(prefix, { data: formData })
          .then(() => {
            url = `${cdn}/${chapterId}/${suffix}`
            const hashValue = getHashFromFileLink(originalFileUrl)
            const dataForCommentedChapter = {
              chapter_id: chapterId,
              file_url: `${url}?hash=${hashValue}`,
            }
            Axios.get(url)
              .then((response) => {
                setContent(response.data)
              })
              .catch((err) => {
                throw err
              })
            /*  make post api call for commented file on either
                comments_file_url is null or file are not in sync  
            */
            if (
              !commentedFileUrl ||
              (commentedFileUrl && !commentedFileUrl?.includes(hashValue))
            ) {
              return post(
                `/content_api/book.create_or_update_chapter_comment_file`,
                { params: dataForCommentedChapter }
              )
                .then(() => {
                  setCommentedFileUrl(`${url}?hash=${hashValue}`)
                })
                .catch((err) => {
                  throw err
                })
            }
          })
          .catch((err) => {
            throw err
          })
          .finally(() => {
            getCommentsOnly(CommentedInnerHtml)
          })
      })
      .catch((err) => {
        throw err
      })
  }

  const handleClick=()=>{
    const editor = window.tinymce.activeEditor
    const selectedNode = editor.selection.getNode()
    const selectedCommentID = selectedNode?.getAttribute('id')
    if (selectedNode.nodeName === 'SPAN' && selectedCommentID) {
      setClickedCommentId(selectedCommentID)
    } else setClickedCommentId('')
  }

  useEffect(() => {
    setProfileImage(get('image_url'))
    setProfileName(get('name'))
  }, [])


  useEffect(() => {
    if (addComment) {
      const checkIfClickedOutside = (e) => {
        if (
          addComment &&
          showCommentIconRef.current &&
          !showCommentIconRef.current.contains(e.target)
        ) {
          setAddComment(false)
        }
      }
      document.addEventListener('mousedown', checkIfClickedOutside)
      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [addComment])

  const handleAddCommentClick = () => {
    setShowCommentBox(true)
    setAddComment(false)
    scollToRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  // get all comments with sync comments file and original chapter file
  // it is only run first time
  const getAllComments = () => {
    const pathName = location.pathname.split('/')[2]
    if (chapterId && pathName === 'audiobooks' && initialContent) {
      setShowCommentIcon(true)
      getApi(`/content_api/book.get_chapter_comments?chapter_id=${chapterId}`)
        .then((res) => {
          let innerHTML = initialContent
          if (commentedFileUrl) {
            Axios.get(commentedFileUrl)
              .then((response) => {
                // create document for dom query
                const { list, finalCommentList } = filterCommentsLists(
                  response?.data,
                  res?.result,
                )
                setallCommentsList(list)
                setCommentedList(finalCommentList)
                setContent(response.data)
              })
              .catch((err) => {
                throw err
              })
          } else {
            setallCommentsList(res?.result)
            setCommentedList(res?.result)
          }
          if (initialContent) {
            // calculating hash value to check whether original file is sync with commented file
            const hashVal = getHashFromFileLink(originalFileUrl)

            // case 1 commented_file_url  not present and comments present
            // case 2 commented_file_url present but dosen't contains hash value ,means files are not in sync
            if (
              (!commentedFileUrl && res?.result?.length) ||
              (commentedFileUrl && !commentedFileUrl?.includes(hashVal))
            ) {
              res?.result?.map(({ _id, props }) => {
                const seperatedHTML = innerHTML.split(props.selected_text)
                innerHTML = seperatedHTML.join(
                  `<span style='background-color: #F6BE00' class='highlight' id='${_id}' >${props.selected_text}</span>`,
                )
                return null
              })
              // creating commented file with comments
              updateCommentsFile(innerHTML)
            }
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }
  useEffect(() => {
    getAllComments()
  }, [])

  const handleOpenPopup = (id) => {
    const editor = window.tinymce.activeEditor
    const scrollYNumber = editor?.contentDocument?.getElementById(id)?.offsetTop
    if (scrollYNumber) {
      editor
        .getWin()
        .scrollTo({ top: scrollYNumber, left: 0, behavior: 'smooth' })
    }
    const allComments = [...allCommentsList]
    const singleComment = allComments.filter(({ _id }) => id === _id)[0]
    setPopup(true)
    setShowSingleCommentPopUp({
      status: singleComment.status,
      selected_text: singleComment?.props?.selected_text,
      comment: singleComment.comment,
    })
  }

  return (
    <>
      <Popup
        show={popup}
        close={() => {
          setPopup(false)
          setShowSingleCommentPopUp({})
        }}
        renderActions={
          <button
            className="action-button primary-button"
            type="button"
            onClick={() => {
              setPopup(false)
              setShowSingleCommentPopUp({})
            }}
          >
            Close
          </button>
        }
      >
        <MessageHeading>
          <MessageSpan> Status: {showSingleCommentPopUp.status}</MessageSpan>
        </MessageHeading>
        <MessageHeading>
          <MessageSpan>
            {' '}
            Selected Text: {showSingleCommentPopUp.selected_text}
          </MessageSpan>
        </MessageHeading>
        <MessageBody>
          <MessageSpan>Comment:</MessageSpan> {showSingleCommentPopUp.comment}
        </MessageBody>
      </Popup>
      <EditorHolder showCommentIcon={showCommentIcon}>
        <EditorContainer id="chapterBody" showCommentIcon={showCommentIcon}>
          {allCommentsList.length > 0 && (
            <SummaryBox>
              <h3>Summary</h3>
              {allCommentsList.map((comment, index) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p onClick={() => handleOpenPopup(comment._id)}>
                    Comment {index + 1}: {comment.comment}
                  </p>
                  <span>{comment.status}</span>
                </div>
              ))}
            </SummaryBox>
          )}
          <div className="editorFixed">
            {addComment && (
              <MyTooltip title="Add Comment" placement="bottom">
                <div
                  className="wordImageBox"
                  onClick={handleAddCommentClick}
                  ref={showCommentIconRef}
                >
                  <WordCount width={40} height={40} />
                </div>
              </MyTooltip>
            )}
            <MyEditor
              setContent={setContent}
              updateWordCount={updateWordCount}
              content={content}
              handleMouseUp={handleMouseUp}
              setEditorToolbarChange={setEditorToolbarChange}
              handleClick={handleClick}
            />
          </div>
        </EditorContainer>
        <div
          className="comment-list"
          style={{
            display: !showCommentIcon ? 'none': null
          }}
          ref={scollToRef}
        >
          {showCommentBox && (
            <CommentBox
              profileName={profileName}
              profileImage={profileImage}
              setShowCommentBox={setShowCommentBox}
              selectedValue={selectedValue}
              chapterId={chapterId}
              getAllComments={getAllComments}
              bookId={bookId}
              updateCommentsFile={() => updateCommentsFile()}
              isContentChangedValid={isContentChangedValid}
            />
          )}
          {commentedList && (
            <CommentBoxList
              commentedList={commentedList}
              getAllComments={getAllComments}
              profileImage={profileImage}
              profileName={profileName}
              chapterId={chapterId}
              clickedCommentId={clickedCommentId}
              setClickedCommentId={setClickedCommentId}
              bookId={bookId}
              episodeTag={episodeTag}
              updateCommentsFile={() => updateCommentsFile()}
            />
          )}
        </div>
      </EditorHolder>
    </>
  )
}

export {
  TextEditor,
  getContent,
  isContentChanged,
  resetIsContentChanged,
  isUndoEmpty,
  isRedoEmpty,
  handleRedo,
  handleUndo,
}
