import React, { useEffect, useState } from 'react'
import { get } from '../../lib/api'
import GenericError from '../GenericError'
import { getTokenAndUid } from '../../lib/utils'
import Loader from '../Loader'

const EditWriterProfileContainer = (props) => {
  const [userDetails, setUserDetails] = useState({})
  const [apiError, toggleApiError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async function () {
    setIsLoading(true)
    const { uid } = getTokenAndUid()
    // const  userDetails = await get('/user_api/user.get_details', { params: {
    //   uid
    // } })
    // const { fullname, gender, email, phone_number, bio } = userDetails.result[0]
    // const creatorAccDetails = await get('/user_api/user.get_creator_details?is_novel=0')
    // const { account_type, languages } = creatorAccDetails.result
    // const addressDetails = await get(`/user_api/user_addresses/${uid}`)
    // const { address_id, address_1, address_2, pin_code, city, state } = addressDetails.result[0]
    // const bankDetails = await get(`/user_api/user_bank_accounts/${uid}`)
    // const {account_id, account_name, bank_name, account_no, ifsc_code, cancelled_cheque_url, pan_card_url} = bankDetails.result[0]
    const allPromises = [
      get('/user_api/user.get_details', {
        params: {
          uid,
          is_novel: 1,
        },
      }),
      get('/user_api/user.get_creator_details?is_novel=0'),
      get(`/user_api/user_addresses/${uid}/?is_novel=0`),
      get(`/user_api/user_bank_accounts/${uid}/?is_novel=0`),
    ]
    Promise.all(allPromises)
      .then((responseArray) => {
        const [userDetails, creatorAccDetails, addressDetails, bankDetails] =
          responseArray
        const { fullname, gender, email, phone_number, bio, image_url } =
          userDetails.result[0]
        const { account_type, languages,  professional_creator_details,
            creator_alias, } = creatorAccDetails.result
        const { address_id, address_1, address_2, pin_code, city, state } =
          addressDetails.result[0] || {}
        const {
          account_id,
          account_name,
          bank_name,
          account_no,
          ifsc_code,
          cancelled_cheque_url,
          pan_card_url,
          pan_card_no,
          contract_url
        } = bankDetails.result[0] || {}

        setUserDetails({
         contract_url,
          professional_creator_details,
          creator_alias,
          fullname,
          gender,
          email,
          phone_number,
          bio,
          image_url,
          account_type,
          languages,
          address_id,
          address_1,
          address_2,
          pin_code,
          city,
          state,
          account_id,
          account_name,
          bank_name,
          account_no,
          ifsc_code,
          cancelled_cheque_url,
          pan_card_no,
          pan_card_url,
        })
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })
  }

  const child = React.Children.only(props.children)

  if (isLoading) {
    return <Loader />
  }

  if (apiError) {
    return (
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
    )
  }
  return React.cloneElement(child, {
    ...props,
    userDetails,
    reset: fetchData,
  })
}

export default EditWriterProfileContainer
