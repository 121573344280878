import styled from 'styled-components'
import AudiobooksHomeGraphic from '../../../../../components/Images/AudiobooksHome'

const Container = styled.div`
  padding-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
  margin-bottom: 40px;
  flex-direction: column;
  background-color: var(--Dove);
`
const ArtContainer = styled.div`
  width: 342px;
  margin: auto;

`
const EmptyAudioBookIcon = styled(AudiobooksHomeGraphic)`
@media(max-width: 600px){
  width: 240px;
  height: 202px;
}
`

const StartText = styled.div`
  margin-top: 40px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--Text700);
  text-align: center;
  max-width: 615px;

  @media (max-width: 600px){
    max-width: 350px;
    font-size: 20px;
  }
`
const DescriptionText = styled.p`
  max-width: 512px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-top: ${({ firstIndex }) => firstIndex ? '16px' : '2px'};
  text-align: center;

  @media (max-width: 600px){
    max-width: 350px;
  }
`
export {
  Container,
  ArtContainer,
  StartText,
  DescriptionText,
  EmptyAudioBookIcon
}
