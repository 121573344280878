import styled from 'styled-components'
import Bin from '../../../../components/Images/Dustbin'

const Dustbin = styled(Bin)`
  fill: #3e445e;
`
const ChapterOptionsBox = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`

const Option = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`
export {
  Dustbin,
  ChapterOptionsBox,
  Option
}
