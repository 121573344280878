function debounce (fn, delay) {
  let timer
  return function () {
    clearTimeout(timer)

    let context = this
    let args = arguments
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

export {
  debounce
}
