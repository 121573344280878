import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useAudioPlayer from '../../../../../lib/audio/useAudioPlayer'
import PauseIcon from '../../../../../components/Images/PauseIcon'
import PlayIcon from '../../../../../components/Images/PlayIcon'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { isAdminAccess } from '../../../../../config'
import StarEmptyIcon from '../../../../../components/Images/StarEmpty'
import StarFilledIcon from '../../../../../components/Images/StarFilled'
import Loader from '../../../../../components/Loader'
import { get, post, put } from '../../../../../lib/api'
import { getTokenAndUid } from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import MessagePopup from '../../../../../components/MessagePopup'
import AwardIcon from '../../../../../components/Images/AwardIcon'
import { Box, Image, Text, Button} from '../../../../../designSystem'
import { Popup } from '../../../../../components/Popup' 
import Vellip from '../../../../../components/Images/Vellip' 
const EditButtonBox = styled.div`
  margin-left: auto;
  align-self: center;
  display: none;

`

const ChapterOptionsBox = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`

const Option = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`

const Container = styled.div`
  border-radius: ${({ editmode }) => editmode ? '4px' : '2px'};
  box-shadow: ${({ editmode }) => editmode ? '0 8px 16px 0 rgba(73, 83, 89, 0.16)' : 'none'};
  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
`
const VellipContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  padding: 8px;
  border-radius: 4px;

  &: hover {
    background-color: #ebecf1;
  }
`
const VellipIcon = styled(Vellip)`
  display: block;
`

const AuditionDiv = styled.div`
  padding: 24px;
  // border-radius: 2px;
  background-color: var(--Dove);
  flex-direction: row;
  &:hover ${EditButtonBox} {
    display: block;
  }

  @media(max-width:600px){
    flex-direction:column;
    padding: 16px;
  }
`
const ActionsButtons = styled.div`
  background-color: #f6f7f9;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const CancelButton = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
  margin-right: 40px;
  cursor: pointer;
`
const SaveButton = styled.div`
  cursor: pointer;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
  text-align: center;
`
const Separator = styled.span`
  padding: 0 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
`
const Stats = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-right: 5px;
`
const Controls = styled.div`
  border-radius: 4px;
  background-color: var(--Grey100);
  display: inline-flex;
  align-items: center;
  padding: 12px;

  @media(max-width:600px){
    width: 90vw;
  }
`
const ProgressBarOuter = styled.div`
  height: 8px;
  border-radius: 4px;
  background-color: var(--Grey900);
  margin: 0 12px;
  width: 320px;
`
const ProgressBarInner = styled.div`
  height: 8px;
  border-radius: 4px;
  background-color: var(--Azure500);
  width: ${({ width }) => width || 0}%;
`
const Time = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`

const EditButton = styled.div`
  border-radius: 4px;
  background-color: rgba(6, 138, 226, 0.1);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Azure500);
  width: 128px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
`
const AwardButton = styled(EditButton)``
const AwardButtonBox = styled.div`
  margin-left: auto;
  align-self: center;
`
const AwardedBadge = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 24px;
  background-color: #79ba10;
  margin-left: 8px;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
  margin: 24px 0;
`
const RatingsTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.8);
  margin-bottom: 16px;
`
const Sound = styled.div`
  width: 31%;
`
const Narration = styled.div`
  width: 31%;
`
const CommunityM = styled.div`
  width: 31%;
`
const IndividualTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`
const IndividualTitleSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
`
const AvgRatings = styled(IndividualTitleSpan)`
  margin-left: auto;
`

const DisplayRatingsBox = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: #f6f7f9;
  margin-bottom: 16px;
`
const StarsRatingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`
const RatingTypeSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
`
const StarsHolder = styled.div`
  margin-left: auto;
`
const StarFilled = styled(StarFilledIcon)`
  margin-left: 12px;
`
const SmallStar = styled(StarFilledIcon)`
  width: 14px;
  height: 14px;
`
const StarEmpty = styled(StarEmptyIcon)`
  margin-left: 12px;
`
const RemarksTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
  margin-bottom: 8px;
`
const RemarksBox = styled.textarea`
  display: block;
  border-radius: 4px;
  border: solid 1px #e0e2eb;
  background-color: #f0f1f5;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.4);
  width: 100%;
  resize: none;
`
const RatingsDisplayCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 475px;
`
const IndividualRatingDisplay = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #f6f7f9;
  justify-content: smace-between;
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);

  &:last-child {
    margin-right: 0px;
  }
`
const IndividualLabel = styled.span`
  margin-right: 8px;
`
const IndividualRating = styled.span`
`

function formatDuration(duration) {
  return moment
    .duration(duration, "seconds")
    .format("mm:ss", { trim: false });
}

const StarsArray = ({type, by, rating, handleClick}) => {
  const stars = []
  for(let i = 0 ; i<5 ; i++) {
    stars.push(
      i < rating ? <StarFilled key={type + by + 'star-' + i} onClick={() => handleClick(i + 1)} /> : <StarEmpty key={type + by + 'star-' + i} onClick={() => handleClick(i + 1)}/>
    )
  }
  return stars
}

const AuditionCard = ({data: {
  file_url,
  audition_id,
  description,
  is_approved,
  vo_artist_info: {
    fullname,
    stats: { subscriber_count, total_plays } = {},
    image_url,
  } = {},
  ratings_stats = {}
} = {}, isOwner, handleEditClick, successCallback, handleAward}) => {
  const audioElement = useRef()
  const [isRatingsDivShown, setRatingsDivShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [remarks, setRemarks] = useState({})
  const [apiError, toggleApiError] = useState(false)
  const [isAwardPopupShown, setIsAwardPopupShown] = useState(false)
  const [isChapterOptionsShown, toggleShowChapterOptions] = useState(false)
  const isMobile = window.screen.width < 600

  const handleVellipClick = (e) => {
    e.stopPropagation()
    toggleShowChapterOptions(!isChapterOptionsShown)
  }

  const { curTime, duration, playing, setPlaying } = useAudioPlayer(audioElement)

  const handleRateClick = () => {
    get('/social/get_story_comments', {
      params: {
        story_id: audition_id,
        entity_type: 'audition'
      }
    }).then(res => {
      const remarkObj = res.result[0] || {}
      const {story_rating, background_rating, voice_rating, sound_remark, narration_remark, cm_remark} = remarkObj
      setRemarks({story_rating, background_rating, voice_rating, sound_remark, narration_remark, cm_remark})
    }).finally(() => {
      setRatingsDivShow(true)
      setIsLoading(false)
    })
  }
  const handleStarsClick = (key, rating) => {
    setRemarks({...remarks, [key]: rating})
  }
  const handleRemarkChange = (key, value) => {
    setRemarks({...remarks, [key]: value})
  }
  const handleSubmit = () => {
    const {sound_remark, narration_remark, cm_remark, story_rating, background_rating, voice_rating} = remarks
    if (!(sound_remark || narration_remark || cm_remark)) return

    let { uid } = getTokenAndUid()
    let formData = {
      sound_remark,
      narration_remark,
      cm_remark,
      comment_creator_uid: uid,
      story_id: audition_id,
      entity_type: 'audition'
    }

    if (story_rating) formData['story_rating'] = story_rating
    if (background_rating) formData['background_rating'] = background_rating
    if (voice_rating) formData['voice_rating'] = voice_rating
    
    let formDataForEntityStats = {...formData, entity_id: audition_id}
    delete formDataForEntityStats.story_id
    if (formDataForEntityStats.story_rating && formDataForEntityStats.background_rating && formDataForEntityStats.voice_rating) {
      formDataForEntityStats['avg_rating'] = ((formDataForEntityStats.story_rating + formDataForEntityStats.background_rating + formDataForEntityStats.voice_rating) / 3).toFixed(2)
    }
    const promise1 = post('/social/comment.create', {
      data: formData
    })
    const promise2 = post('/content_api/entity_stats.update', {
      data: formDataForEntityStats
    })
    Promise.all([promise1, promise2]).then(() => {
      setRatingsDivShow(false)
      successCallback()
    })
  }

  const handleAwardAudition = () => {
    if (handleAward) {
      handleAward(audition_id, () => toggleApiError(true))
      return
    }
    put('/content_api/book.award_audition', {
      data: {
        audition_id,
        is_awarded: 1
      }
    }).then(() => {
      setRatingsDivShow(false)
      successCallback()
    })
  }

  const {story_rating = 0, background_rating = 0, voice_rating = 0, sound_remark = '', narration_remark = '', cm_remark = ''} = remarks
  return (
    <>
    <Popup
      className="bottom-sheet no-padding"
      show={isChapterOptionsShown}
      close={() => toggleShowChapterOptions(false)}
    >
     {!isRatingsDivShown && (isOwner || isAdminAccess) && <ChapterOptionsBox>
        <Option onClick={handleEditClick}>
          <Text variant="title" fontWeight="r">Edit</Text>
        </Option>
        {isAdminAccess && <Option onClick={handleRateClick}>
          <Text variant="title" fontWeight="r">Edit</Text>
        </Option>}
      </ChapterOptionsBox>}
    </Popup>
    <MessagePopup
      renderIcon={<AwardIcon/>}
      show={isAwardPopupShown}
      onClose={() => setIsAwardPopupShown(false)}
      onConfirm={handleAwardAudition}
      cancelText="Cancel"
      confirmText={"Award Project"}
      messageTitle="Are You Sure?"
      messageBody={"This project will be awarded to this creator, and it will start appearing as awarded project in creator’s dashboard."}
    />
    <GenericError 
      show={apiError}
      onClose={() => toggleApiError(false)}
    />
    <Container editmode={isRatingsDivShown ? 1 : null}>
    <AuditionDiv>
      <Box variant="flex" justifyContent="flex-start">
        <Box width={[40,112]} height={[40,112]} >
          {image_url && <Image variant="avatar" width={[40,112]} height={[40,112]} src={image_url} alt="artist image"/>}
        </Box>
        <Box variant="flex" maxWidth="70%" flexDirection="column">
          <Box variant="flex" mb={4} ml={[32,8]}>
            <Text varinat="titleSecondary" fontWeight={500}>
              {fullname}
            </Text>
            {!!(total_plays || subscriber_count) && <Separator>
              &bull;
            </Separator>}
            {!!total_plays && <Text variant="caption" mr={5}>
              {total_plays} Plays,
            </Text>}
            {subscriber_count && <Text variant="caption" mr={5}>
              {subscriber_count} Followers
            </Text>}
            {!!is_approved && <AwardedBadge>Awarded</AwardedBadge>}
          </Box>
          <Text variant="caption">
            {description}
          </Text>
          <Box ml={[-24,8]} mt={[48,0]}>
            <audio ref={audioElement}>
              <source src={file_url} />
            </audio>
            <Controls>
              {playing ? 
                <PauseIcon onClick={() => setPlaying(false)}/> : 
                <PlayIcon onClick={() => setPlaying(true)}/>
              }
              {duration && (
                  <ProgressBarOuter>
                    <ProgressBarInner width={curTime / duration * 100}/>
                  </ProgressBarOuter>
                )
              }
              <Text variant="caption">
                {formatDuration(curTime)} / {formatDuration(duration)}
              </Text>
            </Controls>
          </Box>
          {!!Object.keys(ratings_stats).length && <RatingsDisplayCard>
            <IndividualRatingDisplay>
              <IndividualLabel>Sound Er</IndividualLabel>
              <IndividualRating>{ratings_stats['voice_rating'] || 'NA'}</IndividualRating>
              <SmallStar/>
            </IndividualRatingDisplay>
            <IndividualRatingDisplay>
              <IndividualLabel>Editor</IndividualLabel>
              <IndividualRating>{ratings_stats['story_rating'] || 'NA'}</IndividualRating>
              <SmallStar/>
            </IndividualRatingDisplay>
            <IndividualRatingDisplay>
              <IndividualLabel>Comm Mngr</IndividualLabel>
              <IndividualRating>{ratings_stats['background_rating'] || 'NA'}</IndividualRating>
              <SmallStar/>
            </IndividualRatingDisplay>
            <IndividualRatingDisplay>
              <IndividualLabel>Overall</IndividualLabel>
              <IndividualRating>{ratings_stats['avg_rating'] || 'NA'}</IndividualRating>
              <SmallStar/>
            </IndividualRatingDisplay>
          </RatingsDisplayCard>}
        </Box>
        {!isMobile && !isRatingsDivShown && (isOwner || isAdminAccess) && <EditButtonBox>
          <EditButton onClick={handleEditClick}>
            Edit
          </EditButton>
          {isAdminAccess && <EditButton onClick={handleRateClick}>
            Rate
          </EditButton>}
        </EditButtonBox>}
        {isMobile && !isRatingsDivShown && (isOwner || isAdminAccess) && 
          (<VellipContainer>
            <VellipIcon
              onClick={handleVellipClick}
            />
          </VellipContainer>)
        }
        {isAdminAccess && isRatingsDivShown && !is_approved && 
          <AwardButtonBox>
          <AwardButton onClick={() => setIsAwardPopupShown(true)}>
            Award Project
          </AwardButton>
          </AwardButtonBox>
        }
      </Box>
      {isLoading && <Loader/>}
      {isAdminAccess && isRatingsDivShown && (<><Box variant="hr"/>
      <RatingsTitle>
        Rate This Voice Over Sample and Give Feedback
      </RatingsTitle>
      <Box variant="flex">
        <Box width="31%">
          <Box variant="flex" mb={8}>
            <IndividualTitleSpan>Sound Quality / Sound Engineer</IndividualTitleSpan>
            <AvgRatings>{voice_rating}</AvgRatings>
            <SmallStar />
          </Box>
          <DisplayRatingsBox>
            <StarsRatingRow>
              <RatingTypeSpan>
                Voice
              </RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='voice' by='sound-er' rating={voice_rating} handleClick={rating => handleStarsClick('voice_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>
                Story
              </RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='story' by='sound-er' rating={0} handleClick={rating => handleStarsClick('story_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>
                Background
              </RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='background' by='sound-er' rating={0} handleClick={rating => handleStarsClick('background_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
          </DisplayRatingsBox>
          <RemarksTitle>Remark</RemarksTitle>
          <RemarksBox value={sound_remark} onChange={e => handleRemarkChange('sound_remark', e.target.value)} rows={3}></RemarksBox>
        </Box>
        <Narration>
          <IndividualTitle>
            <IndividualTitleSpan>Narration / Editor</IndividualTitleSpan>
            <AvgRatings>{story_rating}</AvgRatings>
            <SmallStar />
          </IndividualTitle>
          <DisplayRatingsBox>
            <StarsRatingRow>
              <RatingTypeSpan>Voice</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='voice' by='ditor' rating={0} handleClick={rating => handleStarsClick('voice_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>Story</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='story' by='ditor' rating={story_rating} handleClick={rating => handleStarsClick('story_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>Background</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='background' by='ditor' rating={0} handleClick={rating => handleStarsClick('background_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
          </DisplayRatingsBox>
          <RemarksTitle>Remark</RemarksTitle>
          <RemarksBox value={narration_remark} onChange={e => handleRemarkChange('narration_remark', e.target.value)} rows={3}></RemarksBox>
        </Narration>
        <CommunityM>
          <IndividualTitle>
            <IndividualTitleSpan>Character / Community Manager</IndividualTitleSpan>
            <AvgRatings>{background_rating}</AvgRatings>
            <SmallStar />
          </IndividualTitle>
          <DisplayRatingsBox>
            <StarsRatingRow>
              <RatingTypeSpan>Voice</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='voice' by='cm' rating={0} handleClick={rating => handleStarsClick('voice_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>Story</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='story' by='cm' rating={0} handleClick={rating => handleStarsClick('story_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
            <StarsRatingRow>
              <RatingTypeSpan>Background</RatingTypeSpan>
              <StarsHolder>
                <StarsArray type='background' by='cm' rating={background_rating} handleClick={rating => handleStarsClick('background_rating', rating)}/>
              </StarsHolder>
            </StarsRatingRow>
          </DisplayRatingsBox>
          <RemarksTitle>Remark</RemarksTitle>
          <RemarksBox value={cm_remark} onChange={e => handleRemarkChange('cm_remark', e.target.value)} rows={3}></RemarksBox>
        </CommunityM>
      </Box></>)}
    </AuditionDiv>
    {isAdminAccess && isRatingsDivShown && <ActionsButtons>
      <CancelButton onClick={() => setRatingsDivShow(false)}>Cancel</CancelButton>
      <SaveButton onClick={handleSubmit} isValid={sound_remark || narration_remark || cm_remark}>Update</SaveButton>
    </ActionsButtons>}
    </Container>
    </>
  )
}

export default AuditionCard
