import styled from 'styled-components'
import Bg from "./Images/RevenueCardDesktopBg.svg"

const Container = styled.div`
  background-color: var(--bg-alt);
  width: 100%;
  @media(max-width : 600px){
    width: 100vw;
  }
`

const WritersBenefitHeaderLinks = styled.div`

@media (max-width: 700px) {

  ${({isUserFromUS}) => isUserFromUS ? 'display:grid':''};
  ${({isUserFromUS}) => isUserFromUS ? 'grid-template-columns:1fr 1fr':''};
  ${({isUserFromUS}) => isUserFromUS ? 'width:100%':''};

}
`

const WritersBenefitHeaderLink = styled.a`
  color: inherit;
  margin: 0px 16px;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.2s;
  font-family: 'Inter', sans-serif;
`


const RewardsAndBenefits = styled.div`
  position: relative;
  font-family: 'Inter', sans-serif;
  margin-top: 0px;
  transition: all 0.2s;
  padding: 1.8rem;
  img{
    width: 100%;
  }
  strong{
    font-weight: 700;
    color: rgba(13, 21, 54, 1.0);
  }
`

const RewardsAndBenefitsHeading = styled.h2`
  transition: all 0.2s;
  color: ${({isUserFromUS}) => isUserFromUS ? '#0350A2' : '#0d1536'};
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: rgba(13, 21, 54, 0.65);
  font-family: 'Inter';
  margin-top: 10px;
`

const RewardsAndBenefitsSubHeading = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(13, 21, 54, 0.65);
  margin: 16px auto;
  font-family: 'Inter';
`

const RewardsAndBenefitsCards = styled.div`
  font-family: 'Inter';
`

const RewardsAndBenefitsTable = styled.table`
  border: 1px solid;
  width: 90%;
  line-height: 36px;
  margin: 16px auto;
  border-collapse: collapse;
  font-family: 'Inter';
  ${({isUserFromUS}) => isUserFromUS ? 'table-layout: fixed;width: max-content;' : ''};

  th,
  td {
    padding: ${({isUserFromUS}) => isUserFromUS ? '0px 16px' :'8px'};
    border: 1px solid var(--Text500);
    text-align: left;
    font-family: 'Inter';
  }
  th {
    color: ${({ theme }) => theme.colors.text.text900};
    font-weight: 700;
    line-height: 1.6;
    font-family: 'Inter';
  }
  td {
    color: var(--Text500);
    font-weight: 600;
    ${({isUserFromUS}) => isUserFromUS ? 'white-space: nowrap;':''};
    font-family: 'Inter';
  }

  tr:first-child th {
    border-top-color: transparent;
  }
  tr:last-child td {
    border-bottom-color: transparent;
  }
  tr td:first-child,
  tr th:first-child {
    border-left-color: transparent;
  }
  td{
    border-bottom-color: ${({isUserFromUS}) => isUserFromUS ? 'transparent' : 'inherit'};
  }
  tr td:last-child,
  tr th:last-child {
    border-right-color: transparent;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

`

const RewardsAndBenefitsNote = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px auto;
  font-family: 'Inter';
  p{
    font-size: 1rem;
    font-weight: 500;
    color: #0D1536;
    font-family: 'Inter';
  }
  h4{
    font-size: 1.2rem;
    font-weight: 700;
    color: #B88214;
    font-family: 'Inter';
  }
`

const RewardsAndBenefitsNoteList = styled.ol`
  margin: 0px auto;
  width: 90%;
  color: rgba(13, 21, 54, 0.65);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 200%;
  li{
    font-family: 'Inter';
  }
  @media (max-width: 600px) {
    font-size: 0.85rem;
  }
`

const HowItWorksHeading = styled.h2`
  transition: all 0.2s;
  margin-bottom: 20px;
  margin-top: 12px;
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-size: 2.7rem;
  color: rgba(13, 21, 54, 0.8);
  @media (max-width: 600px) {
    margin-top: 48px;
    margin-top: 0px;
    font-size: 6vw;
  }
`

const HowItWorksMobileCard = styled.div`
  border: 1px solid var(--Fjord500);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
  text-align: center;
  margin: 16px 3.5px;
  min-width: 88vw;
  min-height: 400px;
  transform: ${({cardIndex}) => `translateX(${-90 * cardIndex}vw)`};
  transition: transform 0.7s ease;
`

export const HowItWorksDesktopCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 28%;
  border: 1px solid var(--Fjord500);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
  text-align: center;
  margin-right: 32px;
  height: 425px;
`

const HowItWorksCardIconContainer = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 4px;
  background-color: var(--azure-300);
  color: var(--bg-alt);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  svg {
    width: 44px;
    height: 44px;
  }
`

const HowItWorksCardHeading = styled.h3`
  text-transform: uppercase;
  color: #0d1536cc;
  font-size: 18px;
  text-align: center;
  background-color: rgba(102, 205, 246, 0.15);
  border: 1px solid var(--azure-300);
  padding: 8px;
  width: 80px;
  border-radius: 4px;
  margin: 0px auto;
`

const HowItWorksCardText = styled.p`
  color: var(--Text500);
  margin: 16px auto;
  line-height: 1.5rem;
  width: 80%;
  min-height: auto;
  text-align: center;
  transition: all 0.2s;
  font-size: 0.89rem;
  @media (max-width: 600px) {
    min-height: 132px;
  }
`

const HowItWorksBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px auto;
  background: rgba(0, 0, 0, 0.3);;
  border-radius: 20px;
  max-width: 130px;
  padding: 0 8px;
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
`

const HowItWorksBtn = styled.span`
  cursor: pointer;
  width: 15px;
  height: 15px;
  transform: scale(${({ isActive }) => (isActive ? 1.4 : 1)});
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  color: ${({ isActive }) => isActive ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'};
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: none;
  border: none;
  transition: all 0.2s;
  font-size: 14px;
`

const HowItWorksCardsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  overflow-x: scroll;
  &::-webkit-scrollbar{
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  @media (max-width: 700px) {
    padding: 0;
    padding-left: 6vw;
    overflow: hidden;
  }
`

const FrequentlyAskedQuestionsHeading = styled.h2`
  color: rgba(13, 21, 54, 0.8);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 120%;
  text-align: center;
  @media (max-width: 700px) {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 120%;
    color: rgba(13, 21, 54, 0.8);
  }
`
const AccordionContainer = styled.div`
  margin: auto;
  max-width: 800px;
  transition: all 0.2s;
  color: rgba(13, 21, 54, 0.65);
  @media (max-width: 600px) {
    margin: auto;
  }
`

const Accordion = styled.div`
  margin: 16px auto;
  color: rgba(13, 21, 54, 0.65);
`

const AccordionText = styled.div`
  max-height: 0;
  overflow: hidden;
  color: var(--Text500);
  transition: max-height 0.25s ease-out;
  color: rgba(13, 21, 54, 0.65);
  &::first-letter {
    text-transform: capitalize;
  }
`

const AccordionBtn = styled.button`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: rgba(13, 21, 54, 0.7);
  &::first-letter {
    text-transform: capitalize;
  }
  &:after {
    content: '+';
    color: rgba(13, 21, 54, 0.7);
    font-size: 20px;
    margin-left: 8px;
    float: right;
  }
  &.open:after {
    content: '-';
    color: rgba(13, 21, 54, 0.7);
  }
`

export const FirstSection = styled.section`
  background: linear-gradient(180deg, #999DC5 0%, #FAE7B8 100%);
  padding: 5vw;
  font-family: 'Inter', sans-serif;
  img{
    width: 80vw;
    display: block;
    margin: auto;
    border-radius: 12px;
  }
`

export const WBHeader = styled.div`
  background-color: transparent;
  color: var(--clay-text-clay-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  font-family: 'Inter', sans-serif;
  @media (max-width: 700px) {
    flex-direction: ${({isUserFromUS}) => isUserFromUS ? 'column' : 'row'};
    align-items: ${({isUserFromUS}) => isUserFromUS ? 'flex-start' : 'center'};
  }
`

export const WBHeaderDesktop = styled.div`
  background-color: transparent;
  color: var(--clay-text-clay-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  padding: 0 8%;
`


export const AboutWBHeading = styled.h4`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 40px;
  text-align: center;
  color: rgba(13, 21, 54, 0.8);
  margin: 16px 0;
`

export const AboutWB = styled.p`
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 160%;
  color: rgba(13, 21, 54, 0.65);
  span {
    font-weight: 700;
    color: rgba(13, 21, 54, 0.9);
  }
`

export const HeadingLine = styled.h2`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 35px;
  color: white;
  text-align: center;
  margin: 25px 0;

  span{
    color: #068AE2;
  }
`

export const RevenueIncentive = styled.section`
  text-align: center;
  background-color : #CDB3D4;
  font-family: 'Inter', sans-serif;
  padding: 4% 2%;
  h2{
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: rgba(13, 21, 54, 0.8);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
  h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 200%;
    color: rgba(13, 21, 54, 0.65);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
  h6{
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 200%;
    color: rgba(13, 21, 54, 0.4);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
`

export const RevenueIncentiveDesktop = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color : #CDB3D4;
  text-align: center;
  font-family: 'Inter', sans-serif;
  padding: 4% 2%;
  h2{
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 120%;
    text-align: center;
    color: rgba(13, 21, 54, 0.8);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
  h4{
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    text-align: center;
    color: rgba(13, 21, 54, 0.65);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
  h6{
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    text-align: center;
    color: rgba(13, 21, 54, 0.4);
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }
`
export const Revenue = styled.div`
  background: #C0A3C8;
  border-radius: 16px;
  display: block;
  width: 90%;
  margin: 10px auto;
  padding: 12px 16px;
  font-family: 'Inter', sans-serif;
`
export const RevenueDesk = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #C0A3C8;
  border-radius: 16px;
  width: 90%;
  height: 30%;
  margin: 10px 0;
  padding: 12px 16px;
  overflow: hidden;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: 'Inter', sans-serif;
`

export const RevenueBgImg = styled.img`
  position: absolute;
  width: 100%;
`
export const RevenueHeading = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: rgba(13, 21, 54, 0.8);
`

export const Incentive = styled.div`
    background: rgba(192, 163, 200, 0.6);
    border-radius: 16px;
    display: block;
    width: 90%;
    padding: 12px 16px;
    margin: 10px auto;
    font-family: 'Inter', sans-serif;
  @media (max-width: 600px){
    font-family: 'Inter', sans-serif;
  }
`
export const IncentiveHeading = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: rgba(13, 21, 54, 0.8);
`
export const IncentiveSubHeading = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: rgba(13, 21, 54, 0.65);
  margin: 16px 0;
  span{
    color : #068AE2;
    font-weight: 700;
  }
`

export const SponsoredBy = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: rgba(13, 21, 54, 0.65);
`

export const RevenueCard = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px 16px;
  margin: 16px 0;
  text-align: left;
  position: relative;
  font-family: 'Inter', sans-serif;
  h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #068AE2;
    font-family: 'Inter', sans-serif;
  }
  h4{
    text-align: left;
    font-family: 'Inter', sans-serif;
  }
  img{
    width: 100%;
    margin-bottom: 8px;
    font-family: 'Inter', sans-serif;
  }
  p{
    font-size: 16px;
    line-height: 20px;
    color: #068AE2;
    text-align: left;
    font-family: 'Inter', sans-serif;
  }
  span{
    font-weight: 700;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
  }
`
export const RevenueCardDesk = styled.div`
  width:30%;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px 16px;
  margin: 16px 0;
  text-align: left;
  position: relative;
  font-family: 'Inter', sans-serif;
  h3{
    font-weight: 600;
    font-size: 2vw;
    line-height: 2.4vw;
    color: #068AE2;
    font-family: 'Inter', sans-serif;
  }
  h4{
    text-align: left;
    font-size: 1.45vw;
    margin-bottom: 14px;
    font-family: 'Inter', sans-serif;
  }
  img{
    width: 100%;
    margin-bottom: 12px;
    font-family: 'Inter', sans-serif;
  }
  p{
    font-size: 1.25vw;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
    color: #068AE2;
    text-align: left;
  }
  span{
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 2vw;
  }
`

export const HowItWorksMobile = styled.section`
  background: linear-gradient(345.07deg, #CDB3D4 3.04%, #47CACC 98.31%);
  padding: 7vw 0;
  font-family: 'Inter', sans-serif;
`

export const HowItWorksDesktop = styled.section`
  background: linear-gradient(345.07deg, #CDB3D4 3.04%, #47CACC 98.31%);
  padding: 2vw 2vw;
  font-family: 'Inter', sans-serif;
`
export const FAQs = styled.section`
  background: linear-gradient(345.92deg, rgba(208, 230, 165, 0.8) 2.63%, rgba(134, 227, 206, 0.8) 96.81%);
  padding: 5vw;
  font-family: 'Inter', sans-serif;
`
export const FAQsDesktop = styled.section`
  background: linear-gradient(345.92deg, rgba(208, 230, 165, 0.8) 2.63%, rgba(134, 227, 206, 0.8) 96.81%);
  padding: 5vw;
  font-family: 'Inter', sans-serif;
`
export const FAQSectionHead = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 200%;
  color: rgba(13, 21, 54, 0.65);
  margin: 24px auto;
  max-width: 800px;
  @media (max-width: 700px) {
    margin-top: 24px;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 200%;
    color: rgba(13, 21, 54, 0.8);
    font-family: 'Inter', sans-serif;
  }
`
export const Deadline = styled.section`
  padding: 5vw;
  background: linear-gradient(346.91deg, #CD8331 2.11%, #ECC97F 95.7%);
  font-family: 'Inter', sans-serif;
`

export const DeadlineDesk = styled.section`
  background: linear-gradient(346.91deg, #CD8331 2.11%, #ECC97F 95.7%);
  display: flex;
  font-family: 'Inter', sans-serif;
`
export const TreasureImg = styled.div`
flex: 1;
  img{
    width: 23vw;
    transform: translate(0, 50px);
    display: block;
    margin: auto;
    font-family: 'Inter', sans-serif;
  }
`
export const DeadlineInnerDiv = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 16px 4px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  h3{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: rgba(13, 21, 54, 0.8);
    font-family: 'Inter', sans-serif;
  }
  h4{
    font-weight: 500;
    font-size: 15px;
    line-height: 200%;
    color: rgba(13, 21, 54, 0.65);
    font-family: 'Inter', sans-serif;
    margin: 5px 0;
  }
`
export const DeadlineMessage = styled.div`
  background: linear-gradient(92.03deg, #FFC700 -22.56%, #BA5E0A 114.99%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 98%;
  height: 100px;
  color: #FFFFFF;
  text-align: left;
  margin: 40px auto;
  font-family: 'Inter', sans-serif;
  img{
    flex: 1;
    height: 44vw;
    transform: translate(0px, -15px);
  }
  p{
    width: 65%;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
  }
  span{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
  }
`

export const DeadlineMessageDesk = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  text-align: center;
  margin: 0px auto;
  font-family: 'Inter';
  font-style: normal;
  h3{
    font-weight: 700;
    font-size: 48px;
    line-height: 40px;
    color: rgba(13, 21, 54, 0.8);
    font-family: 'Inter', sans-serif;
  }
  h4{
    font-weight: 500;
    font-size: 24px;
    line-height: 200%;
    color: rgba(13, 21, 54, 0.65);
    font-family: 'Inter', sans-serif;
    margin: 8px 0;
  }
  p{
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
  }
  span{
    font-weight: 700;
    font-size: 28px;
    line-height: 46px;
    color: white;
    font-family: 'Inter', sans-serif;
  }
`

export const DesktopTopSection = styled.section`
  background: linear-gradient(163.39deg, #FFBE88 3.03%, #47CACC 113.41%);
  padding: 1.5% 5%;
  font-family: 'Inter', sans-serif;
`

export const TopSectionFlexedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2% 0;
  font-family: 'Inter', sans-serif;
`

export const TopSectionText = styled.div`
  width: 45%;
  font-family: 'Inter', sans-serif;
`

export const TopSectionImage = styled.img`
  width: 45%;
`

export const TopSectionVideo = styled.div`
  position: relative;
  img{
    position: absolute;
    top: -16%;
    right: -43%;
    width: 29vw;
    z-index: 1;
  }
  div{
    width: 15.5vw;
    height: 32vw;
    border-radius: 32px;
    background-image: url(${({videoThumbnail})=> videoThumbnail });
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`

export const AboutWBP = styled.div`
  background: rgba(251, 250, 250, 0.6);
  border: 1px solid #87C1AA;
  border-radius: 8px;
  padding: 24px;
  color: rgba(13, 21, 54, 0.7);
  h3{
    font-weight: 600;
    font-size: 1.9vw;
    line-height: 24px;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    color: rgba(13,21,54,0.8);
  }
  p{
    font-weight: 400;
    font-size: 1.6vw;
    line-height: 2.5vw;
    font-family: 'Inter', sans-serif;
  }
  span{
    font-weight: 700;
    color: rgba(13,21,54,0.85);
  }
`

export const DesktopHeading = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 3vw;
  line-height: 120%;
  color: white;
  margin: 16px 0;
  span{
    font-family: 'Italianno', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    line-height: 120%;
    letter-spacing: 0.32em;
    color: #FFFFFF;
  }
`
export const CreateNewButton = styled.button`
  color: white;
  outline: none;
  border: none;
  width: 100%;
  height: 64px;
  background: #206CC6;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  margin-top: 21px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
`

export const IncentiveCardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Inter', sans-serif;
`

export const CTAButtonPhone = styled.button`
  transition: all 0.2s ease;
  position: fixed;
  bottom: 3vw;
  width: ${({scrolled}) => scrolled ? '15vw' : '90vw'};
  margin: ${({scrolled}) => scrolled ? '5vw 5vw 5vw 80vw' : '5vw'};
  outline: none;
  border: none;
  height: 60px;
  color: white;
  background: #206CC6;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  font-family: 'Inter', sans-serif;
  box-shadow: 2px 2px 10px #3e3e3e;

  span {
    display: ${({scrolled}) => scrolled ? 'none' : 'auto'};
    transition: all 0.6s ease;
  }
  img{
    margin-right: ${({scrolled}) => scrolled ? '0' : '10px'};
  }
`


export {
  Container,
  WritersBenefitHeaderLinks,
  WritersBenefitHeaderLink,
  RewardsAndBenefits,
  RewardsAndBenefitsHeading,
  RewardsAndBenefitsSubHeading,
  RewardsAndBenefitsCards,
  RewardsAndBenefitsTable,
  RewardsAndBenefitsNote,
  RewardsAndBenefitsNoteList,
  HowItWorksHeading,
  HowItWorksMobileCard,
  HowItWorksCardIconContainer,
  HowItWorksCardHeading,
  HowItWorksCardText,
  HowItWorksBtnContainer,
  HowItWorksBtn,
  HowItWorksCardsContainer,
  FrequentlyAskedQuestionsHeading,
  AccordionContainer,
  Accordion,
  AccordionText,
  AccordionBtn,
}
