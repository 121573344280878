import React from 'react'
import styled from 'styled-components'
import CardGraphic from './TrophyGuyIcon'
import { mileStoneNotices } from '../constants'

const Card = styled.div`
  margin-bottom: 8px;
  background-color: var(--Dove);
  display: flex;
`
const Details = styled.div`
  padding: 24px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 32px;
`
const ActionButton = styled.div`
  border-radius: 4px;
  border: solid 2px var(--LD10);
  cursor: pointer;
  padding: 14px;
  width: 180px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
`
const ArtContainer = styled.div``

const NotificatonCard = ({ handleClick = () => null, no_button, button_text='View Application', toReplace1, toReplace2, withReplace1, withReplace2 }) => {
  return (
    <Card>
      <Details>
        <Title>{mileStoneNotices['approved'].title.replace(toReplace1, withReplace1).replace(toReplace2, withReplace2)}</Title>
        <Description>{mileStoneNotices['approved'].message.replace(toReplace1, withReplace1).replace(toReplace2, withReplace2)}</Description>
        {!no_button && <ActionButton onClick={handleClick}>{button_text}</ActionButton>}
      </Details>
      <ArtContainer>
        <CardGraphic />
      </ArtContainer>
    </Card>
  )
}
export default NotificatonCard
