import React from 'react'
import NovelForm from '../NovelForm'
import queryString from 'query-string'
import { put } from '../../../../lib/api'
import PrivatePage from '../../../../components/PrivatePage'
import { menuItemObj } from '../../../../components/PrivatePage'


function NovelAddEdit(props) {
  const {
    location: { search },
    history
  } = props
  const qParams = queryString.parse(search)

  const handleAfterSubmit = () => {
    const { publish_chapter_id, redirect_to_home } = qParams
    const { id } = props.match.params
    if (!publish_chapter_id) return
    if (publish_chapter_id) {
      put('/content_api/book.update_chapter_status', {
        data: {
          chapter_id: publish_chapter_id,
          status: 'published',
          book_id: id,
          view: 'dashboard'
        }
      }).then(() => {
        if (redirect_to_home) {
          history.push(menuItemObj.home)
        }
      })
    }

  }

  return <NovelForm {...props} afterSubmit={handleAfterSubmit} />
}

export default function WrappedNovelAddEdit () {
  return <PrivatePage>
    <NovelAddEdit />
  </PrivatePage>
}
