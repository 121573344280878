import React ,{useState,useEffect} from "react";
import { withRouter } from "react-router-dom";
import { AccountContext } from "../../providers/accountProvider";
import styled from "styled-components";
import GirlWriterIcon from "./GirlWriter.svg";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Box } from "../../designSystem";
import { FloatingInput } from "../FormikInputs";
import { Popup } from '../../components/Popup';
import OTPInput from '../OTPInput';
import { get, post } from '../../lib/api';
import Loader from '../Loader';
import StudioLogo from '../Images/StudioLogo'
import './override.css';
import { saveSession,getTokenAndUid } from '../../lib/utils'
import { isValidPhoneNumber } from 'libphonenumber-js';
import { menuItemObj } from '../../components/PrivatePage';
import WhatsappIcon from '../Images/WhatsappIcon'

const StyledWhatsappIcon = styled(WhatsappIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
`

const MainDiv = styled.div`
  background: linear-gradient(
    106deg,
    var(--storm-text-storm-700) -1%,
    #6d7ab1 108%
  );
`;
const WriterLabelDiv = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${GirlWriterIcon});
  background-position: right;
  background-repeat: no-repeat;
`;
const ProfessionalWriterDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px;
`;
const ProfessionalWriterProgramLabel = styled.label`
  width: 412px;
  height: 96px;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  @media(max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const ApplicationFormLabel = styled.label`
  width: 412px;
  height: 24px;
  font-size: 20px;
  margin: 16px 0 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  @media(max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const WriterDetailDiv = styled.div`
  padding: 48px;
`;
const PersonalDetailsLabel = styled.div`
  width: 476px;
  height: 32px;
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--strom-text-strom-700);
  @media(max-width: 600px){
    width: auto;
    height: auto;
  }
`;
const BasisDetailsToHelpUsKnowYou = styled.label`
  width: 476px;
  height: 28px;
  margin: 8px 0 16px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: var(--strom-text-strom-500);
`;
const HorizontalBar=styled.div`
width: 100%;
height: 2px;
margin: 48px 0 24px;
background-color: var(--Grey900);
`
const ContinueButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
`
const ContinueButtonDiv=styled.div`
  display:flex;
  justify-content:flex-end;
`
const OtpTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const ResendMessage = styled.span`
  color: var(--lime-700);
  font-size: 12px;
  font-style: italic;
`

const HelpText = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
  margin-bottom: 24px;
`
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`
const ResendOtp = styled.button`
  background: none;
  outline: none;
  width: 47%;
  border-radius: 4px;
  border: solid 2px #e7e8eb;
  padding: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text100);
  cursor: pointer;
`
const ResendOtpEnabled = styled(ResendOtp)`
  color: var(--Text500);
`
const WrongOtpSpan = styled.span`
  color: var(--Punch500);
  font-size: 12px;
  font-style: italic;
`
const LoginButton = styled.button `
  outline: none;
  border: none;
  padding: 14px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  width:100%;
`
const ChangeNumber = styled.a`
  color: var(--Text500);
  text-decoration: none;
  width: 47%;
  border-radius: 4px;
  border: solid 2px #e7e8eb;
  padding: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  cursor: pointer;
`
const InvalidDetailsDiv=styled.div`
 color:red;
`
const StudioImageDiv=styled.div`
display:flex;
justify-content:center;
margin-bottom: 10px;
`
const OuterDiv=styled.div`
margin:0px 200px;
background: white;
@media (max-width: 800px) {
    margin: 0px;
}
`
const OuterMainDiv=styled.div`
background-color: var(--Grey900);
height: 100vh;
`
const otpCountdownFrom = 20;
function ProfessionalWriterSignup({history, onUpdateUser}) {
  const [secondsRemaining, setSeconds] = useState(otpCountdownFrom)
  const [otpPopup,setOtpPopup]=useState(false);
  const [otpError, setOTPError] = useState(false);
  const [isOtpResent, setIsOtpResent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpProcessing, setIsOtpProcessing] = useState(false);
  const [phone, setPhone] = useState('');
  const [verifyFirebase, setVerifyFirebase] = useState(false);
  const [isOtpResendProcessing, setIsOtpResendProcessing] = useState(false);
  const [invalidDetails, setInvalidDetails] = useState('');
  const [firstName,setFirstName]=useState('');
  const [lastName,setLastName]=useState('');
  const [email,setEmail]=useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoginApiProcessing, setIsLoginApiProcessing] = useState(false)
  const [tempSessionInfo, setTempSessionInfo] = useState(null)
  const [apiError, setApiError] = useState({
    type: '',
    message: ''
  })

  useEffect(() => {
    const { uid } = getTokenAndUid()
    if (uid) {
      history.push(menuItemObj.audiobooks)
      return
    }
  }, [])
  useEffect(() => {
    if (!otpPopup) return
    if (secondsRemaining) setTimeout(() => setSeconds(secondsRemaining - 1), 1000)
  })
  const handleChangeOtp = otp => {
    setOtp(otp)
  }
  const handleOtpCheck = () => {
    // prevent multiple clicks submit
    if (isOtpProcessing) return
    if (otp.length !== 6) return
    setIsOtpProcessing(true)
    // verify number by checking otp here
    setOTPError(false)
    let phoneNumber = (phone.startsWith('+91') ? '' : '+91') + phone
    let body = {
      phone_number: phoneNumber,
      otp: otp,
    }
    if (verifyFirebase) {
      window.confirmationResult
      .confirm(otp)
      .then(() => {
        setIsOtpProcessing(false)
        handleLogin()
      })
      .catch(() => {
        setIsOtpProcessing(false)
        setApiError({ type: 'beforeVerify', message: 'Failed to verify the otp' })
      })
      setVerifyFirebase(false)
    } else {
      let otpVerifyApi = '/user_api/user.verify_otp'
      // TODO: remove this hardcoding once apis are deployed in qa and prod
      post(otpVerifyApi, {data: body})
      .then((res) => {
        setIsOtpProcessing(false)
        if (res.status === 1) {
          handleLogin()
        } else setOTPError(true)
      })
      .catch(() => {
        setIsOtpProcessing(false)
        setApiError({ type: 'beforeVerify', message: 'Failed to verify the otp' })
      })
    }
  }
  const handlePhoneOtpRequestSend = (isResendRequest) => {
    if (isOtpResendProcessing && isResendRequest) return
    setIsOtpResent(false)
    // send otp to verify number
    if (isResendRequest) {
      setIsOtpResendProcessing(true)
    }

    // let plivoSupportedCountryCodes = []
    let plivoSupportedCountryCodes = ['+91', '+880', '+234', '+1']
    let phoneNumber = (phone.startsWith('+91') ? '' : '+91') + phone
    let status = plivoSupportedCountryCodes.findIndex((countryCode) => {
      return phoneNumber.startsWith(countryCode)
    }, phoneNumber)
    if ((status === -1)) {
      // firebaseLogin(phoneNumber, isResendRequest)
    } else {
      let otpRequestApi = '/user_api/user.send_otp'
      get(otpRequestApi, {
        params: {
          phone_number: phoneNumber,
          channel: isResendRequest ? 'whatsapp' : null
        }
      })
        .then(() => {
          if (isResendRequest) {
            setIsOtpResent(true)
            setIsOtpResendProcessing(false)
          }
          setIsProcessing(false);
          setSeconds(otpCountdownFrom);
          setOtpPopup(true);
        })
        .catch(() => {
          if (isResendRequest) {
            setIsOtpResendProcessing(false)
          }
          setIsProcessing(false)
          setApiError({ type: 'beforeVerify', message: 'Failed to send otp' })
        })
    }
  }
  const handleLogin = () => {
    // call login api after number is verified
    setIsLoginApiProcessing(true)
    let phoneNumber = (phone.startsWith('+91') ? '' : '+91') + phone
    let loginData = {
      account_kit_access_token: otp,
      type: 'account_kit',
      phone_number: phoneNumber,
      view: 'dashboard',
      user_type: 'pugc',
      device_id: localStorage.getItem('uuid')
    }

    post('/user_api/user.login', { data: loginData })
      .then((response) => {
        setIsLoginApiProcessing(false)
        const { user_info = {} } = response
        let {
          uid,
          access_token: token,
          image_url: userImageUrl,
          fullname: userName,
        } = user_info
        let isUserExist = response?.already_exists

        const sessionInfo = {
          uid,
          token,
          image_url: userImageUrl,
          name: userName,
          phone: phoneNumber,
          fullname: firstName+" "+lastName,
        }
        if (typeof isUserExist == 'undefined' || isUserExist === 0) {
          // dont save sessioninfo to localstorage yet, wait for the user to register
          // if the user drops off at this point, no need to save userinfo to localstorage
          // otherwise user will be directly logged in next time user opens studio and wont be able to select account type
         // changeView('register')
         // send username to update user api
         let phoneNumber = (phone.startsWith('+91') ? '' : '+91') + phone
         let userData = {
           phone_number: phoneNumber,
           view: 'dashboard',
           fullname: firstName+" "+lastName,
           firstname:firstName,
           lastname:lastName,
           email: email
         }
     
         post('/user_api/user.update?is_novel=0', { data: userData, headers: { uid, 'access-token': token } }).then(res=>{
            history.push({
              pathname: '/writer-basic-details',
              info:sessionInfo
            });
         })
          
          //setTempSessionInfo(sessionInfo)
        } else if (isUserExist === 1) {
          saveSession(sessionInfo)
          get('/user_api/user.get_creator_details?is_novel=0').then(res => {
            onUpdateUser(res.result)
            let { account_type } = res.result
            if (account_type === 'Author') {
              history.push(menuItemObj.home)
            } else {
              history.push(menuItemObj.audiobooks)
            }
          })
        }

      }).catch((error) => {
        setIsLoginApiProcessing(false)
        console.log(error)
        setApiError({type: 'afterVerify', message: 'Something went wrong while loggin in.'})
      })
  }
  const emailValidation = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  return (
    <>
      <Popup
          show={otpPopup}
          close={setOtpPopup}
          className="otp-popup"
        >
             <>
             <StudioImageDiv>
             <StudioLogo></StudioLogo>
             </StudioImageDiv>
              <OtpTitle>
                Enter your Code {otpError && <WrongOtpSpan> - incorrect OTP</WrongOtpSpan>}
                {isOtpResent && !otpError && <ResendMessage> - OTP sent again</ResendMessage>}
              </OtpTitle>

              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                onChangeOTP={otp => handleChangeOtp(otp)}
                inputClassName={otpError ? 'error' : ''}
              />

              <LoginButton onClick={handleOtpCheck} disabled={otp.length !== 6}>
                  {isOtpProcessing ? <Loader size='sm' invertColor noPadding /> : 'continue'}
              </LoginButton>

              <HelpText>
                We send an SMS with a 6-digit code to +{phone}
              </HelpText>

              <ActionButtons>
                {secondsRemaining ? (
                  <ResendOtp>RESEND OTP in {secondsRemaining} s</ResendOtp>
                ) : (
                  <ResendOtpEnabled id='otp-handler' onClick={() => handlePhoneOtpRequestSend(true)}>
                    {isOtpResendProcessing ? <Loader size='sm' noPadding /> : (<>{'RESEND OTP on '} <StyledWhatsappIcon /></>)}
                  </ResendOtpEnabled>
                )}
                <ChangeNumber onClick={()=>setOtpPopup(false)}>EDIT NUMBER</ChangeNumber>
              </ActionButtons>
            </>
        </Popup>
        <OuterMainDiv>
        <OuterDiv>
      <MainDiv>
        <WriterLabelDiv>
          <ProfessionalWriterDiv>
            <ProfessionalWriterProgramLabel>
              Professional Writer<br></br>
              Program
            </ProfessionalWriterProgramLabel>
            <ApplicationFormLabel>Application Form</ApplicationFormLabel>
          </ProfessionalWriterDiv>
        </WriterLabelDiv>
      </MainDiv>
      <WriterDetailDiv>
        <PersonalDetailsLabel>Personal Details</PersonalDetailsLabel>
        <BasisDetailsToHelpUsKnowYou>
          Basic details to help us know you
        </BasisDetailsToHelpUsKnowYou>

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phone_number: "",
            email: "",
          }}
          validationSchema={Yup.object({})}
          onSubmit={(values, { setSubmitting }) => {
            if ((!isValidPhoneNumber('+91' + phone)) || !emailValidation() || firstName===""){
              setInvalidDetails("Invalid Details");
              return 
            }
            setInvalidDetails("");
            handlePhoneOtpRequestSend();
          }}
        >
          {({ values = {}, isSubmitting }) => (
            <Form>
              {/* <GenericError
            show={apiError}
            onClose={() => {
              toggleApiError(false)
            }}
          /> */}

              <Box
                variant="flex"
                flexDirection={["column", "row"]}
                mt={16}
                mb={16}
              >
                <Box width={["100%", "49%"]} mb={'1rem'}>
                  <FloatingInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    size="lg"
                    value={firstName}
                    onChange={text => setFirstName(text)}
                    required
                  />
                </Box>
                <Box width={["100%", "49%"]}>
                  <FloatingInput
                    type="text"
                    name="lastName"
                    label="Last Name"
                    size="lg"
                    value={lastName}
                    onChange={text => setLastName(text)}
                  />
                </Box>
              </Box>
              <Box variant="flex" flexDirection={["column", "row"]} >
                <Box width={["100%", "49%"]} mb={'1rem'} >
                  <FloatingInput
                    type="number"
                    name="phone_number"
                    label="Mobile No"
                    size="lg"
                    value={phone}
                    onChange={number => setPhone(number)}
                    required
                  />
                </Box>
                <Box width={["100%", "49%"]}>
                  <FloatingInput
                    type="text"
                    name="email"
                    label="Email Address"
                    size="lg"
                    value={email}
                    onChange={number => setEmail(number)}
                    required
                  />
                </Box>
              </Box>
             <HorizontalBar></HorizontalBar>
             {invalidDetails !=="" &&<InvalidDetailsDiv>{invalidDetails}</InvalidDetailsDiv>}
             <ContinueButtonDiv>
            <ContinueButton type="submit" >Continue</ContinueButton>
            </ContinueButtonDiv>
             {/* {isSubmitting && <ContinueButton type="button" >
                    <Loader invertColor inline size="sm" />
                </ContinueButton>} */}
            </Form>
          )}
        </Formik>
      </WriterDetailDiv>
      </OuterDiv>
      </OuterMainDiv>
    </>
  );
}

const ProfessionalWriterSignupWrapper = (props) => (
  <AccountContext.Consumer>
    {({ onUpdateUser, user }) => (
      <ProfessionalWriterSignup
        onUpdateUser={onUpdateUser}
        user={user}
        {...props}
      />
    )}
  </AccountContext.Consumer>
);

export default withRouter(ProfessionalWriterSignupWrapper);
