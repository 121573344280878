import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SubHeader from '../../../components/WithSubHeaderBG'
import {
  Tab,
  TabNameSpan,
  UnderLine,
} from '../../Shows/Audiobooks/List/AudioWriter/Audiobooks.UI'
import ApplicationHistory from './applicationHistory'
import BalanceHistory from './balanceHistory'
import { get } from '../../../lib/api'
import { getTokenAndUid } from '../../../lib/utils'
import OpenedNotifications from '../../Home/openedNotification'
import Tooltip from '../../../components/Tooltip'
import { Button, Text, Box } from '../../../designSystem'
import SpeakerIcon from '../../../components/Images/Megaphone'
import {
  MessageBody,
  MessageSpan,
  MessageHeading,
} from '../../../components/MessagePopup'
import { Popup } from '../../../components/Popup'
import PocketWriter from '../../Shows/Novels/PocketWriter'

const WriterLogo = styled(PocketWriter)`
  width: 72px;
  height: 72px;
  margin: auto;
  @media (max-width: 600px) {
    // width:40px;
    // height: 40px;
  }
`
const CTALogin = styled.div`
  background-color: #068ae2;
  cursor: pointer;
  @media (max-width: 600px) {
  }
`

const WalletDiv = styled.div`
  padding: 24px;
  background-color: var(--Dove);
  margin-bottom: 2px;
`
const TopWalletSection = styled.div`
  display: flex;
  justify-content: space-between;
`
const Anchor = styled.a`
  color: blue !important;
`
const HalfSection = styled.div``
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    justify-content: flex-end;
  }
`
const TotalEarningLabel = styled.div`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
`
const AvailableAmountLabel = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: var(--Text500);
`
const TabsHolder = styled.div`
  background-color: var(--Dove);
  padding: 16px 24px 0 24px;
  display: flex;
  height: 56px;
  background-color: var(--Dove);
  margin-bottom: 1px;
`
const PocketWriterTab = styled(TabsHolder)`
  flex-direction: column;
  height: auto;
  margin: auto;
`
const tabs = ['Application History', 'Balance History']
const isMobile = window.screen.width < 600

export const Wallet = ({
  walletDetails: { total_earnings = 0, balance = 0, withdrawable } = {},
  handleWithdrawAmount,
}) => (
  <WalletDiv>
    <TopWalletSection>
      <HalfSection>
        <Text variant="titleLarge">₹ 0</Text>
        <TotalEarningLabel>Total Income</TotalEarningLabel>
      </HalfSection>
      <RightSection>
        <AvailableAmountLabel>Available : 0</AvailableAmountLabel>
        {!isMobile && (
          <Tooltip
            title={
              !withdrawable && balance ? 'You have one pending request' : ''
            }
            arrow
          >
            {/* <WithdrawCTA enabled={balance} onClick={handleWithdrawAmount}>Withdraw Money</WithdrawCTA> */}
            <Button
              variant="semiFillPrimary"
              sizeVariant="L"
              mt={4}
              disabled={true}
              onClick={handleWithdrawAmount}
            >
              Withdraw Money
            </Button>
          </Tooltip>
        )}
      </RightSection>
    </TopWalletSection>

    {isMobile && (
      <Tooltip
        title={!withdrawable && balance ? 'You have one pending request' : ''}
        arrow
      >
        {/* <WithdrawCTA enabled={balance} onClick={handleWithdrawAmount}>Withdraw Money</WithdrawCTA> */}
        <Button
          variant="semiFillPrimary"
          sizeVariant="L"
          mt={8}
          width="100%"
          disabled={!balance}
          onClick={handleWithdrawAmount}
        >
          Withdraw Money
        </Button>
      </Tooltip>
    )}
  </WalletDiv>
)

const Income = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [walletDetails, setWalletDetails] = useState({})
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false)
  const [openedNotification, setOpenedNotification] = useState(null)

  useEffect(() => {
    fetchWalletDetails()
  }, [])

  const fetchWalletDetails = () => {
    let { uid } = getTokenAndUid()
    get(`/content_api/book.user_wallet/${uid}/`).then((response) => {
      setWalletDetails(response.result)
    })
  }
  const handleWithdrawAmount = () => {
    if (walletDetails.balance && walletDetails.total_earnings) {
      setIsOpenWithdrawal(true)
    }
  }

  const handleViewStatusClick = (program) => {
    setOpenedNotification(program)
  }

  const handleReset = () => {
    setOpenedNotification(null)
    setIsOpenWithdrawal(false)
    fetchWalletDetails()
  }

  if (openedNotification) {
    return (
      <OpenedNotifications
        program={openedNotification}
        handleReset={handleReset}
      />
    )
  }

  if (isOpenWithdrawal) {
    return (
      <Popup
        show={isOpenWithdrawal}
        close={() => setIsOpenWithdrawal(false)}
        renderActions={
          <>
            <button
              className="action-button primary-button"
              type="button"
              onClick={() => setIsOpenWithdrawal(false)}
            >
              Okay
            </button>
          </>
        }
      >
        <MessageHeading>
          <SpeakerIcon />
          <MessageSpan>Amount Withdrawal Notice</MessageSpan>
        </MessageHeading>
        <MessageBody>
          We release the payments on 7th of every month.
          <br />
          For any payment related issues, click{' '}
          <Anchor href="https://forms.gle/KS82rruST1i6jhLb6" target="_blank">
            HERE
          </Anchor>{' '}
          to raise complaint
        </MessageBody>
      </Popup>
    )
  }

  return (
    <SubHeader title="Income">
      <Box
        variant="flexCard"
        p={[16, 24]}
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
      >
        <Text variant="heading">Income</Text>
      </Box>
      <Box variant="hr" />
      <Wallet
        walletDetails={walletDetails}
        handleWithdrawAmount={handleWithdrawAmount}
      />
      {walletDetails && walletDetails.total_earnings > 0 && (
        <PocketWriterTab>
          <Box
            display="flex"
            justifyContent="space-between"
            margin="auto"
            maxWidth={500}
            flexDirection={['column', 'row']}
          >
            <Box display="flex" margin="auto" flexDirection="column">
              <WriterLogo />
              {/* <Text fontSize={[32,24]} pt={[8,16]} pb={[8,16]} color="#068ae2" fontWeight="s">Pocket Writer</Text> */}
            </Box>
            <Box p={24}>
              <Text variant="titleSecondary" textAlign="center" pt={8} pb={8}>
                We are moving the novels to our new platform Pocket
                Writer,exclusive for writers
              </Text>
              <a href="https://writer.pocketnovel.com/?referrer=Studio_Novels">
                <CTALogin>
                  <Text
                    fontSize={16}
                    p={8}
                    fontWeight="s"
                    textAlign="center"
                    color="rgba(255, 255, 255, 0.98)"
                  >
                    Click here to login
                  </Text>
                </CTALogin>
              </a>
            </Box>
          </Box>
          <Box
            backgroundColor="rgba(242, 198, 2, 0.16)"
            mt={24}
            borderRadius={4}
            m="auto"
          >
            <Text
              fontSize={16}
              p={8}
              fontWeight="s"
              textAlign="center"
              color="#ae8701"
            >
              Don’t worry! All your Income from Writer Benefit program will be
              safe
            </Text>
          </Box>
        </PocketWriterTab>
      )}
      <TabsHolder>
        {tabs.map((tab, i) => {
          return (
            <Tab
              key={tab}
              last={i === tabs.length - 1}
              active={selectedTab === i}
              onClick={() => setSelectedTab(i)}
            >
              <TabNameSpan>{tab}</TabNameSpan>
              <UnderLine active={selectedTab === i} />
            </Tab>
          )
        })}
      </TabsHolder>
      {selectedTab === 0 && (
        <ApplicationHistory onViewMoreClick={handleViewStatusClick} />
      )}
      {selectedTab === 1 && <BalanceHistory />}
    </SubHeader>
  )
}

export default Income
