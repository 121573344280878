import React, { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Popup } from '../Popup'
import Exclamation from '../Images/Exclamation'
import InfoIcon from '../Images/InfoIcon'
import './style.css'
import './override.css'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import CrossIcon from '../Images/CrossDark'
import CropIcon from '../Images/Crop'
import { get, cancellableAPI } from '../../lib/api'
import DownloadWithoutCircle from '../Images/DownloadWithoutCircle'
import { colorMapperForVoaStatus, mapForUItoBEStatus } from '../../lib/utils'

const BlueDownload = styled(DownloadWithoutCircle)`
  float: left;
`

const StatusTag = styled.div`
  text-transform: capitalize;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  background-color: ${({ bgColor }) => bgColor || 'rgba(242,198,2,0.1)'};
  color: ${({ color }) => color || 'rgba(242,198,2,0.1)'};
  border: ${({ borderColor }) =>
    `solid 1px ${borderColor}` || 'solid 1px rgba(242,198,2,0.2)'};
  padding: 6px 12px;
  display: inline-block;
  margin-left: auto;
  cursor: pointer;
`

function blobToFile(theBlob, fileName) {
  // this can be saved as a helper
  // return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type, size: 2 })
  return new File([theBlob], 'untitled.jpeg', {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  })
}

const Upload = ({
  imageUrl,
  extraNote,
  reducedLabelMargin,
  sampleFileUrl,
  onChange,
  uploadedFileMeta,
  imageValidator,
  handleDurationUpdate,
  setFileCallback = () => null,
  audioLengthValidator,
  audioDurationErrorMsg,
  dimensionErrorMsg,
  readOnly,
  hasError,
  showProgress,
  hideInputOnValue,
  cloud_dir_file,
  extraParams = {},
  label,
  bottomInfoLabel,
  fileTypeTags,
  renderComp,
  placeholder,
  infoTitle,
  maxFileSize,
  accept,
  aspectRatio = 4 / 3,
  allowImageCrop,
  potraitMode = false,
  privateDownload,
  saveWithoutCDN,
  renderCompData,
  subLabel,
  ...props
}) => {
  const [isErrorPopupOpen, toggleErrorPopup] = useState(false)
  const [isCropPopupOpen, toggleCropPopup] = useState(false)
  const [isAudioPopupOpen, toggleAudioPopup] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [audioDetectError, toggleAudioDetectError] = useState(false)
  const [file, setFile] = useState('')
  const [fileBlob, setFileBlob] = useState('')
  const [error, setError] = useState(null)
  const [progress, setProgress] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  let cancelMethodRef = useRef(() => {})

  const newHandleChange = (e) => {
    setFileUploading(true)
    readURL(e)
  }

  const handleUploadProgress = (progress) => {
    setProgress(progress * 100)
  }

  const setUrl = (url) => {
    onChange(url)
    setFileCallback()
  }

  let uploadInputRef
  const readURL = (e) => {
    setError(null)
    e.preventDefault()
    e.stopPropagation()
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setFile(file)
      let imageMimeTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp',
      ]
      let audioMimeTypes = ['audio/mpeg', 'audio/mp3', 'audio/ogg', 'audio/wav']
      let isAudio = audioMimeTypes.includes(file.type)
      let isImage = imageMimeTypes.includes(file.type)
      let isVideo = file.type === 'video/mp4'
  
      // File size and type validation
      if (file.size > maxFileSize || !accept.includes(file.type)) {
        setError(
          file.size > maxFileSize
            ? { type: 'File Size', message: 'File size exceeded' }
            : { type: 'File Type', message: 'invalid file type' }
        )
        toggleErrorPopup(true)
        setFileUploading(false)
        return
      }
  
      // Handle image files
      if (isImage) {
        handleImageFile(file)
      }
      // Handle audio and video files
      else if ((isAudio || isVideo) && accept.includes(file.type)) {
        handleAudioVideoFile(file, isAudio, isVideo)
      }
      // Handle other file types
      else if (accept.includes(file.type)) {
        uploadFile(file, isImage, isAudio, isVideo)
      }
    }
  }
  
  const handleImageFile = (file) => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      setFileBlob(reader.result)
      if (allowImageCrop) {
        setFileUploading(false)
        toggleCropPopup(true)
      } else {
        validateAndUploadImage(file, e.target.result)
      }
    }
    reader.readAsDataURL(file)
  }
  
  const validateAndUploadImage = (file, dataUrl) => {
    const image = new Image()
    image.onload = function () {
      if (imageValidator && !imageValidator(this.width, this.height)) {
        toggleErrorPopup(true)
        setError({
          type: 'image dimensions not correct',
          message: dimensionErrorMsg,
        })
        setFileUploading(false)
      } else {
        uploadFile(file, true, false, false)
      }
    }
    image.src = dataUrl
  }
  
  const handleAudioVideoFile = (file, isAudio, isVideo) => {
    // For large files, we'll use the File API instead of loading the entire file
    const url = URL.createObjectURL(file)
    const media = isAudio ? new Audio() : document.createElement('video')
    
    media.preload = 'metadata'
    media.onloadedmetadata = function() {
      URL.revokeObjectURL(url)
      const duration = media.duration
      
      if (handleDurationUpdate) {
        handleDurationUpdate(duration)
      }
      if (audioLengthValidator && !audioLengthValidator(duration)) {
        toggleErrorPopup(true)
        setError({
          type: 'Invalid Audio File',
          message: audioDurationErrorMsg,
        })
        setFileUploading(false)
      } else if (isAudio) {
        handleAudioFile(file, duration)
      } else {
        uploadFile(file, false, isAudio, isVideo)
      }
    }
    
    media.onerror = function() {
      URL.revokeObjectURL(url)
      setFileUploading(false)
      toggleAudioDetectError(true)
    }
    
    media.src = url
  }
  
  const handleAudioFile = (file, duration) => {
    if (!duration) {
      setFileUploading(false)
      toggleAudioDetectError(true)
      return
    }
    
    const audioSize = file.size / (1024 * 1024)
    if (audioSize > (duration * 3) / 60) {
      toggleAudioPopup(true)
      setFileUploading(false)
    } else {
      uploadFile(file, false, true, false)
    }
  }

  const handleClick = () => {
    uploadInputRef.value = null
  }

  const handleModalClose = () => {
    toggleErrorPopup(false)
  }

  const handleCropPopupClose = () => {
    toggleCropPopup(false)
    handleCropReset()
    handleFileReset()
  }
  const handleSaveCropped = async () => {
    const croppedImage = await getCroppedImg(
      fileBlob,
      croppedAreaPixels,
      rotation
    )
    let blob = await fetch(croppedImage).then((r) => r.blob())
    const croppedFile = blobToFile(blob, file.name)
    uploadFile(croppedFile, true)
    handleCropPopupClose()
    handleCropReset()
    handleFileReset()
  }

  const handleReUpload = () => {
    setProgress(0)
    uploadInputRef.value = null
    uploadInputRef.click()
  }

  const handleUploadDifferent = () => {
    toggleAudioPopup(false)
    toggleErrorPopup(false)
    handleCropPopupClose()
    uploadInputRef.click()
  }

  const handleCropReset = () => {
    setZoom(1)
    setRotation(0)
    setCrop({ x: 0, y: 0 })
    setCroppedAreaPixels(null)
  }
  const handleFileReset = () => {
    if (uploadInputRef) uploadInputRef.value = null
    setFile('')
    setFileBlob('')
  }
  const handleAudioPopup = () => {
    toggleAudioPopup(false)
    handleFileReset()
  }

  const handleSaveAudio = () => {
    toggleAudioPopup(false)
    uploadFile(file, false, true, false)
  }

  const handleDurationDetect = () => {
    toggleAudioDetectError(false)
    handleFileReset()
  }

  const uploadFile = (file, isImage, isAudio, isVideo) => {
    setFileUploading(true)
    let fileNameSplit = file.name.split('.')
    let ext = fileNameSplit.length > 1 ? fileNameSplit.pop() : ''
    let params = {
      // title: title || 'general',
      tags: fileTypeTags || 'file',
      image_extension: ext,
      ...extraParams,
    }
    let getPreSignedUrl = '/upload/get_presigned_url'
    get(getPreSignedUrl, { params }).then((response) => {
      let imageUrl = response.result[0]
      let awsFields = imageUrl.fields
      let prefix = imageUrl.url
      let suffix = imageUrl.s3_unique_key
      const formData = new FormData()
      formData.append('key', awsFields.key)
      formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
      formData.append('x-amz-security-token', awsFields['x-amz-security-token'])
      formData.append('policy', awsFields.policy)
      formData.append('signature', awsFields.signature)
      formData.append('file', file)

      const onUploadProgress = (progressEvent) =>
        handleUploadProgress(progressEvent.loaded / file.size)

      const cdn = isVideo
        ? 'https://d2ps1cw166f1t3.cloudfront.net'
        : isImage
        ? 'https://djhonz7dexnot.cloudfront.net'
        : isAudio
        ? 'https://dbj64m8271a9g.cloudfront.net'
        : 'https://d31b0xt3oaqqjh.cloudfront.net'
      const { post, cancelRequest } = cancellableAPI()
      cancelMethodRef.current = cancelRequest
      post(prefix, { data: formData, onUploadProgress })
        .then((response) => {
          if (saveWithoutCDN) {
            setUrl(`${cloud_dir_file ? cloud_dir_file + '/' : ''}${suffix}`)
          } else {
            setUrl(
              `${cdn}/${cloud_dir_file ? cloud_dir_file + '/' : ''}${suffix}`
            )
          }
          setFileUploading(false)
        })
        .catch((error) => {
          setFileUploading(false)
          throw error
        })
    })
  }

  const handleCancelUpload = () => {
    cancelMethodRef.current()
  }

  const InnerComp = renderComp

  return (
    <>
      {label && (
        <>
          <div
            className={`file-upload-label ${
              reducedLabelMargin ? 'reduced-margin' : ''
            }`}
          >
            <div>
              <span htmlFor={props.name}>{label}</span>
              {infoTitle && <InfoIcon title={infoTitle} />}
            </div>
            {sampleFileUrl && (
              <div className="sample-file-download">
                <a href={sampleFileUrl} target="_blank" rel="noreferrer">
                  <BlueDownload />
                  Download Template
                </a>
              </div>
            )}
            {props?.audioStatus && (
              <StatusTag {...colorMapperForVoaStatus(props?.audioStatus)}>
                {' '}
                {mapForUItoBEStatus[props?.audioStatus]}
              </StatusTag>
            )}
          </div>
          {extraNote && <div className="info-sub-title">{extraNote}</div>}
        </>
      )}
      <div className="Upload">
        <Popup
          show={isErrorPopupOpen}
          close={handleModalClose}
          renderActions={
            <>
              <button
                className="action-button"
                type="button"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                className="action-button primary"
                type="button"
                onClick={handleUploadDifferent}
              >
                Upload Different File
              </button>
            </>
          }
        >
          {error && error.type && (
            <div className="form-error">
              <Exclamation />
              <span className="title">{error.type}</span>
            </div>
          )}
          {error && error.message && <div>{error.message}</div>}
        </Popup>
        {
          <Popup
            show={isAudioPopupOpen}
            close={handleAudioPopup}
            className="crop-image-popup"
          >
            <div className="header-icons">
              <span>Audio Size Warning !</span>
              <CrossIcon onClick={handleAudioPopup} />
            </div>
            <div className="form-error">
              <Exclamation />
              <span className="title">
                The file seems to be too large for its duration, You might want
                to reduce the bitrate and try again
              </span>
            </div>
            <div className="action-buttons-holder">
              <button
                className="action-button"
                type="button"
                onClick={handleUploadDifferent}
              >
                Choose Different File
              </button>
              <button
                className="action-button primary"
                type="button"
                onClick={handleSaveAudio}
              >
                I know what i am doing
              </button>
            </div>
          </Popup>
        }
        <Popup
          show={audioDetectError}
          close={handleDurationDetect}
          className="crop-image-popup"
        >
          <div className="header-icons">
            <span>Upload Warning !</span>
            <CrossIcon onClick={handleDurationDetect} />
          </div>
          <div className="form-error">
            <Exclamation />
            <span className="title">
              Browser does not support this feature ,use Google Chrome
            </span>
          </div>
          <div className="action-buttons-holder">
            <button
              className="action-button primary"
              type="button"
              onClick={handleDurationDetect}
            >
              Okay
            </button>
          </div>
        </Popup>
        <Popup
          show={isCropPopupOpen}
          close={handleCropPopupClose}
          className="crop-image-popup"
        >
          <div className="header-icons">
            <CropIcon />
            <span>Crop Image</span>
            <CrossIcon onClick={handleCropPopupClose} />
          </div>
          <div className="image-crop-holder">
            <Cropper
              image={fileBlob}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="action-buttons-holder">
            <button
              className="action-button"
              type="button"
              onClick={handleUploadDifferent}
            >
              Choose Different File
            </button>
            <button
              className="action-button primary"
              type="button"
              onClick={handleSaveCropped}
            >
              Crop and Save
            </button>
          </div>
        </Popup>

        <div className="input">
          <input
            type="file"
            className={`${imageUrl && hideInputOnValue ? 'hidden' : ''}`}
            ref={(node) => {
              uploadInputRef = node
            }}
            accept={accept}
            disabled={readOnly}
            onClick={handleClick}
            onChange={newHandleChange}
            {...props}
          />
          <InnerComp
            title={extraParams.title}
            hasError={hasError}
            fileMeta={uploadedFileMeta}
            readOnly={readOnly}
            imageUrl={imageUrl}
            progress={progress}
            isLoading={fileUploading}
            placeholder={placeholder}
            handleReUpload={handleReUpload}
            cancelRequest={handleCancelUpload}
            privateDownload={privateDownload}
            renderCompData={renderCompData}
            subLabel={subLabel}
            showReadOption={props?.showReadOption}
            isDownloadOptionRemoved={props?.isDownloadOptionRemoved}
          />
        </div>
        {bottomInfoLabel && (
          <div className="file-upload-bottom-label">
            <InfoIcon />
            <span>{bottomInfoLabel}</span>
          </div>
        )}
      </div>
    </>
  )
}

Upload.defaultProps = {
  accept: 'image/jpeg,image/jpg,image/png,image/webp',
  maxFileSize: '20971520',
}

export default Upload
