import styled from 'styled-components'

import CrossIcon from '../../../../../../components/Images/CrossIcon'
import { Indicator } from '../../../Unpublished/Edit/addEditEpisode'

const StyledCrossIcon = styled(CrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const SectionSubTitle = styled(SectionTitle)`
  margin: 24px 0 12px 0;
`
const RangeSelectorDiv = styled.div`
  padding: 24px;
  border-radius: 4px;
  border: solid 1px var(--LD10);
  margin-bottom: 16px;
`
const RangeInputs = styled.div`
  display: flex;
  margin-bottom: 24px;
`
const FromInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
`
const ToInput = styled(FromInput)``
const Input = styled.input`
  width: 100%;
  padding: 10px 24px;
  border-radius: 4px;
  border: solid 1px var(--Grey500);
  background-color: var(--Grey100);
  font-size: 14px;
  line-height: 1.43;
  color: var(--Text300);

  &:focus {
    background-color: var(--Dove);
  }
`
const RangeInput = styled(Input)`
  width: 72px;
  padding: 12px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--Text700);
  border: ${({ error }) =>
    error ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey500)'};
`
const InputLabel = styled.label`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 8px;
  display: inline-block;
`
const AddButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
  margin-left: 6px;
`

const IndicatorPoint = styled(Indicator)`
  background-color: ${(props) => props.bgColor};
`
const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Punch500);
  margin-top: ${({ margin }) => (margin ? '15px' : '')};
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
`
const Container = styled.div`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--Dove);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  justify-content: flex-start;
`
const SerialNumber = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--Text500);
`
const Header = styled.div`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--Dove);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  justify-content: flex-start;
  gap: 15px;
`

const Status = styled.div`
  min-width: 30%;
  text-align: right;
  margin-left: auto;
`

const StatusTag = styled.div`
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  padding: 6px 12px;
  display: inline-block;
  margin-left: 10px;
`
const AddEpisodeButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
`
const TaskDescriptionDiv = styled.div`
    width: 431px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    color="var(--Text500)"
`
export {
  StyledCrossIcon,
  SectionSubTitle,
  RangeSelectorDiv,
  RangeInputs,
  ToInput,
  RangeInput,
  InputLabel,
  AddButton,
  SaveButton,
  FromInput,
  IndicatorPoint,
  DeleteButton,
  CancelButton,
  Container,
  SerialNumber,
  Header,
  Status,
  StatusTag,
  AddEpisodeButton,
  TaskDescriptionDiv,
}
