import React from 'react'
import { withRouter } from 'react-router-dom'
import NavigationDrawer from '../NavigationDrawer'
import { AccountContext } from '../../providers/accountProvider'
import { get } from '../../lib/api'
import ErrorBoundary from '../ErrorBoundary'
import { ErrorComponent } from '../ErrorPage'
import { notifyBugsnag } from '../ErrorBoundary'
import FreshChat from '../FreshChat'
import GenericError from '../GenericError'

// for menu object
// if showOnlyTo property is not present, show it to all roles, if showOnlyTo: [] -> show it to none
// if showOnlyTo: [some values], show it to all roles mentioned in array
const professionalWritersItems=[
  {
    slug: 'home',
    label: 'Home',
    link: '/home'
  },
 
]
const professionalWritersRejectedItems=[
  {
    slug: 'home',
    label: 'Home',
    link: '/home'
  },
  {
    slug: 'novels',
    label: 'Novels',
    link: '/shows/novels',
  },
]
const professionalWritersApprovedItems=[
  {
    slug: 'home',
    label: 'Home',
    link: '/home'
  },
  {
    slug: 'audiobooks',
    label: 'Audioseries',
    link: '/shows/audiobooks'
  },
]
export let menuItemsArray=[
  {
    slug: 'home',
    label: 'Home',
    link: '/home'
  },
  {
    slug: 'shows',
    label: 'Shows',
    subLabel: 'Audioseries and Novels',
    link: '/shows',
    submenu: [
      {
        slug: 'novels',
        label: 'Novels',
        link: '/shows/novels',
        showOnlyTo: ['Author', 'Publisher', 'Admin','Writing Agency'],
      },
      {
        slug: 'audiobooks',
        label: 'Audioseries',
        link: '/shows/audiobooks'
      },
      {
        slug: 'podcasts',
        label: 'Podcasts',
        link: '/shows/podcasts',
        showOnlyTo: [],
      }
    ]
  },
  {
    slug: 'analytics',
    label: 'Analytics',
    subLabel: 'Engagement and Audience',
    link: '/analytics',
    showOnlyTo: ['Admin'],
    submenu: [
      {
        slug: 'engagement',
        label: 'Engagement',
        link: '/analytics/engagement',
      },
      {
        slug: 'audience',
        label: 'Audience',
        link: '/analytics/audience',
      }
    ]
  },
  {
    slug: 'community',
    label: 'Community',
    subLabel: 'Comments and Reviews',
    link: '/community',
    submenu: [
      {
        slug: 'comments',
        label: 'Comments',
        link: '/community/comments'
      },
      {
        slug: 'reviews',
        label: 'Reviews',
        link: '/community/reviews'
      }
    ]
  },
  {
    slug: 'writers-benefit',
    label: 'Writer’s Benefit',
    link: '/writers-benefit',
  },
  {
    slug: 'income',
    label: 'Income',
    link: '/income',
    showOnlyTo: ['Author', 'Publisher','Writing Agency'],
  }
]

// Note: No two routes should have same slug, irrespective of parant

const filterItemsFromSingleLevelMenu = (childArray, userRoles) => {
  return childArray ? childArray.filter(childItem => !childItem.showOnlyTo ? true : childItem.showOnlyTo.some(grantedRole => userRoles.includes(grantedRole))) : null
}

const filterItemsFromMenu = (menuArray, roles = '') => {
  const userRoles = roles
  return menuArray.reduce((finalMenu, mainMenu) => {
    if (!mainMenu.showOnlyTo) {
      return finalMenu.concat({...mainMenu, submenu: filterItemsFromSingleLevelMenu(mainMenu.submenu, userRoles)}).filter(Boolean)
    } else {
      return mainMenu.showOnlyTo.some(grantedRole => userRoles.includes(grantedRole)) ? finalMenu.concat({...mainMenu, submenu: filterItemsFromSingleLevelMenu(mainMenu.submenu, userRoles)}).filter(Boolean) : finalMenu
    }
  }, [])
}

export let menuItemObj =menuItemsArray && menuItemsArray.reduce((obj, item) => {
  if (item.submenu) {
    item.submenu.reduce((object, sub) => {
      obj[sub.slug] = sub.link
    }, {})
  }
  obj[item.slug] = item.link
  return obj
}, {})

class PrivatePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message : '',
      apiError : false
    }
    this.handleError = this.handleError.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname || 
        this.props.location.key !== prevProps.location.key) {
      this.setState({message:'',apiError:false})
    }
    
  }

  handleError (e) {
    e.preventDefault()
    console.error(e.reason.message)
    if (e.reason.code === 401 || e.reason.code === 403) {
       this.props.history.push('/logout')
    } else if (e.reason.message === 'cancelled') {
      // do nothing
    } else {
      this.setState({message: e.reason.message || "", apiError: true})
      notifyBugsnag(e.reason.message || e, e.reason)
    }
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleError)
    get('/user_api/user.get_creator_details?is_novel=0').then(res => {
      this.props.onUpdateUser(res.result)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleError)
  }

  render () {
    const child = React.Children.only(this.props.children)
    const { location, match, history, user, isProfileNameShown } = this.props
    const newProps = { location, match, history, user }   

    let sidebarTags = null;
    if (
      this.props.user.account_type === "Author" &&
      this.props.user.professional_creator === 1 &&
      this.props.user.professional_creator_details.status === "under_review"
    ) {
      sidebarTags = professionalWritersItems;
    } else if (
      this.props.user.account_type === "Author" &&
      this.props.user.professional_creator === 1 &&
      this.props.user.professional_creator_details.status === "rejected"
    ) {
      sidebarTags = professionalWritersRejectedItems;
    } else if (
      this.props.user.account_type === "Author" &&
      this.props.user.professional_creator === 1 &&
      this.props.user.professional_creator_details.status === "approved"
    ) {
      sidebarTags = professionalWritersApprovedItems;
    } else if (
      !this.props.user.account_type
    ) {
      sidebarTags = [];
    } else {
      sidebarTags = menuItemsArray;
    }


    return (
      <>
        <GenericError message={this.state.message} show={this.state.apiError} onClose={() => this.setState({message:'', apiError: false})} />
        <FreshChat />
        <NavigationDrawer menuItemsArray={filterItemsFromMenu(sidebarTags, user.account_type)} isProfileNameShown={isProfileNameShown}>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {React.cloneElement(child, {
                ...newProps,
            })}
          </ErrorBoundary>
        </NavigationDrawer>
      </>
    )
  }
}

const PrivatePageWrapper = props => (
  <AccountContext.Consumer>
    {({ onUpdateUser, user }) => (
      <PrivatePage
        onUpdateUser={onUpdateUser}
        user={user}
        {...props}
      />
    )}
  </AccountContext.Consumer>
)

export default withRouter(PrivatePageWrapper)
