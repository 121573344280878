import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Container, Heading } from './rejectionCard'
import { ExtraNotesHeading, ExtraNote, NoteText, OkayButton, OkayButtonDiv, IconHolder } from './greetingNotification'
import { get, post } from '../../../lib/api'
import { getTokenAndUid } from '../../../lib/utils'
import Loader from '../../../components/Loader'
import ArrowRight from '../../../components/Images/ArrowRight'
import GenericError from '../../../components/GenericError'

const Wrapper = styled.div`
  // background-color: var(--Grey300);
  padding: 44px 180px;
  min-height: 100vh;

  @media (max-width: 600px) {
    padding: 0;
  }
`
const HR = styled.div`
  width: 100%;
  height: 2px;
  background-color: var(--Grey900);
  margin-top: 40px;
`
const BalanceDetails = styled.div`
  padding: 32px;
  border-radius: 4px;
  border: solid 2px var(--LD10);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.span`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
`
const Balance = styled.span`
  font-size: 40px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);  
`

const Card = ({ handleReset }) => {
  const [walletDetails, setWalletDetails] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState({
    display: false,
    title: '',
    message: ''
  })

  useEffect(() => {
    let { uid } = getTokenAndUid()
    get(`/content_api/book.user_wallet/${uid}/`).then(response => {
      setWalletDetails(response.result)
    })
  }, [])

  const handleWithDraw = () => {
    if (isSubmitting) return
    const { wallet_id } = walletDetails
    if (!wallet_id) return
    setIsSubmitting(true)
    post('/content_api/book.wallet_transaction/', {data: {
      wallet_id
    }}).then(() => {
      setIsSubmitting(false)
      handleReset()
    }).catch(error => {
      setIsSubmitting(false)
      // setError({...error, display: true, message: error.code === 400 ? error.message : ''})
      throw error
    })
  }

  return (
    <>
      {error.display && <GenericError
        show={error.display}
        onClose={() => setError({...error, display: false})}
        message={error.message}
      />}
      <Wrapper>
        <Container>
          <Heading>Request Withdrawal</Heading>
          <BalanceDetails>
            <Label>Withdrawable Balance</Label>
            <Balance>₹{walletDetails.balance || 0}</Balance>
          </BalanceDetails>
          <ExtraNotesHeading>
            Note:
          </ExtraNotesHeading>
          <ExtraNote>
            <IconHolder>
              <ArrowRight />
            </IconHolder>
            <NoteText>
              Once the withdrawl request is made you can’t cancel it
            </NoteText>
          </ExtraNote>
          <ExtraNote>
            <IconHolder>
              <ArrowRight />
            </IconHolder>
            <NoteText>
              Withdrawal request takes 2-4 days to process and additional 7-8 days to reflect in your bank account.
            </NoteText>
          </ExtraNote>
          <HR />
          <OkayButtonDiv>
            <OkayButton onClick={handleWithDraw}>
              {!isSubmitting && "Request Withdrawal"}
              {isSubmitting && <Loader size='sm' invertColor noPadding />}
            </OkayButton>
          </OkayButtonDiv>
        </Container>
      </Wrapper>
    </>
  )
}

export default Card
