import React, { useState, useEffect, useRef } from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'
import DownArrow from '../../Images/DownArrow'
import CrossIcon from '../../Images/CrossIcon'
import Tooltip from '../../Tooltip'
import { useDidUpdate } from '../../../lib/customHooks'

const Label = styled.label`
  margin-right: 6px;
`
const LabelDiv = styled.div`
  display: flex;
  align-items: center;
  font-size:  12px;
  top: 6px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
  position: absolute;
`

const Container = styled.div`
  position: relative;
`
const ErrorSpan = styled.span``
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const SelectionField = styled.div`
  height: 68px;
  // padding: 14px 16px 0 10px;
  padding: 12px 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: ${({ hasError }) =>
    hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'};
  background-color: var(--Grey300);
`
const SelectedSpan = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
`
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 78px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`
const Option = styled.div`
  text-transform: capitalize;
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);

  &:hover {
    background-color: var(--Grey500);
  }
`
const StyledDownArrow = styled(DownArrow)`
  align-self: center;
`
const StyledCrossIcon = styled(CrossIcon)`
  align-self: center;
  fill: #0d1536;
  fill-opacity: 0.65;
`

// TODO: modify this component to take options object as well as options array

const DropDownSelect = ({
  label,
  infoTitle,
  hasError,
  placeholder,
  selectOptions,
  tooltip,
  dependentOn,
  resetKey,
  idKey,
  nameKey,
  selectByName,
  ...props
}) => {
  // const { values: {book_type: type} } = useFormikContext()
  // const [type, changeType] = useState(type)
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [field, meta] = useField(props)
  const { value, onBlur, onChange } = field
  const dropDownRef = useRef()
  const selectionFieldRef = useRef()

  useDidUpdate(() => {
    newHandleChange('')
  }, [dependentOn, resetKey])

  const newHandleChange = (idOrName) => {
    // do something with this val
    toggleDropDownOpen(false)
    onChange({ target: { name: props.name, value: idOrName } })
  }

  const handleDropDownClick = (value) => {
    if (value) toggleDropDownOpen(false)
    else toggleDropDownOpen(true)
  }

  const handleClick = (e) => {
    if (
      !dropDownRef.current.contains(e.target) &&
      !selectionFieldRef.current.contains(e.target)
    ) {
      toggleDropDownOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  // const options = optionsObj[type] || []
  const options = Array.isArray(selectOptions)
    ? selectOptions
    : selectOptions[dependentOn] || []
  const Field = (
    <SelectionField ref={selectionFieldRef} hasError={hasError}>
      <LabelDiv>
        <Label>
          {label || ''}
        </Label>
        {/* {infoTitle && <InfoIcon title={infoTitle}/>} */}
      </LabelDiv>
      <SelectedSpan
        onClick={() => handleDropDownClick(value)}
        isPlaceholder={!value}
      >
        {value
          ? selectByName
            ? value
            : (options.find((option) => option[idKey] === value) || {})[
                nameKey
              ] || ''
          : placeholder || 'Select from options'}
      </SelectedSpan>
      {value ? (
        <StyledCrossIcon onClick={() => newHandleChange('')} />
      ) : (
        <StyledDownArrow onClick={() => handleDropDownClick(value)} />
      )}
    </SelectionField>
  )
  return (
    <Container>
      {/* {label && (
        <LabelContainer>
          <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan>
          <InfoIcon title={infoTitle} />
        </LabelContainer>
      )} */}
      {tooltip ? (
        <Tooltip title={tooltip.title} placement={tooltip.placement} arrow>
          {Field}
        </Tooltip>
      ) : (
        Field
      )}
      <OptionsDropDown isOpen={isOpen} ref={dropDownRef}>
        {options.map((option) => (
          <Option
            key={option[idKey]}
            onClick={() =>
              newHandleChange(selectByName ? option[nameKey] : option[idKey])
            }
          >
            {option[nameKey]}
          </Option>
        ))}
      </OptionsDropDown>
    </Container>
  )
}

export default DropDownSelect
