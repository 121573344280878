import React, { useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import WordCount from '../../../../components/Images/WordCount'
import { getDate } from '../../../../components/TextEditor/helper'
import { put } from '../../../../lib/api'
import {
  ChapterHolder,
  Container,
  InfoHolder,
  Calendar,
  ChapterCount,
  ButtonWrapper,
  EyeIcon,
  DragHandler,
} from '../styles'
import GenericError from '../../../../components/GenericError'
import MessagePopup from '../../../../components/MessagePopup'
import ReorderIcon from '../../../../components/Images/Reorder'
import DragIcon from '../../../../components/Images/DragHandle'
import { Button, Text } from '../../../../designSystem'
import { menuItemObj } from '../../../../components/PrivatePage'
import { VellipContainer, VellipIcon } from '../styles'
import { Dustbin, ChapterOptionsBox, Option } from './commonStyles'
import { Popup } from '../../../../components/Popup'

const isMobile = window.screen.width < 600

const DragHandle = SortableHandle(DragIcon)

const SortableItem = SortableElement(
  ({ chapter, index, dragHandle, ...props }) => {
    const [hoverPublished, setHoverPublished] = useState(-1)
    const [isChapterOptionsShown, toggleShowChapterOptions] = useState(false)

    const handleVellipClick = (e) => {
      e.stopPropagation()
      toggleShowChapterOptions(!isChapterOptionsShown)
    }

    const handleDeleteClick = (e, mobile) => {
      if (mobile) toggleShowChapterOptions(false)
      props.handleDelete(e, 'published', chapter.chapter_id)
    }
    return (
      <>
        <Popup
          className="bottom-sheet no-padding"
          show={isChapterOptionsShown}
          close={() => toggleShowChapterOptions(false)}
        >
          <ChapterOptionsBox>
            <Option onClick={e => handleDeleteClick(e, true)}>
              <Dustbin />
              <Text variant="title" fontWeight="r">Delete</Text>
            </Option>
          </ChapterOptionsBox>
        </Popup>
        <ChapterHolder
          key={chapter.chapter_id}
          onClick={() =>
            props.history.push(
              `${menuItemObj.novels}/edit-chapter/${props.id}?chapter_id=${chapter.chapter_id}`
            )
          }
          onMouseOver={() => setHoverPublished(index)}
          onMouseLeave={() => setHoverPublished(-1)}
        >
          {index === hoverPublished ? (
            <DragHandler>{dragHandle()}</DragHandler>
          ) : (
            <ChapterCount> {chapter.natural_sequence_number}</ChapterCount>
          )}
          <Container>
            <Text variant="titleSecondary">
              {chapter.chapter_title}
            </Text>
            <InfoHolder>
              <EyeIcon />
              <Text variant="caption" ml="4px" mr="16px">
                {chapter.total_views}
              </Text>
              <Calendar />
              <Text variant="caption" ml="4px" mr="16px">
                {getDate(chapter.create_time)}
              </Text>
              <WordCount />
              <Text variant="caption" ml="4px" mr="16px">
                {chapter.word_count}
              </Text>
            </InfoHolder>
          </Container>
          {!isMobile && <ButtonWrapper onClick={handleDeleteClick}>
            <Button variant="semiFillDanger">Delete</Button>
          </ButtonWrapper>}
          {isMobile && <VellipContainer>
            <VellipIcon
              onClick={handleVellipClick}
            />
          </VellipContainer>}
        </ChapterHolder>
      </>
    )
  }
)

const SortableList = SortableContainer(({ items, ...props }) => {
  return (
    <ul>
      {items.map((chapter, index) => (
        <SortableItem
          key={`item-${chapter.chapter_id}`}
          index={index}
          chapter={chapter}
          dragHandle={() => <DragHandle />}
          {...props}
        />
      ))}
    </ul>
  )
})

function SortableComponent(props) {
  const [moveIndexes, setMoveIndexes] = useState([0, 0])
  const [isWarningShown, setWarning] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState({})

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    setMoveIndexes([oldIndex, newIndex])
    setWarning(true)
  }

  const handleMove = () => {
    const [oldIndex, newIndex] = moveIndexes
    const { chapters, id } = props
    const EpisodeObjFrom = chapters[oldIndex]
    const EpisodeObjTo = chapters[newIndex]
    const sequenceFrom =
      EpisodeObjFrom.seq_number || EpisodeObjFrom.chapter_details.seq_number
    const sequenceTo =
      EpisodeObjTo.seq_number || EpisodeObjTo.chapter_details.seq_number
    const api = '/content_api/book.sequence_show_episodes'
    let data = {
      prev_seq: sequenceFrom,
      next_seq: sequenceTo,
    }
    data['book_id'] = id
    data['is_chapter'] = true
    data['view'] = 'dashboard'

    put(api, {
      data,
    })
      .then(() => {
        setWarning(false)
        props.updateChaptersList()
      })
      .catch((error) => {
        setWarning(false)
        setApiErrorMsg({})
        throw error
      })
  }

  return (
    <>
      <GenericError
        show={apiError}
        title={apiErrorMsg.title}
        message={apiErrorMsg.message}
        onClose={() => toggleApiError(false)}
      />
      <MessagePopup
        show={isWarningShown}
        renderIcon={<ReorderIcon />}
        onClose={() => setWarning(false)}
        onConfirm={handleMove}
        cancelText="Cancel"
        confirmText={'Change sequence'}
        messageTitle="Confirm Sequence changes?"
        messageBody={
          'Are you sure you want to change the sequence of this episodes, this will result in changing sequence of other episodes as well.'
        }
      />
      <SortableList
        items={props.chapters}
        {...props}
        lockAxis="y"
        onSortEnd={onSortEnd}
        useDragHandle={true}
        // pressDelay={200}
      />
    </>
  )
}

export { SortableComponent }
