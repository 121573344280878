import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/WithSubHeaderBG'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import CameraIcon from '../../../../components/Images/Camera'
import {
  Input,
  FloatingInput,
  Upload,
  DropDownSelect,
} from '../../../../components/FormikInputs'
import Tooltip from '../../../../components/Tooltip'
import * as Yup from 'yup'
import Loader from '../../../../components/Loader'
import {
  FormContainer,
  StepHeader,
  FlexContainer,
  FlexChildLeft,
  FlexChildRight,
  FormActionDiv,
  ArrowRight,
  ImageUploadDiv,
  ThumbnailPlaceHolder,
  UpdateText,
  OverlayDiv,
  ThumbnailUpload,
  LoaderDiv,
} from '../styles'
import { get, post } from '../../../../lib/api'
import DependentDropDown from '../../../../components/FormikInputs/DropDownSelect/DropDown'
import { Box, Button } from '../../../../designSystem'
import { menuItemObj } from '../../../../components/PrivatePage'
import NovelDescription from '../NovelDescriptionTips'

const Link = styled.div`
  color: var(--azure-500);
  font-size: 12px; 
  display: inline-block;
  cursor: pointer;
`

const ImageUploadComponent = ({ isLoading, imageUrl, placeholder }) => (
  <ImageUploadDiv>
    {isLoading && <Loader size="md" />}
    {!isLoading && imageUrl && <ThumbnailUpload src={imageUrl} alt="" />}
    {!isLoading && !imageUrl && placeholder && (
      <ThumbnailPlaceHolder>
        {placeholder || 'Click here to upload or drag your file'}
      </ThumbnailPlaceHolder>
    )}
    { !isLoading && imageUrl && (
        <OverlayDiv>
          <CameraIcon />
          <UpdateText>
            Update Cover Image
          </UpdateText>
        </OverlayDiv>
      )
    }
  </ImageUploadDiv>
)

const DescriptionComponent = () => {
  const [description, toggleDescription] = useState(false)
  return(
    <>
      <Link onClick={() => toggleDescription(true)}>&nbsp;Learn more about great descriptions!</Link>
      {description && <NovelDescription onClose={() => toggleDescription(false)} />}
    </>
  )
}


export default function NovelForm(props) {
  const [book, setBook] = useState({})

  const action = props.match.params.action

  useEffect(() => {
    if (action === 'edit-novel-details') { 
      let params = {
        uid: localStorage.getItem('uid'),
        book_id: props.match.params.id,
        view: 'dashboard',
      }
      // get('EDIT_NOVELS_API', params)
      get('/content_api/book.novel_details', { params: params })
        .then((response) => {
          setBook(response.results)
        })
        .catch((err) => {
          props.history.goBack()
          throw err
        })
    }
  }, [])

  return (
    <SubHeader
      title={
        action === 'edit-novel-details'
          ? 'Edit Novel Detail'
          : 'Create New Novel'
      }
      showBackButton={true}
    >
      {(action === 'edit-novel-details' && book.book_id) ||
      action === 'create-new-novel' ? (
        <Formik
          initialValues={{
            book_title: book['book_title'] || '',
            language: book['language'] || '',
            book_type: (book['category_obj'] || {}).book_type || 'novel_series',
            description: book['description'] || '',
            description_en: book['description_en'] || '',
            // prologue: book['prologue'] || '',
            category: (book['category_obj'] || {}).topic_id || '',
            // tags: book['tags'] || '',
            image_url: book['image_url'] || '',
            created_by: book['created_by'] || '',
            show_state: book['status'] === 'approved' ? book['is_trial'] === 1 ? 'Trial' : book['completed'] === 1 ? 'Completed' : book['is_disabled'] != 1 ? "Running" : '' : '',
            gender: book['gender_pref'] || '',
          }}
          validationSchema={Yup.object({
            book_title: Yup.string().required('Required'),
            created_by: Yup.string(),
            language: Yup.string().required('Required'),
            book_type: Yup.string().required('Required'),
            category: Yup.string().required('Required'),
            show_state: Yup.string(),
            gender: Yup.string(),
            description: Yup.string().required('Required'),
          })}
          onSubmit={(values) => {
            const formData = values
            if (formData.language === 'english') {
              formData.description_en = book.description
            }
            formData.is_novel = 1
            if (action === 'edit-novel-details') {
              formData.book_id = book.book_id
              formData.created_by = book.created_by
              formData.gender_pref = values['gender'].includes('male,female') || values['gender'].includes('female,male') ? [] : values['gender']
              formData.is_trial = book.status === 'approved' && values['show_state'] === 'Trial' ? 1 : 0
              formData.completed = book.status === 'approved' && values['show_state'] === 'Completed' ? 1 : 0
            } else {
              formData.created_by = localStorage.getItem('uid')
            }
            post('/content_api/?is_novel=0', {
              data: formData,
            })
              .then((res) => {
                if (props.afterSubmit) {
                  props.afterSubmit()
                } 
                props.history.push(
                  `${menuItemObj.novels}/id/${res.results.book_id}?tab=draft`
                )
              })
              .catch((err) => {
                props.history.goBack()
                throw err
              })
          }}
        >
          {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
            <Form autoComplete="off">
              <FormContainer>
                <StepHeader>
                  <span>Novel Details</span>
                </StepHeader>
                <Box variant="hr" my={16} />

                <FlexContainer>
                  <FlexChildLeft>
                    <Box mt={[16, 0]}>
                      <FloatingInput
                        label="Title"
                        name="book_title"
                        type="text"
                        hasError={submitCount > 0 && errors['book_title']}
                        placeholder="Example - Kitni Mohabatt hai - कितनी मोहबत्त है"
                        infoTitle="Title of the novel"
                      />
                    </Box>
                    <Box mt={16}>
                      <DropDownSelect
                        label="Language"
                        name="language"
                        selectByName
                        infoTitle="Add the language of this novel"
                        placeholder="Select a Language"
                        hasError={submitCount > 0 && errors['language']}
                        selectOptions={[
                          {id: 1, name: 'hindi' },
                          {id: 2, name: 'english'},
                          {id: 3, name: 'tamil'},
                          {id: 4, name: 'bengali'},
                          {id: 5, name: 'malayalam'},
                          {id: 6, name: 'telugu'},
                          {id: 7, name: 'marathi'},
                        ]}
                        idKey="id"
                        nameKey="name"
                      />
                    </Box>
                    
                    {/* <OptionSelect
                      label="Type"
                      name="book_type"
                      dependentOn={values['language'] || 'hindi'}
                      hasError={submitCount > 0 && errors['book_type']}
                      selectKey="topic_id"
                      infoTitle="Select the type of this novel"
                      selectOptions={[
                        {topic_id: "novel_short_story", name: "Short Story"},
                        {topic_id: "novel_series", name: "Series"}
                      ]}
                    /> */}
                    <Box mt={16}>
                      <DependentDropDown
                        label="Category"
                        name="category"
                        // dependentOn={values['book_type']}
                        dependentOn={"novel_series"}
                        infoTitle="Select the category of this novel"
                        placeholder="Choose a Category"
                        hasError={submitCount > 0 && errors['category']}
                        language={values['language']}
                        is_novel={1}
                        idKey="topic_id"
                        nameKey="topic_name"
                        tooltip={{
                          title: !values['book_type']
                            ? 'Please select a type first'
                            : '',
                          placement: 'top',
                        }}
                      />
                    </Box>
                    {/* <SearchableInput
                      label="Tags - optional"
                      name="tags"
                      selectKey="id"
                      nameKey="name"
                      infoTitle="Tags related to the novel"
                      placeholder="Select Tags"
                      options={[]}
                    /> */}
                  </FlexChildLeft>
                  <FlexChildRight>
                    <Upload
                      name="image_url"
                      placeholder="Upload a Thumnail"
                      renderComp={ImageUploadComponent}
                      dimensionErrorMsg="The image does not follow the dimensions of the thumbnail. Please try with a different image."
                      fileTypeTags="image"
                      imageValidator={(width, height) => width < height}
                      potraitMode={true}
                      extraParams={{ title: values.book_title || 'general' }}
                      allowImageCrop
                      aspectRatio={2/3}
                    />
                  </FlexChildRight>
                </FlexContainer>
                <Input
                  asTextArea
                  infoTitle="Description in language selected is required"
                  label={`Description ${values['language'] ? ' in ' + values['language'] : ''}`}
                  name="description"
                  hasError={submitCount > 0 && errors['description']}
                  placeholder={values['language'] !== 'hindi' ? "Write a description regarding this novel" :
                  'Write a description regarding this novel. Sample Description : \n"कहते हैं कि आग और पानी एक दूसरे के वजूद को मिटा देते हैं। रिशान, एक गैंगस्टर है तो वहीं विधा एक एनजीओ मे काम करने वाली बेहद खुशमिजाज लड़की है। क्या होगा जब दोनों होंगे आमने-सामने? क्या इनके बीच पनपेगा प्यार या तकरार? क्या होगी इस इश्क की कहानी? पढ़े पूरी कहानी सिर्फ Pocket FM पर। "'}
                />
                {values['language'] === 'hindi' && <DescriptionComponent />}
                {values['language'] !== 'english' && <Input
                  asTextArea
                  infoTitle="Description in English"
                  label={"Description in English"}
                  name="description_en"
                  placeholder="Write a description in english regarding this novel"
                />}
                {/* {<Input
                  asTextArea
                  infoTitle="Prologue of the book"
                  label="Prologue"
                  name="prologue"
                  hasError={submitCount > 0 && errors['prologue']}
                  placeholder="Prologue regarding this novel"
                />} */}
                <Box variant="hr" my="24px" />
                <FormActionDiv>
                  {!isSubmitting && (
                    <Tooltip
                      title={
                        isValid ? '' : 'Fill enter all the details to continue'
                      }
                      arrow
                    >
                      <Button variant="fillPrimary" width={['100%', 'unset']} rightIcon={<ArrowRight />} type="submit">
                        {action === 'edit-novel-details' ? 'Save ' : 'Continue'}
                      </Button>
                    </Tooltip>
                  )}
                  {isSubmitting && (
                    <LoaderDiv>
                      <Loader size="md" />
                    </LoaderDiv>
                  )}
                </FormActionDiv>
              </FormContainer>
              
            </Form>
          )}
        </Formik>
      ) : (
        ''
      )}
    </SubHeader>
  )
}
