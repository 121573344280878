import React, { useEffect, useState, useRef } from 'react'
import SubHeader from '../../../../components/WithSubHeaderBG'
import Dustbin from '../../../../components/Images/Dustbin'
import queryString from 'query-string'
import { Popup } from '../../../../components/Popup/index'
import GenericError from '../../../../components/GenericError'
import { SortableComponent } from './sortableNovel'
import PrivatePage from '../../../../components/PrivatePage'
import EditorArt from '../../../../components/Images/EditorArt'
import CreatorInfo from '../../../../components/CreatorInfo'
import { useCreatorProfile } from '../../../../lib/customHooks'
import DraftChapter from './draftChapter'
import { menuItemObj } from '../../../../components/PrivatePage';
import NovelProgrammatic from './Programmatic';
import {
  DeleteHeader,
  DeleteMessage,
  SingleBookDetail,
  BookDetailTopSection,
  PopupCancelButton,
  PopupDeleteDraftButton,
  ChapterContainer,
  ThumbnailDiv,
  Thumbnail,
  Tab,
  UnderLine,
  Details,
  Description,
  ArtistDetails,
  ProfileImageDiv,
  ProfileImage,
  MobileDescription,
  StyledAuthorIcon,
  VellipContainer,
  VellipIcon,
  EditBookDiv,
  EditBookSpan,
  EditButton,
  BookOptionsBox,
  Left,
  Right,
} from '../styles'
import { get, put } from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import { useInfiniteScroll } from '../../../../lib/customHooks'
import EmptyChapters from './../Empty/emptyChapter'
import ClampLines from 'react-clamp-lines'
import PencilIcon from '../../../../components/Images/PencilIcon'
import { Text, Box, Button } from '../../../../designSystem'

const tabs = [
  {
    name: 'Draft',
    count: '4',
  },
  {
    name: 'Published',
    count: '4',
  },
  {
    name: 'Trash',
    count: '4',
  },
]

function NovelDetail(props) {
  const [bookDetails, setBookDetails] = useState({})
  const [chapters, setChapters] = useState({})
  const [deleteDraft, setDeleteDraft] = useState(false)
  const [deleteTrash, setDeleteTrash] = useState(false)
  const [haveBookDetails, setHaveBookDetails] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const [apiError, toggleApiError] = useState(false)
  const [chapterStatus, setChapterStatus] = useState({
    book_id: '',
    chapter_id: '',
    status: '',
    view: 'dashboard',
  })
  const [statusTimeline, setStatusTimeline] = useState({
    entity_id: '',
    entity_name: 'chapter',
    prev_status: '',
    next_status: '',
  })
  const [chapterLoader, setChapterLoader] = useState(false)
  const [isBookOptionsShown, toggleShowBookOptions] = useState(false)
  const bookOptionsRef = useRef()
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } =
    useCreatorProfile()

  const params = queryString.parse(props.location.search)
  let id = props.match.params.id;
  let activeForApi = !params.tab ? 'published' : params.tab
  let active = params.tab || 'published'

  useEffect(() => {
    setChapterLoader(true)
    if (!haveBookDetails) {
      get('/content_api/book.novel_details', {
        params: {
          book_id: id,
          req_author: 1,
          view: 'dashboard',
        },
      })
        .then((res) => {
          setHaveBookDetails(true)
          setBookDetails(res.results)
          setShowLoader(false)
        })
        .catch((err) => {
          setShowLoader(false)
          throw err
        })
    }
    getChaptersByStatus()
  }, [activeForApi])

  const handleClick = (e) => {
    if (bookOptionsRef.current && !bookOptionsRef.current.contains(e.target)) {
      toggleShowBookOptions(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        setChapters((prevState) => ({
          chapters: prevState.chapters.concat(response.results.chapters),
        }))
        setNextUrl(response.results.next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  const getChaptersByStatus = () => {
    get('/content_api/book.chapters_by_status', {
      params: {
        page_no: 1,
        book_id: id,
        status: activeForApi,
        view: 'dashboard',
      },
    })
      .then((response) => {
        setChapters(response.results)
        setNextUrl(response.results.next_url)
        setChapterLoader(false)
      })
      .catch((err) => {
        setChapters([])
        setChapterLoader(false)
        throw err
      })
  }
  const handleDelete = (e, view = 'unset', chapter_id = '') => {
    e && e.stopPropagation()
    setDeleteDraft(!deleteDraft)
    if (view !== 'unset') {
      setChapterStatus({
        ...chapterStatus,
        book_id: id,
        chapter_id: chapter_id,
        status: 'trash',
      })
      setStatusTimeline({
        ...statusTimeline,
        entity_id: chapter_id,
        prev_status: view,
        next_status: 'trash',
      })
    }
  }

  const deleteChapter = () => {
    put('/content_api/book.update_chapter_status', {
      data: chapterStatus,
    })
      .then(() => {
        getChaptersByStatus()
        setDeleteDraft(false)
      })
  }

  const handleChapterRecover = (e, chapter_id) => {
    e.stopPropagation()
    put('/content_api/book.update_chapter_status', {
      data: {
        chapter_id: chapter_id,
        status: 'draft',
        book_id: id,
        view: 'dashboard',
      },
    })
      .then(() => {
        getChaptersByStatus()
      })
  }

  const handleTrashDelete = (e, chapter_id = null) => {
    e && e.stopPropagation()
    if (chapter_id !== null) {
      setChapterStatus({
        ...chapterStatus,
        chapter_id: chapter_id,
        book_id: id,
      })
    }
    setDeleteTrash(!deleteTrash)
  }

  const deleteTrashChapter = () => {
    get('/content_api/book.delete_chapter', {
      params: {
        chapter_id: chapterStatus.chapter_id,
        book_id: id,
        view: 'dashboard',
      },
    })
      .then(() => {
        getChaptersByStatus()
        setDeleteTrash(!deleteTrash)
      })
  }

  const handleApiError = () => {
    toggleApiError(false)
    props.history.push(`${menuItemObj.novels}?tab=published`)
  }
  const hasRequiredBookDetails =
    bookDetails.image_url &&
    bookDetails.description &&
    bookDetails.book_title &&
    bookDetails.category_obj &&
    bookDetails.category_obj.topic_id
  const shouldRedirectAfterEdit = !hasRequiredBookDetails
  const { stats = {} } = bookDetails || {}
  const { published_chapter_count } = stats
  const showUpsellDiv =
    published_chapter_count !== undefined && published_chapter_count < 5
  const isMobile = window.screen.width < 600

  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <GenericError show={apiError} onClose={() => handleApiError()} />
      {showLoader && <Loader />}
      {!showLoader && !apiError && (
        <SubHeader title="Novel Detail" showBackButton={true}>
          {bookDetails.book_id ? (
            <SingleBookDetail key={bookDetails.book_id}>
              <BookDetailTopSection>
                <ThumbnailDiv>
                  {bookDetails.image_url && (
                    <Thumbnail src={bookDetails.image_url} />
                  )}
                </ThumbnailDiv>
                <Details>
                  <Box mb="4px">
                    {/* <TitleText>
                      {bookDetails.book_title}
                    </TitleText> */}
                    <Text variant="title" >
                      {bookDetails.book_title} 
                    </Text>
                  </Box>
                  {bookDetails.category_obj.topic_name && (
                    <Box mb="4px">
                      <Text variant="subtext">
                        {bookDetails.category_obj.topic_name}
                      </Text>
                    </Box>
                  )}
                  {!isMobile && <Description>
                    <ClampLines
                      lines={2}
                      text={bookDetails.description || 'No description available'}
                      id={bookDetails.book_id}
                    />
                  </Description>}
                  {!isMobile && bookDetails.author_info && (
                    <ArtistDetails
                      onClick={() =>
                        onUserClick(bookDetails.author_info.author_id)
                      }
                    >
                      <ProfileImageDiv>
                        <ProfileImage src={bookDetails.author_info.image_url} />
                        <StyledAuthorIcon />
                      </ProfileImageDiv>
                      {/* <ArtistName> */}
                        {/* {bookDetails.author_info.fullname} */}
                        <Text variant="caption" ml="8px">
                          {bookDetails.author_info.fullname}
                        </Text>
                      {/* </ArtistName> */}
                    </ArtistDetails>
                  )}
                </Details>
                
                <VellipContainer ref={bookOptionsRef}>
                  <VellipIcon
                    onClick={() => toggleShowBookOptions(!isBookOptionsShown)}
                  />
                  <BookOptionsBox show={isBookOptionsShown}>
                    <EditBookDiv>
                      <EditButton
                        to={`${menuItemObj.novels}/edit-novel-details/${bookDetails.book_id}`}
                      >
                        <PencilIcon />
                        <EditBookSpan>Edit Novel</EditBookSpan>
                      </EditButton>
                    </EditBookDiv>
                  </BookOptionsBox>
                </VellipContainer>
              </BookDetailTopSection>

              {isMobile && <MobileDescription>
                <ClampLines
                  lines={2}
                  text={bookDetails.description || 'No description available'}
                  id={bookDetails.book_id}
                />
              </MobileDescription>}

              {isMobile && bookDetails.author_info && (
                <ArtistDetails
                  onClick={() =>
                    onUserClick(bookDetails.author_info.author_id)
                  }
                >
                  <ProfileImageDiv>
                    <ProfileImage src={bookDetails.author_info.image_url} />
                    <StyledAuthorIcon />
                  </ProfileImageDiv>
                  {/* <ArtistName> */}
                    {/* {bookDetails.author_info.fullname} */}
                    <Text variant="caption" ml="8px">
                      {bookDetails.author_info.fullname}
                    </Text>
                  {/* </ArtistName> */}
                </ArtistDetails>
              )}
            </SingleBookDetail>
          ) : (
            ''
          )}
          {showUpsellDiv && (
            <Box variant="flexCard" flexDirection={['column', 'row']} mb="8px">
              <Left>
                <Text variant="heading" mb="8px">Please publish at least 5 chapters</Text>
                <Text>
                  Publishing 5 chapters of your book increases the visibility of
                  your book, which will help it reach more readers.
                </Text>
              </Left>
              <Right>
                <EditorArt />
              </Right>
            </Box>
          )}
          {haveBookDetails && (
            <ChapterContainer>
              <Box variant="flexCard" p="16px">
                <Text variant="heading">
                  Chapters
                </Text>
                <Button variant="outlinePrimary" onClick={() =>
                    props.history.push(
                      `${menuItemObj.novels}/add-chapter/${id}?proceed_to_details=${
                        shouldRedirectAfterEdit ? '1' : ''
                      }`
                    )
                  }>
                  New Chapter
                </Button>
              </Box>
              <Box variant="hr" />
              <Box variant="flexCard" px="24px" pt="16px" justifyContent="unset">
                  {tabs.map((tab, i) => {
                    return (
                      <Tab
                        key={i}
                        last={i === tabs.length - 1}
                        active={active.toUpperCase() === tab.name.toUpperCase()}
                        onClick={() =>
                          props.history.push(
                            `${menuItemObj.novels}/id/${id}?tab=${tab.name
                              .replace(/\s/g, '-')
                              .toLowerCase()}`
                          )
                        }
                      >
                        <span>
                          {tab.name}{' '}
                          {tab.name === active && chapters.chapter_count}
                        </span>
                        <UnderLine
                          active={
                            active.toUpperCase() === tab.name.toUpperCase()
                          }
                        />
                      </Tab>
                    )
                  })}
                </Box>
              {chapterLoader ? (
                <Loader />
              ) : chapters.chapters && chapters.chapters.length === 0 ? (
                <EmptyChapters status={active} />
              ) : active === 'draft' && chapters.chapters ? (
                chapters.chapters.map((chapter, i) =>
                  chapter.status === 'draft' ? (
                    <DraftChapter
                      key={chapter.chapter_id}
                      chapter={chapter}
                      handleDraftDelete={handleDelete}
                      onClick={() =>
                        props.history.push(
                          `${menuItemObj.novels}/edit-chapter/${id}?chapter_id=${
                            chapter.chapter_id
                          }&proceed_to_details=${
                            shouldRedirectAfterEdit ? '1' : ''
                          }`
                        )
                      }
                    />
                  ) : (
                    ''
                  )
                )
              ) : (active === 'published' || active === 'chapters') && chapters.chapters ? (
                <SortableComponent
                  chapters={chapters.chapters}
                  handleDelete={handleDelete}
                  handleDeleteCMS={handleTrashDelete}
                  updateChaptersList={getChaptersByStatus}
                  id={id}
                  {...props}
                />
              ) : active === 'programmatic' && chapters.chapters ? (
                <NovelProgrammatic {...bookDetails}></NovelProgrammatic>
              ) : active === 'trash' && chapters.chapters ? (
                chapters.chapters.map(
                  (chapter, i) =>
                    chapter.status === 'trash' && (
                      <DraftChapter
                        key={chapter.chapter_id}
                        chapter={chapter}
                        handleTrashDelete={handleTrashDelete}
                        handleChapterRecover={handleChapterRecover}
                      />
                    )
                )
              ) : (
                ''
              )}

              {isFetching && <Loader />}
            </ChapterContainer>
          )}
          <Popup
            show={deleteDraft}
            close={handleDelete}
            renderActions={
              <>
                <PopupCancelButton
                  className="action-button"
                  type="button"
                  onClick={handleDelete}
                >
                  Cancel
                </PopupCancelButton>
                &nbsp;
                <PopupDeleteDraftButton
                  className="action-button"
                  type="button"
                  onClick={deleteChapter}
                >
                  Delete this chapter
                </PopupDeleteDraftButton>
              </>
            }
          >
            <Dustbin />
            <DeleteHeader>Delete this chapter?</DeleteHeader>
            <br />
            <DeleteMessage>
              Are you sure you want to delete this chapter, Deleting chapters
              moves it into the trash folder.
            </DeleteMessage>
          </Popup>
          <Popup
            show={deleteTrash}
            close={handleTrashDelete}
            renderActions={
              <>
                <PopupCancelButton
                  className="action-button"
                  type="button"
                  onClick={handleTrashDelete}
                >
                  Cancel
                </PopupCancelButton>
                &nbsp;
                <Button variant="fillDanger" onClick={deleteTrashChapter}>
                  Delete Permanently
                </Button>
              </>
            }
          >
            <Dustbin />
            <DeleteHeader>Delete this Chapter Permanently?</DeleteHeader>
            <br />
            <DeleteMessage>
              Are you sure you want to permanently delete this chapter, after
              deleting you won't be able to recover it.
            </DeleteMessage>
          </Popup>
        </SubHeader>
      )}
    </>
  )
}

export default function WrappedNovelDetail() {
  return (
    <PrivatePage>
      <NovelDetail />
    </PrivatePage>
  )
}
