import styled from 'styled-components'
import EmptyAuditionsResults from '../../../../../components/Images/EmptyAuditionsResults'
import EmptyAuditions from '../../../../../components/Images/EmptyAuditions'

const Container = styled.div`
  padding-top: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
  margin-bottom: 40px;
  flex-direction: column;
  background-color: var(--Dove);
`
const ArtContainer = styled.div`
  width: 342px;
  margin: auto;
  text-align: center;
`

const EmptyResultsIcon = styled(EmptyAuditionsResults)`
  margin-top: 5%;

  @media (max-width: 600px) {
    width: 310px;
    height: 202px;
  }
`

const EmptyIcon = styled(EmptyAuditionsResults)`
  margin-top: 5%;

  @media (max-width: 600px) {
    width: 310px;
    height: 202px;
  }
`

const StartText = styled.div`
  margin-top: 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
  text-align: center;
  max-width: 615px;
`
const DescriptionText = styled.p`
  max-width: 615px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-top: ${({ firstIndex }) => firstIndex ? '16px' : '2px'};
  text-align: center;
`
export {
  Container,
  ArtContainer,
  StartText,
  DescriptionText,
  EmptyResultsIcon,
  EmptyIcon
}
