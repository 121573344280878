import React, { useState, useEffect, useContext } from 'react'
import './override.css'
import styled from 'styled-components'
import { Popup } from '../../../components/Popup'
import CrossIcon from '../../../components/Images/CrossIcon'
import Calendar from '../Calendar'

const InnerContent = styled.div`
  width: calc(100% - 96px);
  margin: auto;
  padding-top: 56px;

  @media (max-width: 600px) {
    width: calc(100% - 32px);
    padding-top: 32px;
  }
`
const Title = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--Text700);
`
const SubText = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 24px;
`
const SubtextLink = styled(SubText)`
  color: var(--azure-500);
`
const CalenderBox = styled.div`
  width 364px;
  height: 312px;
  border-radius: 4px;
  background-color: var(--dove);
  margin-bottom: 24px;
`
const LabelBox = styled.div`
  display: flex;
  justify-content: center;
`
const LabelDesc = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
`
const GreenLabel = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--lime-100);
  margin-right: 8px;
`
const RedLabel = styled(GreenLabel)`
  background-color: var(--crimson-300);
  margin-left: 16px;
`

const StyledCrossIcon = styled(CrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
  margin-bottom: 24px;
`

const Tracker = ({ datesMissing, closeTracker, isTrackerShown }) => {
  
  return (
    <>
    <Popup
      show={isTrackerShown}
      close={closeTracker}
      className="right-drawer-tracker"
      renderCloseButton={() => <div className="close-button-tracker" onClick={closeTracker}>˟</div>}
    >
      <InnerContent>
          <StyledCrossIcon onClick={closeTracker} />
          <Title>
          Daily Upload Tracker
          </Title>
          <SubText>
          If you miss more than 4 days in a month, you won’t be eligble for the monthly rewards.
          </SubText>
          {/* <SubtextLink>Learn more</SubtextLink> */}
          <CalenderBox>
            <Calendar
              highlightedDates={datesMissing}
            />
          </CalenderBox>
          <LabelBox>
            <GreenLabel/>
            <LabelDesc>Chapter uploaded</LabelDesc>
            <RedLabel />
            <LabelDesc>Chapter missed</LabelDesc>
          </LabelBox>
        </InnerContent>
      {/* <BottomBGSection /> */}
    </Popup>
    </>
  )
}

export default Tracker
