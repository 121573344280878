import React, { useState,useContext } from 'react'
import SubHeader from '../../../../../components/WithSubHeaderBG'
// import AddIcon from '../../../../components/Images/AddIcon'
import styled from 'styled-components'
import BookList from '../../Unpublished/List'
import ShowsList from '../../Published/List'
import AuditionsList from '../../Auditions/List'
import RecordingList from '../../Recording/List'
import EmptyState from '../emptyState'
import { isAdminAccess } from '../../../../../config'
import Search from '../../../../../components/Images/Search'
import SearchDrawer from '../SearchDrawer'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button } from '../../../../../designSystem'
import ConceptList from '../../Concept/conceptList';
import { AccountContext } from '../../../../../providers/accountProvider'

const Container = styled.div``
const TabHolder = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  height: 56px;
  background-color: var(--Dove);
  @media (max-width: 600px) {
    padding: 16px 16px 0;
    // height: 50px;
    width: 100vw;
    overflow: auto;

    &::-webkit-scrollbar-thumb{
      background: transparent;
    }
  
  }
`
export const Tab = styled.div`
  margin-right: ${({ last }) => !last ? '48px' : '0'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme, active }) => active ? theme.colors.interactive.azure2 : theme.colors.text.text300};

  @media (max-width: 600px) {
    margin-right: ${({ last }) => !last ? '24px' : '0'};
  }
`
const ActionButtonDiv = styled.div`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`
export const TabNameSpan = styled.span`
  white-space: nowrap;
  padding: 7px 12px;
  margin-top: -6px;
  margin-left: -11px;
  &:hover {
    border-radius: 4px;
    background-color: #f0f1f5;
  }
  @media (max-width: 600px) {
    padding: 0;
    margin-left: 0;
    margin-top: -2px;
    &:hover {
      background-color: unset;
    }
  }
`
export const UnderLine = styled.div`
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ active }) => active ? 'var(--Azure500)' : 'none'};
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey100);
`
const componentTabMapping = {
  'Published': ShowsList,
  'Auditions': AuditionsList,
  'Unpublished': BookList,
  'Recording and Production': RecordingList,
  'Concepts':ConceptList
}

const SearchContainer = styled.div`
  margin-left: auto;
  align-self: baseline;
  display: flex;
  padding: 8px;
  margin-top: -4px;
  border-radius: 3px;
  &:hover {
    background-color: #ebecf1;
  }
`

const SearchIcon = styled(Search)`
  cursor: pointer;
`

const AudiobooksUI = ({ history, active, tabs, userType,location }) => {
  const [searchDrawerOpen, setSearchOpen] = useState(false)
  const ListingComponent = componentTabMapping[active] || (() => null);
  const { user: {account_type = 'Author',professional_creator,professional_creator_details} } = useContext(AccountContext)
  return (
    <>
      <SearchDrawer
        show={searchDrawerOpen}
        close={() => setSearchOpen(false)}
      />
    <SubHeader title='Audiobooks'>
      <Container>
        <Box variant="flexCard" px={[16, 24]} py={active === 'Unpublished' ? [16, 16] : [16, 24]} borderTopLeftRadius="4px" borderTopRightRadius="4px" height={77}>
          <Text variant="heading">
            Audioseries
          </Text>
          {/* <Button variant="outlinePrimary" onClick={() => null}>
            New Novel
          </Button> */}
          {professional_creator===1 && (professional_creator_details && professional_creator_details?.status==="approved") &&
            (
              <Button variant="outlinePrimary" pr={8} pl={8} onClick={() => history.push(`${menuItemObj.audiobooks}/new-concept?process=createNewConcept`)}>
                  New Concept
              </Button>
            )
          }
          {professional_creator!==1 && active === 'Unpublished' && 
            (
              <Button variant="outlinePrimary" pr={8} pl={8} onClick={() => history.push(`${menuItemObj.audiobooks}/new?process=createNew`)}>
                  Submit New Audiobook
              </Button>
            )
          }
          {active === 'Published' && isAdminAccess && 
            (
              <SearchContainer>
                <SearchIcon onClick={() => setSearchOpen(true)}/>
              </SearchContainer>
            )
          }
        </Box>
        <Box variant="hr" />
        <TabHolder>
          {
            tabs.map((tab, i) => {
              return (
                <Tab key={tab} last={i === tabs.length - 1} active={active.toUpperCase() === tab.toUpperCase()} onClick={() => history.push(`${menuItemObj.audiobooks}?tab=${tab.replace(/\s/g , '-').toLowerCase()}`)}>
                  <TabNameSpan>{ tab }</TabNameSpan>
                  <UnderLine  active={active.toUpperCase() === tab.toUpperCase()} />
                </Tab>
              )
            })
          }
          
        </TabHolder>
        <HR/>
        <ListingComponent history={history} location={location} renderEmptyState={
          (text, subtext,history,showButton) => <EmptyState text={text} subtext={subtext} history={history} showButton={showButton} />
        } userType={userType} />
      </Container>
    </SubHeader>
    </>
  )
}
export default AudiobooksUI
