import React from 'react'

const Clock = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path fill="#0D1536" fillOpacity=".65" fillRule="evenodd" d="M10 17.083c3.911 0 7.083-3.171 7.083-7.083 0-3.912-3.172-7.083-7.084-7.083-3.912 0-7.083 3.17-7.083 7.083 0 3.912 3.171 7.083 7.083 7.083zm0 1.25c4.602 0 8.333-3.73 8.333-8.333s-3.731-8.333-8.334-8.333c-4.602 0-8.333 3.73-8.333 8.333 0 4.602 3.731 8.333 8.333 8.333z" clipRule="evenodd"/>
    <path fill="#0D1536" fillOpacity=".65" fillRule="evenodd" d="M10 4.375c.345 0 .625.28.625.625v4.741l2.734 2.734c.244.244.244.64 0 .884-.245.244-.64.244-.884 0l-2.917-2.917c-.117-.117-.183-.276-.183-.442V5c0-.345.28-.625.625-.625z" clipRule="evenodd"/>
  </svg>
)

export default Clock
