import styled from 'styled-components'
import Vellip from '../../components/Images/Vellip'
import Pin from '../../components/Images/Pin'
import LikeBlack from '../../components/Images/LikeBlack'
import LikeOutlined from '../../components/Images/LikeOutlined'
import LoadReply from '../../components/Images/LoadReply'
import Pencil from '../../components/Images/PencilIcon'
import DustbinBlack from '../../components/Images/DustbinBlack'
import AuthorIcon from '../../components/Images/AuthorIcon'
import CreatorIcon from '../../components/Images/CreatorIcon'
import StarIcon from '../../components/Images/StarIcon'
import PlusIcon from '../../components/Images/PlusIcon'
import CrossDark from '../../components/Images/CrossDark'
import RatingIcon from '../../components/Images/RatingIcon'

const TabHolder = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  height: 56px;
  background-color: var(--Dove);
`
const FilterHolder = styled.div`
  padding: 8px 24px 8px 24px;
  display: flex;
  height: 48px;
  margin-bottom: 4px;
  margin-top: 1px;
  background-color: var(--Dove);
  position: relative;
`
const ProfileImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`
const ReplyProfileImage = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
`
const ProfileImageContainer = styled.div`
  position: relative;
`
const EntityComment = styled.div``
const Container = styled.div`
  border-radius: 4px;
  min-height: 70vh;
  background-color: var(--Dove);
  margin-top: 1px;
`
const EntityContainer = styled.div`
  border-radius: 4px;
  min-height: 70vh;
  margin-top: 1px;
  padding-bottom: 16px;
`
const HR = styled.div`
  height: 4px;
  background-color: var(--Grey900);
`
const CommentsContainer = styled.div`
  width: 70%;
`
const CommentContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-left: 24px;
  position: relative;
`
const LinkCommentToReply = styled.div`
  position: absolute;
  height: 90%;
  width: 1px;
  border: 1px solid;
  left: 18px;
  top: 40px;
  border-color: #e0e2eb;
`
const ReplyContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 16px;
  margin-left: 48px;
`
const ExtendCommentLink = styled.div`
  position: absolute;
  height: 115%;
  width: 1px;
  border: 1px solid;
  left: -6px;
  top: 12px;
  overflow: visible;
  border-color: #e0e2eb;
`
const CommentInfo = styled.span`
  height: 20px;
  margin: 0 4px 0 0;
  font-size: 12px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  display: flex;
`
const ReplyInfo = styled(CommentInfo)``
const CommentText = styled.span`
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const ReplyText = styled(CommentText)``
const CommentInfoContainer = styled.div`
  margin-left: 12px;
`
const ReplyInfoContainer = styled(CommentInfoContainer)``
const CommentHeader = styled.div``
const ReplyHeader = styled(CommentHeader)``
const CommentBody = styled.div`
  margin-top: 4px;
  overflow: hidden;
  max-width: 650px;
`
const ReplyBody = styled(CommentBody)``
const CommentFooter = styled.div`
  margin-top: 8px;
  display: flex;
`
const ReplyFooter = styled(CommentFooter)``
const LikesDiv = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 4px;
  /* positon: relative; */
  margin-right: 16px;
`
const ReplyDiv = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 16px;
  margin-top: 4px;
`
const Likes = styled.span`
  margin: 0 0 0 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
`
const Reply = styled(Likes)``

const CommentSeparator = styled.div`
  height: 1px;
  margin-top: 24px;
  margin-left: 24px;
  background-color: var(--Grey900);
`
const VellipContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  border-radius: 4px;
  :hover {
    transition: 200ms;
    background-color: var(--Grey300);
  }
`
const VellipIcon = styled(Vellip)`
  display: block;
  width: 20px;
  height: 20px;
`
const EntityDetailContainer = styled.div`
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 16px;
  display: flex;
  width: 30%;
`
const EntityNameDiv = styled.div``
const EntityName = styled.span`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const ParentEntityName = styled.span`
  font-size: 12px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
`
const EntityImageDiv = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: var(--Grey900);
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`
const EntityImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const SingleEntityContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
  flex-direction: row;
  background-color: var(--Dove);
  margin-bottom: 8px;
  border-radius: 4px;
  margin-top: 1px;
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100vw;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(13, 21, 54, 0.08);
`

const EntityDetailDiv = styled.div``
const LoadMoreComments = styled.div`
  margin-left: 48px;
  margin-top: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Azure500);
`
const AngleBottomRightContainer = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  padding: 5px 4px 3px;
  background-color: var(--Fjord500);
`
const LoadReplyContainer = styled.div`
  display: flex;
  margin-left: 48px;
  cursor: pointer;
  margin-top: 20px;
`
const Tab = styled.div`
  margin-right: ${({ last }) => (!last ? '48px' : '0')};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ active }) => (active ? 'var(--Azure500)' : 'var(--Text300)')};
`
const UnderLine = styled.div`
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ active }) => (active ? 'var(--Azure500)' : 'none')};
`
const CommentActionContainer = styled.div`
  display: ${({ showAction }) => (showAction ? 'block' : 'none')};
  position: absolute;
  border-radius: 4px;
  width: 240px;
  margin-left: 32px;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 12px 16px 0 rgba(24, 27, 37, 0.12);
  background-color: var(--Dove);
  left: 0px;
  top: 0px;
  z-index: 999;
`
const CommentAction = styled.div`
  width: 240px;
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 16px;
  :hover {
    transition: 200ms;
    background-color: var(--Grey300);
  }
`
const ActionText = styled.span`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-left: 16px;
`
const PinIcon = styled(Pin)`
  margin-left: 24px;
`
const LikeOutlinedIcon = styled(LikeOutlined)`
  margin-left: 24px;
`
const LikeOutlinedSmall = styled(LikeOutlined)`
  width: 20px;
  height: 20px;
`
const LikeBlackIcon = styled(LikeBlack)`
  margin-left: 24px;
`
const RatingYellowIcon = styled(RatingIcon)``
const PencilIcon = styled(Pencil)`
  margin-left: 24px;
`
const DustbinIcon = styled(DustbinBlack)`
  margin-left: 24px;
  width: 24px;
  height: 24px;
`
const TypeReplyDiv = styled.div`
  width: 624px;
  margin: 0 0 8px 8px;
  padding: 8px 8px 8px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey100);
  @media (max-width: 660px) {
    height: 150px;
    width: 80vw;
  }
`
const TypeReplyHere = styled.input`
  outline: None;
  caret-color: #068ae2;
  background-color: var(--Grey100);
  border: None;
  width: 100%;
  resize: None;
  margin-top: 4px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const CreateReplyContainer = styled.div`
  position: relative;
  height: 132px;
`
const TypeReplyText = styled.div`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
`
const ReplyButton = styled.button`
  cursor: pointer;
  width: 88px;
  height: 36px;
  margin: 0 0 0 8px;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
  outline: none;

  :hover {
    transition: 200ms;
    background-color: var(--Azure300);
  }
`
const CancelButton = styled.button`
  cursor: pointer;
  width: 88px;
  height: 36px;
  margin: 0 0 0 8px;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  border-radius: 4px;
  background-color: var(--Dove);
  outline: none;

  :hover {
    transition: 200ms;
    background-color: #e7e8eb;
  }
`
const ActionButtonDiv = styled.div`
  position: absolute;
  right: 0px;
`
const LoadReplyIcon = styled(LoadReply)`
  width: 16px;
  height: 16px;
`
const LoadReplyIconDiv = styled.div`
  background-color: #a4a9c1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 4px;
`
const LoadReplyText = styled.span`
  margin: 3px 0 1px 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Fjord500);
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  top: 26px;
  right: -3px;
`
const StyledCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  top: 26px;
  right: -3px;
`
const StyledStarIcon = styled(StarIcon)`
  width: 14px;
  height: 14px;
  background-color: #b88214;
  border-radius: 50%;
  top: 26px;
  right: -3px;
  position: absolute;
`
const ReplyStarIcon = styled(StarIcon)`
  position: absolute;
  top: 14px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b88214;
`
const ReplyAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  top: 14px;
  right: -3px;
  width: 10px;
  height: 10px;
`
const ReplyCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  top: 14px;
  right: -3px;
  width: 10px;
  height: 10px;
`
const CreatorNoteContainer = styled.div`
  display: flex;
  width: 92px;
  height: 21px;
  margin: 0 0 0 4px;
  padding: 2.5px 8px;
  border-radius: 4px;
  background-color: var(--Grey900);
`
const CreatorNote = styled.span`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
`
const PopupCancelButton = styled.button`
  width: 88px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
`
const PopupDeleteCommentButton = styled.button`
  width: 164px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--Punch500);
  color: #fff;
`
const DeleteHeader = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  position: relative;
  bottom: 10px;
  margin-left: 8px;
`
const DeleteMessage = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-top: 13px;
`
const LinkCommentReplyCurve = styled.div`
  width: 5px;
  height: 10px;
  border: 2px solid #e0e2eb;
  border-bottom-left-radius: 50%;
  bottom: -5px;
  position: absolute;
  left: -1px;
  border-top: none;
  border-right: none;
`
const ApplyFilterDiv = styled.div`
  cursor: pointer;
  height: 32px;
  padding: 4px 8px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  display: flex;
`
const SelectedFilterDiv = styled.div`
  max-width: 212px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 32px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  display: flex;
  margin-left: 4px;
  background-color: var(--Grey100);
`
const ApplyFilterText = styled.span`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
`
const SelectedFilterText = styled.span`
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  margin-top: 4px;
  margin-left: 10px;
`
const AddIcon = styled(PlusIcon)`
  width: 20px;
  height: 20px;
  margin-left: 4px;
`
const SelectFiltersDiv = styled.div`
  position: absolute;
  width: 330px;
  border-radius: 4px;
  background-color: var(--Dove);
  max-height: 328px;
  z-index: 9999;
  box-shadow: 0 12px 16px 0 rgba(24, 27, 37, 0.12);
`
const FilterHeaderDiv = styled.div`
  width: 304px;
  height: 40px;
  margin: 8px 8px 0 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--Grey100);
`
const FilterBody = styled.div`
  overflow: scroll;
  max-height: 220px;
  margin-top: 4px;
`
const FilterHeaderText = styled.span`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-left: 8px;
`
const CrossIcon = styled(CrossDark)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-right: 8px;
`
const FilterDiv = styled.div`
  width: 288px;
  min-height: 44px;
  display: flex;
  padding: 12px 0px 12px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 2px;
  :hover {
    transition: 200ms;
    background-color: var(--Grey300);
  }
  overflow: hidden;
`
const FilterText = styled.span`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text700);
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const CrossLightIcon = styled(CrossDark)`
  width: 20px;
  height: 20px;
  margin: 4px;
`
const TabNameSpan = styled.span`
  padding: 7px 12px;
  margin-top: -6px;
  margin-left: -11px;
  &:hover {
    border-radius: 4px;
    background-color: #f0f1f5;
  }
`
const Search = styled.input`
  width: 288px;
  height: 32px;
  margin: 8px 16px 8px 16px;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  border-radius: 4px;
  justify-content: space-between;
  border: solid 1px var(--Grey500);
  background-color: var(--Grey100);
  outline-color: var(--Azure500);
`
const FilterShowImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
`
const ShowCreatorName = styled.span`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
`
const RatingDiv = styled.div``
export {
  ShowCreatorName,
  FilterShowImage,
  Search,
  TabNameSpan,
  CrossLightIcon,
  EntityContainer,
  FilterText,
  FilterDiv,
  CrossIcon,
  FilterHeaderText,
  FilterHeaderDiv,
  SelectFiltersDiv,
  AddIcon,
  ApplyFilterText,
  ApplyFilterDiv,
  TabHolder,
  ProfileImage,
  ProfileImageContainer,
  Container,
  HR,
  CommentsContainer,
  CommentContainer,
  CommentInfo,
  CommentText,
  CommentInfoContainer,
  CommentHeader,
  CommentBody,
  CommentFooter,
  LikesDiv,
  Likes,
  ReplyDiv,
  Reply,
  FilterBody,
  CommentSeparator,
  ReplyProfileImage,
  ReplyContainer,
  ReplyInfoContainer,
  ReplyHeader,
  ReplyInfo,
  ReplyBody,
  ReplyText,
  ReplyFooter,
  VellipContainer,
  VellipIcon,
  EntityDetailContainer,
  EntityImageDiv,
  EntityImage,
  SingleEntityContainer,
  EntityNameDiv,
  EntityName,
  ParentEntityName,
  EntityDetailDiv,
  LoadMoreComments,
  AngleBottomRightContainer,
  LoadReplyContainer,
  Tab,
  UnderLine,
  CommentActionContainer,
  CommentAction,
  EntityComment,
  ActionText,
  PinIcon,
  LikeOutlinedIcon,
  LikeBlackIcon,
  TypeReplyHere,
  CreateReplyContainer,
  TypeReplyDiv,
  TypeReplyText,
  ReplyButton,
  CancelButton,
  ActionButtonDiv,
  LoadReplyIcon,
  LoadReplyText,
  LoadReplyIconDiv,
  PencilIcon,
  DustbinIcon,
  StyledAuthorIcon,
  StyledCreatorIcon,
  ReplyAuthorIcon,
  ReplyCreatorIcon,
  ReplyStarIcon,
  StyledStarIcon,
  CreatorNoteContainer,
  CreatorNote,
  LikeOutlinedSmall,
  PopupDeleteCommentButton,
  PopupCancelButton,
  DeleteHeader,
  DeleteMessage,
  LinkCommentToReply,
  ExtendCommentLink,
  LinkCommentReplyCurve,
  FilterHolder,
  SelectedFilterDiv,
  SelectedFilterText,
  Divider,
  RatingYellowIcon,
  RatingDiv,
}
