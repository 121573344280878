import React from 'react'
import styled from 'styled-components'
import WordCount from '../../../../../components/Images/WordCount'
import { Box, Text } from '../../../../../designSystem'
import { colorMapperStatus } from '../../../../../lib/utils'
import { scriptStatusShow } from './helpers'

const Container = styled.div`
  padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--Dove);
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73,83,89,0.16);
  }
  @media (max-width:600px){
    flex-direction: column;
    width : 100vw;
    align-items: inherit;
    padding: 12px 16px;
  }
`
const SNO = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--Text500);
  @media (max-width:600px){
    width: unset;
    height: unset;
    color: var(--Text300);
    font-size: 10px;
    line-height: 12px;
    padding: 0;
    margin-bottom: 8px;
    font-weight: 500;
  }
`

const WordCountIcon = styled(WordCount)`
  margin-right: 4px;
`
const Status = styled.div`
  width: 30%;
  text-align: right;

  @media (max-width:600px){
    margin-top: 10px;
    width: unset;
    text-align: left;
  }
`
const StatusTag = styled.div`
  text-transform: capitalize;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  /* color: ${({ status }) =>
    status === 'pending'
      ? 'var(--Corn800)'
      : status === 'resolved'
      ? 'var(--Azure800)'
      : status === 'approved'
      ? 'var(--Lime800)'
      : 'var(--Punch800)'};
  border: ${({ status }) =>
    status === 'pending'
      ? 'solid 1px rgba(242,198,2,0.2)'
      : status === 'resolved'
      ? 'solid 1px rgba(6, 138, 226, 0.2)'
      : status === 'approved'
      ? 'solid 1px rgba(121,186,16,0.2)'
      : 'solid 1px rgba(221, 54, 35, 0.2)'};
  background-color: ${({ status }) =>
    status === 'pending'
      ? 'rgba(242,198,2,0.1)'
      : status === 'resolved'
      ? 'rgba(6, 138, 226, 0.1)'
      : status === 'approved'
      ? 'rgba(121,186,16,0.1)'
      : 'rgba(221, 54, 35, 0.1)'}; */
  background-color: ${({ bgColor }) => bgColor || 'rgba(242,198,2,0.1)'};
  color: ${({ color }) => color || 'rgba(242,198,2,0.1)'};
  border: ${({ borderColor }) =>
    `solid 1px ${borderColor}` || 'solid 1px rgba(242,198,2,0.2)'};

  padding: 6px 12px;
  display: inline-block;
`

function SingleEpisode({
  handleOnClick,
  data: { sn, word_count, chapter_title, status } = {},
  isMobile,
}) {
  return (
    <Container onClick={handleOnClick}>
      <SNO>
        {isMobile && 'Episode '}
        {sn}
      </SNO>
      {/* {<ThumbnailDiv>
      {!!thumbnail && <Thumbnail src={thumbnail} />}
    </ThumbnailDiv>} */}
      <Box
        variant="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width="70%"
      >
        <Text variant="titleSecondary">{chapter_title}</Text>
        {word_count > 0 && (
          <Text variant="caption">
            {/* <WordCountIcon /> */}
            {word_count} words
          </Text>
        )}
      </Box>
      <Status>
        <StatusTag status={status} {...colorMapperStatus(status)}>
          {/* {status === 'pending' ? 'Under Review' : status} */}
          {scriptStatusShow(status)}
        </StatusTag>
      </Status>
    </Container>
  )}

export default SingleEpisode
