import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Loader from '../../../components/Loader'
import {
  border,
  compose,
  flexbox,
  layout,
  position,
  space,
  variant,
} from 'styled-system';
import { Box } from '../box';
import { Flex } from '../flex';
// import { Spinner } from '../spinner';
// import { focusRing } from '../sharedStyles';

const defaultSizeStyles = {
  fontSize: 'BT.M',
  lineHeight: 'BT.M',
  padding: '10px 20px',
}

const buttonSizeVariants = variant({
  prop: 'sizeVariant',
  variants: {
    M: {
      ...defaultSizeStyles,
      fontSize: 'BT.M',
      lineHeight: 'BT.M',
      padding: '10px 18px',
    },
    L: {
      ...defaultSizeStyles,
      fontSize: 'BT.L',
      lineHeight: 'BT.L',
      padding: '12px 18px',
    },
  },
})

const defaultButtonStyles = {
  position: 'relative',
  fontFamily: 'body',
  fontWeight: 'b',
  cursor: 'pointer',
  borderRadius: '4px',
  borderWidth: "2px",
  borderStyle: 'solid',
  ml: 0,
  mr: 0,
  mb: 0,
  appearance: 'none',
};

const typeVariants = {
  fillPrimary: {
    ...defaultButtonStyles,
    bg: 'interactive.azure2',
    color: 'constant.whiteMuted88',
    borderColor: 'transparent',
    '&:hover': {
      bg: 'interactive.azure3',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'interactive.silver1',
      color: 'interactive.silver2',
      borderColor: 'transparent',
    },
  },
  fillDanger: {
    ...defaultButtonStyles,
    bg: 'interactive.punch2',
    color: 'constant.whiteMuted88',
    borderColor: 'transparent',
    '&:hover': {
      bg: 'interactive.punch3',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'interactive.silver1',
      color: 'interactive.silver2',
      borderColor: 'transparent',
    },
  },
  semiFillPrimary: {
    ...defaultButtonStyles,
    bg: 'interactive.azureMuted16',
    color: 'interactive.azure3',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'interactive.azureMuted16',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'interactive.silver1',
      color: 'interactive.silver2',
      borderColor: 'transparent',
    },
  },
  semiFillDanger: {
    ...defaultButtonStyles,
    bg: 'interactive.punchMuted16',
    color: 'interactive.punch3',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'interactive.punchMuted16',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'interactive.silver1',
      color: 'interactive.silver2',
      borderColor: 'transparent',
    },
  },
  outlinePrimary: {
    ...defaultButtonStyles,
    bg: 'transparent',
    color: 'interactive.azure3',
    borderColor: 'interactive.azure2',
    '&:hover': {
      bg: 'interactive.azureMuted16',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'transparent',
      color: 'interactive.silver1',
      borderColor: 'transparent',
    },
  },
  outlineDanger: {
    ...defaultButtonStyles,
    bg: 'transparent',
    color: 'interactive.punch3',
    borderColor: 'interactive.punch2',
    '&:hover': {
      bg: 'interactive.punchMuted16',
    },
    '&:active, &:focus': {
    },
    '&:disabled': {
      bg: 'transparent',
      color: 'interactive.silver1',
      borderColor: 'transparent',
    },
  },
}

const buttonVariants = variant({
  variants: typeVariants
});

const StyledButton = styled.button`
  ${buttonVariants}
  ${buttonSizeVariants}
  ${compose(space, layout, flexbox, border, position)}
`;

const ButtonIcon = styled(Box)`
  display: inline-block;
`;

const LoaderIconWrapper = styled(Box)`
  display: flex;
`

const Wrapper = styled.div``

export const Button = forwardRef(
  (
    {
      leftIcon,
      rightIcon,
      disabled,
      isLoading,
      children,
      mb,
      mt,
      mx,
      my,
      ml,
      mr,
      type,
      ...props
    },
    ref
  ) => (
    <Wrapper>
      <StyledButton
        type={type || "button"}
        ref={ref}
        disabled={disabled}
        isLoading={isLoading}
        {...props}
        mx={mx}
        my={my}
        mb={mb}
        mt={mt}
        ml={ml}
        mr={mr}
      >
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          justifyContent="center"
        >
          {leftIcon && (
            <ButtonIcon disabled={disabled} mr={4}>
              {leftIcon}
            </ButtonIcon>
          )}
          {children}
          {rightIcon && !isLoading && (
            <ButtonIcon disabled={disabled} ml={4}>
              {rightIcon}
            </ButtonIcon>
          )}
          {!!isLoading && (
            <LoaderIconWrapper ml={4}>
              <Loader
                size={props.size === 'L' ? 'sm' : 'xs'}
                danger={['fillDanger', 'outlineDanger', 'semiFillDanger'].indexOf(props.variant) > -1}
                color={typeVariants[props.variant]['color']}
                inline
              />
            </LoaderIconWrapper>
          )}
        </Box>
      </StyledButton>
    </Wrapper>
  )
);

Button.defaultProps = {
  variant: 'fillPrimary',
  sizeVariant: 'M'
};
