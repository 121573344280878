import React from 'react'

const LikeBlack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0D1536"
      fillOpacity=".8"
      d="M4.549 6.507c-2.065 2.01-2.065 5.27 0 7.28L11.962 21l.038-.037.038.037 7.413-7.214c2.065-2.01 2.065-5.269 0-7.279-2.056-2-5.383-2.01-7.451-.027-2.068-1.982-5.395-1.973-7.451.027z"
    />
  </svg>
)

export default LikeBlack
