import React from 'react'
import ChapterAddEdit from './ChapterAddEdit'
import NovelDetail from './Details'
import NovelAddEdit from './NovelAddEdit'
import WriterPopup from './WriterPopup'

const NovelActionPage = (props) => {
  const action = props.match.params.action
  let Comp
  switch (action) {
    case 'create-new-novel':
      Comp = NovelAddEdit
      break
    case 'edit-novel-details':
      Comp = NovelAddEdit
      break
    case 'add-chapter':
      Comp = ChapterAddEdit
      break
    case 'edit-chapter':
      Comp = ChapterAddEdit
      break
    case 'id':
      Comp = NovelDetail
      break
    default:
      Comp = () => {
        return null
      }
  }
  return (
    <>
      <Comp {...props} />
      <WriterPopup />
    </>
  )
}

export default NovelActionPage
