import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ClampLines from 'react-clamp-lines'
import { useDidUpdate, useCreatorProfile, useInfiniteScroll } from '../../../../../lib/customHooks'
import SubHeader from '../../../../../components/WithSubHeaderBG'
import { get, put } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import RjGirl from '../../../../../components/Images/RjGirl'
import Arrow from '../../../../../components/Images/ArrowRight'
import AuditionCard from '../Submit/auditionCard'
import AddEditEntry from '../Submit/addEditEntry'
import { getTopicNameFromId } from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import { TNCContent, TNCPara, TNCTitle } from '../List'
import { FullScreenNotice } from '../../../../../components/Popup'
import { descriptionText } from '../../constants'
import { isAdminAccess } from '../../../../../config'
import CreatorInfo from '../../../../../components/CreatorInfo'
import PrivatePage from '../../../../../components/PrivatePage'
import moment from 'moment'
import { menuItemObj } from '../../../../../components/PrivatePage'

const BookSummary = styled.div`
  padding: 24px;
  display: flex;
  background-color: var(--Dove);
  margin-bottom: 32px;
  position: relative;
`
const BookImageDiv = styled.div`
  width: 144px;
  height: 144px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--Grey900);
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`
const BookImage = styled.img`
  width: 100%;
  border-radius: 4px;
  height: 100%;
`
const BookDetails = styled.div``

const BookTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-bottom: 12px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    line-height: 1.8;
    color: var(--Text500);
    outline: none;
  }
`
const AuthorDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ProfileImageDiv = styled.div`
  position: relative;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const AuthorName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 8px;
`
const SubmitCtaBox = styled.div`
  border-radius: 2px;
  background-color: var(--Dove);
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`
const CtaBox = styled.div`
  padding: 24px;
`
const ImageBox = styled.div``

const SubmitTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
  margin-bottom: 8px;
`
const SubmitText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-bottom: 24px;
`
const SubmitCta = styled.div`
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 16px 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  display: inline-block;

  svg {
    margin-left: 5px;
    vertical-align: bottom;
  }
`
const EntryCount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Spacer = styled.div`
  height: 80px;
`
const ViewAuditions = (props) => {
  const {
    match: {
      params: { id },
    },
    history
  } = props
  const [auditionbook, setBookDetails] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isAddEntryBoxShown, setAddEntryBoxDisplay] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [isTNCPopupShown, setTNCPopupDisplay] = useState(false)
  const [editId, setEditId] = useState(null)
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } = useCreatorProfile()
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)

  useEffect(() => {
    if (!isAdminAccess) {
      history.push(menuItemObj.audiobooks)
      return
    }
    fetchData()
  }, [])

  useDidUpdate(() => {
    setLoading(false)
  }, [auditionbook])

  const fetchData = () => {
    setLoading(true)
    const bookDetailsApi =
      '/content_api/book.book_auditions'
    get(bookDetailsApi, { params: { book_id: id } })
      .then((response) => {
        const { results } = response
        const { next_url } = results
        setBookDetails(results)
        setNextUrl(next_url)
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { results } = response
        const { next_url, auditions } = results
        setBookDetails(prevState => ({...results, auditions: prevState.auditions.concat(auditions)}))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  const successAddEntryCB = () => {
    setAddEntryBoxDisplay(false)
    fetchData()
  }

  const successEditEntryCB = () => {
    setEditMode(false)
    fetchData()
  }

  const successRatingsSubmit = () => {
    fetchData()
  }

  const handleEditClick = (id) => {
    setEditMode(true)
    setEditId(id)
  }
  const handleAssignAudition = (audition_id, errCB) => {
    put('/content_api/book.award_audition', {
      data: {
        audition_id,
        is_awarded: 1,
        is_accepted: 1
      }
    }).then(() => {
      successRatingsSubmit()
    }).catch(error => {
      errCB()
      throw(error)
    })
  }
  const {
    has_participated,
    image_url,
    book_id,
    book_title,
    category_obj: { topic_name } = {},
    description,
    auditions_count,
    auditions,
    author_info: { author_id, fullname, image_url: authorImage } = {}
  } = auditionbook || {}
  if (isLoading) {
    return (
      <div style={{ marginTop: '200px' }}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <FullScreenNotice
        show={isTNCPopupShown}
        close={() => setTNCPopupDisplay(false)}
        withCloseButton
      >
        <TNCTitle>Terms and Conditions for Company Name</TNCTitle>
        <TNCContent>
          {descriptionText.acceptAudition.map((text, i) => (
            <TNCPara key={'audition-accept-terms-' + i}>{text}</TNCPara>
          ))}
          {descriptionText.acceptConditions.map((text, i) => (
            <p key={'audition-accept-conditions-' + i}>{text}</p>
          ))}
        </TNCContent>
      </FullScreenNotice>
      <GenericError 
        show={apiError}
        onClose={() => toggleApiError(false)}
      />
      {/* {!books && (
        <BlankPaper />
      )} */}
      {auditionbook && (
        <SubHeader title="Audiobook Details" showBackButton>
        <BookSummary>
          <BookImageDiv>
            {image_url && <BookImage src={image_url} />}
          </BookImageDiv>
          <BookDetails>
            <BookTitle>
              {book_title}
              {topic_name && <CategorySpan>{topic_name}</CategorySpan>}
            </BookTitle>
            {description && <BookDescription><ClampLines lines={2} text={description} id="audition-description"/></BookDescription>}
            <AuthorDetails onClick={() => onUserClick(author_id)}>
              <ProfileImageDiv>
                <ProfileImage src={authorImage} />
                <StyledAuthorIcon />
              </ProfileImageDiv>
              <AuthorName>{fullname}</AuthorName>
            </AuthorDetails>
          </BookDetails>
        </BookSummary>
        
        {!isAddEntryBoxShown && (
          <SubmitCtaBox>
            <CtaBox>
              <SubmitTitle>Submit your Entry to Participate</SubmitTitle>
              <SubmitText>
                Once you have read the audiobook details and you are ready to submit your entry, simply click on the "Participate Now" button to participate
              </SubmitText>
              <SubmitCta onClick={() => setAddEntryBoxDisplay(true)}>
                Participate Now
                <Arrow />
              </SubmitCta>
            </CtaBox>
            <ImageBox>
              <RjGirl />
            </ImageBox>
          </SubmitCtaBox>
        )}
        {isAddEntryBoxShown && (
          <AddEditEntry
            book_id={book_id}
            successCallback={successAddEntryCB}
            cancelCallback={() => setAddEntryBoxDisplay(false)}
            book_title={book_title}
            mode="add"
            without_meta={true}
          />
        )}
        {auditions_count > 0 && <EntryCount>
          {auditions_count}{auditions_count > 1 ? ' Entries' : ' Entry'}
        </EntryCount>}
        {
          auditions.length > 0 && auditions.map((audition, index) => {
            const isThisBeingEditedByAdmin = isAdminAccess && (editId === audition.audition_id)
            if (isThisBeingEditedByAdmin && isEditMode) {
              return (
                <AddEditEntry
                  data={audition}
                  key={audition.audition_id}
                  book_id={book_id}
                  mode='edit'
                  cancelCallback={() => setEditMode(false)}
                  successCallback={successEditEntryCB}
                  book_title={book_title}
                  without_meta={true}
                />
              )
            }
            return (
              <AuditionCard data={audition} key={audition.audition_id} handleEditClick={() => handleEditClick(audition.audition_id)} successCallback={successRatingsSubmit} handleAward={handleAssignAudition}/>
            )
          })
        }
        {auditions &&
          !!auditions.length && <Spacer>
          {isFetching && <Loader />}
        </Spacer>}
      </SubHeader>
      )}
    </>
  )
}

const WrapperViewAuditions = () => (
  <PrivatePage>
    <ViewAuditions />
  </PrivatePage>
)
export default WrapperViewAuditions
