import React, { useEffect, useState } from 'react'

import Loader from '../../components/Loader'
import { Box, Text } from '../../designSystem'
import { get } from '../../lib/api'
import { Status, StatusTag, SerialNumber, Container, TaskDescriptionDiv } from '../../../src/pages/Shows/Audiobooks/Published/ShowDetails/AssetManagement/styled'
import TaskDetail from '../../components/TaskDetail'

function TaskList({ showTask = true, auditionbook }) {
  const [taskId, setTaskId] = useState('')
  const queryParams = new URLSearchParams(window.location.search)
  const showId = queryParams.get('id')
  const task_id = queryParams.get('task_id')
  const [showTaskList, setShowTaskList] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [taskList, setTaskList] = useState([])
  const fetchTaskList = () => {
    setLoading(true)
    get('/content_api/assets_management/tasks/', {
      params: {
        entity_id: showId,
      },
    })
      .then((response) => {
        setTaskList(response)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }
  useEffect(() => {
    if (task_id && showTask) {
      setTaskId(task_id)
      setShowTaskList(false)
    }
    fetchTaskList()
  }, [])
  const onTaskClick = (taskId) => {
    setTaskId(taskId)
    setShowTaskList(false)
  }

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading &&
        showTaskList &&
        taskList.map((task, index) => (
          <Container
            onClick={() => onTaskClick(task?.entity_assets_tasks_id)}
            key={index}
          >
            <SerialNumber>{index + 1}</SerialNumber>
            <Box
              variant="flex"
              flexDirection="row"
              justifyContent="space-between"
              flex="2"
              ml={[7, 0]}
            >
              <Box>
                <Text variant="titleSecondary">{task.title}</Text>
                <Box variant="flex" mt={5} justifyContent="flex-start">
                  <TaskDescriptionDiv>{task?.description}</TaskDescriptionDiv>
                </Box>
              </Box>
            </Box>

            <Status>
              <StatusTag> {task?.state}</StatusTag>
            </Status>
          </Container>
        ))}
      {!isLoading && !showTaskList && (
        <TaskDetail task_id={taskId} auditionbook={auditionbook} />
      )}
    </>
  )
}

export default TaskList
