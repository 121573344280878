import React from 'react'

const CropIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#0D1536" fillOpacity=".8" d="M7.455 2v14.546H22v1.818h-3.636V22h-1.819v-3.636h-9.09c-1.005 0-1.819-.814-1.819-1.819v-9.09H2V5.636h3.636V2h1.819z"/>
    <path fill="#0D1536" fillOpacity=".8" d="M18.364 14.727h-1.818V7.455H9.273V5.636h7.273c1.004 0 1.818.814 1.818 1.819v7.272z"/>
  </svg>
)

export default CropIcon
