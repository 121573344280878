import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from '../../../lib/api'
import Loader from '../../../components/Loader'
import { Text, Box } from '../../../designSystem'
import { useInfiniteScroll } from '../../../lib/customHooks'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const Card = styled.div`
  background-color: var(--Dove);
  padding: 16px 18px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const BalanceHistory = () => {
  const [trxnArr, setTrxnArr] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)

  // useEffect(() => {
    // fetchBalanceHistory()
  // }, [])

  // const fetchBalanceHistory = () => {
  //   let { uid } = getTokenAndUid()
  //   get(`/content_api/book.user_wallet/${uid}/`).then((response) => {
  //     let { result, next_url } = response
  //     setNextUrl(next_url)
  //     let { wallet_id } = result
  //     if (wallet_id) {
  //       get(`/content_api/book.wallet_transaction/${wallet_id}/`).then(
  //         (response) => {
  //           const { result, next_url } = response
  //           setNextUrl(next_url)
  //           setTrxnArr(result)
  //         }
  //       )
  //     }
  //   })
  // }
  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result, next_url } = response
        setTrxnArr((prevState) => prevState.concat(result))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }
  const isMobile = window.screen.width < 600
  return (
    <>
      {!isMobile && (
        <Card>
          <Text variant="titleSecondary" fontWeight="r">
            Description
          </Text>
          <Text
            variant="titleSecondary"
            fontWeight="r"
            ml="auto"
            textAlign="right"
          >
            Amount
          </Text>
          <Text
            variant="titleSecondary"
            fontWeight="r"
            ml={30}
            mr={10}
            textAlign="right"
          >
            Balance
          </Text>
        </Card>
      )}
      {trxnArr.map(
        ({ create_time, description, debit, credit, balance }, i) => {
          return (
            <Box
              variant="flex"
              bg="surface.foreground"
              flexDirection="row"
              px={18}
              py={16}
              my={4}
              key={`transaction-entry-${i}`}
            >
              <Box variant="flex" flexDirection="column">
                <Text variant="title" fontWeight="r" width={250}>
                  {description}
                </Text>
                <Text variant="subtext" textAlign="left">
                  {dayjs(create_time).format('DD/MM/YYYY')}
                </Text>
              </Box>
              <Box variant="flex" flexDirection="row">
                <Text
                  width={80}
                  fontWeight="s"
                  mr={10}
                  color={debit ? '#dd3623' : '#79ba10'}
                >
                  {debit ? `- ₹${debit}` : `+ ₹${credit}`}
                </Text>
                {!isMobile && (
                  <Text fontWeight="s" width={80}>
                    {balance}
                  </Text>
                )}
              </Box>
            </Box>
          )
        }
      )}
      {isFetching && <Loader />}
    </>
  )
}

export default BalanceHistory
