import React, { useState, useEffect, useRef } from 'react'
import { Revisions } from '../../../../../components/TextEditor/revisions'
import { Preview } from '../../../../../components/TextEditor/preview'
import {
  AddAnotherCTA,
  ActionButtonDiv,
} from '../../../../../components/TextEditor/styles'
import PrivatePage, { menuItemObj } from '../../../../../components/PrivatePage'
import Tooltip from '../../../../../components/Tooltip'
import { get, post } from '../../../../../lib/api'
import Axios from 'axios'
import GenericError from '../../../../../components/GenericError'
import queryString from 'query-string'
import Loader from '../../../../../components/Loader'
import SaveIcon from '../../../../../components/Images/Save'
import { getAudiobookWordCountLimit } from '../../Unpublished/Edit/helpers'
import { isAdminAccess } from '../../../../../config'
import { Provider } from '../../../../../context/editorContext'
import { EditorControls } from '../../Unpublished/Edit/chapterEditor'
import { TextEditor, getContent, isContentChanged,  isUndoEmpty, isRedoEmpty, handleRedo as onRedo, handleUndo as onUndo} from '../../../../../components/TextEditor'

const AddEditChapter = (props) => {
  const {
    history,
    match: {
      params: { id },
    },
    location: { search }
  } = props
  const qParams = queryString.parse(search)
  const { chapter_id, create_new, show_id, created_by, title: titleFromQpram, book_type, language, moderation_required } = qParams
  const [content, setContent] = useState('')
  const [initialContent, setInitialContent] = useState('')
  const [wordCount, setWordCount] = useState(0)
  const [editorState, setEditorState] = useState(null)
  const [title, setTitle] = useState(titleFromQpram || 'Untitled Chapter')
  const [isLoading, setLoading] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [showPreview, toggleShowPreview] = useState(false)
  const [showVersion, toggleShowVersion] = useState(false)
  const [episodeTag, setEpisodeTag] = useState(false)
  const [editorToolbarChange,setEditorToolbarChange] = useState(false)


  useEffect(() => {
    if (create_new === 'true') return
    if (!chapter_id) return
    setLoading(true)
    get('/content_api/book.get_chapter_details', {
      params: {
        chapter_id,
        book_id: id,
        show_id: show_id,
        view: isAdminAccess ? 'cms' : 'dashboard',
        is_novel: 0,
      }
    }).then(response => {
      const { result } = response
      if(result?.status !== "approved"){
        setEpisodeTag(true)
      }
      const { file_url, chapter_title } = result
      if (!title) setTitle(chapter_title)
      if (file_url) {
        Axios.get(file_url).then(response => {
          setContent(response.data)
          setInitialContent(response.data)
        })
      } else {
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (title && content) setLoading(false)
  }, [title, content])

  const updateState = (editor) => {
    setEditorState(editor)
  }

  const handleChapterSave = () => {
    const isValid = isContentChanged(editorState) && wordCount >= getAudiobookWordCountLimit(language, book_type) && editorToolbarChange
    if (!isValid) return
    const content = getContent(editorState)

    setLoading(true)
    const ContentDoc = document.implementation.createHTMLDocument('Content')
    ContentDoc.body.innerHTML = content
    const allHighlights = ContentDoc.getElementsByClassName('highlight')
    const len = allHighlights.length
    for (let i = 0; i < len; i++) {
      allHighlights[0].outerHTML = allHighlights[0].innerHTML
    }
    const modifiedContent = ContentDoc.body.innerHTML
    //create a text file
    let file = new Blob([modifiedContent], {
      type: 'text/plain;charset=utf-8',
    })

    let url = ''

    // upload to s3
    let params = {
      title,
      tags: 'file',
      file_dir: id,
      image_extension: 'txt',
    }
    let getPreSignedUrl = '/upload/get_presigned_url'
    get(getPreSignedUrl, { params }).then((response) => {
      let imageUrl = response.result[0]
      let awsFields = imageUrl.fields
      let prefix = imageUrl.url
      let suffix = imageUrl.s3_unique_key
      const formData = new FormData()
      formData.append('key', awsFields.key)
      formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
      formData.append('x-amz-security-token', awsFields['x-amz-security-token'])
      formData.append('policy', awsFields.policy)
      formData.append('signature', awsFields.signature)
      formData.append('file', file)
      const cdn = 'https://d31b0xt3oaqqjh.cloudfront.net'
      post(prefix, { data: formData })
        .then((response) => {
          url = `${cdn}/${id}/${suffix}`

          if (chapter_id) {
            const dataForVersion = {
              chapter_id,
              word_count: wordCount,
              file_url: url,
              chapter_title: title,
              book_id: id,
              view: isAdminAccess ? 'cms' : 'dashboard'
            }
            // if (moderation_required) {
            //   dataForVersion.status = "pending"
            // }
            return post('/content_api/book.version?is_novel=0', {
              data: dataForVersion
            }).then(() => {
              history.push(`${menuItemObj.audiobooks}?tab=published&id=${show_id}`)
            })

          } else {
            let chapter_details = {
              book_id: id,
              word_count: wordCount,
              file_url: url,
              chapter_title: title
            }
            if (!moderation_required) {
              chapter_details.status = 'approved'
            }
            const data = {
              chapter_details,
              book_id: id,
              show_id,
              view: isAdminAccess ? 'cms' : 'dashboard'
              // story_details
            }

            return post('/content_api/book.create_episode', {
              data
            }).then(() => {
              history.push(`${menuItemObj.audiobooks}?tab=published&id=${show_id}`)
            })
            
          }
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const saveButtonEnabled=()=>{
    if(isContentChanged(editorState) && wordCount >= getAudiobookWordCountLimit(language, book_type) && editorToolbarChange){
        return true
    }
    else{
     return false
    }
   }
  return (
    <Provider value={() => 
      <EditorControls
        title={title}
        onBack={() => history.goBack()}
        onTitleChange={setTitle}
        wordCount={wordCount}
        undoActive={!isUndoEmpty(editorState)}
        // undoActive={true}
        redoActive={!isRedoEmpty(editorState)}
        // redoActive={true}
        handleUndo={() => onUndo(editorState, setEditorState)}
        handleRedo={() => onRedo(editorState, setEditorState)}
        handleShowRevision={() => toggleShowVersion(true)}
        showRevisionsIcon={chapter_id}
        actionButtons={{
          secondary: {
            label: 'Save',
            tooltip: wordCount < getAudiobookWordCountLimit(language, book_type) ? `Please type atleast ${getAudiobookWordCountLimit(language, book_type)} words to continue` : !isContentChanged(editorState) ? 'Please make some changes first' : '',
            icon: SaveIcon,
            show: true,
            // enabled: isContentChanged(editorState) && wordCount >= getAudiobookWordCountLimit(language, book_type),
            enabled:saveButtonEnabled(),
            onClick: handleChapterSave
          }
        }}
      />
    }>
      <PrivatePage isProfileNameShown={false}>
        <>
          {isLoading && !apiError && <Loader />}
          {!isLoading && apiError && <GenericError
            show={apiError}
            onClose={() => toggleApiError(false)}
          />}
          {!isLoading && <TextEditor
              content={content}
              initialContent={initialContent}
              setContent={setContent}
              editorState={editorState}
              updateState={updateState}
              updateWordCount={setWordCount}
              chapterId={chapter_id}
              bookId={id}
              episodeTag={episodeTag}
              setEditorToolbarChange={setEditorToolbarChange}
          />}
          {!isLoading && showVersion && <Revisions open={showVersion} close={() => toggleShowVersion(false)} chapter_id={chapter_id} />}
          {/* <Preview
            open={showPreview}
            close={() => toggleShowPreview(false)}
            title={title}
            content={content}
          /> */}
        </>
      </PrivatePage>
    </Provider>
  )
}

export default AddEditChapter
