import React from 'react'
import { InlineRadioToggle } from '../../FormComponents'
import { useField } from 'formik';

const FormikInlineRadioToggle = props => {
  const [field] = useField(props)
  const { onChange } = field
  const newHandleChange = val => {
    onChange({target: { name: props.name, value: val}})
  }

  return (
    <InlineRadioToggle
      {...{...field, onChange: newHandleChange}}
      {...props}
    />
  )
}

export default FormikInlineRadioToggle
