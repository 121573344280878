import React from 'react'
import {
  Container,
  ArtContainer,
  StartText,
  DescriptionText,
  EmptyResultsIcon,
  EmptyIcon
} from './styles'

const EmptyState = ({ filtersApplied, text, subtext = '' }) => {
  const subtextArray = Array.isArray(subtext) ? subtext : [subtext]
  return (
    <Container>
      {filtersApplied ? <EmptyResultsIcon/> : <EmptyIcon/>}
      <StartText>{ text }</StartText>
      {subtext && subtextArray.map((txt, i, arr) => (
        <DescriptionText key={i} firstIndex={i === 0}>
          {txt}
        </DescriptionText>
      ))}
    </Container>
  )
}
export default EmptyState
