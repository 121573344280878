import React from 'react'
import Exclamation from '../../../../../components/Images/Exclamation'
import { Popup } from '../../../../../components/Popup'

function AudioWarningPopup({ audioRepetionWarning, setAudioRepitionWarning }) {
  return (
    <Popup
      show={audioRepetionWarning?.isWarning}
      close={() => setAudioRepitionWarning({ isWarning: false })}
      className="crop-image-popup"
    >
      <div className="header-icons">
        <span>Audio File Repition Warning !</span>
      </div>
      <div className="form-error">
        <Exclamation />
        <span className="title">{audioRepetionWarning?.msg}</span>
      </div>
      <button
        className="action-button primary"
        type="button"
        onClick={() => setAudioRepitionWarning({ isWarning: false })}
        style={{ float: 'right', 'margin-bottom': '8px' }}
      >
        Okay
      </button>
    </Popup>
  )
}
export default AudioWarningPopup
