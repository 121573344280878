import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import LikeIcon from '../../../components/Images/LikeIcon'
import ReplyIcon from '../../../components/Images/ReplyIcon'
import AlertSnack from '../../../components/AlertSnackBar'
import ReplyCard from './reply'
import NewReply from './addNewReply'
import {
  ProfileImage,
  ProfileImageContainer,
  CommentContainer,
  LikesDiv,
  ReplyDiv,
  VellipContainer,
  VellipIcon,
  CommentActionContainer,
  LoadReplyContainer,
  LoadReplyIcon,
  LoadReplyText,
  LoadReplyIconDiv,
  StyledCreatorIcon,
  StyledAuthorIcon,
  LikeOutlinedSmall,
  CommentAction,
  LikeOutlinedIcon,
  LikeBlackIcon,
  DustbinIcon,
  CreatorNote,
  CreatorNoteContainer,
  PopupCancelButton,
  PopupDeleteCommentButton,
  DeleteMessage,
  DeleteHeader,
  LinkCommentToReply,
  LinkCommentReplyCurve,
  PencilIcon,
  ReplyButton,
  CancelButton,
  ActionButtonDiv,
  TypeReplyDiv,
  TypeReplyHere,
  RatingYellowIcon,
  RatingDiv,
} from '../styles'
import { post } from '../../../lib/api'
import { CommentContext } from '../../../providers/commentProvider'
import { Popup } from '../../../components/Popup'
import DustbinBlack from '../../../components/Images/DustbinBlack'
import GenericError from '../../../components/GenericError'
import { getTokenAndUid } from '../../../lib/utils'
import { isAdminAccess } from '../../../config'
import { Box, Text } from '../../../designSystem'
import CreatorInfo from '../../../components/CreatorInfo'
import { useCreatorProfile } from '../../../lib/customHooks'

const ChapterOptionsBox = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`

const Option = styled.div`
  padding: 16px 0px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`
const isMobile = window.screen.width < 600

const EditCommentDiv = (props) => {
  const { commentText, closeCommentEdit, setCommentText, updateComment } = props
  return (
    <Box height={132} position="relative" mr={120}>
      <TypeReplyDiv>
        <TypeReplyHere
          as="textarea"
          value={commentText}
          placeholder="Reply"
          rows={3}
          onChange={(e) => setCommentText(e.target.value)}
        />
      </TypeReplyDiv>
      {!isMobile && (
        <ActionButtonDiv>
          <CancelButton onClick={() => closeCommentEdit()}>Cancel</CancelButton>
          <ReplyButton onClick={() => updateComment()}>Reply</ReplyButton>
        </ActionButtonDiv>
      )}
    </Box>
  )
}

const CommentCard = ({
  comments,
  commentIndex,
  entityKey,
  userInfo,
  isComment,
}) => {
  const { allComments, onUpdateComments } = useContext(CommentContext)
  const [comment, setComment] = useState(null)
  const [showAction, toggleShowAction] = useState(false)
  const [addReply, toggleAddReply] = useState(false)
  const [loadMoreReply, toggleLoadMoreReply] = useState(false)
  const [commentLiked, setCommentLiked] = useState(comments.is_liked)
  const [showDeletePopup, toggleShowDeletePopup] = useState(false)
  const [errorPopup, toggleErrorPopup] = useState(false)
  const [commentEdit, setCommentEdit] = useState(false)
  const [commentText, setCommentText] = useState('')
  const [savedChanges, setSavedChanges] = useState(false)
  const [isCommentOptionsShown, toggleShowCommentOptions] = useState(false)
  const showActionsRef = useRef()
  const addReplyRef = useRef()
  const {
    isDrawerShowing,
    setDrawerShowing,
    onUserClick,
    userInfo: drawerUserInfo,
  } = useCreatorProfile()
  const { uid } = getTokenAndUid()

  const handleVellipClick = (e) => {
    e.stopPropagation()
    toggleShowCommentOptions(!isCommentOptionsShown)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    setComment(
      allComments[entityKey].result &&
        allComments[entityKey].result.length > commentIndex
        ? allComments[entityKey].result[commentIndex]
        : null
    )
  }, [allComments])

  const handleClick = (e) => {
    if (showActionsRef.current && !showActionsRef.current.contains(e.target)) {
      toggleShowAction(false)
    }
    if (addReplyRef.current && !addReplyRef.current.contains(e.target)) {
      toggleAddReply(false)
    }
  }

  const editComment = () => {
    setCommentText(comment.comment)
    setCommentEdit(true)
  }

  const closeCommentEdit = () => {
    setCommentEdit(false)
  }

  const updateComment = () => {
    if (commentText.length === 0) return
    post('/social/comment.create', {
      data: {
        comment_creator_uid: uid,
        story_id: allComments[entityKey].story_id,
        show_id: allComments[entityKey].result[commentIndex].show_id,
        author_id: allComments[entityKey].result[commentIndex].author_id,
        creator_id: allComments[entityKey].result[commentIndex].creator_id,
        comment: commentText,
        comment_id: comment._id.$oid,
        is_replied: 1,
        entity_type: 'story',
        _id: {
          $oid: comment._id.$oid,
        },
        comment_creator_name: userInfo.fullname,
        comment_creator_image: userInfo.image_url,
      },
    })
      .then((res) => {
        let comments = JSON.parse(JSON.stringify(allComments))
        let editedComment = comments[entityKey].result[commentIndex]
        editedComment['comment'] = commentText
        setCommentEdit(false)
        onUpdateComments(comments)
      })
      .catch((err) => {
        toggleErrorPopup(true)
        throw err
      })
  }

  const createReply = () => {
    toggleAddReply(false)
    toggleLoadMoreReply(true)
  }

  const removeComment = () => {
    // make api call
    let data = {
      comment_creator_uid: comment.comment_creator_uid,
      story_id: comment.story_id,
      comment_id: comment._id.$oid,
      caller_id: uid,
      creator_id: comment.creator_id,
      author_id: comment.author_id,
    }
    const deleteApi = isComment
      ? '/social/comment.delete'
      : '/social/review.delete'
    post(deleteApi, {
      data,
    })
      .then((res) => {
        toggleShowDeletePopup(false)
        let oldComment = JSON.parse(JSON.stringify(allComments))
        oldComment[entityKey].result.splice(commentIndex, 1)
        onUpdateComments(oldComment)
      })
      .catch((err) => {
        toggleShowDeletePopup(false)
        toggleErrorPopup(true)
        throw err
      })
  }

  const updateLikeCount = (comment_id, like_count) => {
    post('/social/comment.create', {
      data: {
        comment_creator_uid: comment.comment_creator_uid,
        story_id: allComments[entityKey].story_id,
        show_id: comment.show_id,
        author_id: comment.author_id,
        creator_id: comment.creator_id,
        comment: comment.comment,
        like_count: like_count,
        is_replied: 1,
        entity_type: 'story',
        _id: {
          $oid: comment_id,
        },
        comment_creator_name: userInfo.fullname,
        comment_creator_image: userInfo.image_url,
      },
    }).catch((err) => {
      setSavedChanges(true)
      // toggleErrorPopup(true)
      // throw err
    })
  }

  const likeOrUnlikeComment = (comment_id) => {
    let data = {
      uid: uid,
      entity_id: comment_id,
      entity_type: 'comment',
      action: commentLiked ? 'unlike' : 'like',
      status: 'active',
    }
    post('/user_api/user_action.update', {
      data,
    })
      .then(() => {
        let oldComment = JSON.parse(JSON.stringify(allComments))
        oldComment[entityKey].result[commentIndex]['is_liked'] = !commentLiked
        let like_count =
          oldComment[entityKey].result[commentIndex]['like_count'] || 0
        oldComment[entityKey].result[commentIndex]['like_count'] = commentLiked
          ? like_count > 0
            ? like_count - 1
            : like_count
          : like_count + 1
        updateLikeCount(
          comment_id,
          oldComment[entityKey].result[commentIndex]['like_count']
        )

        setCommentLiked(!commentLiked)
        onUpdateComments(oldComment)
      })
      .catch((err) => {
        setSavedChanges(true)
      })
    toggleShowCommentOptions(false)
  }
  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={drawerUserInfo}
        close={() => setDrawerShowing(false)}
      />
      <GenericError show={errorPopup} onClose={() => toggleErrorPopup(false)} />
      {comment && (
        <Box>
          <CommentContainer>
            {(addReply ||
              (comment.comments &&
                comment.comments.length > 0 &&
                comment.comments[0])) && (
              <LinkCommentToReply>
                <LinkCommentReplyCurve />
              </LinkCommentToReply>
            )}
            <ProfileImageContainer>
              <ProfileImage
                src={comment.comment_creator_image}
                onClick={() => onUserClick(comment.comment_creator_uid)}
              />
              {uid === comment.comment_creator_uid &&
                userInfo.role &&
                userInfo.role.toLowerCase() === 'author' && (
                  <StyledAuthorIcon />
                )}
              {uid === comment.comment_creator_uid &&
                userInfo.role &&
                userInfo.role.toLowerCase() !== 'author' && (
                  <StyledCreatorIcon />
                )}
            </ProfileImageContainer>
            {commentEdit ? (
              !isMobile ? (
                <EditCommentDiv
                  commentText={commentText}
                  setCommentText={setCommentText}
                  updateComment={updateComment}
                  closeCommentEdit={closeCommentEdit}
                />
              ) : (
                <Popup
                  show={commentEdit}
                  close={closeCommentEdit}
                  renderActions={
                    <>
                      <CancelButton onClick={closeCommentEdit}>
                        Cancel
                      </CancelButton>
                      <ReplyButton onClick={updateComment}>Reply</ReplyButton>
                    </>
                  }
                >
                  <EditCommentDiv
                    commentText={commentText}
                    setCommentText={setCommentText}
                    updateComment={updateComment}
                    closeCommentEdit={closeCommentEdit}
                  />
                </Popup>
              )
            ) : (
              <Box ml={12}>
                <Box>
                  {comment.user && (
                    <>
                      <Text
                        variant="caption"
                        mr={4}
                        textAlign="left"
                        onClick={() => onUserClick(comment.comment_creator_uid)}
                      >
                        {comment.user.fullname} &nbsp;<strong>&middot;</strong>
                        &nbsp;
                        {comment.comment_creation_time}{' '}
                      </Text>
                      {!!comment.is_creator_note && (
                        <CreatorNoteContainer>
                          <CreatorNote>Creator Note</CreatorNote>
                        </CreatorNoteContainer>
                      )}
                      {!!comment.user_rating && (
                        <RatingDiv>
                          {[
                            ...Array(Math.round(comment.user_rating)).keys(),
                          ].map((elm, index) => (
                            <RatingYellowIcon key={index}></RatingYellowIcon>
                          ))}
                        </RatingDiv>
                      )}
                    </>
                  )}
                </Box>
                <Box mt={4} overflow="hidden" maxWidth={['70vw', 650]}>
                  <Text variant="title" fontWeight="normal">
                    {comment.comment}
                  </Text>
                </Box>
                <Box variant="flex" mt={8} justifyContent="flex-start">
                  <LikesDiv
                    onClick={() => likeOrUnlikeComment(comment._id.$oid)}
                  >
                    {comment.is_liked || commentLiked ? (
                      <LikeIcon />
                    ) : (
                      <LikeOutlinedSmall />
                    )}
                    <Text variant="caption" fontWeight="bold" ml={4}>
                      {comment.like_count}{' '}
                      {comment.like_count > 1 ? 'Likes' : 'Like'}
                    </Text>
                  </LikesDiv>
                  {!isAdminAccess && (
                    <ReplyDiv
                      ref={addReplyRef}
                      onClick={() => toggleAddReply(true)}
                    >
                      <ReplyIcon />
                      <Text variant="caption" fontWeight="bold" ml={4}>
                        Reply
                      </Text>
                    </ReplyDiv>
                  )}
                  <VellipContainer
                    ref={showActionsRef}
                    onClick={() => toggleShowAction(!showAction)}
                  >
                    {!isMobile ? (
                      <>
                        <VellipIcon />
                        <CommentActionContainer showAction={showAction}>
                          {uid === comment.comment_creator_uid && (
                            <CommentAction onClick={editComment}>
                              <PencilIcon />
                              <Text variant="caption" fontWeight="bold" ml={16}>
                                Edit
                              </Text>
                            </CommentAction>
                          )}
                          <CommentAction
                            onClick={() =>
                              likeOrUnlikeComment(comment._id.$oid)
                            }
                          >
                            {comment.is_liked || commentLiked ? (
                              <LikeBlackIcon />
                            ) : (
                              <LikeOutlinedIcon />
                            )}
                            <Text variant="caption" fontWeight="bold" ml={16}>
                              {comment.is_liked || commentLiked
                                ? 'Unlike'
                                : 'Like'}
                            </Text>
                          </CommentAction>
                          <CommentAction
                            onClick={() => toggleShowDeletePopup(true)}
                          >
                            <DustbinIcon />
                            <Text variant="caption" fontWeight="bold" ml={16}>
                              Remove
                            </Text>
                          </CommentAction>
                        </CommentActionContainer>
                      </>
                    ) : (
                      <>
                        <VellipIcon onClick={handleVellipClick} />
                        <Popup
                          className="bottom-sheet no-padding"
                          show={isCommentOptionsShown}
                          close={() => toggleShowCommentOptions(false)}
                        >
                          <ChapterOptionsBox showAction={showAction}>
                            {uid === comment.comment_creator_uid && (
                              <Option onClick={editComment}>
                                <PencilIcon />
                                <Text
                                  variant="caption"
                                  fontWeight="bold"
                                  ml={16}
                                >
                                  Edit
                                </Text>
                              </Option>
                            )}
                            <Option
                              onClick={() =>
                                likeOrUnlikeComment(comment._id.$oid)
                              }
                            >
                              {comment.is_liked || commentLiked ? (
                                <LikeBlackIcon />
                              ) : (
                                <LikeOutlinedIcon />
                              )}
                              <Text variant="caption" fontWeight="bold" ml={16}>
                                {comment.is_liked || commentLiked
                                  ? 'Unlike'
                                  : 'Like'}
                              </Text>
                            </Option>
                            <Option
                              onClick={() => {
                                toggleShowDeletePopup(true)
                                toggleShowCommentOptions(false)
                              }}
                            >
                              <DustbinIcon />
                              <Text variant="caption" fontWeight="bold" ml={16}>
                                Remove
                              </Text>
                            </Option>
                          </ChapterOptionsBox>
                        </Popup>
                      </>
                    )}
                  </VellipContainer>
                  {savedChanges && (
                    <AlertSnack
                      text={`Failed to ${
                        comment.is_liked || commentLiked ? 'Unlike' : 'Like'
                      }`}
                      close={() => setSavedChanges(false)}
                    />
                  )}
                </Box>
              </Box>
            )}
          </CommentContainer>
          <NewReply
            show={addReply}
            index={0}
            entityKey={entityKey}
            commentIndex={commentIndex}
            moreReplies={comment.comments && comment.comments.length > 0}
            close={() => toggleAddReply(false)}
            onClick={(e) => e.stopPropagation()}
            createReply={createReply}
            userInfo={userInfo}
          />
          {comment &&
            comment.comments &&
            comment.comments.length > 0 &&
            comment.comments.map(
              (reply, index) =>
                (index === 0 || loadMoreReply) &&
                reply && (
                  <ReplyCard
                    key={reply.comment_id}
                    comment={reply}
                    entityKey={entityKey}
                    isComment={isComment}
                    moreReplies={comment.comments.length > index + 1}
                    index={index}
                    commentIndex={commentIndex}
                    userInfo={userInfo}
                    comment_id={comment._id.$oid}
                  />
                )
            )}
          {!loadMoreReply && comment.comments && comment.comments.length > 1 && (
            <LoadReplyContainer onClick={() => toggleLoadMoreReply(true)}>
              <LoadReplyIconDiv>
                <LoadReplyIcon />
              </LoadReplyIconDiv>
              <LoadReplyText>See More Replies</LoadReplyText>
            </LoadReplyContainer>
          )}
        </Box>
      )}
      <Popup
        show={showDeletePopup}
        close={() => toggleShowDeletePopup(false)}
        renderActions={
          <>
            <PopupCancelButton
              className="action-button"
              type="button"
              onClick={() => toggleShowDeletePopup(false)}
            >
              Cancel
            </PopupCancelButton>
            &nbsp;
            <PopupDeleteCommentButton
              className="action-button"
              type="button"
              onClick={removeComment}
            >
              Delete Comment
            </PopupDeleteCommentButton>
          </>
        }
      >
        <DustbinBlack />
        <DeleteHeader>Delete this Comment Permanently?</DeleteHeader>
        <br />
        <DeleteMessage>
          Are you sure you want to permanently delete this comment, after
          deleting you won't be able to recover it.
        </DeleteMessage>
      </Popup>
    </>
  )
}

export default CommentCard
