import React from 'react'

const BigInfoIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="#0D1536" fillOpacity=".8" d="M16.667 0C8.212 0 1.333 6.879 1.333 15.333c0 8.455 6.879 15.334 15.334 15.334S32 23.788 32 15.333C32 6.88 25.122 0 16.667 0zm0 27.879c-6.918 0-12.546-5.628-12.546-12.546 0-6.917 5.628-12.545 12.546-12.545 6.917 0 12.545 5.628 12.545 12.545 0 6.918-5.628 12.546-12.545 12.546z"/>
    <path fill="#0D1536" fillOpacity=".8" d="M16.665 6.505c-1.024 0-1.858.834-1.858 1.86 0 1.024.834 1.857 1.858 1.857 1.025 0 1.859-.833 1.859-1.858 0-1.025-.834-1.86-1.859-1.86zM16.667 13.01c-.77 0-1.394.624-1.394 1.394v8.363c0 .77.624 1.394 1.394 1.394.77 0 1.394-.624 1.394-1.394v-8.363c0-.77-.624-1.394-1.394-1.394z"/>
  </svg>
)

export default BigInfoIcon
