import { useState, useEffect, useRef, useCallback } from 'react'

const useInfiniteScrollLatest = (callback, { nextUrl } = {}) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (!isFetching) return
    callback(setIsFetching)
  }, [isFetching])

  const observer = useRef()
  const lastNodeEleRef = useCallback(
    (node) => {
      if (isFetching) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextUrl) {
          setIsFetching(true)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isFetching, nextUrl]
  )

  return { isFetching, lastNodeEleRef }
}

export default useInfiniteScrollLatest
