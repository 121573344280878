import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Calendar from '../../Images/CalendarOutlineIcon'
import Tooltip from '../../Tooltip'
import moment from 'moment'



const Container = styled.div`
  padding: 12px;
  margin-top: 24px;
  border: solid 1px var(--Grey900);
  background-color: ${({ yesSelected }) => yesSelected ? 'var(--Dove)' : 'var(--Grey100)'};
  border-radius: 2px;
  box-shadow: ${({ yesSelected }) => yesSelected ? '0 0 4px 0 rgba(73, 83, 89, 0.16)' : 'none'};
`
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`
const LabelSpan = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`
const ToggleButton = styled.div`
  cursor: pointer;
  margin-right: 8px;
  width: 88px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  border-radius: 4px;
  color: ${({ yesButton, selected }) => selected && yesButton ? 'rgba(255, 255, 255, 0.98)' : !selected && !yesButton ? 'var(--Text300)' : 'var(--Text500)'};
  background-color: ${({ yesButton, selected }) => yesButton && selected ? 'var(--Azure500)' : yesButton ? 'transparent' : selected ? 'var(--Grey900)' : 'var(--Grey100)'};
  margin-left: ${({ yesButton }) => yesButton ? '8px' : 'auto'};
`
const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.DateInput {
    width: 140px;
  }
  input.DateInput_input {
    width: 140px;
    font-size: 16px;
    border-bottom: none;
  }
  div.SingleDatePicker_picker {
    z-index: 9999999;
  }
  div.SingleDatePickerInput__withBorder:focus-within {
    border: solid 1px var(--Azure500);
  }
  @media (max-width:600px){
    div.DateInput {
      width: 110px;
    }
    input.DateInput_input {
      width: 110px;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
`
const CalendarIcon = styled(Calendar)`
  fill: var(--Azure500);
`
const Options = styled.div`
  position: absolute;
  max-height: 395px;
  overflow-y: scroll;
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  padding: 8px 0;
  background-color: var(--Dove);
  width: 80px;
  top: 50px;
  left: 0;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  z-index: 999999;
`
const DateSelect = styled.div`
  margin-top: 16px;
`
const TitleDateSelect = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
`
const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`
const HourFieldOuter = styled.div`
  position: relative;
`
const HourField = styled.div`
  padding: 12px 14px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  @media (max-width:600px){
    padding: 10px 12px;
  }
`
const Separator = styled.div`
  margin: 0 4px;
`
const MinuteFieldOuter = styled.div`
  position: relative;
`
const MinuteField = styled.div`
  padding: 12px 14px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-right: 8px;
  @media (max-width:600px){
    padding: 10px 12px;
  }
`
const AMPM = styled.div`
  padding: 12px 11px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  @media (max-width:600px){
    padding: 10px 12px;
  }
`

const SingleHour = styled.div`
  padding: 16px;
  :hover {
    background-color: var(--Grey500);
  }
`
const SingleMinute = styled.div`
  padding: 16px;
  :hover {
    background-color: var(--Grey500);
  }
`


const transform = "DD MMM YYYY"

const ScheduleLater = ({ id, label, infoTitle, disableToggleBack, calendarTitle, asTextArea, rowCount, ...props }) => {
  const [field] = useField(props)
  const { onChange, value: val } = field
  const [selectedOption, setSelected] = useState(val ? 1 : 0)
  const [datePickerFocussed, setDatePickerFocussed] = useState(false)
  const [isHourDropdownOpen, toggleHourDropdown] = useState(false)
  const [isMinuteDropdownOpen, toggleMinuteDropdown] = useState(false)
  const hourRef = useRef()
  const minuteRef = useRef()
  let initialValue = useRef(val)
  const value = val ? moment(val).format('DD MMM YYYY, hh:mm A') : val
  const newHandleChange = date => {
    const val = date && (moment(date).format(transform) + ', 12:00 AM')
    onChange({target: { name: props.name, value: val}})
  }

  const handleClick = (e) => {
    if (!hourRef.current || !minuteRef.current) return
    if (!hourRef.current.contains(e.target)) {
      toggleHourDropdown(false)
    }
    if (!minuteRef.current.contains(e.target)) {
      toggleMinuteDropdown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const handleChangeHour = hour => {
    toggleHourDropdown(false)
    if (!value) return
    const stringHour = String(hour).padStart(2, '0')
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const newTime = stringHour + ':' + oldTime.split(':')[1]
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const handleChangeMinute = minute => {
    toggleMinuteDropdown(false)
    if (!value) return
    const stringMinute = String(minute).padStart(2, '0')
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const oldTimeHour = oldTime.split(' ')[0].split(':')[0]
    const oldTimeAA = oldTime.split(' ')[1]
    const newTime = oldTimeHour + ':' + stringMinute + ' ' + oldTimeAA
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const handleChangeAA = ampm => {
    if (!value) return
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const newTime = oldTime.split(' ')[0] + ' ' + ampm
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const handleChangeToNo = () => {
    if (disableToggleBack && initialValue.current) return
    setSelected(0)
  }

  const hours = []
  const minutes = []
  for(let i = 0 ; i<60 ; i++) {
    i < 13 && i !== 0 && hours.push(
      <SingleHour key={'hour-count-' + i} onClick={() => handleChangeHour(i)}>{String(i).padStart(2, '0')}</SingleHour>
    )
    minutes.push(
      <SingleMinute key={'minute-count-' + i} onClick={() => handleChangeMinute(i)}>{String(i).padStart(2, '0')}</SingleMinute>
    )
  }
  const date = (value && moment(value)) || null
  const time = (value && value.split(',')[1].trim()) || '12:00 AM'
  const hh = time.split(' ')[0].split(':')[0]
  const mm = time.split(' ')[0].split(':')[1]
  const A = time.split(' ')[1]

  return (
    <Container yesSelected={selectedOption}>
      <ToggleContainer>
        <LabelContainer>
          <LabelSpan>{label}</LabelSpan><InfoIcon title={infoTitle}/>
        </LabelContainer>
        <ToggleButton selected={!selectedOption} onClick={handleChangeToNo}>No</ToggleButton>
        <ToggleButton selected={selectedOption} onClick={() => setSelected(1)} yesButton={true}>Yes</ToggleButton>
      </ToggleContainer>
      {!!selectedOption && <DateSelect>
        <TitleDateSelect>{calendarTitle}</TitleDateSelect>
        <DateContainer>
          <SingleDatePicker
            date={date} // momentPropTypes.momentObj or null
            onDateChange={date => newHandleChange(date)} // PropTypes.func.isRequired
            focused={datePickerFocussed} // PropTypes.bool
            onFocusChange={({ focused }) => setDatePickerFocussed(focused)} // PropTypes.func.isRequired
            id={id || "your_unique_id"} // PropTypes.string.isRequired,
            numberOfMonths={1}
            placeholder="date"
            noBorder={false}
            customInputIcon={<CalendarIcon/>}
            inputIconPosition="after"
            displayFormat="D MMMM YYYY"
          />
          <Tooltip title={value ? "" : "Please select Date first"}>
          <TimeContainer>
            <HourFieldOuter>
              <HourField ref={hourRef} onClick={() => toggleHourDropdown(true)}>
                {hh}
              </HourField>
              <Options isOpen={isHourDropdownOpen} >{hours}</Options>
            </HourFieldOuter>
            <Separator>:</Separator>
            <MinuteFieldOuter>
              <MinuteField ref={minuteRef} onClick={() => toggleMinuteDropdown(true)}>
                {mm}
              </MinuteField>
              <Options isOpen={isMinuteDropdownOpen} >{minutes}</Options>
            </MinuteFieldOuter>
            <AMPM onClick={() => handleChangeAA(A === 'AM' ? 'PM' : 'AM')}>{A}</AMPM>
          </TimeContainer>
          </Tooltip>
        </DateContainer>
      </DateSelect>}
    </Container>
  );
};

export default ScheduleLater
