import React from 'react'
import styled from 'styled-components'
import Back from '../Images/BackButton'
import { withRouter } from 'react-router-dom'

const SubHeader = styled.div`
  // height: ${({ size }) => size === 'small' ? '112' : '167'}px;
  // background-color: ${({ transparent }) => transparent ? 'transparent' : 'var(--Grey900)'};
`
const MainBackground = styled.div`
  background-color: var(--Grey100);
`
const ChildContainer = styled.div`
  width: calc(100% - ${({ padding }) => padding || "96"}px);
  margin: auto;
  padding-top: ${({ size }) => size === 'small' ? '32' : '40'}px;
  max-width: ${({ fixMaxWidth }) => fixMaxWidth ? '1024px' : 'unset'};

  @media (max-width: 600px) {
    padding-top: 1px;
    width: 100%;
  }
`
const TitleBar = styled.div`
  justify-content: space-between;
  // margin-bottom: ${({ size }) => size === 'small' ? '24' : '32'}px;
  height: 72px;
  margin-bottom: 1px;
  background-color: var(--Dove);
  border-radius: 4px;
  padding: 16px 24px;
  display: ${({ showTopBar }) => showTopBar ? 'flex' : 'none'};
`
const TitleHolder = styled.div`
  display: flex;
  align-items: center;
`
export const BackButton = styled(Back)`
  margin-right: 16px;
  cursor: pointer;
`
const Title = styled.span`
  align-self: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text900);
`
const Spacer = styled.div`
  width: 100%;
  height: 60px;
`
const WithSubHeader = ({ transparent, title, size, padding, fixMaxWidth, history, additionalDiv, children, showBackButton, onClickBackButton }) => {
  const RenderAdditionalDiv = additionalDiv || (() => null)
  return (
    <>
      <SubHeader transparent={transparent} size={size}>
        <ChildContainer padding={padding} size={size} fixMaxWidth={fixMaxWidth}>
          <TitleBar size={size} showTopBar={additionalDiv}>
            <TitleHolder>
              {showBackButton && <BackButton onClick={onClickBackButton || history.goBack}/>}
              {title && <Title>
                {title}
              </Title>}
            </TitleHolder>
            <RenderAdditionalDiv />
          </TitleBar>
          { children }
          <Spacer />
        </ChildContainer>
      </SubHeader>
      <MainBackground />
    </>
  )
}

export default withRouter(WithSubHeader)
