import React from 'react'
import styled from 'styled-components'
import { descriptionText } from '../../constants'

const EmptyContainer = styled.div`
  background-color: var(--Dove);
  padding: 56px;
  margin-bottom: 24px;
`
const MainHeading = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--Text700);
  margin-bottom: 16px;
  text-align: center;

  @media(max-width:600px){
    font-size: 24px;
  }
`
const DescriptionText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  text-align: center;
  margin-bottom: 32px;
`
const AddEpisodeButton = styled.button`
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--Azure500);
  padding: 16px 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
`
const AddEpisodeDiv = styled.div`
  text-align: center;
`
const EmptyState = ({ onAddNew }) => (
  <EmptyContainer>
    <MainHeading>
      Add Episodes 
    </MainHeading>
    <DescriptionText>
      {descriptionText.emptyEpisodes}
    </DescriptionText>
    <AddEpisodeDiv>
      <AddEpisodeButton type='button' onClick={() => onAddNew()}>
        Add Episodes
      </AddEpisodeButton>
    </AddEpisodeDiv>
  </EmptyContainer>
)
export default EmptyState
