import React from 'react'

const Campaign= props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}> 
    <path fill="#fff" fill-rule="evenodd" d="M13.42 1.108c-.291.174-.334.331-.334 1.244 0 .708.01.812.088.968.128.259.274.348.567.348.285 0 .445-.092.564-.326.108-.212.113-1.74.006-1.965-.154-.325-.58-.453-.89-.269zM9.979 3.183c-.161.081-.215.143-.331.383-.077.158-1.335 2.827-2.796 5.931l-2.656 5.645 1.847 1.847 1.848 1.847 5.86-2.758c3.65-1.718 5.908-2.804 5.986-2.88.162-.155.229-.394.17-.61-.037-.134-.81-.927-4.672-4.786-2.62-2.618-4.681-4.645-4.75-4.671-.18-.067-.292-.056-.506.052zm9.041-.015c-.23.107-1.686 1.577-1.728 1.743-.142.573.386 1.03.906.783.078-.036.481-.41.898-.83.63-.637.765-.795.806-.948.089-.33-.08-.664-.4-.784-.17-.065-.283-.056-.482.036zm.636 5.57c-.432.263-.411.874.037 1.113.183.097.219.1 1.04.087.929-.015.96-.023 1.175-.31.13-.174.123-.52-.014-.712-.171-.24-.289-.264-1.244-.264-.775 0-.866.008-.994.086zM2.66 16.687c-.51.514-.615.643-.641.781-.065.347-.04.379 1.586 1.998 1.41 1.405 1.536 1.52 1.703 1.546.295.047.486-.073 1.103-.696l.55-.555-1.846-1.845-1.845-1.845-.61.616zm8.454 2.054l-2.254 1.064 1.607 1.608c1.714 1.716 1.74 1.737 2.088 1.673.145-.026.331-.195 1.515-1.375 1.475-1.47 1.486-1.485 1.388-1.852-.042-.157-.196-.332-1.046-1.187-.548-.552-1.007-1.001-1.02-1-.013.003-1.038.483-2.278 1.069z" clip-rule="evenodd"/>
</svg>
  
)

export default Campaign;
