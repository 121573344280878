import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  SingleBook,
  ThumbnailDiv,
  Thumbnail,
  Details,
  Title,
  TitleText,
  Description,
  CategoryDiv,
  ArtistsContainer,
  ArtistDetails,
  ProfileImageDiv,
  ProfileImage,
  ArtistName,
  StyledAuthorIcon,
} from '../styles'
import ClampLines from 'react-clamp-lines'
import { Card } from '../../../../components/Card'
import { menuItemObj } from '../../../../components/PrivatePage'
import { Image, Text } from '../../../../designSystem'

const NovelList = ({ history, novels }) => {
  return novels.length
    ? novels.map((novel) => (
        <Card
          key={novel.book_id}
          onClick={() =>
            history.push(`${menuItemObj.novels}/id/${novel.book_id}?tab=draft`)
          }
        >
          <Image width={[80, 96]} height={[120, 144]} mr={[8, 24]} src={novel.image_url} />
          <Details>
            <Text variant="title" fontWeight="r" mb={8}>
                {novel.book_title}
            </Text>
            {/* <Description>
              <ClampLines
                lines={2}
                text={novel.description || 'No description available'}
                id={novel.book_id}
                buttons={false}
              />
            </Description> */}
            {novel.author_info && (
              <ArtistsContainer>
                <ArtistDetails>
                  <ProfileImageDiv>
                    {novel.author_info.image_url && (
                      <ProfileImage src={novel.author_info.image_url} />
                    )}
                    <StyledAuthorIcon />
                  </ProfileImageDiv>
                  <Text variant="caption" ml={8}>{novel.author_info.fullname}</Text>
                </ArtistDetails>
              </ArtistsContainer>
            )}
          </Details>
        </Card>
      ))
    : null
}

export default withRouter(NovelList)
