import React, { useState, useEffect } from 'react'
import OTPInput from '../OTPInput'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import LoginIcon from './writer-login-art.svg'
import StudioLogo from '../Images/StudioLogo'
import { isLoggedIn, saveSession, convertUrlToHttps, getTokenAndUid, handleEventLog, getRoleStringFromAccountType } from '../../lib/utils'
import { FloatingInput, PhoneInput, DropdownMultiSelect } from '../FormComponents'
import { get, post } from '../../lib/api'
import Loader from '../Loader'
import CheckedIcon from '../Images/Checked'
import Axios from 'axios'
import { AccountContext } from '../../providers/accountProvider'
import { isValidPhoneNumber } from 'libphonenumber-js'
import DropDownSelect from '../../components/GenericDropDownSelect'
import { menuItemObj } from '../../components/PrivatePage'
import { Box } from '../../designSystem'
import WriterPopup from '../../pages/Shows/Novels/WriterPopup'
import WhatsappIcon from '../Images/WhatsappIcon'
import { notifyBugsnag } from '../ErrorBoundary'

const StyledWhatsappIcon = styled(WhatsappIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
`

const Container = styled.div`
  background-color: var(--Grey300);
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
`
const MainDiv = styled.div`
  display: flex;
  margin: auto;
  width: 900px;
  min-height: 540px;
  @media(max-width: 600px){
    width:100vw;
    flex-direction: column;
    // margin-right: 16px;
  }
`
const LeftDiv = styled.div`
  background-color: var(--FjordDark300);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`
const RightDiv = styled.div`
  width: 50%;
  padding: 48px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`
const IconHolder = styled.div`
`
const LogoHolder = styled.div`
  text-align: center;
  margin-bottom: 80px;
`
const LoginHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const LoginButton = styled.button `
  outline: none;
  border: none;
  padding: 14px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  opacity: ${({ disabled }) => disabled ? 0.3 : 1};
`
const ContactUsDiv = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  color: var(--Text500);
  margin-top: 16px;
`
const ContactUsButton = styled.a`
  margin-left: 4px;
  text-decoration: None;
  color: var(--Azure500);
`
const OtpTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const ResendMessage = styled.span`
  color: var(--lime-700);
  font-size: 12px;
  font-style: italic;
`

const HelpText = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
  margin-bottom: 24px;
`
const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`
const ResendOtp = styled.button`
  background: none;
  outline: none;
  width: 47%;
  border-radius: 4px;
  border: solid 2px #e7e8eb;
  padding: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text100);
  cursor: pointer;
`
const ResendOtpEnabled = styled(ResendOtp)`
  color: var(--Text500);
`
const ChangeNumber = styled.a`
  color: var(--Text500);
  text-decoration: none;
  width: 47%;
  border-radius: 4px;
  border: solid 2px #e7e8eb;
  padding: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  cursor: pointer;
`
const WrongOtpSpan = styled.span`
  color: var(--Punch500);
  font-size: 12px;
  font-style: italic;
`
const MessageDiv = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--Text700);
`
const ErrorMessageDiv = styled(MessageDiv)`
  color: var(--Punch500);
  margin-top: 80px;
`
const HomeRedirectButton = styled(ContactUsButton)``

const SetupHeading = styled(LoginHeading)`
  margin-top: -30px;
`

const InputContainer = styled.div`
  margin-bottom: 16px;
`
const RadioLabel = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ invalid }) => invalid ? 'var(--Punch500)' : 'var(--Text700)'};
`
const LabelErrorSpan = styled.span`
  font-style: italic;
  font-size: 12px;
`
const OptionsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* justify-content: space-between; */
`
const LangOptionsContainer = styled.div`
  margin-top: 8px;
`
const RadioOption = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 10px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: ${({ active }) =>
    active ? ' var(--Azure500)' : 'var(--Grey300)'};
  // margin-right: 16px;
`
const LangRadioOption = styled(RadioOption)`
  margin-bottom: 8px;
`
const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  border: ${({ active }) =>
    active ? 'solid 1px var(--TextDark500)' : 'solid 1px var(--Text500)'};
  padding: 3px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
`
const RadioNameSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ active }) => (active ? 'var(--TextDark700)' : 'var(--Text500)')};
`

const otpCountdownFrom = 20

const Welcome = ({history, onUpdateUser}) => {
  const [view, changeView] = useState('login') // 'login', 'otp', 'register'
  const [verifyFirebase, setVerifyFirebase] = useState(false)
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('')
  const [isOtpResent, setIsOtpResent] = useState(false)
  const [fullName, updateFullName] = useState('')
  const [email, updateEmail] = useState('')
  const [langPref, updateLangPref] = useState('')
  const [accountType, updateAccountType] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOtpResendProcessing, setIsOtpResendProcessing] = useState(false)
  const [isOtpProcessing, setIsOtpProcessing] = useState(false)
  const [isLoginApiProcessing, setIsLoginApiProcessing] = useState(false)
  const [secondsRemaining, setSeconds] = useState(otpCountdownFrom)
  const [otpError, setOTPError] = useState(false)
  const [isRegisterProcessing, setIsRegisterProcessing] = useState(false)
  const [isRegisterSubmitted, setIsRegisterSubmitted] = useState(false)
  const [tempSessionInfo, setTempSessionInfo] = useState(null)
  const [isPopupShown,setIsPopupShown] = useState(false);
  const [loader,setLoader]=useState(false);
  const [apiError, setApiError] = useState({
    type: '',
    message: ''
  })
  const isMobile = window.screen.width < 600;
  useEffect(() => {
    if (view !== 'otp') return
    if (secondsRemaining) setTimeout(() => setSeconds(secondsRemaining - 1), 1000)
  })

  useEffect(() => {
    const { uid } = getTokenAndUid()
    if (uid) {
      history.push(menuItemObj.audiobooks)
      return
    }
    else 
      checkForGlobalLogin();

  }, [])

  const checkForGlobalLogin=()=>{
    //regular expressions to extract IP and country values
    const countryCodeExpression = /loc=([\w]{2})/;
    const userIPExpression = /ip=([\w\.]+)/;
  
    //automatic country determination.
    const initCountry=()=> {
      return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.timeout = 3000;
          xhr.onreadystatechange = function () {
              if (this.readyState == 4) {
                  if (this.status == 200) {
                      let countryCode = countryCodeExpression.exec(this.responseText)
                      let ip = userIPExpression.exec(this.responseText)
                      if (countryCode === null || countryCode[1] === '' ||
                          ip === null || ip[1] === '') {
                          reject('IP/Country code detection failed');
                      }
                      let result = {
                          "countryCode": countryCode[1],
                          "IP": ip[1]
                      };
                      resolve(result)
                  } else {
                      reject(xhr.status)
                  }
              }
          }
          xhr.ontimeout = function () {
              reject('timeout')
          }
          xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace', true);
          xhr.send();
      });
      }
      initCountry().then(result => {
            if (result.countryCode !== 'IN') {
              history.push('/global-login');
            }
            else{
              setLoader(false);
            }
       }).catch(error=>{
        setLoader(false);
     })
    }

  const handlePhoneInputChange = (text) => {
    setPhoneError('')
    setPhone(text);
  }

  const handleNameInputChange = text => {
    updateFullName(text);
  }

  const handleEmailInputChange = text => {
    updateEmail(text);
  }

  const checkInputValidity = () => {
    setPhoneError('')
    // if (phone.length !== 10 || !phone.match(/[0-9]+$/)) {
    //   setPhoneError('incorrect phone number')
    //   return false
    // }
    if (!isValidPhoneNumber('+' + phone)) {
      setPhoneError('incorrect phone number')
      return false
    }
    return true
  }
  const handlePhoneOtpRequestSend = (isResendRequest) => {
    if (isOtpResendProcessing && isResendRequest) return
    setIsOtpResent(false)
    // send otp to verify number
    if (isResendRequest) {
      setIsOtpResendProcessing(true)
    }

    // let plivoSupportedCountryCodes = []
    let plivoSupportedCountryCodes = ['+91', '+880', '+234', '+1']
    let phoneNumber = (phone.startsWith('+') ? '' : '+') + phone
    let status = plivoSupportedCountryCodes.findIndex((countryCode) => {
      return phoneNumber.startsWith(countryCode)
    }, phoneNumber)
    if ((status === -1)) {
      // firebaseLogin(phoneNumber, isResendRequest)
    } else {
      let otpRequestApi = '/user_api/user.send_otp'
      get(otpRequestApi, {
        params: {
          phone_number: phoneNumber,
          channel: isResendRequest ? 'whatsapp' : null
        }
      })
        .then(() => {
          if (isResendRequest) {
            setIsOtpResent(true)
            setIsOtpResendProcessing(false)
          }
          setIsProcessing(false)
          setSeconds(otpCountdownFrom)
          changeView('otp')
        })
        .catch((e) => {
          notifyBugsnag(e.message || 'unknown api error', {code: e.code, message: e.message || '', method: e.method, url: e.url})
          if (isResendRequest) {
            setIsOtpResendProcessing(false)
          }
          setIsProcessing(false)
          setApiError({ type: 'beforeVerify', message: 'Failed to send otp' })
        })
    }
  }
  const handleOtpCheck = () => {
    // prevent multiple clicks submit
    if (isOtpProcessing) return
    if (otp.length !== 6) return
    setIsOtpProcessing(true)
    // verify number by checking otp here
    setOTPError(false)
    let phoneNumber = (phone.startsWith('+') ? '' : '+') + phone
    let body = {
      phone_number: phoneNumber,
      otp: otp,
    }
    if (verifyFirebase) {
      window.confirmationResult
      .confirm(otp)
      .then(() => {
        setIsOtpProcessing(false)
        handleLogin()
      })
      .catch(() => {
        setIsOtpProcessing(false)
        setApiError({ type: 'beforeVerify', message: 'Failed to verify the otp' })
      })
      setVerifyFirebase(false)
    } else {
      let otpVerifyApi = '/user_api/user.verify_otp'
      // TODO: remove this hardcoding once apis are deployed in qa and prod
      post(otpVerifyApi, {data: body})
      .then((res) => {
        setIsOtpProcessing(false)
        if (res.status === 1) {
          handleLogin()
        } else setOTPError(true)
      })
      .catch(() => {
        setIsOtpProcessing(false)
        setApiError({ type: 'beforeVerify', message: 'Failed to verify the otp' })
      })
    }
  }
  const handleSmsLogin = () => {
    // handle multiple submit clicks
    if (isProcessing) return
    const validity = checkInputValidity()
    if (!validity) return
    setIsProcessing(true)
    handlePhoneOtpRequestSend()
  }
  const handleChangeOtp = otp => {
    setOtp(otp)
  }
  const resetLogin = () => {
    changeView('login')
    setPhone('')
    setOtp('')
    setPhoneError('')
    setIsOtpResent(false)
    setOTPError(false)
  }
  
  const handleAccTypeUpdate = (actype) => {
    // let currValues = accountType.split(',').filter(Boolean)
    // if (currValues.includes(actype)) {
    //   currValues = currValues.filter((v) => v !== actype)
    // } else {
    //   currValues.push(actype)
    // }
    // const newValue = currValues.join(',')
    updateAccountType(actype)
  }

  const handleLangUpdate = (lang) => {
    let currValues = langPref.split(',').filter(Boolean)
    if (currValues.includes(lang)) {
      currValues = currValues.filter((v) => v !== lang)
    } else {
      currValues.push(lang)
    }
    const newValue = currValues.join(',')
    updateLangPref(newValue)    
    // updateLangPref(lang)    
  }

  const handleRegisterFormSubmit = () => {
    // handle multiple submit clicks
    if (isRegisterProcessing) return
    setIsRegisterSubmitted(true) // this is to show register form errors on submit click, doesnt needed to be reset back
    const isValid = validateFields()
    if (!isValid) return

    setIsRegisterProcessing(true)

    const { uid, token } = tempSessionInfo || {}
    if (!uid) return

    // send username to update user api
    let phoneNumber = (phone.startsWith('+') ? '' : '+') + phone
    let userData = {
      phone_number: phoneNumber,
      view: 'dashboard',
      fullname: fullName,
      email: email,
      role: getRoleStringFromAccountType(accountType)
    }

    post('/user_api/user.update?is_novel=0', {
      data: userData,
      headers: { uid, 'access-token': token },
    })
    const referrer = localStorage.getItem('referrer')
    const creatorData = {
      account_type: accountType,
      languages: langPref.toLowerCase(),
      fullname: fullName,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      source_platform: 'studio',
      device_id: localStorage.getItem('uuid'),
    }
    if(referrer && (accountType === 'Author')){
      creatorData['referrer'] = `studio_${referrer}`
    }
    post('/user_api/user.create_creator_account?is_novel=0', {
      data: creatorData,
      headers: {
        uid,
        'access-token': token,
        'device-type':isMobile?'Mobile':'Desktop'
      },
    }).then(() => {
      saveSession(tempSessionInfo)
      onUpdateUser({ account_type: accountType })
      if(accountType === 'Author') {
        handleEventLog('register', 'signup_sucess', { phone_number: phoneNumber, route: window.location.pathname + window.location.search, uid })
        setIsPopupShown(true)
      } else {
        history.push(menuItemObj.audiobooks)
      }
      setIsRegisterProcessing(false)
      // tempSessionInfo && saveSession({...tempSessionInfo, name: fullName})
      
    }).catch(error => {
      setIsRegisterProcessing(false)
      console.log(error)
      setApiError({type: 'afterVerify', message: 'Something went wrong while setting up your account.'})
    })
  }

  const validateFields = () => {
    return !!langPref && !!accountType && !!fullName && !!email
  }

  const handleLogin = () => {
    // call login api after number is verified
    setIsLoginApiProcessing(true)
    let phoneNumber = (phone.startsWith('+') ? '' : '+') + phone
    let loginData = {
      account_kit_access_token: otp,
      type: 'account_kit',
      phone_number: phoneNumber,
      view: 'dashboard',
      user_type: 'pugc',
      device_id: localStorage.getItem('uuid'),
    }

    post('/user_api/user.login', { data: loginData })
      .then((response) => {
        setIsLoginApiProcessing(false)
        const { user_info = {} } = response
        let {
          uid,
          access_token: token,
          image_url: userImageUrl,
          fullname: userName,
        } = user_info
        let isUserExist = response?.already_exists

        const sessionInfo = {
          uid,
          token,
          image_url: userImageUrl,
          name: userName,
          phone: phoneNumber
        }
        if (typeof isUserExist == 'undefined' || isUserExist === 0) {
          // dont save sessioninfo to localstorage yet, wait for the user to register
          // if the user drops off at this point, no need to save userinfo to localstorage
          // otherwise user will be directly logged in next time user opens studio and wont be able to select account type
          changeView('register')
          setTempSessionInfo(sessionInfo)
        } else if (isUserExist === 1) {
          saveSession(sessionInfo)
          get('/user_api/user.get_creator_details?is_novel=0').then(res => {
            onUpdateUser(res.result)
            let { account_type } = res.result
            if (account_type === 'Author') {
              history.push(menuItemObj.home)
            } else {
              history.push(menuItemObj.audiobooks)
            }
          })
        }

      }).catch((error) => {
        setIsLoginApiProcessing(false)
        console.log(error)
        setApiError({type: 'afterVerify', message: error.message || 'Something went wrong while loggin in.'})
      })
  }

  const authorSelected = accountType.includes('Author') ? 'true' : undefined
  const publisherSelected = accountType.includes('Publisher') ? 'true' : undefined
  const voartistSelected = accountType.includes('VO Artist')
  const writerAgencySelected = accountType.includes('Writing Agency')
  const productionHouseSelected = accountType.includes('Production House')
    ? 'true'
    : undefined

  const recordingStudioSelected = accountType.includes('recording_studio')
    ? 'true'
    : undefined

  const [nameMissing, emailMissing, accTypeMissing, langPrefMissing] = [
    fullName,
    email,
    accountType,
    langPref
  ].map((field) => !field && isRegisterSubmitted)

  return (
    <Container>
       {loader && 
      <Loader></Loader>}
        {!loader && 
      <MainDiv>
        <LeftDiv>
          <IconHolder>
            <img src={LoginIcon} />
          </IconHolder>
        </LeftDiv>
        <RightDiv>
          <LogoHolder>
            <StudioLogo />
          </LogoHolder>

          {view === 'login' && !apiError.type && !isLoginApiProcessing && (
            <>
              <LoginHeading>
                Enter your mobile number to continue
              </LoginHeading>

              {/* <FloatingInput
                error={phoneError}
                type="text"
                name="number"
                label="Mobile Number"
                value={phone}
                maxLength={10}
                onChange={text => handlePhoneInputChange(text)}
              /> */}

              <PhoneInput
                error={phoneError}
                type="text"
                value={phone}
                onChange={text => handlePhoneInputChange(text)}
              />

              <LoginButton id="login-handler" onClick={handleSmsLogin} disabled={!isValidPhoneNumber('+' + phone)}>
                  {isProcessing ? <Loader size='sm' invertColor noPadding /> : 'continue'}
              </LoginButton>
            </>
          )}

          {view === 'otp' && !apiError.type && !isLoginApiProcessing && (
            <>
              <OtpTitle>
                Enter your Code {otpError && <WrongOtpSpan> - incorrect OTP</WrongOtpSpan>}
                {isOtpResent && !otpError && <ResendMessage> - OTP sent again</ResendMessage>}
              </OtpTitle>

              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                onChangeOTP={otp => handleChangeOtp(otp)}
                inputClassName={otpError ? 'error' : ''}
              />

              <LoginButton onClick={handleOtpCheck} disabled={otp.length !== 6}>
                  {isOtpProcessing ? <Loader size='sm' invertColor noPadding /> : 'continue'}
              </LoginButton>

              <HelpText>
                We send an SMS with a 6-digit code to +{phone}
              </HelpText>

              <ActionButtons>
                {secondsRemaining ? (
                  <ResendOtp>RESEND OTP in {secondsRemaining} s</ResendOtp>
                ) : (
                  <ResendOtpEnabled id='otp-handler' onClick={() => handlePhoneOtpRequestSend(true)}>
                    {isOtpResendProcessing ? <Loader size='sm' noPadding /> : (<>{'RESEND OTP on '} <StyledWhatsappIcon /></>)}
                  </ResendOtpEnabled>
                )}
                <ChangeNumber href="/">EDIT NUMBER</ChangeNumber>
              </ActionButtons>
            </>
          )}

          {apiError.type && (
            <ErrorMessageDiv>
              {apiError.message} click<HomeRedirectButton href="/">Here</HomeRedirectButton> to refresh
            </ErrorMessageDiv>
          )}

          {isLoginApiProcessing && !apiError.type && (
            <>
              <Loader size='md' fjordColor inline/>
              <MessageDiv>
                Please wait we are logging you in
              </MessageDiv>
            </>
          )}

          {view === 'register' && !apiError.type && !isLoginApiProcessing && (
            <>
              <SetupHeading>
                Let’s Setup your account
              </SetupHeading>

              <Box mb={16} >
                <FloatingInput
                  error={nameMissing}
                  type="text"
                  name="full-name"
                  label="Your Name"
                  value={fullName}
                  onChange={text => handleNameInputChange(text)}
                />
              </Box>

              <Box mb={16} >
                <FloatingInput
                  error={emailMissing}
                  type="text"
                  name="email"
                  label="Your Email"
                  value={email}
                  onChange={text => handleEmailInputChange(text)}
                />
              </Box>

              {
                // language select radio buttons ----------------
              }


              <InputContainer>
                <RadioLabel invalid={langPrefMissing}>
                  Language Proficiency{' '}
                  {langPrefMissing && <LabelErrorSpan> - required</LabelErrorSpan>}
                </RadioLabel>
                <LangOptionsContainer>
                  {/* <DropdownMultiSelect
                    name="langPref"
                    dropdownHeight="256"
                    placeholder="Click to select languages"
                    value={langPref}
                    onChange={languages => updateLangPref(languages)}
                    options={
                      [
                        {id: 1, name: 'hindi'},
                        {id: 2, name: 'tamil'},
                        {id: 3, name: 'bengali'},
                        {id: 4, name: 'malayalam'},
                        {id: 5, name: 'telugu'},
                        {id: 6, name: 'marathi'},
                        {id: 7, name: 'kannada'},
                        {id: 8, name: 'english'},
                      ]
                    }
                  /> */}
                    <DropDownSelect
                      name="langPref"
                      placeholder="Click to select language"
                      selectOptions={[
                        { id: 1, name: 'hindi' },
                        { id: 2, name: 'tamil' },
                        { id: 3, name: 'bengali' },
                        { id: 4, name: 'malayalam' },
                        { id: 5, name: 'telugu' },
                        { id: 6, name: 'marathi' },
                        { id: 7, name: 'kannada' },
                        { id: 8, name: 'english' },
                        { id: 9, name: 'spanish' },
                      ]}
                      idKey="name"
                      nameKey="name"
                      value={langPref}
                      onChange={(language) => updateLangPref(language)}
                    />
                  </LangOptionsContainer>
                </InputContainer>

                {
                  // account type select radio buttons ----------------
                }

                <InputContainer>
                  <RadioLabel invalid={accTypeMissing}>
                    Account Type{' '}
                    {accTypeMissing && (
                      <LabelErrorSpan> - required</LabelErrorSpan>
                    )}
                  </RadioLabel>
                  <OptionsContainer>
                    <RadioOption
                      onClick={() => handleAccTypeUpdate('Production House')}
                      active={productionHouseSelected}
                    >
                      <RadioIcon active={productionHouseSelected}>
                        <CheckedIcon active={productionHouseSelected} />
                      </RadioIcon>
                      <RadioNameSpan active={productionHouseSelected}>
                        Production House
                      </RadioNameSpan>
                    </RadioOption>
                    <RadioOption
                      onClick={() => handleAccTypeUpdate('Author')}
                      active={authorSelected}
                    >
                      <RadioIcon active={authorSelected}>
                        <CheckedIcon active={authorSelected} />
                      </RadioIcon>
                      <RadioNameSpan active={authorSelected}>
                        Author/Writer
                      </RadioNameSpan>
                    </RadioOption>
                    <RadioOption
                      onClick={() => handleAccTypeUpdate('recording_studio')}
                      active={recordingStudioSelected}
                    >
                      <RadioIcon active={recordingStudioSelected}>
                        <CheckedIcon active={recordingStudioSelected} />
                      </RadioIcon>
                      <RadioNameSpan active={recordingStudioSelected}>
                        Recording Studio
                      </RadioNameSpan>
                    </RadioOption>
                    <RadioOption
                      onClick={() => handleAccTypeUpdate('Writing Agency')}
                      active={writerAgencySelected}
                    >
                      <RadioIcon active={writerAgencySelected}>
                        <CheckedIcon active={writerAgencySelected} />
                      </RadioIcon>
                      <RadioNameSpan active={writerAgencySelected}>
                        Writing Agency
                      </RadioNameSpan>
                    </RadioOption>

                    <RadioOption
                      onClick={() => handleAccTypeUpdate('VO Artist')}
                      active={voartistSelected}
                    >
                      <RadioIcon active={voartistSelected}>
                        <CheckedIcon active={voartistSelected} />
                      </RadioIcon>
                      <RadioNameSpan active={voartistSelected}>
                        VO Artist
                      </RadioNameSpan>
                    </RadioOption>

                    <RadioOption
                      onClick={() => handleAccTypeUpdate('Publisher')}
                      active={publisherSelected}
                    >
                      <RadioIcon active={publisherSelected}>
                        <CheckedIcon active={publisherSelected} />
                      </RadioIcon>
                      <RadioNameSpan active={publisherSelected}>
                        Publisher
                      </RadioNameSpan>
                    </RadioOption>
                </OptionsContainer>
            </InputContainer>

            <LoginButton onClick={handleRegisterFormSubmit} disabled={!validateFields()}>
                {isRegisterProcessing ? <Loader size='sm' invertColor noPadding /> : 'continue'}
            </LoginButton>
              {isPopupShown && <WriterPopup setIsPopupShown={setIsPopupShown} url="https://writer.pocketnovel.com/?referrer=Studio_SignUp"/>}
            </>
          )}
          
          {view !== 'create-book' && <ContactUsDiv>
            <span>Need help?</span>
            <ContactUsButton href="mailto:care@pocketfm.in">Contact Us</ContactUsButton>
          </ContactUsDiv>}
        </RightDiv>
      </MainDiv>}
    </Container>
  )
}

const WelcomePageWrapper = props => (
  <AccountContext.Consumer>
    {({ onUpdateUser, user }) => (
      <Welcome
        onUpdateUser={onUpdateUser}
        user={user}
        {...props}
      />
    )}
  </AccountContext.Consumer>
)

export default withRouter(WelcomePageWrapper)
