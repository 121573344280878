import React from 'react'

const CalendarIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path fillOpacity=".65" d="M5.5 2.5c.275 0 .5.224.5.5v1.667c0 .276-.225.5-.5.5-.277 0-.5-.224-.5-.5V3c0-.276.223-.5.5-.5zM4.166 10.5c0-.276.224-.5.5-.5h8.333c.276 0 .5.224.5.5s-.224.5-.5.5H4.666c-.276 0-.5-.224-.5-.5zM4.666 12.5c-.276 0-.5.224-.5.5s.224.5.5.5h5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-5z"/>
    <path fillOpacity=".65" fillRule="evenodd" d="M16.666 4.333h-1.792v-1h1.875c.507 0 .917.41.917.917v12.083c0 .507-.41.917-.917.917H2.583c-.507 0-.917-.41-.917-.917V4.25c0-.506.41-.917.917-.917h1.874v1H2.666v1.5h14v-1.5zm-14 2.5h14v9.417h-14V6.833z" clipRule="evenodd"/>
    <path fillOpacity=".65" d="M6.54 3.333h6.25v1H6.54v-1zM14.333 3c0-.276-.224-.5-.5-.5-.277 0-.5.224-.5.5v1.667c0 .276.223.5.5.5.276 0 .5-.224.5-.5V3z"/>
  </svg>
)

export default CalendarIcon
