import React from 'react'

const StarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    fill="none"
    viewBox="0 0 8 8"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity=".99"
      d="M6.893 3.546c-.022-.063-.071-.11-.132-.121l-1.664-.254-.75-1.573C4.32 1.54 4.26 1.5 4.2 1.5c-.061 0-.122.04-.15.098l-.743 1.58-1.664.253c-.061.011-.116.057-.133.12-.022.064-.005.133.044.18L2.76 4.957l-.286 1.735c-.011.063.016.132.066.173.05.04.121.046.176.011l1.488-.818 1.488.818c.022.012.05.018.077.018.094 0 .165-.081.165-.173 0-.018 0-.03-.005-.04l-.281-1.724L6.855 3.73c.038-.051.055-.12.038-.184z"
    />
  </svg>
)

export default StarIcon
