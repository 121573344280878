import React from 'react'

const Reorder = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="#068AE2" d="M22.62 25.516c.08-.032.15-.08.21-.138l3.825-3.826c.25-.249.25-.652 0-.901-.25-.25-.653-.25-.902 0l-2.738 2.737V9.623c0-.352-.285-.637-.638-.637h-5.101c-.353 0-.638.285-.638.637 0 .352.285.638.638.638h4.464v13.127l-2.738-2.737c-.25-.25-.652-.25-.902 0-.124.124-.187.287-.187.45 0 .164.063.327.187.451l3.826 3.826c.058.06.13.106.208.138.155.065.331.065.487 0zM15.363 22.377c0-.353-.285-.638-.637-.638h-4.464V8.612l2.737 2.738c.25.249.653.249.902 0 .25-.25.25-.653 0-.902l-3.825-3.826c-.059-.059-.13-.105-.208-.138-.156-.064-.332-.064-.487 0-.079.033-.149.08-.208.138l-3.826 3.826c-.124.124-.187.287-.187.45 0 .164.063.327.187.452.25.249.653.249.902 0l2.737-2.738v13.765c0 .353.286.638.638.638h5.102c.352 0 .637-.285.637-.638z"/>
    <path fill="#068AE2" d="M30.666 27.478V4.522c0-1.758-1.43-3.189-3.188-3.189H4.52c-1.758 0-3.188 1.43-3.188 3.189v22.956c0 1.758 1.43 3.189 3.189 3.189h22.956c1.758 0 3.188-1.43 3.188-3.189zM4.522 29.391c-1.055 0-1.914-.858-1.914-1.913V4.522c0-1.055.858-1.913 1.913-1.913h22.957c1.054 0 1.913.858 1.913 1.913v22.956c0 1.055-.859 1.913-1.913 1.913H4.522z"/>
  </svg>
)

export default Reorder
