import { ChangeNavDrawerItem } from "./actionTypes";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ChangeNavDrawerItem: {
      const {id} = action.payload;
      return {
        ...state,
        id: id
      };
    }
    default :
    return {
        ...state
    }
  }
}