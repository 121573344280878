const audiobooks = [
  { name: 'Self Help Audiobook', slug: 'selfHelfBook' },
  { name: 'Society and Culture', slug: 'societyAndCulture' },
  {
    name: 'Mythological Thriller Audiobooks',
    slug: 'mythologicalThrillerAudiobooks',
  },
  { name: 'Suspense and Thriller', slug: 'suspenseAndThriller' },
  { name: 'Horror Audiobook', slug: 'horrorAudiobook' },
  { name: 'Crime Audiobooks', slug: 'crimeAudiobooks' },
  { name: 'Fantasy Audiobooks', slug: 'fantasyAudiobooks' },
  { name: 'Classic Audiobooks', slug: 'classicAudiobooks' },
  { name: 'Stories', slug: 'stories' },
  { name: 'Romantic Audiobook', slug: 'romanticAudiobook' },
]

const novelsTopics = [
  {
    language: 'hindi',
    topic_id: '1c42d6fed3c2342d391be5d92bcd22bb16b84b73',
    type: 'Series',
    sub_topics: [
      {
        topic_id: 'ed41467b4c0870a67cf8a8849b9ef73885599c63',
        topic_name: 'Romance',
      },
      {
        topic_id: 'c85fd5b41284966384d9b428212df112df696302',
        topic_name: 'Suspense and Thriller',
      },
    ],
  },
  {
    language: 'english',
    topic_id: '5fc60105f469b212c7d3f8f2b8bf0f91d38d00fd',
    type: 'Series',
    sub_topics: [
      {
        topic_id: '7ae626f7d428b85bfbe09b0cca10b9f391c16eea',
        topic_name: 'Romance',
      },
      {
        topic_id: 'f52b29256db53d92b2c1f309d7d76c1260383b87',
        topic_name: 'Suspense and Thriller',
      },
    ],
  },
]

const tags = [
  {
    name: 'Ambitious Girl',
    id: '4f9065d1ad65e1fc28f0cc7623461165e1662367',
  },
  {
    name: 'Arranged Marriage',
    id: '73cc1e7c06abe2e74932cf91dfbd12b44db6c54b',
  },
  {
    name: 'Arrogant Guy',
    id: '160fe4c0c097673f89a60e145a639bfce3c6f678',
  },
  {
    name: 'Aspiring Actress',
    id: 'f4a9a27a27ad4ec9e060f3c6cc78aeeed9ea1bfb',
  },
  {
    name: 'Aspiring Girl',
    id: 'cd3b51a1226e36f28fe45607dabc04675922e221',
  },
  {
    name: 'Betrayal',
    id: 'af6510ca5403984995b99376d42282d081641fd7',
  },
  {
    name: 'Boy Hates Girl',
    id: '73b898e41e5999c2acbada2913bdf443fd5a551d',
  },
  {
    name: 'Celebrity',
    id: '243d9cab5b7db34b04ca0acf5604c5facdec1e2e',
  },
  {
    name: 'Childhood Friends',
    id: '8a53c7c6f47bd37b71ccdc3f8cfeddff658c81f8',
  },
  {
    name: 'Childhood Love',
    id: '01331455bfc7f0aa9aa8d27d5c0f7e7b23ec0772',
  },
  {
    name: 'College Life',
    id: '3132003ddb8cf903800d59469d58dfe762c65431',
  },
  {
    name: 'College Romance',
    id: '52e558fc07f21e8db8586a485506cada4440b53b',
  },
  {
    name: 'Conspiracy',
    id: '92b1acc6118e38f7c303c7f143eb16635ccdb1fb',
  },
  {
    name: 'Contract Marriage',
    id: 'db81ef170d39f6ed67567b08ee2efd2022507b43',
  },
  {
    name: 'Corporate Love',
    id: '09d8c291e963dfab680d1d0de12a450a79e27c5a',
  },
  {
    name: 'Cute Child',
    id: '866d7a09accdc0951f5a3417873a0bd7ac4bbddc',
  },
  {
    name: 'Dark Past',
    id: 'a20d4436077854f12871437dedae4befb207b7c8',
  },
  {
    name: 'Divorce',
    id: 'b348022534dc960b6e9ea5b0864a8b8eb68c261a',
  },
  {
    name: 'Extra-Marrital Affair',
    id: 'ef1b0fc6af48d4d202254a461e7421c6f907b658',
  },
  {
    name: 'Falling in Love Again',
    id: '41e80a65ce5249697881a9ac3c1512a74ded8bce',
  },
  {
    name: "Father's Love",
    id: '49421923cb7cd866856b0cd7482f2717f3b780fa',
  },
  {
    name: 'Forced Marriage',
    id: 'c36ebf56be4a65acef89ebfdc47c3f2838788e49',
  },
  {
    name: 'Husband-Wife',
    id: '486e7658507b662a2d5fc60cb6ccb48c5faacb80',
  },
  {
    name: 'Live-in Relationship',
    id: 'c3634ba14b008e2e99ec3b90a8609868a9665cf7',
  },
  {
    name: 'Love Marriage',
    id: 'f1fe4f21e99e2fb1c2c8347e8a43980b25830265',
  },
  {
    name: 'Love Triangle',
    id: '806aa53923cebed553129a271096a7f123024629',
  },
  {
    name: 'Love vs Friendship',
    id: '2c1a1693129b788465a17afbad03c00561527b74',
  },
  {
    name: 'Memory Loss',
    id: '4599b4e18e618753b98db6bd632ceacf4e8bdc23',
  },
  {
    name: 'Modern Romance',
    id: 'e8fc874fb95d124a81676142c0cb21f2fd82b386',
  },
  {
    name: "Mother's Love",
    id: '776c9dd75792581d9bd88144b007e92ead3b2418',
  },
  {
    name: 'One-sided Love',
    id: 'aa44c9d5841a6a61efdf7e14b99defbec534a997',
  },
  {
    name: 'Re-birth',
    id: '1b2b18323dbff4f511dd8a87913d13ed5ca223fd',
  },
  {
    name: 'Rebel',
    id: '2efabfd7b6fd62f6ad1463640c11712cffe00400',
  },
  {
    name: 'Revenge',
    id: '91daf1f87e612fd018c2fd12cf14277a2ff153c2',
  },
  {
    name: 'Rich Guy',
    id: 'e46c8b40f2e5d9985a00fc62e8506b0bac99c07a',
  },
  {
    name: 'Road Trip',
    id: 'fa7a8ea480cb25a247d248a179b5fb032a25fc63',
  },
  {
    name: 'Secret Love',
    id: '156d542ce098da352ae7bdbe17e4e33996fff95c',
  },
  {
    name: 'Single Parent',
    id: 'df7d595ec8af33cfcdcbea426c565cd81c0df7ce',
  },
  {
    name: 'Small Town',
    id: 'edd24a5ef0fa7969387024ade1f86d8c54e0b565',
  },
  {
    name: 'Strong Women',
    id: '89ec76807c920ff8c235eeaec0b96549749ac058',
  },
  {
    name: 'Teenage Love',
    id: '47f4314a1e55d6bd9f449d515c5e0e29cda908bc',
  },
  {
    name: 'Women Power',
    id: '8c5a2d561c0fed5cbc9bb7377f9b6d0c493edef1',
  },
]

const audioSeries = [
  { name: 'Romantic Audio-Series', slug: 'romanticAudioSeries' },
  { name: 'Horror Audio-Series', slug: 'horrorAudioSeries' },
  {
    name: 'Suspense and Thriller Audio Series',
    slug: 'suspenceAndThrillerAudioSeries',
  },
  { name: 'Family Drama', slug: 'familyDrama' },
  { name: 'Historical Drama', slug: 'historicalDrama' },
]
const countryList=
[
  {"name":"Global","code":"WW"},
  {"name": "Albania", "code": "AL"},
  {"name": "Åland Islands", "code": "AX"},
  {"name": "Algeria", "code": "DZ"},
  {"name": "American Samoa", "code": "AS"},
  {"name": "Andorra", "code": "AD"},
  {"name": "Angola", "code": "AO"},
  {"name": "Anguilla", "code": "AI"},
  {"name": "Antarctica", "code": "AQ"},
  {"name": "Antigua and Barbuda", "code": "AG"},
  {"name": "Argentina", "code": "AR"},
  {"name": "Armenia", "code": "AM"},
  {"name": "Aruba", "code": "AW"},
  {"name": "Australia", "code": "AU"},
  {"name": "Austria", "code": "AT"},
  {"name": "Azerbaijan", "code": "AZ"},
  {"name": "Bahamas (the)", "code": "BS"},
  {"name": "Bahrain", "code": "BH"},
  {"name": "Bangladesh", "code": "BD"},
  {"name": "Barbados", "code": "BB"},
  {"name": "Belarus", "code": "BY"},
  {"name": "Belgium", "code": "BE"},
  {"name": "Belize", "code": "BZ"},
  {"name": "Benin", "code": "BJ"},
  {"name": "Bermuda", "code": "BM"},
  {"name": "Bhutan", "code": "BT"},
  {"name": "Bolivia (Plurinational State of)", "code": "BO"},
  {"name": "Bonaire, Sint Eustatius and Saba", "code": "BQ"},
  {"name": "Bosnia and Herzegovina", "code": "BA"},
  {"name": "Botswana", "code": "BW"},
  {"name": "Bouvet Island", "code": "BV"},
  {"name": "Brazil", "code": "BR"},
  {"name": "British Indian Ocean Territory (the)", "code": "IO"},
  {"name": "Brunei Darussalam", "code": "BN"},
  {"name": "Bulgaria", "code": "BG"},
  {"name": "Burkina Faso", "code": "BF"},
  {"name": "Burundi", "code": "BI"},
  {"name": "Cabo Verde", "code": "CV"},
  {"name": "Cambodia", "code": "KH"},
  {"name": "Cameroon", "code": "CM"},
  {"name": "Canada", "code": "CA"},
  {"name": "Cayman Islands (the)", "code": "KY"},
  {"name": "Central African Republic (the)", "code": "CF"},
  {"name": "Chad", "code": "TD"},
  {"name": "Chile", "code": "CL"},
  {"name": "China", "code": "CN"},
  {"name": "Christmas Island", "code": "CX"},
  {"name": "Cocos (Keeling) Islands (the)", "code": "CC"},
  {"name": "Colombia", "code": "CO"},
  {"name": "Comoros (the)", "code": "KM"},
  {"name": "Congo (the Democratic Republic of the)", "code": "CD"},
  {"name": "Congo (the)", "code": "CG"},
  {"name": "Cook Islands (the)", "code": "CK"},
  {"name": "Costa Rica", "code": "CR"},
  {"name": "Croatia", "code": "HR"},
  {"name": "Cuba", "code": "CU"},
  {"name": "Curaçao", "code": "CW"},
  {"name": "Cyprus", "code": "CY"},
  {"name": "Czechia", "code": "CZ"},
  {"name": "Côte d'Ivoire", "code": "CI"},
  {"name": "Denmark", "code": "DK"},
  {"name": "Djibouti", "code": "DJ"},
  {"name": "Dominica", "code": "DM"},
  {"name": "Dominican Republic (the)", "code": "DO"},
  {"name": "Ecuador", "code": "EC"},
  {"name": "Egypt", "code": "EG"},
  {"name": "El Salvador", "code": "SV"},
  {"name": "Equatorial Guinea", "code": "GQ"},
  {"name": "Eritrea", "code": "ER"},
  {"name": "Estonia", "code": "EE"},
  {"name": "Eswatini", "code": "SZ"},
  {"name": "Ethiopia", "code": "ET"},
  {"name": "Falkland Islands (the) [Malvinas]", "code": "FK"},
  {"name": "Faroe Islands (the)", "code": "FO"},
  {"name": "Fiji", "code": "FJ"},
  {"name": "Finland", "code": "FI"},
  {"name": "France", "code": "FR"},
  {"name": "French Guiana", "code": "GF"},
  {"name": "French Polynesia", "code": "PF"},
  {"name": "French Southern Territories (the)", "code": "TF"},
  {"name": "Gabon", "code": "GA"},
  {"name": "Gambia (the)", "code": "GM"},
  {"name": "Georgia", "code": "GE"},
  {"name": "Germany", "code": "DE"},
  {"name": "Ghana", "code": "GH"},
  {"name": "Gibraltar", "code": "GI"},
  {"name": "Greece", "code": "GR"},
  {"name": "Greenland", "code": "GL"},
  {"name": "Grenada", "code": "GD"},
  {"name": "Guadeloupe", "code": "GP"},
  {"name": "Guam", "code": "GU"},
  {"name": "Guatemala", "code": "GT"},
  {"name": "Guernsey", "code": "GG"},
  {"name": "Guinea", "code": "GN"},
  {"name": "Guinea-Bissau", "code": "GW"},
  {"name": "Guyana", "code": "GY"},
  {"name": "Haiti", "code": "HT"},
  {"name": "Heard Island and McDonald Islands", "code": "HM"},
  {"name": "Holy See (the)", "code": "VA"},
  {"name": "Honduras", "code": "HN"},
  {"name": "Hong Kong", "code": "HK"},
  {"name": "Hungary", "code": "HU"},
  {"name": "Iceland", "code": "IS"},
  {"name": "India", "code": "IN"},
  {"name": "Indonesia", "code": "ID"},
  {"name": "Iran (Islamic Republic of)", "code": "IR"},
  {"name": "Iraq", "code": "IQ"},
  {"name": "Ireland", "code": "IE"},
  {"name": "Isle of Man", "code": "IM"},
  {"name": "Israel", "code": "IL"},
  {"name": "Italy", "code": "IT"},
  {"name": "Jamaica", "code": "JM"},
  {"name": "Japan", "code": "JP"},
  {"name": "Jersey", "code": "JE"},
  {"name": "Jordan", "code": "JO"},
  {"name": "Kazakhstan", "code": "KZ"},
  {"name": "Kenya", "code": "KE"},
  {"name": "Kiribati", "code": "KI"},
  {"name": "Korea (the Democratic People's Republic of)", "code": "KP"},
  {"name": "Korea (the Republic of)", "code": "KR"},
  {"name": "Kuwait", "code": "KW"},
  {"name": "Kyrgyzstan", "code": "KG"},
  {"name": "Lao People's Democratic Republic (the)", "code": "LA"},
  {"name": "Latvia", "code": "LV"},
  {"name": "Lebanon", "code": "LB"},
  {"name": "Lesotho", "code": "LS"},
  {"name": "Liberia", "code": "LR"},
  {"name": "Libya", "code": "LY"},
  {"name": "Liechtenstein", "code": "LI"},
  {"name": "Lithuania", "code": "LT"},
  {"name": "Luxembourg", "code": "LU"},
  {"name": "Macao", "code": "MO"},
  {"name": "Madagascar", "code": "MG"},
  {"name": "Malawi", "code": "MW"},
  {"name": "Malaysia", "code": "MY"},
  {"name": "Maldives", "code": "MV"},
  {"name": "Mali", "code": "ML"},
  {"name": "Malta", "code": "MT"},
  {"name": "Marshall Islands (the)", "code": "MH"},
  {"name": "Martinique", "code": "MQ"},
  {"name": "Mauritania", "code": "MR"},
  {"name": "Mauritius", "code": "MU"},
  {"name": "Mayotte", "code": "YT"},
  {"name": "Mexico", "code": "MX"},
  {"name": "Micronesia (Federated States of)", "code": "FM"},
  {"name": "Moldova (the Republic of)", "code": "MD"},
  {"name": "Monaco", "code": "MC"},
  {"name": "Mongolia", "code": "MN"},
  {"name": "Montenegro", "code": "ME"},
  {"name": "Montserrat", "code": "MS"},
  {"name": "Morocco", "code": "MA"},
  {"name": "Mozambique", "code": "MZ"},
  {"name": "Myanmar", "code": "MM"},
  {"name": "Namibia", "code": "NA"},
  {"name": "Nauru", "code": "NR"},
  {"name": "Nepal", "code": "NP"},
  {"name": "Netherlands (the)", "code": "NL"},
  {"name": "New Caledonia", "code": "NC"},
  {"name": "New Zealand", "code": "NZ"},
  {"name": "Nicaragua", "code": "NI"},
  {"name": "Niger (the)", "code": "NE"},
  {"name": "Nigeria", "code": "NG"},
  {"name": "Niue", "code": "NU"},
  {"name": "Norfolk Island", "code": "NF"},
  {"name": "Northern Mariana Islands (the)", "code": "MP"},
  {"name": "Norway", "code": "NO"},
  {"name": "Oman", "code": "OM"},
  {"name": "Pakistan", "code": "PK"},
  {"name": "Palau", "code": "PW"},
  {"name": "Palestine, State of", "code": "PS"},
  {"name": "Panama", "code": "PA"},
  {"name": "Papua New Guinea", "code": "PG"},
  {"name": "Paraguay", "code": "PY"},
  {"name": "Peru", "code": "PE"},
  {"name": "Philippines (the)", "code": "PH"},
  {"name": "Pitcairn", "code": "PN"},
  {"name": "Poland", "code": "PL"},
  {"name": "Portugal", "code": "PT"},
  {"name": "Puerto Rico", "code": "PR"},
  {"name": "Qatar", "code": "QA"},
  {"name": "Republic of North Macedonia", "code": "MK"},
  {"name": "Romania", "code": "RO"},
  {"name": "Russian Federation (the)", "code": "RU"},
  {"name": "Rwanda", "code": "RW"},
  {"name": "Réunion", "code": "RE"},
  {"name": "Saint Barthélemy", "code": "BL"},
  {"name": "Saint Helena, Ascension and Tristan da Cunha", "code": "SH"},
  {"name": "Saint Kitts and Nevis", "code": "KN"},
  {"name": "Saint Lucia", "code": "LC"},
  {"name": "Saint Martin (French part)", "code": "MF"},
  {"name": "Saint Pierre and Miquelon", "code": "PM"},
  {"name": "Saint Vincent and the Grenadines", "code": "VC"},
  {"name": "Samoa", "code": "WS"},
  {"name": "San Marino", "code": "SM"},
  {"name": "Sao Tome and Principe", "code": "ST"},
  {"name": "Saudi Arabia", "code": "SA"},
  {"name": "Senegal", "code": "SN"},
  {"name": "Serbia", "code": "RS"},
  {"name": "Seychelles", "code": "SC"},
  {"name": "Sierra Leone", "code": "SL"},
  {"name": "Singapore", "code": "SG"},
  {"name": "Sint Maarten (Dutch part)", "code": "SX"},
  {"name": "Slovakia", "code": "SK"},
  {"name": "Slovenia", "code": "SI"},
  {"name": "Solomon Islands", "code": "SB"},
  {"name": "Somalia", "code": "SO"},
  {"name": "South Africa", "code": "ZA"},
  {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
  {"name": "South Sudan", "code": "SS"},
  {"name": "Spain", "code": "ES"},
  {"name": "Sri Lanka", "code": "LK"},
  {"name": "Sudan (the)", "code": "SD"},
  {"name": "Suriname", "code": "SR"},
  {"name": "Svalbard and Jan Mayen", "code": "SJ"},
  {"name": "Sweden", "code": "SE"},
  {"name": "Switzerland", "code": "CH"},
  {"name": "Syrian Arab Republic", "code": "SY"},
  {"name": "Taiwan (Province of China)", "code": "TW"},
  {"name": "Tajikistan", "code": "TJ"},
  {"name": "Tanzania, United Republic of", "code": "TZ"},
  {"name": "Thailand", "code": "TH"},
  {"name": "Timor-Leste", "code": "TL"},
  {"name": "Togo", "code": "TG"},
  {"name": "Tokelau", "code": "TK"},
  {"name": "Tonga", "code": "TO"},
  {"name": "Trinidad and Tobago", "code": "TT"},
  {"name": "Tunisia", "code": "TN"},
  {"name": "Turkey", "code": "TR"},
  {"name": "Turkmenistan", "code": "TM"},
  {"name": "Turks and Caicos Islands (the)", "code": "TC"},
  {"name": "Tuvalu", "code": "TV"},
  {"name": "Uganda", "code": "UG"},
  {"name": "Ukraine", "code": "UA"},
  {"name": "United Arab Emirates (the)", "code": "AE"},
  {"name": "United Kingdom of Great Britain and Northern Ireland (the)", "code": "GB"},
  {"name": "United States Minor Outlying Islands (the)", "code": "UM"},
  {"name": "United States of America (the)", "code": "US"},
  {"name": "Uruguay", "code": "UY"},
  {"name": "Uzbekistan", "code": "UZ"},
  {"name": "Vanuatu", "code": "VU"},
  {"name": "Venezuela (Bolivarian Republic of)", "code": "VE"},
  {"name": "Viet Nam", "code": "VN"},
  {"name": "Virgin Islands (British)", "code": "VG"},
  {"name": "Virgin Islands (U.S.)", "code": "VI"},
  {"name": "Wallis and Futuna", "code": "WF"},
  {"name": "Western Sahara", "code": "EH"},
  {"name": "Yemen", "code": "YE"},
  {"name": "Zambia", "code": "ZM"},
  {"name": "Zimbabwe", "code": "ZW"}
]

const alpnumericvalidatePattern = /^[a-zA-Z0-9 ]*$/
export {
  audiobooks,
  audioSeries,
  tags,
  novelsTopics,
  countryList,
  alpnumericvalidatePattern,
}
