import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './styles.css'

const InputField = ({ country, error, ...props }) => {
  return (
    <PhoneInput
      {...props}
      country={country || 'in'}
      dropdownClass="country-list-dd"
      inputClass="phone-input"
      containerClass={`phone-input-container${error ? ' error' : ''}`}
      placeholder=""
      // isValid={(inputNumber, country) => {
      //   return 'Invalid value: '+inputNumber+', '+country.name;
      // }}
    />
  )
}
export default InputField
