import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  compose,
  variant,
  space,
  layout,
  flexbox,
  border,
  position,
} from 'styled-system';

const imageVariants = variant({
  variants: {
    thumbnail: {
      borderRadius: '4px',
    },
    avatar: {
      width: 3,
      height: 3,
      borderRadius: 999999,
    },
  },
});

const WrapperVariants = variant({
  variants: {
    thumbnail: {
      borderRadius: '4px',
      boxShadow: '0 4px 8px 0 rgba(73, 83, 89, 0.16)',
      backgroundColor: 'surface.sf500'
    },
    avatar: {
      width: 3,
      height: 3,
      borderRadius: 999999,
    },
  },
});

const Wrapper = styled.div(
  // {
  //   width: '96px',
  //   height: '144px',
  //   borderRadius: '4px',
  //   boxShadow: '0 4px 8px 0 rgba(73, 83, 89, 0.16)',
  // },
  compose(space, layout, flexbox, border, position),
  WrapperVariants
)

export const StyledImage = styled.img(
  compose(space, layout, flexbox, border, position),
  imageVariants
);

export const Image = forwardRef(
  (
    {
      src,
      width,
      height,
      borderRadius,
      variant,
      ...props
    },
    ref
  ) => (
    <Wrapper
      {...props}
      width={width}
      height={height}
      borderRadius={borderRadius}
      variant={variant}
    >
      {src && (
        <StyledImage
          variant={variant}
          ref={ref}
          src={src}
          width={width}
          height={height}
          borderRadius={borderRadius}
        />
      )}
    </Wrapper>
  )
);

Image.defaultProps = {
  variant: 'thumbnail',
};
