import React from 'react'
import styled from 'styled-components'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { ratingColorMapping, numFormatter, convertToQueryString } from '../../../../../lib/utils'

const StatsDiv = styled.div`
  display: flex;
  @media (max-width:600px){
    flex-direction: column;
  }
`
const CountDiv = styled.div`
  margin-right: 48px;
  @media (max-width:600px){
    margin-right: 24px;
    width: 30%
  }
`
const ReviewCountDiv = styled(CountDiv)``
const ReviewCount = styled.div`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--TextDark700);
  border-radius: 4px;
  background-color: ${({ color }) => color};
  padding: 0 4px;
  margin-bottom: 4px;
`
const CountInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`
const CommentCountDiv = styled(CountDiv)`
  cursor: pointer;
`
const Count = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
  margin-bottom: 4px;
`

const TopBox = styled.div`
  display: inline-flex;
  justify-content: space-between;
`
const BottomBox = styled.div`
  display: inline-flex;
  justify-content: space-between;
`

const handleCommentClick = (show_id, history) => {
  const obj  = {
    'show_id':[show_id]
  }
  const qString = convertToQueryString(obj,'filters')
  history.push(`${menuItemObj.comments}?tab=audiobooks&${qString}`)
}

const Stats = ({
  history,
  show_id,
  stats: {
    total_plays,
    like_count,
    avg_rating=0,
    rating_count,
    library_count,
    comments_count,
    show_audio_length,
  } = {},
}) => (
  <StatsDiv>
    <TopBox>
      <ReviewCountDiv>
        <ReviewCount color={ratingColorMapping[String(Math.round(avg_rating * 10) / 10) || '0.0'] || '#26730b'}>{(Math.round(avg_rating * 10) / 10).toFixed(1) || '0.0'}</ReviewCount>
        <CountInfo>{`${rating_count || 'No'} Reviews`}</CountInfo>
      </ReviewCountDiv>
      <CountDiv>
        <Count>{(library_count || 0).toLocaleString()}</Count>
        <CountInfo>Added to Library</CountInfo>
      </CountDiv>
      <CountDiv>
        <Count>{numFormatter(total_plays || 0, 'M').toLocaleString()}</Count>
        <CountInfo>Plays</CountInfo>
      </CountDiv>
    </TopBox>
    <BottomBox>
      <CommentCountDiv onClick={() => handleCommentClick(show_id,history)}>
        <Count>{(comments_count || 0).toLocaleString()}</Count>
        <CountInfo>Comments</CountInfo>
      </CommentCountDiv>
      <CountDiv>
        <Count>{(like_count || 0).toLocaleString()}</Count>
        <CountInfo>Likes</CountInfo>
      </CountDiv>
      <CountDiv>
        <Count>{(((show_audio_length || 0) / 3600).toFixed(1)).toLocaleString()}</Count>
        <CountInfo>Hours</CountInfo>
      </CountDiv>
    </BottomBox>
  </StatsDiv>
)

export default Stats
