const errorMessages = {
  dimension: 'The image you have uploaded is not square in dimension. Please choose a different image that is square have same size horizontally and vertcally'
}

const descriptionText = {
  emptyEpisodes: 'Episodes are pieces in which your audiobook is broken into, an ideal episode should be 2500-3000 words long. know more about episode submission guidelines.',
  acceptAudition: [
  'Introduction',
  'These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.',
  'These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.',
  'Minors or people below 18 years old are not allowed to use this Website.',
  'Intellectual Property Rights',
  'Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.',
  'You are granted limited license only for purposes of viewing the material contained on this Website.', 
  'Restrictions', 
  'You are specifically restricted from all of the following:'
  ],
  acceptConditions: [
    'publishing any Website material in any other media;', 
  'selling, sublicensing and/or otherwise commercializing any Website material;', 
  'publicly performing and/or showing any Website material;', 
  'using this Website in any way that is or may be damaging to this Website;', 
  'using this Website in any way that impacts user access to this Website;', 
  'using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;', 
  'engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;', 
  'using this Website to engage in any advertising or marketing.', 
  'Certain areas of this Website are restricted from being access by you and Company Name may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.'
  ]
}

const emptyStateText = {
  Published: {
    text: 'No Live Audiobooks!',
    subtext: [
      'You haven\'t made any audiobook live yet,',
      'if you haven\'t submitted any audiobooks yet, go ahead and submit to make them live in the Pocket FM App']
  },
  Unpublished: {
    text: 'Nothing to See Here!',
    subtext: 'There is nothing under review right now, Newly created audiobook and recent episode script which are under review can be seen here.'
  },
  Auditions: {
    text: 'No Auditions Available!',
    subtext: 'Currently no active auditions are available, Please check in some time later to see active auditions.'
  },
  Recording: {
    text: 'No Audiobooks!',
    subtext: 'No Audiobooks available to be recorded, Accepeted audiobooks will be listed here to record.'
  },
  Concepts: {
    text: 'No Audiobook Concepts',
    subtext: 'Every story starts with an idea, submit your audiobook concept to continue'
  }
}

const conceptStatusText = {     // not required as of now
  approved: {
    text: 'Audiobook Concept Approved',
    subtext: 'You audiobook concept has been approved.'
  },
  under_review: {
    text: 'Audiobook Concept Submitted',
    subtext: 'You audiobook concept has been submitted succesfully, You will be contacted once your concept is approved.'
  }
}

export { 
  errorMessages,
  descriptionText,
  emptyStateText,
  conceptStatusText
 }
