import HowItWorksStep1SVG from "../../../components/Images/HowItWorksStep1SVG"
import HowItWorksStep2SVG from "../../../components/Images/HowItWorksStep2SVG"
import HowItWorksStep3SVG from "../../../components/Images/HowItWorksStep3SVG"
import HowItWorksStep4SVG from "../../../components/Images/HowItWorksStep4SVG"
import NewNovelDesktop from './Images/NewNovel.svg'
import DailyUploadDesktop from './Images/DailyUpload.svg'
import CompletionBonusDesktop from './Images/CompletionBonus.svg'
import RevenueSvg from './Images/Revenue.svg'
import React from 'react'

export const dataHindi = {
    video_thumbnail_desktop: 'https://djhonz7dexnot.cloudfront.net/db636caede6ae47d610a3aab3dc986e6805832fd.jpg',
    video_thumbnail_mobile: 'https://djhonz7dexnot.cloudfront.net/86c142e47289f12e3000cb4466e53ca792eed823.jpg',
    deadline_date: 1,
    deadlineDate: '1st',
    deadline_date_suffix: "",
    days_or: "दिन " ,
    months_to_go:" महीने बाकी हैं! ",
    monthInWords:"जुलाई",
    month: 7,
    year: 2023,
    step: "चरण",
    contactUs : "संपर्क करें",
    faqs : "लगातार पूछे जाने वाले प्रश्न",
    introductory_heading : "लेखक बनने के सपने को करें साकार",
    aboutWriterProgram : {
        heading : 'राइटर्स बेनिफिट प्रोग्राम के बारे मे',
        subText : 'राइटर बेनिफिट प्रोग्राम के अन्तर्गत हमारा प्रयास है कि लेखकों को आर्थिक रूप से पुरस्कृत किया जाए ताकि वे मूल रूप से काल्पनिक उपन्यास लिखकर अपने जुनून को करियर में बदल सकें।'
    },
    journeyCta: 'अपना सफर शुरू करें',
    revenueAndIncetive:{
        heading: 'पुरस्कार और प्रोत्साहन राशि',
        tagline: 'रोज़ लिखें | रोज़ कमाएँ',
        note: 'केवल हिंदी उपन्यासों के लिए'
    },
    revenueCard: {
        heading: 'रेवेन्यू ',
        sponsorLine: 'पाठकों द्वारा प्रायोजित 🖤',
        revenueShare: 'न्यूनतम 25% का लाइफटाइम रेवेन्यू शेयर किया जाएगा।',
        definition: 'आपका उपन्यास अगर राइटर बेनिफिट प्रोग्राम के लिए चुना जाता है, तो आप लाईफटाइम इस पुरस्कार के पात्र हो जाएंगे।',
    },
    incentiveCard: {
        heading: 'प्रोत्साहन राशि',
        sponsorLine: 'Pocket Novel द्वारा प्रायोजित 🖤',
        tagline: <>हर एक उपन्यास से लगभग <span>₹1 लाख</span> प्रति वर्ष कमाने का सुनहरा मौका।</>,
        newNovelBonusCard: {
            heading: 'नये उपन्यास का बोनस  प्लान',
            blueTag: <>कम से कम <span>₹3000</span> तक की धनराशि पाएं।</>
        },
        dailyBonusCard: {
            heading: 'रोज़ाना चैप्टर अपडेट बोनस',
            blueTag: <>पाएं <span>₹9000</span> प्रतिमाह तक धनराशि</>
        },
        completionBonus: {
            heading: 'नोवल कम्पलिट करने का बोनस ',
            blueTag: <>पाएं <span>₹25000</span> तक की धनराशि</>
        }
    },
    deadline:{
        heading: 'आवेदन करने की अंतिम तिथि',
        text: <>एक बार प्रोग्राम का हिस्सा बनने के बाद, आप <span> जीवन भर रेवेन्यू </span> के पात्र होंगे।</>,
        spanText: 'LIFETIME REVENUE'
    },
    howItWorksLine: "जाने यह कैसे काम करता है?",
    howItWorksArray : [
        {
        step: '1',
        instruction:<>पहले 30,000 शब्द लिखें और राइटर्स बेनिफिट प्रोग्राम के लिए आवेदन करें। ✍️</>,
            stepSVG: <HowItWorksStep1SVG />
        },
        {
        step: '2',
        instruction:<> यदि चुना जाता है, तो कॉन्ट्रैक्ट साइन करें और हर महीने रेवेन्यू शेयर के साथ नई कहानी का बोनस भी प्राप्त करें। </>,
            stepSVG: <HowItWorksStep2SVG />
    
        },
        {
        step: '3',
        instruction:<>चैप्टर को प्रतिदिन अपडेट करें, और हर दिन डेली अपडेट बोनस प्राप्त करें 💸 💰</>,
            stepSVG: <HowItWorksStep3SVG />
    
        },
        {
        step: '4',
        instruction:<>एक बार जब आप कम से कम 2,50,000 शब्द पूरा कर, अच्छी पाठक समीक्षा प्राप्त कर लेते हैं, तो हमारी टीम स्वयं ऑडियोबुक बनाने के लिए आपसे संपर्क करेगी। परीक्षण के बाद, यदि नॉवेल को ऑडियो रूपांतरण के लिए चुना जाता है, तो आप अपने ऑडियोबुक से उसके प्रदर्शन के आधार पर रेवेन्यू प्राप्त करेंगे।</>,
            stepSVG: <HowItWorksStep4SVG />
        },
    ],
    revenue: {
        heading: 'न्यूनतम 25% का लाइफटाइम रेवेन्यू शेयर किया जाएगा।',
        image: RevenueSvg,
        subHeading: <>आपका उपन्यास अगर राइटर बेनिफिट प्रोग्राम के लिए चुना जाता है, तो आप लाईफटाइम इस पुरस्कार के पात्र हो जाएंगे।</>,
        extraNotes: [
            {
            no: 1,
            text: 'आय का न्यूनतम 25% रिवेन्यू टैक्स और भुगतान गेटवे शुल्कों की कटौती के बाद ही लेखकों के साथ शेयर किया जाएगा।',
            },
            {
            no: 2,
            text: 'किसी भी महीने का रिवेन्यू, अगले महीने के पहले दिन ही आपके आय टैब में एड कर दिया जाएगा।',
            },
            {
            no: 3,
            text: 'हम आपके आय टैब में आपकी रिवेन्यू राशि जोड़ते रहेंगे, लेकिन आपकी रेवेन्यू राशि कम से कम ₹100 होने के बाद ही आप अपनी इस आय को अपने बैंक एकाउंट में स्थानांतरित कर सकते है।',
            },
            {
            no: 4,
            text: '22 दिसंबर 2022 से पहले राईटर बेनिफिट प्रोग्राम (पुराना प्रोग्राम) के लिए आवेदन करने वाले हिंदी उपन्यास भी इस बैनिफिट के पात्र होंगे। यदि आपको प्राप्त होने वाली रिवेन्यू राशि में कोई कमी है तो उसका भुगतान आपको अगले माह कर दिया जायेगा।',
            },
        ],
    },
    new_novel_bonus: {
        heading: '1. नये उपन्यास का बोनस  प्लान 🖋️',
        image: NewNovelDesktop,
        subHeading: <>कम से कम ₹3000 तक की धनराशि पाएं। </>,
        tableHeadings: ['न्यूनतम शब्द ', 'न्यूनतम इनाम 💰'],
        table: [
        {
            wordCount: '30,000',
            reward: '₹3000',
        },
        ],
        note: <>कॉपिड, अनुवादित, अश्लील या गैर-काल्पनिक कंटेंट को अनुमति नहीं है। </>,
        extraNotes: [
        {
            no: 1,
            text: 'लेखक को राइटर बेनिफिट प्रोग्राम (WBP) के लिए आवेदन करना होगा।',
        },
        {
            no: 2,
            text: 'यदि उपन्यास हमारे कंटेंट गुणवत्ता मानकों की शर्तों को पूरा करता है, तो ही आपके एप्लीकेशन को अप्रूव किया जाएगा।',
        },
        {
            no: 3,
            text: 'एप्लीकेशन अप्रूवल के समय, पुरस्कार को लेखक के आय टैब में जोड़ दिया जाएगा।',
        },
        {
            no: 4,
            text: <>पुरस्कार राशि एप्लीकेशन अप्रूवल के समय प्रकाशित शब्दों पर आधारित होगी और इसकी गणना 10 पैसे प्रति शब्द की दर से की जाएगी।
            <br/>उदाहरण: अगर अप्रूवल के समय उपन्यास में 65,000 शब्द पुरे होते है, तो इसका इनाम ₹6,500 होगा</>,
        },
        {
            no: 5,
            text: "कान्ट्रैक्ट साइन करने के बाद ही लेखक वॉलेट से पैसे अपने बैंक अकाउंट में ट्रांसफर करने के पात्र होंगे। भुगतान चक्र अगले महीने के पहले सप्ताह में शुरू होता है।",
        },
        {
            no: 6,
            text: "उपन्यास का रिव्यू करते समय यदि कंटेंट कॉपिड, अनुवादित आदि पाया जाता है, तो हम पुरस्कार राशि को अस्वीकार करने या पुनः प्राप्त करने का अधिकार सुरक्षित रखते है।",
        },
        ],
    },
    daily_update_bonus : {
        heading: '2. रोज़ाना चैप्टर अपडेट बोनस ⭐',
        image: DailyUploadDesktop,
        subHeading: <>यह इनाम 10 पैसे/शब्द की प्रोत्साहन राशि पर, प्रतिदिन 2,000 शब्दों तक के लिए जेनरेट किया जाता है। पाठक रोज़ाना चैप्टर अपडेट चाहते है इसलिए, यदि आप नियमित रूप से चैप्टर प्रकाशित करते है तो हम आपको अधिक भुगतान करेंगे। लेकिन यदि आप किसी भी दिन चैप्टर प्रकाशित करने से चूक गए तो भुगतान राशि कम कर दी जायेगी।</>,
        tableHeadings: ['प्रोत्साहन प्रकार', 'प्रोत्साहन दर 💰'],
        table: [
        {
            wordCount: <span style={{color: '#54B585'}} >पूर्ण प्रोत्साहन</span>,
            reward: <> <span style={{color: '#54B585'}}>100%</span> प्रोत्साहन राशि <br /> = 10 पैसे/शब्द  </>,
        },
        {
            wordCount: <span style={{color: '#799E22'}} >स्ट्रीक प्रोत्साहन</span>,
            reward: <><span style={{color: '#799E22'}}>150%</span> प्रोत्साहन राशि <br /> = 15 पैसे/शब्द</>,
        },
        {
            wordCount: <span style={{color: '#F2C602'}} >आधा प्रोत्साहन</span>,
            reward: <><span style={{color: '#F2C602'}}>50%</span> प्रोत्साहन राशि <br /> = 5 पैसे/शब्द</>,
        },
        {
            wordCount: <span style={{color: '#E51A4D'}} >अनुपस्थित दिन</span>,
            reward: <>कोई भुगतान<span style={{color: '#E51A4D'}}>नहीं</span></>,
        },
        
        ],
        note: <>यदि आपकी नोवल से पाठक बहुत कम हो रहे है तो यह इनाम रोक दिया जाएगा</>,
        extraNotes: [
        {
            no: 1,
            text: <> यह इनाम का स्ट्रक्चर लूडो के 'सांप और सीढ़ी' खेल जैसा है। प्रोत्साहन प्रकार (कम/ज्यादा) इस बात पर निर्भर करता है, कि WBP कान्ट्रैक्ट अप्रूव होने के बाद किस तरह से शब्द सीमा को मैन्टैन रखा है। </>,
        },
        {
            no: 2,
            text: <> हमारा <strong>पूर्ण प्रोत्साहन</strong> 10 पैसे/शब्द है <br />
            उदाहरण के लिए, <br/>
            -यदि आपने 1 अक्टूबर से 6 अक्टूबर तक प्रतिदिन 2000 शब्दों के अध्याय प्रकाशित किए हैं;<br/>
            तो 1 अक्टूबर से 6 अक्टूबर तक <strong>पूर्ण प्रोत्साहन</strong> (10 पैसे/शब्द) मिलेगा, इसलिए आपको प्रत्येक दिन ₹200 प्राप्त होंगे।.</>,
        },
        {
            no: 3,
            text: <> प्रोग्राम की समीक्षा के बाद, यदि आप 30 दिनों तक <strong>लगातार</strong> चैप्टर प्रकाशित करते है, तो आपको <strong>स्ट्रीक प्रोत्साहन</strong> (15 पैसे/शब्द) मिलना शुरू हो जाता है।<br/> 
            उदाहरण के लिए,<br/>
            -यदि आपने 1 अक्टूबर से 6 अक्टूबर तक प्रतिदिन 2000 शब्दों के अध्याय प्रकाशित किए हैं;<br/>
            तो 1 अक्टूबर से 30 अक्टूबर तक <strong>पूर्ण प्रोत्साहन</strong> राशि (10 पैसे / शब्द) मिलेगी, इसलिए प्रत्येक दिन ₹200। <br/>
            और 31 अक्टूबर से 2 नवंबर तक <strong>स्ट्रीक इंसेंटिव</strong>(15 पैसे/शब्द) मिलेगा, यानी हर दिन ₹300।<br/>
            -यह <strong>स्ट्रीक प्रोत्साहन</strong> तब तक जारी रहता है जब तक आप रोजाना चैप्टर पब्लिश नहीं करते।</>
        },
        {
            no: 4,
            text: <> यदि आप किसी दिन चैप्टर प्रकाशित करने से चूक जाते है, तो आपको अगले दिन आधी राशि मिलती है।<br/>
            उदाहरण के लिए,<br/>
            - यदि आप 1 जनवरी को चैप्टर प्रकाशन से चूक जाते है,<br/>
            तो आपको 2 जनवरी को प्रकाशित चैप्टर के लिए <strong>आधी प्रोत्साहन</strong> राशि (5 पैसे/शब्द) दी जाएगी। <br/>
            लेकिन आपको 3 जनवरी से फिर से <strong>फुल इंसेंटिव</strong>(10 पैसे/शब्द) ही मिलेगा।<br/>
            - - यदि आप 1 से 3 जनवरी तक प्रकाशन से चूक जाते है, तो आपको <br/>
            तो आपको 4 से 6 जनवरी तक प्रकाशन के लिए <strong>आधी प्रोत्साहन</strong> राशि (5 पैसे/शब्द)<br />
            और 7 जनवरी से फिर से <strong>पूर्ण प्रोत्साहन</strong>  राशि (10 पैसे/शब्द) दी जाती है।</>,
        },
        {
            no: 5,
            text: <> यह पुरस्कार प्राप्त करना जारी रखने के लिए, प्रतिदिन अच्छी क्वालिटी चैप्टर अपलोड करें और सुनिश्चित करें कि वैलिड पाठक % एक अध्याय से दूसरे अध्याय में बहुत कम ना हो रहा हो। आप एनालिटिक्स सेक्शन में अपनी नोवल का ग्राफ देख सकते है: एंगेजमेंट टैब</>,
        },
        ],
    },
    completion_bonus : {
        heading: '3. नोवल कम्पलिट करने का बोनस  🚀',
        image: CompletionBonusDesktop,
        subHeading: 'आपकी नोवल पूरी हो जाने पर, यह इनाम लेखक को तब दिया जाएगा जब हमारे संपादकों द्वारा आपके नोवल की समीक्षा पूर्ण कर ली जाएगी।',
        tableHeadings: ['शब्द गणना (कुल)', 'इनाम 💰'],
        table: [
        {
            wordCount: '1,00,000 - 2,50,000',
            reward: '₹1,000',
        },
        {
            wordCount: '2,50,000 - 3,50,000',
            reward: '₹5000',
        },
        {
            wordCount: '3,50,000 - 5,00,000',
            reward: '₹10,000',
        },
        {
            wordCount: 'More than 5,00,000',
            reward: '₹25,000',
        },
        ],
        note: <>यदि आपकी नॉवेल के पाठकों की संख्या में गिरावट आती है, तो भी कम शब्द गणना के अंतर्गत इनाम अर्जित किया जा सकेगा। </>,
        extraNotes: [
        {
            no: 1,
            text: <> कृपया हमें writers@pocketfm.com पर विषय के साथ ईमेल करें: <strong>उपन्यास का नाम, लेखक का नाम,</strong> नोवल कम्पलिशन बोनस के लिए अनुरोध।</>,
        },
        {
            no: 2,
            text: <>हमारे संपादकों व्दारा कंटेंट गुणवत्ता मानकों के अनुरूप आपकी नोवल की जांच करने के बाद ही आपका भुगतान स्वीकृत किया जाएगा।</>,
        },
        ],
    },
    WritingFAQs: [
        {
        question: '1. How to register as a writer? Any tips to create a novel?',
        answer: "Please follow these steps ",
        youtubeLink: 'Hs8Jq8leaoU'
        },
        {
        question: '2. What are the terms & conditions for writing?',
        answer: "Only original fiction stories are allowed. We disable plagiarism ( copied content)  and sexually explicit (adult content ) books.",
        },
        {
        question: '3. How many words need to publish a chapter',
        answer: "Each chapter must contain a minimum 1000 words or maximum 5000 words.",
        },
        {
        question: '4. Can I write short stories and Poetry or Shayari?',
        answer: "We have observed that fiction stories have much higher readers than non-fiction, shayari, and poetry. So we recommend that all writers write original fiction stories. Hence, even if we allow writers to write short stories and poems, they are not eligible to apply for the Writers Benefit Program.",
        },
        {
        question: '5. Which languages can I write in?',
        answer: "You are welcome to write in any language option seen, but the Writer benefit program is currently only open for Hindi, English, and Tamil.",
        },
        {
        question: '6. Why is my novel not visible on app',
        answer: "As we get hundereds of new books published daily, we are only able to feature the books with good performance in our featured list . To become featured in our app, make sure to write regularly with hooky long story & plotline",
        },
    ],
    ProgramFAQs: [
        {
        question: '1. What are the minimum performance guidelines for signing a novel?',
        answer: "Only top 10% of the applications to the program are accepted, please make sure your readers are engaged with chapters added to your novel. You can refer & compare your novel's performances against Platform's Avg book data by going to Analytics Tab -> Engagement -> valid reader % vs Chapter Graph",
        },
        {
        question: '2. Why do I have to sign the contract?',
        answer: "Contracts include the terms and conditions which a writer is subject to if he/she wants to enter the Writers Benefit Program. So, if a writer wants to enjoy the benefits of WBP and be properly compensated for his or her work, he or she must sign the contract.",
        },
        {
        question: '3. What is the payment structure?',
        answer: "If selected for writer benefit program, the writer is then eligible to get Revenue Share , New Novel Bonus, Daily Update Bonus, & Completion Bonus. You can refer to respective sections in Revenue & Incentive section in writer benefit page for detailed information",
        },
        {
        question: '4. What is the mode of payment?',
        answer: "Your rewards will be reflected in Income Tab. The said amount might be subject to adjustment based on the defined criteria in some cases. The final eligible payout will be deposited in the writer’s associated bank account before 1st or 2nd week of every month given that the payout> 100 INR",
        },
        {
        question: '5. How many days do I need to write, once accepted to the program ?',
        answer: "Writers need to upload chapters on a daily basis with quality content. For missed days there wont be any Daily Update Bonus ",
        },
        {
        question: '6. What are coins?',
        answer: "Coins are used to support the writers financially. Except for the first few chapters of a novel, all the other chapters are locked for a limited time. Coins can be used to unlock these chapters and get early access to the entire story. Please watch this video for more detailed information: ",
        youtubeLink: 'i5knJeoyCnY'
        },
        {
        question: '7. How do writers benefit from coins?',
        answer: "A minimum 25% of the Revenue recieved from reader spending coins on the novel is shared with the author. Check out the Revenue share card for more information",
        },
        {
        question: '8. Will my story be made into an audiobook?',
        answer: "Your novel must have a word count of at least 2,50,000 words to be eligible for consideration. If your book gets a high readership & enagagement , we'll first test it in audio format & if it shows success, we will convert it to an audiobook.",
        },
    ],
    PlatformFAQs: [
        {
        question: '1. How to download mobile applications?',
        answer: `Android- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a> , iPhone- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a>`,
        },
        {
        question: '2. How do I login? How to use the app?',
        answer: "Please use this for login - <a href='https://writer.pocketnovel.com/login'>here</a> and follow these steps - tutorial ",
        youtubeLink: 'Tu9XoKAP918'
        },
        {
        question: '3. How to publish a chapter?',
        answer: "If you want to add chapters to a novel on our platform, you have to login to our <a href='https://writer.pocketnovel.com/'>writers@pocketfm.com</a> or our mobile app Pocket Novel Reader. This link will help you to understand.Watch here",
        },
        {
        question: '4. Can I write from outside India?',
        answer: "Our app is available globally. We welcome all writers from all countries. But we are currently only supporting authors from India financially ",
        },
        {
        question: '5. How to upload the Book Cover?',
        answer: "Go to the novels, choose the edit novel option. On the right side, there is an option to update the cover image. You can upload the book cover from there and also publish the novel from your side. Our graphic team will upload a book cover for you if the novel performs well.",
        },
    ],
    IssuesFAQs: [
        {
        question: '1.Didn’t get the OTP or Face issue during logging in?',
        answer: "Please try to get OTP on WhatsApp. If the issue still persists then mail us on <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a> along with your login info like phone number you are trying to use.",
        },
        {
        question: '2. Issues with email and password',
        answer: "Double-check that you have entered your username and password correctly. Remember, password is case-sensitive. If you're still having trouble, you can use the `Forgot your Username or Password?` link to have a password reset link emailed to you on your registered email ID. If the email doesn't appear in your inbox, check your spam/junk mail folder. If you're still having difficulties, email us the screenshot/video of the issue at <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a>",
        },
        {
        question: '3. What to do, if my App not working',
        answer: "Restart your phone and check for updates. If you are still facing the same issue, 'force stop' the Pocket Novel app from your phone's Settings and try clearing app cache. If the problem is not yet solved, email us at writers@pocketfm.com with a screenshot/video of the issue.",
        }
    ],
}
