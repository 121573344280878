import React from 'react'

const IncomeContainer = (props) => {
  // implement logic like api calling here
  const child = React.Children.only(props.children)
  return React.cloneElement(child, {
    ...props,
  })
}

export default IncomeContainer
