import styled from 'styled-components'
import Arrow from '../../../../../components/Images/ArrowRight';

const ArrowRight = styled(Arrow)`
  margin-bottom: -5px;
`

const FormContainer = styled.div`
  padding: 24px;
  margin-bottom: 32px;
  background-color: var(--Dove);
`
const FormActionDiv = styled.div`
  text-align: right;
`
const ContinueButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  opacity: ${({ isValid }) => isValid ? '1' : '0.3'};
  border-radius: 4px;
  background-color: var(--Azure500);
`
const CancelButton = styled.button`
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
  padding: 16px 40px;
  background: none;
  outline: none;
`
const LoaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  .loader {
    margin: 0;
  }
`
const StepHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  color: var(--Text500);
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
  margin-top: 15px;
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const FlexChildLeft = styled.div`
  width: 63%;
`
const FlexChildRight = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-end;
`

const ImageUploadDiv = styled.div`
  margin-top: 24px;
  width: 256px;
  height: 256px;
  border-radius: 4px;
  box-shadow: inset 0 0 32px 0 rgba(0, 0, 0, 0.08);
  border: dashed 1px var(--Azure500);
  background-color: var(--Grey100);
  display: flex;
  align-items: center;
`
const ThumbnailPlaceHolder = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text300);
  text-align: center;
  flex: 1;
`
const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`
export {
  FormContainer,
  StepHeader,
  HR,
  FlexContainer,
  FlexChildLeft,
  FlexChildRight,
  FormActionDiv,
  ContinueButton,
  CancelButton,
  ArrowRight,
  ImageUploadDiv,
  ThumbnailPlaceHolder,
  Thumbnail,
  LoaderDiv
}
