import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import {
  Input,
  Upload,
  DateTimeSelect,
  MultiSelectDropdown,
  CMSearch,
} from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import moment from 'moment'
import Loader from '../../../../../components/Loader'
import { get, post } from '../../../../../lib/api'
import Tooltip from '../../../../../components/Tooltip'
import { FileUploadComponent } from '../Edit/addEditEpisode'
import { objectCloneWithTopLevelNullRemoved } from '../../../../../lib/utils'

import {
  FormContainer,
  HR,
  FlexContainer,
  FormActionDiv,
  ContinueButton,
  CancelButton,
  ArrowRight,
  LoaderDiv,
} from '../New/styles'

const FetchFirstEpisodeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
const FetchEpisodeDiv = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #068ae2;
  border-radius: 4px;
  background-color: rgba(6, 138, 226, 0.1);
  padding: 8px 12px;
  margin-top: 16px;
`
const LeftHalfSection = styled.div`
  width: 49%;
`
const RightHalfSection = styled.div`
  width: 49%;
`
const StepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const HeadTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
`
const RequirementForm = ({ data = {}, id, mode, successCB, enableCmSearch, cancelCB }) => {
  const hangleFetchFirstEpisode = (handleChange) => {
    get('/content_api/book.get_first_chapter_file', {
      params: {
        book_id: id,
        view: 'dashboard'
      },
    }).then((res) => {
      const { result: { chapter_url } = {} } = res
      if (chapter_url) {
        handleChange({
          target: { name: 'audition_script_url', value: chapter_url },
        })
      }
    })
  }
  return (
    <Formik
      initialValues={{
        audition_script_url: data['audition_script_url'] || '',
        last_participation_date: data['last_participation_date'] || '',
        // base_price: data['base_price'] || '',
        description: data['description'] || '',
        requirements: data['requirements'] || '',
        created_by: data['created_by'] || '',
      }}
      validationSchema={Yup.object({
        audition_script_url: Yup.string().required('Required'),
        last_participation_date: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        requirements: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let {
          audition_script_url = null,
          last_participation_date = null,
          base_price = null,
          description = null,
          requirements = null,
          created_by = null,
        } = values

        created_by = created_by || null
        
        let formData = {
          audition_script_url,
          last_participation_date: last_participation_date
            ? moment(last_participation_date).format('YYYY-MM-DD HH:mm:ss')
            : null,
          base_price,
          description,
          requirements,
          created_by,
        }
        formData['book_id'] = id
        formData['view'] = 'dashboard'
        formData = objectCloneWithTopLevelNullRemoved(formData)
        let createBookMetaApi =
          '/content_api/book.create_or_update_audition_meta'
        post(createBookMetaApi, { data: formData }).then((response) => {
          setSubmitting(false)
          successCB()
        })
      }}
    >
      {({ values = {}, isValid, isSubmitting, handleChange, submitCount, errors }) => (
        <Form autoComplete="off">
          <FormContainer>
            <StepHeader>
              <HeadTitle>Audiobook Requirement</HeadTitle>
              {!!enableCmSearch && <CMSearch placeholder="Type to Search CM" name="created_by" />}
            </StepHeader>
            <HR />

            <Input
              label="Description"
              asTextArea
              name="description"
              hasError={submitCount > 0 && errors['description']}
              type="text"
              placeholder="Any important info regarding upload for this audiobook"
              infoTitle="Add the detailed description for this audition"
            />

            <FlexContainer>
              <LeftHalfSection>
                <MultiSelectDropdown
                  label="Special Requirements"
                  name="requirements"
                  hasError={submitCount > 0 && errors['requirements']}
                  infoTitle="Add any kind of special requirement for this book"
                  placeholder="Select requirements"
                  options={[
                    {
                      id: 1,
                      name: '2 episodes per day',
                    },
                    {
                      id: 1,
                      name: '15 minutes per episode',
                    }
                  ]}
                />
                <DateTimeSelect
                  infoTitle="Add the end date for this audition"
                  label="Audition End Date"
                  name="last_participation_date"
                  hasError={submitCount > 0 && errors['last_participation_date']}
                  id={id}
                />
              </LeftHalfSection>
              <RightHalfSection>
                <Upload
                  label="Demo Script File"
                  name="audition_script_url"
                  hasError={submitCount > 0 && errors['audition_script_url']}
                  hideInputOnValue={true}
                  showProgress={true}
                  placeholder="Drop your doc file here to upload or click to select"
                  renderComp={FileUploadComponent}
                  fileTypeTags="file"
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc"
                  extraParams={{
                    file_dir: id,
                    title: 'Audition Requirements',
                  }}
                  cloud_dir_file={id}
                  uploadedFileMeta={{ words: '', size: '' }}
                />
                <FetchFirstEpisodeContainer
                  onClick={() => hangleFetchFirstEpisode(handleChange)}
                >
                  <FetchEpisodeDiv>Fetch First Episode Script</FetchEpisodeDiv>
                </FetchFirstEpisodeContainer>
              </RightHalfSection>
            </FlexContainer>
          </FormContainer>
          <FormActionDiv>
            <CancelButton onClick={cancelCB}>Cancel</CancelButton>
            {!isSubmitting && (
              <Tooltip
                title={isValid ? '' : 'Enter all the details to continue'}
                arrow
              >
                <ContinueButton isValid={isValid} type="submit">
                  {mode === 'edit' ? 'Save' : "Setup Audition"} <ArrowRight />
                </ContinueButton>
              </Tooltip>
            )}
            {isSubmitting && (
              <LoaderDiv>
                <Loader size="md" />
              </LoaderDiv>
            )}
          </FormActionDiv>
        </Form>
      )}
    </Formik>
  )
}

export default RequirementForm
