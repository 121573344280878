import React from 'react'
import styled, { css } from 'styled-components'

import bluebackground from '../images/bluebackground.svg'

const section = css`
  padding: 32px 48px;
  color: ${({ theme }) => theme.colors.text.text900};
  transition: all 0.2s;
  @media (max-width: 600px) {
    padding: 16px;
  }
`
const sectionHeading = css`
  text-transform: capitalize;
  text-align: center;
  line-height: 1.33;
  font-size: 25px;
  transition: all 0.2s;
  span:last-of-type {
    margin-left: 8px;
  }
  @media (max-width: 600px) {
    font-size: 21px;
  }
`
const FrequentlyAskedQuestions = styled.div`
  ${section}
  transition: all 0.2s;
  @media (max-width: 600px) {
  }
`
const FrequentlyAskedQuestionsHeading = styled.h2`
  ${sectionHeading}
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: var(--bg-alt);
`
const AccordionContainer = styled.div`
  margin: 32px auto;
  max-width: 800px;
  transition: all 0.2s;
  @media (max-width: 600px) {
    margin: 32px auto;
  }
`
const Accordion = styled.div`
  margin: 16px auto;
`
const AccordionText = styled.p`
  max-height: 0;
  overflow: hidden;
  color: var(--Text500);
  transition: max-height 0.25s ease-out;
  &::first-letter {
    text-transform: capitalize;
  }
`

const AccordionBtn = styled.button`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &::first-letter {
    text-transform: capitalize;
  }
  &:after {
    content: '+';
    font-size: 20px;
    color: #000000;
    margin-left: 8px;
    float: right;
  }
  &.open:after {
    content: '-';
  }
`

const Wrapper = styled.div`
  box-sizing: border-box;
  background: url(${bluebackground}) top/cover no-repeat;
  width: 100%;
  height: fit-content;
  padding-top: 4rem;
  padding-top: 5rem;
  max-height: 700px;
  min-height: 500px;
`

const frequentlyAskedQuestionsArray = [
  {
    question: 'When will I get my payment after submitting my work?',
    answer:
      'After submission of work as per quality standards of Pocket FM, one receives the payment by 8th of subsequent month provided given the contract is signed before 1st of the month.',
  },
  {
    question: 'Will I get credited for my writing?',
    answer:
      'Writers will be given due credits for their story and writing. The writer’s name will be featured on the show that they have written on the Pocket FM app.',
  },
]

export default function Index(props) {
  const toggleAccordionTextHandler = (e) => {
    const accordionTextElement = e.currentTarget.nextElementSibling
    if (accordionTextElement.tagName === 'P') {
      e.currentTarget.classList.toggle('open')
      accordionTextElement.style.maxHeight
        ? (accordionTextElement.style.maxHeight = null)
        : (accordionTextElement.style.maxHeight =
            accordionTextElement.scrollHeight + 'px')
    }
  }
  return (
    <>
      <Wrapper>
        <FrequentlyAskedQuestions id="faq">
          <FrequentlyAskedQuestionsHeading>
            <span>{'Frequently Asked Questions?️'}</span>
          </FrequentlyAskedQuestionsHeading>
          <AccordionContainer>
            {frequentlyAskedQuestionsArray.map((data, index) => (
              <Accordion key={index}>
                <AccordionBtn onClick={toggleAccordionTextHandler}>
                  {data.question}
                </AccordionBtn>
                <AccordionText>{data.answer}</AccordionText>
              </Accordion>
            ))}
          </AccordionContainer>
        </FrequentlyAskedQuestions>
      </Wrapper>
    </>
  )
}
