import React, { useCallback } from 'react'
import Modal from '../Modal/index'
import './style.css'

export default function Popup(props) {
  const onRefChange = useCallback((node) => {
    if (node && props.setPopupRef) {
      props.setPopupRef(node)
    }
  }, [])

  return (
    <Modal show={props.show} close={props.close} {...props}>
      {props.withCloseButton && (
        <div className="close-mark" onClick={props.close}>
          ˟
        </div>
      )}
      {props.renderCloseButton && props.renderCloseButton()}
      <div
        className={`popup-notification ${props.className}`}
        onClick={props.onClick}
        ref={onRefChange}
        id={`${props.id}`}
      >
        {props.head && <div className="head">{props.head}</div>}
        <div className="details">{props.children}</div>
        {props.renderActions && (
          <div className="actions">{props.renderActions}</div>
        )}
      </div>
      {props.withCloseMark && (
        <div
          className="close-Button"
          onClick={props.close}
          style={props.withCloseMarkStyles}
        >
          ˟
        </div>
      )}
    </Modal>
  )
}
