import React, { useEffect, useState } from 'react'
import { emptyStateText } from '../../constants'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import { get } from '../../../../../lib/api'
import { useDidUpdate, useInfiniteScroll } from '../../../../../lib/customHooks'
import { mockResponse } from './mock'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import CreatorIcon from '../../../../../components/Images/CreatorIcon'
import ClampLines from 'react-clamp-lines'
import { getTopicNameFromId, removeHtmlTags } from '../../../../../lib/utils'
import EmptyState from '../emptyState'
import GenericError from '../../../../../components/GenericError'
import CreatorInfo from '../../../../../components/CreatorInfo'
import { useCreatorProfile } from '../../../../../lib/customHooks'
import { isAdminAccess } from '../../../../../config'
import {convertUrlToHttps} from '../../../../../lib/utils'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Image, Text } from '../../../../../designSystem'

const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`
const BookSummary = styled.div`
  padding: 24px;
  display: flex;
  background-color: var(--Dove);
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-bottom: 100px;
  }
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
  @media(max-width:600px){
    padding: 16px;
  }
`
const BookImageDiv = styled.div`
  width: 144px;
  height: 144px;
  margin-right: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  background-color: var(--Grey900);
  flex: none;
`
const BookImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const BookDetails = styled.div`
  margin-right: 16px;
`
const PendingCountInfo = styled.div`
  margin-left: auto;
  min-width: 20%;
  text-align: right;
  @media(max-width:600px){
    text-align: left;
    margin-top: 8px;
  }
`
const Count = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  display: inline-block;
`
const BookTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.text500};
  font-weight: 500;
  margin-top: 8px;
`
const ArtistsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  display: -webkit-inline-box;
  overflow: auto;

  &::-webkit-scrollbar-thumb{
    background: transparent;
  }
  @media(max-width:600px){
    margin-top: 8px;
  }
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  @media(max-width:600px){
    margin-right: 0;
  }
`
const ProfileImageDiv = styled.div`
  position: relative;
  margin-right: 8px;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const StyledCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`
const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
`

const Recording = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState(null)
  const [apiError, toggleApiError] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } = useCreatorProfile()
  const isMobile = window.screen.width < 600
  const fetchData = () => {
    setIsLoading(true)
    const recordingBookListApi =
      '/content_api/book.recording_shows'
    get(recordingBookListApi, {
      params: {
        view: isAdminAccess ? 'cms' : null
      }
    })
      .then((response) => {
        const { result } = response
        if (Array.isArray(result)) {
          setBooks(result)
          setNextUrl(null)
        } else {
          setBooks(result.books)
          setNextUrl(result.next_url)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result } = response
        const { next_url, books: newBooks } = result
        setBooks(books.concat(newBooks))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useDidUpdate(() => {
    setIsLoading(false)
  }, [books])

  const handleUserClick = (e, id) => {
    e.stopPropagation()
    onUserClick(id)
  }

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    )
  }

  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <GenericError 
        show={apiError}
        onClose={() => toggleApiError(false)}
      />
      {!books && (
        <BlankPaper />
      )}
      {books && !!books.length && books.map(
            ({
              show_id,
              image_url,
              show_title,
              category_obj: { topic_name } = {},
              show_desc,
              show_desc_html,
              language,
              author_info: { author_id, fullname: author_name, image_url: author_image } = {},
              new_episodes_to_record,
              vo_artist_info: {
                uid,
                fullname: artist_name,
                image_url: artist_image,
              },
              translator_info: { fullname: translator_name, image_url: translator_image, translator_id } = {},
              production_house_info: {
                production_house_name,
                production_house_id,
                image_url: production_house_image
              } = {}
            }) => (
              <BookSummary
                key={show_id}
                onClick={() =>
                  history.push(
                    `${menuItemObj.audiobooks}?tab=recording-and-production&id=${show_id}`
                  )
                }
              >
                <Image width={[60,144]} height={[60,144]} src={convertUrlToHttps(image_url,true)} mr={[8, 24]}/>
                <Box mr={14}>
                  {/* <Text variant="titleSecondary" mb={8}>
                    {show_title} &middot; {language && (language[0].toUpperCase() + language.slice(1))}
                      {!isMobile && topic_name && <CategorySpan>{topic_name}</CategorySpan>}
                  </Text> */}
                  <Text variant="title" fontWeight="r">
                    {show_title} &middot; {language && (language[0].toUpperCase() + language.slice(1))}
                  </Text>
                  {!isMobile && topic_name && <Text variant="subtext">{topic_name}</Text>}

                  {!isMobile && <BookDescription><ClampLines text={show_desc || removeHtmlTags(show_desc_html) || 'No description available'} lines={2} id="recording-show-list" buttons={false}></ClampLines></BookDescription>}
                  
                  <ArtistsContainer>
                    {author_name && <ArtistDetails onClick={(e) => handleUserClick(e, author_id)}>
                      {!isMobile && author_image && <ProfileImageDiv>
                        <ProfileImage src={author_image} />
                        <StyledAuthorIcon />
                      </ProfileImageDiv>}
                      {author_name && <ArtistName>{author_name}</ArtistName>}
                    </ArtistDetails>}
                    {author_name && artist_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                    {artist_name && <ArtistDetails onClick={(e) => handleUserClick(e, uid)}>
                      {!isMobile && artist_image &&<ProfileImageDiv>
                        <ProfileImage src={artist_image} />
                        <StyledCreatorIcon />
                      </ProfileImageDiv>}
                      <ArtistName>{artist_name}</ArtistName>
                    </ArtistDetails>}
                    {author_name && artist_name && translator_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                    {translator_name && <ArtistDetails onClick={(e) => handleUserClick(e, translator_id)}>
                      {!isMobile && translator_image && <ProfileImageDiv>
                        <ProfileImage src={translator_image} />
                        <StyledAuthorIcon />
                      </ProfileImageDiv>}
                      {translator_name && <ArtistName>{translator_name}</ArtistName>}
                    </ArtistDetails>}
                    {author_name && artist_name && translator_name && production_house_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                    {production_house_name && <ArtistDetails onClick={(e) => handleUserClick(e, production_house_id)}>
                      {!isMobile && production_house_image && <ProfileImageDiv>
                        <ProfileImage src={production_house_image} />
                        <StyledCreatorIcon />
                      </ProfileImageDiv>}
                      {production_house_name && <ArtistName>{production_house_name}</ArtistName>}
                    </ArtistDetails>}
                  </ArtistsContainer>

                  {isMobile && !!new_episodes_to_record && <PendingCountInfo>
                    <Count>{`${new_episodes_to_record} new ${new_episodes_to_record > 1 ? 'Episodes' : 'Episode'} to record`}</Count>
                  </PendingCountInfo>}
                  
                </Box>
                {!isMobile && !!new_episodes_to_record && <PendingCountInfo>
                  <Count>{`${new_episodes_to_record} new ${new_episodes_to_record > 1 ? 'Episodes' : 'Episode'} to record`}</Count>
                </PendingCountInfo>}
              </BookSummary>
            )
          )
      }
      {isFetching && <Loader />}
      {
        books && !books.length && (
          <EmptyState text={emptyStateText['Recording']['text']} subtext={emptyStateText['Recording']['subtext']} />
        )
      }
    </>
  )
}

export default withRouter(Recording)
