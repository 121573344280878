import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import WBPReviewImg from '../../../components/Images/WBPReview'

const Container = styled.div`
  padding: 48px 48px 24px;
  background-color: var(--Dove);
  // height: 600px;

  @media (max-width: 600px) {
    padding: 24px 0;
  }
`
const StepHeading = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: var(--Text500);
  margin-bottom: 24px;
`
const StepDesc = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--Text500);
  margin-bottom: 80px;
`
const OkayDiv = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  border: solid 2px var(--LD10);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  margin-left: auto;
  display: table;
  cursor: pointer;
`
const ArtDiv = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 360px;
  }
`
const HR = styled.div`
  background-color: var(--Grey900);
  height: 2px;
  margin-bottom: 24px;
`

// TODO: mention users email in the description

const Step = ({ resetSteps }) => (
  <>
    <Container>
      <StepHeading>
        Your Application is being reviewed
      </StepHeading>
      <StepDesc>
      Thank you for applying to the Writer Benefits Program. We have recieved your application and it is being reviewed. You will recieve a notification on your email.
      In the meantine keep adding new chapters to your novel for your readers.
      </StepDesc>
      <ArtDiv>
        <WBPReviewImg />
      </ArtDiv>
      <HR />
      <OkayDiv onClick={resetSteps} >Okay</OkayDiv>
    </Container>
  </>
)
export default Step
