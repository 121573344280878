import React from 'react'

const MonthlyTargetCalandar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="240" height="208" fill="none" viewBox="0 0 240 208">
    <mask id="xg0u77xj6a" width="240" height="208" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M0 0H240V208H0z"/>
    </mask>
    <g mask="url(#xg0u77xj6a)">
        <path fill="#F0F1F5" d="M164.441 60.036C155.15 42.748 136.88 31 115.84 31c-25.597 0-47.128 17.422-53.382 41.097C42.938 79.423 29 98.274 29 120.341c0 10.409 3.082 20.102 8.398 28.187-.045.983-.09 1.966-.09 2.949 0 25.551 20.728 46.234 46.235 46.234 10.14 0 19.521-3.261 27.16-8.845 10.185 5.763 21.978 9.068 34.53 9.068 38.819 0 70.267-31.448 70.267-70.267.045-32.163-21.576-59.278-51.059-67.631z"/>
        <path fill="#FFDC01" d="M202.065 112.344c-.139.404-.316.771-.489 1.162-4.563 9.973-13.384 18.81-24.525 20.245l-.089-.341c8.47-3.543 13.551-11.031 15.827-19.956.217-.858.702-3.205.639-3.362l8.637 2.252z"/>
        <path fill="#FFEC03" d="M178.057 97.293c-1.761 8.475-3.134 30.175 3.886 39.686l-.264.239c-8.665-7.943-11.992-29.769-12.018-40.565l8.396.64z"/>
        <path fill="#FF8B00" d="M209.204 71.434c-1.08 1.059-5.938 6.992-10.5 13.868-4.969 7.467-9.202 15.317-12.457 23.6-1.071 2.782-2.01 5.619-2.824 8.51-1.779 6.574-2.485 12.173-2.459 17.928l-.353.044c-4.944-22.691 3.659-50.626 14.484-70.935l.592-1.097c.216-.385.391-.712.673-1.177l12.844 9.26z"/>
        <path fill="#D67205" d="M209.203 71.435l-12.844-9.259c-1.389 2.288-5.128 9.54-8.178 17.174l5.977.33 4.777 5.27c4.498-6.728 9.205-12.477 10.268-13.515z"/>
        <path fill="#FFB600" d="M208.254 91.505L195.32 77.24l-19.223-1.056 9.571-16.707-4.939-18.61 18.849 3.936 16.176-10.444 2.077 19.142 14.932 12.154-17.563 7.892-6.946 17.958z"/>
        <path fill="#FFC536" d="M196.155 75.505l-17.073-.938 8.497-14.837-4.381-16.524 16.734 3.497 14.364-9.276 1.843 16.999 13.259 10.794-15.592 7.005-6.169 15.947-11.482-12.667z"/>
        <path fill="#FFC536" d="M184.058 72.312c14.019 4.327 28.834-2.746 34.471-15.947l-2.385-1.942-1.845-16.995-14.365 9.272-16.735-3.498 4.381 16.525-6.561 11.453c.98.43 1.993.81 3.039 1.132z" opacity=".51"/>
        <path fill="#EABE06" d="M190.406 120.136l.598.192 2.534 4.857c3.954-3.959 7.382-9.565 8.528-12.842l-8.638-2.252c.079.192-.71 5.165-3.022 10.045z"/>
        <path fill="#FFD110" d="M192.74 118.264l4.868 9.327 6.149-8.535 10.372-1.747-6.217-8.486 1.544-10.405-9.99 3.29-9.417-4.682.038 10.516-7.363 7.511 10.016 3.211z"/>
        <path fill="#FFD938" d="M193.981 116.792l-7.962-2.554 5.854-5.97-.031-8.36 7.486 3.721 7.941-2.613-1.228 8.27 4.941 6.746-8.243 1.389-4.888 6.782-3.87-7.411z"/>
        <path fill="#FFCF03" d="M178.054 97.292l-8.392-.642c.007 2.916.256 6.642.786 10.679l3.334-1.202 3.027 1.4c.241-4.119.726-7.746 1.245-10.235z"/>
        <path fill="#FFB10F" d="M183.443 107.857l-9.543-4.419-9.895 3.562 1.253-10.442-6.446-8.311 10.317-2.032 5.914-8.702 5.124 9.187 10.098 2.934-7.152 7.71.33 10.513z"/>
        <path fill="#FFCA5C" d="M167.118 96.042l-5.126-6.605 8.201-1.618 4.697-6.913 4.075 7.303 8.027 2.329-5.686 6.13.263 8.357-7.584-3.513-7.867 2.833 1-8.303z"/>
        <path fill="#ED7D2B" d="M175.258 78.857l18.683 96.587c.38 1.964-1.125 3.788-3.126 3.788h-9.854l-5.703-100.375z"/>
        <path fill="#78ACEA" d="M175.034 78.466l12.384 94.832c.411 3.145-2.038 5.933-5.209 5.933h-18.166l10.242-101.108.749.343z"/>
        <path fill="#699DEE" d="M175.033 78.465l5.544 94.229c.209 3.547-2.611 6.537-6.164 6.537h-27.094l27.714-100.766z"/>
        <path fill="#5288D8" d="M175.242 79.234l-2.695 93.46c.208 3.547-2.611 6.537-6.165 6.537h-27.093l35.953-99.997z"/>
        <path fill="#D7E9FF" d="M45.524 179.231H152.35c2.667 0 4.954-1.906 5.433-4.53l16.256-88.894c.738-4.036-2.361-7.754-6.464-7.754H62.005c-2.666 0-4.953 1.906-5.432 4.53L40.09 172.715c-.62 3.392 1.984 6.516 5.433 6.516z"/>
        <path fill="#F99746" d="M171.926 97.352H53.798l3.03-16.784c.262-1.456 1.53-2.515 3.01-2.515h114.42c.625 0 1.098.567.985 1.181l-3.317 18.118z"/>
        <path fill="#78ACEA" d="M169.162 73.002c2.824 0 5.121 2.296 5.121 5.12h-.984c0-2.281-1.856-4.138-4.138-4.138s-4.139 1.856-4.139 4.138c0 .458.075.899.213 1.31.407 1.216 1.362 2.182 2.573 2.6.354.123.728.2 1.117.222v.984c-.454-.02-.893-.1-1.309-.232-1.66-.526-2.959-1.879-3.408-3.573-.11-.419-.169-.858-.169-1.31.002-2.825 2.299-5.121 5.123-5.121z"/>
        <path fill="#78ACEA" d="M167.579 82.709c0 .102.015.202.04.296.129.469.559.814 1.069.814.613 0 1.109-.497 1.109-1.109s-.496-1.108-1.109-1.108c-.357 0-.674.17-.877.432-.147.185-.232.42-.232.675zM150.803 73.002c2.824 0 5.121 2.296 5.121 5.12h-.983c0-2.281-1.857-4.138-4.139-4.138s-4.138 1.856-4.138 4.138c0 .458.074.899.212 1.31.407 1.216 1.363 2.182 2.572 2.6.354.123.729.2 1.119.222v.984c-.455-.02-.893-.1-1.309-.232-1.663-.526-2.96-1.879-3.409-3.573-.111-.419-.17-.858-.17-1.31.003-2.825 2.3-5.121 5.124-5.121z"/>
        <path fill="#78ACEA" d="M149.219 82.709c0 .102.015.202.04.296.13.469.559.814 1.07.814.612 0 1.109-.497 1.109-1.109s-.497-1.108-1.109-1.108c-.358 0-.677.17-.879.432-.145.185-.231.42-.231.675zM132.441 73.002c2.824 0 5.121 2.296 5.121 5.12h-.983c0-2.281-1.855-4.138-4.139-4.138-2.281 0-4.138 1.856-4.138 4.138 0 .458.075.899.213 1.31.407 1.216 1.363 2.182 2.571 2.6.354.123.729.2 1.119.222v.984c-.453-.02-.892-.1-1.308-.232-1.663-.527-2.959-1.879-3.408-3.573-.111-.419-.17-.858-.17-1.31.002-2.825 2.299-5.121 5.122-5.121z"/>
        <path fill="#78ACEA" d="M130.856 82.709c0 .102.015.202.04.296.13.469.56.814 1.07.814.612 0 1.108-.497 1.108-1.109s-.496-1.108-1.108-1.108c-.358 0-.675.17-.879.432-.146.185-.231.42-.231.675zM114.077 73.002c2.824 0 5.12 2.296 5.12 5.12h-.983c0-2.281-1.855-4.138-4.138-4.138-2.281 0-4.139 1.856-4.139 4.138 0 .458.075.899.213 1.31.407 1.216 1.362 2.182 2.572 2.6.354.123.728.2 1.117.222v.984c-.453-.02-.892-.1-1.309-.232-1.662-.527-2.959-1.879-3.407-3.573-.111-.419-.17-.858-.17-1.31.003-2.825 2.3-5.121 5.124-5.121z"/>
        <path fill="#78ACEA" d="M112.497 82.709c0 .102.014.202.04.296.129.469.559.814 1.069.814.613 0 1.109-.497 1.109-1.109s-.496-1.108-1.109-1.108c-.357 0-.674.17-.877.432-.147.185-.232.42-.232.675zM95.717 73.002c2.824 0 5.121 2.296 5.121 5.12h-.983c0-2.281-1.857-4.138-4.14-4.138-2.28 0-4.137 1.856-4.137 4.138 0 .458.075.899.213 1.31.406 1.216 1.362 2.182 2.573 2.6.352.123.728.2 1.117.222v.984c-.456-.02-.893-.1-1.309-.232-1.663-.526-2.96-1.879-3.409-3.573-.11-.419-.17-.858-.17-1.31.002-2.825 2.3-5.121 5.124-5.121z"/>
        <path fill="#78ACEA" d="M94.135 82.709c0 .102.015.202.04.296.13.469.559.814 1.07.814.612 0 1.108-.497 1.108-1.109s-.496-1.108-1.108-1.108c-.358 0-.677.17-.878.432-.146.185-.232.42-.232.675zM77.355 73.002c2.824 0 5.122 2.296 5.122 5.12h-.984c0-2.281-1.855-4.138-4.139-4.138-2.281 0-4.138 1.856-4.138 4.138 0 .458.075.899.213 1.31.407 1.216 1.363 2.182 2.573 2.6.352.123.729.2 1.117.222v.984c-.454-.02-.892-.1-1.308-.232-1.661-.526-2.96-1.879-3.408-3.573-.11-.419-.17-.858-.17-1.31.002-2.825 2.3-5.121 5.122-5.121z"/>
        <path fill="#78ACEA" d="M75.772 82.709c0 .102.015.202.04.296.13.469.56.814 1.07.814.612 0 1.108-.497 1.108-1.109s-.496-1.108-1.108-1.108c-.358 0-.675.17-.878.432-.147.185-.232.42-.232.675zM58.995 73.002c2.824 0 5.12 2.296 5.12 5.12h-.983c0-2.281-1.855-4.138-4.138-4.138-2.281 0-4.139 1.856-4.139 4.138 0 .458.075.899.213 1.31.407 1.216 1.362 2.182 2.573 2.6.354.123.728.2 1.117.222v.984c-.454-.02-.893-.1-1.309-.232-1.66-.526-2.959-1.879-3.407-3.573-.111-.419-.17-.858-.17-1.31.001-2.825 2.3-5.121 5.123-5.121z"/>
        <path fill="#78ACEA" d="M57.41 82.709c0 .102.016.202.04.296.13.469.56.814 1.07.814.613 0 1.109-.497 1.109-1.109s-.496-1.108-1.109-1.108c-.357 0-.674.17-.877.432-.147.185-.232.42-.232.675z"/>
        <path fill="#AFCDFB" d="M103.614 128.88h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.691 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.69.321 1.255 1.012 1.255zM88.544 128.88h5.971c.69 0 1.365-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255H90.18c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.133.69.323 1.255 1.013 1.255z"/>
        <path fill="#78ACEA" d="M73.475 128.88h5.972c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M148.819 128.88h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.69.322 1.255 1.012 1.255z"/>
        <path fill="#F99746" d="M155.365 110.953h2.566c.297 0 .586-.242.644-.539l.495-2.566c.057-.297-.139-.539-.435-.539h-2.566c-.297 0-.586.242-.644.539l-.495 2.566c-.057.296.139.539.435.539z"/>
        <path fill="#AFCDFB" d="M133.751 128.88h5.971c.691 0 1.364-.565 1.498-1.255l1.152-5.972c.132-.69-.323-1.255-1.013-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.134.69.323 1.255 1.013 1.255zM118.683 128.88h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#78ACEA" d="M58.407 128.88h5.972c.69 0 1.363-.565 1.497-1.255l1.152-5.972c.132-.69-.323-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.69.322 1.255 1.012 1.255z"/>
        <path fill="#AFCDFB" d="M106.116 115.909h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.971c.133-.69-.323-1.255-1.013-1.255h-5.971c-.691 0-1.365.565-1.498 1.255l-1.151 5.971c-.134.69.322 1.255 1.012 1.255zM91.048 115.909h5.971c.69 0 1.365-.565 1.498-1.255l1.151-5.971c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.971c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#78ACEA" d="M75.978 115.909h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.971c.133-.69-.322-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.497 1.255l-1.152 5.971c-.133.69.322 1.255 1.013 1.255zM60.911 115.909h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.971c.132-.69-.323-1.255-1.013-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.971c-.133.69.323 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M100.962 142.628h5.971c.691 0 1.364-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.134.69.323 1.255 1.013 1.255zM85.894 142.628h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.013-1.255H87.53c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#78ACEA" d="M70.825 142.628h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.69.322 1.255 1.012 1.255z"/>
        <path fill="#AFCDFB" d="M146.169 142.628h5.971c.691 0 1.365-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.134.69.322 1.255 1.013 1.255zM131.097 142.628h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.133.69.322 1.255 1.013 1.255zM116.03 142.628h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.691 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.69.322 1.255 1.012 1.255z"/>
        <path fill="#78ACEA" d="M55.759 142.628h5.971c.69 0 1.365-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.363.565-1.497 1.255l-1.152 5.972c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M98.311 156.376h5.972c.69 0 1.365-.565 1.497-1.255l1.152-5.971c.133-.691-.322-1.255-1.013-1.255h-5.971c-.69 0-1.365.564-1.497 1.255l-1.152 5.971c-.134.69.321 1.255 1.012 1.255zM83.241 156.376h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.971c.133-.691-.323-1.255-1.013-1.255h-5.971c-.69 0-1.365.564-1.498 1.255l-1.151 5.971c-.133.69.322 1.255 1.012 1.255z"/>
        <path fill="#78ACEA" d="M68.175 156.376h5.971c.69 0 1.365-.565 1.498-1.255l1.151-5.971c.133-.691-.322-1.255-1.012-1.255H69.81c-.69 0-1.364.564-1.497 1.255l-1.152 5.971c-.133.69.323 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M143.515 156.376h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.971c.133-.691-.322-1.255-1.012-1.255h-5.972c-.69 0-1.363.564-1.497 1.255l-1.152 5.971c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#F99746" d="M128.448 156.376h5.972c.69 0 1.363-.565 1.497-1.255l1.152-5.971c.133-.691-.323-1.255-1.013-1.255h-5.971c-.691 0-1.365.564-1.498 1.255l-1.151 5.971c-.134.69.322 1.255 1.012 1.255z"/>
        <path fill="#AFCDFB" d="M113.38 156.376h5.971c.691 0 1.365-.565 1.498-1.255L122 149.15c.133-.691-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.564-1.497 1.255l-1.152 5.971c-.134.69.323 1.255 1.013 1.255z"/>
        <path fill="#78ACEA" d="M53.105 156.376h5.971c.69 0 1.364-.565 1.497-1.255l1.152-5.971c.133-.691-.322-1.255-1.013-1.255h-5.971c-.69 0-1.365.564-1.498 1.255l-1.151 5.971c-.134.69.322 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M95.808 169.345h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.133.689.322 1.255 1.013 1.255zM80.741 169.345h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.69 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.689.322 1.255 1.012 1.255z"/>
        <path fill="#78ACEA" d="M65.67 169.345h5.972c.69 0 1.365-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.134.689.323 1.255 1.013 1.255z"/>
        <path fill="#AFCDFB" d="M141.015 169.345h5.971c.69 0 1.365-.565 1.497-1.255l1.152-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.365.565-1.497 1.255l-1.152 5.972c-.134.689.322 1.255 1.013 1.255zM125.946 169.345h5.972c.69 0 1.364-.565 1.497-1.255l1.152-5.972c.133-.69-.323-1.255-1.013-1.255h-5.971c-.691 0-1.365.565-1.498 1.255l-1.151 5.972c-.134.689.322 1.255 1.012 1.255zM110.878 169.345h5.971c.691 0 1.365-.565 1.498-1.255l1.151-5.972c.133-.69-.322-1.255-1.012-1.255h-5.972c-.69 0-1.364.565-1.497 1.255l-1.152 5.972c-.134.689.322 1.255 1.013 1.255zM137.265 108.595h15.819c.233 0 .424-.19.424-.423 0-.233-.191-.424-.424-.424h-15.819c-.233 0-.424.191-.424.424 0 .233.191.423.424.423zM136.783 110.513h15.821c.233 0 .423-.191.423-.423 0-.233-.19-.424-.423-.424h-15.82c-.233 0-.424.191-.424.424-.001.232.19.423.423.423z"/>
        <path fill="#fff" d="M154.232 94.33h5.429c.444 0 .808-.364.808-.809 0-.445-.364-.808-.808-.808h-5.429c-.445 0-.809.363-.809.808-.001.444.364.808.809.808zM139.335 94.33h5.429c.445 0 .808-.364.808-.809 0-.445-.363-.808-.808-.808h-5.429c-.444 0-.808.363-.808.808 0 .444.364.808.808.808zM124.437 94.33h5.429c.444 0 .808-.364.808-.809 0-.445-.364-.808-.808-.808h-5.429c-.445 0-.808.363-.808.808 0 .444.363.808.808.808zM109.542 94.33h5.429c.445 0 .808-.364.808-.809 0-.445-.363-.808-.808-.808h-5.429c-.444 0-.808.363-.808.808-.001.444.364.808.808.808zM94.646 94.33h5.429c.444 0 .808-.364.808-.809 0-.445-.364-.808-.808-.808h-5.43c-.444 0-.807.363-.807.808 0 .444.363.808.808.808zM79.75 94.33h5.428c.445 0 .808-.364.808-.809 0-.445-.363-.808-.808-.808H79.75c-.444 0-.808.363-.808.808 0 .444.364.808.808.808zM64.853 94.33h5.429c.444 0 .808-.364.808-.809 0-.445-.364-.808-.808-.808h-5.43c-.444 0-.807.363-.807.808 0 .444.363.808.808.808z"/>
    </g>
  </svg>
)

export default MonthlyTargetCalandar
