import React from 'react'

const Pin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0D1536"
      fillOpacity=".8"
      d="M7.58 2v.517c0 1.213.84 2.234 1.969 2.51l-.847 4.38h-.09C6.62 9.406 5 11.028 5 13.023v.516h6.484V22h1.032v-8.46H19v-.516c0-1.995-1.62-3.618-3.612-3.618h-.09l-.847-4.38c1.129-.275 1.97-1.296 1.97-2.51V2H7.58zm10.336 10.507H6.084c.24-1.178 1.282-2.067 2.528-2.067h6.776c1.246 0 2.289.889 2.528 2.067zm-3.67-3.1H9.754l.832-4.307h2.83l.832 4.306zm-.406-5.34h-3.68c-.673 0-1.247-.432-1.46-1.034h6.6c-.213.602-.787 1.034-1.46 1.034z"
    />
  </svg>
)

export default Pin
