import React from 'react'

const InfoIcon = React.forwardRef((props, ref) => (
  <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
    <path fill="#0D1536" fillOpacity=".65" d="M6.25 0C3.08 0 .5 2.58.5 5.75s2.58 5.75 5.75 5.75S12 8.92 12 5.75 9.42 0 6.25 0zm0 10.454c-2.594 0-4.705-2.11-4.705-4.704s2.11-4.705 4.705-4.705c2.594 0 4.704 2.111 4.704 4.705s-2.11 4.704-4.704 4.704z"/>
    <path fill="#0D1536" fillOpacity=".65" d="M6.25 2.44c-.385 0-.697.312-.697.697 0 .384.312.696.697.696.384 0 .696-.312.696-.696 0-.385-.312-.698-.696-.698zM6.25 4.879c-.289 0-.523.234-.523.523v3.136c0 .289.234.523.523.523.289 0 .523-.234.523-.523V5.402c0-.29-.234-.523-.523-.523z"/>
  </svg>
))

export default InfoIcon
