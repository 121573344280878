import React from 'react'

const ImportantNoteIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="16"
        cy="16"
        r="15.5"
        fill="#F2C602"
        fillOpacity="0.2"
        stroke="#B88214"
      />
      <path
        d="M17.9784 24.1953H13.45C13.0328 24.1953 12.6953 24.5329 12.6953 24.9501C12.6953 25.3672 13.0328 25.7048 13.45 25.7048H17.9784C18.3955 25.7048 18.7331 25.3672 18.7331 24.9501C18.7331 24.5329 18.3955 24.1953 17.9784 24.1953Z"
        fill="#B88214"
      />
      <path
        d="M9.46305 9.08948L8.76649 8.6868C8.65173 8.62043 8.52135 8.58545 8.38923 8.58545C8.12017 8.58545 7.86972 8.73022 7.73537 8.9632L7.36297 9.60915C7.15509 9.96963 7.27926 10.4322 7.63974 10.6402L8.33142 11.0391C8.44356 11.1037 8.56972 11.138 8.69636 11.138C8.9685 11.138 9.2175 10.9834 9.34625 10.7346C9.45344 10.5275 9.57364 10.3214 9.70341 10.1219C9.81704 9.94746 9.85364 9.74306 9.80662 9.54644C9.76048 9.35311 9.63846 9.19083 9.46305 9.08948Z"
        fill="#B88214"
      />
      <path
        d="M15.3822 6.85083C15.4806 6.8383 15.5967 6.82566 15.7138 6.82566C15.8309 6.82566 15.947 6.83843 16.0452 6.85083C16.0765 6.8548 16.1081 6.85675 16.1393 6.85675C16.5325 6.85675 16.8407 6.54512 16.8407 6.14731V5.32662C16.8407 4.9104 16.502 4.57178 16.0858 4.57178H15.3418C14.9255 4.57178 14.5869 4.91045 14.5869 5.32662V6.14735C14.5869 6.54516 14.8949 6.85679 15.2883 6.85679C15.3193 6.85679 15.3511 6.8548 15.3822 6.85083Z"
        fill="#B88214"
      />
      <path
        d="M11.4283 8.11231C11.5718 8.11231 11.713 8.06974 11.8364 7.98952C12.036 7.85963 12.2421 7.73943 12.4492 7.63224C12.6339 7.53661 12.7669 7.37716 12.8237 7.1833C12.8794 6.99325 12.8546 6.79218 12.7537 6.61741L12.3548 5.92568C12.2204 5.69258 11.9697 5.54785 11.7006 5.54785C11.5687 5.54785 11.4384 5.58284 11.3238 5.64884L10.6779 6.02123C10.5031 6.12197 10.3782 6.28474 10.326 6.47949C10.2737 6.67425 10.3006 6.87772 10.4015 7.05224L10.8042 7.74879C10.9358 7.9765 11.1691 8.11231 11.4283 8.11231Z"
        fill="#B88214"
      />
      <path
        d="M23.6943 8.9632C23.56 8.73022 23.3095 8.58545 23.0405 8.58545C22.9083 8.58545 22.7778 8.62056 22.6632 8.6868L21.9666 9.08948C21.7912 9.19095 21.6691 9.35319 21.6229 9.54648C21.576 9.7431 21.6127 9.94754 21.7265 10.1219C21.8573 10.3224 21.9777 10.5286 22.0847 10.7347C22.2137 10.9832 22.4626 11.1377 22.7345 11.1377C22.8612 11.1377 22.9874 11.1034 23.0997 11.0387L23.7901 10.6401C24.1504 10.432 24.2745 9.96947 24.0666 9.60895L23.6943 8.9632Z"
        fill="#B88214"
      />
      <path
        d="M18.9816 7.63232C19.189 7.73962 19.3947 7.85946 19.5931 7.98833C19.7166 8.06848 19.8575 8.1108 20.0009 8.1108C20.2603 8.1108 20.4938 7.97483 20.6253 7.74721L21.0267 7.05224C21.2348 6.69188 21.1111 6.22928 20.7506 6.02099L20.1073 5.6492C19.9927 5.58296 19.8623 5.54785 19.7301 5.54785C19.4611 5.54785 19.2106 5.69262 19.0762 5.92544L18.6772 6.61712C18.5765 6.79205 18.5515 6.99313 18.6071 7.1833C18.6641 7.3774 18.797 7.5368 18.9816 7.63232Z"
        fill="#B88214"
      />
      <path
        d="M6.28589 13.6285V14.3727C6.28589 14.7889 6.62456 15.1275 7.04073 15.1275H7.86442C8.0721 15.1275 8.26697 15.0413 8.39917 14.891C8.53117 14.7407 8.59174 14.5368 8.56514 14.3315C8.55326 14.2396 8.5396 14.1187 8.5396 14.0008C8.5396 13.8838 8.55237 13.7676 8.56482 13.6693C8.59072 13.4646 8.52983 13.2611 8.39759 13.1111C8.26487 12.9604 8.06934 12.874 7.86142 12.874H7.04073C6.62456 12.8737 6.28589 13.2123 6.28589 13.6285Z"
        fill="#B88214"
      />
      <path
        d="M25.1433 14.373V13.6289C25.1433 13.2127 24.8046 12.874 24.3884 12.874H23.5677C23.3598 12.874 23.1643 12.9605 23.0315 13.1111C22.8992 13.2612 22.8384 13.4646 22.8643 13.6693C22.8767 13.7676 22.8894 13.8838 22.8894 14.0008C22.8894 14.1187 22.8758 14.2396 22.8639 14.3315C22.8373 14.5368 22.8979 14.7407 23.0299 14.891C23.1621 15.0413 23.357 15.1276 23.5647 15.1276H24.3883C24.8046 15.1279 25.1433 14.7894 25.1433 14.373Z"
        fill="#B88214"
      />
      <path
        d="M23.7891 17.3613L23.0813 16.9534C22.97 16.8893 22.8455 16.8555 22.7212 16.8555C22.4416 16.8555 22.1877 17.0269 22.0746 17.292C21.9795 17.515 21.8778 17.7418 21.7729 17.966C21.6007 18.3339 21.7399 18.7803 22.0901 18.9825L22.6627 19.3133C22.7772 19.3795 22.9077 19.4144 23.0397 19.4144C23.3087 19.4144 23.5594 19.2699 23.6937 19.037L24.0659 18.3923C24.1668 18.2176 24.1934 18.0142 24.1412 17.8194C24.089 17.6247 23.9639 17.4619 23.7891 17.3613Z"
        fill="#B88214"
      />
      <path
        d="M8.70088 16.8579C8.57671 16.8579 8.45229 16.8918 8.34101 16.9559L7.63902 17.3607C7.46434 17.4614 7.33931 17.6242 7.2871 17.819C7.23484 18.0137 7.26156 18.2172 7.36238 18.3918L7.73454 19.0365C7.86901 19.2694 8.1197 19.4141 8.38893 19.4141C8.5208 19.4141 8.65106 19.3793 8.76567 19.3131L9.32751 18.9892C9.67732 18.7875 9.81763 18.3413 9.6468 17.9732C9.54123 17.7457 9.44025 17.5179 9.34672 17.2962C9.23435 17.03 8.98089 16.8579 8.70088 16.8579Z"
        fill="#B88214"
      />
      <path
        d="M18.0873 27.1867C18.0873 26.7706 17.7507 26.4341 17.3347 26.4341H14.0947C13.6787 26.4341 13.342 26.7706 13.342 27.1867C13.342 27.6027 13.6786 27.9392 14.0947 27.9392H17.3348C17.7507 27.9392 18.0873 27.6028 18.0873 27.1867Z"
        fill="#B88214"
      />
      <path
        d="M10.8626 18.6033C11.5518 19.9869 12.2026 21.2935 12.3093 22.5788C12.3431 22.9845 12.6811 23.3023 13.079 23.3023H18.3516C18.7508 23.3023 19.0885 22.9838 19.1206 22.5771C19.2257 21.2418 19.9169 19.865 20.5851 18.5336C21.3424 17.025 22.1254 15.4652 22.1254 13.8733C22.1254 10.3389 19.2498 7.46338 15.7154 7.46338C12.1809 7.46338 9.30542 10.3389 9.30542 13.8733C9.30526 15.4763 10.097 17.066 10.8626 18.6033ZM15.7153 9.71718C18.007 9.71718 19.8715 11.5817 19.8715 13.8734C19.8715 14.9306 19.2412 16.187 18.5739 17.5172L18.571 17.523C18.0235 18.6113 17.4572 19.737 17.1294 20.9524C17.1165 21.0002 17.0517 21.0486 17.0146 21.0486H14.4086C14.3706 21.0486 14.3042 20.9991 14.2907 20.9504C13.9663 19.7789 13.4135 18.6706 12.8789 17.5989C12.1993 16.2324 11.559 14.945 11.559 13.8734C11.559 11.5816 13.4235 9.71718 15.7153 9.71718Z"
        fill="#B88214"
      />
    </svg>
  )
}

export default ImportantNoteIcon
