import React from 'react'

const EmptyIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="194" height="192" fill="none" viewBox="0 0 194 192" {...props}>
    <path fill="#E5F1F9" d="M152.587 178.567c0 3.115-21.33 5.597-47.571 5.597-26.293 0-47.57-2.534-47.57-5.597 0-3.115 21.33-5.596 47.57-5.596 26.294 0 47.571 2.481 47.571 5.596z"/>
    <path fill="#AED6F2" d="M109.874 160.722c-37.91 2.429-99.472-20.01-76.505-56.124 17.793-27.983 3.274-47.835-5.913-56.283-16.21-14.889.686-52.745 77.243-47.888 69.905 4.435 95.829 51.056 87.012 92.925-7.92 37.592-38.332 64.625-81.837 67.37z"/>
    <path fill="#068AE2" d="M69.584 117.854l-14.186 14.187 4.629 4.629 14.187-14.186-4.63-4.63z"/>
    <path fill="#068AE2" d="M53.238 127.637L0 180.875 11.125 192l53.238-53.238-11.125-11.125z"/>
    <path fill="#068AE2" d="M53.21 127.665l-3.696 3.696 11.126 11.125 3.696-3.696-11.126-11.125zM109.398 21.494c-33.315 0-60.295 26.98-60.295 60.242 0 33.263 26.98 60.243 60.243 60.243 33.262 0 60.242-26.98 60.242-60.243.053-33.263-26.927-60.242-60.19-60.242zm0 109.45c-27.19 0-49.207-22.017-49.207-49.208 0-27.19 22.016-49.208 49.207-49.208 27.191 0 49.208 22.017 49.208 49.208s-22.069 49.208-49.208 49.208z"/>
    <g opacity=".7">
        <path fill="#E1FFF5" d="M132.418 72.232c-4.118-7.34-3.801-15.365.74-17.846 4.487-2.535 11.51 1.425 15.575 8.764 4.118 7.34 3.801 15.364-.739 17.846-4.488 2.482-11.457-1.425-15.576-8.764zM123.759 51.694c-1.689-2.112-1.425-5.122.581-6.759 2.007-1.584 5.016-1.214 6.706.95 1.689 2.113 1.425 5.175-.581 6.759-2.006 1.584-5.016 1.162-6.706-.95z" opacity=".7"/>
    </g>
    <path fill="#E5F1F9" d="M34.161 73.289c2.904-14.097-12.619-15.892-13.991-7.709-1.848 10.771 11.563 19.535 13.991 7.709zM29.99 85.168c-5.702 1.636-2.112 7.76 1.162 6.705 4.276-1.373 3.643-8.078-1.162-6.705z"/>
    <circle cx="85.534" cy="72.338" r="6.864" fill="#383838"/>
    <circle cx="84.478" cy="71.282" r="3.696" fill="#fff"/>
    <circle cx="87.91" cy="76.298" r="1.32" fill="#fff"/>
    <circle cx="127.772" cy="72.338" r="6.864" fill="#383838"/>
    <circle cx="126.716" cy="71.282" r="3.696" fill="#fff"/>
    <circle cx="130.148" cy="76.298" r="1.32" fill="#fff"/>
    <path fill="#383838" fillRule="evenodd" d="M105.239 84.085c-3.652.524-6.39 2.53-7.77 4.384-.35.467-1.01.564-1.478.216-.468-.348-.565-1.01-.217-1.478 1.7-2.281 4.928-4.605 9.165-5.212 4.276-.613 9.469.535 15.047 5.02.455.365.527 1.03.161 1.485-.365.454-1.03.526-1.484.161-5.193-4.174-9.811-5.094-13.424-4.576z" clipRule="evenodd"/>
  </svg>
)

export default EmptyIcon
