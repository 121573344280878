import React from 'react'
import { Popup } from '../../../../components/Popup'
import styled from 'styled-components'
import CrossIcon from '../../../../components/Images/CrossDark'
import './style.css'
import { Box } from '../../../../designSystem'

const Container = styled.div`
  padding: 48px;
  background-color: var(--Dove);
  margin-bottom: 96px;
`
const Heading = styled.h1`
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--Text500);
`

const Text = styled.p`
  margin: 16px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.75;
  text-align: left;
  color: ${({ good }) => (good ? 'green' : 'red')};
`

const NovelDescription = ({ onClose, ...props}) => {
  return (
    <Popup show={true} close={onClose} className="description-popup" >
      <>
        <>
          <Box variant="flex">
            <Box><Heading>Important Tips for Hindi Novel Description</Heading></Box>
            <Box><CrossIcon onClick={onClose} /></Box>
          </Box>
          <Text good={true}>GOOD</Text>
          <table className="sample-desc-table">
            <tr>
              <th></th>
              <th>Title</th>
              <th>~600 characters long description</th>
              {/* <th>~400 characters long description</th> */}
            </tr>
            <tr>
              <td>1</td>
              <td>
                <b>Pyaar ya nafrat|प्यार या नफरत|Author- Swani</b>{' '}
              </td>
              <td>
                कहते है प्यार और नफ़रत के दरमियान काफी कम फासला होता है ।ये फासला
                कब नजदीकियों में बदल जाता है, इसका एहसास भी नहीं हो पाता । ये
                कहानी है झील की जो की प्यार में धोखा मिलने के बाद प्यार के एहसास
                से भी मुंह मोड़ चुकी है ।अब झील के लिए उसका काम ही सब कुछ है और
                वही धीरज जिसका फिलहाल एक ही मकसद है झील से बदला लेना उसके लिए वो
                किसी भी हद तक जा सकता है । मगर दिल के किसी कोने में आज भी वो झील
                से बहुत प्यार करता है धीरज के दिल में झील के लिए जो छुपा हुआ
                प्यार है या उसके लिए जो नफ़रत है कौन सा एहसास धीरज के दिल पर राज़
                करेगा ,प्यार या नफ़रत ??जानने के लिए पढ़िए " Pyaar Ya Nafrat "
                सिर्फ Pocket FM पर| .<br />
                <br />
                <b>(594 characters)</b>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <b>
                  Dil Tum Bin Lagta Nahi|दिल तुम बिन लगता नहीं| Author-Deepika
                  Bhardwaj
                </b>
              </td>
              <td>
                अतीत के पन्नो में सुलगती एक मोहोब्बत जिसने पूरा होने से पहले ही
                अपना दम तोड़ दिया था, और इसी वजह से शायद शिविका अपने अतित में से
                बाहर नही आ पा रही थी | खैर किया वो भूली भिसरी यादें शिविका भूला
                पाएगी?क्या वो अपने आज में वापस लौट पाएगी ? कई सवाल है और ये सारे
                सवाल सिर्फ एक ही शक्स के इर्दगिर्द घूमते नजर आते है | और वो शक्स
                है युवराज जो एक उम्मीद बनके शिविका की जिंदगी में आया है ! क्या
                शिविका अपना अतित भुला के एक नयी शूरुवात कर पाएगी ? क्या युवराज
                उसे उसकी खुशिया लौटने में कामयाब हो जाएगा? ऐसे हजारो सवाल और ढेर
                सारा रोमांस ,तड़प एन्जॉय करने के लिए पढ़िये "dil tum bin lgta
                nahi "और जुड़े रहे POCKET FM पर।
                <br />
                <br />
                <b>(609 characters)</b>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <b>
                  Kashi : ek prem kahani | काशी : एक प्रेम कहानी |Author-Preeti
                  Tiwari
                </b>
              </td>
              <td>
                काशी जो की बनारस की एक साधारण सी लड़की है , वो अपने बाबा के साथ
                बनारस में बहुत खुश थी । तो वही दूसरी तरफ अभय प्रताप सिंह जो एक
                रॉयल family से बिलोंग करता है, गुस्से वाला, गुरुर से भरा हुआ
                ,अहंकारी लड़का है । पर उसका ये गुरुर टूटता है जब वो मिलता है
                काशी से | तो ऐसा क्या हुआ होगा जिससे काशी के आने से , अभय प्रताप
                खुद में बदलाव लाने लगा ? अभय और काशी की ज़िंदगी से जुड़े सवालों
                को जानने के लिए पढिए "Kashi : ek prem kahani" और जुड़े रहे सिर्फ
                आपके अपने Pocket FM पर
                <br />
                <b>(466 characters)</b>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <b>Adhoore Risthey | अधुरे रिश्ते | Author - Pinky Khurana</b>
              </td>
              <td>
                काशी जो की बनारस की एक साधारण सी लड़की है , वो अपने बाबा के साथ
                बनारस में बहुत खुश थी । तो वही दूसरी तरफ अभय प्रताप सिंह जो एक
                रॉयल family से बिलोंग करता है, गुस्से वाला, गुरुर से भरा हुआ
                ,अहंकारी लड़का है । पर उसका ये गुरुर टूटता है जब वो मिलता है
                काशी से | तो ऐसा क्या हुआ होगा जिससे काशी के आने से , अभय प्रताप
                खुद में बदलाव लाने लगा ? अभय और काशी की ज़िंदगी से जुड़े सवालों
                को जानने के लिए पढिए "Kashi : ek prem kahani" और जुड़े रहे सिर्फ
                आपके अपने Pocket FM पर
                <br />
                <b>(466 characters)</b>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <b>मौत का सौदा | Maut ka Sauda |</b>
              </td>
              <td>
                हममे से बहुत ऐसे लोग है जिन्होंने अघोरियों का नाम तो सुन होगा पर
                उनके पास जाने से भी डर लगता है ऐसी ही कहानी निशा नाम की एक ऐसी
                लड़की की है जिसे अघोरी से डर लगता है, जब वो अपने पापा के साथ
                विवेक की जान बचाने के लिए अंतिम कोशिश यानी एक अघोरी के पास जाते
                है|क्या अघोरि मचेन्द्र नाथ निशा की मदद करेगा? उसको निशा से क्या
                दुश्मनी है और निशा उसको देख के डर क्यूँ रही है? कौन है ये
                मचेन्द्र नाथ और क्या उसका निशा से से कोई पुराना नाता है? ड्रामे
                और थ्रीलर से भरपूर इस कहानी का आनंद लेने के लिए पढिए "Maut ka
                Sauda "और सुनते रहे Pocket FM पर।
                <br />
                <b>(523 characters)</b>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <b>Black Love|ब्लैक लव| Author- Riya Pandey</b>
              </td>
              <td>
                कहते हैं कि आग और पानी एक दूसरे के वजूद को मिटा देते हैं। रिशान,
                एक गैंगस्टर है तो वहीं विधा एक एनजीओ मे काम करने वाली बेहद
                खुशमिजाज लड़की है। क्या होगा जब दोनों होंगे आमने-सामने? क्या
                इनके बीच पनपेगा प्यार या तकरार? क्या होगी इस इश्क की कहानी? पढ़े
                पूरी कहानी सिर्फ Pocket FM पर।
                <br />
                <b>(280 characters)</b>
              </td>
            </tr>
          </table>
          <Text>BAD</Text>
          <table>
            <tr>
              <td>1</td>
              <td>
                <b>Dost</b>{' '}
              </td>
              <td>
                अगर आपके पास भी एक बहुत प्यारा दोस्त है , तोह आपको भी ये ज़रूर
                पसंद आयेगी।
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <b>Magical Drago</b>
              </td>
              <td>
                एक ऐसी दुनिया जहां इंसान और राक्षस एक बंधन बनाते हैं और एक साथ
                लड़ते हैं, वह दुनिया जहां दोनों शक्तिशाली होने के लिए एक साथ
                विकसित होते हैं। चलिए "अंश" के इस रोमांचक सफर में जहां वह अपने
                सबसे बड़े सपने को पूरा करने के लिए अपने सिल्वर स्पैरो के साथ सफर
                में आने वाले अनगिनत बाधाओं को पार करता है।
              </td>
            </tr>
          </table>
        </>
      </>
    </Popup>
  )
}

export default NovelDescription
