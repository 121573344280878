import React, { useState, useEffect, useRef } from 'react'
import BrandLogoIcon from '../../../components/Images/BrandLogoIcon'
import ChevronRight from '../../../components/Images/ChevronRight'
import ChevronLeft from '../../../components/Images/ChevronLeft'
import PocketFMIcon from '../../../components/Images/PocketFMIcon'
import ImportantNoteIcon from '../../../components/Images/ImportantNoteIcon'
import { dataIndia } from './data-india'
import { dataHindi } from './data-hindi'
import { handleEventLog, handleVideoWatchedEventLog, handleVideoPlayedEventLog } from '../../../lib/utils'
import revenueSvg from './Images/Revenue.svg'
import CompletionBonus from './Images/CompletionBonus.svg'
import DailyUpload from './Images/DailyUpload.svg'
import NewNovel from './Images/NewNovel.svg'
import Treasure from './Images/Treasure.svg'
import NewNovelDesktop from './Images/1D.svg'
import DailyUploadDesktop from './Images/2D.svg'
import CompletionBonusDesktop from './Images/3D.svg'
// import MobileVidThumb from './Images/MobileVidThumbnail.jpeg'
import MobileTopImage from './Images/MobileV.svg'
import DesktopTopImage from './Images/DesktopV.svg'
import Phone from './Images/phone.png'
import CtaIcon from './Images/icon.svg'
import { Popup } from '../../../components/Popup'
import {
  Accordion,
  AccordionBtn,
  AccordionContainer,
  AccordionText,
  Container,
  FrequentlyAskedQuestionsHeading,
  HowItWorksBtn,
  HowItWorksBtnContainer,
  HowItWorksCardHeading,
  HowItWorksCardIconContainer,
  HowItWorksCardsContainer,
  HowItWorksCardText,
  HowItWorksHeading,
  HowItWorksMobileCard,
  RewardsAndBenefits,
  RewardsAndBenefitsCards,
  RewardsAndBenefitsHeading,
  RewardsAndBenefitsNote,
  RewardsAndBenefitsNoteList,
  RewardsAndBenefitsSubHeading,
  RewardsAndBenefitsTable,
  WritersBenefitHeaderLink,
  WritersBenefitHeaderLinks,
  FirstSection,
  WBHeader,
  HeadingLine,
  AboutWBHeading,
  AboutWB,
  RevenueIncentive,
  Revenue,
  Incentive,
  RevenueHeading,
  IncentiveHeading,
  IncentiveSubHeading,
  RevenueCard,
  HowItWorksMobile,
  FAQs,
  Deadline,
  DeadlineInnerDiv,
  SponsoredBy,
  DeadlineMessage,
  DesktopTopSection,
  TopSectionFlexedDiv,
  TopSectionText,
  TopSectionImage,
  TopSectionVideo,
  AboutWBP,
  DesktopHeading,
  CreateNewButton,
  IncentiveCardsContainer,
  RevenueCardDesk,
  RevenueDesk,
  DeadlineDesk,
  TreasureImg,
  DeadlineMessageDesk,
  HowItWorksDesktop,
  HowItWorksDesktopCard,
  FAQsDesktop,
  WBHeaderDesktop,
  RevenueIncentiveDesktop,
  FAQSectionHead,
  CTAButtonPhone
} from './styles'

const mobileBreakPoint = 600 //600px viewport width breakpoint

export const daysDifference = timeDifference => Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

const WritersBenefitUI = ({ history,location,isUserIndian, language }) => {
  const wbpData = language === 'hindi' ? dataHindi : dataIndia
  const isPlayedEventSent = useRef(false);
  const isWatched10EventSent = useRef(false);
  const isWatched25EventSent = useRef(false);
  const isWatched50EventSent = useRef(false);
  const isWatched75EventSent = useRef(false);
  const isWatched100EventSent = useRef(false);
  

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < mobileBreakPoint ? true : false)
  const [videoLink, setVideoLink] = useState('')
  const [cardDetailsOpen, setCardDetailsOpen] = useState(false)
  const [variantType, setVariantType] = useState('')
  const [cardData, setCardData] = useState('revenue')
  const {year, month, deadline_date, days_or, months_to_go, deadlineDate, monthInWords } = wbpData
  const screenName = location.pathname.includes('/author-welfare') ? 'author_welfare' : 'writers_benefit'
  const resizeWindowHandler = () => setIsMobileView(window.innerWidth < mobileBreakPoint ? true : false)
  const createNewNovelHandler = () => {
    handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:'start_your_journey'})
    window.location.href = `https://writer.pocketnovel.com/?referrer=Studio_Writer_benefit_public`;
  }

  const toggleAccordionTextHandler = (e) => {
    const accordionTextElement = e.currentTarget.nextElementSibling
    if(accordionTextElement.tagName === "DIV"){
      e.currentTarget.classList.toggle("open")
      accordionTextElement.style.maxHeight ? accordionTextElement.style.maxHeight = null : accordionTextElement.style.maxHeight =  accordionTextElement.scrollHeight + 'px'
    }
  }
  useEffect(() => {
    setVariantType(language === 'hindi' ? 'variant_b' : 'variant_a')
  },[language])

  let interval
  useEffect(() => {
    handleEventLog(screenName,'page_load',{route : window.location.pathname + window.location.search})
    interval = setInterval(() => !document.hidden && handleEventLog(screenName,'wbp_heartbeat',{route : window.location.pathname + window.location.search,type:variantType}), 60000)
    return () => {
      clearInterval(interval);
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeWindowHandler)
    return () => {
      window.removeEventListener('resize', resizeWindowHandler) 
    }
  }, [])

  const videoLinkHandler = (videoCode) => {
    setVideoLink(videoCode)
  }

  const handleTimeUpdate = () => {
    const vid = document.getElementsByTagName("video")[0];
    const ct = parseInt(vid.currentTime)
    const duration = vid.duration
    prepareVideoEvents(ct, duration)
  };

  const sendVideoPlayedEvent = () => {
    if (!isPlayedEventSent.current) {
      handleVideoPlayedEventLog(screenName);
    }
    isPlayedEventSent.current = true;
  }

  const prepareVideoEvents = (ct, duration) => {
    if (ct === parseInt(1.0 * duration)) {
      if (!isWatched100EventSent.current) {
        handleVideoWatchedEventLog(screenName, 100);
        isWatched100EventSent.current = true;
      }
    }
    if (ct === parseInt(0.75 * duration)) {
      if (!isWatched75EventSent.current) {
        handleVideoWatchedEventLog(screenName, 75);
        isWatched75EventSent.current = true;
      }
    }
    if (ct === parseInt(0.5 * duration)) {
      if (!isWatched50EventSent.current) {
        handleVideoWatchedEventLog(screenName, 50);
        isWatched50EventSent.current = true;
      }
    }
    if (ct === parseInt(0.25 * duration)) {
      if (!isWatched25EventSent.current) {
        handleVideoWatchedEventLog(screenName, 25);
        isWatched25EventSent.current = true;
      }
    }
    if (ct === parseInt(0.1 * duration)) {
      if (!isWatched10EventSent.current) {
        handleVideoWatchedEventLog(screenName, 10);
        isWatched10EventSent.current = true;
      }
    }
  };

  const handleOpenCard = (cardData) => {
    handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:cardData})
    setCardData(cardData)
    setCardDetailsOpen(true)
  }

  const FAQAccordianContainer = ({type}) => {
    return (<AccordionContainer>
      {wbpData[type].map((data, index) => (
        <Accordion key={index}>
          <AccordionBtn onClick={toggleAccordionTextHandler}>
            {data.question}
          </AccordionBtn>
          <AccordionText>
            <span
              dangerouslySetInnerHTML={{ __html: data.answer }}
            ></span>
            {data.youtubeLink && (
              <span
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => videoLinkHandler(data.youtubeLink)}
              >
                here
              </span>
            )}
          </AccordionText>
        </Accordion>
      ))}
    </AccordionContainer>)
  }

  const CardDetails = () => {
    return (
      <>
        <RewardsAndBenefits>
          <ChevronLeft
            fillOpacity="1"
            style={{
              background: 'white',
              borderRadius: '4px',
              position: 'absolute',
              top: `1.8rem`,
              left: `1.8rem`,
              cursor: 'pointer',
            }}
            height={'35px'}
            width={'35px'}
            onClick={() => setCardDetailsOpen(false)}
          />
          <img src={wbpData[cardData].image} alt={cardData} />
          <RewardsAndBenefitsHeading >
            {wbpData[cardData].heading}
          </RewardsAndBenefitsHeading>
          <RewardsAndBenefitsSubHeading>
            {wbpData[cardData].subHeading}
          </RewardsAndBenefitsSubHeading>
          <RewardsAndBenefitsCards>
            {wbpData[cardData].table && <RewardsAndBenefitsTable>
              <thead>
                <tr>
                  {wbpData[cardData].tableHeadings.map(
                    (tableheading, index) => (
                      <th key={index}>{tableheading}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {wbpData[cardData].table.map((tableRow, index) => (
                  <tr key={index}>
                    <td>{tableRow.wordCount}</td>
                    <td>{tableRow.reward}</td>
                    {tableRow?.bonus && <td>{tableRow.bonus}</td>}
                  </tr>
                ))}
              </tbody>
            </RewardsAndBenefitsTable>}
            {wbpData[cardData].note && <RewardsAndBenefitsNote>
              <ImportantNoteIcon
                width={isMobileView ? '15vw' : '34px'}
                height={isMobileView ? '15vw' : '34px'}
                style={{minWidth: '35px'}}
              />
              <div>
                <p>{wbpData[cardData].note}</p>
                {/* <h4>retention is above benchmark.</h4> */}
              </div>
            </RewardsAndBenefitsNote>}
            <RewardsAndBenefitsNoteList>
              {wbpData[cardData].extraNotes.map((note) => (
                <li key={note.no}>{note.text}</li>
              ))}
            </RewardsAndBenefitsNoteList>
          </RewardsAndBenefitsCards>
        </RewardsAndBenefits>
      </>
    )
  }

  const MobileView = () => {
    const [step, setStep] = useState(0)  
    const [scrollState, setScrollState] = useState(false)

    const handlePageScroll = (e) => {
      if(window.scrollY !== 0) {
          setScrollState(true)
      }else{
          setScrollState(false)
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', handlePageScroll)
      return () => {
        window.removeEventListener('scroll', handlePageScroll)
      }
    }, [])

    return (
      <>
        {cardDetailsOpen && (
          <Popup
            show={true}
            close={() => setCardDetailsOpen(false)}
            className={'WBPCardDetails'}
            closeMarksStyles={{ top: '20%' }}
          >
            <CardDetails />
          </Popup>
        )}
        <FirstSection>
          <WBHeader>
            <PocketFMIcon
              width={isMobileView ? '100' : '132'}
              height={isMobileView ? '22' : '30'}
            />
            <WritersBenefitHeaderLinks>
              <WritersBenefitHeaderLink
                target="_blank"
                href="mailto:writers@pocketfm.in"
                onClick={() => handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:'contact_us'})}
              >
                {wbpData.contactUs}
              </WritersBenefitHeaderLink>
              <WritersBenefitHeaderLink 
                href="#faq"
                onClick={() => handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:'faq'})}
              >
              {wbpData.faqs}
              </WritersBenefitHeaderLink>
            </WritersBenefitHeaderLinks>
          </WBHeader>
          <div>
            <BrandLogoIcon
              width={isMobileView ? '95' : '162'}
              height={isMobileView ? '23' : '50'}
              style={{ margin: '25px 0 0 0' }}
            />
          </div>
          <HeadingLine>
            {wbpData.introductory_heading}
          </HeadingLine>
          {/* <img src={MobileTopImage} alt="" /> */}
          <img
            src={wbpData.video_thumbnail_mobile}
            alt=""
            onClick={() => videoLinkHandler('https://d2ps1cw166f1t3.cloudfront.net/5487fdskjhjygf63tuygw.mp4')}
          />
          <AboutWBHeading>{wbpData.aboutWriterProgram.heading}</AboutWBHeading>
          <AboutWB>
            {wbpData.aboutWriterProgram.subText}
          </AboutWB>
        </FirstSection>
        <RevenueIncentive>
          <h2>{wbpData.revenueAndIncetive.heading}</h2>
          <h4>{wbpData.revenueAndIncetive.tagline}</h4>
          <h6>{wbpData.revenueAndIncetive.note}</h6>
          <Revenue>
            <RevenueHeading>{wbpData.revenueCard.heading}</RevenueHeading>
            <SponsoredBy>{wbpData.revenueCard.sponsorLine}</SponsoredBy>
            <RevenueCard>
              <img src={revenueSvg} alt="revenue" />
              <h3>{wbpData.revenueCard.revenueShare}</h3>
              <ChevronRight
                fillOpacity="1"
                style={{
                  background: 'rgba(239, 241, 241, 0.6)',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: '5vw',
                  right: '5vw',
                  cursor: 'pointer',
                }}
                height={'35px'}
                width={'35px'}
                onClick={() => handleOpenCard('revenue')}
              />
            </RevenueCard>
          </Revenue>
          <Incentive>
            <IncentiveHeading>{wbpData.incentiveCard.heading}</IncentiveHeading>
            <SponsoredBy>{wbpData.incentiveCard.sponsorLine} </SponsoredBy>
            <IncentiveSubHeading>
              {wbpData.incentiveCard.tagline}
            </IncentiveSubHeading>
            <RevenueCard>
              <img src={NewNovel} alt="NewNovel" />
              <h4>1. {wbpData.incentiveCard.newNovelBonusCard.heading}</h4>
              <p>
                {wbpData.incentiveCard.newNovelBonusCard.blueTag}{' '}{' '}
              </p>
              <ChevronRight
                fillOpacity="1"
                style={{
                  background: 'rgba(239, 241, 241, 0.6)',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: '5vw',
                  right: '5vw',
                  cursor: 'pointer',
                }}
                height={'35px'}
                width={'35px'}
                onClick={() => handleOpenCard('new_novel_bonus')}
              />
            </RevenueCard>
            <RevenueCard>
              <img src={DailyUpload} alt="DailyUpload" />
              <h4>2. {wbpData.incentiveCard.dailyBonusCard.heading}</h4>
              <p>
                {wbpData.incentiveCard.dailyBonusCard.blueTag}{' '}
              </p>
              <ChevronRight
                fillOpacity="1"
                style={{
                  background: 'rgba(239, 241, 241, 0.6)',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: '5vw',
                  right: '5vw',
                  cursor: 'pointer',
                }}
                height={'35px'}
                width={'35px'}
                onClick={() => handleOpenCard('daily_update_bonus')}
              />
            </RevenueCard>
            <RevenueCard>
              <img src={CompletionBonus} alt="Completion Bonus" />
              <h4>3. {wbpData.incentiveCard.completionBonus.heading}</h4>
              <p>
                {wbpData.incentiveCard.completionBonus.blueTag}{' '}
              </p>
              <ChevronRight
                fillOpacity="1"
                style={{
                  background: 'rgba(239, 241, 241, 0.6)',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: '5vw',
                  right: '5vw',
                  cursor: 'pointer',
                }}
                height={'35px'}
                width={'35px'}
                onClick={() => handleOpenCard('completion_bonus')}
              />
            </RevenueCard>
          </Incentive>
        </RevenueIncentive>
        <Deadline>
          <DeadlineInnerDiv>
            <h3>{wbpData.deadline.heading}</h3>
            <h4>
            {deadlineDate}{' '}{monthInWords} : {' '}
              {daysDifference(
                new Date(year,month,deadline_date) - new Date()
              )}{' '}{days_or}{' '}
              {Math.floor(
                daysDifference(
                  new Date(year,month,deadline_date) - new Date()) / 31)}{' '}{months_to_go}
            </h4>
            <DeadlineMessage>
              <img src={Treasure} alt="treasure" />
              <p>
                {wbpData.deadline.text}{' '}
              </p>
            </DeadlineMessage>
          </DeadlineInnerDiv>
        </Deadline>
        <HowItWorksMobile>
          <HowItWorksHeading> {wbpData.howItWorksLine}</HowItWorksHeading>
          <HowItWorksCardsContainer>
            {wbpData.howItWorksArray.map((content, index) => (
              <HowItWorksMobileCard key={index} cardIndex={step} >
                <HowItWorksCardIconContainer>
                  {wbpData?.howItWorksArray[index]?.stepSVG}
                </HowItWorksCardIconContainer>
                <HowItWorksCardHeading>{wbpData.step} {index+1}</HowItWorksCardHeading>
                <HowItWorksCardText>
                  {wbpData.howItWorksArray[index].instruction}
                </HowItWorksCardText>
              </HowItWorksMobileCard>
              )
            )}
          </HowItWorksCardsContainer>
          <HowItWorksBtnContainer>
            <HowItWorksBtn onClick={() => setStep(0)} isActive={step + 1 === 1}>1</HowItWorksBtn>
            <HowItWorksBtn onClick={() => setStep(1)} isActive={step + 1 === 2}>2</HowItWorksBtn>
            <HowItWorksBtn onClick={() => setStep(2)} isActive={step + 1 === 3}>3</HowItWorksBtn>
            <HowItWorksBtn onClick={() => setStep(3)} isActive={step + 1 === 4}>4</HowItWorksBtn>
          </HowItWorksBtnContainer>
        </HowItWorksMobile>
        <FAQs>
          <FrequentlyAskedQuestionsHeading id='faq' > Frequently Asked Questions ⁉️ </FrequentlyAskedQuestionsHeading>
          <FAQSectionHead>Writing</FAQSectionHead>
          <FAQAccordianContainer type={'WritingFAQs'} />
          <FAQSectionHead>Program</FAQSectionHead>
          <FAQAccordianContainer type={'ProgramFAQs'} />
          <FAQSectionHead>Platform</FAQSectionHead>
          <FAQAccordianContainer type={'PlatformFAQs'} />
          <FAQSectionHead>Issues</FAQSectionHead>
          <FAQAccordianContainer type={'IssuesFAQs'} />
        </FAQs>
        <CTAButtonPhone scrolled={scrollState} onClick={createNewNovelHandler}>
          <img src={CtaIcon} style={{width: '25px'}} alt="Icon" /> <span>Start Your Journey</span>
        </CTAButtonPhone>
      </>
    )
  }

  const DesktopView = () => {
    return (
      <>
        {cardDetailsOpen && (
          <Popup
            show={true}
            close={() => setCardDetailsOpen(false)}
            className={'WBPCardDetails'}
            closeMarksStyles={{ top: '20%' }}
          >
            <CardDetails />
          </Popup>
        )}
        <DesktopTopSection>
          <WBHeaderDesktop>
            <PocketFMIcon
              width={isMobileView ? '100' : '132'}
              height={isMobileView ? '22' : '30'}
            />
            <WritersBenefitHeaderLinks>
              <WritersBenefitHeaderLink
                target="_blank"
                href="mailto:writers@pocketfm.in"
                style={{
                  color: 'rgba(13, 21, 54, 0.8)',
                  fontWeight: '700',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:'contact_us'})}
              >
                {wbpData.contactUs}
              </WritersBenefitHeaderLink>
              <WritersBenefitHeaderLink
                href="#faq"
                style={{
                  color: 'rgba(13, 21, 54, 0.8)',
                  fontWeight: '700',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => handleEventLog(screenName,'button_click',{route : window.location.pathname + window.location.search,button:'faq'})}
              >
                {wbpData.faqs}
              </WritersBenefitHeaderLink>
            </WritersBenefitHeaderLinks>
          </WBHeaderDesktop>
          <TopSectionFlexedDiv>
            <TopSectionText>
              <div>
                <BrandLogoIcon
                  width={'162'}
                  height={'50'}
                  style={{ margin: '25px 0 0 0' }}
                />
              </div>
              <DesktopHeading>
                {wbpData.introductory_heading}
              </DesktopHeading>
              <AboutWBP>
                <h3>{wbpData.aboutWriterProgram.heading}</h3>
                <p>{wbpData.aboutWriterProgram.subText}</p>
              </AboutWBP>
              <CreateNewButton onClick={createNewNovelHandler}>
                {wbpData.journeyCta} <img src={CtaIcon} style={{marginRight: "5px"}} alt="Icon" />
              </CreateNewButton>
            </TopSectionText>
            {/* <TopSectionImage src={DesktopTopImage} alt="" /> */}
            <TopSectionVideo videoThumbnail={wbpData.video_thumbnail_desktop}>
              <div></div>
              <img
                src={Phone}
                alt="phone"
                onClick={() => videoLinkHandler('https://d2ps1cw166f1t3.cloudfront.net/5487fdskjhjygf63tuygw.mp4')}
              />
            </TopSectionVideo>
          </TopSectionFlexedDiv>
        </DesktopTopSection>
        <RevenueIncentiveDesktop>
          <h2>{wbpData.revenueAndIncetive.heading}</h2>
          <h4>{wbpData.revenueAndIncetive.tagline}</h4>
          <h6>{wbpData.revenueAndIncetive.note}</h6>
          <RevenueDesk>
            <RevenueHeading style={{ fontSize: '32px' }}>
              {wbpData.revenueCard.heading}
            </RevenueHeading>
            <SponsoredBy style={{ fontSize: '16px' }}>
              {wbpData.revenueCard.sponsorLine}{' '}
            </SponsoredBy>
            <RevenueCardDesk style={{ width: '32vw', margin: '32px' }}>
              <img src={revenueSvg} alt="revenue" />
              <h3>{wbpData.revenueCard.revenueShare}</h3>
              <ChevronRight
                fillOpacity="1"
                style={{
                  background: 'rgba(239, 241, 241, 0.6)',
                  borderRadius: '4px',
                  position: 'absolute',
                  bottom: '1.2vw',
                  right: '1.2vw',
                  cursor: 'pointer',
                }}
                height={'35px'}
                width={'35px'}
                onClick={() => handleOpenCard('revenue')}
              />
            </RevenueCardDesk>
          </RevenueDesk>
          <Incentive>
            <IncentiveHeading style={{ fontSize: '32px' }}>
              {wbpData.incentiveCard.heading}
            </IncentiveHeading>
            <SponsoredBy style={{ fontSize: '16px' }}>
              {wbpData.incentiveCard.sponsorLine}{' '}
            </SponsoredBy>
            <IncentiveSubHeading style={{ fontSize: '2.5vw' }}>
              {wbpData.incentiveCard.tagline}
            </IncentiveSubHeading>
            <IncentiveCardsContainer>
              <RevenueCardDesk>
                <img src={NewNovelDesktop} alt="NewNovel" />
                <h4>1.{wbpData.incentiveCard.newNovelBonusCard.heading}</h4>
                <p>
                  {wbpData.incentiveCard.newNovelBonusCard.blueTag}{' '}
                </p>
                <ChevronRight
                  fillOpacity="1"
                  style={{
                    background: 'rgba(239, 241, 241, 0.6)',
                    borderRadius: '4px',
                    position: 'absolute',
                    bottom: '1.2vw',
                    right: '1.2vw',
                    cursor: 'pointer',
                  }}
                  height={'3vw'}
                  width={'3vw'}
                  onClick={() => handleOpenCard('new_novel_bonus')}
                />
              </RevenueCardDesk>
              <RevenueCardDesk>
                <img src={DailyUploadDesktop} alt="DailyUpload" />
                <h4>2. {wbpData.incentiveCard.dailyBonusCard.heading}</h4>
                <p>
                  {wbpData.incentiveCard.dailyBonusCard.blueTag}{' '}
                </p>
                <ChevronRight
                  fillOpacity="1"
                  style={{
                    background: 'rgba(239, 241, 241, 0.6)',
                    borderRadius: '4px',
                    position: 'absolute',
                    bottom: '1.2vw',
                    right: '1.2vw',
                    cursor: 'pointer',
                  }}
                  height={'3vw'}
                  width={'3vw'}
                  onClick={() => handleOpenCard('daily_update_bonus')}
                />
              </RevenueCardDesk>
              <RevenueCardDesk>
                <img src={CompletionBonusDesktop} alt="Completion Bonus" />
                <h4>3. {wbpData.incentiveCard.completionBonus.heading}</h4>
                <p>
                  {wbpData.incentiveCard.completionBonus.blueTag}{' '}
                </p>
                <ChevronRight
                  fillOpacity="1"
                  style={{
                    background: 'rgba(239, 241, 241, 0.6)',
                    borderRadius: '4px',
                    position: 'absolute',
                    bottom: '1.2vw',
                    right: '1.2vw',
                    cursor: 'pointer',
                  }}
                  height={'3vw'}
                  width={'3vw'}
                  onClick={() => handleOpenCard('completion_bonus')}
                />
              </RevenueCardDesk>
            </IncentiveCardsContainer>
          </Incentive>
        </RevenueIncentiveDesktop>
        <DeadlineDesk>
          <TreasureImg>
            <img src={Treasure} alt="treasure" />
          </TreasureImg>
          <DeadlineMessageDesk>
            <h3>{wbpData.deadline.heading}</h3>
            <h4>
            {deadlineDate}{' '}{monthInWords} : {' '}
              {daysDifference(new Date(year,month,deadline_date) - new Date()
              )}{' '}{days_or}{' '}
              {Math.floor(
                daysDifference(new Date(year,month,deadline_date) - new Date()) / 31)}{' '}{months_to_go}
            </h4>
            <p>
              {wbpData.deadline.text}{' '}
            </p>
          </DeadlineMessageDesk>
        </DeadlineDesk>
        <HowItWorksDesktop>
          <HowItWorksHeading>{wbpData.howItWorksLine}</HowItWorksHeading>
          <HowItWorksCardsContainer>
            {wbpData.howItWorksArray.map((content, index) => (
              <HowItWorksDesktopCard key={index} >
                <HowItWorksCardIconContainer>
                  {wbpData?.howItWorksArray[index]?.stepSVG}
                </HowItWorksCardIconContainer>
                <HowItWorksCardHeading>{wbpData.step} {index+1}</HowItWorksCardHeading>
                <HowItWorksCardText>
                  {wbpData.howItWorksArray[index].instruction}
                </HowItWorksCardText>
              </HowItWorksDesktopCard>
              )
            )}
          </HowItWorksCardsContainer>
        </HowItWorksDesktop>
        <FAQsDesktop id="faq">
          <FrequentlyAskedQuestionsHeading>Frequently Asked Questions ⁉️</FrequentlyAskedQuestionsHeading>
          <FAQSectionHead>Writing</FAQSectionHead>
          <FAQAccordianContainer type={'WritingFAQs'} />
          <FAQSectionHead>Program</FAQSectionHead>
          <FAQAccordianContainer type={'ProgramFAQs'} />
          <FAQSectionHead>Platform</FAQSectionHead>
          <FAQAccordianContainer type={'PlatformFAQs'} />
          <FAQSectionHead>Issues</FAQSectionHead>
          <FAQAccordianContainer type={'IssuesFAQs'} />
        </FAQsDesktop>
      </>
    )
  }

  return (
    <Container>
      {videoLink && (
        <Popup
          show={true}
          close={() => setVideoLink('')}
          className={'wbp_video'}
        >
          <div style={{ width: '80vw', height: '80vh', margin: 'auto', display: 'flex' , alignItems: 'center', justifyContent: 'center'}}>
            <div
              style={{
                position: 'absolute',
                color: '#464646',
                fontSize: '100px',
                top: '0',
                right: '0',
                zIndex: '2',
                cursor: 'pointer',
                lineHeight: '44px',
                margin: '40px'
              }}
              onClick={() => setVideoLink('')}
            >
              ˟
            </div>
            {!videoLink.includes("cloudfront") && <iframe
              style={{
                height: '100%',
                width: '100%',
              }}
              src={`https://www.youtube.com/embed/${videoLink}?autoplay=1`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
            ></iframe>}
            {videoLink.includes("cloudfront") && 
              <video 
                controls 
                autoPlay 
                preload="metadata" 
                onPlay={() => sendVideoPlayedEvent()} 
                onTimeUpdate={handleTimeUpdate}
                style={{maxWidth: '-webkit-fill-available', maxHeight: '-webkit-fill-available'}}
            >
              <source src={videoLink} type="video/mp4" />
            </video>}
          </div>
        </Popup>
      )}
      {isMobileView && <MobileView />}
      {!isMobileView && <DesktopView />}
    </Container>
  )
}
export default WritersBenefitUI
