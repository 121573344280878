import React from 'react'

const WritersBenefitContainer = props => {
  // implement logic like api calling here
  const child = React.Children.only(props.children)
  return (
    React.cloneElement(child, {
        ...props,
    })
  )
}

export default WritersBenefitContainer
