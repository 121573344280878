import React,{ useState } from 'react'
import { Popup } from '../../../components/Popup'
import styled from 'styled-components'
import { Box, Text } from '../../../designSystem'
import PocketWriter from './PocketWriter'
import { Link } from 'react-router-dom'

const WriterLogo = styled(PocketWriter)`
    width: 72px;
    height: 72px;
    @media(max-width:600px){
        width:40px;
        height: 40px;
    }
`

const CTALogin = styled.div`
    background-color:#068ae2;
    margin: 56px 90px 24px;
    pointer: cursor;
    @media(max-width:600px){
        margin: 24px 16px;
    }
`
const BackToHomeLink = styled(Link)`
    display: block;
    text-align: center;
    width: max-content;
    margin: 8px auto;
    cursor: pointer;
    color: var(--storm-text-storm-500) !important;
`

const WriterPopup = ({ setIsPopupShown,url="https://writer.pocketnovel.com/?referrer=Studio_Novels", ...props }) => {
    const [isPopupOpen,setIsPopupOpen] = useState(true)
    const onClose = () => {
        setIsPopupOpen(false)
        if (setIsPopupShown) {
            setIsPopupShown(false)
        }
    }
    return (
        <>
        <Popup show={isPopupOpen} close={onClose} disableAutoHide>
           <Box variant="flex" pt={8} pb={8} pr={[16,24]} pl={[16,24]} flexDirection="column">
                <Text variant="titleMedium" color="#068ae2" fontWeight={600} textAlign="center">Introducing</Text>
                <Box display="flex" pt={16} margin="auto">
                    <WriterLogo />
                    <Text fontSize={[32,46]} pt={[8,16]} pl={8} color="#068ae2" fontWeight="s">Pocket Writer</Text>
                </Box>
                <Text variant="titleSecondary" textAlign="center" pt={24}>We are moving the novels to our new platform Pocket Writer,exclusive for writers</Text>
                <a href={url}>
                    <CTALogin >
                        <Text fontSize={16} p={8} fontWeight="s" textAlign="center" color="rgba(255, 255, 255, 0.98)">Click here to login</Text>
                    </CTALogin>
                </a>
                <Box backgroundColor="rgba(242, 198, 2, 0.16)" mt={24} borderRadius={4}>
                    <Text fontSize={16} p={8} fontWeight="s" textAlign="center" color="#ae8701">Don’t worry! All your work progress will be safe</Text>
                </Box>
                <BackToHomeLink to="/home">Go back to home</BackToHomeLink>
            </Box>
        </Popup>
        </>
    )
}

export default WriterPopup
