import React from 'react'
import styled from 'styled-components'
import CheckedIcon from '../../components/Images/RadioSelected'
import UnCheckedIcon from '../../components/Images/RadioUnselected'

const ProgressArm1 = styled.div`
  width: ${({ width }) => "calc(" + (width || 0) + "% - " + (width) + "px)"};
  @media (max-width: 600px) {
    height: ${({ width }) => "calc(" + (width || 0) + "% - " + (width) + "px)"};
  }
`
const ProgressArm2 = styled.div`
  // width: ${({ width }) => width || 0}%;
  width: ${({ width }) => "calc(" + (width || 0) + "% - " + (width) + "px)"};
  @media (max-width: 600px) {
    height: ${({ width }) => "calc(" + (width || 0) + "% - " + (width) + "px)"};
  }
`

const ProgressDivOuter = styled.div`
  border-radius: 4px;
  background-color: var(--Grey300);
  height: 8px;

  @media (max-width: 600px) {
    width: 8px;
    height: 100%;
  }
`
const ProgressDivInner = styled.div`
  height: 8px;
  background-color: var(--Azure500);
  border-radius: 4px;
  width: ${({ width }) => width || 0}%;

  @media (max-width: 600px) {
    width: 8px;
    height: ${({ width }) => width || 0}%;
  }
`
const Container = styled.div`
  @media (max-width: 600px) {
    height: 300px;
    display: flex;
  }
`
const TopLevel = styled.div`
  display: flex;
  justify-content: space-between;
`
const MidLevel = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0 14px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: 300px;
    svg {
      margin: 14px 0 14px 8px;
    }
  }
`
const BottomLevel = styled.div`
  display: flex;
  justify-content: space-between;
`
const CurrentLabel = styled.span`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
`
const MileStoneLabel = styled(CurrentLabel)``
const MileStoneAward = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--Text500);
  // margin-left: ${({ margin }) => "calc(" + (margin || 0) + "% - " + (margin * 1.2) + "px)"};
`
const MileStoneAwardDummy = styled(MileStoneAward)`
  visibility: hidden;
`
const MobileLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 24px;
`
const StepLabelMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const TimeLine = ({ word_count, wordCountLabel, mileStone1, mileStone2, mileStone1Label, mileStone2Label, mileStone1Award, mileStone2Award}) => {
  const isMobile = window.screen.width < 600
  let remainingWordCount = word_count - mileStone1
  remainingWordCount = remainingWordCount < 0 ? 0 : remainingWordCount
  return (
    <Container>
      {!isMobile && <TopLevel>
        <CurrentLabel>{wordCountLabel}</CurrentLabel>
        <MileStoneLabel>{mileStone1Label}</MileStoneLabel>
        <MileStoneLabel>{mileStone2Label}</MileStoneLabel>
      </TopLevel>}
      <MidLevel>
        <ProgressArm1 width={100 * (mileStone1 / mileStone2)}>
          <ProgressDivOuter>
            <ProgressDivInner width={(word_count / (mileStone1 / 100)) > 100 ? 100 : (word_count / (mileStone1 / 100))} />
          </ProgressDivOuter>
        </ProgressArm1>
        {word_count >= mileStone1 && <CheckedIcon />}
        {word_count < mileStone1 && <UnCheckedIcon />}
        <ProgressArm2 width={100 * (1 - (mileStone1 / mileStone2))}>
          <ProgressDivOuter>
            <ProgressDivInner width={(remainingWordCount / (mileStone2 - mileStone1) * 100) > 100 ? 100 : (remainingWordCount / (mileStone2 - mileStone1) * 100)} />
          </ProgressDivOuter>
        </ProgressArm2>
        {word_count >= mileStone2 && <CheckedIcon />}
        {word_count < mileStone2 && <UnCheckedIcon />}
      </MidLevel>
      {!isMobile && <BottomLevel>
        <MileStoneAwardDummy>just a long string</MileStoneAwardDummy>
        <MileStoneAward>{"+₹" + mileStone1Award}</MileStoneAward>
        <MileStoneAward>{"+₹" + mileStone2Award}</MileStoneAward>
      </BottomLevel>}
      {isMobile && (
        <MobileLabelsContainer>
          <StepLabelMobile>
            <CurrentLabel>{wordCountLabel}</CurrentLabel>
          </StepLabelMobile>
          <StepLabelMobile>
            <MileStoneLabel>{mileStone1Label}</MileStoneLabel>
            <MileStoneAward >{"+₹" + mileStone1Award}</MileStoneAward>
          </StepLabelMobile>
          <StepLabelMobile>
            <MileStoneLabel>{mileStone2Label}</MileStoneLabel>
            <MileStoneAward>{"+₹" + mileStone2Award}</MileStoneAward>
          </StepLabelMobile>
        </MobileLabelsContainer>
      )}
    </Container>
  )
}
export default TimeLine
