import React from 'react'

const Vellip = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="6" r="2" fill="#0D1536" fillOpacity=".65"/>
    <circle cx="12" cy="18" r="2" fill="#0D1536" fillOpacity=".65"/>
    <circle cx="12" cy="12" r="2" fill="#0D1536" fillOpacity=".65"/>
  </svg>
)

export default Vellip
