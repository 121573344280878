import React, { useEffect, useState, useContext } from 'react'
import SubHeader from '../../components/WithSubHeaderBG'
import SingleEntityComments from './SingleEntityComments'
import { WithFilters } from '../../HOC/withFiltersHOC'
import {
  TabHolder,
  Container,
  EntityContainer,
  Tab,
  UnderLine,
  TabNameSpan,
} from './styles'
import Filters from '../../components/Filters'
import { CommentContext } from '../../providers/commentProvider'
import { get } from '../../lib/api'
import { useInfiniteScroll } from '../../lib/customHooks'
import Loader from '../../components/Loader'
import EmptyState from './empty'
import GenericError from '../../components/GenericError'
import { getTokenAndUid } from '../../lib/utils'
import { isAdminAccess } from '../../config'
import { menuItemObj } from '../../components/PrivatePage'

const CommentsUI = ({
  history,
  location: { pathname },
  filterObj,
  setFilterObj,
  qFilters,
  ...props
}) => {
  const { allComments, onUpdateComments } = useContext(CommentContext)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const [nextUrl, setNextUrl] = useState(null)
  const [showLoader, toggleShowLoader] = useState(true)
  const [errorPopup, toggleErrorPopup] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  // const [showFilter, setShowFilter] = useState({})
  // const [commentCount, setCommentCount] = useState(0)
  const { uid } = getTokenAndUid()
  const isComment = isReviewOrComment()

  // const { search } = location
  // const qParams = queryString.parse(search)
  // const { tab, entity_id } = qParams
  // let active = tab || 'audiobooks'

  function isReviewOrComment() {
    return window.location.href.includes('comments') ? true : false
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    let data = {
      caller_id: uid,
      last_fetched_comment_id: (nextUrl || {}).last_fetched_comment_id,
      view: isAdminAccess ? 'cms' : 'dashboard',
      show_id: (filterObj.show_id || [])[0],
      last_fetched_comment: (nextUrl || {}).last_fetched_comment,
      offset_no: (nextUrl || {}).offset_no,
      ratings: (filterObj.ratings || [])[0],
    }
    if ((filterObj.responded_comment_check || [])[0]) {
      data['responded_comment_check'] = true
    }

    // const api = isComment ? '/social/comment.get_creator_comments' : '/social/comment.get_creator_reviews'
    const api = isComment
      ? '/social/get_creator_show_comments'
      : '/social/comment.get_creator_reviews'
    get(api, {
      params: data,
    })
      .then((res) => {
        let comments = JSON.parse(JSON.stringify(allComments))
        onUpdateComments(
          comments.concat(
            isComment ? res.result.story_comments : res.result.show_reviews
          )
        )
        setNextUrl(res.result.next_obj)
        setIsFetching(false)
      })
      .catch((err) => {
        setIsFetching(false)
        toggleErrorPopup(true)
        throw err
      })
  }

  useEffect(() => {
    get('/user_api/user.get_details', {
      params: {
        uid: uid,
        is_novel: 0,
      },
    })
      .then((res) => {
        setUserDetails(res.result[0])
      })
      .catch((err) => {
        toggleErrorPopup(true)
        throw err
      })
  }, [])

  useEffect(() => {
    fetchComments()
  }, [qFilters, pathname])

  // useEffect(() => {
  //   if (entity_id) return
  //   fetchComments({})
  // }, [active])

  const fetchComments = () => {
    toggleShowLoader(true)
    // if (active === 'novels') {
    //   toggleShowLoader(false)
    //   onUpdateComments([])
    // } else {

    let data = {
      caller_id: uid,
      last_fetched_comment_id: '',
      view: isAdminAccess ? 'cms' : 'dashboard',
      show_id: (filterObj.show_id || [])[0],
      ratings: (filterObj.ratings || [])[0],
    }

    if ((filterObj.responded_comment_check || [])[0]) {
      data['responded_comment_check'] = true
    }

    // const api = isComment ? '/social/comment.get_creator_comments' : '/social/comment.get_creator_reviews'
    const api = isComment
      ? '/social/get_creator_show_comments'
      : '/social/comment.get_creator_reviews'

    get(api, {
      params: data,
    })
      .then((res) => {
        console.log(res)
        onUpdateComments(
          isComment ? res.result.story_comments : res.result.show_reviews
        )
        setNextUrl(res.result.next_obj)
        // setCommentCount(res.result.total_parent_comments_count || 0)
        toggleShowLoader(false)
      })
      .catch((err) => {
        toggleErrorPopup(true)
        throw err
      })
    // }
  }

  const setFilters = (obj) => {
    const qString = setFilterObj(obj)
    const menuObj = isComment ? menuItemObj.comments : menuItemObj.reviews
    history.push(`${menuObj}?tab=audiobooks&${qString}`)
  }

  return (
    <>
      <GenericError show={errorPopup} onClose={() => toggleErrorPopup(false)} />
      <SubHeader title="Comments">
        <TabHolder>
          <Tab active={true}>
            <TabNameSpan>Audiobooks</TabNameSpan>
            <UnderLine active={true} />
          </Tab>
          {/* {['AudioBooks', 'Novels].map((tab, i) => {
            return (
              <Tab
                key={tab}
                last={i === 1}
                active={active.toUpperCase() === tab.toUpperCase()}
                onClick={() =>
                  history.push(
                    `/community/comments?tab=${tab
                      .replace(/\s/g, '-')
                      .toLowerCase()}`
                  )
                }
              >
                <TabNameSpan>{tab}</TabNameSpan>
                <UnderLine
                  active={active.toUpperCase() === tab.toUpperCase()}
                />
              </Tab>
            )
          })} */}
        </TabHolder>
        <Filters
          showSearch={true}
          filterObj={filterObj}
          setFilters={setFilters}
          options={
            !isComment
              ? [
                  // {
                  //   name: 'Ratings',
                  //   value: 'ratings',
                  //   filterKey: 'ratings',
                  //   isFilterCategory : true
                  // },
                  // {
                  //   name: '1 star',
                  //   value: '1',
                  //   filterKey: 'ratings',
                  //   parent: 'ratings'
                  // },
                  // {
                  //   name: '2 star',
                  //   value: '2',
                  //   filterKey: 'ratings',
                  //   parent: 'ratings'
                  // },
                  // {
                  //   name: '3 star',
                  //   value: '3',
                  //   filterKey: 'ratings',
                  //   parent: 'ratings'
                  // },
                  // {
                  //   name: '4 star',
                  //   value: '4',
                  //   filterKey: 'ratings',
                  //   parent: 'ratings'
                  // },
                  // {
                  //   name: '5 star',
                  //   value: '5',
                  //   filterKey: 'ratings',
                  //   parent: 'ratings'
                  // }
                ]
              : !isAdminAccess
              ? [
                  {
                    name: "I haven't responded",
                    filterKey: 'responded_comment_check',
                    value: true,
                  },
                ]
              : []
          }
        />
        <>
          {showLoader && (
            <Container>
              <Loader />
            </Container>
          )}
          {!showLoader && allComments && allComments.length === 0 && (
            <Container>
              <EmptyState />
            </Container>
          )}

          {!showLoader && allComments && allComments.length > 0 && (
            <EntityContainer>
              {allComments.map((comment, index) => (
                <React.Fragment key={'entity-id-' + index + comment.story_id}>
                  {comment.result.length > 0 && (
                    <SingleEntityComments
                      entity_id={index}
                      storyWiseComments={comment}
                      userInfo={userDetails}
                      isComment={isComment}
                    />
                  )}
                </React.Fragment>
              ))}
            </EntityContainer>
          )}
          {isFetching && <Loader />}
        </>
        {/* </Container> */}
      </SubHeader>
    </>
  )
}

export default WithFilters(CommentsUI)
