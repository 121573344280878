import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import LikeIcon from '../../../components/Images/LikeIcon'
import ReplyIcon from '../../../components/Images/ReplyIcon'
import { get, post } from '../../../lib/api'
import { Box } from '../../../designSystem'
import AlertSnack from '../../../components/AlertSnackBar'
import {
  ReplyProfileImage,
  ProfileImageContainer,
  ReplyContainer,
  ReplyInfoContainer,
  ReplyHeader,
  ReplyInfo,
  ReplyBody,
  ReplyText,
  ReplyFooter,
  Likes,
  Reply,
  LikesDiv,
  ReplyDiv,
  VellipContainer,
  VellipIcon,
  CommentActionContainer,
  CommentAction,
  ActionText,
  ReplyAuthorIcon,
  ReplyCreatorIcon,
  LikeOutlinedSmall,
  LikeOutlinedIcon,
  PencilIcon,
  DustbinIcon,
  TypeReplyHere,
  TypeReplyDiv,
  ReplyButton,
  CreateReplyContainer,
  CancelButton,
  LikeBlackIcon,
  PopupDeleteCommentButton,
  PopupCancelButton,
  DeleteHeader,
  DeleteMessage,
  ExtendCommentLink,
  LinkCommentReplyCurve,
} from '../styles'
import NewReply from './addNewReply'
import { CommentContext } from '../../../providers/commentProvider'
import { Popup } from '../../../components/Popup'
import DustbinBlack from '../../../components/Images/DustbinBlack'
import GenericError from '../../../components/GenericError'
import { getTokenAndUid } from '../../../lib/utils'
import { isAdminAccess } from '../../../config'

const ChapterOptionsBox = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`

const Option = styled.div`
  padding: 16px 0px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`

const ReplyContainerDiv = (props) => {
  const isMobile = window.screen.width < 600
  const { replyText, setReplyText, editReply, closeReplyEdit } = props
  return (
    <CreateReplyContainer>
      <TypeReplyDiv>
        <TypeReplyHere
          as="textarea"
          value={replyText}
          placeholder="Reply"
          rows={3}
          onChange={(e) => setReplyText(e.target.value)}
        />
      </TypeReplyDiv>
      {!isMobile && (
        <Box position="absolute" right="0px">
          <CancelButton onClick={() => closeReplyEdit()}>Cancel</CancelButton>
          <ReplyButton onClick={() => editReply()}>Reply</ReplyButton>
        </Box>
      )}
    </CreateReplyContainer>
  )
}

const ReplyCard = ({
  comment,
  index,
  entityKey,
  isComment,
  commentIndex,
  userInfo,
  comment_id,
  moreReplies,
}) => {
  const { allComments, onUpdateComments } = useContext(CommentContext)
  const [showAction, toggleShowAction] = useState(false)
  const [addReply, toggleAddReply] = useState(false)
  const [reply, setReply] = useState(null)
  const [commentEdit, setCommentEdit] = useState(false)
  const [replyText, setReplyText] = useState(comment.comment)
  const [commentLiked, setCommentLiked] = useState(comment.is_liked)
  const [showDeletePopup, toggleShowDeletePopup] = useState(false)
  const [isCommentOptionsShown, toggleShowCommentOptions] = useState(false)
  const [savedChanges, setSavedChanges] = useState(false)
  const [errorPopup, toggleErrorPopup] = useState(false)
  const showActionsRef = useRef()
  const addReplyRef = useRef()
  const { uid } = getTokenAndUid()

  const isMobile = window.screen.width < 600
  const handleVellipClick = (e) => {
    e.stopPropagation()
    toggleShowCommentOptions(!isCommentOptionsShown)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    setReply(
      allComments[entityKey].result &&
        allComments[entityKey].result.length > commentIndex &&
        allComments[entityKey].result[commentIndex].comments &&
        allComments[entityKey].result[commentIndex].comments.length > index
        ? allComments[entityKey].result[commentIndex].comments[index]
        : null
    )
  }, [allComments])

  const handleClick = (e) => {
    if (showActionsRef.current && !showActionsRef.current.contains(e.target)) {
      toggleShowAction(false)
    }
    if (addReplyRef.current && !addReplyRef.current.contains(e.target)) {
      toggleAddReply(false)
    }
  }

  const createReply = () => {
    toggleAddReply(false)
    setCommentEdit(false)
  }

  const editReply = () => {
    if (replyText.length === 0) return
    post('/social/comment.create', {
      data: {
        comment_creator_uid: uid,
        story_id: allComments[entityKey].story_id,
        show_id: allComments[entityKey].result[commentIndex].show_id,
        author_id: allComments[entityKey].result[commentIndex].author_id,
        creator_id: allComments[entityKey].result[commentIndex].creator_id,
        comment: replyText,
        comment_id: comment._id.$oid,
        parent_comment_id: comment.parent_comment_id,
        entity_type: 'story',
        _id: {
          $oid: comment._id.$oid,
        },
        is_replied: 1,
        comment_creator_name: userInfo.fullname,
        comment_creator_image: userInfo.image_url,
      },
    }).then((res) => {
      let comments = JSON.parse(JSON.stringify(allComments))
      let replies = comments[entityKey].result[commentIndex].comments
      replies.splice(index, 1, res.result.comment_obj)
      // comments[entityKey].result[commentIndex].comments = replies

      createReply()
      onUpdateComments(comments)
      // get('/social/comment.get_comment_of_a_show', {
      //   params: {
      //     comment_id: creatorNote
      //       ? allComments[entityKey].creator_note.comment_id
      //       : allComments[entityKey].result[commentIndex].comment_id,
      //   },
      // })
      //   .then((response) => {
      //     let comments = JSON.parse(JSON.stringify(allComments))
      //     if (!creatorNote)
      //       comments[entityKey].result[commentIndex].comments =
      //         response.result[0].comments
      //     else
      //       comments[entityKey].creator_note.comments =
      //         response.result[0].comments
      //     createReply()
      //     onUpdateComments(comments)
      //   })
      //   .catch((err) => {
      //     toggleErrorPopup(true)
      //     throw err
      //   })
    })
  }

  const closeReplyEdit = () => {
    setCommentEdit(false)
    toggleAddReply(false)
  }

  const removeComment = () => {
    const deleteApi = isComment
      ? '/social/comment.delete'
      : '/social/review.delete'
    post(deleteApi, {
      data: {
        comment_creator_uid:
          allComments[entityKey].result[commentIndex].comments[index]
            .comment_creator_uid,
        story_id:
          allComments[entityKey].result[commentIndex].comments[index].story_id,
        comment_id:
          allComments[entityKey].result[commentIndex].comments[index]._id.$oid,
        parent_comment_id:
          allComments[entityKey].result[commentIndex].comments[index]
            .parent_comment_id,
        caller_id: uid,
        creator_id: allComments[entityKey].result[commentIndex].creator_id,
        author_id: allComments[entityKey].result[commentIndex].author_id,
      },
    }).then(() => {
      toggleShowDeletePopup(false)
      get('/social/comment.get_comment_of_a_show', {
        params: {
          comment_id: allComments[entityKey].result[commentIndex]._id.$oid,
        },
      }).then((response) => {
        let comments = JSON.parse(JSON.stringify(allComments))
        comments[entityKey].result[commentIndex].comments =
          response.result[0].comments

        onUpdateComments(comments)
      })
    })
  }

  const editComment = () => {
    setReplyText(comment.comment)
    setCommentEdit(true)
    toggleShowCommentOptions(false)
  }

  const updateLikeCount = (like_count) => {
    post('/social/comment.create', {
      data: {
        comment_creator_uid: comment.comment_creator_uid,
        story_id: allComments[entityKey].story_id,
        show_id: allComments[entityKey].result[commentIndex].show_id,
        author_id: allComments[entityKey].result[commentIndex].author_id,
        creator_id: allComments[entityKey].result[commentIndex].creator_id,
        comment: comment.comment,
        like_count: like_count,
        is_replied: 1,
        parent_comment_id: comment.parent_comment_id,
        entity_type: 'story',
        _id: {
          $oid: comment._id.$oid,
        },
        comment_creator_name: userInfo.fullname,
        comment_creator_image: userInfo.image_url,
      },
    })
  }

  const likeOrUnlikeComment = (comment_id) => {
    let data = {
      uid: uid,
      entity_id: comment_id,
      entity_type: 'comment',
      action: commentLiked ? 'unlike' : 'like',
      status: 'active',
    }
    post('/user_api/user_action.update', {
      data,
    })
      .then(() => {
        let oldComment = JSON.parse(JSON.stringify(allComments))
        oldComment[entityKey].result[commentIndex].comments[index]['is_liked'] =
          !commentLiked
        let like_count =
          oldComment[entityKey].result[commentIndex].comments[index][
            'like_count'
          ] || 0
        oldComment[entityKey].result[commentIndex].comments[index][
          'like_count'
        ] = commentLiked
          ? like_count > 0
            ? like_count - 1
            : like_count
          : like_count + 1
        updateLikeCount(
          oldComment[entityKey].result[commentIndex].comments[index][
            'like_count'
          ]
        )

        setCommentLiked(!commentLiked)
        onUpdateComments(oldComment)
      })
      .catch(() => {
        setSavedChanges(true)
      })
    toggleShowCommentOptions(false)
  }

  return (
    <>
      <GenericError show={errorPopup} onClose={() => toggleErrorPopup(false)} />
      {reply && (
        <ReplyContainer>
          {(moreReplies || addReply) && (
            <ExtendCommentLink>
              <LinkCommentReplyCurve />
            </ExtendCommentLink>
          )}
          <ProfileImageContainer>
            <ReplyProfileImage
              src={commentEdit ? userInfo.image_url : comment.user.image_url}
            />
            {/* <ReplyStarIconContainer> */}
            {uid === comment.comment_creator_uid &&
              userInfo.role &&
              userInfo.role.toLowerCase() === 'author' && <ReplyAuthorIcon />}
            {uid === comment.comment_creator_uid &&
              userInfo.role &&
              userInfo.role.toLowerCase() !== 'author' && <ReplyCreatorIcon />}
            {/* </ReplyStarIconContainer> */}
          </ProfileImageContainer>
          {commentEdit ? (
            !isMobile ? (
              <ReplyContainerDiv
                replyText={replyText}
                closeReplyEdit={closeReplyEdit}
                setReplyText={setReplyText}
                editReply={editReply}
              />
            ) : (
              <Popup
                show={commentEdit}
                close={closeReplyEdit}
                renderActions={
                  <>
                    <CancelButton onClick={() => closeReplyEdit()}>
                      Cancel
                    </CancelButton>
                    <ReplyButton onClick={() => editReply()}>Reply</ReplyButton>
                  </>
                }
              >
                <ReplyContainerDiv
                  replyText={replyText}
                  closeReplyEdit={closeReplyEdit}
                  setReplyText={setReplyText}
                  editReply={editReply}
                />
              </Popup>
            )
          ) : (
            <ReplyInfoContainer>
              <ReplyHeader>
                <ReplyInfo>
                  {comment.user && comment.user.fullname} &nbsp;{' '}
                  <strong>&middot;</strong>&nbsp;
                  {comment.comment_creation_time}
                </ReplyInfo>
              </ReplyHeader>
              <ReplyBody>
                <ReplyText>{comment.comment}</ReplyText>
              </ReplyBody>
              <ReplyFooter>
                <LikesDiv onClick={() => likeOrUnlikeComment(comment._id.$oid)}>
                  {comment.is_liked || commentLiked ? (
                    <LikeIcon />
                  ) : (
                    <LikeOutlinedSmall />
                  )}
                  <Likes>
                    {comment.like_count}{' '}
                    {comment.like_count > 1 ? 'Likes' : 'Like'}
                  </Likes>
                </LikesDiv>
                {!isAdminAccess && (
                  <ReplyDiv
                    ref={addReplyRef}
                    onClick={() => toggleAddReply(true)}
                  >
                    <ReplyIcon />
                    <Reply>Reply</Reply>
                  </ReplyDiv>
                )}
                <VellipContainer
                  ref={showActionsRef}
                  onClick={() => toggleShowAction(!showAction)}
                >
                  {!isMobile ? (
                    <>
                      <VellipIcon />
                      <CommentActionContainer showAction={showAction}>
                        {/* comment.comment_id === userInfo.uid */}
                        {uid === comment.comment_creator_uid && (
                          <CommentAction onClick={() => editComment()}>
                            <PencilIcon />
                            <ActionText>Edit</ActionText>
                          </CommentAction>
                        )}
                        <CommentAction
                          onClick={() => likeOrUnlikeComment(comment._id.$oid)}
                        >
                          {comment.is_liked || commentLiked ? (
                            <LikeBlackIcon />
                          ) : (
                            <LikeOutlinedIcon />
                          )}
                          <ActionText>
                            {comment.is_liked || commentLiked
                              ? 'Unlike'
                              : 'Like'}
                          </ActionText>
                        </CommentAction>
                        <CommentAction
                          onClick={() => toggleShowDeletePopup(true)}
                        >
                          <DustbinIcon />
                          <ActionText>Remove</ActionText>
                        </CommentAction>
                      </CommentActionContainer>
                    </>
                  ) : (
                    <>
                      <VellipIcon onClick={handleVellipClick} />
                      <Popup
                        className="bottom-sheet no-padding"
                        show={isCommentOptionsShown}
                        close={() => toggleShowCommentOptions(false)}
                      >
                        <ChapterOptionsBox showAction={showAction}>
                          {/* comment.comment_id === userInfo.uid */}
                          {uid === comment.comment_creator_uid && (
                            <Option onClick={() => editComment()}>
                              <PencilIcon />
                              <ActionText>Edit</ActionText>
                            </Option>
                          )}
                          <Option
                            onClick={() =>
                              likeOrUnlikeComment(comment._id.$oid)
                            }
                          >
                            {comment.is_liked || commentLiked ? (
                              <LikeBlackIcon />
                            ) : (
                              <LikeOutlinedIcon />
                            )}
                            <ActionText>
                              {comment.is_liked || commentLiked
                                ? 'Unlike'
                                : 'Like'}
                            </ActionText>
                          </Option>
                          <Option
                            onClick={() => {
                              toggleShowDeletePopup(true)
                              toggleShowCommentOptions(false)
                            }}
                          >
                            <DustbinIcon />
                            <ActionText>Remove</ActionText>
                          </Option>
                        </ChapterOptionsBox>
                      </Popup>
                    </>
                  )}
                </VellipContainer>
                {savedChanges && (
                  <AlertSnack
                    text={`Failed to ${
                      comment.is_liked || commentLiked ? 'Unlike' : 'Like'
                    }`}
                    close={() => setSavedChanges(false)}
                  />
                )}
              </ReplyFooter>
            </ReplyInfoContainer>
          )}
        </ReplyContainer>
      )}
      <NewReply
        show={addReply && !commentEdit}
        close={() => toggleAddReply(false)}
        onClick={(e) => e.stopPropagation()}
        index={index + 1}
        moreReplies={moreReplies}
        createReply={createReply}
        entityKey={entityKey}
        commentIndex={commentIndex}
        userInfo={userInfo}
      />
      <Popup
        show={showDeletePopup}
        close={() => toggleShowDeletePopup(false)}
        renderActions={
          <>
            <PopupCancelButton
              className="action-button"
              type="button"
              onClick={() => toggleShowDeletePopup(false)}
            >
              Cancel
            </PopupCancelButton>
            &nbsp;
            <PopupDeleteCommentButton
              className="action-button"
              type="button"
              onClick={removeComment}
            >
              Delete Comment
            </PopupDeleteCommentButton>
          </>
        }
      >
        <DustbinBlack />
        <DeleteHeader>Delete this Comment Permanently?</DeleteHeader>
        <br />
        <DeleteMessage>
          Are you sure you want to permanently delete this comment, after
          deleting you won't be able to recover it.
        </DeleteMessage>
      </Popup>
    </>
  )
}

export default ReplyCard
