import React from 'react'

const FileIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="#0D1536" fillOpacity=".65" d="M17.503 13.987c-.2-.162-.43-.27-.687-.351-.258-.054-.544-.081-.888-.081h-1.317c-.229 0-.372.054-.487.135-.085.081-.143.243-.143.46v3.484c0 .163 0 .27.058.379.028.108.085.162.171.216.086.054.23.081.43.081h1.317c.23 0 .43-.027.63-.054.172-.027.344-.081.515-.135.172-.081.315-.162.43-.27.172-.162.315-.325.43-.514.114-.189.2-.405.257-.648.057-.243.086-.486.086-.784 0-.837-.258-1.486-.802-1.918zm-.744 3.35c-.086.054-.172.108-.258.135-.114.027-.2.054-.286.054H15.04v-3.242h.687c.315 0 .573.027.802.108.229.054.4.216.544.46.143.243.229.594.229 1.053-.029.703-.2 1.162-.544 1.432z"/>
    <path fill="#0D1536" fillOpacity=".65" d="M26.61 7.99l-.03-.028v-.027l-5.44-5.133-.029-.027s-.028 0-.028-.027c0 0-.029 0-.029-.027-.086-.054-.172-.054-.286-.054H7.28c-.516 0-1.003.189-1.346.513l-.029.027c-.344.324-.573.81-.573 1.297v22.992c0 .513.23.973.573 1.297.344.324.86.54 1.375.54h17.438c.545 0 1.031-.216 1.375-.54.344-.324.573-.81.573-1.297V8.26c0-.108-.029-.189-.058-.27zm-5.298-3.378l3.236 3.053H22.17c-.23 0-.458-.081-.601-.243-.144-.135-.258-.351-.258-.568V4.612zm4.123 22.884c0 .19-.085.351-.229.486-.143.136-.315.216-.515.216H7.28c-.2 0-.373-.08-.516-.189-.143-.135-.2-.297-.2-.486V4.477c0-.19.086-.351.229-.487l.028-.027c.144-.108.315-.189.487-.189h12.829v3.053c0 .54.229 1.027.63 1.405.372.352.916.568 1.489.568h3.178v18.696z"/>
    <path fill="#0D1536" fillOpacity=".65" d="M12.664 13.933c-.143-.135-.315-.216-.544-.27-.2-.054-.515-.081-.888-.081H9.915c-.23 0-.4.054-.487.135-.114.081-.143.243-.143.46v3.674c0 .189.057.324.143.432.086.108.23.135.372.135.144 0 .258-.054.373-.162.086-.108.143-.243.143-.432v-1.351h.945c.63 0 1.088-.135 1.403-.378.315-.244.487-.622.487-1.108 0-.216-.029-.432-.115-.622-.114-.162-.229-.324-.372-.432zm-.687 1.513c-.086.108-.2.19-.373.243-.171.054-.372.081-.6.081h-.688v-1.459h.687c.458 0 .745.081.916.216.144.136.2.298.2.514-.028.162-.056.297-.142.405zM19.823 13.528c-.172.027-.287.054-.372.108-.086.027-.172.108-.2.189-.058.081-.058.19-.058.351v3.648c0 .189.057.35.143.432.086.108.23.135.372.135.144 0 .258-.054.373-.135.085-.108.143-.243.143-.432v-1.567h1.69c.142 0 .257-.027.343-.108.086-.055.114-.163.114-.27 0-.109-.028-.19-.114-.27-.086-.055-.2-.109-.344-.109h-1.69v-1.216h2.005c.172 0 .287-.027.372-.108.086-.08.115-.162.115-.27 0-.108-.029-.189-.114-.27-.086-.081-.201-.108-.373-.108h-2.405z"/>
  </svg>
)

export default FileIcon
