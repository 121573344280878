import React from 'react'
import PrivatePage from '../PrivatePage'
import SubHeader from '../WithSubHeaderBG'
import ProfileContainer from './Profile.container'
import ProfileUI from './Profile.UI'

const Profile = props => {
  return (
    <PrivatePage>
      <SubHeader title="Edit Profile" padding="20" >
        <ProfileContainer {...props}>
          <ProfileUI />
        </ProfileContainer>
      </SubHeader>
    </PrivatePage>
  )
}

export default Profile
