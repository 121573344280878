import React from 'react'
import {
  EmptyContainer,
  EmptyHeader,
  EmptyNovelIcon,
} from '../../pages/Shows/Novels/styles'

export default function EmptyState() {
  return (
    <EmptyContainer>
      <EmptyNovelIcon />
      <br />
      <EmptyHeader>Nothing to see here</EmptyHeader>
    </EmptyContainer>
  )
}
