import React, { useState, useEffect } from "react";
import NewConcept from "./newConcept";
import Loader from "../../../../components/Loader";
import { get } from "../../../../lib/api";

const EditConcept = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [book, setBook] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    const bookDetailsApi = `/content_api/concept_notes/${id}/`
    get(bookDetailsApi).then((response) => {
      const { result } = response;
      setBook(result);
      setLoading(false);
    });
  };

  return !isLoading && book ? (
    <NewConcept {...props} mode="edit" data={book} />
  ) : (
    <Loader />
  );
};

export default EditConcept;
