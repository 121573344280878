import React, { useState, useRef } from 'react'
import { PasswordEye, PasswordClose, InputLabel, InputLabelDiv, InputDiv, Input, RequiredField } from './styled'

const FloatingInput = ({ hasError, label, infoTitle, asTextArea, rowCount, onChange, size = 'lg',password,required, ...props}) => {
  const [isInputActive, setIsInputActive] = useState(!!props.value); // this is needed for floating placeholder
  const inputRef = useRef()
  const [togglePassword,setTogglePassword]=useState(false);
  const handleInputChange = (text) => {
    if (text !== '') {
      setIsInputActive(true);
    } else {
      setIsInputActive(false);
    }
    onChange(text)
  }

  const handleDivClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }
  const showPassword=(id)=>{
    setTogglePassword(!togglePassword);
    const x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
     }
  }

  return (
    <InputDiv
      isTextArea={asTextArea}
      active={isInputActive}
      error={hasError}
      isLarge={size === 'lg'}
      onClick={handleDivClick}
    >
      <InputLabelDiv isTextArea={asTextArea}>
        <InputLabel htmlFor={props.name}>
          {label || 'Enter Detail'}{' '}
          {required && <RequiredField>*</RequiredField>}
        </InputLabel>
        {/* {infoTitle && <InfoIcon title={infoTitle}/>} */}
      </InputLabelDiv>
      <Input
        type="text"
        ref={inputRef}
        onChange={(e) => handleInputChange(e.target.value)}
        {...(asTextArea && {as: 'textarea', rows: rowCount || 5})}
        {...props}
        id={password && `${label}password`}
      />
      {password && !togglePassword && (
        <PasswordEye onClick={() => showPassword(`${label}password`)} />
      )}
      {password && togglePassword && (
        <PasswordClose onClick={() => showPassword(`${label}password`)} />
      )}
    </InputDiv>
  )
}
export default FloatingInput
