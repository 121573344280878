import { SwapItemInMainLayout } from "./actionTypes";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SwapItemInMainLayout: {
      const {componentId} = action.payload;
      return {
        ...state,
        componentId: componentId
      };
    }
    default :
    return {
        ...state
    }
  }
}