import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from "@material-ui/core/styles"

const MyTooltip = withStyles({
  tooltipArrow: {
    padding: '12px 16px',
    borderRadius: '4px',
    boxShadow: '0 20px 40px 0 rgba(24, 27, 37, 0.16)',
    border: 'solid 1px var(--Grey900)',
    backgroundColor: 'var(--Dove)',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.8',
    letterSpacing: 'normal',
    color: 'var(--Text500)',
  },
  arrow: {
    color: 'var(--Dove)',
  }
})(Tooltip)

export default MyTooltip
