import React from 'react'

function EyeClose(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M19.2042 3.99897C18.9113 3.70607 18.4364 3.70607 18.1435 3.99897L5.06202 17.0804C4.76913 17.3733 4.76913 17.8482 5.06202 18.1411C5.35492 18.434 5.82979 18.434 6.12268 18.1411L19.2042 5.05963C19.4971 4.76673 19.4971 4.29186 19.2042 3.99897Z" fill="#0D1536"/>
        <path d="M11.9999 3.99964C13.1843 3.99964 14.3178 4.24687 15.3756 4.6455L14.2312 5.78993C13.5126 5.57414 12.7651 5.44791 11.9999 5.44791C7.58489 5.44791 3.77721 9.63003 2.64915 10.9991C3.18994 11.6541 4.34648 12.9546 5.88871 14.1324L4.84778 15.1733C2.64608 13.4581 1.2451 11.5711 1.13961 11.4269C0.953465 11.1722 0.953465 10.8268 1.13961 10.5722C1.33593 10.304 6.01902 3.99964 11.9999 3.99964Z" fill="#0D1536"/>
        <path d="M12 6.65481C12.4156 6.65481 12.8178 6.71328 13.1987 6.82243L11.9168 8.10429C10.3815 8.14764 9.14098 9.38772 9.09993 10.9212L7.81807 12.2031C7.70754 11.8208 7.6483 11.417 7.6483 10.9997C7.6483 8.60395 9.6005 6.65481 12 6.65481Z" fill="#0D1536"/>
        <path d="M11.1282 15.2569L12.539 13.8461C13.7088 13.6257 14.6328 12.702 14.8517 11.5334L16.2625 10.1226C16.3209 10.4059 16.3516 10.6993 16.3516 10.9997C16.3516 13.3954 14.3994 15.3445 12 15.3445C11.7015 15.3445 11.4099 15.3143 11.1282 15.2569Z" fill="#0D1536"/>
        <path d="M21.3507 11.0001C20.8368 10.3778 19.7677 9.1733 18.3402 8.04485L19.3792 7.00584C21.4497 8.67059 22.7583 10.4332 22.8602 10.5724C23.0466 10.8268 23.0466 11.1725 22.8602 11.4271C22.6637 11.6953 17.9808 17.9996 11.9999 17.9996C10.9264 17.9996 9.89478 17.7966 8.92336 17.4617L10.0867 16.2984C10.7069 16.4595 11.3469 16.5514 11.9999 16.5514C16.4149 16.5514 20.2226 12.3692 21.3507 11.0001Z" fill="#0D1536"/>
        </svg>
    )
}

export default EyeClose