import React from 'react'
import PrivatePage from '../../components/PrivatePage'
import UnderConstructionArt from '../../components/Images/UnderConstruction'
import styled from 'styled-components'

const Container = styled.div`
  margin: 45px auto 40px auto;
  max-width: 550px;
`
const ComingSoonText = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--Text700);
  margin-top: 40px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 28px;
  }
`

const UnderConstructionIcon = styled(UnderConstructionArt)`
  margin-top: 5%;
  @media (max-width: 600px) {
    width: 90vw;
    height: 202px;
  }
`

const ComingSoon = (props) => {
  return (
    <PrivatePage>
      <Container>
        <UnderConstructionIcon />
        <ComingSoonText>Coming Soon!</ComingSoonText>
      </Container>
    </PrivatePage>
  )
}

export default ComingSoon
