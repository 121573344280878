import React, { useState } from 'react'
import styled from 'styled-components'

import LoginIcon from './create_novel_illustration.svg'
import { FloatingInput } from '../../../../components/FormComponents'
import { post } from '.././../../../lib/api'
import Loader from '../../../../components/Loader'
import { withRouter } from 'react-router-dom'
import { menuItemObj } from '../../../../components/PrivatePage'
import { Text, Button } from '../../../../designSystem'
import WriterPopup from '../WriterPopup'

const Wrapper = styled.div`
  background-color: var(--Grey300);
  padding: 40px;
  min-height: 100vh;
  
  @media (max-width: 600px) {
    padding: 0;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  background-size: cover;
`
const MainDiv = styled.div`
  display: flex;
  margin: auto;
  width: 900px;
  min-height: 512px;

  @media (max-width: 600px) {
    width: 100%;
  }
`
const LeftDiv = styled.div`
  background-image: linear-gradient(to bottom, #335973, #044673);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`
const RightDiv = styled.div`
  width: 50%;
  padding: 64px 48px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
    padding: 24px 16px;
  }
`
const IconHolder = styled.div`
`
const LoginHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const SubText = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 24px;
`
const LoginButton = styled.button `
  outline: none;
  border: none;
  padding: 14px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  opacity: ${({ disabled }) => disabled ? 0.3 : 1};
`
const CreateBookButton = styled(LoginButton)`
  text-transform: inherit;
  margin-top: 8px;
`
const CreateNew = ({history}) => {
  const [bookName, updateBookName] = useState('')
  const [isBookCreateProcessing, setIsBookCreateProcessing] = useState(false)
  const [apiError, setApiError] = useState({
    type: '',
    message: ''
  })

  const handleBookNameInputChange = text => {
    updateBookName(text);
  }

  const handleBookCreate = () => {
    if (!bookName) return
    if (isBookCreateProcessing) return
    setIsBookCreateProcessing(true)
    post('/content_api/book.create_book?is_novel=0', { data: {
      book_title: bookName,
      is_novel: 1
    } }).then(response => {
      const { book_id } = response.results
      history.push(`${menuItemObj.novels}/add-chapter/${book_id}?proceed_to_details=1`)
    })
  }

  return (
    <Wrapper>
      <Container>
          <MainDiv>
            <LeftDiv>
              <IconHolder>
                <img src={LoginIcon} />
              </IconHolder>
            </LeftDiv>
            <WriterPopup />
            <RightDiv>
                <Text variant="titleMedium" mb={16}>
                  Create a New Novel
                </Text>

                <Text variant="titleSecondary" fontWeight="r" mb={24}>
                  Your Novel will be a part of writer benefit program
                </Text>

                <FloatingInput
                  type="text"
                  name="book-name"
                  value={bookName}
                  label="Book Name"
                  onChange={text => handleBookNameInputChange(text)}
                />

                {/* <CreateBookButton onClick={handleBookCreate} disabled={!bookName}>
                    {isBookCreateProcessing ? <Loader size='sm' invertColor noPadding /> : 'Create Book and Go to First Chapter'}
                </CreateBookButton> */}
                <Button sizeVariant="L" variant="fillPrimary" mt={8} isLoading={isBookCreateProcessing} width="100%" onClick={handleBookCreate} disabled={!bookName}>
                  Create Book and Go to First Chapter
                </Button>
            </RightDiv>
          </MainDiv>
        </Container>
    </Wrapper>
  )
}

export default withRouter(CreateNew)
