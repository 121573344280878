import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { get } from '../../../lib/api'
import styled from 'styled-components'
import CrossIcon from '../../Images/CrossIcon'
import { useDidUpdate } from '../../../lib/customHooks'
import Tooltip from '../../Tooltip'

const Label = styled.label`
  margin-right: 6px;
`
const LabelDiv = styled.div`
  display: flex;
  align-items: center;
  font-size:  12px;
  top: 6px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
  position: absolute;
`

const Container = styled.div``

const SelectionField = styled.div`
  position: relative;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  // padding: 6px 8px;
  padding: 24px 8px 14px 8px;


  &:focus-within {
    background-color: var(--Dove);
    border: solid 2px var(--Azure500);
    // padding: 5px 7px;
    padding: 23px 7px 13px 7px;
  }

`
const SelectedValue = styled.div`
  border-radius: 4px;
  background-color: var(--Fjord800);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--TextDark700);
  padding-right: 6px;
  margin-right: 8px;
  margin-top: 3px;
`
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`
const Option = styled.div`
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);

  &:hover {
    background-color: var(--Grey500);
  }
`
const BoldSpan = styled.span`
  color: var(--Text900);
`
const StyledCrossIcon = styled(CrossIcon)`
  // align-self: center;
  fill: var(--TextDark700);
  height: 9px;
  width: 9px;
`
const CrossIconSpan = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: auto;
  text-align: center;
  margin-left: 8px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
const Input = styled.input`
  flex-grow: 1;
  border: none;
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
  margin-top: 3px;
  min-height: 36px;

  &:focus {
    outline: none;
  }

  &:focus {
    background-color: var(--Dove);
  }
`
const DropDownHolder = styled.div`
  position: relative;
`

const SearchableInput = ({
  label,
  infoTitle,
  placeholder,
  options: optionsFromProp,
  nameKey = 'name',
  selectKey = 'name',
  apiUrl,
  tooltip,
  ...props
}) => {
  // const [type, changeType] = useState(type)
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [field, meta] = useField(props)
  const { value = '', onBlur, onChange } = field
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(optionsFromProp || [])
  const newHandleChange = (name) => {
    // do something with this val
    toggleDropDownOpen(false)
    setInputValue('')
    const currentValues = value.split(',').map(each => each.trim())
    if (currentValues.indexOf(name) > -1) return
    currentValues.push(name)
    const newValues = currentValues.filter(Boolean).join(',')
    onChange({ target: { name: props.name, value: newValues } })
  }

  const handleInputChange = e => {
    toggleDropDownOpen(true)
    setInputValue(e.target.value)
  }

  const handleClick = () => {
    toggleDropDownOpen(false)
    setInputValue('')
  }

  const handleRemoveValue = v => {
    const currentValues = value.split(',').map(each => each.trim()).filter(Boolean)
    const newValues = currentValues.filter(val => val !== v).join(',')
    onChange({ target: { name: props.name, value: newValues } })
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  useDidUpdate(() => {
    onChange({ target: { name: props.name, value: '' } })
    setOptions([])
  }, [apiUrl])

  useEffect(() => {
    if (!apiUrl) return
    get(apiUrl).then(response => {
      setOptions(response.result.tags || response.result || [])
    })
  }, [apiUrl])

  const getOptions = () => {
    const currentValues = value.split(',').map(each => each.trim()).filter(Boolean)
    return options.filter(option => currentValues.indexOf(option[selectKey]) === -1).map(tag => {
      if (inputValue === '') return tag
      const index = tag[nameKey].toLowerCase().indexOf(inputValue.toLowerCase())
      if (index > -1) {
        const strArr = tag[nameKey].split('')
        if (index === strArr.length -1) return tag
        let firstArr = strArr.slice(0, index)
        let secondArr = strArr.slice(index, index + inputValue.length)
        let thirdArr = strArr.slice(index + inputValue.length)
        let substr = [firstArr.join(''), secondArr.join(''), thirdArr.join('')]
        return {...tag, substr}
      } else {
        return null
      }
    }).filter(Boolean)
  }
  const selectedValues = value.split(',').map(each => each.trim()).filter(Boolean)
  const selectedValuesObjArr = selectedValues.map(v => options.find(opt => opt[selectKey] === v)).filter(Boolean)

  const Field = (
    <SelectionField>
      <LabelDiv>
        <Label>
          {label || ''}
        </Label>
        {/* {infoTitle && <InfoIcon title={infoTitle}/>} */}
      </LabelDiv>
      {selectedValuesObjArr.map((valObj) => (
        <SelectedValue key={valObj[nameKey]}>
          {valObj[nameKey]}
          <CrossIconSpan onClick={() => handleRemoveValue(valObj[selectKey])}>
            <StyledCrossIcon />
          </CrossIconSpan>
        </SelectedValue>
      ))}
      <Input
        onChange={e => handleInputChange(e)}
        value={inputValue}
        placeholder={selectedValues.length ? '' : placeholder}
      />
    </SelectionField>
  )
  return (
    <Container>
      {/* <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan>
        <InfoIcon title={infoTitle}/>
      </LabelContainer> */}
      {
        tooltip ? (
          <Tooltip title={tooltip.title}  placement={tooltip.placement} arrow>
            {Field}
          </Tooltip>
        ) : Field
      }
      <DropDownHolder>
      <OptionsDropDown isOpen={isOpen} >
        {getOptions().map((obj) => (
          <Option key={obj[nameKey]} onClick={() => newHandleChange(obj[selectKey])}>
            {!obj.substr && obj[nameKey]}
            {obj.substr && obj.substr.map((str, i) => {
                if (i === 1) return <BoldSpan key={i}>{str}</BoldSpan>
                return str
              })
            }
          </Option>
        ))}
      </OptionsDropDown>
      </DropDownHolder>
    </Container>
  )
}

export default SearchableInput
