
import React from 'react'

export default function Index() {
  return (
    
<svg width="296" height="106" viewBox="0 0 296 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.05 0h111.154a5 5 0 0 1 5 5v87.429a5 5 0 0 0 5 5h112.305a5 5 0 0 0 3.807-1.76l33.638-39.508a4.999 4.999 0 0 0-.062-6.554L243.711 6.441C241.5 3.94 243.275 0 246.613 0c1.097 0 2.143.465 2.877 1.28l43.586 48.335a5 5 0 0 1 .105 6.576l-40.614 48.037a5 5 0 0 1-3.818 1.772H11.011c-4.295 0-6.59-5.057-3.765-8.29l36.32-41.553a5 5 0 0 0-.01-6.591L7.295 8.3C4.455 5.07 6.75 0 11.05 0z" fill="#fff"/>
    <path d="m69.456 66.75.003.001c1.632 1.172 3.577 1.749 5.81 1.749 2.233 0 4.173-.576 5.797-1.749 1.628-1.17 2.857-2.851 3.7-5.016.854-2.173 1.272-4.772 1.272-7.781 0-2.992-.418-5.573-1.271-7.73-.845-2.155-2.074-3.83-3.703-4.99-1.631-1.162-3.57-1.734-5.795-1.734-2.224 0-4.164.572-5.796 1.734v.001c-1.62 1.16-2.849 2.834-3.701 4.989-.845 2.148-1.263 4.729-1.272 7.728v.002c0 3 .413 5.593 1.257 7.767l.001.002c.853 2.163 2.08 3.849 3.698 5.026zm9.324-5.647v.001c-.891 1.542-2.055 2.252-3.511 2.252-.987 0-1.824-.324-2.537-.98-.72-.67-1.308-1.69-1.734-3.11-.416-1.413-.626-3.179-.617-5.31v-.001c.008-3.195.491-5.541 1.39-7.099.893-1.553 2.053-2.265 3.498-2.265.975 0 1.808.327 2.523.991l.002.002c.72.662 1.307 1.675 1.734 3.086.424 1.404.642 3.16.642 5.284 0 3.221-.483 5.584-1.39 7.15zm10.582 6.38v.5h19.273V62.88h-11.11l3.95-4.086c1.731-1.7 3.085-3.156 4.056-4.368.982-1.215 1.687-2.345 2.094-3.389a8.687 8.687 0 0 0 .607-3.173c0-1.605-.401-3.05-1.21-4.32-.799-1.265-1.92-2.255-3.348-2.971-1.435-.72-3.084-1.073-4.936-1.073-1.828 0-3.47.362-4.913 1.1l-.001.001c-1.433.739-2.566 1.786-3.387 3.138-.827 1.36-1.231 2.95-1.231 4.747v.5H94.8v-.5c0-.862.165-1.576.474-2.16a3.294 3.294 0 0 1 1.336-1.347l.005-.002c.573-.323 1.254-.492 2.058-.492.783 0 1.464.154 2.054.45a3.33 3.33 0 0 1 1.373 1.246l.002.002c.323.516.496 1.152.496 1.932 0 .683-.133 1.32-.399 1.915-.272.61-.686 1.255-1.254 1.935a43.595 43.595 0 0 1-2.188 2.394l-9.248 9.223-.147.146V67.484z" fill="url(#8ihf6b1e4a)" stroke="url(#j9ny8g33pb)"/>
    <path d="M183.679 65.631a4.118 4.118 0 0 1 .778-1.553l.295-.33 19.408-19.433a6.29 6.29 0 0 1 8.521-.35l.362.333.351.38a6.297 6.297 0 0 1 .001 8.17l-.336.363-19.391 19.42a4.137 4.137 0 0 1-1.504.96l-.438.134-8.701 2.137.024.094-.024-.094a1.311 1.311 0 0 1-1.619-1.37l.04-.228 2.233-8.633zm22.635-19.737h-.001l-.004.003-.287.259-.004.004-19.41 19.434a1.502 1.502 0 0 0-.302.438l-.003.007-.085.233-.002.01-1.645 6.358-.042.162.163-.04 6.411-1.576c.118-.029.233-.073.341-.13l.005-.002.146-.087.007-.004.006-.005.197-.163h.001l.006-.007 19.39-19.42-.071-.07.071.07a3.689 3.689 0 0 0-.009-5.22l-.07.071.07-.071a3.666 3.666 0 0 0-4.879-.255zm-15.426 7.444-2.022 2.023-1.84-4.694-.025-.064H175.592l-.025.064-2.933 7.54a1.307 1.307 0 0 1-1.466.811l-.237-.068a1.312 1.312 0 0 1-.809-1.457l.068-.236 9.87-25.322a1.309 1.309 0 0 1 1.801-.7c.217.106.4.27.529.474l.114.228 8.384 21.4zm-9.567-17.076-.13-.025-4.527 11.614-.053.136H185.98l-.054-.136-4.549-11.61-.056.021z" fill="url(#8vzr922nhc)" stroke="#000" strokeWidth=".2"/>
    <defs>
        <linearGradient id="8ihf6b1e4a" x1="108.135" y1="40" x2="82.393" y2="81.178" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="j9ny8g33pb" x1="108.135" y1="40" x2="82.393" y2="81.178" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="8vzr922nhc" x1="215" y1="31" x2="169.011" y2="78.753" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0068E1"/>
            <stop offset="1" stopColor="#B0D5FF"/>
        </linearGradient>
    </defs>
</svg>

  )
}
