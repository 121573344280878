import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ClampLines from "react-clamp-lines";
import SubHeader from "../../../../components/WithSubHeaderBG";
import Loader from "../../../../components/Loader";
import { Box, Text, Image } from "../../../../designSystem";
import { get, del } from "../../../../lib/api";
import { conceptStatusText } from "../constants";
import FileIcon from "../../../../components/Images/FileIcon";
import ConceptIcon from "../../../../components/Images/ConceptIcon";
import Vellip from "../../../../components/Images/Vellip";
import { menuItemObj } from "../../../../components/PrivatePage";
import { Link } from "react-router-dom";
import MessagePopup from "../../../../components/MessagePopup";
import {
  Container,
  StartText,
  DescriptionText,
} from "../List/emptyState/styles";

const VellipIcon = styled(Vellip)`
  display: block;
`;
const VellipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border-radius: 4px;

  &: hover {
    background-color: #ebecf1;
  }
`;
const ConceptOptionsBox = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  width: 224px;
`;
const EditConceptDiv = styled.div`
  background-color: #ffffff;
  cursor: pointer;
  &: hover {
    background-color: #f0f1f5;
  }
`;
const EditButton = styled(Link)`
  border: none;
  outline: none;
  background: none;
  ${'' /* background-color: #ffffff; */}
  padding: 16px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);

  
`;
const EditConceptSpan = styled.span`
  margin-left: 8px;
`;

const DeleteConceptDiv = styled.div`
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  padding: 16px 24px;

  &: hover {
    background-color: #f0f1f5;
  }
`;
const DeleteConceptSpan = styled.span`
  margin-left: 8px;
`;
const ConceptDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.text500};
  margin-bottom: 12px;
  margin-top: 8px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text.text500};
    outline: none;
  }
`;

const FileLabelDownloadable = styled.a.attrs((props) => ({
  href: props.href,
  download: props.download,
}))`
  border: 1px solid #e0e2eb;
  padding: 10px;
  color: #000;
  margin-top: 5px;
  display: inline-block;
`;

const FileIconStyled = styled(FileIcon)`
  vertical-align: middle;
  margin-right: 5px;
`;

const Tags = styled(Text)`
  display: inline-block;
  font-size: 14px;
`;

const ConceptImageDiv = styled.div`
  width: 125px;
  height: 125px;
  margin-right: 16px;
  border-radius: 10px;
  background-color: var(--Grey900);
  // box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`;

const ConceptDetail = (props) => {
  const {
    location: { search },
    history,
  } = props;
  //const qParams = queryString.parse(search);
  const {
    match: {
      params: { id },
    },
  } = props;
  const conceptOptionsRef = useRef();
  const [isConceptOptionsShown, toggleShowBookOptions] = useState(false);
  const [conceptDetails, setConceptDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [apiError, toggleApiError] = useState(false);
  const {
    category_details,
    concept_id,
    description,
    file_url,
    is_disabled,
    language,
    status,
    tags,
    title,
  } = conceptDetails || {};

  const statusHeading = conceptStatusText[status]?.text;
  const statusSubtext = conceptStatusText[status]?.subtext;
  const [popupDetails, setPopupDetails] = useState({
    show: false,
    confirmWarning: false,
    confirmText: "",
    messageTitle: "",
    messageBody: "",
    selectOptionBeforeSave: false,
    selectOptions: [],
    onConfirm: () => null,
  });
  const handleFileClick = (e) => {};
  const onDeleteClick = () => {
    setPopupDetails({
      show: true,
      confirmWarning: true,
      confirmText: "Delete Concept",
      messageTitle: "Delete Concept?",
      messageBody: "Are you sure you want to delete the Concept",
      selectOptionBeforeSave: false,
      selectOptions: [],
      onConfirm: deleteConcept,
    });
  };
  const deleteConcept = () => {
    del(`/content_api/concept_notes/${id}/`, {
      data: {
        concept_id: id,
      },
    }).then(() => {
      setPopupDetails({ ...popupDetails, show: false });
      history.push(`${menuItemObj.audiobooks}?tab=concepts`);
    });
  };

  useEffect(() => {
    window.addEventListener("click", handleFileClick);
    return () => window.removeEventListener("click", handleFileClick);
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    setLoading(true);
    const conceptDetailsApi = `/content_api/concept_notes/${id}/`
    get(conceptDetailsApi)
      .then((response) => {
        const { result } = response;
        setConceptDetails(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: "200px" }}>
        <Loader />
      </div>
    );
  }

  const isMobile = window.screen.width < 600;
  return (
    <>
      <MessagePopup
        show={popupDetails.show}
        onClose={() => setPopupDetails({ ...popupDetails, show: false })}
        onConfirm={popupDetails.onConfirm}
        confirmWarning={popupDetails.confirmWarning}
        cancelText="Cancel"
        confirmText={popupDetails.confirmText}
        messageTitle={popupDetails.messageTitle}
        takeFeedback={popupDetails.takeFeedback}
        messageBody={popupDetails.messageBody}
        selectOptionBeforeSave={popupDetails.selectOptionBeforeSave}
        selectOptions={popupDetails.selectOptions}
      />
      {conceptDetails && (
        <SubHeader title="Concept Details" showBackButton>
          <Box
            p={[16, 24]}
            mb={8}
            width={["100vw", "100%"]}
            position="relative"
            bg="surface.foreground"
          >
            <Box
              variant="flex"
              mb={24}
              justifyContent="flex-start"
              flexDirection={["column", "row"]}
            >
              {!isMobile && (
                <ConceptImageDiv>
                  <ConceptIcon width={[60, 144]} height={[60, 144]} mr={24} />
                </ConceptImageDiv>
              )}
              {isMobile && (
                <Box variant="flex" justifyContent="flex-start">
                  <ConceptImageDiv>
                    <ConceptIcon
                      width={[60, 144]}
                      height={[60, 144]}
                      src="data:,"
                      alt="Alternative Text"
                      mr={8}
                    />
                  </ConceptImageDiv>
                  <Text variant="title">{title}</Text>
                </Box>
              )}
              <Box mt={[8, 0]}>
                {!isMobile && <Text variant="title">{title}</Text>}
                {description && (
                  <ConceptDescription>
                    <ClampLines
                      lines={2}
                      text={description || "No description available"}
                      id="recording-show-description"
                    />
                  </ConceptDescription>
                )}
                <Text variant="smallTag">
                  {category_details.topic_name} | {language}
                </Text>
                {/* <Text variant="smallTag">
                  Tags: <Tags>{tags.toString()}</Tags>
                </Text> */}

                <FileLabelDownloadable href={file_url} download={file_url}>
                  <FileIconStyled></FileIconStyled>
                  {file_url}
                </FileLabelDownloadable>
              </Box>
              <VellipContainer ref={conceptOptionsRef}>
                <VellipIcon
                  onClick={() => toggleShowBookOptions(!isConceptOptionsShown)}
                />
                <ConceptOptionsBox show={isConceptOptionsShown}>
                  <EditConceptDiv>
                    <EditButton
                      to={`${menuItemObj.audiobooks}/edit-concept/${id}?process=concept-detail`}
                    >
                      <EditConceptSpan>Edit</EditConceptSpan>
                    </EditButton>
                  </EditConceptDiv>
                  <DeleteConceptDiv onClick={onDeleteClick}>
                    <DeleteConceptSpan>Delete</DeleteConceptSpan>
                  </DeleteConceptDiv>
                </ConceptOptionsBox>
              </VellipContainer>
            </Box>
          </Box>
        </SubHeader>
      )}
      {status && (
        <SubHeader title="Concept Details" showBackButton>
          <Container width={["100vw", "100%"]}>
            <StartText>{statusHeading}</StartText>
            <DescriptionText> {statusSubtext} </DescriptionText>
          </Container>
        </SubHeader>
      )}
    </>
  );
};

export default ConceptDetail;
