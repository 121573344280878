import React from 'react'
import { FieldArray } from "formik";
import { Box } from "../../designSystem";
import { CheckboxInput, DropDownSelect, FloatingInput, Upload } from "../FormikInputs";
import { AddMore,InvalidDetailsDiv, AddMoreMainDiv, DetailDiv, DetailLabel, DetailsContainer, DetailsHeadingContainer, DetailsInputContainer, DownloadIcon, DownloadLink, OtherDiv, RequiredField, SampleRow, SectionHeading, StyledCrossIcon, VoaProfessionalDetailsDiv, VoaVoiceSampleDetailsDiv, VoiceSampleDiv } from "./styles";
import { FileUploadComponent } from '../../pages/Home/WBPSteps/step1'

const getCapitalizeVoiceRange = (v) => v.replace("_"," ").split(" ").map(w => w && w[0].toUpperCase() + w.slice(1)).join(" ")

const demoScriptURL= (_languages) => {
  _languages = _languages.toLowerCase()
  if(_languages === "tamil"){
    return "https://d31b0xt3oaqqjh.cloudfront.net/e58bac6ced93703c1dfb8132616213ab054bc642/dc26741a6f747887995f04d2c23f003cdca61a8e.pdf"
  }else if(_languages === "telugu"){
    return "https://d31b0xt3oaqqjh.cloudfront.net/e58bac6ced93703c1dfb8132616213ab054bc642/9d9e5e40f07086ab1a08916fa19136b6cefca4f4.pdf"
  }else if(_languages === "kannada"){
    return "https://d31b0xt3oaqqjh.cloudfront.net/e58bac6ced93703c1dfb8132616213ab054bc642/b66f2a47253ebe119f156f57207015f2171e4b25.pdf"
  }else if(_languages === "english"){
    return "https://d31b0xt3oaqqjh.cloudfront.net/cms-documents/318351de22d3f81daddcaba473479e3c8f2fc945.pdf"
  }
  return "https://d31b0xt3oaqqjh.cloudfront.net/e58bac6ced93703c1dfb8132616213ab054bc642/d23593ba443546f010a607e25cdc7a1234daaa96.pdf"
}

const VoaDetails = ({values, uid, submitCount, errors, languages, isOverseasUser}) => {
  if(values.workExperience.other === '') values.otherExperience = ""
    return ( 
        <>
          <VoaProfessionalDetailsDiv>
            <SectionHeading>Professional Details</SectionHeading>
            <DetailLabel>Your past experience in the industries</DetailLabel>
            <DetailDiv>
              <CheckboxInput
                name="workExperience.movie"
                label="Movie/TV Dubbing"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.animations"
                label="Animations"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.adCommercials"
                label="Ad Commercials"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.audioBook"
                label="Audiobook"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.podcast"
                label="Podcast"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.radio"
                label="Radio"
              ></CheckboxInput>
              <CheckboxInput
                name="workExperience.other"
                label="Others"
              ></CheckboxInput>
            </DetailDiv>
            {values.workExperience.other !== "" && (
              <OtherDiv>
                <FloatingInput
                  type="text"
                  name="otherExperience"
                  label="Other Experiences"
                  size="lg"
                  border
                  checked={values.workExperience.other !== ''}
                />
              </OtherDiv>
            )}
            <DetailLabel>Voice Range</DetailLabel>
            <DetailDiv>
              <CheckboxInput
                name="voiceRange.child(5-12)"
                label="Child (5-12)"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceRange.teen(13-17)"
                label="Teen (13-17)"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceRange.young_adult(18-25)"
                label="Young Adult (18-25)"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceRange.adult(26-35)"
                label="Adult (26-35)"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceRange.middle_aged(36-54)"
                label="Middle Aged (36-54)"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceRange.senior(55+)"
                label="Senior (55+)"
              ></CheckboxInput>
            </DetailDiv>
            <DetailLabel>Voice Texture</DetailLabel>
            <DetailDiv>
              <CheckboxInput
                name="voiceTexture.soft/soothing"
                label="Soft/Soothing"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceTexture.sharp/seductive"
                label="Sharp/Seductive"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceTexture.husky/grainy"
                label="Husky/Grainy"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceTexture.heavy/deep"
                label="Heavy/Deep"
              ></CheckboxInput>
              <CheckboxInput
                name="voiceTexture.dry"
                label="Dry"
              ></CheckboxInput>
            </DetailDiv>
            <Box variant="flex" flexDirection={['column', 'row']}>
              {
                !isOverseasUser && <Box width={["100%", "49%"]}>
                  <DetailLabel>Additional Language/Accents:</DetailLabel>
                  <FloatingInput
                    type="text"
                    name="other_accents"
                    label="(Regional languages like Bhojpuri, Tamil)"
                    hasError={submitCount > 0 && errors['additional_language']}
                  />
                </Box>
              }
              <Box width={["100%", "49%"]}>
                <DetailLabel>Any major professional detail you want to highlight:</DetailLabel>
                <FloatingInput
                  type="text"
                  name="major_professional_details"
                  hasError={submitCount > 0 && errors['additional_language']}
                />
              </Box>
            </Box>
          </VoaProfessionalDetailsDiv>
          <VoaVoiceSampleDetailsDiv>
            <SectionHeading>Voice Sample</SectionHeading>
            <DetailsContainer>
              <DetailsHeadingContainer>
                <DetailLabel>Genre<RequiredField>*</RequiredField></DetailLabel>
                <DetailLabel>Info About The Sample</DetailLabel>
                <DetailLabel>Voice Range</DetailLabel>
                <DetailLabel>Upload Audio File</DetailLabel>
                <DetailLabel>Download</DetailLabel>
              </DetailsHeadingContainer>  
              <DetailsInputContainer>
              <FieldArray name="voice_samples" render={voiceSamples => {
                  const voiceSampleArray = voiceSamples?.form?.values?.voice_samples
                  let seen = new Set();
                  let hasDuplicatesGenre = voiceSampleArray.some(voiceSample => seen.size === seen.add(voiceSample.genre).size)
                  const nonDeletedVoiceSample = voiceSamples?.form?.values?.voice_samples.filter(v => v.delete !== true)
                  const lastVoiceSample = nonDeletedVoiceSample[nonDeletedVoiceSample.length - 1]
                  const isAddMoreBtnEnabled = Boolean(nonDeletedVoiceSample.length === 0 || (lastVoiceSample?.genre && lastVoiceSample?.voice_range && lastVoiceSample?.audio_url))
                  const defaultGenreSelectOptions = [{ id: 1, name: 'Romantic' },{ id: 2, name: 'Horror' },{ id: 3, name: 'Comedy' },{ id: 4, name: 'Drama' },{ id: 5, name: 'Fantasy' },{ id: 6, name: 'Action' },{ id: 7, name: 'Thriller' }]
                  let defaultVoiceRangeSelectOptions = [{ id: 1, name: 'Child(5-12)' },{ id: 2, name: 'Teen(13-17)' },{ id: 3, name: 'Young Adult(18-25)' },{id: 4, name: 'Adult(26-35)'},{ id: 5, name: 'Middle Aged(36-54)' },{ id: 6, name: 'Senior(55+)' }]
                  const voiceRangeExists = Object.values(values?.voiceRange).filter(v => v !== "").length > 0
                  if(voiceRangeExists) defaultVoiceRangeSelectOptions = Object.keys(values.voiceRange).map((k,index) => ({name: k,id:index+1})).filter(k => values.voiceRange[k.name] !== "").map(v=> ({...v,name: getCapitalizeVoiceRange(v.name)}))                
                  let voiceRangeFilterOptions = []
                  if(hasDuplicatesGenre) {
                    const filteredValue = voiceSampleArray.filter(voiceSample => voiceSample.genre === lastVoiceSample?.genre)
                    voiceRangeFilterOptions = defaultVoiceRangeSelectOptions.filter(value =>  filteredValue.findIndex(val => val.voice_range === value.name) === -1 ? true : false)
                  }
                  const genreSelectOptions = defaultGenreSelectOptions
                  const voiceRangeSelectOptions = hasDuplicatesGenre ? voiceRangeFilterOptions : defaultVoiceRangeSelectOptions 
                return (
                      <>
                        {values.voice_samples.map((elm, index) => (
                          !elm.delete ? 
                          (<VoiceSampleDiv key={index}>
                              <SampleRow>
                                <DropDownSelect
                                  name={`voice_samples[${index}].genre`}
                                  selectByName
                                  placeholder="Genre"
                                  selectOptions={genreSelectOptions}
                                  idKey="id"
                                  nameKey="name"
                                  hasError={submitCount > 0 && errors['voice_samples']?.length > 0 && errors['voice_samples'][index]?.genre}      
                                />
                                <FloatingInput
                                  type="text"
                                  name={`voice_samples[${index}].desc`}
                                  label="Tone, Tempo or others"
                                  size="lg"
                                  maxlength="150"
                                  hasError={submitCount > 0 && errors['voice_samples']?.length > 0 && errors['voice_samples'][index]?.desc}      
                                />
                                <DropDownSelect
                                  name={`voice_samples[${index}].voice_range`}
                                  selectByName
                                  placeholder="Voice Range"
                                  selectOptions={voiceRangeSelectOptions}
                                  idKey="id"
                                  nameKey="name"
                                  hasError={submitCount > 0 && errors['voice_samples']?.length > 0  && errors['voice_samples'][index]?.voice_range}      
                                  />
                              <Upload
                                label=""
                                name={`voice_samples[${index}].audio_url`}
                                maxFileSize={52428800}
                                hideInputOnValue={true}
                                showProgress={true}
                                placeholder="Upload File"
                                renderComp={FileUploadComponent}
                                fileTypeTags="media"
                                accept="audio/mpeg, audio/mp3, audio/ogg, audio/wav"
                                extraParams={{title: 'Voice-Sample'}}
                                hasError={submitCount > 0 && errors['voice_samples']?.length > 0  && errors['voice_samples'][index]?.audio_url}      
                              />
                              <DownloadLink href={isOverseasUser ? 'https://d31b0xt3oaqqjh.cloudfront.net/cms-documents/318351de22d3f81daddcaba473479e3c8f2fc945.pdf' : demoScriptURL(languages)} download>
                                <DownloadIcon /> Demo Script
                              </DownloadLink>
                            </SampleRow>
                            {index >= 0 && <StyledCrossIcon onClick={() => voiceSamples.remove(index)}/>}
                          </VoiceSampleDiv>):""
                        ))}
                        {voiceRangeSelectOptions.length === 0 && <InvalidDetailsDiv>Now further no more combination will be possible with {lastVoiceSample?.genre} Genre.</InvalidDetailsDiv>}
                        <AddMoreMainDiv>
                          <AddMore type="button" disabled={!isAddMoreBtnEnabled} onClick={() => voiceSamples.push({ genre: "", desc: "", voice_range: "", audio_url: "", creator_id: uid})}>Add More</AddMore>
                        </AddMoreMainDiv>
                      </>
                    )}}
                  />
              </DetailsInputContainer>       
            </DetailsContainer>
            
          </VoaVoiceSampleDetailsDiv></>
     );
}
 
export default VoaDetails;