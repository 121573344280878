import React from 'react'

const Mic = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.49855 11.9935C7.77483 12.2698 8.09686 12.4869 8.45575 12.6386C8.82794 12.796 9.22258 12.8759 9.62875 12.8759H10C10.4062 12.8759 10.8009 12.7961 11.173 12.6386C11.5319 12.4869 11.8539 12.2698 12.1302 11.9935C12.4065 11.7172 12.6235 11.3952 12.7753 11.0363C12.9327 10.6641 13.0126 10.2695 13.0126 9.86334V4.67925C13.0126 4.27309 12.9328 3.87844 12.7753 3.50625C12.6235 3.14737 12.4065 2.82534 12.1302 2.54906C11.8539 2.27278 11.5319 2.05573 11.173 1.90393C10.8009 1.74649 10.4062 1.66669 10 1.66669H9.62877C9.22261 1.66669 8.82796 1.74649 8.45578 1.90393C8.09689 2.05573 7.77486 2.27278 7.49858 2.54906C7.2223 2.82534 7.00526 3.14737 6.85345 3.50625C6.69605 3.87844 6.61621 4.27309 6.61621 4.67925V9.86334C6.61621 10.2695 6.69602 10.6642 6.85345 11.0363C7.00523 11.3952 7.22227 11.7173 7.49855 11.9935Z" fill="#0D1536" fillOpacity="0.4"/>
    <path d="M12.0132 14.7578C12.6858 14.4733 13.2898 14.0662 13.8083 13.5477C14.3268 13.0292 14.734 12.4252 15.0184 11.7526C15.3131 11.0559 15.4626 10.3162 15.4626 9.55396V9.19026C15.4626 8.85559 15.1913 8.58429 14.8566 8.58429C14.5219 8.58429 14.2506 8.85559 14.2506 9.19026V9.55396C14.2506 10.7389 13.7892 11.8529 12.9513 12.6907C12.1135 13.5285 10.9995 13.99 9.81459 13.99C8.62968 13.99 7.5157 13.5285 6.67786 12.6907C5.84002 11.8529 5.37858 10.7389 5.37858 9.55396V9.19026C5.37858 8.85559 5.10727 8.58429 4.7726 8.58429C4.43793 8.58429 4.16663 8.85559 4.16663 9.19026V9.55396C4.16663 10.3162 4.31603 11.0559 4.61073 11.7526C4.89521 12.4252 5.30236 13.0292 5.82087 13.5477C6.33937 14.0662 6.94331 14.4733 7.61596 14.7578C8.1264 14.9737 8.66004 15.1113 9.20861 15.1696V17.0931H7.82833C7.49366 17.0931 7.22236 17.3644 7.22236 17.6991C7.22236 18.0337 7.49366 18.305 7.82833 18.305H9.81456H11.8008C12.1355 18.305 12.4068 18.0337 12.4068 17.6991C12.4068 17.3644 12.1355 17.0931 11.8008 17.0931H10.4205V15.1696C10.9692 15.1113 11.5028 14.9737 12.0132 14.7578Z" fill="#0D1536" fillOpacity="0.4"/>
  </svg>
)

export default Mic
