import React from 'react'
import styled from 'styled-components'
import { Popup } from '../Popup'
import Author from '../Images/AuthorIcon'
import Creator from '../Images/CreatorIcon'
import { numFormatter } from '../../lib/utils'

import './override.css'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageDiv = styled.div`
  margin-top: 70px;
  width: 128px;
  height: 128px;
  border-radius: 50%;
`
const Image = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 50%;
`
const NameDiv = styled.div`
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.8);
  text-align: center;
`
const RoleDiv = styled.div`
  margin-top: 8px;
  border-radius: 28px;
  background-color: ${({ author }) => author ? '#068AE2' : '#00b268'};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 6px;
`
const AuthorIcon = styled(Author)`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`
const VOIcon = styled(Creator)`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`
const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`
const StatDiv = styled.div`
  margin-right: 58px;
  &:last-child {
    margin-right: 0px;
  }
`
const Count = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.8);
  margin-bottom: 2px;
`
const StatText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`

const EmailDiv = styled.div`
  margin-top: 24px;
  align-self: flex-start;
  padding-left: 80px;
`
const ContactLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`
const Contact = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`
const PhoneDiv = styled.div`
  margin-top: 24px;
  align-self: flex-start;
  padding-left: 80px;
`
const CreatorID = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  margin-top: 8px;
  margin-bottom: 8px;
`

const ProfileInfo = ({userInfo: {fullname, image_url, creator_alias, account_type = '', phone_number, email, stats: {subscriber_count, subscription_count, total_plays} = {}} = {}, ...props}) => {
  const isVoiceArtist = !account_type.includes('Author')
  return (
    <Popup
      {...props}
      className="right-drawer-user-profile"
    >
      <Container>
        <ImageDiv><Image src={image_url}></Image></ImageDiv>
        {fullname && <NameDiv>{fullname}</NameDiv>}
        {creator_alias && <CreatorID>{creator_alias}</CreatorID>}
        {account_type && (
          <RoleDiv author={isVoiceArtist ? '' : 1}>
            {isVoiceArtist ? <VOIcon/> : <AuthorIcon/>}
            {isVoiceArtist ? 'Voice Over Artist' : 'Author'}
          </RoleDiv>
        )}
        <Stats>
          {!!total_plays && 
            <StatDiv>
              <Count>{numFormatter(total_plays)}</Count>
              <StatText>Plays</StatText>
            </StatDiv>}
          {!!subscription_count && 
            <StatDiv>
              <Count>{numFormatter(subscription_count)}</Count>
              <StatText>Follows</StatText>
            </StatDiv>}
          {!!subscriber_count && 
            <StatDiv>
              <Count>{numFormatter(subscriber_count)}</Count>
              <StatText>Followers</StatText>
            </StatDiv>}
        </Stats>
        {/* <EmailDiv>
          <ContactLabel>Email Address</ContactLabel>
          <Contact>{email}</Contact>
        </EmailDiv> */}
        <PhoneDiv>
          <ContactLabel>Mobile number</ContactLabel>
          <Contact>{phone_number}</Contact>
        </PhoneDiv>
      </Container>
    </Popup>
  )
}

export default ProfileInfo
