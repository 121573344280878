import React from 'react'

const CreatorIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
    <circle cx="7" cy="7" r="6.5" fill="#00B268" stroke="#fff"/>
    <path fill="#fff" d="M5.874 7.897c.124.124.269.222.43.29.168.071.345.107.528.107H7c.183 0 .36-.036.528-.107.162-.068.307-.166.43-.29.125-.124.223-.27.291-.43.071-.168.107-.346.107-.529V4.606c0-.183-.036-.36-.107-.528-.068-.162-.166-.307-.29-.43-.124-.125-.27-.223-.43-.291-.168-.071-.346-.107-.529-.107h-.167c-.183 0-.36.036-.528.107-.161.068-.306.166-.43.29-.125.124-.222.27-.29.43-.072.168-.107.346-.107.529v2.332c0 .183.035.36.106.528.069.162.166.307.29.431z"/>
    <path fill="#fff" d="M7.906 9.141c.303-.128.574-.31.808-.544.233-.233.416-.505.544-.808.133-.313.2-.646.2-.99v-.163c0-.15-.122-.273-.273-.273-.15 0-.272.122-.272.273V6.8c0 .533-.208 1.034-.585 1.411s-.878.585-1.411.585c-.534 0-1.035-.208-1.412-.585S4.92 7.333 4.92 6.8v-.164c0-.15-.122-.273-.272-.273-.15 0-.273.122-.273.273V6.8c0 .343.067.676.2.989.128.303.311.575.544.808.234.233.506.416.808.544.23.098.47.16.717.186v.865h-.621c-.15 0-.273.122-.273.273 0 .15.122.273.273.273H7.81c.151 0 .273-.122.273-.273 0-.15-.122-.273-.273-.273h-.62v-.865c.246-.027.486-.088.716-.186z"/>
  </svg>
)

export default CreatorIcon
