import React from 'react'

const HowItWorksStep2SVG = (props) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5747 8.90952V12.2093V15.5439V18.9826H21V8.90952H17.5747ZM19.2963 11.4625C18.8838 11.4625 18.5431 11.1326 18.5431 10.7331C18.5431 10.3336 18.8838 10.0037 19.2963 10.0037C19.7088 10.0037 20.0495 10.3336 20.0495 10.7331C20.0316 11.1326 19.7088 11.4625 19.2963 11.4625Z"
        fill="currentColor"
      />
      <path
        d="M7.22705 6.94708C7.22705 8.70119 8.71552 10.1427 10.5268 10.1427C12.3381 10.1427 13.8265 8.71856 13.8265 6.94708C13.8265 5.19296 12.356 3.75146 10.5268 3.75146C8.69759 3.75146 7.22705 5.19296 7.22705 6.94708ZM10.5268 4.46353C11.9435 4.46353 13.0913 5.57505 13.0913 6.94708C13.0913 8.31911 11.9435 9.43062 10.5268 9.43062C9.11006 9.43062 7.96232 8.31911 7.96232 6.94708C7.96232 5.57505 9.11006 4.46353 10.5268 4.46353Z"
        fill="currentColor"
      />
      <path
        d="M4.19646 7.78061C5.46973 7.78061 6.50987 6.7733 6.50987 5.54021C6.50987 4.30712 5.46973 3.2998 4.19646 3.2998C2.92319 3.2998 1.88306 4.30712 1.88306 5.54021C1.88306 6.7733 2.92319 7.78061 4.19646 7.78061ZM4.19646 4.01187C5.0752 4.01187 5.7746 4.70657 5.7746 5.54021C5.7746 6.39121 5.05727 7.06854 4.19646 7.06854C3.31773 7.06854 2.61833 6.37384 2.61833 5.54021C2.61833 4.70657 3.31773 4.01187 4.19646 4.01187Z"
        fill="currentColor"
      />
      <path
        d="M7.85486 3.50823C8.85913 3.50823 9.66613 2.72669 9.66613 1.75411C9.66613 0.781536 8.85913 0 7.85486 0C6.85059 0 6.06152 0.781536 6.06152 1.75411C6.06152 2.72669 6.86853 3.50823 7.85486 3.50823ZM7.85486 0.712066C8.44666 0.712066 8.93087 1.18099 8.93087 1.75411C8.93087 2.32724 8.44666 2.79616 7.85486 2.79616C7.26306 2.79616 6.77886 2.32724 6.77886 1.75411C6.77886 1.18099 7.26306 0.712066 7.85486 0.712066Z"
        fill="currentColor"
      />
      <path
        d="M9.54059 11.202L8.89499 10.8199C8.42872 10.542 7.87279 10.4899 7.35272 10.6636L7.04785 10.7852L8.55426 11.5841C8.87706 11.4625 9.19986 11.3236 9.54059 11.202Z"
        fill="currentColor"
      />
      <path
        d="M3.65847 10.8546L3.2998 11.011L6.52782 12.4525C6.74302 12.3482 7.10168 12.1919 7.53209 12.0183L5.43388 10.8894C4.89588 10.6115 4.21441 10.5941 3.65847 10.8546Z"
        fill="currentColor"
      />
      <path
        d="M16.1221 12.1572C16.1042 12.1398 14.1853 11.0283 12.8582 11.0978C11.7284 11.1499 8.10589 12.6956 6.74295 13.3035C6.63535 13.3729 6.29462 13.6508 6.02562 14.0155C5.90008 14.1892 5.97182 14.3455 6.00769 14.415C6.07942 14.5365 6.22289 14.6581 6.42015 14.6408L12.1588 14.2413C12.392 14.2239 12.6072 14.3976 12.6251 14.6408C12.643 14.8665 12.4637 15.0749 12.2126 15.0923L6.47395 15.4918C5.95389 15.5265 5.48762 15.2834 5.23655 14.8492C4.98548 14.4323 5.02135 13.9287 5.29035 13.5466C5.41588 13.3556 5.57728 13.1993 5.70282 13.0603L2.08027 11.4451L1.79334 11.2888C1.20154 11.0631 0.520068 11.1499 0 11.5146L6.79675 16.6554C7.62169 17.3153 8.73356 17.5411 9.73783 17.2285L16.1221 15.596C16.158 15.5786 16.1939 15.5786 16.2297 15.5786H16.6781V12.2267H16.3373C16.2656 12.2267 16.1939 12.2093 16.1221 12.1572Z"
        fill="currentColor"
      />
      <path
        d="M11.3218 7.05965C11.3002 7.26374 11.2222 7.42857 11.088 7.55416C10.9537 7.67975 10.7523 7.76295 10.4838 7.80377L11.4861 9H11L10 7.81083L10.0463 7.46703H10.2593C10.3349 7.46703 10.4012 7.46389 10.4583 7.45761C10.5154 7.45133 10.5648 7.4427 10.6065 7.43171C10.6497 7.41915 10.6867 7.40424 10.7176 7.38697C10.7485 7.36813 10.777 7.34694 10.8032 7.32339C10.8711 7.25746 10.9151 7.16954 10.9352 7.05965H10V6.71586H10.9306C10.9182 6.65463 10.8981 6.60283 10.8704 6.56044C10.8441 6.51648 10.8094 6.47724 10.7662 6.4427C10.723 6.40816 10.6659 6.38305 10.5949 6.36735C10.5255 6.35165 10.4321 6.3438 10.3148 6.3438H10V6H12V6.3438H11.1111C11.1651 6.38305 11.2099 6.43485 11.2454 6.49922C11.2809 6.56358 11.3056 6.63579 11.3194 6.71586H12V7.05965H11.3218Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HowItWorksStep2SVG
