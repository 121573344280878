import React, { useState, useEffect } from 'react'
import AddEditForm from '../New'
import Loader from '../../../../../components/Loader'
import { get } from '../../../../../lib/api'
import { isAdminAccess } from '../../../../../config'

const EditBookDetails = (props) => {
  const { match: { params: { id }}} = props
  const [book, setBook] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    const bookDetailsApi =
      '/content_api/book.book_details'
    get(bookDetailsApi, { 
      params: { book_id: id, view: isAdminAccess ? 'cms' : 'dashboard', info_level: 'max', is_novel: 0 } 
    })
      .then((response) => {
        const { results } = response
        setBook(results)
        setLoading(false)
      })
  }

  return !isLoading && book ? (
    <AddEditForm {...props} mode='edit' data={book} />
  ) : <Loader/>
}

export default EditBookDetails
