import React from 'react'
import HomeContainer from './Home.container'
import HomeUI from './Home.UI'
import PrivatePage from '../../components/PrivatePage'

const Home = props => {
  return (
    <PrivatePage>
      <HomeContainer {...props}>
        <HomeUI />
      </HomeContainer>
    </PrivatePage>
  )
}

export default Home
