import React, { useRef,useState,useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { getWordCountFromText } from '../../../lib/utils'

const EditorHolder = styled.div`
  padding: 16px;
  background-color: #fff;
  height: 410px;
`

const MyEditor = ({ content, updateWordCount, handleMouseUp,setEditorToolbarChange=()=>{},handleClick}) => {
  const editorRef = useRef(null);
  const [editorState,setEditorState] = useState(true)

  const handleEditorChange = () => {
    const editor = window.tinymce.activeEditor
    // const wordCount = editor.plugins.wordcount.getCount()
    // updateWordCount(wordCount)
    if (!editor) return
    const plainText = editor.getContent({ format: 'text' })
    setEditorState(true)
    const count = getWordCountFromText(plainText)
    updateWordCount(count);
  }

  useEffect(()=>{
    if(editorState){
      setEditorToolbarChange(isContentChanged(content))
      setEditorState(false)
    }
 },[editorState])

  const delayedHandleEditorChange = debounce(handleEditorChange, 1000);


  return (
      <>
        {/* <EditorHolder> */}
          <Editor
            id="editor"
            initialValue={content}
            // onInit={(evt, editor) => {editorRef.current = editor;console.log(editor);}}
            onInit={(evt, editor) => {
              editorRef.current = editor;
              editor.focus()
              handleEditorChange()
            }}
            onEditorChange={delayedHandleEditorChange}
            onMouseUp={handleMouseUp}
            onClick={handleClick}
            selector="textarea"
            apiKey="zbbryxi5kv4tdgq6s2l2wso42dv4oj0uwrfh8ls403w4j09o"
            init={{
              // inline: true,
              height: 500,
              skin: "naked",
              // toolbar: [
              //   'undo redo | styleselect | bold italic | link image',
              //   'alignleft aligncenter alignright'
              // ],
              // toolbar1: 'undo redo | styleselect | bold italic | link image',
              // toolbar2: 'alignleft aligncenter alignright',
              menubar: false,
              branding: false,
              resize: false,
              statusbar: false,
              toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
              // toolbar: true,
              plugins: [
                'advlist autolink lists',
                'charmap print preview anchor help',
                'searchreplace visualblocks code save',
                'insertdatetime media table paste wordcount',
              ]
            }}
            name="body"
            // onEditorChange={this.handleEditorChange}
          />
        {/* </EditorHolder> */}
      </>
  )
}

export default MyEditor

export const getContent = () => {
  if (!window.tinymce) return ''
  const editor = window.tinymce.activeEditor
  if (!editor) return ''
  return editor.getContent();
}

export const isContentChanged = () => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  return editor.isDirty()
}
export const resetIsContentChanged = (status=false) => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  editor.setDirty(status)
}
export const isRedoEmpty = () => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  if (!editor.undoManager) return
  return !editor.undoManager.hasRedo()
}
export const isUndoEmpty = () => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  if (!editor.undoManager) return
  return !editor.undoManager.hasUndo()
}
export const handleUndo = () => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  if (!editor.undoManager) return
  editor.undoManager.undo()
}
export const handleRedo = () => {
  if (!window.tinymce) return
  const editor = window.tinymce.activeEditor
  if (!editor) return
  if (!editor.undoManager) return
  editor.undoManager.redo()
}
