import React from 'react'

export default function Index() {
  return (
    <svg
      width="174"
      height="115"
      viewBox="0 0 174 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M139.008 100.238c0-.02.109-2.247-.973-2.76l.077-.074c.495.172 1.043.705 1.263 1.456.164.559.04.681.04.702l-.407.675v.001z"
        fill="url(#s5rb0qyqqa)"
      />
      <path
        d="M131.843 94.465s.37 2.868 2.126 3.707c1.756.839 4.234-.667 4.234-.667s-.37-2.868-2.126-3.707c-1.756-.84-4.234.667-4.234.667zM138.823 104.417c-.02-.112-.41-2.735.587-5.659.959-2.809.427-5.191.423-5.215h.705c.018.102.067 2.44-.684 5.643-.676 2.883.586 5.231.317 5.231h-1.349.001z"
        fill="#FA7167"
      />
      <path
        d="M140.304 96.671c.004-.015.34-1.937 1.246-2.155-.404.052-.919.341-1.206.878-.214.4-.139.512-.143.528l.104.75h-.001z"
        fill="#FA7167"
      />
      <path
        d="M143.209 96.87c-1.354-.273-2.109-2.178-2.109-2.178s1.439-1.465 2.792-1.194c1.354.273 2.109 2.179 2.109 2.179s-1.439 1.464-2.792 1.193z"
        fill="#FCB8B3"
      />
      <path
        d="M132.696 90.658a7.046 7.046 0 0 1 2.023-9.786c3.273-2.145 7.672-1.244 9.827 2.015a7.046 7.046 0 0 1-2.023 9.786c-3.273 2.145-7.672 1.244-9.827-2.015z"
        fill="#C8E1FF"
      />
      <path
        d="M142.222 92.22a6.447 6.447 0 0 1-4.859.928 6.425 6.425 0 0 1-4.095-2.764 6.38 6.38 0 0 1-.933-4.839 6.392 6.392 0 0 1 2.777-4.078 6.444 6.444 0 0 1 4.858-.928 6.42 6.42 0 0 1 4.095 2.764 6.372 6.372 0 0 1 .933 4.838 6.395 6.395 0 0 1-2.776 4.078zm-6.898-10.431c-2.799 1.836-3.576 5.597-1.733 8.384 1.844 2.787 5.621 3.561 8.42 1.727 2.799-1.836 3.576-5.598 1.733-8.385s-5.62-3.56-8.419-1.726h-.001z"
        fill="#94BDEE"
      />
      <path
        d="m145.913 103.734-.001.006-.141 2.165-.525 8.035h-9.819l-.578-7.926-.159-2.184-.007-.096h11.23z"
        fill="url(#ppq5jg1bnb)"
      />
      <path
        d="m145.912 103.74-.142 2.165c-.739-.257-2.795-.874-5.474-.874-3.381 0-5.449.983-5.449.983l-.159-2.184 11.223-.09h.001z"
        fill="#94BDEE"
      />
      <path
        d="M146.254 104.879h-11.927l-.137-1.381h12.216l-.152 1.381z"
        fill="#206CC6"
      />
      <path
        d="M145.397 114.368h-10.181l-.116-.481h10.426l-.129.481z"
        fill="#B4C9F7"
      />
      <path
        d="m32.68 65.07.916.74s2.506-.895 3.072 2.07c0 0 .15 1.265-.654 1.267-.804.002-.472-.138-1.61-.105-1.139.033-2.083-.564-2.25-1.484l-.544-.78 1.07-1.71v.001z"
        fill="#EA8E50"
      />
      <path
        d="M32.575 66.66c.731-.76.673-1.323.673-1.323s-8.465-6.901-9.323-7.464c-.585-.383-6.818-5.886-9.732-9.24-1.445-1.665-3.236-1.02-5.284-.434l-1.687.482c-2.037.581-2.566 3.418-.896 4.797 4.707 3.882 12.537 8.25 14.003 9.167 3.637 2.278 10.757 4.985 10.757 4.985s.864-.32 1.491-.972l-.002.001z"
        fill="#1E3D70"
      />
      <path
        d="M42.236 109.058s2.055 1.532 5.202.722c0 0 2.537-.575 3.095.685 0 0-3.892 1.97-4.877 2.507-.742.406-1.518 1.047-2.065 1.542a1.024 1.024 0 0 1-1.478-.114c-.172-.21-.367-.459-.582-.749-.6-.81-1.998-3.427-1.998-3.427l2.703-1.167v.001z"
        fill="#312808"
      />
      <path
        d="M18.937 54.992c-1.071-7.827 4.432-15.037 12.292-16.103 7.86-1.067 15.1 4.413 16.17 12.24 1.072 7.827-4.432 15.036-12.291 16.103-7.86 1.067-15.1-4.414-16.171-12.24z"
        fill="#206CC6"
      />
      <path
        d="M21.752 63.178c-5.61-5.586-5.61-14.642 0-20.227 5.609-5.586 14.703-5.586 20.312 0 5.609 5.585 5.609 14.641 0 20.227-5.61 5.586-14.703 5.586-20.313 0z"
        fill="#C8E1FF"
      />
      <path
        d="M41.161 62.45a12.918 12.918 0 0 0 3.834-9.215c0-3.482-1.362-6.753-3.834-9.215a13.027 13.027 0 0 0-9.253-3.817 13.027 13.027 0 0 0-9.253 3.817 12.917 12.917 0 0 0-3.833 9.215c0 3.481 1.361 6.753 3.833 9.214a13.027 13.027 0 0 0 9.253 3.817c3.496 0 6.782-1.355 9.253-3.817zm3.053-9.215c0 6.757-5.52 12.254-12.306 12.254-6.785 0-12.305-5.498-12.305-12.254 0-6.757 5.52-12.254 12.305-12.254s12.306 5.497 12.306 12.254z"
        fill="#94BDEE"
      />
      <path
        d="M43.44 108.279s-1.25 2.873-4.137 2.344c0 0-5.892-4.845-9.933-9.878-1.313-1.636-2.43-3.29-3.088-4.804-2.68-6.179-9.913-15.394-14.512-20.065l11.506-4.145s3.686 7.382 8.59 16.45c3.533 6.535 7.699 13.944 11.574 20.098z"
        fill="#585858"
      />
      <path
        d="m31.866 88.182-2.497 12.563c-1.312-1.636-2.43-3.29-3.087-4.804-2.68-6.179-9.913-15.394-14.512-20.065l11.506-4.145s3.685 7.382 8.59 16.45z"
        fill="#585858"
      />
      <path
        d="M22.46 110.724s-.125 2.291 2.665 2.597c0 0 1.595.008 1.598 1.466l-8.109.026s-.235-.588-.292-1.849c-.044-.967.942-3.497.942-3.497l3.196 1.256v.001z"
        fill="#312808"
      />
      <path
        d="M19.503 71.73s5.125 5.608 8.38 15.713c1.77 5.498-3.245 19.322-4.223 24.125 0 0-3.365.284-5.665-.602 0 0-.175-9.92 1.17-17.566.753-4.279-6.748-9.15-10.027-16.882-2.23-5.26.483-5.171 3.817-5.297l6.547.508z"
        fill="#585858"
      />
      <path
        d="M13.585 46.706c-2.852 1.604-8.69.124-8.69.124s2.371-2.7 1.294-7.047l2.24 1.133 2.405 1.216s.155.767.263 2.147c.028.354.053.752.07 1.185a7.498 7.498 0 0 1 2.42 1.241l-.002.001z"
        fill="#FFB07B"
      />
      <path
        d="M24.044 71.441c0 .5-.578.738-.7.763-1.151.21-4.363 1.453-7.256 1.739-2.437.254-4.699.013-7.184-.484-.33-.067-1.1-.273-1.156-.587-.069-.344-9.585-19.32-2.187-27.471.173-.192 1.633.153 2.969.468 1.234.292 2.364.13 3.375-.275l1.253.657c1.538.853 4.448 3.885 4.788 6.264.173 1.21.868 2.106 1.069 3.17.074.394.15.78.229 1.154.15.735.307 1.434.468 2.1.436 1.8.895 3.348 1.345 4.687.48 1.42.95 2.603 1.372 3.591.546 1.283 1.006 2.24 1.294 2.974.204.517.32.922.32 1.25z"
        fill="#312808"
      />
      <path
        d="M23.724 70.19C10.29 63.889 5.637 51.785 5.637 51.785s10.915 4.01 13.607 5.053c.151.735.307 1.434.468 2.1a55.66 55.66 0 0 0 1.345 4.687c.48 1.421.951 2.603 1.372 3.592.546 1.282 1.006 2.24 1.295 2.974z"
        fill="#312808"
      />
      <path
        d="m31.583 66.537 1.162.424s2.324-1.637 3.772 1.025c0 0 .524 1.163-.305 1.416-.829.252-.528.015-1.692.4-1.165.387-2.312.109-2.752-.72l-.788-.577.605-1.968h-.002z"
        fill="#FFB07B"
      />
      <path
        d="m32.539 68.173.625-1.62s-8.771-7.12-9.666-7.731c-.609-.417-6.673-4.93-9.629-8.447a6.203 6.203 0 0 0-6.014-2.076l-1.841.384c-2.224.464-2.946 3.267-1.221 4.74 4.857 4.147 13.05 8.956 14.578 9.957 3.792 2.483 12.18 5.964 12.18 5.964s.65-.296.988-1.172v.001z"
        fill="#312808"
      />
      <path
        d="M11.096 44.279c-2.804.156-4.628-2.149-4.628-2.149s1.268-.699 1.96-1.214l2.404 1.216s.156.768.264 2.147z"
        fill="#EA8E50"
      />
      <path
        d="M12.292 41.411s-4.25 3.037-6.108.373c-1.859-2.665-3.659-4.067-1.024-6.118 2.635-2.052 3.877-1.403 4.714-.664.837.739 3.475 5.552 2.418 6.41z"
        fill="#FFB07B"
      />
      <path
        d="M10.323 32.596a6.777 6.777 0 0 0-.495-1.744s-.267 1.1-2.285.948c-2.019-.152-5.219.197-6.286 2.488a4.877 4.877 0 0 0-.181.45c-.194-.508-.24-1.27-.24-1.27-.383.987 0 2.22 0 2.22l-.016.108c-.446.375-.82 1.542-.82 1.542.134-.325.463-.538.755-.668-.01 1.782.717 3.525 1.99 4.044 1.864.759 1.848.18 3.723 1.417 0 0 .503-.621.16-1.039-.343-.417-1.637-1.024-.914-1.63.724-.607.99.076 1.257-.342.267-.417-1.067-1.63.648-2.769 1.713-1.138 2.857-2.73 2.704-3.755z"
        fill="#585858"
      />
      <path
        d="M106.339 71.794c-.002-.06.328-6.772-2.929-8.316l.23-.22c1.492.519 3.145 2.122 3.807 4.386.492 1.683.118 2.052.12 2.116l-1.228 2.034z"
        fill="#FA7167"
      />
      <path
        d="M97.835 58.499s-.372 3.452 1.444 4.92c1.817 1.47 5.133.397 5.133.397s.372-3.452-1.444-4.92c-1.817-1.47-5.133-.397-5.133-.397zM105.783 84.386c-.062-.338-1.236-8.244 1.768-17.053 2.886-8.465 1.288-15.64 1.275-15.712h2.124c.055.307.202 7.351-2.059 17.004-2.036 8.686 1.766 15.76.956 15.76h-4.064z"
        fill="#FCB8B3"
      />
      <path
        d="M110.244 61.049c.01-.045 1.024-5.837 3.752-6.496-1.219.158-2.768 1.027-3.635 2.646-.644 1.203-.418 1.544-.43 1.592l.314 2.257-.001.001z"
        fill="#FCB8B3"
      />
      <path
        d="M99.328 45.688s-2.942-4.137-6.284-5.352c0 0 1.25 9.703-.578 9.823-1.828.12-.61-10.912-.61-10.912s-8.704-4.813-12.239-4.68c0 0 5.004 9.696 3.203 9.364-1.8-.332-7.479-10.18-7.479-10.18s-9.083-2.23-10.876 3.104c-1.793 5.334.429 8.182 1.769 8.6 1.341.42 10.078.815 9.784 1.931-.293 1.117-6.295 2.578-9.523 1.37 0 0 3.103 9.132 7.892 8.957 4.788-.174 6.435-3.023 9.396-3.077 2.961-.055 5.068.862 3.548 1.566-1.52.705-6.478-.118-8.978 1.759-2.5 1.877-4.5 3.006-.131 4.167 4.368 1.161 10.962-.15 14.091-2.227.01-.006 2.562-.208 4.572 11.56.775 4.538.18 11.268.18 11.268l1.621-.246c.9-13.192-3.881-24.158-3.881-24.158s8.111-5.972 4.522-12.64v.003z"
        fill="#FA7167"
      />
      <path
        d="M125.856 63.096s-9.774-4.089-6.275-11.529c0 0 2.946-4.657 6.497-6.17 0 0-.782 10.593 1.207 10.617 1.988.022.016-11.868.016-11.868s9.154-5.728 12.995-5.79c0 0-4.854 10.805-2.92 10.34 1.932-.465 7.507-11.475 7.507-11.475s9.718-2.95 11.977 2.729c2.259 5.678.019 8.896-1.41 9.428-1.429.532-10.879 1.474-10.495 2.666.383 1.192 6.978 2.427 10.407.928 0 0-2.826 10.083-8.029 10.174-5.202.092-7.155-2.9-10.37-2.786-3.214.116-5.445 1.232-3.755 1.907 1.69.675 7.017-.507 9.838 1.38 2.821 1.889 5.057 2.997.388 4.512-4.668 1.515-13.315 1.871-15.447-1.596l-2.132-3.467h.001z"
        fill="#FCB8B3"
      />
      <path
        d="M152.534 42.291s-24.791 11.2-26.678 20.804c0 0 .594 4.136-1.439 9.185l.209 1.831s1.988-6.528 3.361-7.547c0 0-6.675-7.406 24.547-24.273z"
        fill="#FA7167"
      />
      <path
        d="M124.418 72.28s-3.287 6.45-.194 13.5l.991-.389s-1.906-7.934-.29-12.215c1.617-4.28-.507-.895-.507-.895z"
        fill="#FA7167"
      />
      <path
        d="M88.593 18.8c4.005 4.246 1.921 12.675 1.921 12.675s-8.578 1.548-12.584-2.697c-4.005-4.245-1.922-12.675-1.922-12.675s8.578-1.547 12.585 2.698z"
        fill="#FC8C83"
      />
      <path
        d="M88.593 18.8c4.005 4.245 1.921 12.674 1.921 12.674l-14.506-15.37s8.578-1.548 12.585 2.697V18.8zM140.253 34.967c-2.091 2.412-6.814 1.763-6.814 1.763s-1.334-4.559.756-6.97c2.09-2.411 6.814-1.763 6.814-1.763s1.334 4.56-.756 6.97z"
        fill="#FCB8B3"
      />
      <path
        d="M115.547 56.18c-1.401.386-3.023-1.045-3.023-1.045s.65-2.059 2.052-2.446c1.401-.386 3.023 1.045 3.023 1.045s-.65 2.058-2.051 2.445h-.001z"
        fill="#FA7167"
      />
      <path
        d="M90.802 10.928c.96 2.268-1.11 5.303-1.11 5.303s-3.627-.642-4.587-2.91c-.96-2.268 1.11-5.303 1.11-5.303s3.627.642 4.588 2.91h-.001z"
        fill="#FCB8B3"
      />
      <path
        d="M125.205 45.152c8.347-8.312 8.347-21.789 0-30.101-8.347-8.313-21.881-8.313-30.228 0-8.347 8.312-8.347 21.789 0 30.101 8.347 8.313 21.881 8.313 30.228 0z"
        fill="#206CC6"
      />
      <path
        d="M123.354 45.385c8.348-8.313 8.348-21.79 0-30.102-8.347-8.312-21.88-8.312-30.228 0-8.347 8.313-8.347 21.79 0 30.102 8.348 8.312 21.881 8.312 30.228 0z"
        fill="#C8E1FF"
      />
      <path
        d="M110.705 49.825a19.406 19.406 0 0 1-14.375-3.923c-4.109-3.177-6.73-7.756-7.38-12.895-.65-5.14.75-10.223 3.94-14.315 3.19-4.092 7.788-6.702 12.949-7.35a19.406 19.406 0 0 1 14.375 3.923c4.109 3.177 6.73 7.756 7.38 12.896a19.206 19.206 0 0 1-3.939 14.315c-3.19 4.092-7.789 6.701-12.95 7.349zm-4.72-37.336c-10.019 1.257-17.144 10.395-15.882 20.372 1.262 9.977 10.439 17.072 20.458 15.816 10.018-1.257 17.143-10.395 15.882-20.372-1.262-9.977-10.439-17.072-20.457-15.816h-.001z"
        fill="#94BDEE"
      />
      <path
        d="m127.144 82.33-.002.017-.425 6.52-1.583 24.209H95.55l-1.742-23.877-.48-6.58-.02-.289h33.836z"
        fill="url(#ywauh5ncxc)"
      />
      <path
        d="m127.142 82.347-.426 6.52c-2.229-.774-8.422-2.63-16.491-2.63-10.19 0-16.418 2.961-16.418 2.961l-.48-6.58 33.814-.272h.001z"
        fill="#94BDEE"
      />
      <path
        d="M128.172 85.777H92.235l-.412-4.16h36.806l-.457 4.16zM125.589 114.37H94.915l-.351-1.45h31.414l-.389 1.45z"
        fill="#206CC6"
      />
      <path
        d="M72.527 100.881c-.027-.152-.556-3.708.796-7.67 1.299-3.808.58-7.036.574-7.068h.956c.024.138.09 3.306-.927 7.648-.916 3.907.794 7.089.43 7.089h-1.829v.001z"
        fill="#FCB8B3"
      />
      <path
        d="M74.534 90.384c.004-.02.46-2.625 1.687-2.921-.548.071-1.244.462-1.634 1.19-.29.541-.188.695-.193.717l.14 1.015z"
        fill="#FCB8B3"
      />
      <path
        d="M72.267 89.776s-1.2-1.942-2.665-2.583c0 0 .278 4.39-.545 4.392-.824 0 .043-4.915.043-4.915s-3.767-2.412-5.358-2.454c0 0 1.965 4.497 1.166 4.295-.8-.201-3.06-4.785-3.06-4.785s-4.014-1.262-4.973 1.08c-.96 2.342-.046 3.686.544 3.912.59.226 4.5.656 4.336 1.148-.165.493-2.901.977-4.314.34 0 0 1.128 4.189 3.281 4.249 2.155.06 2.977-1.172 4.308-1.11 1.33.06 2.25.532 1.547.805-.703.272-2.904-.24-4.08.53-1.177.77-2.108 1.22-.181 1.867 1.927.648 4.925.249 6.39-.593.004-.003 1.155-.02 1.715 5.321.216 2.059-.247 5.062-.247 5.062l.735-.064c.788-5.895-1.04-10.956-1.04-10.956s3.815-2.446 2.398-5.542z"
        fill="#FA7167"
      />
      <path
        d="M76.92 88.194c-.631.174-1.36-.47-1.36-.47s.292-.926.922-1.1c.63-.174 1.36.47 1.36.47s-.293.925-.923 1.1zM84.17 74.04c-2.201 1.436-2.182 5.341-2.182 5.341s3.586 1.579 5.788.142c2.201-1.434 2.182-5.34 2.182-5.34s-3.587-1.578-5.788-.142z"
        fill="#FCB8B3"
      />
      <path
        d="M84.17 74.04c-2.201 1.436-2.182 5.341-2.182 5.341l7.97-5.198s-3.587-1.578-5.788-.142zM84.037 70.367c-.66.891-.076 2.435-.076 2.435s1.654.1 2.314-.79c.66-.892.077-2.436.077-2.436s-1.654-.1-2.315.79v.001z"
        fill="#FA7167"
      />
      <path
        d="M64.926 77.66c-.663-5.246 3.07-10.034 8.338-10.695 5.269-.66 10.077 3.056 10.74 8.303.664 5.247-3.069 10.035-8.338 10.696-5.268.66-10.076-3.057-10.74-8.303z"
        fill="#206CC6"
      />
      <path
        d="M64.093 77.766c-.663-5.247 3.07-10.035 8.338-10.696 5.27-.66 10.077 3.057 10.741 8.304.663 5.246-3.07 10.034-8.338 10.695-5.269.66-10.077-3.056-10.74-8.303z"
        fill="#C8E1FF"
      />
      <path
        d="M74.74 85.336a8.729 8.729 0 0 1-6.465-1.764 8.658 8.658 0 0 1-3.32-5.8 8.639 8.639 0 0 1 1.772-6.44 8.71 8.71 0 0 1 5.825-3.305 8.728 8.728 0 0 1 6.466 1.764 8.658 8.658 0 0 1 3.32 5.8 8.639 8.639 0 0 1-1.773 6.44 8.71 8.71 0 0 1-5.824 3.305zm-2.122-16.793c-4.507.566-7.711 4.675-7.144 9.163.567 4.488 4.695 7.679 9.201 7.114 4.507-.564 7.711-4.675 7.144-9.163-.568-4.488-4.695-7.679-9.201-7.114z"
        fill="#94BDEE"
      />
      <path
        d="M82.135 99.957v.008l-.192 2.933-.712 10.889H67.924l-.783-10.74-.216-2.96-.01-.13h15.22z"
        fill="url(#av42pclhdd)"
      />
      <path
        d="m82.135 99.965-.192 2.933c-1.003-.347-3.788-1.183-7.417-1.183-4.584 0-7.385 1.332-7.385 1.332l-.216-2.96 15.21-.122z"
        fill="#94BDEE"
      />
      <path
        d="M82.597 101.508H66.433l-.185-1.871h16.555l-.206 1.871z"
        fill="#206CC6"
      />
      <path
        d="M81.436 114.369H67.638l-.157-.652h14.13l-.175.652z"
        fill="#94BDEE"
      />
      <path
        d="M136.967 58.393s-1.519-.295-1.867 0c-.348.295-1.995 1.963-1.642 2.51.353.547 2.044-.453 2.396-.548.352-.095 1.335-.477 1.335-.477l-.222-1.485z"
        fill="#FFB27D"
      />
      <path
        d="m153.116 111.895 1.629.604s7.076-9.772 7.947-18.37c.156-1.535.104-3.023-.233-4.397l.19-1.088.203-1.163c.23-1.313.547-3.108.909-5.153.833-4.706 1.911-10.721 2.75-15.143l-.439-.14-4.75-1.526-.981-3.104-3.536-.927s-.79 1.539-1.497 8.809a54.87 54.87 0 0 0-.338 3.145c-.275 3.48-.295 7.799.337 12.734.163 1.264.367 2.569.622 3.909.186.983.214 2.321.135 3.859-.356 7.028-2.947 17.952-2.947 17.952l-.001-.001z"
        fill="#312808"
      />
      <path
        d="m154.969 73.442 7.679 15.204.203-1.163c.23-1.313.546-3.108.909-5.153.833-4.706 1.911-10.721 2.75-15.143l-.439-.14-4.75-1.526-.981-3.104-3.536-.927s-.79 1.539-1.497 8.809a54.89 54.89 0 0 0-.338 3.145v-.002z"
        fill="#312808"
      />
      <path
        d="m158.348 63.44.177-1.518c2.979.787 10.936-2.201 10.936-2.201s2.885 2.525 2.507 8.58c-.185 2.954-.522 15.51-.875 19.227a112.739 112.739 0 0 1-.285 2.687v.004a.53.53 0 0 0 .086.329c.52.877.95 1.791 1.303 2.727 3.462 9.187-.533 20.502-.533 20.502l-1.037-.416s-2.274-12.592-4.121-18.155c-.313-.943-.615-1.684-.89-2.128-.803-1.29-1.549-3.4-2.225-5.909-1.279-4.743-2.309-10.914-2.993-15.677-.095.004-2.048-8.051-2.048-8.051l-.002-.001z"
        fill="#312808"
      />
      <path
        d="m136.967 58.394.222 1.486s.098 0 .272-.012c.542-.037 1.861-.11 3.353-.27 2.244-.222 4.906-.614 5.966-1.265 1.615-.982 4.266-3.99 5.992-6.997.049-.075.086-.148.123-.221.9-1.596 1.528-3.18 1.577-4.445a6.331 6.331 0 0 0-.209-2.013 5.125 5.125 0 0 0-.912-1.817.978.978 0 0 0-.173-.21c-.592-.7-1.171-.993-1.171-.993-.297-.124-.617.024-.95.38v.011c-.271.283-.567.712-.85 1.253-.012.012-.025.025-.025.036-.062.11-.123.233-.197.368-.025.025-.037.062-.05.086-.136.283-.271.578-.407.885-1.517 3.29-3.131 8.275-4.475 9.76-.58.638-3.23 1.89-5.338 2.824-1.517.663-2.75 1.153-2.75 1.153l.002.001z"
        fill="#FFB27D"
      />
      <path
        d="M152.658 45.025c.934.432 2.064.725 3.43.8 5.12.28 6.283-4.19 6.494-7.158-.739-.266-1.385-.24-1.757-.22-.363.02-.743.057-1.055.095-.626-.325-1.751-1.909-2.402-4.556l-.221.102-3.694 1.73s.392 1.196.681 2.342c.226.896.389 1.763.253 2.006-.291.117-.466.192-.466.192l-.001.004c-.037.094-.84 2.132-1.263 4.664l.001-.001z"
        fill="#FFB27D"
      />
      <path
        d="M151.377 48.372c.039.102.081.201.128.303 1.296 2.781 3.697 14.302 3.697 14.302-.342 2.072 15.632-.406 15.673-2.293.563-2-1.996-5.213-3.439-6.825a4.5 4.5 0 0 1-.483-.649 4.505 4.505 0 0 1-.655-1.902c-.283-2.673-.175-5.178-.199-6.702-.281-.209-.555-.4-.808-.584-.389-.282-.732-.545-.985-.813-.277-.291-.452-.59-.471-.926a4.921 4.921 0 0 1 .019-.775c.012-.124.028-.247.047-.37.199-1.294.742-2.467.742-2.467-1.373-.554-3.817-.224-3.817-.224s.003.135.004.356c.008.889 0 3.17-.286 3.603-.358.538-6.902 2.894-7.248 2.164-.341-.717.592-4.08.626-4.207l.001-.004c-2.191.856-2.975 1.576-2.975 1.576 1.321.853-.592 3.739.429 6.437z"
        fill="#585858"
      />
      <path
        d="M155.786 24.34c.008-.222.179-2.604 3.823-2.729 0 0-1.791.213-2.101.997 0 0 1.22-.969 2.213-.657.994.312 1.577 2.045 1.583 2.615.006.57-.546 2.409-1.176 2.565-.239.059-.514.043-.764 0 .167.26.332.548.489.869 1.454 2.972-.045 5.876-1.276 5.556-.789-.207-1.013 1.136-1.013 1.136l-7.669-6.183s2.816-5.804 5.891-4.17z"
        fill="#312808"
      />
      <path
        d="M153.453 35.819s.392 1.196.682 2.343c2.159-.6 2.815-2.86 3.013-4.072l-3.695 1.729z"
        fill="#ED985F"
      />
      <path
        d="M152.999 37.086s5.166-1.19 4.372-4.383c-.794-3.193-.662-5.497-3.994-4.872-3.332.626-3.668 2-3.663 3.13.005 1.13 1.921 6.352 3.286 6.125h-.001z"
        fill="#FFB27D"
      />
      <path
        d="M149.423 29.15c.555-1.157 1.209-1.096 1.618-.891 2.428-2.236 4.809-.41 4.809-.41.917 2.205 2.903 3.106 2.903 3.106s-.794.81-3.094.792c-.703-.006-1.41-.327-2.053-.77 1.24 1.244 2.694 2 2.694 2-2.88-.548-4.811-3.868-5.009-4.22-.244.359-.711 1.122-.75 1.767-.054.877.249 2.578-.255 2.643 0 0-1.698-2.282-.864-4.018h.001z"
        fill="#312808"
      />
      <path
        d="M150.947 41.934s-1.244 2.161-1.43 2.717c0 0 1.614-.062 2.265 1.173 0 0 .156-3.6-.835-3.89zM159.503 59.105c3.151-.033 5.819-3.291 7.449-5.895a4.518 4.518 0 0 1-.654-1.902c-.283-2.674-.175-5.179-.199-6.703-.281-.208-.555-.4-.809-.583-.388-.282-.731-.545-.984-.813-2.58 4.343-9.075 15.94-4.803 15.895v.001z"
        fill="#585858"
      />
      <path
        d="m147.594 60.811.431 1.572s.542-.098 1.406-.258c.258-.05.406-.086.406-.086.69-.135 1.516-.319 2.416-.515h.012c1.541-.368 3.267-.81 4.722-1.302 1.023-.356 1.911-.736 2.514-1.117.062-.037.111-.074.173-.123 1.442-1.044 3.155-4.113 4.488-7.403.185-.454.357-.908.53-1.35.616-1.645 1.109-3.266 1.405-4.665.186-.835.308-1.596.333-2.222.012-.221.025-.43.012-.626-.025-3.56-1.8-4.051-1.8-4.051-.036-.012-.074-.025-.123-.037-.308-.05-.628.16-.961.565-.419.503-.863 1.325-1.319 2.32-.703 1.584-1.442 3.634-2.133 5.672-.383 1.117-.752 2.235-1.122 3.265a65.023 65.023 0 0 1-.617 1.756c-.382 1.031-.727 1.866-1.035 2.393-.197.344-.764.823-1.517 1.363-1.306.946-3.217 2.088-4.882 3.02-.691.393-1.344.75-1.886 1.044-.875.478-1.455.785-1.455.785h.002z"
        fill="#FFB27D"
      />
      <path
        d="M162.244 41.518c2.373-.651 4.204 1.193 4.204 1.193-.03-3.554-1.807-4.04-1.807-4.04-.714-.296-1.543.937-2.397 2.847zM165.633 114.619h8.205s.196-1.246-.232-2.19c-.41-.907-1.054-1.319-.9-2.415-.521.535-1.371 1.133-2.557.974-.146.235-.939 1.49-1.423 1.838-.537.383-2.927.246-3.093 1.793zM148.017 114.618h8.205s.306-1.303-.232-2.19c-.477-.785-.295-1.232.059-1.881-.372.152-.852.289-1.381.259-.409-.023-.842-.208-1.221-.427-.352.401-1.865 2.107-2.338 2.446-.536.384-2.926.246-3.092 1.793z"
        fill="#585858"
      />
      <path
        d="m147.105 68.093-1.949-4.737a1.32 1.32 0 0 0-1.567-.765c-.645-1.209-1.535-2.192-2.544-2.882-1.063-.728-2.256-1.13-3.434-1.125a4.886 4.886 0 0 0-1.832.361c-2.772 1.131-4.015 4.62-2.997 8.045l-.152.061s-.474-.032-1.269-.032c-2.51 0-8.218.332-12.223 3.102 0 0-.252.266-.262.674-.005.134.018.285.084.447 0 0 6.429-4.145 12.374.114.365.261.718.503 1.064.727l.533 1.296c.884 2.15 3.373 3.186 5.531 2.305l5.445-2.222 1.135-.463c1.922-.784 2.85-2.993 2.062-4.906h.001zm-10.556-3.84-2.218.906c-.603.246-.92.898-.764 1.511l-.238.097c-.902-3.12.198-6.268 2.674-7.278a4.19 4.19 0 0 1 1.066-.28c1.14-.15 2.327.156 3.396.819 1.015.63 1.92 1.582 2.572 2.777l-.327.133a1.305 1.305 0 0 0-1.609-.542l-2.219.906a1.1 1.1 0 0 0-1.427-.596l-.308.125a1.091 1.091 0 0 0-.597 1.422h-.001z"
        fill="#3F3A2A"
      />
      <path
        d="M132.399 72.084a29.44 29.44 0 0 1-1.064-.727c-5.946-4.26-12.373-.114-12.373-.114a1.04 1.04 0 0 1-.085-.447c.9-.59 4.273-2.554 8.452-1.883 4.897.787 5.782 1.465 5.07 3.17zM145.044 73l-1.135.463c1.701-.925-.405-4.833-1.637-7.412-1.232-2.58-3.097-1.4-3.097-1.4-.427-.865-1.342-.818-1.342-.818-.047-.833-.685-1.001-.685-1.001l.307-.125a1.095 1.095 0 0 1 1.427.596l2.219-.906a1.304 1.304 0 0 1 1.609.543l.327-.134c-.652-1.195-1.557-2.147-2.572-2.777-1.068-.663-2.256-.97-3.396-.82a2.37 2.37 0 0 0 .542-.623c1.178-.005 2.371.397 3.434 1.125 1.008.69 1.898 1.672 2.544 2.882a1.32 1.32 0 0 1 1.567.765l1.949 4.736c.789 1.914-.14 4.123-2.062 4.907l.001-.001z"
        fill="#312808"
      />
      <path
        d="M142.49 62.335c.788.332 1.898 1.486 3.674.786 1.269-.492 2.613-.835 3.266-.995.259-.05.407-.086.407-.086l-.703-2.038s-.025.012-.086.025c-.456.097-2.774.589-3.859.343-1.22-.282-3.266 1.178-3.266 1.178s-.222.467.567.786v.001z"
        fill="#FFB27D"
      />
      <path
        d="M120.217 74.664c0-.738-1.341-3.014-1.341-3.014s-1.341 2.276-1.341 3.014c0 .737.601 1.335 1.341 1.335.741 0 1.341-.598 1.341-1.335z"
        fill="#585858"
      />
      <path
        d="M33.886 52.564c-.08.757-.375 1.367-.881 1.833-.506.465-1.266.774-2.278.925l3.78 4.434h-1.834l-3.77-4.408.175-1.274h.803c.285 0 .535-.012.75-.035a3.95 3.95 0 0 0 .559-.096c.163-.046.302-.102.419-.166a2.19 2.19 0 0 0 .322-.235c.257-.245.422-.57.498-.978h-3.526V51.29h3.509a1.613 1.613 0 0 0-.227-.576 1.633 1.633 0 0 0-.393-.436 1.574 1.574 0 0 0-.646-.28c-.262-.058-.614-.087-1.056-.087h-1.187v-1.274h7.54v1.274h-3.35c.203.145.372.337.505.576.134.238.227.506.28.803h2.566v1.274h-2.558zM75.29 75.776c-.05.476-.236.86-.554 1.154-.319.293-.797.487-1.434.582l2.379 2.792h-1.154l-2.374-2.776.11-.802h.505c.18 0 .338-.007.473-.022a2.48 2.48 0 0 0 .352-.06c.102-.03.19-.064.264-.105.073-.044.14-.093.203-.148.161-.154.266-.359.313-.615h-2.22v-.803h2.21a1.016 1.016 0 0 0-.144-.362 1.03 1.03 0 0 0-.247-.275.99.99 0 0 0-.407-.176 3.189 3.189 0 0 0-.665-.055h-.747v-.802h4.748v.802h-2.11c.128.092.234.212.319.363.084.15.142.318.175.505h1.616v.803h-1.61zM140.053 85.862c-.04.364-.181.658-.425.882-.244.224-.609.373-1.097.446l1.82 2.134h-.883l-1.815-2.122.084-.613h.387c.137 0 .257-.006.361-.017a1.9 1.9 0 0 0 .269-.046.989.989 0 0 0 .202-.08 1.02 1.02 0 0 0 .155-.114.848.848 0 0 0 .24-.47h-1.698v-.614h1.689a.8.8 0 0 0-.298-.487.756.756 0 0 0-.311-.135 2.445 2.445 0 0 0-.508-.042h-.572v-.613h3.631v.613h-1.614c.098.07.179.163.244.278.064.114.109.243.134.386h1.236v.614h-1.231zM111.197 29.098c-.148 1.373-.681 2.482-1.6 3.326-.918.845-2.296 1.405-4.134 1.68l6.859 8.046h-3.326l-6.843-8 .317-2.312h1.457c.517 0 .971-.02 1.362-.063.391-.042.729-.1 1.014-.174.296-.085.549-.185.76-.301.211-.127.407-.27.586-.428.465-.443.766-1.035.903-1.774h-6.399v-2.312h6.367a2.92 2.92 0 0 0-.411-1.046 2.983 2.983 0 0 0-.713-.792c-.296-.232-.687-.4-1.172-.506-.475-.106-1.114-.159-1.917-.159h-2.154v-2.312h13.685v2.312h-6.082c.369.264.676.613.918 1.046.243.433.412.918.507 1.457h4.657v2.312h-4.641z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="s5rb0qyqqa"
          x1="139.46"
          y1="97.404"
          x2="137.082"
          y2="98.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0068E1" />
          <stop offset="1" stopColor="#B0D5FF" />
        </linearGradient>
        <linearGradient
          id="ppq5jg1bnb"
          x1="145.913"
          y1="103.734"
          x2="135.568"
          y2="115.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0068E1" />
          <stop offset="1" stopColor="#B0D5FF" />
        </linearGradient>
        <linearGradient
          id="ywauh5ncxc"
          x1="127.144"
          y1="82.33"
          x2="95.978"
          y2="117.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0068E1" />
          <stop offset="1" stopColor="#B0D5FF" />
        </linearGradient>
        <linearGradient
          id="av42pclhdd"
          x1="82.135"
          y1="99.957"
          x2="68.116"
          y2="115.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0068E1" />
          <stop offset="1" stopColor="#B0D5FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
