import React, { useEffect } from 'react'
import styled from 'styled-components'

const SaveAlert = styled.label`
  transform: none;
  width: 170px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 12px 16px 0 rgba(24, 27, 37, 0.12);
  position: absolute;
  background-color: var(--grey-900);
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate3d(0, -50%, 0);
  text-align: center;
  padding: 10px 10px 10px 10px;
`

function AlertSnackBar(props) {
  useEffect(() => {
    setTimeout(function () {
      props.close()
    }, 3000)
  })

  return <SaveAlert>{props.text}</SaveAlert>
}

export default AlertSnackBar
