import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AccountContext } from "../../providers/accountProvider";
import styled from "styled-components";
import LoginIcon from "../Welcome/writer-login-art.svg";
import StudioLogo from "../Images/StudioLogo";
import Loader from "../Loader";
import { Box } from "../../../src/designSystem";
import { Formik, Form } from "formik";
import CheckedIcon from '../Images/Checked'
import * as Yup from "yup";
import { FloatingInput, DropDownSelect } from "../../components/FormikInputs";
import { countryList } from "../../constants";
import { post, get } from "../../lib/api";
import { saveSession, getRoleStringFromAccountType } from "../../lib/utils";
import { menuItemObj } from "../../components/PrivatePage";

const Container = styled.div`
  background-color: var(--Grey300);
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
`;
const MainDiv = styled.div`
  display: flex;
  margin: auto;
  width: 900px;
  min-height: 540px;
  @media (max-width: 600px) {
    width: 100vw;
    flex-direction: column;
    // margin-right: 16px;
  }
`;
const LeftDiv = styled.div`
  background-color: var(--FjordDark300);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;
const RightDiv = styled.div`
  width: 50%;
  padding: 48px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;
const IconHolder = styled.div``;
const LogoHolder = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;
const LoginHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`;
const FrogetPasswordLabel = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`;
const ResendTryAgainButton=styled.button`
color: var(--Azure500);
font-size: 14px;
margin-bottom: 100px;
outline: none;
border: none;
background: ${({isSubmitting})=>isSubmitting?'var(--Azure500)':'none'};
cursor:pointer;
`
const LoginButton = styled.button`
  outline: none;
  border: none;
  width: 100%;
  padding: 14px;
  align-items: center;
  cursor: ${({disabled})=>disabled?"not-allowed":"pointer"};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;
const ContactUsDiv = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  color: var(--Text500);
  margin-top: 16px;
`;
const ForgetPasswordDiv=styled.div`
  display:flex;
  color: var(--Azure500);
  justify-content:flex-end;
  font-size: 12px;
  cursor:pointer;
`
const ContactUsButton = styled.a`
  margin-left: 4px;
  text-decoration: None;
  color: var(--Azure500);
`;

const ResendOtp = styled.button`
  background: none;
  outline: none;
  width: 47%;
  border-radius: 4px;
  border: solid 2px #e7e8eb;
  padding: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text100);
  cursor: pointer;
`;

const MessageDiv = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--Text700);
`;

const SetupHeading = styled(LoginHeading)`
  margin-top: -30px;
  font-size: 17px;
`;

const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const LangOptionsContainer = styled.div`
  margin-top: 8px;
`;
const LoginSignupDiv = styled.div`
  display: flex;
  text-align: center;
  border: 2px solid black;
  border-radius: 4px;
`;
const Signup = styled.div`
  flex: 1;
  border-right: 1px solid black;
  background-color: ${({ type }) =>
    type === "signup" ? "var(--Azure500)" : "white"};
  color: ${({ type }) => (type === "signup" ? "white" : "black")};
  padding: 6px;
`;
const Login = styled.div`
  flex: 1;
  background-color: ${({ type }) =>
    type === "login" ? "var(--Azure500)" : "white"};
  color: ${({ type }) => (type === "login" ? "white" : "black")};
  padding: 6px;
`;
const RadioLabel = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ invalid }) => invalid ? 'var(--Punch500)' : 'var(--Text700)'};
`
const LabelErrorSpan = styled.span`
  font-style: italic;
  font-size: 12px;
`
const OptionsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 115px;
`
const RadioOption = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 10px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: ${({ active }) =>
    active ? ' var(--Azure500)' : 'var(--Grey300)'};
  // margin-right: 16px;
`

const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  border: ${({ active }) =>
    active ? 'solid 1px var(--TextDark500)' : 'solid 1px var(--Text500)'};
  padding: 3px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
`
const RadioNameSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ active }) => (active ? 'var(--TextDark700)' : 'var(--Text500)')};
`

function GlobalLogin({ history, onUpdateUser }) {
  const [view, changeView] = useState("login");
  const [type, setType] = useState("login");
  const [email, setEmail] = useState("");
  const [langPref, updateLangPref] = useState("");
  const [message, setMessage] = useState({"text":"",color:"red"});
  const [tempSessionInfo, setTempSessionInfo] = useState(null);
  const [loader,setLoader]=useState(true);
  const [accountType, updateAccountType] = useState('')
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const [activationButton, setActivationButton] = useState(false);
  const referrer = params.get("referrer");
  const isMobile = window.screen.width < 600;
  const authorSelected = accountType.includes('Author') ? 'true' : undefined
  const publisherSelected = accountType.includes('Publisher')
    ? 'true'
    : undefined
  const localizationAgencySelected = accountType.includes('localised_agency')
    ? 'true'
    : undefined
  const voartistSelected = accountType.includes('VO Artist')
    ? 'true'
    : undefined
  const checkForGlobalLogin=()=>{
    //regular expressions to extract IP and country values
    const countryCodeExpression = /loc=([\w]{2})/;
    const userIPExpression = /ip=([\w\.]+)/;
  
    //automatic country determination.
    //const initCountry=()=> {
      return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.timeout = 3000;
          xhr.onreadystatechange = function () {
              if (this.readyState == 4) {
                  if (this.status == 200) {
                      let countryCode = countryCodeExpression.exec(this.responseText)
                      let ip = userIPExpression.exec(this.responseText)
                      if (countryCode === null || countryCode[1] === '' ||
                          ip === null || ip[1] === '') {
                          reject('IP/Country code detection failed');
                      }
                      let result = {
                          "countryCode": countryCode[1],
                          "IP": ip[1]
                      };
                      resolve(result)
                  } else {
                      reject(xhr.status)
                  }
              }
          }
          xhr.ontimeout = function () {
              reject('timeout')
          }
          xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace', true);
          xhr.send();
      });
     // }
    //   initCountry().then(result => {
    //         if (result.countryCode === 'IN') {
    //           history.push('/login')
    //         }
    //         else{
    //           setLoader(false);
    //         }
    //    }).catch(error=>{
    //     setLoader(false);
    //  })
    }
    
  useEffect(() => {
  async function checkDetails() {
    try{
      const result= await checkForGlobalLogin();
      if (result.countryCode === 'IN') {
        history.push('/login')
      }
      else{
        setLoader(false);
        const token = params.get('token')
        const uid = params.get('uid')
        if(uid && token){
          get('/user_api/user.get_creator_details?is_novel=0',{
            headers:{
              'access-token':token,
              'uid': uid,
            }
          }).then(res => {
            const { result } = res;
            if(result.uid){
              const { fullname , image_url, uid } = result
              const sessionInfo ={
                name:fullname,
                token,
                image_url,
                uid,
              }
              saveSession(sessionInfo)
              history.push(menuItemObj.audiobooks);
            }else{
              setTempSessionInfo({token, uid})
              changeView('register')
            }
          })
        }
      }

    }catch(error){
       setLoader(false);
    }
  

    
  }
  checkDetails();
  },[])

  const emailValidation = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const onActivationClick = () => {
    post(`/user_api/activation`, {
      data: { email: email },
    })
      .then((response) => {
        setActivationButton(false);
        setMessage({"text":response.message,"color":"green"});
      })
      .catch((error) => {
        setActivationButton(false);
        setMessage({...message ,"text":error.message,"color":"red"});
      });
  };
  const onLoginSignupClick=(value,resetForm)=>{
     setType(value)
     resetForm();
     setMessage({...message ,"text":""});
     setActivationButton(false);
  }
  const handleAccTypeUpdate = (actype) => {
    updateAccountType(actype)
  }
  return (
    <Container>
       {loader && 
      <Loader></Loader>}
      {!loader &&
      <MainDiv>
        <LeftDiv>
          <IconHolder>
            <img src={LoginIcon} />
          </IconHolder>
        </LeftDiv>
        <RightDiv>
          <LogoHolder>
            <StudioLogo />
          </LogoHolder>

            {view === 'login' && (
              <>
                <LoginHeading>Enter your Details To Continue</LoginHeading>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().required('Required'),
                    password: Yup.string().required('Required'),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setActivationButton(false)
                    setMessage({ ...message, text: '' })
                    setEmail(values.email)
                    const loginData = {
                      type: 'email_login',
                      email: values.email,
                      password: values.password,
                      view: 'dashboard',
                      user_type: 'pugc',
                      device_id: localStorage.getItem('uuid')
                    }

                  post(`/user_api/user.login?type=${type}`, { data: loginData })
                    .then((response) => {
                      setSubmitting(false);
                      if (response?.user_info) {
                        const { user_info = {} } = response;
                        let {
                          uid,
                          access_token: token,
                          image_url: userImageUrl,
                          fullname: userName,
                        } = user_info;
                        let isUserExist = response.already_exists;

                        const sessionInfo = {
                          uid,
                          token,
                          image_url: userImageUrl,
                          name: userName,
                        };
                        if (
                          typeof isUserExist == "undefined" ||
                          isUserExist === 0
                        ) {
                          // dont save sessioninfo to localstorage yet, wait for the user to register
                          // if the user drops off at this point, no need to save userinfo to localstorage
                          // otherwise user will be directly logged in next time user opens studio and wont be able to select account type
                          changeView("register");
                          setMessage({...message ,"text":""});
                          setTempSessionInfo(sessionInfo);
                        } else if (isUserExist === 1) {
                          saveSession(sessionInfo);
                          get("/user_api/user.get_creator_details?is_novel=0").then(
                            (res) => {
                              onUpdateUser(res.result);
                              let { account_type } = res.result;
                              if (referrer === "premyatra")
                                window.location.href = "/writer-contest.html";
                              else history.push(menuItemObj.audiobooks);
                              // if (account_type === 'Author') {
                              //   history.push(menuItemObj.home)
                              // } else {
                              //   history.push(menuItemObj.audiobooks)
                              // }
                            }
                          );
                        }
                      } else {
                        setMessage({"text":response.message,"color":"green"});
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      setMessage({...message ,"text":error.message,"color":"red"});
                      if (error.message.includes("not verified")) {
                        setActivationButton(true);
                      }
                    });
                }}
              >
                {({ values = {}, isSubmitting, errors,resetForm }) => (
                  <Form>
                    <Box mb={16}>
                      <LoginSignupDiv>
                        <Signup type={type} onClick={() => onLoginSignupClick("signup",resetForm)}>
                          Signup
                        </Signup>
                        <Login type={type} onClick={() => onLoginSignupClick("login",resetForm)}>
                          Login
                        </Login>
                      </LoginSignupDiv>
                    </Box>
                    <Box width="100%" mb={16}>
                      <FloatingInput
                        type="text"
                        name="email"
                        label="Email ID"
                        size="lg"
                      />
                    </Box>
                    <Box width="100%" mb={16}>
                      <FloatingInput
                        type="password"
                        name="password"
                        label="Password"
                        size="lg"
                        password
                      />
                    </Box>
                    <Box mb={10}>
                    <ForgetPasswordDiv onClick={()=>changeView('forgetPassword')}>
                    Forgot Password?
                    </ForgetPasswordDiv>
                    </Box>
                    <Box width="100%" mb={16}>
                      <LoginButton
                        id="login-handler"
                        disabled={
                          !emailValidation(values.email) || errors.password
                        }
                        type="submit"
                      >
                        {isSubmitting ? (
                          <Loader size="sm" invertColor noPadding />
                        ) : (
                          "continue"
                        )}
                      </LoginButton>
                    </Box>
                  

                    {message.text !== "" && (
                      <Box variant="flex" justifyContent="center" color={message.color==="red"?"red":"green"}>
                        {message.text}
                      </Box>
                    )}
                  </Form>
                )}
              </Formik>
              {activationButton && (
                <Box mt={16}>
                  <LoginButton
                    type="notsubmit"
                    onClick={() => onActivationClick()}
                  >
                    Resend Email Activation Link
                  </LoginButton>
                </Box>
              )}
            </>
          )}
          {view === "forgetPassword" &&
            <>
            <LoginHeading>Password Reset </LoginHeading>
            {message?.text !== "passwordChangdSuccessfully" && message?.text !== "passwordChangeFailed" && (<FrogetPasswordLabel>Enter the email address and we'll send you a link to reset your password.</FrogetPasswordLabel>)}
            {message.text === "passwordChangdSuccessfully" && (<FrogetPasswordLabel>We’ve sent you an email with password reset link, be sure to check the spam folder, incase you don’t find it.</FrogetPasswordLabel>)}
            {message.text === "passwordChangeFailed" && (<FrogetPasswordLabel>We can’t find any account associated with the email address {email}.</FrogetPasswordLabel>)}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                  setActivationButton(false);
                 // setMessage({...message ,"text":""});
                  setEmail(values.email);
                  post(`/user_api/user.password_reset_email`, { data: {
                    email: values.email,
                  } })
                    .then((response) => {
                      setSubmitting(false);
                      setMessage({"text":"passwordChangdSuccessfully","color":"green"});
                    
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      setMessage({...message ,"text":"passwordChangeFailed","color":"red"});
                     
                    });
                }
                }
                
            >
              {({ values = {}, isSubmitting, errors,resetForm }) => (
                <Form>
                  {message?.text !== "passwordChangdSuccessfully" && message?.text !== "passwordChangeFailed" && (<React.Fragment>
                  <Box width="100%" mb={16}>
                    <FloatingInput
                      type="text"
                      name="email"
                      label="Email ID"
                      size="lg"
                    />
                  </Box>
                  <Box width="100%" mb={16}>
                    <LoginButton
                      id="login-handler"
                      disabled={
                        !emailValidation(values.email) 
                      }
                      type="submit"
                    >
                      {isSubmitting ? (
                        <Loader size="sm" invertColor noPadding />
                      ) : (
                        "continue"
                      )}
                    </LoginButton>
                  </Box>
                  </React.Fragment>)}

                  {message.text === "passwordChangdSuccessfully" && (<ResendTryAgainButton isSubmitting={isSubmitting} type="submit">
                  {isSubmitting ? (
                        <Loader size="sm" invertColor noPadding />
                      ) : (
                        "Resend Link"
                      )}
                    
                  </ResendTryAgainButton>)}
                  {message.text === "passwordChangeFailed" && (<ResendTryAgainButton isSubmitting={isSubmitting} type="submit">
                  {isSubmitting ? (
                        <Loader size="sm" invertColor noPadding />
                      ) : (
                        "Try Again"
                      )}
                    
                    
                  </ResendTryAgainButton>)}

                  {/* {message.text !== "" && (
                    <Box variant="flex" justifyContent="center" color={message.color==="red"?"red":"green"}>
                      {message.text}
                    </Box>
                  )} */}
                </Form>
              )}
            </Formik>
          </>
          }
          {view === "register" && (
            <>
              <Formik
                initialValues={{
                  fullName: "",
                  email: email,
                  langPref: "English",
                  country: "Global",
                }}
                validationSchema={Yup.object({
                  email: Yup.string().required("Required"),
                  fullName: Yup.string().required("Required"),
                  langPref: Yup.string().required("Required"),
                  country: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setMessage({...message ,"text":""});
                  let countryCode = "";
                  // this is to show register form errors on submit click, doesnt needed to be reset back
                  if (values.country !== "Global")
                    countryCode = countryList.filter(
                      (elm) => elm.name === values.country
                    )[0].code;
                  const { uid, token } = tempSessionInfo || {};
                  if (!uid) return;

                  // send username to update user api
                  let userData = {
                    fullname: values.fullName,
                    view: "dashboard",
                    email: values.email,
                    role: getRoleStringFromAccountType(accountType),
                  }
                  if (values.country !== 'Global')
                    userData.location = countryCode
                  post('/user_api/user.update?is_novel=0', {
                    data: userData,
                    headers: { uid, "access-token": token },
                  });
                  const userAccountData = {
                    account_type: accountType,
                    languages: values.langPref.toLowerCase(),
                    fullname: values.fullName,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    source_platform: "studio",
                    user_id: uid,
                    device_id: localStorage.getItem('uuid')
                  }

                  post('/user_api/user.create_creator_account?is_novel=0', {
                    data: userAccountData,
                    headers: {
                      uid,
                      "access-token": token,
                      "device-type": isMobile ? "Mobile" : "Desktop",
                    },
                  })
                    .then(() => {
                      setSubmitting(false);
                      onUpdateUser({account_type: accountType})
                      tempSessionInfo &&
                        saveSession({
                          ...tempSessionInfo,
                          name: values.fullName,
                        });
                        //handleFacebookEvents(false)
                      if (referrer === "premyatra")
                        window.location.href = "/writer-contest.html";
                      else history.push(menuItemObj.audiobooks);
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      console.log(error);
                      setMessage({...message ,"text":error.message,"color":"red"});
                    });
                }}
              >
                {({ values = {}, isSubmitting }) => (
                  <Form>
                    <SetupHeading>
                      Set up your Creator Account !
                    </SetupHeading>

                    <Box mb={16}>
                      <FloatingInput
                        type="text"
                        name="fullName"
                        label="Your Name"
                      />
                    </Box>
                    <InputContainer>
                      <RadioLabel>
                        Account Type{' '}
                        {/* {accTypeMissing && <LabelErrorSpan> - required</LabelErrorSpan>} */}
                      </RadioLabel>
                      <OptionsContainer>
                        <RadioOption
                          onClick={() =>
                            handleAccTypeUpdate('localised_agency')
                          }
                          active={localizationAgencySelected}
                        >
                          <RadioIcon active={localizationAgencySelected}>
                            <CheckedIcon active={localizationAgencySelected} />
                          </RadioIcon>
                          <RadioNameSpan active={localizationAgencySelected}>
                            Localisation Agency
                          </RadioNameSpan>
                        </RadioOption>
                        <RadioOption
                          onClick={() => handleAccTypeUpdate('Author')}
                          active={authorSelected}
                        >
                          <RadioIcon active={authorSelected}>
                            <CheckedIcon active={authorSelected} />
                          </RadioIcon>
                          <RadioNameSpan active={authorSelected}>
                            Author/Writer
                          </RadioNameSpan>
                        </RadioOption>

                  <RadioOption
                    onClick={() => handleAccTypeUpdate('VO Artist')}
                    active={voartistSelected}
                  >
                    <RadioIcon active={voartistSelected}>
                      <CheckedIcon active={voartistSelected} />
                    </RadioIcon>
                    <RadioNameSpan active={voartistSelected}>VO Artist</RadioNameSpan>
                  </RadioOption>

                        <RadioOption
                          onClick={() => handleAccTypeUpdate('Publisher')}
                          active={publisherSelected}
                        >
                          <RadioIcon active={publisherSelected}>
                            <CheckedIcon active={publisherSelected} />
                          </RadioIcon>
                          <RadioNameSpan active={publisherSelected}>
                            Publisher
                          </RadioNameSpan>
                        </RadioOption>
                      </OptionsContainer>
                    </InputContainer>
                    <Box mb={16}>
                      <FloatingInput
                        type="text"
                        name="email"
                        label="Your Email"
                        disabled
                      />
                    </Box>

                    <InputContainer>
                      <LangOptionsContainer>
                        <DropDownSelect
                          name="langPref"
                          placeholder="Click to select language"
                          selectOptions={[
                            { id: 8, name: "English" },
                            { id: 1, name: "Hindi" },
                            { id: 2, name: "Tamil" },
                            { id: 3, name: "Bengali" },
                            { id: 4, name: "Malayalam" },
                            { id: 5, name: "Telugu" },
                            { id: 6, name: "Marathi" },
                            { id: 7, name: "Kannada" },
                            { id: 9, name: "Spanish" },
                          ]}
                          idKey="name"
                          nameKey="name"
                          value={langPref}
                          onChange={(language) => updateLangPref(language)}
                        />
                      </LangOptionsContainer>
                      <Box mt={16}>
                        <DropDownSelect
                          name="country"
                          placeholder="Click to select country"
                          selectOptions={countryList}
                          idKey="name"
                          nameKey="name"
                        />
                      </Box>
                      <Box mt={16}>
                        <LoginButton
                          id="login-handler"
                          disabled={
                            values.fullName === "" ||
                            values.langPref === "" ||
                            values.email === "" ||
                            values.country === "" ||
                            accountType===""
                          }
                          type="submit"
                        >
                          {isSubmitting ? (
                            <Loader size="sm" invertColor noPadding />
                          ) : (
                            "continue"
                          )}
                        </LoginButton>
                      </Box>
                      {message.text !== "passwordChangdSuccessfully" && message.text !== "passwordChangeFailed" && (
                        <Box variant="flex" justifyContent="center" color={message.color==="red"?"red":"green"}>
                          {message.text}
                        </Box>
                      )}
                    </InputContainer>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {view !== "create-book" && (
            <ContactUsDiv>
              <span>Need help?</span>
              <ContactUsButton href="mailto:writers@pocketfm.in">
                Contact Us
              </ContactUsButton>
            </ContactUsDiv>
          )}
        </RightDiv>
      </MainDiv>}
    </Container>
  );
}
const GlobalLoginPageWrapper = (props) => (
  <AccountContext.Consumer>
    {({ onUpdateUser, user }) => (
      <GlobalLogin onUpdateUser={onUpdateUser} user={user} {...props} />
    )}
  </AccountContext.Consumer>
);

export default withRouter(GlobalLoginPageWrapper);
