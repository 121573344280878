export const getAudiobookWordCountLimit = (language, book_type) => {
  if (book_type === 'Audiobook') return 100
  // if (language === 'hindi') return 1500
  return 1000
}
const scriptStatusShow = (status) => {
  switch (status) {
    case 'pending':
      return 'Under Review'
    case 'issues to be resolved':
      return 'Issue to be Resolved'
    case 'issues resolved in script':
      return 'Issues Resolved'
    case 'approved':
      return 'Approved'
    default:
      return status
  }
}
export { scriptStatusShow }
