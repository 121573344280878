import React, { useEffect, useState, useContext } from 'react'
import EntityDetail from '../EntityDetail'
import { SingleEntityContainer, Divider } from '../styles'
import CommentCard from './comment'
import { CommentContext } from '../../../providers/commentProvider'
import GenericError from '../../../components/GenericError'
import { Box } from '../../../designSystem'

const SingleEntityComments = ({
  entity_id,
  userInfo,
  isComment,
}) => {
  const { allComments } = useContext(CommentContext)
  const [comments, setComments] = useState(allComments[entity_id])
  const [errorPopup, toggleErrorPopup] = useState(false)

  useEffect(() => {
    setComments(allComments[entity_id])
  }, [allComments])

  const isMobile = window.screen.width < 600
  return (
    <>
      <GenericError show={errorPopup} onClose={() => toggleErrorPopup(false)} />
      {comments && (
        <SingleEntityContainer>
          {isMobile && (
            <>
              <EntityDetail isComment={isComment} comment={comments} />
              <Divider />
            </>
          )}
          <Box width={['100%', '70%']} mt={1}>
            {comments.result &&
              comments.result.length > 0 &&
              comments.result.map((comment, index) => (
                <CommentCard
                  key={comment._id.$oid}
                  entityKey={entity_id}
                  comments={comment}
                  commentIndex={index}
                  userInfo={userInfo}
                  isComment={isComment}
                />
              ))}
          </Box>
          {!isMobile && (
            <EntityDetail isComment={isComment} comment={comments} />
          )}
        </SingleEntityContainer>
      )}
    </>
  )
}

export default SingleEntityComments
