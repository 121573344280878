import React, { useEffect, useState ,useContext } from 'react'
import { emptyStateText } from '../../constants'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import { get } from '../../../../../lib/api'
import { useDidUpdate, useInfiniteScroll } from '../../../../../lib/customHooks'
import { mockResponse } from './mock'
import GenericError from '../../../../../components/GenericError'
import { isAdminAccess } from '../../../../../config'
import BookCard from './BookCard'
import BookCardCms from './BookCardCms';
import { AccountContext } from '../../../../../providers/accountProvider'

const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`

const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
`
const Spacer = styled.div`
  height: 80px;
`

const Published = ({ history, renderEmptyState, userType }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState(null)
  const [apiError, toggleApiError] = useState(false)
  const [nextUrl, setNextUrl] = useState(null);
  const { user: {account_type = 'Author',professional_creator,professional_creator_details} } = useContext(AccountContext)
  const isFetching = useInfiniteScroll(fetchMoreData)

  const fetchData = () => {
    setIsLoading(true)
    const auditionBookListApi = '/content_api/book.published_shows'
    get(auditionBookListApi, {
      params: {
        view: isAdminAccess ? 'cms' : null,
      },
    })
      .then((response) => {
        const { result } = response
        // TODO remove this temp check
        if (Array.isArray(result)) {
          setBooks(result)
          setNextUrl(null)
        } else {
          setBooks(result.books)
          setNextUrl(result.next_url)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result } = response
        const { next_url, books: newBooks } = result
        setBooks(books.concat(newBooks))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useDidUpdate(() => {
    setIsLoading(false)
  }, [books])

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    )
  }
  const isMobile = window.screen.width < 600
  return (
    <>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      {!books && <BlankPaper />}
      {books &&
        !!books.length &&
        books.map((book) =>
          isAdminAccess ? (
            <BookCardCms
              key={book.show_id}
              data={book}
              history={history}
              userType={userType}
            />
          ) : (
            <BookCard
              key={book.show_id}
              data={book}
              history={history}
              userType={userType}
              isMobile={isMobile}
            />
          )
        )}
      {books &&
        !!books.length && <Spacer>
        {isFetching && <Loader />}
      </Spacer>}
      {books &&
        !books.length &&
        renderEmptyState(
          emptyStateText[`${(professional_creator===1 && professional_creator_details.status==="approved")?'Concepts':'Published'}`]['text'],
          emptyStateText[`${(professional_creator===1 && professional_creator_details.status==="approved")?'Concepts':'Published'}`]['subtext'],
          history
        )}
    </>
  )
}

export default withRouter(Published)
