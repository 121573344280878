import styled from 'styled-components'
import Eye from '../../Images/Eye'
import EyeClose from '../../Images/EyeClose'
const PasswordEye = styled(Eye)`
  position: absolute;
  right: 9px;
  top: 26px;
`
const PasswordClose = styled(EyeClose)`
  position: absolute;
  right: 9px;
  top: 26px;
`
const InputLabel = styled.label`
  margin-right: 6px;
`

const InputLabelDiv = styled.div`
  display: flex;
  align-items: center;
`

const InputDiv = styled.div`
  background-color: #f3f3f5;
  border-radius: 4px;
  // margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${({ error }) => (error ? 'solid 1px var(--Punch500)' : 'none')};

  textarea,
  input {
    width: 100%;
    height: ${({ isLarge, isTextArea }) =>
      isTextArea ? 'unset' : isLarge ? '68px' : '56px'};
    padding: ${({ isTextArea }) =>
      isTextArea ? '5px 16px 0 10px' : '14px 16px 0 10px'};
    // padding: 14px 16px 0 10px;
    outline: 0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: var(--Text700);
  }

  ${InputLabelDiv} {
    font-size: ${({ isLarge }) => (isLarge ? '14px' : '12px')};
    line-height: 1.33;
    text-align: left;
    color: var(--Text300);
    padding: 0 12px;
    position: ${({ isTextArea }) => (isTextArea ? 'sticky !important' : '')};
    // pointer-events: none;
    position: absolute;
    transform: ${({ isTextArea, active, isLarge }) =>
      active && isTextArea
        ? 'translate(0, 6px) scale(0.75)'
        : isTextArea
        ? 'translate(0, 20px) scale(1)'
        : active
        ? 'translate(0, 12px) scale(0.75)'
        : isLarge
        ? 'translate(0, 25px) scale(1)'
        : 'translate(0, 22px) scale(1)'};
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within ${InputLabelDiv} {
    transform: ${({ isTextArea }) =>
      isTextArea
        ? 'translate(0, 6px) scale(0.75)'
        : 'translate(0, 12px) scale(0.75)'};
  }
`
const Input = styled.input`
  background-color: #f3f3f5;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  border: ${({ border }) =>
    border ? `solid 1px var(--storm-op-16) !important` : 'none'};
`
const RequiredField = styled.span`
  color: red;
`

export {
  PasswordEye,
  PasswordClose,
  InputLabel,
  InputLabelDiv,
  InputDiv,
  Input,
  RequiredField,
}
