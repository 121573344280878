import React, { useEffect, useState } from 'react'
import { menuItemObj } from '../PrivatePage'
import { getTokenAndUid } from '../../lib/utils';
import Loader from '../Loader';
import './style.css'
import { get } from '../../lib/api';

const LandingPage = ({ history }) => {
  const isMobile = window.screen.width < 768
  const [buttonHide, setButtonHide] = useState(false);
  const [loader,setLoader]=useState(true);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const verificationToken = params.get("token");
  const resetPasswordToken = params.get("reset-password-token");
  const handleClick = () => {
    history.push('/login')
  }

  useEffect(() => {
    if(verificationToken){
      window.localStorage.setItem("verification",verificationToken);
      window.location.href="/verify-token.html";
      return;
    }
    if(resetPasswordToken){
      window.localStorage.setItem("resetPasswordToken",resetPasswordToken);
      history.push('/reset-password');
      return;
    }
    setLoader(false);
    window.addEventListener('scroll', function (e) {
      const lastKnownScrollPosition = window.scrollY
      if (lastKnownScrollPosition > 340) {
        setButtonHide(true)
      } else {
        setButtonHide(false)
      }
    })
  })

  useEffect(() => {
    const { uid } = getTokenAndUid()
    if (uid) {
      get('/user_api/user.get_creator_details?is_novel=0').then(res => {
        let { account_type } = res.result
        if (account_type === 'Author') {
          history.push(menuItemObj.home)
        } else {
          history.push(menuItemObj.audiobooks)
        }
      })
    }
    
  }, [])

  return (
    <>
      {loader && 
      <Loader></Loader>}
        {!loader && 
    <div className="welcome-container">
      <div className="header-container">
        <div className="welcome-header ">
          <div className="studio-image">
            <img src="images/Studio.svg" />
          </div>
          <div className="account-buttons">
            {!isMobile && (
              <div className="login-button" onClick={handleClick}>
                <div className="login-button-content">Login</div>
              </div>
            )}
            {isMobile && !buttonHide && (
              <div className="login-button" onClick={handleClick}>
                <div className="login-button-content">Login/Signup</div>
              </div>
            )}
            {buttonHide && (
              <div className="create-button" onClick={handleClick}>
                <div className="create-button-content">
                  {isMobile ? 'Login/Signup' : 'Create Account for Free'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="welcome-part-1">
        <div className="section part-section">
          <div className="first-half-section first-half">
            <div className="heading heading-part">
              Share Your Stories and Voice With the World.
            </div>
            <div className="content content-part">
              Pocket Studio discovers untouched, fresh, interesting, and
              different talented voice-over artists and authors on Pocket FM and
              gives them global exposure in the multimedia industry.
            </div>
            <div className="account-button" onClick={handleClick}>
              <div className="button-content">Create Account for Free</div>
            </div>
          </div>
          <div className="second-video-section">
            <iframe
              src="https://www.youtube.com/embed/4if6qIFshwg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="divider">
        <img src={isMobile ? 'images/divider.svg' : 'images/divider-web.svg'} />
      </div>
      <div className="welcome-part-2">
        <div className="heading-studio">
          <div className="studio-header">What is Pocket Studio?</div>
          <div className="studio-sub-header">
            Pocket Studio is more than just a platform
          </div>
        </div>
        <div className="studio-benefits">
          <div className="studio-benefits-col">
            <div className="benefit-icon">
              <img src="images/comunity.svg" alt="" />
            </div>
            <div className="studio-benefits-header">A Community</div>
            <div className="studio-benefits-content">
              Pocket Studio is for aspiring Writers and Voice Over Artists to
              help them publish and produce stories to react over 1 crore
              audience at Pocket FM.
            </div>
          </div>
          <div className="studio-benefits-col">
            <div className="benefit-icon">
              <img src="images/opportunity.svg" alt="" />
            </div>
            <div className="studio-benefits-header">An Opportunity</div>
            <div className="studio-benefits-content">
              Over 1000 original pocket studio stories have been published and
              produced into Audiobooks on Pocket FM so far, but the search
              hasn’t just ended yet.
            </div>
          </div>
          <div className="studio-benefits-col">
            <div className="benefit-icon">
              <img src="images/dream.svg" alt="" />
            </div>
            <div className="studio-benefits-header">A Dream</div>
            <div className="studio-benefits-content">
              follow your passion with compassion, build your audience, and get
              published.
            </div>
          </div>
        </div>
        <div className="studio-benefits-image">
          <img
            src="images/what-is-studio-illustration.png"
            alt="what-is-studio-illustration"
          />
        </div>
      </div>
      <div className="welcome-part-3">
        <div className="section writers-section">
          <div className="first-half-section part-half">
            <div className="heading">For Writers</div>
            <div className="content">
              Pocket Studios works with aspiring, talented and fresh writers to
              establish virtuoso connections around the world.
              <br />
              <br />
              Over 1000 original pocket studio stories have been published and
              produced into Audiobooks on Pocket FM so far, but the search
              hasn’t just ended yet.
              <br />
              <br />
              Follow your passion with compassion, build your audience, and get
              published. Because “If you don't have a story, you will become a
              part of someone else’s story”.
            </div>
          </div>
          <div className="second-image-section">
            <img src="images/for-writer.svg" />
          </div>
        </div>
      </div>
      <div className="divider-red">
        <img src="images/divider-red.svg" />
      </div>
      <div className="welcome-part-4">
        <div className="section voice-artist">
          <div className="first-half-section part-half-2">
            <div className="heading">For Voice Over Artists</div>
            <div className="content">
              'Meri awaaz hi Pehchaan hai', especially for voice-over artists
              this could be your chance to make your voice reach every nook and
              corner. <br />
              <br />
              Pocket Studio offers you to be the Storyteller. If Story writing
              is an art then the VO's are the artist who drew the attention of
              the masses by their voice strokes and paint the big picture for
              the crowd. <br />
              <br />
              Nobody can imagine a story without a voice that persuades them to
              do so. "Be the Voice that loves to be heard."
            </div>
          </div>
          <div className="second-image-section part-second-half">
            <img src="images/for-vo.png" alt="Voice Artist" />
          </div>
        </div>
      </div>
      <div className="welcome-part-5">
        <div className="welcome-community">
          <div className="center-heading">Massive Community</div>
          <div className="center-content">
            We Connect with the people, for the people.
          </div>
          <div className="community-boxes">
            <div className="pen-box">
              <div className="icon">
                <img src="images/book_with_circle.svg" alt="Pen Icon" />
              </div>
              <div className="image-content">
                <div className="count-header">5000+</div>
                <div className="count-content">Audiobooks</div>
              </div>
            </div>
            <div className="pen-box hover-box">
              <div className="icon">
                <img src="images/pen_with_circle.svg" alt="Pen Icon" />
              </div>
              <div className="image-content">
                <div className="count-header">3000+</div>
                <div className="count-content">Writers</div>
              </div>
            </div>
            <div className="pen-box">
              <div className="icon">
                <img src="images/mic_with_circle.svg" alt="Pen Icon" />
              </div>
              <div className="image-content">
                <div className="count-header">1500+</div>
                <div className="count-content">Voice Artists</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="helping-people">
        <img src="images/helping_people.svg" alt="" />
      </div>
      <div className="welcome-part-6">
        {!isMobile && (
          <div className="sucess-story">
            <div className="center-heading">Success Stories</div>
            <div className="center-content">
              Behind every story there is a person’s story, this is their
              SUCCESS STORY.
            </div>
          </div>
        )}
        <div className="writer-stories">
          <div className="story-1">
            <div className="story-first-section">
              <div className="comma">
                <img src="images/quote.svg" alt="" />
              </div>
              <div className="story-content">
                After completing one story when my next story-writing journey
                began the response was shocking. I never imagined in a span of 4
                months it would reach 13Million people and they will love it.
                It's like a dream come true for me. Pocket FM made my story
                reach millions of people, gave me a fanbase and lovely
                listeners. I get recognized as a writer and also I have got lots
                of writing opportunities. A bang on Kickstart that worked for
                me, for my career, and my future. We should enjoy every step in
                our life and when there are millions of people supporting you
                its double fun.
              </div>
              <div className="writer-description">
                <div className="writer-name">
                  <div className="name">Vibha Vyas</div>
                </div>
                {!isMobile && <div className="horizontal-divider"></div>}
                <div className="writer-book">
                  <div className="book-image">
                    <img src="http://djhonz7dexnot.cloudfront.net/4e11bdac649534488da888251e0070614c822185.webp" />
                  </div>
                  <div className="book-description">
                    <div className="book-name">Billionaire Hired Wife</div>
                    <div className="book-plays">17.6M Plays</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="story-second-section">
              <img src="images/Vibha-Vyas.png" alt="" />
            </div>
          </div>
          <div className="story-1 story-left">
            <div className="front-img">
              <img src="images/Anand.png" alt="" />
            </div>
            <div className="story-first-section">
              <div className="comma">
                <img src="images/quote.svg" alt="" />
              </div>
              <div className="story-content">
                For me working with Pocket FM is like I'm working for my dreams,
                it's my dream job. I never thought people will like my story and
                the way people respond motivates me to write more. Pocket FM has
                provided a platform for young emerging writers who want to
                pursue writing as a career. I didn't let go my interest for
                writing, and Pocket FM has a great range of audiences where
                people connect with you. They listen to your story on a daily
                basis and even connect through different social media platforms.
                Their support is pushing me forward towards my dream of writing
              </div>
              <div className="writer-description">
                <div className="writer-name">
                  <div className="name">Anand Borkar</div>
                </div>
                {!isMobile && <div className="horizontal-divider"></div>}
                <div className="writer-book">
                  <div className="book-image">
                    <img src="http://djhonz7dexnot.cloudfront.net/2b1822b9adf62f0238871cc2e7d4b2509851d94f.webp" />
                  </div>
                  <div className="book-description">
                    <div className="book-name">Yakshini</div>
                    <div className="book-plays">40.9M Plays</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="story-1 story-bottom">
            <div className="story-first-section">
              <div className="comma">
                <img src="images/quote.svg" alt="" />
              </div>
              <div className="story-content">
                I started working with Pocket FM in November last year. The
                story I have written is 'Substitute Bride' and after 8 months of
                hard work, my story has reached millions of people. Pocket FM
                has helped me a lot to learn and gain. They have given me a
                wonderful opportunity and wings to my story.
                
              </div>
              <div className="writer-description">
                <div className="writer-name">
                  <div className="name">Sakshi Sinha</div>
                </div>
                {!isMobile && <div className="horizontal-divider"></div>}
                <div className="writer-book">
                  <div className="book-image">
                    <img src="http://djhonz7dexnot.cloudfront.net/ce19a933e179f066b85115fdc26db78b4b2ff2ee.webp" />
                  </div>
                  <div className="book-description">
                    <div className="book-name">Substitute Bride</div>
                    <div className="book-plays">27.6M Plays</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="story-second-section">
              <img src="images/sakshi.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="join-ill">
        <img
          src={
            isMobile
              ? 'images/join_illustration_mobile.svg'
              : 'images/join_ill.svg'
          }
          alt=""
        />
      </div>
      <div className="welcome-part-7">
        <div className="account-center-div">
          <div className="account-content">
            Join the Largest Community of Authors and Voice Over Artists
          </div>
          <div>
            <div className="account-button" onClick={handleClick}>
              <div className="button-content">Create Account for free</div>
            </div>
          </div>
        </div>
        <div className="shows">
          <img src="images/shows.png" alt="Shows" />
        </div>
      </div>
    </div>}
    </>
  )
}

export default LandingPage
