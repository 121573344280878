/* eslint no-underscore-dangle: 0 */
import React, { useState } from 'react'
import './style.css'
import MyTooltip from '../Tooltip'
import { post } from '../../lib/api'

function CommentBox({
  profileName,
  profileImage,
  // setContent,
  setShowCommentBox,
  // setCommentedList,
  selectedValue,
  // commentedList,
  // content,
  chapterId,
  // getAllComments,
  bookId,
  updateCommentsFile,
}) {
  const [btnDisable, setBtnDisable] = useState(true)
  const [inputVal, setInputVal] = useState('')

  const handleCancelClick = () => {
    setShowCommentBox(false)
  }

  const handleCommentClick = async () => {
    const editor = window.tinymce.activeEditor
    if (!editor.selection.getContent().trim().length) return

    post('/content_api/book.create_chapter_comment', {
      data: {
        chapter_id: chapterId,
        comment: inputVal,
        props: {
          selected_text: selectedValue,
          profile_image: profileImage,
          length: selectedValue.length,
          profile_name: profileName,
        },
        is_edited: false,
        book_id: bookId,
      },
    }).then((response) => {
        const { result } = response
        editor.selection.setContent(
          `<span style='background-color: #F6BE00' class='highlight' id='${
            result._id
          }'>${editor.selection.getContent()}</span>`
        )
        updateCommentsFile()
      })
      .catch((error) => {
        throw error
      })
    setShowCommentBox(false)
  }

  const handleChange = (e) => {
    const text = e.target.value
    if (text.length >= 0) {
      setBtnDisable(false)
      setInputVal(text)
    }
  }
  return (
    <div className="box">
      <div className="ProfilePicInner">
        <div className="ProfileImageContainer">
          <img className="ProfileImage" src={profileImage} alt="profileImage" />
        </div>
        <div className="NameSpan">{profileName || ''}</div>
      </div>
      <div className="CommentField">
        <textarea
          value={inputVal}
          onChange={handleChange}
          className="inputField"
          //   autoFocus
          placeholder="Comment"
        />
      </div>
      <div className="btn">
        <MyTooltip title="Post Comment" placement="bottom">
          <span>
            <button
              onClick={handleCommentClick}
              type="button"
              style={{
                backgroundColor: !btnDisable ? '#068ae2' : null,
                color: !btnDisable ? 'white' : null,
              }}
              disabled={btnDisable}
              className="btnComment"
            >
              Comment
            </button>
          </span>
        </MyTooltip>
        <MyTooltip title="Discard Comment" placement="bottom">
          <button
            className="btnCancel"
            type="button"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </MyTooltip>
      </div>
    </div>
  )
}

export default CommentBox
