import React from 'react'

const Camera = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path fill="#fff" fillOpacity=".9" d="M13.768 10.857c.976.977.976 2.56 0 3.536-.977.976-2.56.976-3.536 0-.976-.977-.976-2.56 0-3.536s2.56-.976 3.536 0z"/>
    <path fill="#fff" fillOpacity=".9" d="M19.5 5.75h-1.727l-1.251-2.5H7.477L6.227 5.75l-1.724.003c-1.376.003-2.495 1.123-2.496 2.499L2 18.25c0 1.378 1.121 2.5 2.5 2.5h15c1.379 0 2.5-1.12 2.5-2.5v-10c0-1.379-1.121-2.5-2.5-2.5zM12 17.625c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"/>
  </svg>
)

export default Camera
