import { useState } from 'react'
import { get } from '../api'

function usePaginationNavigator() {
  const [currentPaginationNo, setCurrentPaginationNo] = useState(1)
  const [episodeRange, setEpisodeRange] = useState([])
  const [episodesCount, setEpisodesCount] = useState()
  const [showPaginationDrawer, setShowPaginationDrawer] = useState(false)
  const [showPreviousLoader, setShowPreviousLoader] = useState(false)

  const LoadPaginatedPage = (url, params, setLoader, callback) => {
    setLoader(true)
    get(url, { params })
      .then((response) => {
        callback(response)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        throw error
      })
  }
  const handleEpisodeData = (episodes_count, order, end_count = 1) => {
    const episodeRangeData = []
    if (order === 'reverse') {
      for (let x = episodes_count; x >= end_count; x -= 10) {
        episodeRangeData.push({ min: x, max: x - 10 < 0 ? 1 : x - 9 })
      }
    } else {
      for (let x = end_count; x <= episodes_count; x += 10) {
        episodeRangeData.push({
          min: x,
          max: x + 10 > episodes_count ? episodes_count : x + 9,
        })
      }
    }
    setEpisodeRange(episodeRangeData)
  }

  return {
    currentPaginationNo,
    setCurrentPaginationNo,
    episodeRange,
    handleEpisodeRangeData: handleEpisodeData,
    episodesCount,
    setEpisodesCount,
    showPaginationDrawer,
    setShowPaginationDrawer,
    showPreviousLoader,
    setShowPreviousLoader,
    handlePaginationApiCall: LoadPaginatedPage,
  }
}

export default usePaginationNavigator
