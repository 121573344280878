import React, { useState, useEffect, useRef } from 'react'
import queryString from 'query-string'
import SubHeader from '../../../../../components/WithSubHeaderBG'
import StepIndicator from '../../../../../components/StepIndicator'
import { get, put } from '../../../../../lib/api'
import styled from 'styled-components'
import EmptyState from './emptyState'
import AddNewEpisode from './addEditEpisode'
import ViewDetails from './viewDetails'
import Loader from '../../../../../components/Loader'
import EpisodeListing from './episodeList'
import AddIcon from '../../../../../components/Images/AddIcon'
import { ArrowRight } from '../New/styles'
import Tooltip from '../../../../../components/Tooltip'
import { Link } from 'react-router-dom'
import { useDidUpdate, useInfiniteScroll } from '../../../../../lib/customHooks'
import ClampLines from 'react-clamp-lines'
import Vellip from '../../../../../components/Images/Vellip'
import PencilIcon from '../../../../../components/Images/PencilIcon'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Popup } from '../../../../../components/Popup'
import { Box, Text, Image } from '../../../../../designSystem'

const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);

    .clamp-lines__button {
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0;
      font-weight: 600;
      line-height: 1.8;
      color: var(--Text500);
      outline: none;
    }
`
const BookCategory = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text300);
  margin-top: 5px;
`
const EditBookDiv = styled.div`
  background-color: #f0f1f5;
  cursor: pointer;
`
const EditButton = styled(Link)`
  border: none;
  outline: none;
  background: none;
  padding: 16px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`
const EditBookSpan = styled.span`
  margin-left: 8px;
`

const SetUpAuditionDiv = styled.div`
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  padding: 16px 24px;
`
const SetupAuditionSpan = styled.span`
  margin-left: 8px;
`

const VellipIcon = styled(Vellip)`
  display: block;
`

const VellipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border-radius: 4px;

  :hover {
    background-color: #ebecf1;
  }
`
const AddAnotherEpisode = styled.div`
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Dove);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`
const AddAnotherCTA = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Azure500);
  margin-left: 14px;
`
const ActionButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`
const GoBackButton = styled.button`
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Azure500);
  margin-right: 40px;
  border: none;
  cursor:pointer;
`
const ContinueButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  opacity: ${({ isValid }) => isValid ? '1' : '0.3'};
  border-radius: 4px;
  background-color: var(--Azure500);
`
const SubmitDiv = styled.div`
  padding: 56px;
  background-color: var(--Dove);
  margin-bottom: 40px;
`
const SubmitTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
  margin-bottom: 8px;
  text-align: center;
`
const SubmitText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  text-align: center;
  max-width: 651px;
  margin: auto;
  margin-bottom: 32px;
`
const OkayButtonDiv = styled.div`
  text-align: center;
`
const OkayButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 16px 40px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
`
const BookOptionsBox = styled.div`
  display: ${({ show }) => show ? 'block' : 'none'};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  width: 224px;
`

const Edit = ({
  match: {
    params: { id },
  },
  location: { search },
  history
}) => {
  const qParams = queryString.parse(search)
  const { process } = qParams
  const [book, setBookDetails] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isAddNewBoxShown, toggleAddNewBox] = useState(false)
  const [isViewDetailsSelected, toggleViewDetails] = useState(false)
  const [isSubmitNoticeShown, setSubmitNotice] = useState(false)
  const [currentStep, setCurrentStep] = useState(2)
  const [isBookOptionsShown, toggleShowBookOptions] = useState(false)
  const bookOptionsRef = useRef()
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const [isMetaInfoExists, setIsMetaInfoExists] = useState(false)
  const isMobile = window.screen.width < 600
  useEffect(() => {
    fetchBookMeta()
    fetchData()
  }, [])

  useDidUpdate(() => {
    setLoading(false)
  }, [book])

  const fetchBookMeta = () => {
    get('/content_api/book.audition_meta', {
      params : {
        book_id: id,
        view: 'dashboard'
      }
    }).then(() => {
      setIsMetaInfoExists(true)
    }).catch(error => {
      if (error.code === 401) throw error
    })
  }

  const fetchData = () => {
    setLoading(true)
    const bookDetailsApi =
      '/content_api/book.book_details'
    get(bookDetailsApi, { params: { book_id: id, view: 'dashboard', info_level: 'max', is_novel: 0 } })
      .then((response) => {
        const { results } = response
        const { next_url } = results
        setBookDetails(results)
        setNextUrl(next_url)
      })
  }
  function fetchMoreData (setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { results } = response
        const { next_url, chapters } = results
        setBookDetails(prevState => ({...results, chapters: prevState.chapters.concat(chapters)}))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }
  const successAddEpisodeCB = () => {
    toggleAddNewBox(false)
    fetchData()
  }

  const successEditEpisodeCB = () => {
    fetchData()
  }

  const handleBookSubmit = () => {
    const { length } = book.chapters || []
    if (length < 5) return
    const updateStatusApi = '/content_api/book.update_book_status'
    put(updateStatusApi, { data: {book_id: id, status: 'pending', view: 'dashboard'} }).then(res => {
      setSubmitNotice(true)
      setCurrentStep(3)
      fetchData()
    })
  }

  const handleClick = (e) => {
    if (bookOptionsRef.current && !bookOptionsRef.current.contains(e.target)) {
      toggleShowBookOptions(false)
    }
  }

  const ViewDetailButton = styled.button`
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: var(--Azure500);
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--Dove);
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
  `

  const onViewDetails = () => {

  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const { book_title, image_url, created_for, category_obj, chapters, chapter_count, description, status, language } = book || {}
  const createBookMode = process === 'createNew'
  return isLoading ? (
    <div style={{ marginTop: '200px' }}>
      <Loader />
    </div>
  ) : (
    <SubHeader
      title={createBookMode ? "Submit New Audiobook" : 'Edit Audiobook'}
      showBackButton
    >
      <Box variant="flexCard" p="16px" borderTopLeftRadius="4px" borderTopRightRadius="4px" flexDirection={["column", "row"]}>
        <Text variant="heading">
          {createBookMode ? "Submit New Audiobook" : 'Audiobook Details'}
        </Text>

        {createBookMode && <StepIndicator
          steps={['Add Details', 'Add Episodes', 'Submit']}
          current={currentStep}
        />}
      </Box>
      <Box variant="hr"/>
      <Box p={[16, 24]} mb={[24, 32]} position="relative" bg="surface.foreground" >
        <Box variant="flex" justifyContent="flex-start" flexDirection={["column","row"]}>
          {!isMobile && <Image height={[60,144]} width={[60,144]} src={image_url} mr={[8, 24]}/>}
          {isMobile && <Box variant="flex" flexDirection="row" justifyContent="flex-start">
            <Image height={[60,144]} width={[60,144]} src={image_url} mr={[8, 24]}/>
            <Text variant="titleSecondary">{ book_title }</Text>
          </Box>}
          <Box mt={[8,0]}>
            {!isMobile && <Text variant="titleSecondary">{ book_title }</Text>}
            <BookDescription>
              <ClampLines
                lines={2}
                text={description || 'No description available'}
                id="unpublished-show-details-description"
              />
            </BookDescription>
            {category_obj.topic_name && <BookCategory>{ category_obj.topic_name }</BookCategory>}
            {book?.book_docs?.length > 0 && <ViewDetailButton type='button' onClick={() => toggleViewDetails(true)}>
              View Documents
            </ViewDetailButton>}
          </Box>
          <VellipContainer ref={bookOptionsRef}>
            <VellipIcon onClick={() => toggleShowBookOptions(!isBookOptionsShown)}/>
            <BookOptionsBox show={isBookOptionsShown}>
              <EditBookDiv>
                <EditButton to={`${menuItemObj.audiobooks}/edit-book-details/${id}${createBookMode ? '?process=createNew' : ''}`} >
                  <PencilIcon/>
                  <EditBookSpan>
                    Edit Audiobook
                  </EditBookSpan>
                </EditButton>
              </EditBookDiv>
            </BookOptionsBox>
          </VellipContainer>
        </Box>
      </Box>
      {!isAddNewBoxShown && chapters && !chapters.length && (
        <EmptyState onAddNew={() => toggleAddNewBox(true)} />
      )}
      {((isAddNewBoxShown && (chapters && !chapters.length)) || (chapters && !!chapters.length)) && (
        <Text variant="title" fontSize={20} mb={10} ml={[16, 0]}>
          { chapters && !chapters.length
            ? 'Add New Episode'
            : `${chapter_count || chapters.length} ${
              (chapter_count || chapters.length) > 1 ? 'Episodes' : 'Episode'
              }`}
        </Text>
      )}
      <EpisodeListing
        bookData={book}
        thumbnail={image_url}
        book_id={id}
        setBookDetails={setBookDetails}
        created_for={created_for}
        successCallback={successEditEpisodeCB}
        book_type={category_obj.topic_name === 'audiobook' ? "Audiobook" : "Audiobook Series"}
        language={language}
      />
      {isFetching && <Loader />}
      {isAddNewBoxShown && (
         
        <Popup
          show={true}
          className="right-drawer-episode-form"
          close={() => toggleAddNewBox(false)}
        >
          <AddNewEpisode
            book_id={id}
            created_for={created_for}
            successCallback={successAddEpisodeCB}
            episodeNumber={(chapters && chapters.length + 1) || 1}
            handleCancel={() => toggleAddNewBox(false)}
            book_type={category_obj.book_type === 'audiobook' ? "Audiobook" : "Audiobook Series"}
            language={language}
          />
        </Popup>
        
      )}
      {isViewDetailsSelected && (
         
        <Popup
          show={true}
          className="right-drawer-episode-form"
          close={() => toggleViewDetails(false)}
        >
          <ViewDetails
            book={book}
            handleCancel={() => toggleViewDetails(false)}
          />
        </Popup>
        
      )}
      {chapters  && (
        <Tooltip
          title={isAddNewBoxShown ? 'Please save previous episode to add another episode' : ''}
          arrow
        >
          <AddAnotherEpisode onClick={() => toggleAddNewBox(true)}>
            <AddIcon/>
            <AddAnotherCTA>
              Add Another Episode
            </AddAnotherCTA>
          </AddAnotherEpisode>
        </Tooltip>
      )}
      {(status === 'draft' || status === 'Draft') && <ActionButtonDiv>
        <GoBackButton type='button' onClick={()=>history.goBack()}>
          Go Back
        </GoBackButton>
        <Tooltip title={chapters.length > 4 ? '' : 'Add at least 5 episodes to continue'} arrow>
          <ContinueButton onClick={handleBookSubmit} isValid={chapters.length > 4} type="button">Continue <ArrowRight/></ContinueButton>
        </Tooltip>
      </ActionButtonDiv>}
      {isSubmitNoticeShown && (
        <SubmitDiv>
          <SubmitTitle>
            Audiobook Submitted
          </SubmitTitle>
          <SubmitText>
            You audiobook has been submitted succesfully, You will recieve email and SMS once your Audiobook is reviewed.
          </SubmitText>
          <OkayButtonDiv>
            <OkayButton type='button' onClick={() => setSubmitNotice(false)}>
              OKAY
            </OkayButton>
          </OkayButtonDiv>
        </SubmitDiv>
      )}
    </SubHeader>
  )
}

export default Edit
