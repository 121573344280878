

import React from 'react'

export default function Index() {
  return (
    
<svg width="289" height="238" viewBox="0 0 289 238" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#0gqgcso9ya)">
        <path d="M40.552 156.239s9.689-58.546 42.072-60.803c-3.887 23.671-18.85 50.245-35.173 61.914-16.317 11.669-6.906-1.111-6.906-1.111h.007z" fill="#FA7167"/>
        <path d="M42.474 157.35a.328.328 0 0 1-.138-.028.325.325 0 0 1-.16-.43c.215-.473 21.73-47.03 35.881-57.97a.32.32 0 0 1 .451.056.32.32 0 0 1-.055.451c-14.026 10.843-35.472 57.261-35.687 57.727a.325.325 0 0 1-.291.187v.007z" fill="#fff"/>
        <path d="M69.472 109.195h-.014a.317.317 0 0 1-.305-.341l.368-7.501c.007-.181.138-.313.34-.306.18.007.32.16.305.34l-.368 7.502a.322.322 0 0 1-.326.306zM60.242 123.379a.322.322 0 0 1-.32-.278l-1.415-10.509a.317.317 0 0 1 .277-.361.32.32 0 0 1 .361.278l1.416 10.509c.02.18-.097.34-.278.361h-.041zM52.767 136.742a.322.322 0 0 1-.32-.277L50.9 125.421a.326.326 0 1 1 .645-.09l1.548 11.043a.322.322 0 0 1-.278.368h-.048zM47.305 147.342a.326.326 0 0 1-.32-.257l-1.38-6.738a.325.325 0 0 1 .25-.382.325.325 0 0 1 .381.25l1.381 6.738a.325.325 0 0 1-.25.382h-.062v.007zM49.13 143.722a.32.32 0 0 1-.312-.25.325.325 0 0 1 .243-.389l12.395-2.848a.325.325 0 0 1 .146.632l-12.395 2.848s-.049.007-.07.007h-.007zM56.702 129.519a.327.327 0 0 1-.32-.264.319.319 0 0 1 .265-.375l13.457-2.417a.314.314 0 0 1 .374.264.318.318 0 0 1-.263.375l-13.457 2.417h-.056zM64.413 116.593a.332.332 0 0 1-.32-.264.33.33 0 0 1 .257-.382l10.605-2.021a.328.328 0 0 1 .382.257.33.33 0 0 1-.257.382l-10.605 2.021h-.062v.007z" fill="#fff"/>
        <path d="M42.072 157.516S32.383 98.97 0 96.713c3.887 23.671 18.85 50.246 35.173 61.915 16.317 11.669 6.906-1.112 6.906-1.112h-.007z" fill="#FA7167"/>
        <path d="M40.156 158.627c.049 0 .09-.007.14-.027.159-.077.235-.264.159-.431-.215-.472-21.73-47.03-35.881-57.97a.32.32 0 0 0-.451.056.32.32 0 0 0 .055.451c14.026 10.843 35.472 57.262 35.687 57.727a.325.325 0 0 0 .291.187v.007z" fill="#fff"/>
        <path d="M13.159 110.472h.014c.18-.007.319-.16.305-.34l-.368-7.502c-.007-.181-.139-.312-.34-.306a.317.317 0 0 0-.305.341l.368 7.501a.322.322 0 0 0 .326.306zM22.39 124.656c.159 0 .298-.118.319-.278l1.415-10.509a.318.318 0 0 0-.277-.361.32.32 0 0 0-.361.278l-1.416 10.509c-.02.181.097.34.278.361h.041zM29.864 138.02c.16 0 .298-.118.32-.278l1.547-11.044a.326.326 0 1 0-.645-.09l-1.548 11.044c-.028.18.097.34.277.368h.049zM35.32 148.619a.326.326 0 0 0 .318-.257l1.381-6.737a.324.324 0 0 0-.25-.382.324.324 0 0 0-.381.25l-1.381 6.737a.324.324 0 0 0 .25.382h.062v.007zM33.494 144.999a.32.32 0 0 0 .312-.25.326.326 0 0 0-.243-.389l-12.395-2.847a.32.32 0 0 0-.389.243.326.326 0 0 0 .243.389l12.395 2.848s.049.006.07.006h.007zM25.922 130.797a.328.328 0 0 0 .32-.264.318.318 0 0 0-.265-.375L12.52 127.74a.316.316 0 0 0-.374.264.318.318 0 0 0 .263.375l13.457 2.418h.056zM18.218 117.871a.334.334 0 0 0 .32-.264.329.329 0 0 0-.257-.382l-10.605-2.022a.33.33 0 0 0-.382.257.329.329 0 0 0 .257.382l10.605 2.022h.062v.007z" fill="#fff"/>
        <path d="M40.163 152.398s-22.93-54.726 3.29-73.882c9.3 22.108 10.758 52.579 3.151 71.145-7.614 18.566-6.434 2.737-6.434 2.737h-.007z" fill="#FCB8B3"/>
        <path d="M42.384 152.314c-.041.021-.083.042-.132.049a.323.323 0 0 1-.36-.278c-.07-.514-6.615-51.392-.452-68.187a.327.327 0 0 1 .417-.194c.166.062.257.25.194.416-6.107 16.649.416 67.367.486 67.881a.349.349 0 0 1-.146.32l-.007-.007z" fill="#fff"/>
        <path d="M39.629 97.165h-.014a.316.316 0 0 1-.444-.118l-3.678-6.55c-.09-.152-.049-.34.124-.444a.324.324 0 0 1 .445.125l3.678 6.55a.322.322 0 0 1-.111.43v.007zM39.358 114.092a.325.325 0 0 1-.423-.069l-6.78-8.141a.322.322 0 0 1 .04-.458.328.328 0 0 1 .459.041l6.78 8.141a.322.322 0 0 1-.041.458c-.014.007-.02.021-.035.028zM40.136 129.386a.32.32 0 0 1-.417-.062l-7.183-8.53a.322.322 0 0 1 .042-.458.33.33 0 0 1 .458.041l7.183 8.53a.322.322 0 0 1-.042.458c-.014.007-.02.021-.035.028l-.006-.007zM41.156 141.272a.317.317 0 0 1-.403-.049L36 136.25a.327.327 0 0 1 .007-.458.327.327 0 0 1 .458.006l4.754 4.974a.327.327 0 0 1-.007.458.383.383 0 0 1-.055.042zM40.774 137.236a.335.335 0 0 1-.402-.042.327.327 0 0 1 0-.458l8.98-9.009a.32.32 0 0 1 .458 0 .328.328 0 0 1 0 .459l-8.98 9.008s-.035.035-.056.049v-.007zM39.629 121.177a.319.319 0 0 1-.41-.056.327.327 0 0 1 .021-.458l10.105-9.217a.32.32 0 0 1 .458.021.327.327 0 0 1-.02.458l-10.105 9.217s-.028.028-.049.035zM39.282 106.131a.318.318 0 0 1-.41-.055.322.322 0 0 1 .014-.459l7.905-7.362a.327.327 0 0 1 .458.014.321.321 0 0 1-.014.458l-7.904 7.363s-.035.027-.049.034v.007z" fill="#fff"/>
        <path d="M55.3 170.297c-1.645-8.738 4.116-18.295 4.116-18.295H22.95s5.76 9.557 4.116 18.295c-1.645 8.738-10.868 18.955-8.398 35.597 2.471 16.642 12.514 21.921 12.514 21.921h20.002s10.042-5.272 12.513-21.921c2.47-16.642-6.753-26.859-8.398-35.597z" fill="#FCB8B3"/>
        <path d="M44.092 220.217c9.175-15.337 11.66-38.89-5.83-46.669-4.053-1.667-8.19-1.757-11.903-.584-2.672 8.176-9.918 17.948-7.697 32.93 2.471 16.643 12.514 21.921 12.514 21.921h6.107c2.818-1.722 4.858-4.341 6.802-7.591l.007-.007z" fill="#FA7167"/>
        <path d="M187.887 0h-53.912v65.742h53.912V0zM250.156 0h-53.912v65.742h53.912V0zM187.887 74.334h-53.912v65.742h53.912V74.334zM250.155 74.334h-53.912v65.742h53.912V74.334z" fill="#E5E5E5"/>
        <path d="M276.043 41.91h-22.154l-12.957 41.16H289l-12.957-41.16z" fill="url(#edle76ve5b)"/>
        <path d="m252.487 83.07 7.787-41.16h-6.385l-12.957 41.16" fill="#94BDEE"/>
        <path d="M266.257 74.334h-2.589v142.153h2.589V74.334z" fill="url(#burbuedmwc)"/>
        <path d="M283.649 214.758h-37.366a2.701 2.701 0 0 0 0 5.404h37.366a2.701 2.701 0 0 0 0-5.404z" fill="url(#na23qddqhd)"/>
        <path d="M146.19 237.914c64.977 0 117.652-3.368 117.652-7.523 0-4.154-52.675-7.522-117.652-7.522s-117.652 3.368-117.652 7.522c0 4.155 52.675 7.523 117.652 7.523z" fill="#E5E5E5"/>
        <path d="m222.873 181.784 22.556 47.023a2.785 2.785 0 0 0 2.519 1.584h.625c1.908 0 3.255-1.875 2.644-3.688l-17.33-51.406-11.007 6.48-.007.007zM81.916 181.784 59.36 228.807a2.785 2.785 0 0 1-2.52 1.584h-.624c-1.908 0-3.255-1.875-2.644-3.688l17.33-51.406 11.007 6.48.007.007z" fill="#FA7167"/>
        <path d="m152.395 204.609-98.983-20.06v-41.716c0-13.301 10.772-24.081 24.062-24.081h50.859c13.29 0 24.062 10.78 24.062 24.081v61.783-.007z" fill="#94BDEE"/>
        <path d="m152.395 209.144-98.983-20.059v-41.717c0-13.301 10.772-24.081 24.062-24.081h50.859c13.29 0 24.062 10.78 24.062 24.081v61.783-.007z" fill="url(#bvez3fr2ce)"/>
        <path d="m152.395 204.609 98.982-20.06v-41.716c0-13.301-10.772-24.081-24.062-24.081h-50.859c-13.29 0-24.061 10.78-24.061 24.081v61.783-.007z" fill="url(#r7g74llt1f)"/>
        <path d="m152.395 209.144 98.982-20.059v-41.717c0-13.301-10.772-24.081-24.062-24.081h-50.859c-13.29 0-24.061 10.78-24.061 24.081v61.783-.007z" fill="#94BDEE"/>
        <path d="M152.395 188.383c-47.833 0-88.836-17.649-106.221-42.765-2.228-3.223-7.274-2.181-8.044 1.66a43.16 43.16 0 0 0-.847 8.474c0 38.458 51.539 69.638 115.112 69.638 63.572 0 115.111-31.18 115.111-69.638 0-2.869-.292-5.696-.847-8.474-.77-3.841-5.816-4.883-8.044-1.66-17.385 25.116-58.395 42.765-106.22 42.765z" fill="url(#zlr725ga2g)"/>
        <path d="M152.395 215.348c-60.763 0-110.524-28.485-114.806-64.582a42.568 42.568 0 0 0-.306 4.987c0 38.459 51.539 69.638 115.112 69.638 63.572 0 115.111-31.179 115.111-69.638a42.54 42.54 0 0 0-.305-4.987c-4.283 36.097-54.044 64.582-114.806 64.582z" fill="#94BDEE"/>
        <path d="M144.17 157.724s-3.886-5.452-4.455-8.328c-3.561 1.744-13.763 6.523-13.763 6.523l16.351 5.369 1.86-3.557.007-.007z" fill="#FA7167"/>
        <path d="M142.761 158.585s-2.533-3.751-3.046-6.376c-1.437.111-2.756.243-2.756.243l-.569 5.994 4.185 1.327 2.186-1.188z" fill="#D1D1D1"/>
        <path d="M242.007 161.211s1.347-.798 3.443-1.472c.597-.188 6.163-5.668 6.163-5.668.236-2.605-1.964-13.753-3.644-16.434-.77-.5-2.908 2.459-2.367 6.064.542 3.598.382 8.654-.499 10.176-.882 1.521-4.157 1.854-4.157 1.854l1.068 5.487-.007-.007z" fill="#FFC9A4"/>
        <path d="M140.846 165.234s41.204 1.972 51.663-1.459c9.974-2.626 10.709-1.174 13.208-1.368 2.498-.195 33.57-5.765 35.999-7.87 2.103 2.431 2.457 6.237 2.457 6.237s-19.787 12.67-31.926 13.156c-2.005-.077-8.203 2.702-8.203 2.702s-26.012 14.371-59.312 9.995c2.915-13.447-3.886-21.386-3.886-21.386v-.007z" fill="#585858"/>
        <path d="M147.585 186.953c1.957-12.336-4.227-19.559-4.227-19.559s41.205 1.972 51.664-1.459c9.973-2.625 10.709-1.174 13.207-1.368 2.318-.181 29.246-4.987 35.048-7.363-.36-.917-.867-1.861-1.561-2.667-2.429 2.105-33.501 7.675-35.999 7.87-2.499.194-3.228-1.258-13.208 1.368-10.459 3.431-51.663 1.459-51.663 1.459s6.801 7.939 3.886 21.386c.958.125 1.902.236 2.846.326l.007.007z" fill="#3F3A2A"/>
        <path d="M200.921 171.873s1.985 1.417 2.512 4.293c.528 2.875 0 5.063 2.589 4.737 2.589-.327 16.92-6.009 19.183-8.224.326-.862-3.019-2.32-6.42-1.028-3.401 1.292-8.363 2.215-10.035 1.674-1.673-.542-2.7-3.675-2.7-3.675l-5.129 2.216v.007z" fill="#FFC9A4"/>
        <path d="M135.342 162.643s34.972-33.215 44.203-35.486c9.231-2.271 22.813 28.887 28.24 42.258-1.784 1.452-7.19 3.966-7.19 3.966s-19.426-22.852-19.593-29.672c-10.035 16.017-15.761 35.062-24.013 41.355-8.252 6.293-24.555 1.57-24.555 1.57l2.915-23.984-.007-.007z" fill="#3F3A2A"/>
        <path d="M135.342 162.642s34.868-29.005 44.092-31.276c9.23-2.272 22.917 24.671 28.344 38.042-1.784 1.451-7.19 3.966-7.19 3.966s-19.426-22.852-19.593-29.673c-10.035 16.017-15.761 35.063-24.013 41.355-8.252 6.293-24.555 1.57-24.555 1.57l2.915-23.984z" fill="#585858"/>
        <path d="M139.361 158.884s-15.081-3.709-44.251-3.861c-17.865-.091-19.752-5.752-19.752-5.752l-13.52.646s-4.04 5.307-4.012 7.759c.063 5.737 4.623 16.322 32.89 26.866 23.722 8.856 36.485 7.314 52.649 4.786-2.679-7.224-5.857-25.491-3.997-30.45l-.007.006z" fill="#EFEFEF"/>
        <path d="M139.361 158.884s-15.081-3.709-44.251-3.861c-17.865-.091-19.752-5.752-19.752-5.752l-13.52.646s-4.04 5.307-4.012 7.759c.063 5.737 4.623 16.322 32.89 26.866 23.722 8.856 36.485 7.314 52.649 4.786-2.679-7.224-5.857-25.491-3.997-30.45l-.007.006z" fill="#737373"/>
        <path d="M97.351 179c-28.26-10.55-32.82-21.129-32.89-26.866-.007-.639.27-1.479.674-2.368l-3.297.159s-4.04 5.307-4.012 7.759c.063 5.737 4.623 16.322 32.89 26.866 23.722 8.856 36.485 7.314 52.649 4.786-.444-1.202-.902-2.702-1.346-4.397-12.812 1.598-24.957 1.417-44.66-5.939h-.008z" fill="#9E9E9E"/>
        <path d="M51.191 144.119s-25.436 11.898-27.13 22.095c-1.977 11.898 28.727 7.654 43.905 5.605 3.047-.41 5.65-3.57 5.85-4.966.646-4.536-1.026-8.537-5.232-9.94-4.206-1.403-14.27 1.834-19.62 2.973 0 0 2.838-3.077 9.695-10.613-1.846-1.153-4.108-1.743-7.468-5.154z" fill="#7D7D7D"/>
        <path d="M28.969 165.067c5.184-8.425 17.003-15.802 23.965-14.906.52 0 1.88 1.264 2.394 2.765.985-1.084 2.089-2.293 3.331-3.661-1.846-1.153-4.108-1.743-7.468-5.154 0 0-25.436 11.898-27.129 22.095-.833 5.015 4.136 7.161 11.299 7.814a170.626 170.626 0 0 1-3.151-1.181c-2.589-1.299-4.532-4.535-3.241-7.779v.007z" fill="#737373"/>
        <path d="M57.826 139.86s1.11 8.272 4.88 13.815c1.353 1.987 6.537 1.827 8.557.528 2.769-1.785 4.178-3.438 4.178-3.438l-2.027-4.647s-12.805-8.613-15.588-6.251v-.007z" fill="#F7BB92"/>
        <path d="M72.123 123.968s3.068 3.813 3.672 7.161c.603 3.348 4.372 4.758 4.601 5.425.139 1.09-1.534 1.882-2.276 1.972-.09 2.98 1.624 7.349.79 9.072-.832 1.722-9.132 4.494-13.616.882-3.657-2.938-4.102-6.439-4.102-6.439s-2.36 1.278-3.477-.583c-1.117-1.862-1.346-5.814 1.118-6.377 2.464-.562 2.741.653 2.741.653s-4.227-4.466-2.651-8.231c1.575-3.764 10.868-6.126 13.2-3.535z" fill="#FFC9A4"/>
        <path d="M61.102 135.193c.278.341.465.542.465.542s-.076-.312-.465-.542z" fill="#FFC9A4"/>
        <path d="M80.396 136.554c-.229-.667-3.998-2.077-4.601-5.425-.604-3.347-3.672-7.161-3.672-7.161-1.707-1.903-7.155-1.139-10.563.889 3.144 2.091 7.517 4.14 10.473 5.918 1.242 2.001 5.504 9.669 3.56 15.503-.277 1.105-.673 2.438-1.332 3.612 2.374-.521 4.261-1.507 4.643-2.285.84-1.723-.881-6.092-.791-9.071.742-.091 2.415-.882 2.276-1.973l.007-.007z" fill="#FFD3B6"/>
        <path d="M64.996 127.393s8.987 1.542 12.062-2.028c-.86.375-1.728 0-1.728 0s2.054-.236 2.755-2.494c-.61.403-2.43.695-3.616.403 1.832-.34 3.4-1.209 5.129-4.827-3.075.305-9.446-.778-11.708.284 1.402-1.312 5.934-1.583 5.934-1.583s-11.549-1.243-14.79 2.375c-.812-.861 2.27-2.584 2.27-2.584s-8.53 1.556-9.717 8.523c-1.402-1.188-4.372-.861-4.372-.861s2.429 1.569 2.86 2.59c-.327 0-2.805.112-3.346 1.403.812-.27 2.7.813 2.804 1.084-2.86 3.021-3.255 13.586 7.377 18.691 3.63 1.945 3.769 1.577 3.769 1.577s-.562-4.202-1.853-7.488c-.014-.041-.014-.09-.042-.111-1.131-.799-1.61-2.195-1.7-2.827-.382-2.709.59-3.695 1.7-3.82 1.312-.139 2.547.632 3.151 1.799l1.221 2.362 1.756-.959s-2.186-3.251-1.214-4.466c.971-1.216 2.429-5.918 1.298-7.05v.007z" fill="#312808"/>
        <path d="M74.462 123.282c1.832-.341 3.4-1.209 5.13-4.828-3.076.306-9.447-.777-11.71.285 1.403-1.312 5.935-1.583 5.935-1.583s-11.549-1.244-14.79 2.375c-.812-.861 2.27-2.584 2.27-2.584s-7.94 1.452-9.564 7.814c4.567-5.647 13.547-1.187 19.974-2.472-1.298.646-2.589 1.944-4.532 2.59 1.943 0 3.886.646 5.83-.646-.986.987-1.964 2.709-3.519 3.487 2.714-.021 5.955-.486 7.565-2.354-.86.375-1.728 0-1.728 0s2.054-.237 2.755-2.494c-.61.403-2.429.695-3.616.403v.007z" fill="#312808"/>
        <path d="M72.179 135.255a6.546 6.546 0 0 1-1.978-.341.159.159 0 0 1-.097-.208c.034-.083.125-.132.208-.097.028.014 3.11 1.062 3.928-.764.035-.084.132-.118.215-.084.084.035.118.132.084.216-.452 1.007-1.437 1.271-2.353 1.271l-.007.007zM72.644 142.452a.16.16 0 0 0-.215.076.16.16 0 0 0 .076.215 6.264 6.264 0 0 0 2.714.625c1.575 0 2.81-.597 3.359-.916l-.063-.341c-.652.403-3.067 1.674-5.878.341h.007z" fill="#CC8640"/>
        <path d="M68.882 133.457s2.776-2.063 4.345-2.063c1.568 0 .971-1 .402-1.299-.569-.299-5.205 1.639-4.747 3.355v.007z" fill="#312808"/>
        <path d="m79.084 145.334.049.931c1.902 1.792 5.469 5.508 5.42 7.987-.013.605-.242 1.084-.707 1.459-3.672 1.702-7.225 1.834-10.55.41-8.592-3.689-13.054-16.649-13.096-16.781a.32.32 0 0 0-.41-.202.327.327 0 0 0-.2.41c.18.542 4.594 13.364 13.456 17.17a12.434 12.434 0 0 0 4.942 1.035c2.013 0 4.074-.486 6.17-1.466l.062-.041c.639-.5.972-1.167.986-1.98.055-3.014-4.373-7.348-6.122-8.939v.007z" fill="#FA7167"/>
        <path d="M112.786 185.224c-1.61 0-3.074-.424-4.351-1.424-5.719-4.452-5.788-11.002-5.851-16.267-.035-3.035-.062-5.661-1.041-7.494-2.672-5.043-17.344-3.73-17.496-3.716a.326.326 0 0 1-.354-.292.326.326 0 0 1 .291-.354c.618-.056 15.255-1.362 18.128 4.063 1.048 1.98 1.083 4.8 1.111 7.793.062 5.39.132 11.502 5.601 15.76 4.552 3.55 11.937-.882 19.758-5.57 6.566-3.939 13.354-8.016 18.531-7.036a.322.322 0 0 1 .257.375.327.327 0 0 1-.375.257c-4.928-.931-11.611 3.084-18.079 6.959-5.92 3.55-11.598 6.953-16.137 6.953l.007-.007z" fill="#FA7167"/>
        <path d="m145.975 167.706-.951.243a1.784 1.784 0 0 0-1.284 2.167l.632 2.487a1.782 1.782 0 0 0 2.165 1.285l4.768-1.223-5.337-4.966.007.007z" fill="#FA7167"/>
        <path d="M59.714 140.736a1.34 1.34 0 1 0 0-2.68 1.34 1.34 0 0 0 0 2.68z" fill="#BF5025"/>
        <path d="M153.075 176.021c-4.123-5.57-12.083-9.495-12.167-9.536l.854-1.751c.347.167 8.467 4.168 12.874 10.127l-1.561 1.16z" fill="#206CC6"/>
        <path d="M139.715 152.216s-4.997 14.42-3.255 21.039c-15.095.57-35.875-5.049-35.875-5.049s2.423-3.23 2.749-5.453c.326-2.222 18.676-13.482 18.676-13.482l17.705 2.945z" fill="#D8D8D8"/>
        <path d="M139.25 152.14s-5.421 12.989-3.679 19.608c-15.095.57-35.874-5.05-35.874-5.05s2.422-3.229 2.748-5.452c.327-2.223 18.323-11.975 18.323-11.975l18.482 2.869z" fill="#E2E2E2"/>
        <path d="M98.267 164.733s7.447-11.335 7.933-18.948c17.004 3.244 33.515 3.605 33.515 3.605s-5.594 9.787-5.226 20.268c-17.122-.215-36.222-4.925-36.222-4.925z" fill="#FCB8B3"/>
        <path d="M108.525 149.737c12.201 2.327 24.52 1.577 30.51.966.416-.841.68-1.313.68-1.313s-16.518-.368-33.515-3.605c-.486 7.613-7.933 18.948-7.933 18.948s1.548.382 4.158.931c2.241-3.89 5.774-10.759 6.1-15.927z" fill="#FA7167"/>
        <path d="M114.535 37.334H28.15v.632h86.385v-.632zM114.535 17.135H28.15v.632h86.385v-.632z" fill="#E5E5E5"/>
    </g>
    <defs>
        <linearGradient id="edle76ve5b" x1="289" y1="41.91" x2="247.675" y2="92.021" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="burbuedmwc" x1="266.257" y1="74.334" x2="260.761" y2="74.438" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="na23qddqhd" x1="286.349" y1="214.758" x2="285.023" y2="225.649" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="bvez3fr2ce" x1="152.395" y1="123.287" x2="65.983" y2="226.722" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="r7g74llt1f" x1="251.377" y1="118.752" x2="164.965" y2="222.187" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <linearGradient id="zlr725ga2g" x1="267.506" y1="143.703" x2="216.382" y2="293.316" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0068E1"/>
            <stop offset="1" stop-color="#B0D5FF"/>
        </linearGradient>
        <clipPath id="0gqgcso9ya">
            <path fill="#fff" d="M0 0h289v237.914H0z"/>
        </clipPath>
    </defs>
</svg>

  )
}
