import React from 'react'
import { useField } from 'formik';
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'
import { useDidUpdate } from '../../../lib/customHooks'

const Container = styled.div``
const ErrorSpan = styled.span``
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`
const Options = styled.div`
  display: flex;
`
const Option = styled.div`
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(73, 83, 89, 0.16);
  border: ${({ isSelected, hasError }) => isSelected ? 'solid 1px var(--Azure500)' : hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'};
  background-color: ${({ isSelected }) => isSelected ? '#f3fafe' : 'var(--Dove)'};
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
`

const OptionSelect = ({ label, dependentOn, hasError, selectKey='name', infoTitle, allowMultiple, selectOptions, ...props }) => {
  const [field, meta] = useField(props);
  const { value, onBlur, onChange } = field
  const newHandleChange = name => {
    let newValue = name
    if (allowMultiple) {
      let oldValue = value || ''
      let valueArr = oldValue.split(',').filter(Boolean)
      if (valueArr.includes(name)) {
        valueArr = valueArr.filter(v => v !== name)
      } else {
        valueArr.push(name)
      }
      newValue = valueArr.join(',')
    }
    onChange({target: { name: props.name, value: newValue}})
  }

  useDidUpdate(() => {
    newHandleChange('')
  }, [dependentOn])

  const options = Array.isArray(selectOptions) ? selectOptions : (selectOptions[dependentOn] || [])
  return (
    <Container>
      <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan><InfoIcon title={infoTitle}/>
      </LabelContainer>
      <Options>
        {
          options.map((option) => {
            let selectedValue = value || ''
            let valueArr = selectedValue.split(',').filter(Boolean)
            const isSelected = valueArr.includes(option[selectKey])
            return (
              <Option key={option.name} isSelected={isSelected} onClick={() => newHandleChange(option[selectKey])} hasError={hasError}>
                {option.name}
              </Option>
            )
          })
        }
      </Options>
    </Container>
  );
};

export default OptionSelect
