import React from 'react'

const Audiobooks = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" d="M16.134 20.018c3.24 0 5.866-2.654 5.866-5.928s-2.627-5.927-5.866-5.927v1.073c2.653 0 4.804 2.173 4.804 4.854s-2.151 4.855-4.804 4.855v1.073z"/>
    <path fill="#fff" d="M19.768 11.519c-.214-.308-.465-.588-.747-.832l-1.15 1.356c.17.147.32.315.449.5l1.448-1.024zM16.134 16.276c1.194 0 2.163-.979 2.163-2.186 0-1.207-.969-2.186-2.163-2.186v1.073c.608 0 1.1.499 1.1 1.113 0 .615-.492 1.113-1.1 1.113v1.073z"/>
    <path fill="#fff" d="M16.134 14.535c.243 0 .44-.2.44-.445s-.197-.444-.44-.444v.889z"/>
    <path fill="#fff" fillRule="evenodd" d="M3.19 20.866c-.073-.055-.13-.134-.157-.231C3 20.544 3 20.435 3 20.336V5.397C3 3.995 3.993 3 5.397 3h10.105c.492 0 .624.133.624.639v1.327c0 .307-.173.498-.452.506-.287.008-.484-.183-.492-.498-.006-.213-.004-.426-.002-.65l.002-.353c-.09-.009-.165-.009-.238-.009h-9.62c-.715 0-1.281.514-1.372 1.228-.082.663.378 1.31 1.05 1.468.14.033.288.033.436.033 3.357 0 6.707 0 10.064.009.14 0 .295.024.418.082.19.092.214.283.214.482v13.122c0 .473-.14.614-.6.614H3.625c-.17 0-.322-.025-.435-.134zM13 10H6v3h7v-3z" clipRule="evenodd"/>
  </svg>
)

export default Audiobooks
