import React from 'react'
import styled from 'styled-components'
import NavigationDrawer from '../NavigationDrawer'
import { menuItemsArray } from '../PrivatePage'
import SubHeader from '../WithSubHeaderBG'

const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
  padding: 30px;
`
export const ErrorComponent = () => (
  <BlankPaper>
    Something went wrong on our end. Please try again later
  </BlankPaper>
)

const ErrorPage = () => {
  return (
    <NavigationDrawer menuItemsArray={menuItemsArray}>
      <SubHeader>
        <ErrorComponent />
      </SubHeader>
    </NavigationDrawer>
  )
}

export default ErrorPage
