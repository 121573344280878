import React, { useState } from 'react'

const ControlsContext = React.createContext({
  isAlertsShown: false,
  toggleIsAlertsShown: () => null,
  alertsCount: 0,
  updateAlertsCount: () => null
})

const ControlsProvider = ({ children }) => {
  const [isAlertsShown, toggleIsAlertsShown] = useState(false)
  const [alertsCount, updateAlertsCount] = useState(0)

  return (
    <ControlsContext.Provider
      value={{
        isAlertsShown,
        toggleIsAlertsShown,
        alertsCount,
        updateAlertsCount
      }}
    >
      {children}
    </ControlsContext.Provider>
  )
}

export { ControlsContext }
export default ControlsProvider
