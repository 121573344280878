import styled from 'styled-components'

const ToolBar = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  background-color: var(--Grey100);
  height: 84px;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
`
const ReadableToolBar = styled(ToolBar)`
  height: unset;
  :hover {
    box-shadow: unset;
  }
  @media print {
    display: none;
  }
`
const TitleHolder = styled.div`
  text-align: center;
  background-color: #fff;
`
const Title = styled.label`
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--Text700);
`
const ReadOnlyTitleHolder = styled.div`
  padding: 16px 24px;
  display: flex;
  background-color: var(--Grey100);
`
const ReadOnlyTitle = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  line-height: 1.2;
  color: var(--Text700);
`
const VersionTitle = styled.label`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
`
const VersionBody = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 16px;
  margin-top: 4px;
`
const Body = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--Text500);
  margin-top: 16px;
`
const EditorHolder = styled.div`
display: ${({ showCommentIcon}) => showCommentIcon  ? 'flex' : null};
align-items: ${({ showCommentIcon}) => showCommentIcon  ? 'flex-start' : null};
margin-left: ${({ showCommentIcon}) => showCommentIcon  ? '15px' : null};
max-width: ${({ showCommentIcon}) => !showCommentIcon  ? '800px' : null};
background-color: ${({ showCommentIcon}) => !showCommentIcon  ? 'rgb(255, 255, 255)' : null};
margin-top: ${({ showCommentIcon}) => showCommentIcon  ? '54px' : null};
margin: ${({ showCommentIcon}) => !showCommentIcon  ? '54px auto auto' : null};

  @media (max-width: 600px) {
    margin-top: 0;
    margin-left:0;
  }
  @media (max-width: 785px) {
    display: ${({ showCommentIcon}) => showCommentIcon  ? 'block' : null};
  }
`

const ReadOnlyEditorHolder = styled(EditorHolder)`
  height: 64vh;
  overflow: auto;
  @media print {
    height: unset;
    overflow: unset;
  }
`

const VersionHolder = styled.div`
  cursor: pointer;
  padding: 16px 24px 0 24px;
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 480px;
  height: 78px;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
`
const PreviewToolbar = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  background-color: #eceff1;
  height: 84px;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
`
const ToolContainer = styled.div`
  display: flex;
  margin-top: 9.7px;
`
const ToolInfo = styled.span`
  margin-left: 4px;
  color: rgba(13, 21, 54, 0.65);
  margin-right: 16px;
  font-weight: bold;
`
const ActionButtonDiv = styled.div`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 2px var(--Azure500);
  width: 95px;
  height: 36px;
  text-align: center;
  justify-content: center;
`
const DraftButtonDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 2px var(--Azure500);
  width: 151px;
  height: 36px;
  text-align: center;
  justify-content: center;
`
const Time = styled.div`
  color: rgba(13, 21, 54, 0.4);
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
  display: flex;
  align-items: center;
`
const WordCount = styled(Time)``
const AddAnotherCTA = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
  line-height: 1.5;
  color: var(--Azure500);
`
const EditorToolButton = styled(AddAnotherCTA)`
  border: 1px solid var(--Azure500);
  cursor: pointer;
  margin-right: 16px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
`
const CloseReadableEditor = styled(AddAnotherCTA)`
  cursor: pointer;
  margin-left: 15px;
`
const PrintReadableEditor = styled(AddAnotherCTA)`
  cursor: pointer;
  margin-left: auto;
`
const Spacer = styled.div`
  height: 40px;
`
const EditAnotherCTA = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  line-height: 1.43;
  color: var(--Azure500);
`
const PublishButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 36px;
  width: 100px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
`
const UpdateButton = styled.button`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  height: 36px;
  width: 100px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
`
const InputTitle = styled.input`
  padding: 0 16px 0 16px;
  border: none;
  outline: none;
  width: 800px;
  text-align: left;
  height: 60px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--Text700);
`
const Tool = styled.span`
  display: flex;
  cursor: pointer;
`
const PublishLabel = styled.label`
  cursor: pointer;
  margin-left: 4px;
`
const RestoreButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 36px;
  width: 88px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
  margin-left: 4px;
`
const VersionRestoreInfo = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text300);
`
const VersionRestore = styled.label`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--Text500);
`
const Date = styled.label`
  color: rgba(13, 21, 54, 0.65);
  font-size: 20px;
  font-weight: 500;
`
const VersionContainer = styled.div`
  margin-top: 16px;
`
const EditorContainer = styled.div`
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--Text500);
  min-height: 550px;
  width: ${({ showCommentIcon}) => showCommentIcon  ? '70%' : null};
  max-width: ${({ showCommentIcon}) => !showCommentIcon  ? '800px' : null};
  margin: ${({ showCommentIcon}) => !showCommentIcon  ? 'auto' : null};

  .tox-toolbar.tox-toolbar--scrolling,
  .tox-toolbar__primary {
    background-color: var(--Grey300) !important;
    justify-content: flex-end;
  }
   @media (max-width: 785px) {
    width: ${({ showCommentIcon}) => showCommentIcon  ? 'auto' : null};
   }
`
const ReadOnlyEditorContainer = styled(EditorContainer)`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: 2;
  width: unset;
  padding: 0;
  height: 58vh;
  // overflow: scroll;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
    // height: 85vh;
  }
  @media print {
    height: unset;
    overflow: unset;
  }
`

export const SummaryBox = styled.div`
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  margin-bottom: 16px;
  max-height: 150px;
  max-width: 64vw;
  overflow-y: scroll;
  @media (max-width: 785px) {
    max-width: inherit;
  }
  h3 {
    color: black;
  }
  div {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    overflow: hidden;
  }
  span {
    color: black;
    text-transform: capitalize;
    font-weight: 700;
  }
`


export {
  EditorContainer,
  ReadOnlyEditorContainer,
  CloseReadableEditor,
  PrintReadableEditor,
  TitleHolder,
  Title,
  VersionTitle,
  VersionBody,
  Body,
  EditorHolder,
  VersionHolder,
  PreviewToolbar,
  ToolContainer,
  ToolInfo,
  ActionButtonDiv,
  DraftButtonDiv,
  Time,
  ReadOnlyTitle,
  ReadOnlyTitleHolder,
  AddAnotherCTA,
  EditorToolButton,
  EditAnotherCTA,
  PublishButton,
  UpdateButton,
  VersionContainer,
  Date,
  VersionRestore,
  VersionRestoreInfo,
  RestoreButton,
  PublishLabel,
  Tool,
  InputTitle,
  ToolBar,
  ReadableToolBar,
  Spacer,
  WordCount,
  ReadOnlyEditorHolder
}