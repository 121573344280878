import React, { useState } from 'react'
import MyEditor from './DraftJsEditor'
import { Popup } from '../Popup'
import './override.css'

import {
  ReadOnlyEditorContainer,
  ReadOnlyEditorHolder,
  ReadableToolBar,
  EditorToolButton,
  CloseReadableEditor,
  PrintReadableEditor,
  Spacer,
  ReadOnlyTitle,
  ReadOnlyTitleHolder,
} from './styles'

const ReadOnlyEditor = ({ title, content, close }) => {
  const [fontSize, setFontSize] = useState(18)

  const onPrintClick = () => {
    const print_div = document.getElementById('scriptContent')
    const print_area = window.open()
    print_area.document.write(print_div.innerHTML)
    print_area.document.close()
    print_area.focus()
    print_area.print()
    print_area.close()
  }
  return (
    <Popup
      show
      close={close}
      className="editor-file-read-only"
      id="scriptContent"
    >
      <ReadableToolBar>
        <EditorToolButton onClick={() => setFontSize(fontSize + 1)}>
          +
        </EditorToolButton>
        <EditorToolButton onClick={() => setFontSize(fontSize - 1)}>
          -
        </EditorToolButton>
        <PrintReadableEditor onClick={onPrintClick}>Print</PrintReadableEditor>
        <CloseReadableEditor onClick={close}>Close</CloseReadableEditor>
      </ReadableToolBar>

      <ReadOnlyTitleHolder>
        <ReadOnlyTitle>{title || 'Chapter title'}</ReadOnlyTitle>
      </ReadOnlyTitleHolder>
      <ReadOnlyEditorHolder>
        <ReadOnlyEditorContainer id="chapterBody" fontSize={`${fontSize}px`}>
          <MyEditor
            content={content}
            readOnly={true}
            updateState={() => null}
            updateWordCount={() => null}
            tabIndex={1}
          />
        </ReadOnlyEditorContainer>
      </ReadOnlyEditorHolder>
      <Spacer />
    </Popup>
  )
}

export default ReadOnlyEditor
