import React, { useState } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 24px;
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey100);
`
const LabelSpan = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`
const ToggleButton = styled.div`
  cursor: pointer;
  margin-right: 8px;
  width: 88px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  border-radius: 4px;
  color: ${({ yesButton, selected }) => selected && yesButton ? 'rgba(255, 255, 255, 0.98)' : !selected && !yesButton ? 'var(--Text300)' : 'var(--Text500)'};
  background-color: ${({ yesButton, selected }) => yesButton && selected ? 'var(--Azure500)' : yesButton ? 'transparent' : selected ? 'var(--Grey900)' : 'var(--Grey100)'};
  margin-left: ${({ yesButton }) => yesButton ? '8px' : 'auto'};
`
const InputToggle = ({ label, infoTitle, asTextArea, rowCount, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field] = useField(props)
  const { value, onChange } = field
  const [selectedOption, setSelected] = useState(value === 1 ? 1 : 0)
  const handleChange = isTrue => {
    // do something with this val
    setSelected(isTrue)
    if (!props.name) return
    onChange({target: { name: props.name, value: isTrue}})
  }
  return (
    <Container>
      <LabelContainer>
        <LabelSpan>{label}</LabelSpan><InfoIcon title={infoTitle}/>
      </LabelContainer>
      <ToggleButton selected={!selectedOption} onClick={() => handleChange(0)}>No</ToggleButton>
      <ToggleButton selected={selectedOption} onClick={() => handleChange(1)} yesButton={true}>Yes</ToggleButton>
    </Container>
  );
};

export default InputToggle
