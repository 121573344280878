import HowItWorksStep1SVG from "../../../components/Images/HowItWorksStep1SVG"
import HowItWorksStep2SVG from "../../../components/Images/HowItWorksStep2SVG"
import HowItWorksStep3SVG from "../../../components/Images/HowItWorksStep3SVG"
import HowItWorksStep4SVG from "../../../components/Images/HowItWorksStep4SVG"
import NewNovelDesktop from './Images/NewNovel.svg'
import DailyUploadDesktop from './Images/DailyUpload.svg'
import CompletionBonusDesktop from './Images/CompletionBonus.svg'
import RevenueSvg from './Images/Revenue.svg'
import React from 'react'

export const dataIndia = {
  video_thumbnail_desktop: 'https://djhonz7dexnot.cloudfront.net/b79f877e7fa88aca3706e70e00bf5417411056c3.jpg',
  video_thumbnail_mobile: 'https://djhonz7dexnot.cloudfront.net/5c8662e645a9119beb8e5a513ef0200e360c05ca.jpg',
  deadline_date: 1,
  deadlineDate: '1st',
  deadline_date_suffix: "",
  step: "STEP",
  days_or: "days or " ,
  months_to_go:" months to go !! ",
  monthInWords:"July",
  month: 7,
  year: 2023,
  introductory_heading : <>Become the Writer of your <span>Dreams</span></>,
  contactUs : "Contact Us",
  faqs : "FAQ's",
  aboutWriterProgram : {
      heading : 'About Writer Benefit Program',
      subText : <>Writer Benefit Program is our effort to financially reward writers to write <span>Original long-form fiction novels</span> and turn their passion into a career.</>
  },
  journeyCta: 'Start Your Journey',
  revenueAndIncetive:{
    heading: 'Revenue & Incentives',
    tagline: 'UPLOAD DAILY | EARN DAILY',
    note: 'Open for the Hindi Novels Only'
  },
  revenueCard: {
      heading: 'Revenue',
      sponsorLine: 'Sponsored by readers 🖤',
      revenueShare: 'Min. 25% Lifetime Revenue Share',
      definition: 'Once your novel is approved for the program, it will be eligible for this reward for lifetime.',
  },
  incentiveCard: {
      heading: 'Incentives',
      sponsorLine: 'Sponsored by us 🖤',
      tagline: <>Earn upto <span>₹1 lakh</span> per book per year</>,
      newNovelBonusCard: {
          heading: 'NEW NOVEL BONUS',
          blueTag: <>Get at least <span>₹3000</span></>
      },
      dailyBonusCard: {
          heading: 'DAILY UPDATE BONUS',
          blueTag: 'Get upto ₹9000/mon.'
      },
      completionBonus: {
          heading: 'COMPLETION BONUS',
          blueTag: <>Get upto <span>₹25,000</span></>
      }
  },
  deadline:{
      heading: 'Deadline to Enter Program',
      text: <>Once part of the program you will be eligible for <br />{' '}
      <span>LIFETIME REVENUE</span></>
  },
  howItWorksLine: "How it Works",
  howItWorksArray : [
    {
      step: '1',
      instruction:<>Write the first 30,000 words &amp; Apply for our Writers Benefit Program ✍️</>,
      stepSVG: <HowItWorksStep1SVG />
    },
    {
      step: '2',
      instruction:<>If selected, Sign the Contract & Receive New Novel Bonus along with Revenue Share monthly</>,
      stepSVG: <HowItWorksStep2SVG />
  
    },
    {
      step: '3',
      instruction:<>Update Chapters daily, and receive Daily Update Bonus every Day 💸 💰</>,
      stepSVG: <HowItWorksStep3SVG />
  
    },
    {
      step: '4',
      instruction:<>Once you write 2,50,000 words and have positive reviews, our team will contact you to make an audiobook. After testing, if the novel is selected for audio conversion, you will receive revenue from your audiobook based on its performance.</>,
      stepSVG: <HowItWorksStep4SVG />
    },
  ],
  revenue: {
    heading: 'Min. 25% Lifetime Revenue Share',
    image: RevenueSvg,
    subHeading: <>Once your novel is approved for the program, it will be eligible for this reward for lifetime.</>,
    extraNotes: [
      {
        no: 1,
        text: 'Minimum 25% revenue will be shared with writer after deduction of tax & payment gateway charges.',
      },
      {
        no: 2,
        text: 'Revenue share for any month will be added to your Income Tab within the 1st day of next month.',
      },
      {
        no: 3,
        text: 'We will keep adding the revenue share amount in your Income Tab. Transfer of income to your bank account will happen only after your income amount is at least ₹100.',
      },
      {
        no: 4,
        text: 'Hindi novels who applied for the program (old program) before 22nd Dec 2022 will also be eligible for the reward. If there is any deficit in the revenue amount you receive, it will be paid in the next month.',
      },
    ],
  },
  new_novel_bonus: {
    heading: '1. New Novel Bonus 🖋️',
    image: NewNovelDesktop,
    subHeading: <>This reward is given after the novel is approved for the Program.</>,
    tableHeadings: ['Minimum Words ', 'Reward 💰'],
    table: [
      {
        wordCount: '30,000',
        reward: '₹3000',
      },
    ],
    note: <>NO copied, translated, adult or non-fiction content allowed </>,
    extraNotes: [
      {
        no: 1,
        text: 'The writer must apply for the Writer Benefit Program [WBP].',
      },
      {
        no: 2,
        text: 'If the novel meets our content quality standards, it will be approved.',
      },
      {
        no: 3,
        text: 'The reward will be added to author’s Income Tab at the time of approval',
      },
      {
        no: 4,
        text: <>The reward amount will be based on words published at the time of approval & calculated at the rate of 10 paise per word. <br /> Example: if at the time of approval the novel has 65,000 words , then the reward will be ₹6,500 </>,
      },
      {
        no: 5,
        text: "Transfer from writer's wallet to bank account will happen after signing of contract. Payment cycle starts in the 1st week of the next month.",
      },
      {
        no: 6,
        text: "While reviewing the novel, if any malpractice is found including copying, translation etc., we reserve the right to reject or reclaim the reward.",
      },
    ],
  },
  daily_update_bonus : {
    heading: '2. Daily Update Bonus ⭐',
    image: DailyUploadDesktop,
    subHeading: <>This reward is generated daily for up to 2,000 words at an <strong>incentive rate</strong> of 10 paise/word. Readers want daily chapter updates. So, we pay more if you publish regularly but pay less if you have missed publishing</>,
    tableHeadings: ['Incentive Type', 'Incentive Rate 💰'],
    table: [
      {
        wordCount: <span style={{color: '#54B585'}} >Full Incentive</span>,
        reward: <> <span style={{color: '#54B585'}}>100%</span> of incentive <br /> = 10 paise/word  </>,
      },
      {
        wordCount: <span style={{color: '#799E22'}} >Streak Incentive</span>,
        reward: <><span style={{color: '#799E22'}}>150%</span> of incentive <br /> = 15 paise/word</>,
      },
      {
        wordCount: <span style={{color: '#F2C602'}} >Half Incentive</span>,
        reward: <><span style={{color: '#F2C602'}}>50%</span> of incentive <br /> = 5 paise/word</>,
      },
      {
        wordCount: <span style={{color: '#E51A4D'}} >Missed Days</span>,
        reward: <><span style={{color: '#E51A4D'}}>No</span> payment</>,
      },
     
    ],
    note: <>This Reward will be stopped if readers are drastically dropping from your novel</>,
    extraNotes: [
      {
        no: 1,
        text: <> This reward is like ‘snakes and ladders’. Incentive type for the day will be determined how you have published after approval to the program.</>,
      },
      {
        no: 2,
        text: <> Our <strong>Full incentive</strong> is 10 paise/word <br />
        For example, <br/>
        - If you have published chapters of 2000 words each, daily from Oct 1st to Oct 6th;<br/>
        then Oct 1st to Oct 6th will get <strong>Full Incentive</strong> (10 paise / word) , so ₹200 on each day.</>,
      },
      {
        no: 3,
        text: <> After approval to the program, if you publish <strong>continously</strong> for 30 days, you start getting <strong>Streak incentive</strong>  (15 paise/word).<br /> 
        For example,<br/>
        - If you have published chapters of 2000 words each, daily from Oct 1st till Nov 2nd;<br/>
        then Oct 1st till Oct 30th will get <strong>Full Incentive</strong> (10 paise / word) , so ₹200 on each day. <br/>
        and Oct 31st to Nov 2nd will get <strong>Streak Incentive</strong>  (15 paise / word) , so ₹300 on each day. <br/>
        - This <strong>Streak Incentive</strong>  continues till you keep publishing daily</>,
      },
      {
        no: 4,
        text: <> For every day you miss publishing, you get <strong>Half Incentive</strong>  on the next days<br/>
        For example, <br/>
        - If you miss publishing on 1st Jan, you'll get<br/>
        <strong>Half Incentive</strong>  (5 paise/word) for publishing on 2nd Jan. <br/>
        And starting 3rd Jan, you get <strong>Full Incentive</strong> (10 paise/word) again. <br/>
        - If you miss publishing from 1st to 3rd Jan, you'll get <br/>
        <strong>Half Incentive</strong>  (5 paise/word) for publishing from 4th to 6th Jan. <br/>
        And starting 7th Jan, you get <strong>Full Incentive</strong> (10 paise/word) again.</>,
      },
      {
        no: 5,
        text: <> To continue getting this reward, upload quality chapters daily & Make sure Valid Readers % is not dropping a lot from chapter to chapter. You can find the graph in Analytics Section: Engagement Tab</>,
      },
    ],
  },
  completion_bonus : {
    heading: '3. Completion Bonus 🚀',
    image: CompletionBonusDesktop,
    subHeading: 'This reward will be given to the author when the novel is completed after review by our editors.',
    tableHeadings: ['Word Count (Overall)', 'Reward 💰'],
    table: [
      {
        wordCount: '1,00,000 - 2,50,000',
        reward: '₹1,000',
      },
      {
        wordCount: '2,50,000 - 3,50,000',
        reward: '₹5000',
      },
      {
        wordCount: '3,50,000 - 5,00,000',
        reward: '₹10,000',
      },
      {
        wordCount: 'More than 5,00,000',
        reward: '₹25,000',
      },
    ],
    note: <>If readers are drastically dropping from your novel, it might earn the reward for a lower word count bucket </>,
    extraNotes: [
      {
        no: 1,
        text: <> Please email us at writers@pocketfm.com with the Subject: <strong> Novel Name, Author name, Request for Completion Bonus</strong></>,
      },
      {
        no: 2,
        text: <>The payment will be approved after an editorial check to evaluate if the novel meets our content quality guidelines.</>,
      },
    ],
  },
  WritingFAQs: [
    {
      question: '1. How to register as a writer? Any tips to create a novel?',
      answer: "Please follow these steps ",
      youtubeLink: 'Hs8Jq8leaoU'
    },
    {
      question: '2. What are the terms & conditions for writing?',
      answer: "Only original fiction stories are allowed. We disable plagiarism ( copied content)  and sexually explicit (adult content ) books.",
    },
    {
      question: '3. How many words need to publish a chapter',
      answer: "Each chapter must contain a minimum 1000 words or maximum 5000 words.",
    },
    {
      question: '4. Can I write short stories and Poetry or Shayari?',
      answer: "We have observed that fiction stories have much higher readers than non-fiction, shayari, and poetry. So we recommend that all writers write original fiction stories. Hence, even if we allow writers to write short stories and poems, they are not eligible to apply for the Writers Benefit Program.",
    },
    {
      question: '5. Which languages can I write in?',
      answer: "You are welcome to write in any language option seen, but the Writer benefit program is currently only open for Hindi, English, and Tamil.",
    },
    {
      question: '6. Why is my novel not visible on app',
      answer: "As we get hundereds of new books published daily, we are only able to feature the books with good performance in our featured list . To become featured in our app, make sure to write regularly with hooky long story & plotline",
    },
  ],
  ProgramFAQs: [
    {
      question: '1. What are the minimum performance guidelines for signing a novel?',
      answer: "Only top 10% of the applications to the program are accepted, please make sure your readers are engaged with chapters added to your novel. You can refer & compare your novel's performances against Platform's Avg book data by going to Analytics Tab -> Engagement -> valid reader % vs Chapter Graph",
    },
    {
      question: '2. Why do I have to sign the contract?',
      answer: "Contracts include the terms and conditions which a writer is subject to if he/she wants to enter the Writers Benefit Program. So, if a writer wants to enjoy the benefits of WBP and be properly compensated for his or her work, he or she must sign the contract.",
    },
    {
      question: '3. What is the payment structure?',
      answer: "If selected for writer benefit program, the writer is then eligible to get Revenue Share , New Novel Bonus, Daily Update Bonus, & Completion Bonus. You can refer to respective sections in Revenue & Incentive section in writer benefit page for detailed information",
    },
    {
      question: '4. What is the mode of payment?',
      answer: "Your rewards will be reflected in Income Tab. The said amount might be subject to adjustment based on the defined criteria in some cases. The final eligible payout will be deposited in the writer’s associated bank account before 1st or 2nd week of every month given that the payout> 100 INR",
    },
    {
      question: '5. How many days do I need to write, once accepted to the program ?',
      answer: "Writers need to upload chapters on a daily basis with quality content. For missed days there wont be any Daily Update Bonus ",
    },
    {
      question: '6. What are coins?',
      answer: "Coins are used to support the writers financially. Except for the first few chapters of a novel, all the other chapters are locked for a limited time. Coins can be used to unlock these chapters and get early access to the entire story. Please watch this video for more detailed information: ",
      youtubeLink: 'i5knJeoyCnY'
    },
    {
      question: '7. How do writers benefit from coins?',
      answer: "A minimum 25% of the Revenue recieved from reader spending coins on the novel is shared with the author. Check out the Revenue share card for more information",
    },
    {
      question: '8. Will my story be made into an audiobook?',
      answer: "Your novel must have a word count of at least 2,50,000 words to be eligible for consideration. If your book gets a high readership & enagagement , we'll first test it in audio format & if it shows success, we will convert it to an audiobook.",
    },
  ],
  PlatformFAQs: [
    {
      question: '1. How to download mobile applications?',
      answer: `Android- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a> , iPhone- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a>`,
    },
    {
      question: '2. How do I login? How to use the app?',
      answer: "Please use this for login - <a href='https://writer.pocketnovel.com/login'>here</a> and follow these steps - tutorial ",
      youtubeLink: 'Tu9XoKAP918'
    },
    {
      question: '3. How to publish a chapter?',
      answer: "If you want to add chapters to a novel on our platform, you have to login to our <a href='https://writer.pocketnovel.com/'>writers@pocketfm.com</a> or our mobile app Pocket Novel Reader. This link will help you to understand.Watch here",
    },
    {
      question: '4. Can I write from outside India?',
      answer: "Our app is available globally. We welcome all writers from all countries. But we are currently only supporting authors from India financially ",
    },
    {
      question: '5. How to upload the Book Cover?',
      answer: "Go to the novels, choose the edit novel option. On the right side, there is an option to update the cover image. You can upload the book cover from there and also publish the novel from your side. Our graphic team will upload a book cover for you if the novel performs well.",
    },
  ],
  IssuesFAQs: [
    {
      question: '1.Didn’t get the OTP or Face issue during logging in?',
      answer: "Please try to get OTP on WhatsApp. If the issue still persists then mail us on <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a> along with your login info like phone number you are trying to use.",
    },
    {
      question: '2. Issues with email and password',
      answer: "Double-check that you have entered your username and password correctly. Remember, password is case-sensitive. If you're still having trouble, you can use the `Forgot your Username or Password?` link to have a password reset link emailed to you on your registered email ID. If the email doesn't appear in your inbox, check your spam/junk mail folder. If you're still having difficulties, email us the screenshot/video of the issue at <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a>",
    },
    {
      question: '3. What to do, if my App not working',
      answer: "Restart your phone and check for updates. If you are still facing the same issue, 'force stop' the Pocket Novel app from your phone's Settings and try clearing app cache. If the problem is not yet solved, email us at writers@pocketfm.com with a screenshot/video of the issue.",
    }
  ],
}