import React from 'react'

const AwardIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="#A4A9C1" d="M5.047 13.333C4.999 7.536 9.771 2.703 15.601 2.667c5.913-.036 10.684 4.666 10.744 10.595.047 5.869-4.688 10.69-10.542 10.726-5.95.036-10.708-4.679-10.756-10.655zm10.649 6.87c3.89-.036 6.913-3.12 6.889-7.013-.024-3.726-3.165-6.761-6.973-6.738-3.783.024-6.84 3.155-6.805 6.976.035 3.727 3.165 6.81 6.889 6.774zM11.198 29.334c-.892-1.096-1.69-2.096-2.522-3.072-.167-.19-.476-.357-.726-.369-1.285-.107-2.558-.167-3.95-.25.773-1.512 1.511-2.964 2.296-4.5 1.892 2.179 4.177 3.548 6.96 4.179-.69 1.333-1.344 2.607-2.058 4.012zM18.135 25.31c2.808-.631 5.069-2 6.96-4.18.774 1.513 1.511 2.965 2.297 4.5-1.38.084-2.654.144-3.927.25-.273.025-.595.191-.773.394-.833.952-1.618 1.952-2.499 3.035-.69-1.345-1.356-2.63-2.058-4z"/>
    <path fill="#A4A9C1" fillRule="evenodd" d="M10.366 13.286c-.012 2.94 2.367 5.357 5.306 5.369 2.939.012 5.342-2.37 5.354-5.31.012-2.917-2.356-5.31-5.27-5.333-2.951-.024-5.367 2.333-5.39 5.274zm6.45-1.27l-1.12-1.532-1.12 1.531-1.802.592 1.11 1.538.006 1.896 1.806-.58 1.806.58.005-1.896 1.11-1.538-1.802-.592z" clipRule="evenodd"/>
  </svg>
)

export default AwardIcon
