import React from 'react'

const History = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
        <path fill="#0D1536" fillOpacity=".65" d="M9.17 5.833l-.003 4.588c0 .175.079.341.218.464l3.729 3.282 1.053-.928-3.51-3.09.002-4.316h-1.49z"/>
        <path fill="#0D1536" fillOpacity=".65" d="M10.781 1.667c-4.216 0-7.705 3.111-8.29 7.143H0l2.995 2.976L5.99 8.81H3.702c.573-3.373 3.525-5.953 7.08-5.953 3.963 0 7.187 3.204 7.187 7.143 0 3.939-3.224 7.143-7.188 7.143-2.228 0-4.294-1.002-5.668-2.75l-.944.733c1.603 2.038 4.013 3.207 6.612 3.207 4.624 0 8.386-3.738 8.386-8.333 0-4.594-3.762-8.333-8.386-8.333z"/>
    </svg>

)

export default History
