import React, { useState, useEffect } from 'react'
import { RevisionPopup } from '../Popup'
import {
  VersionTitle,
  VersionBody,
  VersionHolder,
  VersionContainer,
  Date,
  Time,
  WordCount,
} from './styles'
// import VVersions from '../TinyMCEEditor/mock.json'
import { Version } from './version'
import { getDate, getTime } from './helper'
import { get } from '../../lib/api'
import Loader from '../Loader'

export function Revisions({chapter_id, ...props}) {
  const [showVersion, setShowVersion] = useState(false)
  const [chapterVersion, setChapterVersion] = useState(null)
  const [versions, setVersions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const handleVersion = (id) => {
    const selectedVersion = versions.find(v => v.version_id === id) || null
    const { file_url } = selectedVersion
    const isNotTxt = (file_url || '').split('.').pop() !== 'txt'
    if (isNotTxt) {
      // if it is not a text version that means we cant fetch its content and show in the editor
      // TODO: show feedback to the user as to why this version is not getting opened upon clicking
      return
    }
    setChapterVersion(selectedVersion)
    setShowVersion(true)
  }
  useEffect(() => {
    if (!chapter_id) return
    get('/content_api/book.chapter_versions', {
      params: {
        chapter_id
      }
    }).then(response => {
      const { results } = response
      setVersions(results)
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      throw error
    })
  }, [])

  if (isLoading) {
    return <Loader/>
  }

  return (
    <>
      {versions && <RevisionPopup show={props.open} close={props.close}>
        <VersionTitle>Historical Versions</VersionTitle>
        <br />
        <VersionBody>
          We provide 5 versions only, and you may copy the history version to
          edit in current version.
        </VersionBody>
        <VersionContainer>
          {versions.map((version) => (
            <VersionHolder
              key={version.version_id}
              onClick={() => handleVersion(version.version_id)}
            >
              <div>
                <Date>{getDate(version.create_time)}</Date>
                <div>
                  <WordCount>{version.word_count} words</WordCount>
                </div>
              </div>
              <Time>{getTime(version.create_time)}</Time>
            </VersionHolder>
          ))}
        </VersionContainer>
      </RevisionPopup>}
      {showVersion && <Version
        open={showVersion}
        close={() => setShowVersion(false)}
        version={chapterVersion}
        disableSaveButton={props.book_id ? false : true}
        restoreSuccessCB={() => props.close()}
        book_id={props.book_id ? props.book_id : null}
        {...props}
      />}
    </>
  )
}
