import React from 'react'
import styled from 'styled-components'

const SectionContainer = styled.div`
  width: 75%;
  margin: 0px auto;
  padding: 3rem;
  @media (max-width: 600px) {
    width: 100%;
    padding: 1rem;
  }
`
const SectionHeading = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(100deg, #b0d5ff 5%, #0068e1 65%);
`
const SectionSubHeading = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
`
const BecomeProWriter = styled.button`
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: var(--bg-alt);
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  background: linear-gradient(215deg, #0068e1, #b0d5ff);
  margin: 2rem auto;
  cursor: pointer;
`
const CenterBox = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;
`
function Index({ createNewNovelHandler }) {
  return (
    <>
      <SectionContainer>
        <SectionHeading>Testimonials</SectionHeading>
        <SectionSubHeading>
          Let’s hear one of our ProAuthor’s story
        </SectionSubHeading>
        <CenterBox>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/HG5qYstq78E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </CenterBox>
        <CenterBox>
          <BecomeProWriter onClick={createNewNovelHandler}>
            {' '}
            Become a ProAuthor
          </BecomeProWriter>
        </CenterBox>
      </SectionContainer>
    </>
  )
}

export default Index
