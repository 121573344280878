import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Calendar from '../../Images/CalendarOutlineIcon'
import Tooltip from '../../Tooltip'
import InfoIcon from '../../InfoIcon'
import moment from 'moment'


const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.DateInput {
    width: 100%;
  }
  div.SingleDatePicker {
    width: 65%;
  }
  input.DateInput_input {
    width: 100%;
    font-size: 16px;
    border-bottom: none;
    background-color: var(--Grey300);
  }
  input.DateInput_input:focus {
    background-color: var(--Dove);
  }
  div.SingleDatePickerInput__withBorder:focus-within {
    border: solid 1px var(--Azure500);
    background-color: var(--Dove);
  }
  div.SingleDatePickerInput__withBorder {
    background-color: var(--Grey300);
    display: flex;
    border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : '1px solid #dbdbdb'};
  }
`
const CalendarIcon = styled(Calendar)`
  fill: var(--Azure500);
`
const Options = styled.div`
  position: absolute;
  max-height: 395px;
  overflow-y: scroll;
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  padding: 8px 0;
  background-color: var(--Dove);
  width: 80px;
  top: 50px;
  left: 0;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
`
const DateSelect = styled.div`
`
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`
const HourFieldOuter = styled.div`
  position: relative;
`
const HourField = styled.div`
  padding: 10px 14px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
`
const Separator = styled.div`
  margin: 0 4px;
`
const MinuteFieldOuter = styled.div`
  position: relative;
`
const MinuteField = styled.div`
  padding: 10px 14px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-right: 8px;
`
const AMPM = styled.div`
  padding: 10px 11px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
`

const SingleHour = styled.div`
  padding: 16px;
  :hover {
    background-color: var(--Grey500);
  }
`
const SingleMinute = styled.div`
  padding: 16px;
  :hover {
    background-color: var(--Grey500);
  }
`


const transform = "DD MMM YYYY"

const DateTimeSelect = ({ id, label, infoTitle, calendarTitle, asTextArea, rowCount, hasError, ...props }) => {
  const [field] = useField(props)
  const { onChange, value: val } = field
  const [datePickerFocussed, setDatePickerFocussed] = useState(false)
  const [isHourDropdownOpen, toggleHourDropdown] = useState(false)
  const [isMinuteDropdownOpen, toggleMinuteDropdown] = useState(false)
  const hourRef = useRef()
  const minuteRef = useRef()
  const value = val ? moment(val).format('DD MMM YYYY, hh:mm A') : val
  const newHandleChange = date => {
    const val = date && (moment(date).format(transform) + ', 12:00 AM')
    onChange({target: { name: props.name, value: val}})
  }

  const handleClick = (e) => {
    if (!hourRef.current || !minuteRef.current) return
    if (!hourRef.current.contains(e.target)) {
      toggleHourDropdown(false)
    }
    if (!minuteRef.current.contains(e.target)) {
      toggleMinuteDropdown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const handleChangeHour = hour => {
    toggleHourDropdown(false)
    if (!value) return
    const stringHour = String(hour).padStart(2, '0')
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const newTime = stringHour + ':' + oldTime.split(':')[1]
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const handleChangeMinute = minute => {
    toggleMinuteDropdown(false)
    if (!value) return
    const stringMinute = String(minute).padStart(2, '0')
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const oldTimeHour = oldTime.split(' ')[0].split(':')[0]
    const oldTimeAA = oldTime.split(' ')[1]
    const newTime = oldTimeHour + ':' + stringMinute + ' ' + oldTimeAA
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const handleChangeAA = ampm => {
    if (!value) return
    const oldDate = value.split(',')[0]
    const oldTime = value.split(',')[1].trim()
    const newTime = oldTime.split(' ')[0] + ' ' + ampm
    const newValue = oldDate + ', ' + newTime
    onChange({target: { name: props.name, value: newValue}})
  }

  const hours = []
  const minutes = []
  for(let i = 0 ; i<60 ; i++) {
    i < 13 && i !== 0 && hours.push(
      <SingleHour key={'hour-count-' + i} onClick={() => handleChangeHour(i)}>{String(i).padStart(2, '0')}</SingleHour>
    )
    minutes.push(
      <SingleMinute key={'minute-count-' + i} onClick={() => handleChangeMinute(i)}>{String(i).padStart(2, '0')}</SingleMinute>
    )
  }
  const date = (value && moment(value)) || null
  const time = (value && value.split(',')[1].trim()) || '12:00 AM'
  const hh = time.split(' ')[0].split(':')[0]
  const mm = time.split(' ')[0].split(':')[1]
  const A = time.split(' ')[1]

  return (
    <DateSelect>
      <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan><InfoIcon title={infoTitle}/>
      </LabelContainer>
      <DateContainer hasError={hasError}>
        <SingleDatePicker
          date={date} // momentPropTypes.momentObj or null
          onDateChange={date => newHandleChange(date)} // PropTypes.func.isRequired
          focused={datePickerFocussed} // PropTypes.bool
          onFocusChange={({ focused }) => setDatePickerFocussed(focused)} // PropTypes.func.isRequired
          id={id || "your_unique_id"} // PropTypes.string.isRequired,
          numberOfMonths={1}
          placeholder="date"
          noBorder={false}
          customInputIcon={<CalendarIcon/>}
          inputIconPosition="after"
          displayFormat="D MMMM YYYY"
        />
        <Tooltip title={value ? "" : "Please select Date first"}>
        <TimeContainer>
          <HourFieldOuter>
            <HourField ref={hourRef} onClick={() => toggleHourDropdown(true)}>
              {hh}
            </HourField>
            <Options isOpen={isHourDropdownOpen} >{hours}</Options>
          </HourFieldOuter>
          <Separator>:</Separator>
          <MinuteFieldOuter>
            <MinuteField ref={minuteRef} onClick={() => toggleMinuteDropdown(true)}>
              {mm}
            </MinuteField>
            <Options isOpen={isMinuteDropdownOpen} >{minutes}</Options>
          </MinuteFieldOuter>
          <AMPM onClick={() => handleChangeAA(A === 'AM' ? 'PM' : 'AM')}>{A}</AMPM>
        </TimeContainer>
        </Tooltip>
      </DateContainer>
    </DateSelect>
  );
};

export default DateTimeSelect
