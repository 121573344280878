import React from 'react'

const Check = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path fill={props.fill || "#0D1536" } fillOpacity=".65" d="M9.195 20c-.405 0-.812-.161-1.121-.484l-5.61-5.847c-.619-.646-.619-1.693 0-2.338.62-.646 1.625-.646 2.244 0l4.487 4.677L19.292 5.484c.62-.645 1.624-.646 2.243 0 .62.646.62 1.693 0 2.339L10.317 19.516c-.31.323-.716.484-1.122.484z"/>
  </svg>

)

export default Check
