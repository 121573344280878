import React from 'react'
import styled from 'styled-components'
import { Popup } from '../Popup'
import './override.css'

const Head = styled.div`
  background-color: #fdfdfd;
`
const InnerContent = styled.div`
  margin: ${({ isMobile }) => (isMobile ? '10px;' : '0px 0px 0px 48px;')}  
  width: 100%;
  padding-top: 15px;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  color: #0d1536;
`

const PaginationDiv = styled.div`
  display: flex;
  border-radius: 4px;
  border: solid 1px var(--storm-op-16);
  background-color: #fdfdfd;
  width: 20%;
  justify-content: center;
  background-color: ${({ currentPaginationNo, index }) =>
    currentPaginationNo == index + 1 ? 'rgba(229, 26, 77, 0.1)' : '#fdfdfd'};
  border: solid 1px var(--storm-op-16);
  cursor: pointer;
  color: ${({ currentPaginationNo, index }) =>
    currentPaginationNo == index + 1 ? 'var(--crimson-800)' : 'black'};
`
const PaginationOuter = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 10px;
`
const PaginationDrawer = (props) => {
  const isMobile = window.screen.width < 600

  const paginationClick = (elm, index) => {
    if (props.order === 'reverse') {
      props.onPaginationRangeClick(
        Math.ceil((props.episodesCount - elm.max) / 10),
        index
      )
    } else {
      props.onPaginationRangeClick(Math.ceil(elm.max / 10), index)
    }
    props.setCurrentPaginationNo(index + 1)
  }
  return (
    <>
      <Popup
        {...props}
        className={
          isMobile
            ? 'right-drawer-pagination-mobile'
            : 'right-drawer-pagination'
        }
      >
        <Head>
          <InnerContent isMobile={isMobile}>
            <div className="close-button" onClick={props.close}>
              ˟
            </div>
            <Title>{props.title || 'Select Episode Range'}</Title>
            <PaginationOuter>
              {props.episodeRange &&
                props.episodeRange.map((elm, index) => (
                  <PaginationDiv
                    currentPaginationNo={props.currentPaginationNo}
                    index={index}
                    key={index}
                    onClick={() => paginationClick(elm, index)}
                  >
                    {elm.min} - {elm.max}
                  </PaginationDiv>
                ))}
            </PaginationOuter>
          </InnerContent>
        </Head>
      </Popup>
    </>
  )
}

export default PaginationDrawer
