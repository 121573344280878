import React, { useState } from "react";
import styled from "styled-components";
import Upload from "../../../../../components/GenericUpload";
import Loader from "../../../../../components/Loader";
import { post, del } from "../../../../../lib/api";
import { checkAspectRatioMatch } from "../../../../../lib/utils";
import MessagePopup from "../../../../../components/MessagePopup";
const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  margin-bottom: 16px;
`;
const EditContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
`;
const EditTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-bottom: 16px;
`;
const HR = styled.div`
  height: 1px;
  background-color: #e0e2eb;
  margin-bottom: 24px;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LeftSection = styled.div`
  width: 50%;
`;
const RightSection = styled.div``;

const InputLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
`;
const Input = styled.input`
  border-radius: 4px;
  border: solid 1px #e0e2eb;
  background-color: #f0f1f5;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  width: 100%;

  &:focus {
    outline: none;
    border: solid 2px var(--Azure500);
    padding: 11px 15px;
    background-color: var(--Dove);
  }
`;
const ImageUploadDiv = styled.div`
  margin-top: 24px;
  width: 484px;
  height: 242px;
  border-radius: 4px;
  box-shadow: inset 0 0 32px 0 rgba(0, 0, 0, 0.08);
  border: dashed 1px var(--Azure500);
  background-color: var(--Grey100);
  display: flex;
  align-items: center;
`;
const ThumbnailPlaceHolder = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text300);
  text-align: center;
  flex: 1;
`;
const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`;
const CardContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
`;
const Details = styled.div``;
const MainTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`;
const BigTitle = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
`;
const SubTitle = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`;
const ImageDiv = styled.div`
  width: 256px;
  height: 128px;
  border-radius: 4px;
`;
const Image = styled.img`
  width: 256px;
  height: 128px;
  border-radius: 4px;
`;
const ActionButtons = styled.div`
  background-color: #f6f7f9;
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
`;
const CancelButton = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
  cursor: pointer;
  padding: 16px 40px;
`;

const SaveButton = styled.div`
  background-color: ${({ active }) => (active ? "var(--Azure500)" : "#e6e6e6")};
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  padding: 16px 50px;
  cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};
  color: ${({ active }) => (active ? "var(--Dove)" : "var(--Text100)")};
`;
const DeleteDiv = styled.div`
  flex: 5;
`;
const DeleteButton = styled.div`
  color: var(--Punch500);
  padding: 16px 32px;
  border-radius: 4px;
  background-color: rgba(221, 54, 35, 0.1);
  width: 110px;
  font-weight: 600;
  cursor: pointer;
`;
const ImageUploadComponent = ({ isLoading, imageUrl, placeholder }) => (
  <ImageUploadDiv>
    {isLoading && <Loader size="md" />}
    {!isLoading && imageUrl && <Thumbnail src={imageUrl} alt="" />}
    {!isLoading && !imageUrl && placeholder && (
      <ThumbnailPlaceHolder>
        {placeholder || "Click here to upload or drag your file"}
      </ThumbnailPlaceHolder>
    )}
  </ImageUploadDiv>
);

const Card = ({ data: { title, message, image_url } = {}, handleClick }) => {
  return (
    <CardContainer onClick={handleClick}>
      <Details>
        <MainTitle>Notification</MainTitle>
        <BigTitle>{title}</BigTitle>
        <SubTitle>{message}</SubTitle>
      </Details>
      <ImageDiv>
        <Image src={image_url} />
      </ImageDiv>
    </CardContainer>
  );
};

const AddEdit = ({ data, cancelCallback, book_id, refreshCB }) => {
  const [popupDetails, setPopupDetails] = useState({
    show: false,
    confirmWarning: false,
    confirmText: "",
    messageTitle: "",
    messageBody: "",
    selectOptionBeforeSave: false,
    selectOptions: [],
    onConfirm: () => null,
  });
  const [defaultValues, setDefaultValues] = useState({
    title: data["title"] || "",
    message: data["message"] || "",
    image_url: data["image_url"] || "",
  });

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.name]: e.target.value });
  };
  const handleFileChange = (imageUrl) => {
    setDefaultValues({ ...defaultValues, image_url: imageUrl });
  };
  const checkNotificationValidity = () => {
    if (defaultValues.title=== "" || defaultValues.message=== "" || defaultValues.image_url=== "" ) return false;
    else return true;
  };
  const notificationDelete = () => {
    del(`/content_api/notifications/${data["id"]}/`,{
      data:{
        entity_id:book_id
      }
    }).then(() => {
      refreshCB();
      cancelCallback();
    });
  };
  const onDeleteButtonClick = () => {
    setPopupDetails({
      show: true,
      confirmWarning: true,
      confirmText: "Delete Notification",
      messageTitle: "Delete this Notification",
      messageBody: "Are you sure you want to Delete this Notification ?",
      selectOptionBeforeSave: false,
      selectOptions: [],
      onConfirm: notificationDelete,
    });
  };
  const handleSubmit = () => {
    if(data)
      defaultValues.id=data['id'];
    const formdata = {
      ...defaultValues,
      entity_id: book_id,
      entity_type: "book",
      props: "{}",
    };
    post("/content_api/notifications/", {
      data: formdata,
    }).then(() => {
      refreshCB();
      cancelCallback();
    });
  };
  const { title, message, image_url } = defaultValues;
  return (
    <Container>
      <EditContainer>
        <MessagePopup
          show={popupDetails.show}
          onClose={() => setPopupDetails({ ...popupDetails, show: false })}
          onConfirm={popupDetails.onConfirm}
          confirmWarning={popupDetails.confirmWarning}
          cancelText="Cancel"
          confirmText={popupDetails.confirmText}
          messageTitle={popupDetails.messageTitle}
          takeFeedback={popupDetails.takeFeedback}
          messageBody={popupDetails.messageBody}
          selectOptionBeforeSave={popupDetails.selectOptionBeforeSave}
          selectOptions={popupDetails.selectOptions}
        />
        <EditTitle>Notification</EditTitle>
        <HR />
        <FlexContainer>
          <LeftSection>
            <InputLabel>Title</InputLabel>
            <Input
              name="title"
              placeholder="Set title"
              value={title}
              onChange={handleChange}
            />
            <InputLabel>Message</InputLabel>
            <Input
              name="message"
              value={message}
              placeholder="Set message"
              onChange={handleChange}
            />
          </LeftSection>
          <RightSection>
            <Upload
              imageUrl={image_url}
              name="image_url"
              placeholder="Upload Notification Image (1024x512)"
              renderComp={ImageUploadComponent}
              dimensionErrorMsg={
                "Please upload an image of specified dimensions"
              }
              fileTypeTags="image"
              imageValidator={(w, h) => checkAspectRatioMatch(w, h, [2, 1])}
              extraParams={{ title: book_id || "general" }}
              onChange={handleFileChange}
            />
          </RightSection>
        </FlexContainer>
      </EditContainer>
      <ActionButtons>
        <DeleteDiv>
          {data.image_url !== "" && (
            <DeleteButton onClick={onDeleteButtonClick}>Delete</DeleteButton>
          )}
        </DeleteDiv>
        <CancelButton onClick={cancelCallback}>Cancel</CancelButton>
        <SaveButton active={checkNotificationValidity()} onClick={handleSubmit}>Save</SaveButton>
      </ActionButtons>
    </Container>
  );
};

const Notification = (props) => {
  const [mode, setMode] = useState("view");
  const handleCancel = () => {
    setMode("view");
  };
  const handleOnclick = (e) => {
    console.log(e);
    setMode("edit");
  };

  if (props.data.image_url === "" && props.isShowAdd) {
    return <AddEdit {...props} cancelCallback={props.cancelAdd} />;
  }
  if (props.data.image_url === "") {
    return null;
  }
  return mode === "view" ? (
    <Card {...props} handleClick={handleOnclick} />
  ) : (
    <AddEdit {...props} cancelCallback={handleCancel} />
  );
};

export default Notification;
