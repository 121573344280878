import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { post } from '../../lib/api'
import MyTooltip from '../Tooltip'
import Check from '../Images/Check'
import CaretDowm from '../Images/CaretDown' 
import './style.css'

function CommentBoxList({
  commentedList,
  getAllComments,
  profileImage,
  profileName,
  chapterId,
  clickedCommentId,
  bookId,
  setClickedCommentId,
  episodeTag,
  updateCommentsFile,
}) {
  const [showOptions, setShowOptions] = useState(false)
  const [editField, setEditField] = useState(false)
  const [editData, setEditData] = useState({})
  const [editTextArea, setEditTextArea] = useState('')
  const [btnDisable, setBtnDisable] = useState(true)
  const [deleteComment, setDeleteComment] = useState(false)
  const [replyBox, setReplyBox] = useState(false)
  const [replyInputVal, setReplyInputVal] = useState('')
  const [replyEditList, setReplyEditList] = useState()
  const [showReplyEditOptions, setShowReplyEditOptions] = useState(false)
  const [editReplyField, setEditReplyField] = useState(false)
  const [editReplyTextField, setEditReplyTextField] = useState('')
  const [deleteReplyComment, setDeleteReplyComment] = useState(false)
  const showOptionsRef = useRef()
  const replyOptionsRef = useRef()
  const listBoxRef = useRef()

  const showOptionsClick = (e, ele) => {
    const list = [...commentedList]
    const editChoosed = list.filter((elem) => elem._id === ele._id)
    setEditData(editChoosed[0])
    setShowOptions(true)
    setShowReplyEditOptions(false)
    e.stopPropagation()
  }

  const handleEditClick = (ele) => {
    const list = [...commentedList]
    const editChoosed = list.filter((elem) => elem._id === ele._id)
    setEditData(editChoosed[0])
    setEditTextArea(editChoosed[0].comment)
    setEditField(true)
    setShowOptions(false)
  }

  const handleResolveClick = (ele) => {
    const list = [...commentedList]
    const editChoosed = list.filter((elem) => elem._id === ele._id)
    post('/content_api/book.edit_chapter_comment', {
      data: {
        _id: ele._id,
        status: 'resolved',
      },
    }).then(() => {
      getAllComments()
    }).catch((err) => {
      throw err
    })
    setEditData(editChoosed[0])
    setShowOptions(false)
  }

  const handleChange = (e) => {
    const text = e.target.value
    setEditTextArea(text)
    setBtnDisable(false)
  }

  const handleSaveClick = (e, ele) => {
    post('/content_api/book.edit_chapter_comment', {
      data: {
        _id: ele._id,
        comment: editTextArea,
        is_edited:true,
      },
    }).then(() => {
      getAllComments()
    }).catch((err) => {
      throw err
    })
    setEditField(false)
    setEditData()
  }

  const handleCancelClick = () => {
    setShowOptions(false)
    setEditField(false)
    setDeleteComment(false)
    setDeleteReplyComment(false)
  }

  const handleDeleteClick = (ele) => {
    setEditData(ele)
    setDeleteComment(true)
    setShowOptions(false)
    setDeleteReplyComment(false)
  }

  const deleteCommentClick = () => {
    const editor = window.tinymce.activeEditor
    const docu = editor.contentDocument
    const ele = docu.getElementById(editData._id)
    ele.parentNode.replaceChild(
      docu.createTextNode(editData.props.selected_text),
      ele
    )
    post('/content_api/book.delete_chapter_comment', {
      data: {
        _id: editData._id,
        chapter_id: chapterId,
      },
    }).then(() => {
      updateCommentsFile()
    }).catch((err) => {
      throw err
    })

    handleCancelClick()
  }

  const deleteUI = () => (
    <div className="deleteBox">
      <br />
      <span>Delete this comment thread?</span>
      <div className="btnList">
        <br />
        <button
          type="button"
          onClick={deleteCommentClick}
          style={{ fontWeight: 'bold' }}
          className="btnCommentList"
        >
          Delete
        </button>
        <button
          type="button"
          onClick={handleCancelClick}
          style={{ fontWeight: 'bold' }}
          className="btnCancelList"
        >
          Cancel
        </button>
      </div>
    </div>
  )

  const handleBoxClick = (ele) => {
    if(episodeTag){
    setReplyBox(true)
    setEditData(ele)
    }
  }

  const handleReplyChange = (e) => {
    const text = e.target.value
    setReplyInputVal(text)
  }

  const handleReplyCancelClick = () => {
    setReplyInputVal('')
  }

  const handleSaveReplyClick = (ele) => {
    post('/content_api/book.create_chapter_comment', {
      data: {
        chapter_id: chapterId,
        comment_index: ele.comment_index,
        comment: replyInputVal,
        props: {
          profile_image: profileImage,
          profile_name: profileName,
        },
        book_id: bookId,
        parent_comment_id: ele._id,
        is_edited:false
      },
    }).then(() => {
      getAllComments()
    }).catch((err) => {
      throw err
    })
    setReplyInputVal('')
  }

  const showReplyOptionsClick = (e, ele, elem) => {
    const choosed = ele?.child_comments?.filter(
      (element) => element._id === elem._id,
    )
    setReplyEditList(choosed[0])
    setShowReplyEditOptions(true)
    setShowOptions(false)
    e.stopPropagation()
  }

  const handleReplyEditClick = (ele, elem) => {
    const choosed = ele?.child_comments?.filter(
      (element) => element._id === elem._id,
    )
    setReplyEditList(choosed[0])
    setEditReplyTextField(choosed[0].comment)
    setEditReplyField(true)
    setShowReplyEditOptions(false)
  }

  const handleReplyEditChange = (e) => {
    const text = e.target.value
    setEditReplyTextField(text)
    setBtnDisable(false)
  }

  const handleEditReplyCancelClick = () => {
    setEditReplyField(false)
    setShowReplyEditOptions(false)
  }

  const handleReplySaveClick = (ele, elem) => {
    post('/content_api/book.edit_chapter_comment', {
      data: {
        _id: elem._id,
        comment: editReplyTextField,
        is_edited:true,
      },
    }).then(() => {
      getAllComments()
    }).catch((err) => {
      throw err
    })
    setEditReplyField(false)
    setReplyEditList()
  }

  const handleReplyDeleteClick = (ele, elem) => {
    setReplyEditList(elem)
    setDeleteReplyComment(true)
    setShowOptions(false)
    setShowReplyEditOptions(false)
    setDeleteComment(false)
  }

  const deleteReplyCommentClick = () => {
    post('/content_api/book.delete_chapter_comment', {
      data: {
        _id: replyEditList._id,
        chapter_id: chapterId,
      },
    }).then(() => {
      getAllComments()
    }).catch((err) => {
      throw err
    })
    setDeleteReplyComment(false)
  }
  const deleteReplyUI = (ele, elem) => (
    <div className="deleteBox">
      <span>Delete this comment thread?</span>
      <div className="btnList btnListStyle">
        <button
          type="button"
          onClick={() => deleteReplyCommentClick(ele, elem)}
          style={{ fontWeight: 'bold' }}
          className="btnCommentList"
        >
          Delete
        </button>
        <button
          type="button"
          onClick={handleCancelClick}
          style={{ fontWeight: 'bold' }}
          className="btnCancelList"
        >
          Cancel
        </button>
      </div>
    </div>
  )

  useEffect(() => {
    if (showOptions || showReplyEditOptions || clickedCommentId) {
      const checkIfClickedOutside = (e) => {
        if (
          (showOptions &&
            showOptionsRef.current &&
            !showOptionsRef.current.contains(e.target)) ||
          (clickedCommentId &&
            listBoxRef.current &&
            !listBoxRef.current.contains(e.target)) ||
          (showReplyEditOptions &&
            replyOptionsRef.current &&
            !replyOptionsRef.current.contains(e.target))
        ) {
          setShowOptions(false)
          setShowReplyEditOptions(false)
          setClickedCommentId('')
        }
      }
      document.addEventListener('mousedown', checkIfClickedOutside)
      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [showOptions, showReplyEditOptions, clickedCommentId])

  const list = commentedList !== undefined && commentedList

  return list?.map((ele) => (
    <div
      className="listBox"
      ref={listBoxRef}
      key={ele._id}
      onClick={() => handleBoxClick(ele)}
      style={
        clickedCommentId === ''
          ? { display: 'block' }
          : ele._id === clickedCommentId
          ? { display: 'block' }
          : { display: 'none' }
      }
    >
      {deleteComment && ele?._id === editData?._id ? deleteUI() : null}
      <div className="ProfilelistPicInner">
        <div className="listFlex">
          <div className="ProfilelistImageContainer">
            <img
              className="ProfilelistImage"
              src={ele?.props?.profile_image}
              alt="profileImage"
            />
          </div>
          <div>
            <div className="NamelistSpan">{ele?.props?.profile_name}</div>
            <div className="dateSpan">{moment.utc(ele?.update_time).local().format('D MMM YYYY')}</div>
          </div>
        </div>
        <div>
          {ele.status === 'resolved' && <Check fill="#79BA10" />}
          {episodeTag &&  <CaretDowm
            width="20px"
            height="20px"
            fill="rgba(13, 21, 54, 0.65)"
            onClick={(e) => showOptionsClick(e, ele)}
          />}
        </div>
        {showOptions && ele?._id === editData?._id ? (
          <div className="optionsList" ref={showOptionsRef}>
            <span className="editOption" onClick={() => handleEditClick(ele)}>
              Edit
            </span>
            <span className="editOption" onClick={() => handleDeleteClick(ele)}>
              Delete
            </span>
            <span
              className="editOption"
              onClick={() => handleResolveClick(ele)}
            >
              Resolve
            </span>
          </div>
        ) : null}
      </div>
      {editField && ele?._id === editData?._id ? (
        <>
          <div className="CommentListField">
            <textarea
              value={editTextArea}
              onChange={(e) => handleChange(e)}
              className="inputListField"
              //   autoFocus
            />
          </div>
          <div className="btnList">
            <MyTooltip title="Save Changes" placement="bottom">
              <span>
                <button
                  onClick={(e) => handleSaveClick(e, ele)}
                  type="button"
                  style={{
                    backgroundColor: !btnDisable ? '#068ae2' : null,
                    color: !btnDisable ? 'white' : null,
                  }}
                  disabled={btnDisable}
                  className="btnCommentList"
                >
                  Save
                </button>
              </span>
            </MyTooltip>
            <MyTooltip title="Discard Changes" placement="bottom">
              <button
                type="button"
                onClick={handleCancelClick}
                className="btnCancelList"
              >
                Cancel
              </button>
            </MyTooltip>
          </div>
        </>
      ) : (
        <div className="commenttext">{ele?.comment} <span className='commentEdited'>{ele?.is_edited && '(edited)'}</span></div>
      )}
      {ele?.child_comments &&
        ele?.child_comments?.map((elem) => (
          <div key={elem._id} className="replyBoxList">
            {deleteReplyComment && elem?._id === replyEditList?._id
              ? deleteReplyUI(ele, elem)
              : null}
            <div className="ProfilelistPicInner">
              <div className="listFlex">
                <div className="ProfilelistImageContainer">
                  <img
                    className="ProfilelistImage"
                    src={elem?.props?.profile_image}
                    alt="profileImage"
                  />
                </div>
                <div>
                  <div className="NamelistSpan">
                    {elem?.props?.profile_name}
                  </div>
                  <div className="dateSpan">{moment.utc(elem?.update_time).local().format('D MMM YYYY')}</div>
                </div>
              </div>
              <div>
              {episodeTag && <CaretDowm
                  width="20px"
                  height="20px"
                  fill="rgba(13, 21, 54, 0.65)"
                  onClick={(e) => showReplyOptionsClick(e, ele, elem)}
                />}
              </div>
              {showReplyEditOptions && elem?._id === replyEditList?._id ? (
                <div className="optionsList" ref={replyOptionsRef}>
                  <span
                    className="editOption"
                    onClick={() => handleReplyEditClick(ele, elem)}
                  >
                    Edit
                  </span>
                  <span
                    className="editOption"
                    onClick={() => handleReplyDeleteClick(ele, elem)}
                  >
                    Delete
                  </span>
                </div>
              ) : null}
            </div>
            {editReplyField && elem?._id === replyEditList?._id ? (
              <>
                <div className="CommentListField">
                  <textarea
                    value={editReplyTextField}
                    onChange={(e) => handleReplyEditChange(e)}
                    className="inputListField"
                  />
                </div>
                <div className="btnList">
                  <MyTooltip title="Save Changes" placement="bottom">
                    {/* <span> */}
                    <button
                      onClick={() => handleReplySaveClick(ele, elem)}
                      type="button"
                      style={{
                        backgroundColor: '#068ae2',
                        color: 'white',
                      }}
                      className="btnCommentList"
                    >
                      Save
                    </button>
                    {/* </span> */}
                  </MyTooltip>
                  <MyTooltip title="Discard Changes" placement="bottom">
                    <button
                      type="button"
                      onClick={handleEditReplyCancelClick}
                      className="btnCancelList"
                    >
                      Cancel
                    </button>
                  </MyTooltip>
                </div>
              </>
            ) : (
              <div className="commenttext">{elem?.comment} <span className='commentEdited'>{elem?.is_edited && '(edited)'}</span></div>
            )}
          </div>
        ))}
      {replyBox && ele?._id === editData?._id ? (
        <div className="replyBox">
          <textarea
            value={replyInputVal}
            onChange={(e) => handleReplyChange(e)}
            className="inputListField"
            placeholder="Reply"
          />
          {replyInputVal.length > 0 ? (
            <div className="btnList">
              <MyTooltip title="Reply to comment" placement="bottom">
                <button
                  onClick={() => handleSaveReplyClick(ele)}
                  type="button"
                  className="btnCommentList"
                  style={{
                    backgroundColor: '#068ae2',
                    color: 'white',
                  }}
                >
                  Save
                </button>
              </MyTooltip>
              <MyTooltip title="Discard comment" placement="bottom">
                <button
                  type="button"
                  onClick={handleReplyCancelClick}
                  className="btnCancelList"
                >
                  Cancel
                </button>
              </MyTooltip>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  ))
}

export default CommentBoxList
