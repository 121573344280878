import React, { useState } from 'react'

import styled from 'styled-components'
import { getNameFromUrl } from '../../lib/utils'

const FileContainer = styled.div`
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 4px;
  border: ${({ hasError }) =>
    hasError ? 'solid 1px var(--Punch500)' : 'none'};
  background-color: var(--Grey300);
`
const FilePlaceHolder = styled.div`
  font-size: ${props => props.fontSize ? props.fontSize : '14px'};
  font-weight: 600;
  line-height: 1.43;
`
const ProgressDiv = styled.div`
  padding: 14px 16px;
  background-color: var(--Grey100);
  border-radius: 4px;
  display: flex;
  align-items: center;
`
const ProgressPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
  margin-right: 10px;
`
const LinearProgress = styled.div`
  width: calc(100% - 140px);
  height: 8px;
  border-radius: 2px;
  background-color: var(--Grey900);
  overflow: hidden;
  margin-right: 16px;
`
const ProgressBar = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: var(--Azure500);
`

const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;
`
const FileNameDiv = styled.div`
  display: flex;
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  &:hover ${HoverActions} {
    display: flex;
  }
`

const FileDetailsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  line-height: 1.43;
  color: var(--Text500);
`

const UploadButton = styled.button`
  outline: none;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  background: none;
  cursor: pointer;
`

export default function AssessmentUploadComponent({
  readOnly,
  isLoading,
  progress,
  imageUrl,
  placeholder,
  handleReUpload = () => null,
  title,
  hasError,
  cancelRequest,
  subLabel,
}) {
  return (
    <>
      {!imageUrl && !isLoading && (
        <FileContainer hasError={hasError}>
          {placeholder && <FilePlaceHolder fontSize={'1.2rem'}>{placeholder}</FilePlaceHolder>}
          {subLabel && <small>{subLabel}</small> }
        </FileContainer>
      )}
      {isLoading && (
        <ProgressDiv>
          <LinearProgress>
            <ProgressBar value={progress >= 100 ? 100 : progress} />
          </LinearProgress>
          <ProgressPercent>
            {(progress >= 100 ? '100' : progress.toFixed(0)) + '% Uploaded'}
          </ProgressPercent>
        </ProgressDiv>
      )}
      {imageUrl && !isLoading && (
        <FileNameDiv>
          <FileDetailsDiv>
            <FileName>{getNameFromUrl(imageUrl, title)}</FileName>
            {!readOnly && (
              <UploadButton type="button" onClick={handleReUpload}>
                Upload
              </UploadButton>
            )}
          </FileDetailsDiv>
          <HoverActions>
            {!readOnly ||
              (true && <UploadButton type="button">Re Upload</UploadButton>)}
          </HoverActions>
        </FileNameDiv>
      )}
    </>
  )
}
