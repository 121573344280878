import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import ProWriterLogo from '../../components/Images/ProWriterLogo'
import PocketFMIcon from '../../components/Images/PocketFMIcon'
import ChevronRight from '../../components/Images/ChevronRight'
import BenefitForWriterSection from './BenefitForWriterSection/Index'
import HowItWorkSection from './HowItWorkSection/Index'
import Testimonials from './Testimonials/Index'
import FaqsSection from './FaqsSection/Index'
import FooterSection from './FooterSection/Index'
import wave1 from './images/wave1.svg'
import wave2 from './images/wave2.svg'
import Machine from './images/machine.png'

const section = css`
  padding: 32px 48px;
  color: ${({ theme }) => theme.colors.text.text900};
  transition: all 0.2s;
  @media (max-width: 600px) {
    padding: 16px;
  }
`
const ProWritersHeader = styled.header`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: var(--Raven);
  color: var(--clay-text-clay-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 48px;
  transition: all 0.2s;

  @media (max-width: 700px) {
    padding: 16px 8px;
  }
`
const ProWritersHeaderLinks = styled.div``
const ProWritersHeaderLink = styled.a`
  color: inherit;
  margin: 0px 16px;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.2s;
  &:last-of-type {
    border: 1px solid #62667c;
    border-radius: 4px;
    padding: 6px 8px;
    margin-right: 0px;
  }
  @media (max-width: 700px) {
    margin: 0px 8px;
    font-size: 12px;
  }
  @media (max-width: 700px) {
    font-size: 10px;
  }
`
const Banner = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: end;
  align-items: center;
  height: 300px;
  color: var(--bg-alt);
  position: relative;
  padding: 32px 10vw;
  padding-bottom: 0px;
  background-color: #030303;
  transition: all 0.2s;
  @media (max-width: 770px) {
    padding: 16px;
    padding-right: 0px;
    justify-items: normal;
    height: auto;
  }
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`
const BannerTextContainer = styled.div`
  width: 100%;
  transition: all 0.2s;
  @media (max-width: 600px) {
    width: 100%;
    min-height: 200px;
  }
`
const BannerHeading = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 8px auto;
  transition: all 0.2s;
  @media (max-width: 770px) {
    font-size: 20px;
  }
`

const BannerSVGContainer = styled.div`
  overflow: hidden;
  img {
    max-width: 350px;
    width: 100%;
    height: 300px;
  }
  @media (max-width: 600px) {
    img {
      display: none;
    }
  }
`
const chevronArrowAnimation = keyframes`
  0%{
    transform: translateX(0px)
  }
  100%{
    transform: translateX(5px)
  }
`
const createNewNovel = css`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  height: 64px;
  width: 100%;
  max-width: 360px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  color: var(--bg-alt);
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
  -webkit-tap-highlight-color: transparent;
  margin: 0px auto;
  svg {
    margin-left: 16px;
    animation: ${chevronArrowAnimation} 0.25s linear infinite alternate;
  }
`
const BannerCreateNovelBtn = styled.button`
  ${createNewNovel}
  z-index: 1;
  margin: 0px;
  transition: all 0.2s;
  background: linear-gradient(215deg, #0068e1, #b0d5ff);
  @media (max-width: 600px) {
    max-width: 280px;
  }
`
const wave = css`
  margin-top: 0px;
  ${section}
  transition: all 0.2s;
  @media (max-width: 600px) {
    margin-top: 32px;
    background: var(--bg-alt);
  }
`
const MarginTopNegative = styled.div`
  margin-top: -70px;
`
const WaveComponentLower = styled.div`
  ${wave}
  background:   url(${wave1}) top/cover no-repeat;
`
const WaveComponentUpper = styled.div`
  ${wave}
  background:   url(${wave2}) top/cover no-repeat;
`
const mobileBreakPoint = 600 //600px viewport width breakpoint

const ProfessionalWriterPage = (props) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < mobileBreakPoint ? true : false
  )
  const resizeWindowHandler = () =>
    setIsMobileView(window.innerWidth < mobileBreakPoint ? true : false)
  useEffect(() => {
    window.addEventListener('resize', resizeWindowHandler)
    return () => {
      window.removeEventListener('resize', resizeWindowHandler)
    }
  }, [])
  const createNewNovelHandler = () =>
    (window.location.href = `/professional-writer-signup`)

  return (
    <>
      <ProWritersHeader>
        <PocketFMIcon
          width={isMobileView ? '100' : '132'}
          height={isMobileView ? '22' : '30'}
        />
        <ProWritersHeaderLinks>
          <ProWritersHeaderLink target="_blank" href="mailto:work@pocketfm.com">
            Contact Us
          </ProWritersHeaderLink>
          <ProWritersHeaderLink href="#faq">FAQ's</ProWritersHeaderLink>
          <ProWritersHeaderLink onClick={createNewNovelHandler}>
            {isMobileView ? 'Pro Author' : 'Become a Pro Author'}
          </ProWritersHeaderLink>
        </ProWritersHeaderLinks>
      </ProWritersHeader>
      <Banner>
        <BannerTextContainer>
          <ProWriterLogo
            width={isMobileView ? '95' : '162'}
            height={isMobileView ? '23' : '50'}
          />
          <BannerHeading>
            Start your journey to reach millions with your writing skills...
          </BannerHeading>
          <BannerCreateNovelBtn onClick={createNewNovelHandler}>
            <span>Become a proAuthor</span>
            <ChevronRight fillOpacity="1" />
          </BannerCreateNovelBtn>
        </BannerTextContainer>
        <BannerSVGContainer>
          <img src={Machine} alt={'Pocket FM pro writer story'} />
        </BannerSVGContainer>
      </Banner>
      <WaveComponentLower />
      <BenefitForWriterSection />
      <WaveComponentUpper />
      <HowItWorkSection createNewNovelHandler={createNewNovelHandler} />
      <WaveComponentLower />
      <Testimonials createNewNovelHandler={createNewNovelHandler} />
      <FaqsSection />
      <MarginTopNegative>
        <WaveComponentUpper />
      </MarginTopNegative>
      <FooterSection createNewNovelHandler={createNewNovelHandler} />
    </>
  )
}

export default ProfessionalWriterPage
