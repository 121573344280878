import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Welcome from './components/Welcome'
import LandingPage from './components/LandingPage'
import Home from './pages/Home'
import WritersBenefit from './pages/WritersBenefit'
import Income from './pages/Income'
import Audiobooks from './pages/Shows/Audiobooks'
import Novels from './pages/Shows/Novels'
import Profile from './components/Profile'
import AudiobookActionPage from './pages/Shows/Audiobooks/Unpublished/actions'
import NovelActionPage from './pages/Shows/Novels/action'
import EditBookChapter from './pages/Shows/Audiobooks/Unpublished/Edit/chapterEditor'
import EditEpisodeChapter from './pages/Shows/Audiobooks/Published/ShowDetails/episodechapterEditor'
import ComingSoon from './pages/ComingSoon'
import Comments from './pages/Comments'
import Logout from './components/Logout'
import { isLoggedIn } from './lib/utils'
import ViewAuditions from './pages/Shows/Audiobooks/Auditions/ViewAuditions'
import WritingTips from './pages/Shows/Novels/writingTips';
import ProfessionalWriterSignup from './components/ProfessionWriterSignup';
import WriterBasicDetails from './components/ProfessionWriterSignup/WriterBasicDetails';
import EditWriterProfile from './components/ProfessionWriterSignup/EditWriterProfile';
import GlobalLogin from './components/GlobalLogin';
import Resetpassword from './components/GlobalLogin/resetPassword';
import ProfessionalWriterPage from './pages/ProfessionalWriterPage'
import EpisodeScriptEditor from './components/EpisodeScriptEditor'

const isRolePermitted = () => true
export default class Routes extends Component {
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => {
      let redirectToLogin = !isLoggedIn() || !isRolePermitted
      return <Route
        {...rest}
        render={props => (
          !redirectToLogin ? (
            <>
              <Component
              {...props}
            />
            </>
          ) : (
            <Redirect to={{
              pathname: isLoggedIn ? '/logout' : '/',
              state: { from: props.location }
            }} />
          )
        )
        }
      />
    }

    return (
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route
          path="/professional-writer-steps"
          component={ProfessionalWriterPage}
          exact
        />
        <Route path="/logout" component={Logout} />
        <Route path="/login" component={Welcome} />
        <Route path="/global-login" component={GlobalLogin} />
        <Route path="/reset-password" component={Resetpassword} />
        <Route
          path="/professional-writer-signup"
          component={ProfessionalWriterSignup}
        />
        <Route path="/writer-basic-details" component={WriterBasicDetails} />
        <Route path="/edit-writer-profile" component={EditWriterProfile} />
        <Route path="/author-welfare" component={WritersBenefit} exact />
        <Route path="/author-welfare-global" component={WritersBenefit} exact />
        <PrivateRoute path="/shows/novels" component={Novels} exact />
        <PrivateRoute
          path="/shows/novels/writing-tips"
          component={WritingTips}
          exact
        />
        <PrivateRoute
          path="/shows/novels/:action?/:id?"
          component={NovelActionPage}
          exact
        />
        <PrivateRoute path="/shows/audiobooks" component={Audiobooks} exact />
        <PrivateRoute
          path="/shows/audiobooks/edit-book-chapter/:id?"
          component={EditBookChapter}
          exact
        />
        <PrivateRoute
          path="/shows/audiobooks/add-edit-task-episode/:id?"
          component={EpisodeScriptEditor}
          exact
        />
        <PrivateRoute
          path="/shows/audiobooks/edit-episode-chapter/:id?"
          component={EditEpisodeChapter}
          exact
        />
        <PrivateRoute
          path="/shows/audiobooks/view-auditions/:id?"
          component={ViewAuditions}
          exact
        />
        <PrivateRoute
          path="/shows/audiobooks/:action?/:id?"
          component={AudiobookActionPage}
          exact
        />
        <PrivateRoute path="/shows/podcasts" component={ComingSoon} />
        <PrivateRoute path="/analytics/engagement" component={ComingSoon} />
        <PrivateRoute path="/analytics/audience" component={ComingSoon} />
        <PrivateRoute path="/community/comments" component={Comments} />
        <PrivateRoute path="/community/reviews" component={Comments} />
        <PrivateRoute path="/edit-profile" component={Profile} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/writers-benefit" component={WritersBenefit} />
        <PrivateRoute path="/income" component={Income} />
      </Switch>
    )
  }
}
