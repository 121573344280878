import React from 'react'
import styled from 'styled-components'
import AuthorIcon from '../../../../../components/Images/Pen'
import CreatorIcon from '../../../../../components/Images/Mic'
import CreatorInfo from '../../../../../components/CreatorInfo'
import { useCreatorProfile } from '../../../../../lib/customHooks'
import {convertUrlToHttps} from '../../../../../lib/utils'
import { menuItemObj } from '../../../../../components/PrivatePage'

const BookSummary = styled.div`
  padding: 16px 24px;
  cursor: pointer;
  display: flex;
  background-color: var(--Dove);
  margin-bottom: 4px;
  position: relative;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }

  &:last-child {
    margin-bottom: 100px;
  }
`
const BookImageDiv = styled.div`
  width: 56px;
  height: 56px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--Grey900);
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`
const BookImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const BookDetails = styled.div`
  margin-right: 16px;
`
const LiveInfo = styled.div`
  margin-left: auto;
  min-width: 20%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const LiveStatusText = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: rgba(13, 21, 54, 0.65);
  border-radius: 24px;
  border: solid 1px rgba(13, 21, 54, 0.2);
  background-color: rgba(13, 21, 54, 0.1);
  display: inline-block;
`
const BookTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  align-items: center;
  margin-bottom: 4px;
`
const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-bottom: 16px;
`
const ArtistsContainer = styled.div`
  display: flex;
`
export const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`
const ProfileImageDiv = styled.div`
  position: relative;
`
export const StyledAuthorIcon = styled(AuthorIcon)``
export const StyledCreatorIcon = styled(CreatorIcon)``
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
export const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 4px;
`
const BookCardCms = ({
  data: {
    show_id,
    image_url,
    show_title,
    language,
    is_live,
    author_info: { fullname: author_name, author_id } = {},
    vo_artist_info: { fullname: artist_name, uid },
    translator_info: { fullname: translator_name, translator_id } = {}
  },
  hideStatus,
  history,
}) => {
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } = useCreatorProfile()
  const handleUserClick = (e, id) => {
    e.stopPropagation()
    onUserClick(id)
  }
  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <BookSummary
        key={show_id}
        onClick={() =>
          history.push(`${menuItemObj.audiobooks}?tab=published&id=${show_id}`)
        }
      >
        <BookImageDiv>{image_url && <BookImage src={convertUrlToHttps(image_url,true)} />}</BookImageDiv>
        <BookDetails>
          <BookTitle>
            {show_title} &middot; {language && (language[0].toUpperCase() + language.slice(1))}
            {/* {getTopicNameFromId(category) && <CategorySpan>{getTopicNameFromId(category)}</CategorySpan>} */}
          </BookTitle>
          <ArtistsContainer>
            {author_name && (
              <ArtistDetails onClick={(e) => handleUserClick(e, author_id)}>
                <StyledAuthorIcon />
                <ArtistName>{author_name}</ArtistName>
              </ArtistDetails>
            )}
            {artist_name && (
              <ArtistDetails onClick={(e) => handleUserClick(e, uid)}>
                <StyledCreatorIcon />
                <ArtistName>{artist_name}</ArtistName>
              </ArtistDetails>
            )}
            {translator_name && <ArtistDetails onClick={(e) => handleUserClick(e, translator_id)}>
              <StyledAuthorIcon />
              <ArtistName>{translator_name}</ArtistName>
            </ArtistDetails>}
          </ArtistsContainer>
        </BookDetails>
        {!hideStatus && !is_live && (
          <LiveInfo>
            <LiveStatusText>Not Live</LiveStatusText>
          </LiveInfo>
        )}
      </BookSummary>
    </>
  )
}
export default BookCardCms
