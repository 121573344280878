import React from 'react'
import styled from 'styled-components'
import Step1 from '../images/Step1'
import Step2 from '../images/Step2'
import Step3 from '../images/Step3'

const SectionContainer = styled.section`
  width: 100%;
  padding: 2rem;
  background-color: #030303;
`
const Wrapper = styled.div`
  width: 75%;
  margin: 0px auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`
const SectionHeading = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--bg-alt);
  text-align: center;
`
const SectionSubHeading = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: var(--bg-alt);
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const StepsHeading = styled.h3`
  background-image: linear-gradient(264deg, #0068e1 100%, #b0d5ff 0%);
  font-family: Inter;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`
const Step = styled.div`
  width: 25%;
  min-width: 10rem;
  svg {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 2.5rem;
  }
`
const StepsText = styled.div`
  font-size: 1rem;
  line-height: 2;
  text-align: center;
  color: var(--bg-alt);
`

const Button = styled.button`
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
`
const BtnText = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(100deg, #b0d5ff 5%, #0068e1 65%);
`
const CenterBox = styled.div`
  display: flex;
  width: 100%;
  height: 12rem;
  justify-content: center;
  align-items: center;
`
const stepArray = [
  {
    step: 'step1',
    stepImage: Step1,
    heading: 'Complete your Profile',
    stepText:
      'Click on “Become a ProAuthor” button. Add the required details to your profile',
  },
  {
    step: 'step2',
    stepImage: Step2,
    heading: 'Attempt the Assessment',
    stepText:
      'Proceed to the assessment page. Download the instructions, attempt the exercise & submit it.',
  },
  {
    step: 'step3',
    stepImage: Step3,
    heading: 'Submission Review',
    stepText:
      'Our team will review your submission. If selected, you will get the opportunity to write for one of our audio shows!',
  },
]

function Index({ createNewNovelHandler }) {
  return (
    <>
      <SectionContainer>
        <Wrapper>
          <SectionHeading>How it works</SectionHeading>
          <SectionSubHeading>
            Three simple steps to get you started
          </SectionSubHeading>
          <StepsContainer>
            {stepArray.map((data) => {
              return (
                <Step key={data.step}>
                  {data.step === 'step1' && <Step1 />}
                  {data.step === 'step2' && <Step2 />}
                  {data.step === 'step3' && <Step3 />}
                  {data.stepImage}
                  <StepsHeading>{data.heading}</StepsHeading>
                  <StepsText>{data.stepText}</StepsText>
                </Step>
              )
            })}
          </StepsContainer>
          <CenterBox>
            <Button onClick={createNewNovelHandler}>
              <BtnText>Become a ProAuthor</BtnText>
            </Button>
          </CenterBox>
        </Wrapper>
      </SectionContainer>
    </>
  )
}

export default Index
