import React from 'react'
import {
  Container,
  StartText,
  DescriptionText,
  EmptyAudioBookIcon
} from './styles'
import { Text,Button } from '../../../../../designSystem';
import { menuItemObj } from '../../../../../components/PrivatePage'

const EmptyState = ({ text, subtext = '' ,history,showButton}) => {
  const subtextArray = Array.isArray(subtext) ? subtext : [subtext]
  return (
    <Container>
      <EmptyAudioBookIcon />
      <StartText>{ text }</StartText>
      {subtext && subtextArray.map((txt, i, arr) => (
        <DescriptionText  key={i} firstIndex={i === 0}>
          {txt}
        </DescriptionText>
       
      ))}
      {showButton && <Button width={["100%", "unset"]} mt={4} onClick={() => history.push(`${menuItemObj.audiobooks}/new-concept?process=createNewConcept`)} >
            New Concept
                  </Button>}
    </Container>
  )
}
export default EmptyState
