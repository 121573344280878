import React from 'react'
import Assessment from './Assessment'
import SubMission from './SubMissionUI'
import ReviewUI from './ReviewUI'

export default function ViewScreen({ screenName, setAssessmentStatus, reviewStatus }) {
  switch (screenName) {
    case 'Assessment':
      return <Assessment setAssessmentStatus={setAssessmentStatus} />
    case 'Review':
      return (
        <ReviewUI reviewStatus={reviewStatus} />
      )
    case 'Submission':
      return <SubMission />
  }
  return null
}
