import React, { useState } from 'react'
import styled from 'styled-components'
import { promotional_text_unpublished } from '../constants'
import { WBPThumbnail, WbpDetails, Title, Description, BookAction } from './bookCard'
import { withRouter } from 'react-router-dom'
import { get } from '../../../lib/api'
import Loader from '../../../components/Loader'
import { menuItemObj } from '../../../components/PrivatePage'

const WBPBookCard = styled.div`
  margin-bottom: 8px;
  display: flex;
  padding: 24px;
  background-color: var(--Dove);
`
const UnpubDescription = styled(Description)`
  margin-bottom: 40px;
`

const UnpublishedBookCard = ({ history, book }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handlePublishClick = () => {
    if (isLoading) return
    setIsLoading(true)
    get('/content_api/book.chapters_by_status', {
      params: {
        page_no: 1,
        book_id: book.book_id,
        status: 'draft',
        view: 'dashboard'
      },
    }).then(response => {
      setIsLoading(false)
      const { results } = response
      const firstChapter = results.chapters[0] || {}
      const { chapter_id } = firstChapter
      if (chapter_id) {
        history.push(`${menuItemObj.novels}/edit-chapter/${book.book_id}?chapter_id=${chapter_id}`)
      } else {
        history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)
      }
    }).catch(error => {
      setIsLoading(false)
      throw error
    })
  }
  
  return (
    <WBPBookCard key={book.book_id}>
        <WBPThumbnail>
          {book.image_url && <img src={book.image_url} />}
        </WBPThumbnail>
        <WbpDetails>
          <Title>
            Publish your Novel to Make Money
          </Title>
          <UnpubDescription>{promotional_text_unpublished}</UnpubDescription>
          <BookAction
            onClick={handlePublishClick}
            enabled={1}
          >
            {!isLoading && 'Publish'}
            {
              isLoading && <Loader invertColor inline size="sm" />
            }
          </BookAction>
        </WbpDetails>
    </WBPBookCard>
  )
}

export default withRouter(UnpublishedBookCard)
