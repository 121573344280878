import styled from 'styled-components';
import { variant } from 'styled-system';
import {
  typographyFunctions,
} from '../typographyFunctions';

const defaultextStyles = {
  fontFamily: 'inherit',
  fontWeight: 'r',
  lineHeight: 'P.M',
  fontSize: 'P.M',
  color: 'text.text500',
  mt: 0,
  mb: 0,
  letterSpacing: 'normal' // TODO: define this is global, after removing material global
};

const textVariants = variant({
  variants: {
    title: {
      ...defaultextStyles,
      fontWeight: 's',
      fontSize: ['M.S', 'D.S'],
      lineHeight: ['M.S', 'D.S'],
      color: 'text.text700'
    },
    titleMedium: {
      ...defaultextStyles,
      fontWeight: 'b',
      fontSize: ['M.L', 'D.L'],
      lineHeight: ['M.L', 'D.L'],
      color: 'text.text700'
    },
    titleLarge: {
      ...defaultextStyles,
      fontWeight: 'b',
      fontSize: ['M.XXL', 'D.XXL'],
      lineHeight: ['M.XXL', 'D.XXL'],
      color: 'text.text700'
    },
    titleSecondary: {
      ...defaultextStyles,
      fontWeight: 's',
      fontSize: ['M.S', 'D.S'],
      lineHeight: ['M.S', 'D.S'],
      color: 'text.text500'
    },
    heading: {
      ...defaultextStyles,
      fontWeight: 'b',
      color: 'text.text700',
      fontSize: ['M.S', 'D.M'],
      lineHeight: ['M.S', 'D.M']
    },
    subtext: {
      ...defaultextStyles,
      color: 'text.text300',
      fontSize: ['M.XXS', 'D.XXS'],
      lineHeight: ['M.XXS', 'D.XXS'],
    },
    smallText: {
      ...defaultextStyles,
      color: 'text.text500',
      fontSize: ['M.XXS', 'D.XXS'],
      lineHeight: ['M.XXS', 'D.XXS'],
    },
    body: {
      ...defaultextStyles
    },
    bodyLarge: {
      ...defaultextStyles,
      fontSize: ['M.M', 'D.M'],
      lineHeight: ['M.M', 'D.M'],
    },
    caption: {
      ...defaultextStyles,
      color: 'text.text500',
      fontSize: ['M.XS', 'D.XS'],
      lineHeight: ['M.XS', 'D.XS'],
    },
    smallTag: {
      ...defaultextStyles,
      fontWeight: 's',
      color: 'text.text700',
      fontSize: ['M.XS', 'D.XS'],
      lineHeight: ['M.XS', 'D.XS'],
    },
    hint: {
      ...defaultextStyles,
    },
    label: {
      ...defaultextStyles,
    },
  },
});

export const Text = styled.p`
  ${textVariants}
  ${typographyFunctions}
`;

Text.defaultProps = {
  variant: 'body',
};
