import React from 'react'

const CrossDark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#0D1536" fillOpacity=".65" d="M16.293 17.707c.39.39 1.024.39 1.414 0 .39-.39.39-1.024 0-1.414L13.414 12l4.293-4.293c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L12 10.586 7.707 6.293c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L10.586 12l-4.293 4.293c-.39.39-.39 1.024 0 1.414.39.39 1.024.39 1.414 0L12 13.414l4.293 4.293z" />
  </svg>

)

export default CrossDark
