import React from "react";
import { Box } from "../../../../../designSystem";
import styled from 'styled-components'

const QCTable = styled.table`
    width: 100%;
    // border-spacing: 8px 16px;
    border: solid 1px black;
    border-collapse: collapse;
    margin-top: 4px;
`

const RowHeading = styled.th`
    border: solid 1px black;
    padding: 8px 4px;
`


const RowColumn = styled.td`
    margin: 8px;
    text-align:center;
    border: solid 1px black;
    padding: 8px 4px;
`

const StyledTextAreField = styled.div`
    width: 100%;
    text-align:center;
    height: 48px;
    border:none;
    padding: 8px;
    // background-color: var(--grey-300);
    outline: 0;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--Text700);
    cursor: 'not-allowed';
`

const QCNoteTable = ({qcNotes = []}) => {
    return(
        <Box py={16} mb={48} >
            <h3>QC Notes</h3>
            <QCTable>
                <tr>
                    {/* <RowHeading>S.No.</RowHeading> */}
                    <RowHeading>Time</RowHeading>
                    <RowHeading>Issue Type</RowHeading>
                    <RowHeading>Category</RowHeading>
                    <RowHeading>Remarks</RowHeading>
                    <RowHeading>Status</RowHeading>
                </tr>
                {Array.isArray(qcNotes) && qcNotes?.map((data,index) => (
                    <tr>
                        {/* <RowColumn><StyledInput type="text" value={index + 1} readOnly={true}/></RowColumn> */}
                        <RowColumn>{data.time}</RowColumn>
                        <RowColumn>{data.issue_type || ''}</RowColumn>
                        <RowColumn>{data.category || ''}</RowColumn>
                        <RowColumn>{data.remarks || ''}</RowColumn>
                        <RowColumn>{data.status || ''}</RowColumn>
                    </tr>
                ))}
            </QCTable>
        </Box>
    )
}

export default QCNoteTable