import React from "react";
import styled from "styled-components";
import AuthorIcon from "../../../../components/Images/Pen";
import CreatorIcon from "../../../../components/Images/Mic";
import { menuItemObj } from "../../../../components/PrivatePage";
import ConceptIcon from "../../../../components/Images/ConceptIcon";

const ConceptSummary = styled.div`
  padding: 16px 24px;
  cursor: pointer;
  display: flex;
  background-color: var(--Dove);
  margin-bottom: 4px;
  position: relative;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }

  &:last-child {
    margin-bottom: 100px;
  }
`;
const ConceptImageDiv = styled.div`
  width: 56px;
  height: 56px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--Grey900);
  // box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`;
const ConceptDetails = styled.div`
  margin-right: 16px;
`;
const LiveInfo = styled.div`
  margin-left: auto;
  min-width: 20%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const LiveStatusText = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--corn-800);
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  display: inline-block;
`
const ConceptTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  align-items: center;
  margin-bottom: 4px;
`;
const ArtistsContainer = styled.div`
  display: flex;
`;
export const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;
export const StyledAuthorIcon = styled(AuthorIcon)``;
export const StyledCreatorIcon = styled(CreatorIcon)``;

export const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 4px;
`;
const ConceptsCardCms = ({
  data: {
    category_details,
    concept_id,
    title,
    language,
    status,
    is_live,
    creator_details: { fullname: author_name, author_id } = {},
    translator_info: { fullname: translator_name, translator_id } = {},
  },
  hideStatus,
  history,
}) => {
  return (
    <>
      <ConceptSummary
        // ref={ref}
        key={concept_id}
        onClick={() => {
          console.log(menuItemObj);
          return history.push(
            `${menuItemObj.audiobooks}/concept-detail/${concept_id}?process=concept-detail`
          );
        }}
      >
        <ConceptImageDiv>
          <ConceptIcon></ConceptIcon>
        </ConceptImageDiv>
        <ConceptDetails>
          <ConceptTitle>
            {title} &middot;{" "}
            {language && language[0].toUpperCase() + language.slice(1)}
          </ConceptTitle>
          <ArtistsContainer>
            {author_name && (
              <ArtistDetails>
                <StyledAuthorIcon />
                <ArtistName>{author_name}</ArtistName>
              </ArtistDetails>
            )}
            {translator_name && (
              <ArtistDetails>
                <StyledAuthorIcon />
                <ArtistName>{translator_name}</ArtistName>
              </ArtistDetails>
            )}
          </ArtistsContainer>
        </ConceptDetails>
        <LiveInfo>
           {status ==="under_review" &&  <LiveStatusText>Under Review</LiveStatusText>}
           {status ==="approved" &&  <LiveStatusText>Approved</LiveStatusText>}
           {status ==="rejected" &&  <LiveStatusText>Rejected</LiveStatusText>}
          </LiveInfo>
      </ConceptSummary>
    </>
  );
};
export default ConceptsCardCms;
