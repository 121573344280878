import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Formik, Form } from 'formik'

import {
  FloatingInput,
  Upload,
} from '../../components/FormikInputs'
import Loader from '../../components/Loader'
import { menuItemObj } from '../../components/PrivatePage'
import { Box, Text } from '../../designSystem'
import { post } from '../../lib/api'
import {
  ScriptEditComponent,
  FileUploadComponent,
} from '../../../src/pages/Shows/Audiobooks/Unpublished/Edit/addEditEpisode'
import {
  CancelButton,
  SaveButton,
  StyledCrossIcon,
  SectionSubTitle,
} from '../../../src/pages/Shows/Audiobooks/Published/ShowDetails/AssetManagement/styled'

function AddTaskAssets({ handleCancel, locate, assetData, task_id, asset_id }) {
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const show_id = queryParams.get('id')
  const [audioDuration, setAudioDuration] = useState('')
  const editorUrl = `${menuItemObj.audiobooks}/add-edit-task-episode/show_id=${show_id}?`
  
  const handleOpenEditor = (values) => {
    history.push(
      `${editorUrl}&story_title=${values.title}&task_id=${task_id}&asset_id=${asset_id}&entity_id=${assetData?.entity_id}`,
      {
        tab: 'Asset Management',
        locate,
      }
    )
  }

  const handleOpenFreshEditor = (values) => {
    history.push(
      `${editorUrl}&title=${values.title}&create_new=true`
    )
  }
  return (
    <Box
      boxShadow="0 8px 16px 0 rgba(73, 83, 89, 0.16)"
      bg="surface.foreground"
      mb={8}
      borderRadius={4}
    >
      <Box variant="flex" p={20}>
        <StyledCrossIcon onClick={handleCancel} />
      </Box>
      <Formik
        initialValues={{
          title: assetData?.title || '',
          description: assetData?.description || '',
          script_url: assetData?.script_url || '',
          entity_id: assetData?.entity_id,
          audio_url: assetData?.audio_url,
          entity_assets_tasks_id: task_id,
          asset_id,
        }}
        onSubmit={(values, { setSubmitting }) => {
          const payload = values
          if (audioDuration) {
            payload.duration = audioDuration
          }
          const createBookApi = '/content_api/assets_management/assets/'
          post(createBookApi, { data: payload })
            .then(() => {
              setSubmitting(false)
              handleCancel()
            })
            .catch((error) => {
              setSubmitting(false)
              throw error
            })
        }}
      >
        {({ values = {}, isSubmitting, submitCount, errors }) => (
          <Form>
            <Box p={[16, 32]}>
              <Box variant="flex" flexDirection="column">
                <Box mb={5}>
                  <Box
                    mt={16}
                    variant="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text variant="titleSecondary">Add Assets</Text>
                  </Box>
                </Box>
                <Box>
                  <FloatingInput
                    label="Title"
                    name="title"
                    infoTitle="Give a title to this episode"
                    hasError={submitCount > 0 && errors.title}
                    type="text"
                    placeholder="Write something that describes this episode"
                  />
                </Box>
                <Box mt={16}>
                  <FloatingInput
                    asTextArea
                    rowCount={4}
                    infoTitle="Add the description of this episode"
                    label="Description"
                    name="description"
                    placeholder="Description of this episode"
                  />
                </Box>
                <Box mb={16}>
                  <ScriptEditComponent
                    label="Script Doc File"
                    name="script_url"
                    title={values.title}
                    resourceUrl={values.script_url}
                    showReadOption
                    handleOpenEditor={() => handleOpenEditor(values) }
                    handleOpenFreshEditor={() => handleOpenFreshEditor(values) }
                  />
                </Box>
                <SectionSubTitle>Upload File</SectionSubTitle>
                <Upload
                  label=""
                  name="audio_url"
                  maxFileSize={614400000}
                  hideInputOnValue
                  showProgress
                  placeholder="Upload File"
                  renderComp={FileUploadComponent}
                  fileTypeTags="media"
                  accept="audio/mpeg, audio/mp3, audio/ogg, audio/wav"
                  handleDurationUpdate={(duration) =>
                    setAudioDuration(duration.toFixed(2))
                  }
                  extraParams={{
                    title: 'asset-audio',
                  }}
                />
                <Box variant="flex" width="100%" gap={10} py={16} px={24}>
                  {/* {mode === 'edit' && <DeleteButton type="button">Delete</DeleteButton>} */}

                  <CancelButton type="button" onClick={handleCancel}>
                    Cancel
                  </CancelButton>

                  {!isSubmitting && (
                    <SaveButton
                      type="submit"
                      disabled={values?.title === ''}
                      isValid={values?.title !== ''}
                    >
                      Save
                    </SaveButton>
                  )}

                  {isSubmitting && (
                    <SaveButton type="button" isValid={values?.title !== ''}>
                      <Loader invertColor inline size="sm" />
                    </SaveButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default AddTaskAssets
