import React from 'react'

const Tools = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
		fill="#fff"
    y="0px"
    viewBox="0 0 293.129 293.129"
		{...props}
  >
    <g>
      <path
        d="M162.179,140.514c3.377-1.727,7.139-2.64,11.042-2.64c6.468,0,12.549,2.511,17.133,7.071l9.868-9.867
		c24.42,8.56,52.664,3.08,72.186-16.441c16.426-16.426,22.904-39.026,19.446-60.329c-0.381-2.346-2.042-4.281-4.303-5.011
		c-2.261-0.731-4.743-0.137-6.423,1.544l-14.652,14.652c-11.932,11.932-31.279,11.932-43.211,0
		c-11.933-11.932-11.933-31.279,0-43.211l14.652-14.652c1.681-1.681,2.28-4.163,1.548-6.425c-0.731-2.263-2.669-3.92-5.016-4.301
		c-21.302-3.458-43.903,3.02-60.328,19.446c-19.812,19.812-25.144,48.604-16.032,73.269l-21.402,21.402L162.179,140.514z"
      />
      <path
        d="M123.179,179.296l-25.385-25.385L9.029,242.675c-11.542,11.542-11.542,30.255,0,41.797
		c11.542,11.542,30.255,11.542,41.797,0l76.521-76.52C119.629,200.193,118.238,188.479,123.179,179.296z"
      />
      <path
        d="M179.795,155.597c-1.815-1.815-4.195-2.723-6.574-2.723s-4.759,0.908-6.574,2.723l-5.299,5.299L66.956,66.504l4.412-4.412
		c4.02-4.019,3.521-10.686-1.061-14.06L31.795,19.669c-3.701-2.725-8.837-2.338-12.087,0.912L3.356,36.934
		c-3.25,3.25-3.637,8.387-0.912,12.087l28.362,38.512c3.374,4.581,10.037,5.085,14.06,1.061l4.412-4.413l94.392,94.392l-5.672,5.672
		c-3.631,3.631-3.631,9.517,0,13.148l87.079,87.079c11.542,11.542,30.255,11.542,41.797,0c11.542-11.542,11.542-30.255,0-41.797
		L179.795,155.597z"
      />
    </g>
  </svg>
)

export default Tools
