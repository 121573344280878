import React, { useRef, useEffect, useState } from 'react'
import { emptyStateText } from '../../constants'
import styled from 'styled-components'
import EpisodeIcon from '../../../../../components/Images/EpisodeIcon'
import { withRouter } from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import { get } from '../../../../../lib/api'
import { isAdminAccess } from '../../../../../config'
import ClampLines from 'react-clamp-lines'
import GenericError from '../../../../../components/GenericError'
import { useInfiniteScroll } from '../../../../../lib/customHooks'
import {convertUrlToHttps} from '../../../../../lib/utils'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Image} from '../../../../../designSystem'

const SingleBook = styled.div`
  display: flex;
  padding: 24px;
  margin-bottom: 8px;
  background-color: var(--Dove);
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }
  @media (max-width : 600px){
    padding: 16px;
    flex-direction: column;
  }
`
const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.text500};
  margin-bottom: 8px;
`
const Status = styled.div`
  width: 25%;
  text-align: right;
`
const StatusTag = styled.div`
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  padding: 6px 12px;
  display: inline-block;
  text-transform: capitalize;
`
const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`
const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
`
function useDidUpdate(callback, deps) {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      callback()
    } else {
      hasMount.current = true
    }
  }, deps)
}

const isMobile = window.screen.width < 600

const BookList = ({ renderEmptyState, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState(null)
  const [apiError, toggleApiError] = useState(false)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const [nextUrl, setNextUrl] = useState(null)

  useEffect(() => {
    const bookListApi =
      '/content_api/book.creator_books'
    get(bookListApi, {
      params: {
        page_no: 1,
        view: isAdminAccess ? 'cms' : null,
        is_novel: 0,
      }
    })
      .then((response) => {
        const { results } = response
        setBooks(results.books)
        setNextUrl(results.next_url)
      })
      .catch((error) => {
        // TODO: remove this hack for failing api
        if (error.code === 400) {
          setBooks([])
        } else {
            setIsLoading(false)
            throw error
          }
      })
  }, [])

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { results } = response
        setBooks(prevState => prevState.concat(results.books))
        setNextUrl(results.next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }


  useDidUpdate(() => {
    setIsLoading(false)
  }, [books])

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    )
  }
  return (
    <>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      {!books && <BlankPaper />}
      {books &&
        !!books.length &&
        books.map((book) => (
          <SingleBook
            key={book.book_id}
            onClick={() =>
              history.push(`${menuItemObj.audiobooks}/edit/${book.book_id}`)
            }
          >
              <Box  width={["100%","80%"]} variant="flex" flexDirection='row' justifyContent="flex-start" width="100%">
                <Image mr={[8, 24]} variant="thumbnail" width={[60,144]} height={[60,144]} src={convertUrlToHttps(book.image_url,true)} />
                <Box height={[80,144]} width="70%">
                  <Text variant="title" fontWeight="r">{book.book_title}</Text>
                  {book.description && !isMobile && <Description><ClampLines lines={3} text={book.description} id="unpublished-book-description-listing" buttons={false}></ClampLines></Description>}
                  <Box variant="flex" flexDirection="row" justifyContent="flex-start" my={8} mt={[8, 24]}>
                    {/* <EpisodeIcon /> */}
                    <Text variant="subtext">{book.chapter_count} {book.chapter_count === 1 ? "Chapter" : "Chapters"}</Text>
                  </Box>
                  {isMobile && book.status && (
                    <Box align="right" ml={[0,'auto']}>
                      <StatusTag>{book.status}</StatusTag>
                      </Box>
                  )}
                </Box>
              </Box>
              
                {!isMobile && book.status && (
                  <Box align="right" ml={[0,'auto']}>
                    <StatusTag>{book.status}</StatusTag>
                    </Box>
                )}
          </SingleBook>
        ))}
        {isFetching && <Loader />}
      {books &&
        !books.length &&
        renderEmptyState(
          emptyStateText['Unpublished']['text'],
          emptyStateText['Unpublished']['subtext']
        )}
    </>
  )
}

export default withRouter(BookList)
