import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { get } from '../../../lib/api'

const Container = styled.div``
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 0;
  right: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1;
`
const Option = styled.div`
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);

  &:hover {
    background-color: var(--Grey500);
  }
`
const Name = styled.div``
const Email = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`
const Input = styled.input`
  flex-grow: 1;
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
  margin-top: 3px;
  min-height: 36px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  padding: 6px 8px;
  &:focus {
    outline: none;
    background-color: var(--Dove);
    width: 300px;
    border: solid 2px var(--Azure500);
    padding: 5px 7px;
  }
`
const DropDownHolder = styled.div`
  position: relative;
`

const CMSearch = ({
  placeholder,
  ...props
}) => {
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [field, meta] = useField(props)
  const { value = '', onChange } = field
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const newHandleChange = (option) => {
    toggleDropDownOpen(false)
    setInputValue(option.fullname)
    onChange({ target: { name: props.name, value: option.uid } })
  }

  const handleInputChange = e => {
    toggleDropDownOpen(true)
    setInputValue(e.target.value)
  }

  const handleClick = () => {
    toggleDropDownOpen(false)
    if (!options.length) return
    if (value) {
      const selectedOption = options.find(opt => opt.uid === value) || {}
      const fullname = selectedOption.fullname || ''
      setInputValue(fullname)
    } else {
      // setInputValue(tempValue)
    }
  }

  useEffect(() => {
    get('/user_api/user.get_community_manager_details').then(response => {
      setOptions(response.result.filter(opt => opt.email))
      if (value) {
        const selectedOption = (response.result.find(obj => obj.uid === value) || {}).fullname
        setInputValue(selectedOption)
      }
    })
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const getOptions = () => {
    return options.filter(option => option.fullname && (option.fullname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1))
  }
  return (
    <Container>
      {/* <SelectionField> */}
        <Input
          onChange={e => handleInputChange(e)}
          value={inputValue}
          placeholder={placeholder}
        />
      {/* </SelectionField> */}
      <DropDownHolder>
      <OptionsDropDown isOpen={isOpen} >
        {getOptions().map((option) => (
          <Option key={"community-manager-search-" + option.uid} onClick={() => newHandleChange(option)}>
            <Name>
              {option.fullname}
            </Name>
            {/* <Email>
              {option.email}
            </Email> */}
          </Option>
        ))}
      </OptionsDropDown>
      </DropDownHolder>
    </Container>
  )
}

export default CMSearch
