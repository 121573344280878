import React from 'react'

const DustbinBlack = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
    <path fill="rgba(13, 21, 54, 0.8)" fillRule="evenodd" d="M26.91 6.485h-5.48v-.833c0-1.646-1.346-2.985-3-2.985h-5.275c-1.654 0-3 1.339-3 2.985v.833H4.675c-.448 0-.807.357-.807.803 0 .446.359.803.807.803h1.271v17.237c0 2.208 1.806 4.005 4.025 4.005h11.645c2.219 0 4.025-1.797 4.025-4.005V8.091h1.27c.449 0 .808-.357.808-.803 0-.446-.36-.803-.807-.803zm-2.878 18.777h-.006v.066c0 1.322-1.083 2.4-2.411 2.4H9.97c-1.328 0-2.411-1.078-2.411-2.4V8.091h16.473v17.171zM11.769 5.652c0-.76.623-1.38 1.386-1.38h5.275c.763 0 1.386.62 1.386 1.38v.833h-8.047v-.833z" clipRule="evenodd"/>
    <path fill="rgba(13, 21, 54, 0.8)" fillRule="evenodd" d="M15.784 25.2c.445 0 .8-.36.8-.807V11.312c0-.448-.355-.807-.8-.807-.444 0-.8.359-.8.807v13.075c0 .448.355.813.8.813zM10.954 24.383c.444 0 .8-.36.8-.807V12.123c0-.448-.356-.807-.8-.807-.445 0-.8.359-.8.807v11.453c0 .448.361.807.8.807zM20.615 24.383c.444 0 .8-.36.8-.807V12.123c0-.448-.356-.807-.8-.807-.444 0-.8.359-.8.807v11.453c0 .448.356.807.8.807z" clipRule="evenodd"/>
  </svg>
)

export default DustbinBlack
