import React, { Component } from 'react'
import styled from 'styled-components'

const SecondaryBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  border: solid 2px var(--Azure500);
  width: ${({ fullWidth }) => fullWidth ? '100%' : '100px'};
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Azure500);
  background-color: var(--Dove);
  opacity: ${({ active }) => active ? '1' : '0.3'};

  svg {
    margin-right: 4px;
  }
`
const PrimaryBtn = styled.button`
  cursor: pointer;
  display: flex;
  width: ${({ fullWidth }) => fullWidth ? '100%' : '100px'};
  height: 36px;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
  opacity: ${({ active }) => active ? '1' : '0.3'};

  svg {
    margin-right: 4px;
  }
`
class PrimaryButton extends Component {
  render () {
    const { children, ...restProps } = this.props
    return (
      <PrimaryBtn
        type="button"
        {...restProps}
      >
        {children}
      </PrimaryBtn>
    )
  }
}

class SecondaryButton extends Component {
  render () {
    const { children, ...restProps } = this.props
    return (
      <SecondaryBtn
        type="button"
        {...restProps}
      >
        {children}
      </SecondaryBtn>
    )
  }
}

export {
  PrimaryButton, SecondaryButton
}
