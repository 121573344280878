import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { get } from '../../lib/api'
import {
  FilterHolder,
  ApplyFilterDiv,
  ApplyFilterText,
  AddIcon,
  SelectFiltersDiv,
  FilterHeaderDiv,
  FilterHeaderText,
  CrossIcon,
  FilterDiv,
  FilterText,
  SelectedFilterDiv,
  SelectedFilterText,
  FilterBody,
  CrossLightIcon,
  Search,
  FilterShowImage,
  ShowCreatorName
} from '../../pages/Comments/styles'

const StyledSelectFiltersDiv = styled(SelectFiltersDiv)`
  max-height: unset;
`
const StyledFilterBody = styled(FilterBody)`
  max-height: 360px;  
`

const Filters = ({ options=[], setFilters, filterObj, multiSelect=false, showSearch=false }) => {
  const [showFilters, toggleShowFilters] = useState(false)
  const [showsFetched, setShowsFetched] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedShow, setSelectedShow] = useState({})
  const [parentFilter, setParentFilter] = useState('')
  const [childOptions, setChildOptions] = useState([])

  const selectFilter = (value, filterKey) => {
    const currentValues = filterObj[filterKey] || []
    if (currentValues.indexOf(value) > -1) return
    let newValues
    if (multiSelect) {
      newValues = currentValues.concat(value)
    } else {
      newValues = [value]
    }
    setFilters({...filterObj, [filterKey]: newValues})
    toggleShowFilters(false)
    setChildOptions([])
    setParentFilter('')
  }

  const handleParentFilter = (val) => {
    setParentFilter(val)
    let arr = []
    options.map(({ value, name, filterKey, parent}) => parent && arr.push({name:name,value:value,filterKey:filterKey}) )
    setChildOptions(arr)
  }
  
  const removeFilter = (value, filterKey) => {
    const currentValues = filterObj[filterKey] || []
    const newValues = currentValues.filter(val => val !== value)
    setFilters({...filterObj, [filterKey]: newValues})
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const selectShowFilter = (selectedShow) => {
    setSearchQuery('')
    selectFilter(selectedShow.entity_id, 'show_id')
  }

  useEffect(() => {
    let { show_id } = filterObj
    if (!show_id) return
    let value = show_id[0]
    if (!value) return
    get('/content_api/show.get_details', {
      params: {
        show_id: value,
        info_level: 'min',
      },
    }).then((res) => {
      setSelectedShow({ show_id: value, title: (res.result[0] || {}).show_title })
    })
  }, [filterObj.show_id])

  useEffect(() => {
    get('/content_api/book.all_published_shows')
    .then((res) => {
      setShowsFetched(res.result)
    })
  }, [])

  return (
    <>
      <FilterHolder>
        <ApplyFilterDiv onClick={() => toggleShowFilters(true)}>
          <ApplyFilterText>Apply Filter</ApplyFilterText>
          <AddIcon />
        </ApplyFilterDiv>
        {Object.keys(filterObj).map(fKey => filterObj[fKey].map(selectedValue => (
            <SelectedFilterDiv onClick={() => removeFilter(selectedValue, fKey)} key={selectedValue}>
              {selectedShow.show_id === selectedValue ? (
                <SelectedFilterText>
                  {selectedShow.title || 'show selected'}
                </SelectedFilterText>
                ) : (
                <SelectedFilterText>
                  {(options.find(opt => opt.value === selectedValue) || {}).name}
                </SelectedFilterText>)}
              <CrossLightIcon />
            </SelectedFilterDiv>
          )
        ))}
        {showFilters && (
          <StyledSelectFiltersDiv>
            <FilterHeaderDiv>
              <FilterHeaderText>Select a Filter</FilterHeaderText>
              <CrossIcon onClick={() => {
                if(parentFilter){
                    setParentFilter('')
                    setChildOptions([])
                }else{
                  toggleShowFilters(false)
                }
              }} />
            </FilterHeaderDiv>
            <StyledFilterBody>
              {!parentFilter && options.map(({ value, name, filterKey, isFilterCategory =false, parent}) => (
                !parent && <FilterDiv key={value} onClick={() => isFilterCategory  ? handleParentFilter(value) : selectFilter(value, filterKey)}>
                  <FilterText>
                    {name}
                  </FilterText>
                </FilterDiv> 
              ))}
              {childOptions.map(({ value, name, filterKey}) => (
                <FilterDiv key={value} onClick={() => selectFilter(value, filterKey)}>
                  <FilterText>
                    {name}
                  </FilterText>
                </FilterDiv>
              ))}
              {!parentFilter && showSearch && <Search
                placeholder = "Search Audiobook"
                onChange={(e) => handleSearch(e)}
              />}
              {showsFetched &&
                searchQuery &&
                showsFetched
                  .filter((shows) =>
                    shows.title
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((show) => (
                    <FilterDiv onClick={() => selectShowFilter(show)} key={show.entity_id}>
                      <FilterShowImage src={show.image_url} />
                      <div>
                        <FilterText>{show.title}</FilterText>
                        <br />
                        <ShowCreatorName>{show.creator_name}</ShowCreatorName>
                      </div>
                    </FilterDiv>
                  ))}
            </StyledFilterBody>
          </StyledSelectFiltersDiv>
        )}
      </FilterHolder>
    </>
  )
}

export default Filters