import React from 'react'
import Routes from './routes'
import AccountProvider from './providers/accountProvider'
import ControlsProvider from './providers/controlsProvider'
import { ThemeProvider } from 'styled-components'
import { theme } from './designSystem'
import './App.css'
import { v4 }  from 'uuid'

export default class App extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('referrer')
    if(ref){
      localStorage.setItem('referrer',ref)
    }else if(document.referrer.includes('google')){
      localStorage.setItem('referrer','google')
    }
    if(!localStorage.getItem('uuid')){
      const id = v4().split('-').join('')
      localStorage.setItem('uuid', id)
    }
    if (window.fcWidget && process.env.REACT_APP_DEPLOY_ENV === 'production') {
      window.fcWidget.init({
        token: "77c39ffd-cd0c-4e39-82c0-4f9681053b4e",
        host: "https://wchat.in.freshchat.com",
      });
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme.light}>
        <AccountProvider>
          <ControlsProvider>
            <Routes />
          </ControlsProvider>
        </AccountProvider>
      </ThemeProvider>
    )
  }
}
