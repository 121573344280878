import { Box, Text } from '../../designSystem'
import { Container, SerialNumber } from '../../../src/pages/Shows/Audiobooks/Published/ShowDetails/AssetManagement/styled'

function IndividualTaskDetail({
  sequence_number,
  title,
  onIndividualTaskClick,
}) {
  return (
    <Container onClick={onIndividualTaskClick}>
      <SerialNumber>{sequence_number}</SerialNumber>
      <Box
        variant="flex"
        flexDirection="column"
        justifyContent="flex-start"
        ml={[7, 0]}
      >
        <Box variant="flex" justifyContent="flex-start">
          <Text variant="caption" color="var(--Text900)">
            {title}
          </Text>
        </Box>
      </Box>
    </Container>
  )
}

export default IndividualTaskDetail
