import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import InfoIcon from '../InfoIcon'
import DownArrow from '../Images/DownArrow'
import CrossIcon from '../Images/CrossIcon'
import Tooltip from '../Tooltip'
import { useDidUpdate } from '../../lib/customHooks'

const Container = styled.div`
  position: relative;
`
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const SelectionField = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  padding: 12px 16px;

`
const SelectedSpan = styled.span`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
`
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 78px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`
const Option = styled.div`
  text-transform: capitalize;
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);

  &:hover {
    background-color: var(--Grey500);
  }
`
const StyledDownArrow = styled(DownArrow)`
  align-self: center;
`
const StyledCrossIcon = styled(CrossIcon)`
  align-self: center;
  fill: #0D1536;
  fill-opacity: 0.65;
`

// TODO: modify this component to take options object as well as options array

const DropDownSelect = ({
  value,
  onChange,
  label,
  infoTitle,
  placeholder,
  selectOptions,
  tooltip,
  dependentOn,
  idKey,
  nameKey,
  selectByName,
  ...props
}) => {
  // const { values: {book_type: type} } = useFormikContext()
  // const [type, changeType] = useState(type)
  const [isOpen, toggleDropDownOpen] = useState(false)
  const dropDownRef = useRef()
  const selectionFieldRef = useRef()

  useDidUpdate(() => {
    newHandleChange('')
  }, [dependentOn])

  const newHandleChange = (idOrName) => {
    // do something with this val
    toggleDropDownOpen(false)
    onChange(idOrName)
  }

  const handleDropDownClick = (value) => {
    if (value) toggleDropDownOpen(false)
    else toggleDropDownOpen(true)
  }

  const handleClick = (e) => {
    if (!dropDownRef.current.contains(e.target) && !selectionFieldRef.current.contains(e.target)) {
      toggleDropDownOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  // const options = optionsObj[type] || []
  const options = Array.isArray(selectOptions) ? selectOptions : (selectOptions[dependentOn] || [])
  const Field = (
    <SelectionField ref={selectionFieldRef}>
        <SelectedSpan onClick={() => handleDropDownClick(value)}>
          {value
            ? selectByName ? value : (options.find((option) => option[idKey] === value) || {})[nameKey] || ''
            : placeholder || 'Choose a Category'}
        </SelectedSpan>
        {value ? (
            <StyledCrossIcon onClick={() => newHandleChange('')} />
          ) : (
            <StyledDownArrow  onClick={() => handleDropDownClick(value)}/>
        )}
      </SelectionField>
  )
  return (
    <Container>
      {label && <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan>
        {infoTitle && <InfoIcon title={infoTitle} />}
        {/* {meta.touched && meta.error && <ErrorSpan>{meta.error}</ErrorSpan>} */}
      </LabelContainer>}
      {
        tooltip ? (
          <Tooltip title={tooltip.title}  placement={tooltip.placement} arrow>
            {Field}
          </Tooltip>
        ) : Field
      }
      <OptionsDropDown isOpen={isOpen} ref={dropDownRef}>
        {options.map(option => (
          <Option key={option[idKey]} onClick={() => newHandleChange(selectByName ? option[nameKey] : option[idKey])}>
            {option[nameKey]}
          </Option>
        ))}
      </OptionsDropDown>
    </Container>
  )
}


export default DropDownSelect
