import axios from 'axios'
import { getTokenAndUid } from '../utils'
// const HOST = 'https://internalapi.pocketfm.in'
// const HOST = 'http://54.151.227.95'
const HOST = process.env.REACT_APP_API_HOST || 'https://api-qa-cms2.pocketfm.com/tejpratap_singh'
const apiVersion = 'v2'

const fetcher = (init, signal) => (urlStr, paramObj = {}) => {
  let url = urlStr
  if (urlStr && urlStr[0] === '/') {
    url = HOST + '/' + apiVersion + urlStr
  }
  if (init.method !== 'get' && paramObj.params) {
    let querystring = ''
    for (const key in paramObj.params) {
      if (paramObj.params[key]) {
        querystring +=
          (querystring.length ? '&' : '') +
          key +
          '=' +
          encodeURIComponent(paramObj.params[key])
      }
    }
    url = url + (querystring ? '?' + querystring : '')
    delete paramObj.params
  }
  
  url = url.indexOf('pocketfm') === -1 ? url : url.indexOf('is_novel') !== -1 ? url : url.indexOf('?') !== -1 ? `${url}&is_novel=0` : `${url}?is_novel=0`

  if (paramObj.params?.hasOwnProperty('is_novel')) {
    delete paramObj.params.is_novel
    // to avoid duplicate keys
  }

  const headersOverrides = paramObj.headers || {}
  delete paramObj.headers
  const fixedHeaders = {
    // 'Content-Type': "application/x-www-form-urlencoded",
    'Content-Type': "application/json",
    'app-version': 180,
    'auth-token': 'web-auth',
    'app-client': "consumer-web",
    'Web-Platform' : "studio"
  }
  const headers = {...fixedHeaders, ...headersOverrides}
  const {token, uid} = getTokenAndUid()
  if (token) {
    headers['access-token'] = token
    headers['uid'] = uid
  }
  const finalAxiosObj = {
    url,
    headers,
    ...init,
    ...paramObj,
  }
  if (signal) {
    finalAxiosObj.cancelToken = signal.token
  }
  return axios(finalAxiosObj)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        // return new Promise(() => {})
        return Promise.reject({message: 'cancelled'})
      }
      return Promise.reject({
        code: error && error.response && error.response.status,
        message: error && error.response && error.response.data && error.response.data.message,
        url: error && error.response && error.response.request && error.response.request.responseURL || url,
        method: init.method
      })
    })
}

// use the following methods by passing relative url like '/user_api' or a complete url as the
// first param and param object as the second.
// the param object can contain params, data, headers objects

const get = fetcher({ method: 'get' })

const post = fetcher({ method: 'post' })

const put = fetcher({ method: 'put' })

const del = fetcher({ method: 'delete' })

const cancellableAPI = () => {
  const signal = axios.CancelToken.source()
  return {
    get: fetcher({ method: 'get' }, signal),
    put: fetcher({ method: 'put' }, signal),
    post: fetcher({ method: 'post' }, signal),
    del: fetcher({ method: 'delete' }, signal),
    cancelRequest: () => signal.cancel()
  }
}

export { get, put, post, del, cancellableAPI }
