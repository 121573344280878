import React, {useState, useContext, Fragment} from 'react'
import * as Yup from 'yup'
import {Formik, Form} from 'formik'
import {
  Input,
  FloatingInput,
  InlineRadioToggle,
  Upload,
  DropDownSelect,
} from '../FormikInputs'
import {FileUploadComponent} from '../../pages/Home/WBPSteps/step1'
import {getDocObj, getTokenAndUid, removeAllSpaces, removeExtraSpaces} from '../../lib/utils'
import {get, post} from "../../lib/api";
import Loader from '../Loader'
import PencilIcon from '../Images/PencilIcon'
import GenericError from '../GenericError'
import { AccountContext } from '../../providers/accountProvider'
import { Box, Text } from '../../designSystem'
import SubHeader from '../WithSubHeaderBG'
import {
  InvalidDetailsDiv,
  BasicDetailsDiv,
  ProfileImageDiv,
  EditIconDiv,
  SectionHeading,
  BankDetailsDiv,
  DocumentsDiv,
  AddressDiv,
  ActionButtonBox,
  SaveButton,
  LabelDiv,
  AdditionalVOADetailsDiv,
} from './styles'
import VoaDetails from './VoaDetails'
import {
  addValues,
  getExperienceObject,
  getVoiceObject,
  getVTextureObject,
} from './helper'
import { ImageUploadComponent } from '../../pages/Shows/Audiobooks/Unpublished/New'
import ToggleSwitch from '../FormikInputs/ToggleSwitch/ToggleSwitch'
import { alpnumericvalidatePattern } from '../../constants'

const ImgUploadCTA = ({handleReUpload}) => (
  <EditIconDiv onClick={handleReUpload}>
    <PencilIcon/>
  </EditIconDiv>
)

const calDifferenceTypeArr = (arr1, arr2, type) => {
  let differenceArr = [];
  if (type === "experience_type") {
    differenceArr = arr1.filter(t => !arr2.find(_type => _type.experience_type === t.experience_type));
    differenceArr = [...differenceArr, ...arr2.filter(t => !arr1.find(_type => _type.experience_type === t.experience_type))];
  } else if (type === "voice_range_type") {
    differenceArr = arr1.filter(t => !arr2.find(_type => _type.voice_range_type === t.voice_range_type));
    differenceArr = [...differenceArr, ...arr2.filter(t => !arr1.find(_type => _type.voice_range_type === t.voice_range_type))];
  } else if (type === "voice_texture_type") {
    differenceArr = arr1.filter(t => !arr2.find(_type => _type.voice_texture_type === t.voice_texture_type));
    differenceArr = [...differenceArr, ...arr2.filter(t => !arr1.find(_type => _type.voice_texture_type === t.voice_texture_type))];
  } else if (type === "voice_sample_type") {
    differenceArr = arr1.filter(voice => !arr2.find(v => v.genre === voice.genre && v.voice_range === voice.voice_range));
    differenceArr = [...differenceArr, ...arr2.filter(voice => !arr1.find(v => v.genre === voice.genre && v.voice_range === voice.voice_range))];
  }
  return differenceArr
}

const ProfileUI = ({userData, isOverseasUser}) => {
  const [apiError, toggleApiError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const {user, onUpdateUser} = useContext(AccountContext)
  const isMobile = window.screen.width < 600
  const overSeaUserAbout = "Welcome to my profile! Subscribe to my page for updates!"
  const notOverSeaUserAbout = userData['languages'] === "hindi" ? "मेरे रेडियो चैनल पे आपका स्वागत है! कृपया मेरा चैनल सब्सक्राइब कीजिये!" : ""
  const panNumberValidation = (panCardNum) => {
    if (panCardNum === '') return true
    const re = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
    return re.test(panCardNum)
  }
  function validateGST(gstNumber, is_gst_no) {
    if (is_gst_no && gstNumber.trim() === '') return false
    if (gstNumber === '') return true

    // Regular expression for GST number validation
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/

    // Remove spaces and convert to uppercase for uniformity
    const formattedGST = gstNumber.replace(/\s/g, '').toUpperCase()
    return gstRegex.test(formattedGST)
  }

  const { uid } = getTokenAndUid()
  return (
    <SubHeader title="Edit Profile">
      <Formik
        initialValues={{
          fullname: userData.fullname || '',
          phone_number: userData.phone_number || '',
          gender: userData.gender || '',
          image_url: userData.image_url,
          email: userData.email || '',
          alias: userData?.alias,
          bio:
            (userData.languages === 'hindi' && userData.bio) ||
            (isOverseasUser ? overSeaUserAbout : notOverSeaUserAbout),
          languages: userData.languages || '',
          account_type: userData.account_type || '',
          account_name: userData.account_name || '',
          bank_name: userData.bank_name || '',
          account_no: userData.account_no || '',
          is_gst_no: !!userData.gst_number,
          gst_number: userData.gst_number || '',
          ifsc_code: userData.ifsc_code || '',
          pan_card_no: userData.pan_card_no || '',
          routing_number: userData.routing_number || '',
          bank_address: userData.bank_address || '',
          cancelled_cheque_url: userData.cancelled_cheque_url || '',
          pan_card_url: userData.pan_card_url || '',
          address_1: userData.address_1 || '',
          address_2: userData.address_2 || '',
          pin_code: userData.pin_code || '',
          city: userData.city || '',
          state: userData.state || '',
          swift_code: userData.swift_code || '',
          trc: userData.trc || '',
          workExperience:
            userData.experience?.length > 0
              ? getExperienceObject('workExperience', userData.experience, {
                  movie: '',
                  animations: '',
                  adCommercials: '',
                  audioBook: '',
                  podcast: '',
                  radio: '',
                  other: '',
                })
              : {
                  movie: '',
                  animations: '',
                  adCommercials: '',
                  audioBook: '',
                  podcast: '',
                  radio: '',
                  other: '',
                },
          otherExperience:
            userData.experience?.length > 0
              ? userData.experience.filter(
                  (exp) =>
                    [
                      'movie',
                      'animations',
                      'adCommercials',
                      'audioBook',
                      'podcast',
                      'radio',
                    ].findIndex((v) => v === exp.experience_type) === -1
                )[0]?.experience_type
              : '',
          voiceRange:
            userData.voice_range?.length > 0
              ? getVoiceObject('voiceRange', userData.voice_range, {
                  'child(5-12)': '',
                  'teen(13-17)': '',
                  'young_adult(18-25)': '',
                  'adult(26-35)': '',
                  'middle_aged(36-54)': '',
                  'senior(55+)': '',
                })
              : {
                  'child(5-12)': '',
                  'teen(13-17)': '',
                  'young_adult(18-25)': '',
                  'adult(26-35)': '',
                  'middle_aged(36-54)': '',
                  'senior(55+)': '',
                },
          voiceTexture:
            userData.voice_texture?.length > 0
              ? getVTextureObject('voiceTexture', userData.voice_texture, {
                  'sooth/soothing': '',
                  'sharp/seductive': '',
                  'husky/grainy': '',
                  'heavy/deep': '',
                  dry: '',
                })
              : {
                  'sooth/soothing': '',
                  'sharp/seductive': '',
                  'husky/grainy': '',
                  'heavy/deep': '',
                  dry: '',
                },
          other_accents: userData.other_accents || '',
          major_professional_details: userData.major_professional_details || '',
          voice_samples:
            userData.voice_samples?.length > 0
              ? userData.voice_samples
              : [
                  {
                    genre: '',
                    desc: '',
                    voice_range: '',
                    audio_url: '',
                  },
                ],
          age: userData.voice_artist_creator_details?.age || '',
          working_as: userData.voice_artist_creator_details?.working_as || '',
          refrences_work_link: userData.voice_artist_creator_details?.refrences_work_link || '',
          availability_per_day: userData.voice_artist_creator_details?.availability_per_day || '',
          access_to_production_facilities: userData.voice_artist_creator_details?.access_to_production_facilities || '',
          recording_microphone_brand: userData.voice_artist_creator_details?.recording_microphone_brand || '',
          recording_microphone_model_no: userData.voice_artist_creator_details?.recording_microphone_model_no || '',
          pop_filter: userData.voice_artist_creator_details?.pop_filter || '',
          sound_card_brand: userData.voice_artist_creator_details?.sound_card_brand || '',
          sound_card_model_no: userData.voice_artist_creator_details?.sound_card_model_no || '',
          headphones_brand: userData.voice_artist_creator_details?.headphones_brand || '',
          headphones_model_no: userData.voice_artist_creator_details?.headphones_model_no || '',
          micro_phone_stand: userData.voice_artist_creator_details?.micro_phone_stand || '',
          software: userData.voice_artist_creator_details?.software || '',
          computer_brand: userData.voice_artist_creator_details?.computer_brand || '',
          computer_model_no: userData.voice_artist_creator_details?.computer_model_no || '',
          any_other_equipment: userData.voice_artist_creator_details?.any_other_equipment || '',
          social_network_followers: userData.voice_artist_creator_details?.social_network_followers || '',
          social_profile_link: userData.voice_artist_creator_details?.social_profile_link || '',
          years_of_exp_voa: userData.voice_artist_creator_details?.years_of_exp_voa || '',
          basic_sound_proofing: userData.voice_artist_creator_details?.basic_sound_proofing || '',
          image_url1: userData.voice_artist_creator_details?.image_url1 || '',
          image_url2: userData.voice_artist_creator_details?.image_url2 || '',
          image_url3: userData.voice_artist_creator_details?.image_url3 || '',
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          fullname: Yup.string().required('Name is mandatory'),
          email: Yup.string().transform((value) => removeAllSpaces(value)).email('Invalid email address'),
          phone_number: !isOverseasUser && Yup.string().required('Required'),
          account_name: Yup.string().when(
            [
              'cancelled_cheque_url',
              'bank_name',
              'account_no',
              'swift_code',
              'trc',
            ],
            (
              cancelled_cheque_url,
              account_no,
              bank_name,
              swift_code,
              trc,
              schema
            ) =>
              // isOverseasUser &&
              cancelled_cheque_url ||
              bank_name ||
              account_no ||
              swift_code ||
              trc
                ? schema.required(['account_name'])
                : schema
          ),
          account_no: Yup.string().when(
            ['cancelled_cheque_url', 'bank_name', 'swift_code', 'trc'],
            (cancelled_cheque_url, bank_name, swift_code, trc, schema) =>
              // isOverseasUser &&
              cancelled_cheque_url || bank_name || swift_code || trc
                ? schema.required(['account_no'])
                : schema
          ),
          bank_name: Yup.string().when(
            ['cancelled_cheque_url', 'swift_code', 'trc'],
            (cancelled_cheque_url, swift_code, trc, schema) =>
              // isOverseasUser &&
              cancelled_cheque_url || swift_code || trc
                ? schema.required(['bank_name'])
                : schema
          ),
          // trc: isOverseasUser && Yup.string().required('Required'),
          voice_samples: Yup.string().when(
            ['account_type'],
            (account_type, schema) =>
              account_type === 'VO Artist'
                ? Yup.array().of(
                    Yup.object({
                      genre: Yup.string().required('Please select a Genre'),
                      desc: Yup.string().max(
                        150,
                        'Please enter description within 150 characters '
                      ),
                      voice_range: Yup.string().required(
                        'Please select a Voice Range'
                      ),
                      audio_url: Yup.string().required(
                        'Please upload an Audio File'
                      ),
                    })
                  )
                : schema
          ),
          image_url1: Yup.string().when(
            ['account_type', 'basic_sound_proofing'],
            (account_type, basic_sound_proofing, schema) =>
              (account_type === 'VO Artist' ||
                account_type === 'Production House') &&
              basic_sound_proofing === 'yes'
                ? schema.required()
                : schema
          ),
          image_url2: Yup.string().when(
            ['account_type', 'basic_sound_proofing'],
            (account_type, basic_sound_proofing, schema) =>
              (account_type === 'VO Artist' ||
                account_type === 'Production House') &&
              basic_sound_proofing === 'yes'
                ? schema.required()
                : schema
          ),
          image_url3: Yup.string().when(
            ['account_type', 'basic_sound_proofing'],
            (account_type, basic_sound_proofing, schema) =>
              (account_type === 'VO Artist' ||
                account_type === 'Production House') &&
              basic_sound_proofing === 'yes'
                ? schema.required()
                : schema
          ),
        })}
        onSubmit={(values, {setSubmitting}) => {
          const {uid} = getTokenAndUid()
          const {account_id, address_id} = userData
          const {
            fullname,
            gender,
            email,
            alias,
            phone_number,
            bio,
            image_url,
            languages,
            address_1,
            address_2,
            pin_code,
            city,
            state,
            account_name,
            account_type,
            bank_name,
            account_no,
            ifsc_code,
            pan_card_no,
            gst_number,
            cancelled_cheque_url,
            pan_card_url,
            swift_code,
            routing_number,
            bank_address,
            trc,
            workExperience,
            otherExperience,
            voiceRange,
            voice_samples,
            voiceTexture,
            other_accents,
            major_professional_details,
            age,
            working_as,
            refrences_work_link,
            availability_per_day,
            access_to_production_facilities,
            recording_microphone_brand,
            recording_microphone_model_no,
            pop_filter,
            sound_card_brand,
            sound_card_model_no,
            headphones_brand,
            headphones_model_no,
            micro_phone_stand,
            software,
            computer_brand,
            computer_model_no,
            any_other_equipment,
            social_network_followers,
            social_profile_link,
            years_of_exp_voa,
            basic_sound_proofing,
            image_url1,
            image_url2,
            image_url3,
            is_gst_no,
          } = values
          const userPostData = {
            fullname: removeExtraSpaces(fullname),
            email: removeAllSpaces(email),
            phone_number,
            bio,
            gender,
            image_url,
          }
          const currentVoiceRangeArr = addValues(voiceRange).map(voiceRange => ({
            "voice_range_type": voiceRange,
            "creator_id": uid
          }))
          const previousVoiceRangeArr = userData['voice_range']
          let finalVoiceRangeArr = []
          if (previousVoiceRangeArr?.length) {
            const intersectionVoiceRangeArr = previousVoiceRangeArr.filter(voice => currentVoiceRangeArr.findIndex(v => v.voice_range_type === voice.voice_range_type) !== -1)
            let differenceVoiceRangeArr = calDifferenceTypeArr(currentVoiceRangeArr, previousVoiceRangeArr, "voice_range_type");
            finalVoiceRangeArr = [...intersectionVoiceRangeArr, ...differenceVoiceRangeArr.map(voice => voice?.id ? {
              ...voice,
              delete: true
            } : voice)]
          }
          const currentWorkExperienceArr = [...addValues(workExperience).map(experience => ({
            "experience_type": experience,
            "creator_id": uid
          }))]
          if (otherExperience) currentWorkExperienceArr.push({"experience_type": otherExperience, "creator_id": uid})
          const previousWorkExperienceArr = userData['experience']
          let finalWorkExperienceArr = []
          if (previousWorkExperienceArr?.length) {
            const intersectionWorkExperienceArr = previousWorkExperienceArr.filter(e => currentWorkExperienceArr.findIndex(exp => exp.experience_type === e.experience_type) !== -1)
            let differenceWorkExperienceArr = calDifferenceTypeArr(currentWorkExperienceArr, previousWorkExperienceArr, "experience_type")
            finalWorkExperienceArr = [...intersectionWorkExperienceArr, ...differenceWorkExperienceArr.map(exp => exp?.id ? {
              ...exp,
              delete: true
            } : exp)]
          }
          const currentVoiceTextureArr = addValues(voiceTexture).map(voiceTexture => ({
            "voice_texture_type": voiceTexture,
            "creator_id": uid
          }))
          const previousVoiceTextureArr = userData['voice_texture']
          let finalVoiceTextureArr = []
          if (previousVoiceTextureArr?.length) {
            const intersectionVoiceTextureArr = previousVoiceTextureArr.filter(voice => currentVoiceTextureArr.findIndex(v => v.voice_texture_type === voice.voice_texture_type) !== -1)
            let differenceVoiceTextureArr = calDifferenceTypeArr(currentVoiceTextureArr, previousVoiceTextureArr, "voice_texture_type");
            finalVoiceTextureArr = [...intersectionVoiceTextureArr, ...differenceVoiceTextureArr.map(voice => voice?.id ? {
              ...voice,
              delete: true
            } : voice)]
          }

          const voice_artist_creator_details = {
            age: age ? Number(age) : '',
            working_as,
            refrences_work_link,
            availability_per_day,
            access_to_production_facilities,
            recording_microphone_brand,
            recording_microphone_model_no,
            pop_filter: pop_filter === 'yes' ? 1 : 0,
            sound_card_brand,
            sound_card_model_no,
            headphones_brand,
            headphones_model_no,
            micro_phone_stand: micro_phone_stand === 'yes' ? 1 : 0,
            software,
            computer_brand,
            computer_model_no,
            any_other_equipment,
            social_network_followers,
            social_profile_link,
            years_of_exp_voa,
            basic_sound_proofing: basic_sound_proofing === 'yes' ? 1 : 0,
            ...((image_url1 || image_url2 || image_url3) && {basic_sound_proofing_img: JSON.stringify({urls: [image_url1, image_url2, image_url3]})})
          }

          if (!pop_filter) {
            delete voice_artist_creator_details.pop_filter
          }
          if (!micro_phone_stand) {
            delete voice_artist_creator_details.micro_phone_stand
          }
          if (!basic_sound_proofing) {
            delete voice_artist_creator_details.basic_sound_proofing
          }
          if (!age) {
            delete voice_artist_creator_details.age
          }

          const creatorPostData = {
            languages: languages || userData['languages'] || '',
            account_type: account_type || userData['account_type'],
            user_id: uid,
            alias: alias?.trim(),
            experience:
              finalWorkExperienceArr.length > 0
                ? finalWorkExperienceArr
                : currentWorkExperienceArr,
            voice_range:
              finalVoiceRangeArr.length > 0
                ? finalVoiceRangeArr
                : currentVoiceRangeArr,
            voice_texture:
              finalVoiceTextureArr.length > 0
                ? finalVoiceTextureArr
                : currentVoiceTextureArr,
            other_accents,
            major_professional_details,
            device_id: localStorage.getItem('uuid'),
            voice_artist_creator_details,
          }

          const currentVoiceSampleData = voice_samples
          const previousVoiceSampleData = userData['voice_samples']
          let finalVoiceSampleData = []
          if (previousVoiceSampleData?.length) {
            const intersectionVoiceSampleData = currentVoiceSampleData.filter(voice => previousVoiceSampleData.findIndex(v => v.genre === voice.genre && v.voice_range === voice.voice_range) !== -1)
            let differenceVoiceSampleData = calDifferenceTypeArr(currentVoiceSampleData, previousVoiceSampleData, "voice_sample_type")
            finalVoiceSampleData = [...intersectionVoiceSampleData.map(voice => voice?.id ? {
              ...voice,
              update: true
            } : voice), ...differenceVoiceSampleData.map(voice => voice?.id ? {...voice, delete: true} : voice)]
          }
          const addressPostData = {
            address_1,
            address_2,
            pin_code,
            city,
            state,
            address_id,
            uid,
          }

          let bankPostData = {
            account_name,
            bank_name,
            account_no,
            gst_number,
          }
          let documents = []
          if (!isOverseasUser) {
            bankPostData = {
              ...bankPostData,
              account_id,
              ifsc_code,
              pan_card_no,
              pan_card_url,
              cancelled_cheque_url,
              uid
            }
            if (!cancelled_cheque_url) {
              delete bankPostData.cancelled_cheque_url
            }

            if (!pan_card_url) {
              delete bankPostData.pan_card_url
            }
            if (is_gst_no) {
              bankPostData = { ...bankPostData, gst_number }
            } else {
              bankPostData.gst_number = ''
            }
          } else {
            if (cancelled_cheque_url) {
              documents = [...documents, {
                'cancelled_cheque_url': getDocObj('cancelled_cheque_url', cancelled_cheque_url, userData.bankDetailDocuments)
              }]
            }
            if (trc) {
              documents = [...documents, {
                'trc': getDocObj('trc', trc, userData.bankDetailDocuments)
              }]
            }
            if (documents.length > 0) {
              bankPostData['documents'] = documents
            }
            bankPostData['swift_code'] = swift_code
            bankPostData['routing_number'] = routing_number
            bankPostData['bank_address'] = bank_address
          }
          for (const variable in bankPostData) {
            if (
              !bankPostData[variable] &&
              variable !== 'documents' &&
              variable !== 'gst_number'
            )
              delete bankPostData[variable]
          }
          if (Object.values(bankPostData).length > 0) {
            if (userData?.id) {
              bankPostData['id'] = userData?.id;
            }
            if (isOverseasUser) {
              bankPostData['creator_id'] = uid
            }
          }
          if (!panNumberValidation(pan_card_no)) {
            setErrorMessage("Invalid Pan Card Number");
            setSubmitting(false);
            return
          }
          if (!validateGST(gst_number, is_gst_no)) {
            setErrorMessage('Invalid GST Number')
            setSubmitting(false)
            return
          }

          setErrorMessage("");
          const allPromises = [
            post('/user_api/user.update?is_novel=0', {data: userPostData}),
            post('/user_api/user.create_creator_account?is_novel=0', {
              data: creatorPostData,
            }),
            !isOverseasUser &&
              post('/user_api/user_addresses/?is_novel=0', {
                data: addressPostData,
              }),
            // isOverseasUser
            //   ? Object.values(bankPostData).length > 0 &&
            //     post('/user_api/row_creator/?is_novel=0', {
            //       data: bankPostData,
            //     })
            //   : post('/user_api/user_bank_accounts/?is_novel=0', {
            //       data: bankPostData,
            //     }),
            !isOverseasUser &&
              post('/user_api/user_bank_accounts/?is_novel=0', {
                data: bankPostData,
              }),
          ]
          if (userData.account_type === 'VO Artist') allPromises.push(post('/user_api/voice_sample/?is_novel=0', {data: finalVoiceSampleData.length > 0 ? finalVoiceSampleData : currentVoiceSampleData}))

          Promise.all(allPromises)
            .then(() => {
              setSubmitting(false)
              // get('/user_api/user.get_creator_details?is_novel=0').then(() => {
              //   onUpdateUser({ ...user, account_type })
              // })
              setTimeout(() => {
                window.location.reload()
              }, 400)
            })
            .catch((error) => {
              setSubmitting(false)
              throw error
            })
        }}
      >
        {({values = {}, isSubmitting, dirty, errors, submitCount}) =>
          (<Form>
            <GenericError
              show={apiError}
              onClose={() => {
                toggleApiError(false)
              }}
            />
            <BasicDetailsDiv>
              <Box mb={[0, 80]} variant="flex" flexDirection={["column", "row"]}>
                <ProfileImageDiv>
                  <img src={values['image_url']} alt="avatar"/>
                  <Upload
                    name="image_url"
                    // renderComp={ImageUploadComponent}
                    renderComp={ImgUploadCTA}
                    imageValidator={(width, height) => width === height}
                    allowImageCrop
                    aspectRatio={1}
                    dimensionErrorMsg="The image does not follow the dimensions of the thumbnail. Please try with a different image."
                    fileTypeTags="image"
                    extraParams={{title: 'thumbnail'}}
                  />
                  {/* <EditIconDiv><PencilIcon /></EditIconDiv> */}
                </ProfileImageDiv>
                <Box variant="flex" mt={[104, 0]}>
                  <SectionHeading>Basic Details</SectionHeading>
                </Box>
              </Box>
              <Box variant="flex" flexDirection={['column', 'row']}>
                <Box width={['100%', '32%']} mb={16}>
                  <FloatingInput
                    type="text"
                    name="fullname"
                    label="Your Name"
                    size="lg"
                  />
                </Box>
                <Box width={['100%', '32%']} mt={[16, 0]} mb={16}>
                  <FloatingInput
                    type="text"
                    name="alias"
                    label="Alias"
                    validateFunc={(value) =>
                      alpnumericvalidatePattern.test(value)
                    }
                    size="lg"
                  />
                </Box>
                <Box width={['100%', '32%']} mb={16}>
                  <FloatingInput
                    type="text"
                    name="phone_number"
                    label={isOverseasUser ? 'Mobile Number (optional)' : 'Mobile Number'}
                    size="lg"
                    maxLength="15"
                    disabled={!isOverseasUser}
                  />
                </Box>
              </Box>
              <Box variant="flex" flexDirection={['column', 'row']}>
                <Box width={['100%', '66%']} mb={16}>
                  <InlineRadioToggle
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: 'Male',
                        name: 'male',
                      },
                      {
                        label: 'Female',
                        name: 'female',
                      },
                      {
                        label: 'Others',
                        name: 'other',
                      },
                      {
                        label: 'Do not wish to answer',
                        name: 'unrevealed',
                      },
                    ]}
                  />
                </Box>
                <Box width={['100%', '32%']} mt={[16, 0]} mb={16}>
                  <FloatingInput
                    type="text"
                    name="email"
                    label="Email ID"
                    size="lg"
                  />
                </Box>
              </Box>

              {(values.account_type === 'VO Artist' ||
                values.account_type === 'Production House') && (
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="age"
                      label="Age"
                      size="lg"
                    />
                  </Box>
                </Box>
              )}
              
              <Box variant="flex" flexDirection={['column', 'row']}>
                <Input type="text" asTextArea name="bio" placeholder="About"/>
              </Box>
              {!isMobile && <Box variant="flex" flexDirection={['column', 'row']}>
                {
                  !isOverseasUser && <Box width={["100%", "49%"]}>
                    <LabelDiv>Language Proficiency</LabelDiv>
                  </Box>
                }
                <Box width={["100%", "49%"]}>
                  <LabelDiv>Account Type</LabelDiv>
                </Box>
              </Box>}
              <Box variant="flex" flexDirection={['column', 'row']}>
                {
                  !isOverseasUser && <>
                    {isMobile && <LabelDiv>Language Proficiency</LabelDiv>}
                    <Box width={["100%", "49%"]}>
                      <DropDownSelect
                        name="languages"
                        selectByName
                        placeholder="Select a Language"
                        selectOptions={[
                          {id: 1, name: 'hindi'},
                          {id: 2, name: 'tamil'},
                          {id: 3, name: 'bengali'},
                          {id: 4, name: 'malayalam'},
                          {id: 5, name: 'telugu'},
                          {id: 6, name: 'marathi'},
                          {id: 7, name: 'kannada'},
                          {id: 8, name: 'english'},
                          {id: 9, name: 'spanish'},
                        ]}
                        idKey="id"
                        nameKey="name"
                      />
                    </Box>
                  </>
                }
                {isMobile && <LabelDiv>Account Type</LabelDiv>}
                <Box width={["100%", "49%"]}>
                  <DropDownSelect
                    name="account_type"
                    // selectByName
                    placeholder="Select Account Type"
                    selectOptions={[
                      { id: 'Author', name: 'Author/Writer' },
                      { id: 'VO Artist', name: 'VO Artist' },
                      { id: 'Publisher', name: 'Publisher' },
                      { id: 'Writing Agency', name: 'Writing Agency' },
                      { id: 'Production House', name: 'Production House' },
                      { id: 'localised_agency', name: 'Localisation Agency' },
                      { id: 'recording_studio', name: 'Recording Studio' },
                    ]}
                    idKey="id"
                    nameKey="name"
                  />
                </Box>
              </Box>
            </BasicDetailsDiv>
            {values.account_type === 'VO Artist' && (
              <VoaDetails
                errors={errors}
                submitCount={submitCount}
                values={values}
                isOverseasUser={isOverseasUser}
                uid={uid}
                languages={values.languages}
              />
            )}
            {(values.account_type === 'VO Artist' ||
              values.account_type === 'Production House') && (
              <AdditionalVOADetailsDiv>
                <SectionHeading>Additional VOA Details</SectionHeading>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="refrences_work_link"
                      label="Reference work link (any other audio OTT platform)"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16} mt={[16, 0]}>
                    <InlineRadioToggle
                      name="working_as"
                      label="Currently working as a"
                      options={[
                        {
                          label: 'Full-time artist',
                          name: 'full-time',
                        },
                        {
                          label: 'Freelancer',
                          name: 'freelancer',
                        },
                      ]}
                    />
                  </Box>
                </Box>

                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <DropDownSelect
                      name="availability_per_day"
                      placeholder="Availability per day (In hours)"
                      selectOptions={[
                        { id: "0-2", name: '0-2' },
                        { id: "2-4", name: '2-4' },
                        { id: "4-6", name: '4-6' },
                        { id: "6-8", name: '6-8' },
                      ]}
                      idKey="id"
                      nameKey="name"
                    />
                  </Box>
                  <Box width={['100%', '49%']}>
                    <DropDownSelect
                      name="access_to_production_facilities"
                      placeholder="Access to any of the Production Facilities"
                      selectOptions={[
                        { id: "home-set-up", name: 'Home Set Up' },
                        { id: "studio", name: 'Studio' },
                        { id: "institute-provided", name: 'Institute Provided' },
                        { id: "no-access-to-production-facilities", name: 'No access to production Facilities' },
                        { id: "any-other", name: 'Any other' },
                      ]}
                      idKey="id"
                      nameKey="name"
                    />
                  </Box>
                </Box>

                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="social_network_followers"
                      label="Social Network followers"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']}>
                    <FloatingInput
                      type="text"
                      name="social_profile_link"
                      label="Social profile link"
                      size="lg"
                    />
                  </Box>
                </Box>

                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="years_of_exp_voa"
                      label="Years of Relevant Experience"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <InlineRadioToggle
                      name="basic_sound_proofing"
                      label="Basic soundproofing"
                      options={[
                        {
                          label: 'Yes',
                          name: 'yes',
                        },
                        {
                          label: 'No',
                          name: 'no',
                        },
                      ]}
                    />
                  </Box>
                </Box>

                {values.basic_sound_proofing === 'yes' && (
                  <>
                  <Box>
                    <Text variant="titleSecondary">Please upload soundproofing images - 3 mandatory</Text>
                  </Box>
                  <Box variant="flex" flexDirection={['column', 'row']}>
                    <Upload
                      name="image_url1"
                      placeholder="Soundproofing image 1"
                      renderComp={ImageUploadComponent}
                      hasError={submitCount > 0 && errors.image_url1}
                      fileTypeTags="image"
                      extraParams={{ title: 'voa-images' }}
                    />
                    <Upload
                      name="image_url2"
                      placeholder="Soundproofing image 2"
                      renderComp={ImageUploadComponent}
                      hasError={submitCount > 0 && errors.image_url2}
                      fileTypeTags="image"
                      extraParams={{ title: 'voa-images' }}
                    />
                    <Upload
                      name="image_url3"
                      placeholder="Soundproofing image 3"
                      renderComp={ImageUploadComponent}
                      hasError={submitCount > 0 && errors.image_url3}
                      fileTypeTags="image"
                      extraParams={{ title: 'voa-images' }}
                    />
                  </Box>
                  {submitCount > 0 && (errors.image_url1 || errors.image_url2 || errors.image_url3) && <Box>
                    <Text variant="caption" color="red">Please upload soundproofing images - 3 mandatory</Text>
                  </Box>}
                  </>
                )}
              </AdditionalVOADetailsDiv>
            )}
            {(values.account_type === 'VO Artist' ||
              values.account_type === 'Production House') && (
              <AdditionalVOADetailsDiv>
                <SectionHeading>Recording Equipment information</SectionHeading>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="recording_microphone_brand"
                      label="Microphone Brand Name"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="recording_microphone_model_no"
                      label="Microphone Model no"
                      size="lg"
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="sound_card_brand"
                      label="Sound card Brand Name"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="sound_card_model_no"
                      label="Sound card Model no"
                      size="lg"
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="headphones_brand"
                      label="Headphones Brand Name"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="headphones_model_no"
                      label="Headphones Model no"
                      size="lg"
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="computer_brand"
                      label="Computer Brand Name"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="computer_model_no"
                      label="Computer Model no"
                      size="lg"
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <InlineRadioToggle
                      name="pop_filter"
                      label="Pop filter"
                      options={[
                        {
                          label: 'Yes',
                          name: 'yes',
                        },
                        {
                          label: 'No',
                          name: 'no',
                        },
                      ]}
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <InlineRadioToggle
                      name="micro_phone_stand"
                      label="Microphone Stand"
                      options={[
                        {
                          label: 'Yes',
                          name: 'yes',
                        },
                        {
                          label: 'No',
                          name: 'no',
                        },
                      ]}
                    />
                  </Box>
                </Box>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="software"
                      label="Software"
                      size="lg"
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="any_other_equipment"
                      label="Any other Special Equipment"
                      size="lg"
                    />
                  </Box>
                </Box>
              </AdditionalVOADetailsDiv>
            )}
            {!isOverseasUser && (
              <BankDetailsDiv>
                <SectionHeading>Bank Details</SectionHeading>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="account_name"
                      label="Full Name ( Account Holder Name )"
                      size="lg"
                      hasError={errors.account_name}
                    />
                  </Box>
                  <Box width={["100%", "49%"]} mb={16}>
                    <FloatingInput
                      type="text"
                      name="bank_name"
                      label="Bank Name"
                      size="lg"
                      hasError={errors.bank_name}
                    />
                  </Box>
                </Box>

                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="account_no"
                      label="Account Number"
                      size="lg"
                      hasError={errors.account_no}
                    />
                  </Box>
                  <Box width={['100%', '49%']} mb={16}>
                    {isOverseasUser ? (
                      <FloatingInput
                        type="text"
                        name="swift_code"
                        label="SWIFT Code"
                        size="lg"
                        hasError={errors.swift_code}
                      />
                    ) : (
                      <FloatingInput
                        type="text"
                        name="ifsc_code"
                        label="IFSC Code"
                        size="lg"
                        hasError={errors.ifsc_code}
                      />
                    )}
                  </Box>
                </Box>
                {isOverseasUser && (
                  <Box variant="flex" flexDirection={['column', 'row']}>
                    <Box width={['100%', '49%']} mb={16}>
                      <FloatingInput
                        type="text"
                        name="routing_number"
                        label="Routing Number"
                        size="lg"
                        maxLength={20}
                        hasError={errors.routing_number}
                      />
                    </Box>
                    <Box width={['100%', '49%']} mb={16}>
                      <FloatingInput
                        type="text"
                        name="bank_address"
                        label="Bank Address"
                        size="lg"
                        hasError={errors.bank_address}
                      />
                    </Box>
                  </Box>
                )}
                {!isOverseasUser && (
                  <Box variant="flex" flexDirection={['column', 'row']}>
                    <Box width={['100%', '49%']} mb={16}>
                      <FloatingInput
                        type="text"
                        name="pan_card_no"
                        label="Pan Card Number"
                        size="lg"
                      />
                    </Box>
                  </Box>
                )}
                <Box variant="flex" flexDirection={['column', 'row']}>
                  {!isOverseasUser && (
                    <Box width={['100%', '49%']} mb={16}>
                      <ToggleSwitch
                        label="Do you have a GST identification number?"
                        name="is_gst_no"
                      />
                      <Box width={['100%', '100%']} mb={16}>
                        {values.is_gst_no && (
                          <FloatingInput
                            type="text"
                            name="gst_number"
                            label="GST Number"
                            size="lg"
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </BankDetailsDiv>
            )}
            {!isOverseasUser && (
              <DocumentsDiv>
                <SectionHeading>Documents</SectionHeading>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']}>
                    <Upload
                      label={
                        isOverseasUser
                          ? 'Bank Statement/Void Check'
                          : 'Passbook Copy or Cancelled cheque'
                      }
                      name="cancelled_cheque_url"
                      privateDownload
                      hideInputOnValue={true}
                      cloud_dir_file={getTokenAndUid().uid}
                      saveWithoutCDN={1}
                      showProgress={true}
                      placeholder="Drop your doc file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      fileTypeTags="user_doc"
                      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                      extraParams={{
                        title: 'Bank-Document',
                      }}
                    />
                  </Box>
                  <Box width={['100%', '49%']}>
                    {!isOverseasUser ? (
                      <Upload
                        label="Pan Card"
                        name="pan_card_url"
                        privateDownload
                        cloud_dir_file={getTokenAndUid().uid}
                        saveWithoutCDN={1}
                        hideInputOnValue
                        showProgress
                        placeholder="Drop your doc file here to upload or click to select"
                        renderComp={FileUploadComponent}
                        fileTypeTags="user_doc"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                        extraParams={{
                          title: 'Pan Card',
                        }}
                      />
                    ) : (
                      <Upload
                        label="W9 (U.S) or W8 (U.K/Europe/Canada) - TRC"
                        name="trc"
                        privateDownload
                        hideInputOnValue
                        cloud_dir_file={getTokenAndUid().uid}
                        saveWithoutCDN={1}
                        showProgress
                        placeholder="Drop your doc file here to upload or click to select"
                        renderComp={FileUploadComponent}
                        fileTypeTags="user_doc"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                        extraParams={{
                          title: 'TRC',
                        }}
                        hasError={errors.trc}
                      />
                    )}
                  </Box>
                </Box>
              </DocumentsDiv>
            )}
            {!isOverseasUser && (
              <AddressDiv>
                <SectionHeading>Mailing Address</SectionHeading>
                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="address_1"
                      label="House No, Apt No, Building No"
                      size="lg"
                    />
                  </Box>

                  <Box width={['100%', '49%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="address_2"
                      label="Address Line 2"
                      size="lg"
                    />
                  </Box>
                </Box>

                <Box variant="flex" flexDirection={['column', 'row']}>
                  <Box width={['100%', '32%']} mb={16}>
                    <FloatingInput
                      type="text"
                      name="pin_code"
                      label="Pincode"
                      size="lg"
                    />
                  </Box>

                  <Box variant="flex" width={['100%', '67%']}>
                    <Box width="49%" mb={16}>
                      <FloatingInput
                        type="text"
                        name="city"
                        label="City"
                        size="lg"
                      />
                    </Box>

                    <Box width="49%" mb={16}>
                      <FloatingInput
                        type="text"
                        name="state"
                        label="State"
                        size="lg"
                      />
                    </Box>
                  </Box>
                </Box>
              </AddressDiv>
            )}
            {errorMessage !== '' && (
              <InvalidDetailsDiv>{errorMessage}</InvalidDetailsDiv>
            )}
             {Object.keys(errors).length > 0 && submitCount > 0 && <InvalidDetailsDiv>
              {
                errors['voice_samples'] && errors['voice_samples'].map((v, index) => <Fragment key={index}>
                  <p>{v?.desc}</p><p>{v?.genre}</p>
                  <p>{v?.voice_range}</p><p>{v?.audio_url}</p></Fragment>)
              }
              {errors?.fullname}
              {errors?.email}
            </InvalidDetailsDiv>}
            <ActionButtonBox>
              {!isSubmitting && (
                <SaveButton type="submit" disabled={!dirty}>Save Profile</SaveButton>
              )}
              {isSubmitting && (
                <SaveButton type="button">
                  <Loader invertColor inline size="sm"/>
                </SaveButton>
              )}
            </ActionButtonBox>
          </Form>)
        }
      </Formik>
    </SubHeader>
  )
}

export default ProfileUI
