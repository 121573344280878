import React from 'react'
import PrivatePage from '../../../../components/PrivatePage'
import New from './New'
import Edit from './Edit'
import EditBookDetails from './EditBookDetails'
import SetupAudition from './SetupAudition';
import NewConcept from '../Concept/newConcept';
import ConceptDetail from '../Concept/conceptDetail';
import EditConcept from '../Concept/editConcept';
const AudiobookActionPage = props => {
  const { match: { params: {action} } } = props
  let Comp
  switch (action) {
    case 'new':
      Comp = New
      break
    case 'edit':
      Comp = Edit
      break
    case 'edit-book-details':
      Comp = EditBookDetails
      break
    case 'setup-audition':
      Comp = SetupAudition
      break
    case 'new-concept':
      Comp = NewConcept
      break
    case 'concept-detail':
      Comp = ConceptDetail
        break
    case 'edit-concept':
      Comp = EditConcept
      break
    default:
      Comp = () => {return null}
  }
  return (
    <PrivatePage>
      <Comp />
    </PrivatePage>
  )
}

export default AudiobookActionPage
