import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import AddEditEpisode from './addEditEpisode'
import SingleEpisodeView from './singleEpisodeView'
import { get, put } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import GenericError from '../../../../../components/GenericError'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import MessagePopup from '../../../../../components/MessagePopup'
import ReorderIcon from '../../../../../components/Images/Reorder'
import DragIcon from '../../../../../components/Images/DragHandle'
import { isAdminAccess } from '../../../../../config'
import { Popup } from '../../../../../components/Popup'

const ListContainer = styled.ul``
// imp: this should be a ul and not a div for re-arrange to work properly

const DragHandle = SortableHandle(DragIcon)


const EpisodeComponent = ({ data, handleEpisodeDeleteFromState, ...props }) => {
  const [mode, setMode] = useState('view')
  const [dataFromProps, setData] = useState(data || {})
  const [isLoading, setLoading] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const handleChangeToEditMode = () => setMode('edit')

  useEffect(() => {
    if (mode === 'edit') {
      setLoading(true)
      const { story_id, chapter_details: {chapter_id} = {} } = data
      const params = {
        story_id: story_id || null,
        chapter_id: chapter_id || null,
        view: isAdminAccess ? 'cms' : 'dashboard',
        show_id: data.show_id
      }
      get('/content_api/book.episode_details', {params}).then(response => {
        const { result } = response
        get('/content_api/ads.entity_ads', {
          params: {
            entity_id: story_id
          }
        }).then(res => {
          const { ads } = res
          setData({...dataFromProps, ...result, ad: ads[0] || {}})
          setLoading(false)
        }).catch(err => {
          console.log(err)
          setData({...dataFromProps, ...result})
          setLoading(false)
        })
      }).catch(error => {
        setLoading(false)
        setMode('view')
        throw error
      })
    }
  }, [mode])
  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }
  return (
    <div>
      <GenericError 
        show={apiError}
        onClose={() => toggleApiError(false)}
      />
      {
        mode === 'view' && (
          <SingleEpisodeView
            {...props}
            data={data}
            handleOnClick={handleChangeToEditMode}
            dragHandle={() => <DragHandle/>}
          />
        )
      }
      {mode === 'edit' && (
          <>
            <Popup
              show={true}
              className="right-drawer-episode-form"
              close={() => setMode('view')}
            >
              <AddEditEpisode
                {...props}
                data={dataFromProps}
                mode="edit"
                handleCancel={() => setMode('view')}
              />
            </Popup>
          </>
        )
      }
    </div>
  )
}
const SortableEpisode = SortableElement(EpisodeComponent)

function EpisodeList({
  episodes,
  thumbnail,
  book_id,
  show_id,
  isFetching,
  lastNodeEleRef,
  ...props
}) {
  return (
    <ListContainer>
      {(episodes || []).map((episode, i) => {
        return (
          <SortableEpisode
            key={episode?.chapter_details?.chapter_id}
            data={{ ...episode, thumbnail, book_id, show_id }}
            {...props}
            index={i}
          />
        )
      })}
      {isFetching && <Loader />}
      {lastNodeEleRef && <div ref={lastNodeEleRef} />}
    </ListContainer>
  )
}
const SortableList = SortableContainer(EpisodeList)

const SortableEpisodeList = ({setBookDetails, ...props}) => {
  const [moveIndexes, setMoveIndexes] = useState([0, 0])
  const [isWarningShown, setWarning] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState({})

  const getSequenceFromIndices = () => {
    const [oldIndex, newIndex] = moveIndexes
    const { episodes } = props
    const EpisodeObjFrom = episodes[oldIndex] || {}
    const EpisodeObjTo = episodes[newIndex] || {}
    const sequenceFrom = EpisodeObjFrom.natural_sequence_number || (EpisodeObjFrom.chapter_details || {}).seq_number
    const sequenceTo = EpisodeObjTo.natural_sequence_number || (EpisodeObjTo.chapter_details || {}).seq_number
    return {sequenceFrom, sequenceTo}
  }
  
  const onSortEnd = ({oldIndex, newIndex}) => {
    console.log(oldIndex, newIndex)
    if (oldIndex === newIndex) return
    setMoveIndexes([oldIndex, newIndex])
    setWarning(true)
  }
  
  const handleMove = () => {
    const [oldIndex, newIndex] = moveIndexes
    // setBookDetails(prevBook => ({...prevBook, episodes: arrayMove(prevBook.episodes, oldIndex, newIndex)}))
    const { episodes, show_id, book_id } = props
    const EpisodeObjFrom = episodes[oldIndex]
    const EpisodeObjTo = episodes[newIndex]
    const storyIdFrom = EpisodeObjFrom.story_id
    const storyIdTo = EpisodeObjTo.story_id
    const sequenceFrom = EpisodeObjFrom.seq_number || EpisodeObjFrom.chapter_details.seq_number
    const sequenceTo = EpisodeObjTo.seq_number || EpisodeObjTo.chapter_details.seq_number
    const isBothSameTypes = storyIdFrom ? !!storyIdTo : !storyIdTo
    if (isBothSameTypes) {
      const api = '/content_api/book.sequence_show_episodes'
      let data = {
        prev_seq: sequenceFrom,
        next_seq: sequenceTo,
        view: isAdminAccess ? 'cms' : 'dashboard'
      }
      if (storyIdFrom) {
        // its a story, different body format
        data['show_id'] = show_id
      } else {
        // its a chapter
        data['book_id'] = book_id
        data['is_chapter'] = true
      }
      put(api, {
        data
      }).then(() => {
        setWarning(false)
        props.successCallback()
      }).catch(error => {
        setWarning(false)
        setApiErrorMsg({})
        throw error
      })
    } else {
      setWarning(false)
      setApiErrorMsg({
        title: 'Cannot move to selected position',
        message: `the selected item cannot be moved from s.no ${sequenceFrom} to s.no ${sequenceTo}`
      })
      toggleApiError(true)
    }
  }
  return (
    <>
      <GenericError 
        show={apiError}
        title={apiErrorMsg.title}
        message={apiErrorMsg.message}
        onClose={() => toggleApiError(false)}
      />
      <MessagePopup
        show={isWarningShown}
        renderIcon={<ReorderIcon/>}
        onClose={() => setWarning(false)}
        onConfirm={handleMove}
        cancelText="Cancel"
        confirmText={"Change sequence"}
        messageTitle="Confirm Sequence changes?"
        messageBody={`Are you sure you want to change the sequence of this episode from s.no ${getSequenceFromIndices().sequenceFrom} to s.no ${getSequenceFromIndices().sequenceTo}, this will result in changing sequence of other episodes as well.`}
      />
      <SortableList
        {...props}
        lockAxis='y'
        onSortEnd={onSortEnd}
        // pressDelay={200}
        useDragHandle={true}
      />
    </>
  )
}
export default SortableEpisodeList
