import React from 'react'

const NoResults = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="none" viewBox="0 0 192 192" {...props}>
    <path fill="#E5F1F9" d="M151.506 178.568c0 3.115-21.179 5.596-47.234 5.596-26.107 0-47.234-2.534-47.234-5.596 0-3.116 21.18-5.597 47.234-5.597 26.107 0 47.234 2.481 47.234 5.597z"/>
    <path fill="#AED6F2" d="M109.095 160.722c-37.64 2.429-98.766-20.01-75.962-56.124 17.667-27.983 3.25-47.835-5.872-56.283C11.168 33.426 27.944-4.43 103.959.427c69.409 4.435 95.149 51.056 86.394 92.925-7.864 37.592-38.06 64.625-81.257 67.37z"/>
    <path fill="#068AE2" d="M69.091 117.854l-14.086 14.187 4.596 4.629 14.087-14.186-4.597-4.63z"/>
    <path fill="#068AE2" d="M52.86 127.637L0 180.875 11.046 192l52.86-53.238-11.046-11.125z"/>
    <path fill="#068AE2" d="M52.833 127.665l-3.67 3.696 11.047 11.125 3.67-3.696-11.047-11.125zM108.623 21.494c-33.08 0-59.868 26.98-59.868 60.242 0 33.263 26.788 60.243 59.815 60.243s59.816-26.98 59.816-60.243c.053-33.263-26.736-60.242-59.763-60.242zm0 109.45c-26.998 0-48.86-22.017-48.86-49.208 0-27.19 21.862-49.208 48.86-49.208s48.859 22.017 48.859 49.208-21.913 49.208-48.859 49.208z"/>
    <g opacity=".7">
        <path fill="#E1FFF5" d="M131.48 72.232c-4.089-7.34-3.775-15.365.734-17.846 4.456-2.535 11.428 1.425 15.465 8.764 4.089 7.34 3.774 15.364-.734 17.846-4.456 2.482-11.376-1.425-15.465-8.764zM122.883 51.694c-1.678-2.112-1.416-5.122.576-6.759 1.992-1.584 4.981-1.214 6.658.95 1.678 2.113 1.416 5.175-.577 6.759-1.992 1.584-4.98 1.162-6.657-.95z" opacity=".7"/>
    </g>
    <path fill="#E5F1F9" d="M33.92 73.289c2.883-14.097-12.53-15.892-13.893-7.709-1.835 10.771 11.48 19.536 13.892 7.709zM29.777 85.168c-5.661 1.637-2.097 7.761 1.154 6.705 4.246-1.372 3.617-8.078-1.154-6.705z"/>
    <ellipse cx="84.927" cy="72.338" fill="#383838" rx="6.815" ry="6.864"/>
    <ellipse cx="83.879" cy="71.282" fill="#fff" rx="3.67" ry="3.696"/>
    <ellipse cx="87.287" cy="76.298" fill="#fff" rx="1.311" ry="1.32"/>
    <ellipse cx="126.866" cy="72.338" fill="#383838" rx="6.815" ry="6.864"/>
    <ellipse cx="125.818" cy="71.282" fill="#fff" rx="3.67" ry="3.696"/>
    <ellipse cx="129.226" cy="76.298" fill="#fff" rx="1.311" ry="1.32"/>
    <path fill="#383838" fillRule="evenodd" d="M104.493 84.085c-3.626.524-6.344 2.53-7.715 4.384-.346.467-1.003.564-1.468.216-.464-.348-.56-1.01-.214-1.478 1.687-2.281 4.892-4.605 9.099-5.212 4.246-.613 9.402.535 14.941 5.02.451.365.523 1.03.16 1.485-.363.454-1.023.526-1.474.161-5.156-4.174-9.741-5.094-13.329-4.576z" clipRule="evenodd"/>
  </svg>
)

export default NoResults
