import React, { useState } from 'react'
import styled from 'styled-components'
import { promotional_text_unpublished, promotional_text_published, promotional_text_eligible, promotional_text_pending, promotional_text_approved, promotional_text_rejected, promotional_text_updated } from '../constants'
import { withRouter } from 'react-router-dom'
import TimeLine from '../timeLine'
import StepIndicator from '../../../components/StepIndicator'
import { numFormatter } from '../../../lib/utils'
import { get as localGet } from '../../../lib/storage'
import Loader from '../../../components/Loader'
import { get, post } from '../../../lib/api'
import { Stats } from './monthlyBookCard'
import { menuItemObj } from '../../../components/PrivatePage'
import { Box, Text } from '../../../designSystem'
import WriterPopup from '../../Shows/Novels/WriterPopup'

const WBPBookCard = styled.div`
  margin-bottom: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    padding: 16px;
  }
`
const TopCardSection = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

export const WBPThumbnail = styled.div`
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  min-width: 112px;
  height: 168px;
  margin-right: 24px;
  background-color: var(--Grey100);
  cursor: pointer;

  img {
    width: 112px;
    height: 168px;
  }
`
export const WbpDetails = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.div`
  color: var(--Text700);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  margin-bottom: 8px;
`
export const Subtext = styled.div`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 8px;
`
export const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500); 
`
const CtaBox = styled.div`
  display: flex;
  margin-top: 16px;
`
const BottomCardSection = styled.div`
  padding: 12px;
  border-radius: 4px;
  border: ${({ theme }) => 'solid 1px ' + theme.colors.surface.sf900};
  background-color: ${({ theme }) => theme.colors.surface.sf100};
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const BookProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 16px;
  }
`
const WordsProgress = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
`
const ProgressDivOuter = styled.div`
  border-radius: 4px;
  background-color: rgba(6,138,226, 0.3);
  height: 8px;
`
const ProgressDivInner = styled.div`
  height: 8px;
  background-color: var(--Azure500);
  border-radius: 4px;
  width: ${({ width }) => width || 0}%;
`
export const AccountProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const CreatorDetailsBox = styled.div`
  display: flex;

  @media (max-width: 600px) {
    margin: 0 auto 16px;
  }
`
export const CreatorImage = styled.div`
  width: 48px;
  height: 48px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  margin-right: 16px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`
export const CreatorDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  // span {
  //   font-size: 12px;
  //   line-height: 1.33;
  //   text-align: left;
  //   color: var(--Text500);
  //   margin-bottom: 4px;
`
export const CreatorName = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  text-transform: capitalize;
`
export const StepsDiv = styled.div``

export const BookAction = styled.div`
  cursor: pointer;
  min-width: 130px;
  padding: 14px;
  border-radius: 4px;
  background-color: ${({ enabled }) => enabled ? "var(--Azure500)" : "#e6e6e6"};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: ${({ enabled }) => enabled ? "rgba(255, 255, 255, 0.98)" : "var(--Text100)"};
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
  }
`
export const BookActionWhite = styled(BookAction)`
  background-color: var(--dove);
  color: var(--Text500);
  margin-left: 16px;
`
const ActionButtonWhite = styled.div`
  border-radius: 4px;
  border: solid 2px var(--LD10);
  cursor: pointer;
  padding: 14px;
  width: 180px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 16px;
`

const HR = styled.div`
  height: 2px;
  background-color: var(--Grey300);
  margin-bottom: 24px;
`
const BottomHr = styled(HR)`
  margin-top: 32px;
  margin-bottom: 0px;
`
const StatsHolder = styled.div`
  margin-top: 24px;
  margin-bottom: -12px;
`
const BookCard = ({ resetAfterUnblock, history, book, totalBooks, novelApply, viewApplication, milestone_reward_threshold, is_program_user, isMobile }) => {
  const [isPublishLoading, setIsPublishLoading] = useState(false)
  const [isUnlockProcessing, setUnlockProcessing] = useState(false)
  const [isPopupShown,setIsPopupShown] = useState(false)
  const profileImage = localGet('image_url')
  const profileName = localGet('name')


  const handlePublishClick = async () => {
    setIsPopupShown(true)
    if(!isPopupShown) return
    if (isPublishLoading) return
    setIsPublishLoading(true)
    let getDetailsBeforePublish = false
    // TODO: disucss if throw error and not procees if details fetch api fails,
    // which might lead to wrong flag sent to chapter open page
    const detailsRes = await get('/content_api/book.novel_details', {
      params: {
        book_id: book.book_id,
        view: 'dashboard',
      },
    })
    const { results } = detailsRes
    const hasRequiredBookDetails = results.image_url && results.description && results.book_title && results.category_obj && results.category_obj.topic_id
    if (!hasRequiredBookDetails) {
      getDetailsBeforePublish = true
    }
    get('/content_api/book.chapters_by_status', {
      params: {
        page_no: 1,
        book_id: book.book_id,
        status: 'draft',
        view: 'dashboard'
      },
    }).then(response => {
      setIsPublishLoading(false)
      const { results } = response
      const firstChapter = results.chapters[0] || {}
      const { chapter_id } = firstChapter
      if (chapter_id) {
        history.push(`${menuItemObj.novels}/edit-chapter/${book.book_id}?chapter_id=${chapter_id}&proceed_to_details=${getDetailsBeforePublish ? '1' : ''}&redirect_to_home=1`)
      } else {
        history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)
      }
    }).catch(error => {
      setIsPublishLoading(false)
      throw error
    })
  }

  const handleUnlockReward = () => {
    if (isUnlockProcessing) return
    const { program_id } = book
    if (!program_id) return
    setUnlockProcessing(true)
    post('/content_api/book.rewards_unlock', { data: {
      program_id
    } }).then(() => {
      setUnlockProcessing(false)
      resetAfterUnblock()
    }).catch(error => {
      setUnlockProcessing(false)
      throw error
    })
  }

  return (
    <WBPBookCard key={book.book_id}>
      {!is_program_user && totalBooks < 2 && (
        <>
          <AccountProgress>
            <CreatorDetailsBox>
              <CreatorImage>
                <img src={profileImage} />
              </CreatorImage>
              <CreatorDetails>
                <Text variant="smallText">Welcome back!</Text>
                <Text variant="smallTag">{profileName}</Text>
              </CreatorDetails>
            </CreatorDetailsBox>
            <StepsDiv>
              <StepIndicator
                steps={['Signup', 'Publish', 'Apply']}
                current={2}
                primaryColor={"#79ba10"}
                armLength={isMobile ? 109 : 150}
              />
            </StepsDiv>
          </AccountProgress>
          <Box variant="hr" my={24}/>
        </>
      )}
      {isPopupShown && <WriterPopup  setIsPopupShown={setIsPopupShown}/>}
      {/* {!is_program_user && totalBooks < 2 && <HR />} */}
      <Box variant="flex">
        <WBPThumbnail onClick={() => history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)}>
          {book.image_url && <img src={book.image_url} />}
        </WBPThumbnail>
        <WbpDetails>
          <Title>
            {/* {
              book.is_eligible ? "Congratulations! You’re eligible for writer benefit Program" : "Make money with Your Novel"
            } */}
            {!book.published && book.application_status === 'not_applied' && "Next Step - Publish your novel"}
            {book.published && book.application_status === 'not_applied' && !book.is_eligible && "Next Step - Complete 50,000 Words"}
            {book.published && book.application_status === 'not_applied' && book.is_eligible && "Next Step - Apply for the Writer Benefits Program"}
            {book.published && book.application_status === 'pending' && "Your Application is submitted"}
            {book.published && book.application_status === 'rejected' && "Your Application is Rejected"}
            {book.published && book.application_status === 'updated' && "Your Application is Updated"}
            {book.published && book.application_status === 'approved' && "Your Application is Approved"}
            {book.published && book.application_status === 'reapplied' && "Next Step - Complete 100,000 Words"}
          </Title>
          <Subtext>
            {book.book_title}
          </Subtext>
          <Description>
          {!book.published && book.application_status === 'not_applied' && promotional_text_unpublished}
          {book.published && book.application_status === 'not_applied' && promotional_text_published}
          {book.published && book.application_status === 'not_applied' && book.is_eligible && promotional_text_eligible}
          {book.published && book.application_status === 'pending' && promotional_text_pending}
          {book.published && book.application_status === 'rejected' && promotional_text_rejected}
          {book.published && book.application_status === 'updated' && promotional_text_updated}
          {book.published && book.application_status === 'approved' && promotional_text_approved}
          {book.published && book.application_status === 'reapplied' && "You can again apply to the Writer Benefits Program after completing 100,000 words on the basis of editorial feedback"}
          </Description>
          <CtaBox>
            {
              !book.published && book.application_status === 'not_applied' && <BookAction
                onClick={handlePublishClick}
                enabled={1}
              >
              {!isPublishLoading ? 'Publish Your Novel' : <Loader invertColor inline size="sm" />}
              </BookAction>
            }
            {
              book.published && book.application_status === 'pending' && <BookAction
                onClick={() => history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)}
                enabled={1}
              >
                Add New Chapter
              </BookAction>
            }
            {
              book.published && book.application_status === 'pending' && <ActionButtonWhite
                onClick={() => viewApplication(book.book_id)}
              >
                View Application
              </ActionButtonWhite>
            }
            {
              book.published && book.application_status === 'approved' && <BookAction
                onClick={() => isUnlockProcessing ? null : handleUnlockReward()}
                enabled={1}
              >
                {!isUnlockProcessing ? "Accept and Unlock Rewards" : <Loader invertColor inline size="sm" />}
              </BookAction>
            }

            {
              book.published && (book.application_status === 'rejected' || book.application_status === 'updated') && <ActionButtonWhite
                onClick={() => null}
              >
                Read Feedback
              </ActionButtonWhite>
            }

          </CtaBox>
        </WbpDetails>
      </Box>
      {book.published && (book.application_status === 'not_applied' || book.application_status === 'reapplied') && <BottomCardSection>
        <BookProgressBar>
          <WordsProgress>{book.word_count || 0} / {book.application_threshold}</WordsProgress>
          <ProgressDivOuter>
            <ProgressDivInner width={(book.word_count / book.application_threshold * 100) > 100 ? 100 : (book.word_count / book.application_threshold * 100)} />
          </ProgressDivOuter>
        </BookProgressBar>
        <BookAction onClick={() => book.is_eligible ? novelApply(book.book_id) : null} enabled={book.is_eligible}>Apply Now</BookAction>
      </BottomCardSection>}
      {book.published && (book.application_status === 'pending' || book.application_status === 'approved') && <BottomHr />}
      {book.published && (book.application_status === 'pending' || book.application_status === 'approved' || book.application_status === 'reapplied') && (
        <StatsHolder>
          <Stats
          views={book.stats.total_views}
          chapters={book.chapters_count}
          words={book.word_count}
        />
        </StatsHolder>
      )}
    </WBPBookCard>
  )
}

export default withRouter(BookCard)
