import React from 'react'

const PocketWriter = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M36 0H4C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0Z" fill="#068AE2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9409 6.88574C26.174 6.88574 31.2268 11.9311 31.2268 18.1548C31.2268 24.3784 26.174 29.4238 19.9409 29.4238C18.1514 29.4238 16.4541 29.0078 14.9499 28.2606L8.65521 33.0208V18.1548V17.4307H8.67834C9.05634 11.538 13.9549 6.88574 19.9409 6.88574Z" fill="white"/>
            <path d="M23.9571 13.5997H23.958C24.067 13.5997 24.1694 13.6422 24.2468 13.7194C24.325 13.7974 24.3681 13.9013 24.3681 14.0119V20.1925C24.3681 20.4192 24.183 20.604 23.9554 20.6046C22.996 20.6069 21.3886 20.8068 20.2797 21.9673V15.4991C20.2797 15.4222 20.2994 15.3501 20.3364 15.2903C21.2465 13.8247 22.9955 13.602 23.9571 13.5997Z" fill="#068AE2"/>
            <path d="M15.5511 20.1925V14.0118C15.5511 13.9012 15.5942 13.7973 15.6724 13.7193C15.7498 13.6421 15.8523 13.5996 15.9611 13.5996H15.9621C16.9237 13.6019 18.6726 13.8246 19.5828 15.2903C19.6198 15.35 19.6394 15.4222 19.6394 15.499V21.9672C18.5305 20.8067 16.9231 20.6068 15.9637 20.6045C15.7362 20.6039 15.5511 20.4191 15.5511 20.1925Z" fill="#068AE2"/>
            <path d="M14.6121 15.025H14.911V20.1925C14.911 20.7712 15.3825 21.243 15.962 21.2445C16.7758 21.2464 18.1176 21.4055 19.0679 22.305C17.4244 21.9025 15.6918 22.1641 14.7044 22.3892C14.5811 22.4172 14.4537 22.3882 14.3549 22.3095C14.2565 22.231 14.2 22.1136 14.2 21.9876V15.4371C14.2 15.2099 14.3849 15.025 14.6121 15.025Z" fill="#068AE2"/>
            <path d="M25.0079 20.1925V15.025H25.3067C25.5339 15.025 25.7188 15.2099 25.7188 15.4371V21.9874C25.7188 22.1135 25.6624 22.2308 25.5639 22.3094C25.4652 22.3881 25.338 22.4172 25.2145 22.389C24.2271 22.164 22.4944 21.9024 20.851 22.3048C21.8012 21.4055 23.143 21.2464 23.9568 21.2444C24.5364 21.243 25.0079 20.7712 25.0079 20.1925Z" fill="#068AE2"/>
        </svg>
    )
}

export default PocketWriter
