import React from 'react'
import PrivatePage from './../../../components/PrivatePage'
import SubHeader from '../../../components/WithSubHeaderBG'
import styled from 'styled-components'

const Container = styled.div`
  padding: 48px;
  background-color: var(--Dove);
  margin-bottom: 96px;
`
const Heading = styled.h1`
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--Text500);
`
const NList = styled.ol``
const LI = styled.li`
  font-size: 16px;
  line-height: 2.15;
  text-align: left;
  color: var(--Text500);
`
const ExampleBlock = styled.div`
  margin: 20px 0;
`
const Text = styled.p`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
`
const WritingTips = (props) => {
  const isMobile = window.screen.width < 768
  return (
    <PrivatePage>
      <SubHeader showBackButton padding={isMobile ? "0" : "336"}>
        <Container>
          <Heading>Important Tips for writing stories</Heading>
          <NList>
            <LI>
            Most trending genres on Pocket FM are – Romance, Crime, Suspense, Thriller, Motivational & Horror. However you are free to select a genre of your choice apart from these as long as it attracts the audience.
            </LI>
            <LI>The submitted story should be free of spelling and grammatical mistakes</LI>
            <LI>The story can be written in Hindi (हिंदी), English, Tamil (தமிழ்), Telugu (తెలుగు) , Bengali(বাংলা), Malayalam (മലയാളം), Marathi (मराठी) and Kannada (ಕನ್ನಡ).</LI>
            <LI>Use proper original scripting language to write your story. Do not write in Roman.</LI>
            <ExampleBlock>
              <Text>Example: The Don’ts</Text>
              <Text>Rahul Rina Ke Pass Gaya - Roman</Text>
            </ExampleBlock>
            <ExampleBlock>
              <Text>Example: The Do’s</Text>
              <Text>राहुल रीना के पास गया - हिंदी</Text>
            </ExampleBlock>
            <LI>Stories should be written just like a novel and not in a screenplay or dialogues format. Keep in mind that if selected for Audiobook, your story  will be narrated in a single voice and the audience should easily understand it.</LI>
            <ExampleBlock>
              <Text>Example: The Don’ts</Text>
              <Text>Rahul: Did you say something to me?</Text>
              <Text>Rashi: Yes, I like you Rahul.</Text>
            </ExampleBlock>
            <ExampleBlock>
              <Text>Example: The Do’s</Text>
              <Text>Rahul looked into Rashi’s eyes and asked, “Did you say something to me, Rashi?” Rashi was embarrassed. She took a moment and replied, “ I really like you Rahul!”</Text>
            </ExampleBlock>
            <LI>While planning and creating a plot for the story, remember you are writing for a long serialized fiction format. The plot should have the potential to go on for hundreds of episodes.</LI>
            <LI>Do not forget to focus on characterization as characters of your story need to keep the audience engaged for a long time. Give enough layers and traits to your characters so that they can lead the plot in an interesting manner. Have a plan to keep introducing new characters to keep a constant engagement.</LI>
            <LI>Every episode must have a high point and a strong “hook” in the end to keep the audience curious for the next episode.</LI>
            <LI>The first 3 episodes of the story are extremely critical. They need to be  engaging and have a mass appeal. The readers decide whether to go ahead with the story or not in the first 30 sec - 5 min</LI>
            <LI>Creating a ‘small conflict’ for every episode will keep the audience thrilled and they will get a sense of “something happening” in each episode. In the same way, you can plan a big conflict for every 10 episodes.</LI>
            <LI>Stories should be written in ‘Modern Language’. It should be your day to day speaking language.  Kindly refrain from using literary language.</LI>
            <LI>Plan each episode well with a proper episode title. Keep the length of all episodes constant for an uniform experience to the readers</LI>
          </NList>
        </Container>
      </SubHeader>
    </PrivatePage>
  )
}

export default WritingTips
