import React from 'react'

const Pen = props => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.3401 6.03532C16.7444 5.45652 16.6038 4.65982 16.0261 4.25428L10.9979 0.733497C10.4191 0.329228 9.62232 0.469594 9.21682 1.04754L8.24694 2.43267L15.3702 7.42045L16.3401 6.03532Z" fill="#0D1536" fillOpacity="0.4"/>
    <path d="M8.74762 11.2346C8.37858 11.7617 7.65202 11.8898 7.12497 11.5207C6.59779 11.1516 6.46968 10.425 6.83872 9.898C7.20786 9.37082 7.93442 9.24271 8.4616 9.61184C8.98864 9.98088 9.11676 10.7074 8.74762 11.2346Z" fill="#0D1536" fillOpacity="0.4"/>
    <path d="M13.1482 6.90726L9.40165 4.28387L6.79133 5.27246C5.46115 5.77434 4.51861 6.97209 4.34365 8.38288L3.3676 16.167L6.34891 11.976C5.62905 11.2376 5.582 10.0751 6.23976 9.28083C6.89739 8.4865 8.04834 8.31607 8.90797 8.88567C9.76774 9.45537 10.0595 10.5817 9.58446 11.497C9.10934 12.4124 8.02055 12.8221 7.05988 12.447L4.09313 16.6175L11.0265 13.0625C12.2925 12.4154 13.0956 11.1202 13.1122 9.69861L13.1482 6.90726Z" fill="#0D1536" fillOpacity="0.4"/>
  </svg>
)

export default Pen
