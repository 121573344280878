import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { AccountContext } from "../../providers/accountProvider";
import styled from "styled-components";
import LoginIcon from "../Welcome/writer-login-art.svg";
import StudioLogo from "../Images/StudioLogo";
import Loader from "../Loader";
import { Box } from "../../../src/designSystem";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FloatingInput, DropDownSelect } from "../../components/FormikInputs";
import { post, get } from "../../lib/api";



const Container = styled.div`
  background-color: var(--Grey300);
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
`;
const MainDiv = styled.div`
  display: flex;
  margin: auto;
  width: 900px;
  min-height: 540px;
  @media (max-width: 600px) {
    width: 100vw;
    flex-direction: column;
    // margin-right: 16px;
  }
`;
const LeftDiv = styled.div`
  background-color: var(--FjordDark300);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;
const RightDiv = styled.div`
  width: 50%;
  padding: 48px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;
const IconHolder = styled.div``;
const LogoHolder = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;
const ResetPasswordHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`;

const ResetPasswordButton = styled.button`
  outline: none;
  border: none;
  width: 100%;
  padding: 14px;
  align-items: center;
  cursor: ${({disabled})=>disabled?"not-allowed":"pointer"};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;
const ContactUsDiv = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  color: var(--Text500);
  margin-top: 16px;
`;

const ContactUsButton = styled.a`
  margin-left: 4px;
  text-decoration: None;
  color: var(--Azure500);
`;

function ResetPassword({ history, onUpdateUser }) {
  const [message, setMessage] = useState({"text":"",color:"red"});
  return (
    <Container>
      <MainDiv>
        <LeftDiv>
          <IconHolder>
            <img src={LoginIcon} />
          </IconHolder>
        </LeftDiv>
        <RightDiv>
          <LogoHolder>
            <StudioLogo />
          </LogoHolder>
            <>
              <ResetPasswordHeading>Set a New Password</ResetPasswordHeading>
              <Formik
                initialValues={{
                  password: "",
                  confirm_password: "",
                }}
                validationSchema={Yup.object({
                  password: Yup.string().required("Required"),
                  confirm_password: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  if(values.password!==values.confirm_password)
                  {
                    setSubmitting(false);
                    setMessage({...message ,"text":"Both Password Should Be Same","color":"red"});
                    return;
                  }
                    
                  let resetData = {
                    token: localStorage.getItem('resetPasswordToken'),
                    password:values.password,
                    confirm_password:values.confirm_password
                  };
                  post(`/user_api/user.change_password`, { data: resetData })
                    .then((response) => {
                      setSubmitting(false);
                      history.push('/global-login');
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      setMessage({...message ,"text":error.message,"color":"red"});
                    });
                }}
              >
                {({ values = {}, isSubmitting, errors,resetForm }) => (
                  <Form>
                    <Box width="100%" mb={16}>
                      <FloatingInput
                        type="password"
                        name="password"
                        label="Password"
                        size="lg"
                        password
                      />
                    </Box>
                    <Box width="100%" mb={16}>
                      <FloatingInput
                        type="password"
                        name="confirm_password"
                        label="Confirm Password"
                        size="lg"
                        password
                      />
                    </Box>
                    <Box mb={10}>
                    </Box>
                    <Box width="100%" mb={16}>
                      <ResetPasswordButton
                        id="ResetPassword-handler"
                        disabled={
                           values.password==="" || values.confirm_password===""
                        }
                        type="submit"
                      >
                        {isSubmitting ? (
                          <Loader size="sm" invertColor noPadding />
                        ) : (
                          "Save And Go To Reset Password"
                        )}
                      </ResetPasswordButton>
                    </Box>
                    {message.text !== "" && (
                        <Box variant="flex" justifyContent="center" color={message.color==="red"?"red":"green"}>
                          {message.text}
                        </Box>
                      )}
                  </Form>
                )}
              </Formik>
            </>
            <ContactUsDiv>
              <span>Need help?</span>
              <ContactUsButton href="mailto:writers@pocketfm.in">
                Contact Us
              </ContactUsButton>
            </ContactUsDiv>
        </RightDiv>
      </MainDiv>
    </Container>
  );
}
const GlobalResetPasswordPageWrapper = (props) => (
  <AccountContext.Consumer>
    {({ onUpdateUser, user }) => (
      <ResetPassword onUpdateUser={onUpdateUser} user={user} {...props} />
    )}
  </AccountContext.Consumer>
);

export default withRouter(GlobalResetPasswordPageWrapper);
