import { novelsTopics } from '../../constants'
import { get as getFromLocal, unset } from '../storage'
import { debounce } from './debounce'
import queryString from 'query-string'
import {v4}  from 'uuid'
import { post } from '../api'
const getNameFromUrl = (url, title) => {
  if (!url) return ''
  let urlSplit = url.split('/')
  return urlSplit.length > 1 ? title ? title + '.' + urlSplit.pop().split('.').pop() : urlSplit.pop() : ''
}

const getTokenAndUid = function () {
  let token = window.localStorage.getItem('token')
  let uid = window.localStorage.getItem('uid')
  return {token, uid}
}
const setAuthToken = function (key, value) {
  if (!(key && value)) return
  window.localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  )
}
const isLoggedIn = function () {
  const { token, uid } = getTokenAndUid()
  return Boolean(token && uid)
}

const saveSession = function (sessionData) {
  for (let key in sessionData) {
    if (sessionData[key]) {
      window.localStorage.setItem(key, (typeof sessionData[key] === 'object') ? JSON.stringify(sessionData[key]) : sessionData[key])
    }
  }
}
const clearSession = function () {
  // window.localStorage.clear()
  unset('uid')
  unset('name')
  unset('image_url')
  unset('token')
}

const getTopicNameFromId = id => {
  if (!id) return undefined

  const allTopics = JSON.parse(getFromLocal('allTopics')) || []
  const selectedTopic = allTopics.reduce((finalArr, {sub_topics}) => finalArr.concat(sub_topics), []).find(topic => topic.topic_id === id) || {}
  return selectedTopic.topic_name
}
const getNovelTopicNameFromId = id => {
  if (!id) return undefined

  const selectedTopic = novelsTopics.reduce((finalArr, {sub_topics}) => finalArr.concat(sub_topics), []).find(topic => topic.topic_id === id) || {}
  return selectedTopic.topic_name
}

const numFormatter = (num,flag = 'K') => {
  if ( num >= 1000000000 ) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
   }
   if (num >= 1000000  && (flag === 'M' || flag ===  'K') ) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
   }
   if (num >= 1000 && flag === 'K') {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
   }
   return num;
}

const getTimeFromSeconds = seconds => {
  if (seconds !== 0 && !seconds) return seconds
  return new Date(Number(seconds) * 1000).toISOString().substr(11, 8).split(':').map((el, i) => i === 0 && el === "00" ? "" : el).filter(Boolean).join(':')
}

const objectCloneWithTopLevelNullRemoved = object => {
  if (!object) return
  return Object.entries(object).reduce((obj, [a, b]) => {
    return (b === null || b === undefined) ? obj : {...obj, [a]: b}
  },{})
}

const parentTopicIds = [
  {topic_id: 'b71216c05fa0ac8f6f903af7d1600fcc69a297b8', language: 'hindi', name: 'Audiobook'},
  {topic_id: '234e590d77cdab7f54bc7411bf0b77e826facdf4', language: 'hindi', name: 'Audiobook Series'},
  {topic_id: '349176de48be18e8393aab288ce8564721c6f578', language: 'tamil', name: 'Audiobook'},
  {topic_id: 'fb318937f9e857038e16d9c5c90ef13b53dac484', language: 'tamil', name: 'Audiobook Series'},
  {topic_id: 'bf33ba42ff7cc10c56079fcf78f00016bee0152d', language: 'bengali', name: 'Audiobook'},
  {topic_id: '57b330dd40916bda4697e765a4b0510603cdbb3e', language: 'bengali', name: 'Audiobook Series'},
  {topic_id: 'malayalam-audiobook', language: 'malayalam', name: 'Audiobook'},
  {topic_id: 'a6fd6ec0fc2bc5835b51f09002576b5d4d0ebeed', language: 'malayalam', name: 'Audiobook Series'},
  {topic_id: 'telugu-audiobook', language: 'telugu', name: 'Audiobook'},
  {topic_id: '05006e8b81ddb504d6f7c2366d395c9681b0d562', language: 'telugu', name: 'Audiobook Series'},
  {topic_id: 'marathi-audiobook', language: 'marathi', name: 'Audiobook'},
  {topic_id: '09119c9393f4ea1ed0b76613d7d615e1a8b73eac', language: 'marathi', name: 'Audiobook Series'},
  {topic_id: 'kannada-audiobook', language: 'kannada', name: 'Audiobook'},
  {topic_id: 'e5330bdcc3e805badaf57e34797ee5720f8a5f51', language: 'kannada', name: 'Audiobook Series'},
  {topic_id: 'english-audiobook', language: 'english', name: 'Audiobook'},
  {topic_id: 'd79a4750949835a163280466f746a82f4e887a92', language: 'english', name: 'Audiobook Series'}
]

const novelsParentTopicIds = [
  {topic_id: '1c42d6fed3c2342d391be5d92bcd22bb16b84b73', language: 'hindi', name: 'Series'},
  {topic_id: 'any random key 123', language: 'hindi', name: 'Short Story'},
  {topic_id: '5fc60105f469b212c7d3f8f2b8bf0f91d38d00fd', language: 'english', name: 'Series'},
  {topic_id: 'english-ss-key', language: 'english', name: 'Short Story'},
]

const cmUids = [
  'a8f747dc7eba45278f487dc40edad4c2ee8c4957', // Yuvraj
  'b65f3d96ccd3d6ab98ae9b5086a1c9b807eac2d7', // Mohit
  '9f52973a146d9a2bc69f4d837dddc233a4d3c207', // Anant
  'a7eb04fc0c8cc086d26147f7a4de00ffbfb8c52b', // Prateek
  '0699c02f5021374582a97a209a03cf51e7ee4a05', // Appul
  'afc45b2312252bf15df5f8b472f3b69155d077be', // Anshu
  '1167e59a312f41b430fdc9eb43cd42f7943df725', // Chai
  '24ad700268f06ebec823e3d32a968a00385ea2aa', // Dishant
  'e389a492c554f1f88cc97ab4cce09ef4a75b9fb1', // Jayesh
  'e58bac6ced93703c1dfb8132616213ab054bc642', // Matwar
  '932cdfbffe8f2a5a15cdf5037cb3a0fe788f1418', // Matwar - QA
  '3b50713a31e287ce4287d4c421b0d636a4781bc0', // Jatin
  '16a17d5119c97c2b8c1f9fa3003b3603c3fbbd5d', // Hitesh
  '230159cf8d580426c7aa311d31618c52310a3090', // Aditi
  'b85c9c621c78a9653d8b6f88454cf52c59031162', // Archit
  '8dbe8abea4d74888b58403bf53fe2aa5197630f7', // Hamsa
  'e7c2309d81568e4504707653f3976d2b311993e6', // Sharan
  '56b71f74d22075d1469f62990128a0489aafca42', // Akhil
  '89698fde3b40b3d5ea74615de6424a593e6c376e',  // podcasts - shubham
  'd0f3621b08878fd50fa49aa5aff3db395b006502', // dev
]

// const clearSession = function () {
//   let cookie = new Cookies();
//   cookie.remove("uid", { path: '/' })
//   cookie.remove("token", { path: '/' })
//   cookie.remove("name", { path: '/' })
//   cookie.remove("image_url", { path: '/' })
// }

const ratingColorMapping = {
'0.0':	'#7e0100',
'0':	'#7e0100',
'1':	'#7e0100',
'1.0':	'#7e0100',
'1.1':	'#880100',
'1.2':	'#910100',
'1.3':	'#9c0100',
'1.4':	'#a70000',
'1.5':	'#b10000',
'1.6':	'#bc0000',
'1.7':	'#c70001',
'1.8':	'#d20001',
'1.9':	'#dc0001',
'2':	'#e70001',
'2.0':	'#e70001',
'2.1':	'#eb0806',
'2.2':	'#ef1b11',
'2.3':	'#f32d1c',
'2.4':	'#f64229',
'2.5':	'#fa5534',
'2.6':	'#fe6b41',
'2.7':	'#fe773c',
'2.8':	'#fc8234',
'2.9':	'#fb8b2d',
'3':	'#fa9626',
'3.0':	'#fa9626',
'3.1':	'#f69922',
'3.2':	'#eb9b1b',
'3.3':	'#df9d14',
'3.4':	'#d39f0e',
'3.5':	'#c7a107',
'3.6':	'#b8a509',
'3.7':	'#a9aa0a',
'3.8':	'#9aaf0c',
'3.9':	'#8cb40e',
'4':	'#7bb910',
'4.0':	'#7bb910',
'4.1':	'#75b711',
'4.2':	'#68ad14',
'4.3':	'#5ca516',
'4.4':	'#4f9b19',
'4.5':	'#42911c',
'4.6':	'#36881f',
'4.7':	'#297e22',
'4.8':	'#1c7524',
'4.9':	'#106c27',
'5':	'#0d6629',
'5.0':	'#0d6629',
}

function gcd (u, v) {
  if (u === v) return u;
  if (u === 0) return v;
  if (v === 0) return u;

  if (~u & 1)
      if (v & 1)
          return gcd(u >> 1, v);
      else
          return gcd(u >> 1, v >> 1) << 1;

  if (~v & 1) return gcd(u, v >> 1);

  if (u > v) return gcd((u - v) >> 1, v);

  return gcd((v - u) >> 1, u);
}

/* returns an array with the ratio */
const getRatio  = (w, h) => {
  var d = gcd(w,h);
  return [w/d, h/d];
}

const checkAspectRatioMatch = (w, h, ratioArr) => {
  if (!w && !h && !ratioArr) return false
  const [width, height] = getRatio(w, h)
  if (width === ratioArr[0] && height === ratioArr[1]) return true
  return false
}

const removeHtmlTags = str => {
  if (str) {
    return str.replace( /(<([^>]+)>)/ig, '')
  }
  return str
}

const convertUrlToHttps = (url, forceConvertAll = false) => {
  if (!url) return
  if (forceConvertAll) {
    return url.replace('http', 'https').replace('httpss', 'https')
  }
  if (window.location.protocol === 'https:') {
    return url.replace('http', 'https').replace('httpss', 'https')
  }
  return url
}
const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

const getWordCountFromText = text => {
    if (!text) return 0
    const regex = /(?:\r\n|\r|\n)/g;  // new line, carriage return, line feed
    const cleanString = text.replace(regex, ' ').trim(); // replace above characters w/ space
    const wordArray = cleanString.match(/\S+/g);  // matches words according to whitespace
    return wordArray ? wordArray.length : 0
}

const convertToQueryString = (obj,key) => {
  const QString = queryString.stringify({
    [key]: JSON.stringify(obj)
  });
  return QString
}

const handleEventLog = (screenName,event,metaData={}) => {
  let { uid } = getTokenAndUid()
  if(metaData.uid){
    uid = metaData.uid
  }
  const id = v4()
  const eventId = id.split('-').join('')
  const source = localStorage.getItem('referrer') !== 'undefined' ? localStorage.getItem('referrer') : '';
  const data  = {
      screen_name : screenName,
      client_ts: String(Date.now()),
      event : event,
      view_type: metaData.route,
      source
  }
  if(!localStorage.getItem('uuid')){
    const uniqueId = v4().split('-').join('')
    localStorage.setItem('uuid', uniqueId)
  }
  const common_fields ={
    device_id : localStorage.getItem('uuid'),
    uid
  }
  if(metaData.phone_number){
    data['phone'] = metaData.phone_number
  }
  if(metaData.button){
    data['module_name'] = metaData.button
  }
  if(metaData.time){
    data['time_spent'] = String(metaData.time)
  }
  if(metaData.entity_type){
    data['entity_type'] = metaData.entity_type
    data['entity_id'] = metaData.entity_id
  }
  if (metaData.type) {
    data['type'] = metaData.type
  }
  const events = [{ data: data, eventId }]
  const postData = {
      events,
      common_fields,
      group: "user_events"
  }
  
  if(process.env.REACT_APP_DEPLOY_ENV !== 'production') {
    console.log({eventData: postData})
    return
  }

  post('https://api.pocketfm.in/v2/logging_data/log', {data: postData, headers : {'platform' : 'studio'}} ).then(() => {
      // console.log("data",postData)
    }).catch((err) => { throw err })
}

export const handleVideoWatchedEventLog = (
  screenName,
  progress,
  metaObj = {}
) => {
  const eventLogMetaObj = {
    ...metaObj,
    route: window.location.pathname + window.location.search,
  }
  handleEventLog(screenName, `video_watched_${progress}`, eventLogMetaObj)
}

export const handleVideoPlayedEventLog = (screenName, metaObj = {}) => {
  const eventLogMetaObj = {
    ...metaObj,
    route: window.location.pathname + window.location.search,
  }
  handleEventLog(screenName, `video_played`, eventLogMetaObj)
}

const getParticularDocument = (documents, doc_name, type) => {
  const index = documents.findIndex((doc) => doc[doc_name])
  return index >= 0 ? documents[index][doc_name][type] : ''
}

const getDocObj = (doc_name,doc_url,documents) => {
  const id = getParticularDocument(documents,doc_name,'id')
  return id === '' ? { url : doc_url } : { url : doc_url,id }
}

const checkOverseasUser = (user) => {
    if(user.location === 'IN') return false;
    if(user.location === '' && user.login_type === 'email_login') return true;
    if(user.location) return true;
    return false;
}

const getRoleStringFromAccountType = (accountType) => {
  if (!accountType) return ''
  if (accountType === 'Author') return 'author'
  if (accountType === 'VO Artist') return 'vo'
  if (accountType === 'Publisher') return 'publisher'
  if (accountType === 'Writing Agency') return 'writing_agency'
  if (accountType === 'Production House') return 'production_house'
  if (accountType === 'localised_agency') return 'localised_agency'
  if (accountType === 'recording_studio') return 'recording_studio'
  return ''
}
const mapForUItoBEStatus = {
  // handling prev key
  raw_under_qc: 'Raw under QC',
  pending: 'Raw under QC',

  issues_in_raw: 'Issues found in raw',

  // handling prev key
  // issues_resolved_by_voa: 'Issues resolved in raw',
  issues_resolved_in_raw: 'Issues resolved in raw',

  ready_for_master: 'Ready for Master',
  // raw_approved: 'Ready for Master',

  master_under_qc: 'Master under QC',
  issue_in_master: 'Issues found in master',

  // handling prev key
  // issue_resolved_by_se: 'Issues resolved in master',
  issue_resolved_in_master: 'Issues resolved in master',

  final_master: 'Final Master',
  approved: 'Master Approved',
}

const colorMapperStatus = (status) => {
  switch (status) {
    case 'pending':
      return {
        color: 'var(--Corn800)',
        borderColor: 'rgba(242,198,2,0.2)',
        bgColor: 'rgba(242,198,2,0.1)',
      }
    case 'issues to be resolved':
      return {
        color: 'var(--Punch800)',
        borderColor: 'rgba(221, 54, 35, 0.2)',
        bgColor: 'rgba(221, 54, 35, 0.1)',
      }
    case 'issues resolved in script':
      return {
        color: 'var(--azure-700)',
        borderColor: 'rgba(3,80,162,0.2)',
        bgColor: 'rgba(3,80,162,0.1)',
      }
    case 'approved':
      return {
        color: 'var(--Lime800)',
        borderColor: 'rgba(121,186,16,0.2)',
        bgColor: 'rgba(121,186,16,0.1)',
      }
    default:
      return {
        color: 'var(--Corn800)',
        borderColor: 'rgba(242,198,2,0.2)',
        bgColor: 'rgba(242,198,2,0.1)',
      }
  }
}

const colorMapperForVoaStatus = (status) => {
  switch (status) {
    case 'pending':
    case 'ready_for_master':
    case 'master_under_qc':
      return {
        color: 'var(--Corn800)',
        borderColor: 'rgba(242,198,2,0.2)',
        bgColor: 'rgba(242,198,2,0.1)',
      }
    case 'issues_in_raw':
    case 'issue_in_master':
      return {
        color: 'var(--Punch800)',
        borderColor: 'rgba(221, 54, 35, 0.2)',
        bgColor: 'rgba(221, 54, 35, 0.1)',
      }
    case 'issues_resolved_in_raw':
    case 'issue_resolved_in_master':
      return {
        color: 'var(--azure-700)',
        borderColor: 'rgba(3,80,162,0.2)',
        bgColor: 'rgba(3,80,162,0.1)',
      }
    case 'final_master':
    case 'approved':
      return {
        color: 'var(--Lime800)',
        borderColor: 'rgba(121,186,16,0.2)',
        bgColor: 'rgba(121,186,16,0.1)',
      }
    default:
      return {
        color: 'var(--Corn800)',
        borderColor: 'rgba(242,198,2,0.2)',
        bgColor: 'rgba(242,198,2,0.1)',
      }
  }
}

const isWordCountZero = (wordCount) => {
  if (!wordCount) {
    Promise.reject({ message: 'Word Count cannot be 0' })
    return true
  }
}

function removeExtraSpaces(str) {
  if(!str) return;
  return str.replace(/\s+/g, ' ').trim();
}

function removeAllSpaces(str) {
  if(!str) return;
  return str.replace(/\s+/g, '').trim();
}

export { debounce, checkOverseasUser, getDocObj, handleEventLog, getParticularDocument, getTokenAndUid,setAuthToken, isLoggedIn, saveSession, clearSession, getNameFromUrl, getTopicNameFromId, getNovelTopicNameFromId, numFormatter, getTimeFromSeconds, objectCloneWithTopLevelNullRemoved, parentTopicIds, novelsParentTopicIds, ratingColorMapping, checkAspectRatioMatch, removeHtmlTags, convertUrlToHttps, monthsLong, monthsShort, cmUids, getWordCountFromText, convertToQueryString, getRoleStringFromAccountType, mapForUItoBEStatus, colorMapperStatus, colorMapperForVoaStatus, removeExtraSpaces, removeAllSpaces, isWordCountZero }

