import styled from 'styled-components'
import { getTime, getDate } from './helper'
import React, { useState, useEffect } from 'react'
import { PreviewPopup } from '../Popup'
import { post, put } from '../../lib/api'
import Axios from 'axios'
import { menuItemObj } from '../PrivatePage'
import {
  Title,
  PreviewToolbar,
  VersionRestore,
  VersionRestoreInfo,
  DraftButtonDiv,
  EditAnotherCTA,
  RestoreButton,
  Body,
} from './styles'

const ActionsContainer = styled.div`
  display: flex;
`

export function Version({disableSaveButton, restoreSuccessCB = () => null, ...props}) {
  const [body, setBody] = useState('')
  const createPreview = () => {
    return {
      __html: `${body}`,
    }
  }
  useEffect(() => {
    const url = props.version.file_url
    if (!url) return
    Axios.get(url).then(response => {
      setBody(response.data)
    })
  }, [])

  const handleRestore = () => {
    const { version_id } = props.version
    put('/content_api/book.restore_version', {
      data: {
        version_id
      }
    }).then(() => {
      restoreSuccessCB()
    })
  }

  const handleNewDraft = () => {
    post('/content_api/book.create_chapter?is_novel=0', {
      data: {
        book_id: props.book_id,
        chapter_title: props.version.chapter_title,
        file_url: props.version.file_url,
        status: "draft",
        word_count: props.version.word_count,
        view: 'dashboard'
      }
    })
    .then((res) => {
      props.history.push(`${menuItemObj.novels}/id/${res.results.book_id}?tab=draft`)
    })
  }

  return (
    <PreviewPopup
      show={props.open}
      close={props.close}
      withCloseMark={true}
      head={
        <PreviewToolbar>
          <div>
            <VersionRestore>{getDate(props.version.create_time)}</VersionRestore>
            <div>
              <VersionRestoreInfo>
                {props.version.word_count} words &middot; {getTime(props.version.create_time)}
              </VersionRestoreInfo>
            </div>
          </div>
          <ActionsContainer>
          {!disableSaveButton && <DraftButtonDiv onClick={handleNewDraft}>
            <EditAnotherCTA>Save as New Draft</EditAnotherCTA>
          </DraftButtonDiv>}
          <RestoreButton onClick={handleRestore}>Restore</RestoreButton>
          </ActionsContainer>
        </PreviewToolbar>
      }
    >
      {/* <Title>{props.version.title}</Title> */}
      <Body dangerouslySetInnerHTML={createPreview()}></Body>
    </PreviewPopup>
  )
}
