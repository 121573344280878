import React from 'react'
import { FloatingInput } from '../../FormComponents'
import { useField } from 'formik';

const FormikFloatingInput = props => {
  const [field] = useField(props)
  const { onChange } = field

  const newHandleChange = (val) => {
    if (props?.validateFunc && !props?.validateFunc(val)) return
    onChange({ target: { name: props.name, value: val } })
  }

  const { placeholder, ...newProps } = props

  return (
    <FloatingInput
      {...{...field, onChange: newHandleChange}}
      {...newProps}
    />
  )
}

export default FormikFloatingInput
