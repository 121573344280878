import React from 'react'
import styled from 'styled-components'
import Submission from './images/Submission'

const Heading = styled.h3`
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.2;
  width: fit-content;
  margin-bottom: 1.6rem;
  -webkit-background-clip: text;
  background-image: linear-gradient(145deg, #b0d5ff, #0068e1);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    padding: 1.5rem;
  }
`

const ImgContainer = styled.div`
  width: 28%;
  svg {
    width: 90%;
  }
  @media(max-width: 600px){
    display: none;
  }
`
const SubMissionInfo = styled.div`
  width: 72%;
  @media(max-width: 600px){
    width: 100%;
  }
`
const SubHeading = styled.h5`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: var(--Text700);
  margin: 1.5rem auto;
`
const ThankYouNote = styled.p`
  font-size: 1rem;
  line-height: 1.2;
  color: #000;
  margin: 1.2rem auto;
`
const Note = styled.h4`
  font-weight: 600;
  font-size: 1rem;
`
export default function SubMissionUI(props) {
  return (
    <Container>
      <ImgContainer>
        <Submission />
      </ImgContainer>
      <SubMissionInfo>
        <Heading>Submission</Heading>
        <SubHeading>
          Sit back and Relax!
          <br /> Your assessment is successfully submitted.
        </SubHeading>
        <ThankYouNote>
          Thank you for your interest. Kindly wait till our team carefully
          reviews your application. You would receive a confirmation once it is
          reviewed.
        </ThankYouNote>
        <Note>Check this space in 3-4 days for an update.</Note>
      </SubMissionInfo>
    </Container>
  )
}
