import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import {
  FloatingInput,
  Input,
  Upload,
  InputToggle,
  ScheduleLater,
} from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import { post } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { Popup } from '../../../../../components/Popup'
import Dustbin from '../../../../../components/Images/Dustbin'
import CrossIcon from '../../../../../components/Images/CrossIcon'
import {
  getTokenAndUid,
  objectCloneWithTopLevelNullRemoved,
} from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import moment from 'moment'
import {
  FileUploadComponent,
  ScriptEditComponent,
} from '../../Unpublished/Edit/addEditEpisode'
import { withRouter } from 'react-router-dom'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Button, Text } from '../../../../../designSystem'
import {
  EditHeader,
  StatusContainer,
  StatusSingle,
  StatusSingleText,
  SelectedStatus,
  StatusesDropdownBox,
  Indicator,
} from '../../Unpublished/Edit/addEditEpisode'
import QCNoteTable from './qcNotes'

const StyledCrossIcon = styled(CrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const TranslatorContainer = styled.p`
  padding: 14px 16px 0 10px;
  color: var(--Text700);
  width: 100%;
  height: 68px;
  line-height: 1.5;
  background-color: #f3f3f5;
  border-radius: 4px;
  font-size: 18px;
`
const LabelTranslator = styled.div`
  margin-top: -5px;
  font-size: 12px;
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 15px;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const InputContainer = styled.div``
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;
`
const DeleteHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const DeleteSpan = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
`
const DeleteBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`
const LeftDiv = styled.div`
  width: 49%;
`
const RightDiv = styled.div`
  width: 49%;
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
`

const AddEditEpisode = ({
  history,
  mode,
  book_id,
  show_id,
  //creator_id,
  created_for,
  successCallback = () => null,
  handleCancel,
  thumbnail,
  data = {},
  isBookOwner,
  isAudioOwner,
  language,
  book_type,
  translator_name,
  chapter_created_info,
  moderation_required,
}) => {
  const [isDeletePopupShown, setDeletePopupShow] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [audioDuration, updateAudioDuration] = useState('')
  const [notesAudioDuration, updateNotesAudioDuration] = useState('')
  const [isStatusSelectshown, toggleStatusSelectShown] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(
    (data['chapter_details'] || {})['status'] || 'pending'
  )
  const chapterStatusRef = useRef()
  const isMobile = window.screen.width < 600
  const handleSelectStatusClick = (status) => {
    setSelectedStatus(status)
    toggleStatusSelectShown(false)
  }
  const { uid } = getTokenAndUid()
  const handleClick = (e) => {
    if (
      chapterStatusRef.current &&
      !chapterStatusRef.current.contains(e.target)
    ) {
      toggleStatusSelectShown(false)
    }
  }

  useEffect(() => {
    if (data?.chapter_details?.media_url) {
      let audio = new Audio()
      audio.src = data?.chapter_details?.media_url
      audio.addEventListener(
        'loadedmetadata',
        function () {
          updateAudioDuration(Math.ceil(audio.duration))
        },
        false
      )
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const handleDelete = () => {
    const payload = {}
    if (data['chapter_details']) {
      const { book_id, chapter_id } = data['chapter_details']
      payload.chapter_details = {
        book_id,
        chapter_id,
        is_disabled: 1,
        status: 'pending',
      }
      payload['book_id'] = book_id
    }
    if (data['story_details']) {
      const { story_id } = data['story_details']
      payload.story_details = {
        story_id,
        show_id: show_id || data.show_id,
        is_disabled: 1,
        status: 'pending',
        // created_by: data.story_details.created_by || creator_id,
        created_by:
          data?.story_details?.created_by ||
          data?.chapter_details?.created_by ||
          uid,
      }
      payload['show_id'] = show_id || data.show_id
    }
    payload['view'] = 'dashboard'
    post('/content_api/book.delete_episode', {
      data: payload,
    })
      .then(() => {
        setDeletePopupShow(false)
        successCallback()
      })
      .catch((error) => {
        setDeletePopupShow(false)
        throw error
      })
  }

  const initialTitle =
    (data['story_details'] || {})['story_title'] ||
    (data['chapter_details'] || {})['chapter_title'] ||
    ''
  const initialDescription =
    (data['story_details'] || {})['story_desc'] ||
    (data['chapter_details'] || {})['description'] ||
    ''

  let editorUrl = `${menuItemObj.audiobooks}/edit-episode-chapter/${
    data['book_id'] || book_id
  }?book_type=${book_type}&language=${language}&moderation_required=${moderation_required}&show_id=${
    show_id || data['show_id']
  }`
  if (mode === 'edit') {
    editorUrl =
      editorUrl + `&chapter_id=${data['chapter_details']['chapter_id']}`
  } else {
    editorUrl = editorUrl + `&created_by=`
  }

  return (
    <Box
      boxShadow="0 8px 16px 0 rgba(73, 83, 89, 0.16)"
      bg="surface.foreground"
      mb={8}
      borderRadius={4}
    >
      <Box variant="flex" p={20}>
        <StyledCrossIcon onClick={handleCancel} />
      </Box>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      <Formik
        initialValues={{
          story_title: initialTitle,
          file_url: (data['chapter_details'] || {})['file_url'] || '',
          media_url:
            (data['story_details'] || data['chapter_details'] || {})[
              'media_url'
            ] || '',
          comment: (data['creator_note'] || {})['comment'] || '',
          story_desc: initialDescription,
          schedule_time: (data['story_details'] || {})['schedule_time'] || '',
          audio_note: (data['ad'] || {})['media_url'] || '',
        }}
        validationSchema={Yup.object({
          story_title: Yup.string().required('Required'),
          // comment: (hasCreatorPermissions && isAudioOwner) ? Yup.string().required('Required') : Yup.string()
        })}
        isInitialValid={mode === 'edit'}
        onSubmit={(values, { setSubmitting }) => {
          const imageUrl = thumbnail || data.thumbnail
          let api = '/content_api/book.create_episode'
          const {
            story_title,
            file_url,
            media_url,
            comment,
            story_desc,
            schedule_time,
            audio_note,
          } = values
          if (!file_url && !media_url) {
            setSubmitting(false)
            return
          }
          let chapter_details = { ...(data['chapter_details'] || {}) }
          chapter_details['content_unchanged'] = 1
          chapter_details['chapter_title'] = story_title
          chapter_details['description'] = story_desc
          chapter_details['qc_details'] =
            data?.chapter_details?.qc_details || []
          if (mode !== 'edit') {
            chapter_details['book_id'] = book_id
          }
          if (file_url) {
            chapter_details.file_url = file_url
          }
          let story_details = {
            show_id: show_id || data.show_id,
            story_id: data.story_id || null,
            story_title,
            story_desc,
            s3_unique_key: media_url ? media_url.split('/').pop() : null,
            audio_status:
              data?.story_details?.audio_status ||
              data?.chapter_details?.audio_status ||
              'pending',
            image_s3_unique_key: (thumbnail || data.thumbnail).split('/').pop(),
            schedule_time: schedule_time
              ? moment(schedule_time).format('YYYY-MM-DD HH:mm:ss')
              : null,
          }

          if (audioDuration) {
            story_details.duration = media_url ? Math.ceil(audioDuration) : null
          }

          // if (isAdminAccess) {
          story_details.created_by =
            // (data.story_details || {}).created_by || creator_id
            uid ||
            (data.story_details || data?.chapter_details || {}).created_by

          // }

          // remove null values
          story_details = objectCloneWithTopLevelNullRemoved(story_details)

          if (!media_url) {
            // chapter_details.status = 'pending'
          } else {
            story_details.status = 'approved'
          }
          if (selectedStatus === 'approved') {
            chapter_details.status = 'approved'
          }
          let chapterAndStory = {
            chapter_details,
            story_details,
            book_id: book_id || data.book_id,
            show_id: show_id || data.show_id,
            view: 'dashboard',
          }
          if (data.story_id) {
            api = '/content_api/book.update_episode'
            const creator_note = {
              comment_creator_uid: uid,
              story_id: data.story_id,
              comment,
              is_creator_note: true,
              entity_type: 'story',
            }
            if ((data['creator_note'] || {})['comment_id']) {
              creator_note.comment_id = (data['creator_note'] || {})[
                'comment_id'
              ]
              if ((data['creator_note'] || {})['comment'] !== comment) {
                chapterAndStory.creator_note = creator_note
              }
            } else {
              if (comment) {
                chapterAndStory.creator_note = creator_note
              }
            }
          }
          if (mode === 'edit') {
            if (!story_details['story_id'] && !media_url) {
              delete chapterAndStory.story_details
              // delete chapterAndStory.show_id
            }
            if (!chapter_details['chapter_id'] && !file_url) {
              delete chapterAndStory.chapter_details
              // delete chapterAndStory.book_id
            }
          } else {
            if (
              chapterAndStory.story_details.s3_unique_key &&
              !chapterAndStory.story_details.duration
            ) {
              setSubmitting(false)
              return
            }
            if (!media_url) {
              delete chapterAndStory.story_details
              // delete chapterAndStory.show_id
            }
            if (!file_url) {
              delete chapterAndStory.chapter_details
              story_details['audio_flow_eligible'] = false
              // delete chapterAndStory.book_id
            }
          }
          let audioRepetionWarning
          post(api, { data: chapterAndStory })
            .then((response) => {
              if (response?.warning_message) {
                audioRepetionWarning = {
                  isWarning: true,
                  msg: response.warning_message,
                }
              }
              if (mode !== 'edit' && comment) {
                const {
                  story_details: {
                    story_info: { story_id },
                  },
                } = response.result
                if (story_id) {
                  api = '/content_api/book.update_episode'
                  const commentFormData = {
                    creator_note: {
                      comment_creator_uid: uid,
                      story_id,
                      comment,
                      is_creator_note: true,
                      entity_type: 'story',
                    },
                    view: chapterAndStory.view,
                    show_id: chapterAndStory.show_id,
                  }
                  post(api, { data: commentFormData })
                }
              }
              if (audio_note) {
                let audioNotePayload = {
                  ad_title: "Creator's Note",
                  media_url: audio_note,
                  image_url: imageUrl || null,
                  ad_name: 'creator_note',
                  preferred_play_time: 1,
                  ad_type: 'audio',
                  skipable: true,
                  skip_duration: 5,
                  show_cta: false,
                  pref_entity_type: 'story',
                  props: {},
                }
                if (notesAudioDuration) {
                  audioNotePayload.duration = Math.ceil(notesAudioDuration)
                }
                if (!data['story_id']) {
                  const {
                    story_details: {
                      story_info: { story_id },
                    },
                  } = response.result
                  audioNotePayload['pref_entity_id'] = story_id
                  audioNotePayload['mapped_entity_id'] = story_id
                } else {
                  audioNotePayload['pref_entity_id'] = data['story_id']
                  audioNotePayload['mapped_entity_id'] = data['story_id']
                  if (data['ad']['ad_id']) {
                    audioNotePayload = data['ad']
                    audioNotePayload.media_url = audio_note
                  }
                  if (notesAudioDuration) {
                    audioNotePayload.duration = Math.ceil(notesAudioDuration)
                  }
                }

                post('/content_api/ads.create_update_ad', {
                  data: audioNotePayload,
                })
              }
            })
            .then(() => {
              handleCancel()
              setSubmitting(false)
              successCallback(
                {
                  ...chapterAndStory,
                  audioWarningMessage: audioRepetionWarning,
                },
                values?.media_url
              )
            })
            .catch((error) => {
              setSubmitting(false)
              throw error
            })

          //             // if scipt is uploaded from file uploader and not from editor page
          //             // (in the case of oversees writers), we need to
          //             // call book.version api. otherwise file_url woudnt get saved
          //             // this flow change has been made after oversees writers support release
          //             if (mode === 'edit' && file_url && data['chapter_details'] && (file_url !== data['chapter_details']['file_url'])) {
          //               const dataForVersion = {
          //                 chapter_id: data['chapter_details']['chapter_id'],
          //                 word_count: 0,
          //                 file_url: file_url,
          //                 chapter_title: story_title,
          //                 book_id,
          //                 view: 'dashboard'
          //               }
          //               post('/content_api/book.version', {
          //                 data: dataForVersion
          //               })
          //             }
        }}
      >
        {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
          <Form>
            <Popup
              show={isDeletePopupShown}
              close={() => setDeletePopupShow(false)}
              renderActions={
                <>
                  <button
                    className="action-button"
                    type="button"
                    onClick={() => setDeletePopupShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="action-button danger"
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </>
              }
            >
              <Box
                variant="flex"
                alignItems="center"
                mb={8}
                justifyContent="flex-start"
              >
                <Dustbin />
                <Text variant="titleSecondary" ml={8}>
                  Delete this episode?
                </Text>
              </Box>
              <Text variant="caption" lineHeight={1.8} fontWeight={500}>
                Are you sure you want to permanently delete this episode, once
                an episode is deleted, you can’t undo it.
              </Text>
            </Popup>
            <Box p={[16, 32]}>
              {/* <Title>Episode {data['natural_sequence_number'] || 1}</Title> */}
              <Box variant="flex" mb={15}>
                <Text variant="titleSecondary">
                  Episode {data['natural_sequence_number'] || 1}
                </Text>
                {/* <Title>Episode {episodeNumber || data['sn'] || data['seq_number'] || 1}</Title> */}
              </Box>
              <Box variant="hr" />
              <Box variant="flex" flexDirection="column">
                <Box width="100%">
                  <Box>
                    <FloatingInput
                      label="Title"
                      name="story_title"
                      infoTitle="Give a title to this episode"
                      disabled={mode !== 'add' && !!initialTitle}
                      hasError={submitCount > 0 && errors['story_title']}
                      type="text"
                      placeholder="Write something that describes this episode"
                    />
                  </Box>
                  <Box mt={16}>
                    <FloatingInput
                      asTextArea
                      disabled={mode !== 'add' && !!initialDescription}
                      rowCount={4}
                      infoTitle="Add the description of this episode"
                      label="Description"
                      name="story_desc"
                      placeholder="Description of this episode"
                    />
                  </Box>

                  <Box mt={16}>
                    <FloatingInput
                      asTextArea
                      rowCount={4}
                      disabled={!isAudioOwner}
                      infoTitle="Add creators note for this episode"
                      label="Creator’s Note"
                      name="comment"
                      placeholder="Creator's Note of this episode"
                    />
                  </Box>
                  {!!chapter_created_info?.author_name && (
                    <Box my={16}>
                      <TranslatorContainer>
                        <LabelTranslator>
                          Selected Author/Writer
                        </LabelTranslator>
                        <Box>{chapter_created_info?.author_name}</Box>
                      </TranslatorContainer>
                    </Box>
                  )}
                  {!!translator_name && (
                    <Box my={16}>
                      <TranslatorContainer>
                        <LabelTranslator>Selected Translator</LabelTranslator>
                        <Box>{translator_name}</Box>
                      </TranslatorContainer>
                    </Box>
                  )}

                  {data.natural_sequence_number === 1 && isAudioOwner && (
                    <InputToggle
                      infoTitle="Mark this episode as intro"
                      label="Mark this Episode as Intro?"
                    />
                  )}
                  {isAudioOwner && (
                    <ScheduleLater
                      infoTitle="Schedule Later"
                      label="Schedule it for later?"
                      calendarTitle="Publish Date"
                      name="schedule_time"
                      id={show_id || data.show_id}
                      disableToggleBack
                    />
                  )}
                </Box>
                <Box width="100%">
                  {(isBookOwner || (data.chapter_details || {}).file_url) &&
                    language !== 'english' && (
                      <ScriptEditComponent
                        label="Script Doc File"
                        name="file_url"
                        title={values.story_title}
                        resourceUrl={values.file_url}
                        readOnly={!isBookOwner}
                        showReadOption
                        handleOpenEditor={() =>
                          history.push(
                            `${editorUrl}&title=${values.story_title}`
                          )
                        }
                        handleOpenFreshEditor={() =>
                          history.push(
                            `${editorUrl}&title=${values.story_title}&create_new=true`
                          )
                        }
                      />
                    )}

                  {(isBookOwner || (data.chapter_details || {}).file_url) &&
                    language === 'english' && (
                      <Upload
                        label="Chapter Document"
                        name="file_url"
                        hasError={submitCount > 0 && errors.file_url}
                        hideInputOnValue
                        showProgress
                        readOnly={!isBookOwner || selectedStatus === 'approved'}
                        placeholder="Upload Script Document"
                        renderComp={FileUploadComponent}
                        fileTypeTags="file"
                        showReadOption
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                        extraParams={{
                          file_dir: 'episodedocumentupload',
                          title: values.story_title || 'episodedocumentupload',
                        }}
                        uploadedFileMeta={{
                          created_time: `${data.chapter_details?.create_time}`,
                        }}
                        cloud_dir_file="episodedocumentupload"
                      />
                    )}

                  {(isAudioOwner || (data.story_details || {}).media_url) && (
                    <Upload
                      label="Audiobook File"
                      name="media_url"
                      maxFileSize={614400000}
                      readOnly={!isAudioOwner}
                      isDownloadOptionRemoved={data?.status === 'approved'}
                      hideInputOnValue
                      showProgress
                      audioStatus={
                        data?.story_details?.audio_status ||
                        data?.chapter_details?.audio_status ||
                        null
                      }
                      placeholder="Drop your audio file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      fileTypeTags="media"
                      accept="audio/mpeg, audio/mp3, audio/ogg, audio/wav"
                      handleDurationUpdate={(duration) =>
                        updateAudioDuration(duration)
                      }
                      // this is the callback to store audio duration in state values.
                      // this is not being stored in formik state
                      extraParams={{
                        title: values.story_title || 'episode-audio',
                        ...(data.story_id && { story_id: data.story_id }),
                      }}
                      uploadedFileMeta={{ size: '' }}
                    />
                  )}
                  {values.media_url && data?.status !== 'approved' && (
                    <Box my={8} width="100%">
                      <audio
                        className="audio-file"
                        controls
                        src={values.media_url}
                      />
                    </Box>
                  )}
                  {(data.story_details || data.chapter_details || {})
                    .media_url &&
                    ((data.chapter_details || {}).qc_details || [])?.length >
                      0 && (
                      <QCNoteTable
                        qcNotes={(data.chapter_details || {}).qc_details || []}
                      />
                    )}
                  {(isAudioOwner || (data.ad || {}).media_url) && (
                    <Upload
                      label="Creator Note Audio"
                      bottomInfoLabel="Duration of Creator’s Note must be within 20 to 120 seconds"
                      name="audio_note"
                      hideInputOnValue
                      readOnly={!isAudioOwner}
                      showProgress
                      placeholder="Drop your audio file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      fileTypeTags="media"
                      audioLengthValidator={(duration) =>
                        duration > 19 && duration < 120
                      }
                      audioDurationErrorMsg="Duration of Creator’s Note must be within 20 to 120 seconds"
                      handleDurationUpdate={(duration) =>
                        updateNotesAudioDuration(duration)
                      }
                      // this is the callback to store audio duration in state values.
                      // this is not being stored in formik state
                      accept="audio/mpeg, audio/mp3"
                      extraParams={{
                        title: 'audio-note',
                      }}
                      uploadedFileMeta={{ size: '' }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box variant="flex" width="100%" py={16} px={24}>
              {/* {mode === 'edit' && <DeleteButton type="button">Delete</DeleteButton>} */}
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              {!isSubmitting && (
                <SaveButton type="submit" isValid={isValid}>
                  Save
                </SaveButton>
              )}
              {isSubmitting && (
                <SaveButton type="button" isValid={isValid}>
                  <Loader invertColor inline size="sm" />
                </SaveButton>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default withRouter(AddEditEpisode)
