import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import ClampLines from 'react-clamp-lines'
import { Link } from 'react-router-dom'
import {
  useDidUpdate,
  useInfiniteScroll,
  useCreatorProfile,
  useGenericInfiniteScroller
} from '../../../../../lib/customHooks'
import SubHeader from '../../../../../components/WithSubHeaderBG'
import { get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { getMockData } from './mock'
import AddIcon from '../../../../../components/Images/AddIcon'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import CreatorIcon from '../../../../../components/Images/CreatorIcon'
import { getTopicNameFromId, getTokenAndUid, removeHtmlTags } from '../../../../../lib/utils'
import EpisodeListing from './episodeList'
import AddNewEpisode from './addEditEpisode'
import GenericError from '../../../../../components/GenericError'
import CreatorInfo from '../../../../../components/CreatorInfo'
import { isAdminAccess } from '../../../../../config'
import Vellip from '../../../../../components/Images/Vellip'
import PencilIcon from '../../../../../components/Images/PencilIcon'
import AuditionIcon from '../../../../../components/Images/AuditionIcon'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button, Image } from '../../../../../designSystem'
import { AddEpisodeButton, TabHolder, Tab, UnderLine, HR} from '../../Published/ShowDetails'
import AudioWarningPopup from '../../Published/ShowDetails/audioWarningPopup'

const BookSummary = styled.div`
  padding: 24px;
  background-color: var(--Dove);
  margin-bottom: 32px;
  position: relative;
`
const BookImageAndDesc = styled.div`
  display: flex;
`
const BookImageDiv = styled.div`
  width: 144px;
  height: 144px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--Grey900);
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`
const BookImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const BookDetails = styled.div``


const BookTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: ${({ theme }) => theme.colors.text.text500};
  margin-bottom: 12px;
  margin-top: 8px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    line-height: 1.8;
    color: ${({ theme }) => theme.colors.text.text500};
    outline: none;
  }
`
const ArtistsContainer = styled.div`
  display: flex;
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
  @media(max-width:600px){
    margin-right: 0;
  }
`
const ProfileImageDiv = styled.div`
  position: relative;
  margin-right: 8px;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const StyledCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`
const EpisodesHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`
const EntryCount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: var(--Text700);
`
const VellipIcon = styled(Vellip)`
  display: block;
`

const VellipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #ebecf1;
  }
`
const BookOptionsBox = styled.div`
  display: ${({ show }) => show ? 'block' : 'none'};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  width: 224px;
`
const EditBookDiv = styled.div`
  background-color: #f0f1f5;
  cursor: pointer
`
const EditButton = styled(Link)`
  border: none;
  outline: none;
  background: none;
  padding: 16px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`
const EditBookSpan = styled.span`
  margin-left: 8px;
`

const SetUpAuditionDiv = styled.div`
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  padding: 16px 24px;
`
const SetupAuditionSpan = styled.span`
  margin-left: 8px;
`
const GeneratedID = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text300);
  margin-bottom: 8px;
`

export const allTabs = {
  episodeListing: "All Episodes",
  toBeRecorded: 'To Be Recorded',
}

const HeaderView = ({
  episodes_count,
  selectedTab,
  setSelectedTab,
  handleAddNewEpisode,
  all_audios_uploaded,
  pending_chapters_count = 0
}) => {
  return (
    <>
      <TabHolder>
        {!all_audios_uploaded && <Tab active={selectedTab === allTabs.toBeRecorded} onClick={() => setSelectedTab(allTabs.toBeRecorded)}>
          <span>{allTabs.toBeRecorded} ({pending_chapters_count})</span>
          <UnderLine active={selectedTab === allTabs.toBeRecorded} />
        </Tab>}
        <Tab active={selectedTab === allTabs.episodeListing} onClick={() => setSelectedTab(allTabs.episodeListing)}>
          <span>{allTabs.episodeListing} ({episodes_count - pending_chapters_count})</span>
          <UnderLine active={selectedTab === allTabs.episodeListing} />
        </Tab>
        <AddEpisodeButton onClick={handleAddNewEpisode}>
          <Button variant="outlinePrimary">New Episode</Button>
        </AddEpisodeButton>
      </TabHolder>
      <HR />
    </>
  );
}

const ShowDetails = (props) => {
  const {
    location: { search },
    history
  } = props
  const qParams = queryString.parse(search)
  const { id } = qParams
  const [auditionbook, setBookDetails] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isAddEntryBoxShown, setAddEntryBoxDisplay] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [addNewError, toggleAddNewError] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  //const isFetching = useInfiniteScroll(fetchMoreData)
  const [audioRepetionWarning, setAudioRepitionWarning] = useState(null)
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } =
    useCreatorProfile()
  const [isBookOptionsShown, toggleShowBookOptions] = useState(false)
  const bookOptionsRef = useRef()
  const isMobile = window.screen.width < 600
  const [selectedTab, setSelectedTab] = useState(allTabs.episodeListing);

  useEffect(() => {
    fetchData()
  }, [id, selectedTab])

  const handleClick = (e) => {
    if (bookOptionsRef.current && !bookOptionsRef.current.contains(e.target)) {
      toggleShowBookOptions(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  useDidUpdate(() => {
    setLoading(false)
  }, [auditionbook])

  const fetchData = () => {
    setLoading(true)
    setNextUrl(null)
    const bookDetailsApi = '/content_api/book.show_episodes'
    get(bookDetailsApi, { params: { show_id: id, view: 'dashboard', paginate_chapters: selectedTab === allTabs.toBeRecorded ? "true" : null } })
      .then((response) => {
        const { result } = response
        const { next_url } = result
        setBookDetails(result)
        setNextUrl(next_url)
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }

  function fetchMoreData (setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result } = response
        const { next_url, episodes } = result
        setBookDetails(prevState => ({...result, episodes: prevState.episodes.concat(episodes)}))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }
  //const isFetching = useInfiniteScroll(fetchMoreData)

  const isFetchingData = useGenericInfiniteScroller(fetchMoreData, { nextUrl })
  let isFetching, lastNodeEleRef
  if (
    typeof isFetchingData === 'object' &&
    !Array.isArray(isFetchingData) &&
    isFetchingData !== null
  ) {
    //  using useLatest Infinte scroll
    isFetching = isFetchingData?.isFetching
    lastNodeEleRef = isFetchingData?.lastNodeEleRef
  } else {
    isFetching = isFetchingData
  }

  const successAddEntryCB = (values) => {
    setAddEntryBoxDisplay(false)
    setAudioRepitionWarning(values?.audioWarningMessage)
    fetchData()
  }

  const successEditEpisodeCB = (values) => {
    setAudioRepitionWarning(values?.audioWarningMessage)
    fetchData()
  }
  const handleDiscard = () => {
    setAddEntryBoxDisplay(false)
  }
  const handleAddNewEpisode = () => {
    setAddEntryBoxDisplay(true)
  }

  const {
    image_url,
    show_id,
    show_title,
    ops_alias,
    category_obj: { topic_name } = {},
    topic_ids,
    show_desc,
    show_desc_html,
    episodes_count,
    episodes,
    book_id,
    moderation_required,
    all_audios_uploaded,
    pending_chapters_count,
    author_info: { author_id, author_name, image_url: author_image } = {},
    creator_info: { creator_id, creator_name, image_url: artist_image } = {},
    translator_info: { translator_name, image_url: translator_image, translator_id } = {},
    production_house_info: { production_house_name, image_url: production_house_image, production_house_id } = {}
  } = auditionbook || {}
  if (isLoading) {
    return (
      <div style={{ marginTop: '200px' }}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      <GenericError
        show={addNewError}
        title={'Cannot Add New Episode'}
        message={'Please make sure all the existing episodes contain audio files before adding new episode'}
        onClose={() => {
          toggleAddNewError(false)
        }}
      />
      {auditionbook && (
        <SubHeader title="Audiobook Details" showBackButton>
          <Box p={[16, 24]} mb={8} width={['100vw', '100%']} position="relative" bg="surface.foreground">
            <Box variant="flex" justifyContent="flex-start" flexDirection={["column","row"]}>
              {!isMobile && <Image width={[60,144]} height={[60,144]} src={image_url} mr={24}/>}
              {isMobile && <Box variant="flex" justifyContent="flex-start">
                <Image width={[60,144]} height={[60,144]} src={image_url} mr={8}/>
                <Text variant="title">
                  {show_title}
                </Text>
              </Box>}
              <Box mt={[8,0]}>
                {!isMobile && <Text variant="title">
                  {show_title}
                </Text>}
                <Text variant="subtext" mt={[0,4]}>
                  {ops_alias} {ops_alias && topic_name && <strong>&middot;</strong>} {topic_name}
                </Text>
                {(show_desc || show_desc_html) && <BookDescription>
                  <ClampLines
                    lines={2}
                    text={show_desc || removeHtmlTags(show_desc_html)}
                    id="recording-show-description"
                  />
                </BookDescription>}
                <ArtistsContainer>
                  {author_id && <ArtistDetails onClick={() =>  onUserClick(author_id)}>
                    {!isMobile && author_image && <ProfileImageDiv>
                      <ProfileImage src={author_image} />
                      <StyledAuthorIcon />
                    </ProfileImageDiv>}
                    {author_name && <ArtistName>{author_name}</ArtistName>}
                  </ArtistDetails>}
                  {author_name && creator_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                  {creator_name && <ArtistDetails onClick={() => onUserClick(creator_id)}>
                    {!isMobile && artist_image && <ProfileImageDiv>
                      <ProfileImage src={artist_image} />
                      <StyledCreatorIcon />
                    </ProfileImageDiv>}
                    <ArtistName>{creator_name}</ArtistName>
                  </ArtistDetails>}
                  {author_name && creator_name && translator_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                  {translator_name && <ArtistDetails onClick={() => onUserClick(translator_id)}>
                    {!isMobile && translator_image && <ProfileImageDiv>
                      <ProfileImage src={translator_image} />
                      <StyledAuthorIcon />
                    </ProfileImageDiv>}
                    {translator_name && <ArtistName>{translator_name}</ArtistName>}
                  </ArtistDetails>}
                  {author_name && creator_name && translator_name && production_house_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                  {production_house_name && <ArtistDetails onClick={() => onUserClick(production_house_id)}>
                    {!isMobile && production_house_image && <ProfileImageDiv>
                      <ProfileImage src={production_house_image} />
                      <StyledCreatorIcon />
                    </ProfileImageDiv>}
                    {production_house_name && <ArtistName>{production_house_name}</ArtistName>}
                  </ArtistDetails>}
                </ArtistsContainer>
              </Box>
            </Box>
            <AudioWarningPopup
              audioRepetionWarning={audioRepetionWarning}
              setAudioRepitionWarning={setAudioRepitionWarning}
            />
            {/* {isAdminAccess && <EditButton to={`/shows/audiobooks/edit-show-details/${show_id}?type=recording`}>
              Edit
            </EditButton>} */}
            {isAdminAccess && <VellipContainer ref={bookOptionsRef}>
              <VellipIcon onClick={() => toggleShowBookOptions(!isBookOptionsShown)}/>
              <BookOptionsBox show={isBookOptionsShown}>
                <EditBookDiv>
                  <EditButton to={`${menuItemObj.audiobooks}/edit-show-details/${show_id}`} >
                    <PencilIcon/>
                    <EditBookSpan>
                      Edit Audiobook
                    </EditBookSpan>
                  </EditButton>
                </EditBookDiv>
                <SetUpAuditionDiv onClick={() => history.push(`${menuItemObj.audiobooks}/view-auditions/${book_id}`)}>
                  <AuditionIcon/>
                  <SetupAuditionSpan>
                    View Auditions
                  </SetupAuditionSpan>
                </SetUpAuditionDiv>
              </BookOptionsBox>
            </VellipContainer>}
          </Box>

          {/* <Box variant="flexCard" px={[16, 24]} py={[16, 16]} borderTopLeftRadius="4px" borderTopRightRadius="4px">
            <Text variant="heading">
              {episodes_count}
              {episodes_count > 1 ? ' Episodes' : ' Episode'}
            </Text>
            <Button variant="outlinePrimary" onClick={handleAddNewEpisode}>
              New Episode
            </Button>
          </Box> */}
          <HeaderView
            selectedTab={selectedTab}
            all_audios_uploaded={all_audios_uploaded}
            setSelectedTab={setSelectedTab}
            handleAddNewEpisode={handleAddNewEpisode}
            episodes_count={episodes_count}
            pending_chapters_count={pending_chapters_count}
          />
          <Box variant="hr"></Box>
          {isAddEntryBoxShown && (
            <AddNewEpisode
              book_id={book_id}
              show_id={show_id}
              data={{ natural_sequence_number: episodes_count + 1 }}
              thumbnail={image_url}
              successCallback={successAddEntryCB}
              handleCancel={handleDiscard}
              creator_id={creator_id}
              mode="add"
              moderation_required={moderation_required}
            />
          )}
          <EpisodeListing
            episodes={episodes}
            thumbnail={image_url}
            book_id={book_id}
            show_id={show_id}
            creator_id={creator_id}
            successCallback={successEditEpisodeCB}
            moderation_required={moderation_required}
            lastNodeEleRef={lastNodeEleRef}
          />
          {isFetching && <Loader />}
        </SubHeader>
      )}
    </>
  )
}

export default ShowDetails
