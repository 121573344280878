import React from 'react'
import styled from 'styled-components'
import CardGraphic from './WBGirl'
import { novelNotices, rewardNotices } from '../constants'
import { Subtext } from './bookCard'
import { ThumbnailDiv, DetailsDiv, CtaBox, BookTitle } from './monthlyBookCard'
import { Image, Text } from '../../../designSystem';
import { withRouter } from "react-router-dom";
const congratulationImage = '/images/congratulationImage.svg'
const Card = styled.div`
  margin-bottom: 8px;
  background-color: var(--Dove);
  display: flex;
  padding: 24px;
`
const Details = styled.div`
  padding: 24px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Description = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 24px;
`
const ActionButton = styled.div`
  border-radius: 4px;
  border: solid 2px var(--LD10);
  cursor: pointer;
  padding: 14px;
  width: 180px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(255,255,255,0.98);
  background-color: var(--Azure500);
  
`
const CardImage=styled.img`
width: 80px;
margin-right: 20px;
`
const ArtContainer = styled.div``

const ProfessionalSignupCard = ({history}) => {
  return (
    <Card>
      {/* <Details>
        <Title>{entityForConstants[action_type].title.replace(toReplace, withReplace)}</Title>
        <Description>{entityForConstants[action_type].message.replace(toReplace, withReplace)}</Description>
        {!no_button && <ActionButton onClick={handleClick}>{button_text}</ActionButton>}
      </Details> */}
      {/* <ArtContainer>
        <CardGraphic />
      </ArtContainer> */}
        {/* <ThumbnailDiv>
          {program.book_details.image_url && <img src={program.book_details.image_url} alt="novel thumbnail" />}
        </ThumbnailDiv> */}
        <CardImage src={congratulationImage} />
        
        <DetailsDiv>
          <Text variant="titleMedium" mb={8}>Your Application is submitted</Text>
          <Text variant="bodyLarge" mb={8}>
          Our team will review your application and will get in touch with you, in the mean time you can complete your profile to increase your chances of approval.
          </Text>
          {/* <Text mb={24}>{entityForConstants[action_type].message.replace(toReplace, withReplace)}</Text> */}
          <CtaBox>
            <ActionButton onClick={()=>history.push('/edit-writer-profile')} >
            Complete your Profile
            </ActionButton>
          </CtaBox>
        </DetailsDiv>
    </Card>
  )
}
export default withRouter(ProfessionalSignupCard)
