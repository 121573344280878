import React from 'react'

const LoadReply = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#fff"
      d="M4 2c0-.368-.299-.667-.667-.667-.368 0-.666.299-.666.667v6c0 1.105.895 2 2 2h6.356l-1.5 1.534c-.257.263-.252.685.01.943.264.257.686.252.944-.01l2.25-2.303c.474-.484.509-1.248.08-1.774l-2.29-2.811c-.233-.286-.653-.328-.938-.096-.286.233-.328.653-.096.938l1.83 2.246H4.667C4.298 8.667 4 8.368 4 8V2z"
    />
  </svg>
)

export default LoadReply
