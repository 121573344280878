import React from 'react'

const Illustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="360" height="180" fill="none" viewBox="0 0 320 160">
    <mask id="dghgmrcfsa" width="320" height="160" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#E9E9E9" d="M0 0H320V160H0z"/>
    </mask>
    <g mask="url(#dghgmrcfsa)">
        <path fill="#263238" d="M295.53 119.469l-15.247 91.054h-6.991l14.558-90.165 7.68-.889z"/>
        <path fill="#fff" d="M295.53 119.469l-15.247 91.054h-6.991l14.558-90.165 7.68-.889z" opacity=".4"/>
        <path fill="#263238" d="M271.914 120.922l-11.751 91.567-6.978.263 11.087-90.652 7.642-1.178z"/>
        <path fill="#fff" d="M271.914 120.922l-11.751 91.567-6.978.263 11.087-90.652 7.642-1.178z" opacity=".4"/>
        <path fill="#263238" d="M247.935 117.715l-7.316 92.031-6.966.614 6.715-91.092 7.567-1.553z"/>
        <path fill="#fff" d="M247.935 117.715l-7.316 92.031-6.966.614 6.715-91.092 7.567-1.553z" opacity=".4"/>
        <path fill="#263238" d="M306.918 98.823l-4.335 25.619c-.463 2.731-2.831 4.736-5.6 4.736h-59.859c-3.382 0-6.013-2.932-5.65-6.302l2.781-25.62c.313-2.881 2.744-5.073 5.65-5.073h61.4c3.533.012 6.202 3.17 5.613 6.64z"/>
        <path fill="#fff" d="M306.918 98.823l-4.335 25.619c-.463 2.731-2.831 4.736-5.6 4.736h-59.859c-3.382 0-6.013-2.932-5.65-6.302l2.781-25.62c.313-2.881 2.744-5.073 5.65-5.073h61.4c3.533.012 6.202 3.17 5.613 6.64z" opacity=".5"/>
        <path fill="#E4897B" d="M184.532 114.52l-9.171-9.421s-15.121-3.483-17.1-4.46c-1.98-.99-4.46 4.46-4.962 6.941-.501 2.48 10.411 4.961 12.14 4.961 1.729 0 3.458-1.115 3.458-1.115l7.191 7.567 8.444-4.473z"/>
        <path fill="#000" d="M184.532 114.52l-9.171-9.421s-15.121-3.483-17.1-4.46c-1.98-.99-4.46 4.46-4.962 6.941-.501 2.48 10.411 4.961 12.14 4.961 1.729 0 3.458-1.115 3.458-1.115l7.191 7.567 8.444-4.473z" opacity=".2"/>
        <path fill="#263238" d="M205.378 42.033c2.982 4.322 2.193 12.027-1.528 14.006-3.721 1.992 2.029 6.452 2.13 9.91.1 3.47-5.6 7.429-6.102 12.39-.501 4.961-3.47 14.62 18.216 17.351s40.691-1.49 44.45-10.16c3.758-8.67-10.06-11.701-10.837-15.272-.777-3.558 3.683-3.858 3.433-9.158-.251-5.299-9.246-4.973-7.204-10.749 3.157-8.982-1.278-17.501-1.278-17.501l-41.28 9.183z"/>
        <path fill="#068AE2" d="M127.091 258.342l11.425 4.247s30.481-76.208 36.745-76.471c6.264-.263 70.045 29.052 86.03 12.791 15.986-16.249-7.128-55.424-7.128-55.424s-38.449-5.487-41.343 3.195c-.563 1.678-1.177 4.973-1.628 7.792-.439 2.656-.74 4.886-.74 4.886s-.012 0-.025-.013c-1.19-.3-39.789-10.26-54.998.013-15.447 10.448-28.338 98.984-28.338 98.984z"/>
        <path fill="#fff" d="M127.091 258.342l11.425 4.247s30.481-76.208 36.745-76.471c6.264-.263 70.045 29.052 86.03 12.791 15.986-16.249-7.128-55.424-7.128-55.424s-38.449-5.487-41.343 3.195c-.563 1.678-1.177 4.973-1.628 7.792-.439 2.656-.74 4.886-.74 4.886s-.012 0-.025-.013c-1.19-.3-39.789-10.26-54.998.013-15.447 10.448-28.338 98.984-28.338 98.984z" opacity=".6"/>
        <path fill="#068AE2" d="M206.707 82.473c-9.296 4.047-16.224 32.999-16.224 32.999l-8.431-7.404-9.158 8.669s7.767 19.331 18.503 20.759c10.737 1.429 25.37-17.777 28.214-29.416 2.831-11.65-12.904-25.607-12.904-25.607z"/>
        <path fill="#000" d="M206.707 82.473c-9.296 4.047-16.224 32.999-16.224 32.999l-8.431-7.404-9.158 8.669s7.767 19.331 18.503 20.759c10.737 1.429 25.37-17.777 28.214-29.416 2.831-11.65-12.904-25.607-12.904-25.607z" opacity=".1"/>
        <path fill="#263238" d="M253.235 137.497l.927 5.988-41.342 3.182-1.103-7.066 41.518-2.104z"/>
        <path fill="#000" d="M253.235 137.497l.927 5.988-41.342 3.182-1.103-7.066 41.518-2.104z" opacity=".2"/>
        <path fill="#263238" d="M240.186 147.521l2.043-.21-1.07-10.406-2.044.21 1.071 10.406z"/>
        <path fill="#fff" d="M221.857 146.456l9.051-.581-.441-6.863-9.051.581.441 6.863z"/>
        <path fill="#263238" d="M214.826 148.631l2.044-.21-1.07-10.406-2.044.21 1.07 10.406z"/>
        <path fill="#000" d="M210.453 159.358l22.487 4.685s-12.427-6.865-21.748-9.571c-.439 2.656-.739 4.886-.739 4.886z" opacity=".3"/>
        <path fill="#263238" d="M209.651 45.453s-5.988-26.936 18.93-24.267c31.282 3.345 19.393 42.157 3.896 39.814-15.497-2.343-22.826-15.547-22.826-15.547z"/>
        <path fill="#E4897B" d="M225.862 83.801c7.755.952 12.227-7.617 12.227-7.617-7.329-5.036-3.633-16.311-3.633-16.311l-11.776 4.134c.626 1.954 1.027 3.608 1.265 5.036 1.228 7.341-2.092 7.93-2.092 7.93-1.491 4.323 2.055 6.59 4.009 6.828z"/>
        <path fill="#000" d="M222.692 64.02c.627 1.954 1.028 3.607 1.266 5.035 5.637-2.255 10.511-9.17 10.511-9.17l-11.777 4.134z" opacity=".2"/>
        <path fill="#E4897B" d="M223.068 28.966c-10.636.915-13.58 6.903-13.417 17.94.2 13.781 4.084 23.265 17.126 19.193 17.727-5.537 13.956-38.661-3.709-37.133z"/>
        <path fill="#DE5753" d="M216.479 45.716s-1.24 2.907-2.719 4.673c0 0 1.04 1.366 2.907.965l-.188-5.638z"/>
        <path fill="#263238" d="M222.918 44.288c.05.877.576 1.553 1.152 1.515.589-.037 1.015-.776.952-1.653-.05-.877-.576-1.554-1.152-1.516-.577.037-1.003.777-.952 1.654z"/>
        <path fill="#263238" d="M224.121 42.659l-1.954-.627s1.052 1.541 1.954.627zM212.018 44.776c.05.877.576 1.554 1.153 1.516.589-.037 1.015-.777.952-1.654-.05-.877-.576-1.553-1.153-1.515-.576.037-1.002.776-.952 1.653z"/>
        <path fill="#263238" d="M213.221 43.16l-1.954-.626c0-.012 1.052 1.541 1.954.626zM227.14 38.587c-.113 0-.238-.05-.326-.137-1.591-1.654-4.309-1.09-4.335-1.09-.238.062-.488-.1-.538-.351-.05-.25.1-.489.351-.539.125-.025 3.219-.676 5.174 1.34.175.176.175.464-.013.64-.075.1-.2.137-.313.137zM210.453 38.838c-.138 0-.263-.063-.351-.175-.15-.2-.125-.476.075-.64 2.381-1.891 4.485-1.34 4.573-1.327.238.062.388.313.313.564-.063.238-.313.388-.551.313-.088-.025-1.779-.426-3.759 1.152-.1.075-.2.113-.3.113z"/>
        <path fill="#263238" d="M236.235 45.891s-5.725-8.318-5.549-15.134c0 0-21.824-6.802-21.023 14.696 0 0-3.695-18.83 14.558-20.822 18.228-1.992 20.471 24.768 11.714 33.55 0 .013 3.846-7.09.3-12.29z"/>
        <path fill="#E4897B" d="M234.695 48.284c-.076-1.716 1.44-4.773 4.66-4.698 3.332.075 5.487 6.715-2.581 9.045-1.165.339-1.929-.751-2.079-4.347z"/>
        <path fill="#263238" d="M219.335 56.49c-.414 0-.852-.038-1.316-.125-.25-.05-.413-.288-.363-.526.05-.251.276-.414.526-.364 4.134.777 5.45-2.781 5.5-2.931.088-.238.338-.364.576-.276.238.088.364.338.276.576 0 .038-1.303 3.646-5.199 3.646zM223.356 29.743s5.788 17.138 16.8 15.973c11.013-1.165-7.917-21.887-7.917-21.887s-18.579-5.236-26.635 5.074c-8.055 10.324 4.047 16.55 4.047 16.55s-1.842-13.493 13.705-15.71z"/>
        <path fill="#263238" d="M232.164 44.313c-.038 0-.076-.013-.113-.025-9.183-3.483-9.008-15.685-9.008-15.81 0-.176.138-.301.313-.301h.013c.175 0 .313.15.301.326 0 .112-.163 11.889 8.606 15.209.163.062.238.238.188.4-.062.126-.175.2-.3.2z"/>
        <path fill="#068AE2" d="M210.452 146.667l-3.746 11.789s54.735 3.971 60.222 35.367c0 0 8.57 4.597-1.315-25.959-9.885-30.568-9.947-64.243-7.354-89.362 0 0-1.892-.84-5.074-1.88l-1.466-.45c-5.562-1.692-14.081-3.583-23.139-3.107-9.621.5-15.685 3.946-18.93 6.527-2.029 1.603-2.944 2.881-2.944 2.881s-11.626 32.899 3.746 64.194z"/>
        <path fill="#E4897B" d="M209.65 79.592s3.383 11.638 18.116 9.897c14.733-1.729 23.954-13.317 23.954-13.317-5.563-1.692-14.082-3.583-23.14-3.107-9.621.5-15.685 3.946-18.93 6.527zM207.108 110.95s-14.107-6.828-16.487-7.329c-2.381-.502-11.551-2.731-15.021-2.97-3.471-.25-6.44 9.497-3.721 10.699 2.731 1.203 14.607 1.203 15.484 1.203.877 0 3.107-1.904 3.107-1.904l12.366 8.106 4.272-7.805z"/>
        <path fill="#068AE2" d="M258.259 78.502c-10.786-3.345-18.215 24.116-21.197 35.893-2.969 11.776-34.214-6.816-34.214-6.816l-5.838 10.136s13.179 27.248 42.482 26.897c29.291-.35 31.433-62.176 18.767-66.11z"/>
        <path fill="#000" d="M258.259 78.502c-10.786-3.345-18.215 24.116-21.197 35.893-2.969 11.776-34.214-6.816-34.214-6.816l-5.838 10.136s13.179 27.248 42.482 26.897c29.291-.35 31.433-62.176 18.767-66.11z" opacity=".1"/>
        <path fill="#068AE2" d="M115.477 57.004s-3.207-6.778-11.388-8.745c-8.068-1.942-13.18 6.101-13.18 6.101l24.568 2.644z"/>
        <path fill="#fff" d="M115.477 57.004s-3.207-6.778-11.388-8.745c-8.068-1.942-13.18 6.101-13.18 6.101l24.568 2.644z" opacity=".7"/>
        <path fill="#068AE2" d="M104.089 48.26c-4.948 0-10.323 4.12-11.926 8.744l-19.932 2.48s1.779-5.788 6.765-8.92c0 0 4.81-.313 12.991-2.305 8.194-1.992 12.102 0 12.102 0z"/>
        <path fill="#fff" d="M104.089 48.26c-4.948 0-10.323 4.12-11.926 8.744l-19.932 2.48s1.779-5.788 6.765-8.92c0 0 4.81-.313 12.991-2.305 8.194-1.992 12.102 0 12.102 0z" opacity=".8"/>
        <path fill="#068AE2" d="M64.889 92.734L46.523 85.53s-8.394 2.418-15.009 1.704c-6.615-.714-12.553-6.352-12.553-6.352s6.94 9.772 22.964 12.002c16.023 2.23 22.964-.15 22.964-.15z"/>
        <path fill="#fff" d="M64.889 92.734L46.523 85.53s-8.394 2.418-15.009 1.704c-6.615-.714-12.553-6.352-12.553-6.352s6.94 9.772 22.964 12.002c16.023 2.23 22.964-.15 22.964-.15z" opacity=".8"/>
        <path fill="#068AE2" d="M64.889 92.734s-20.81 1.904-25.106-1.754c-4.31-3.646-7.93-13.38-7.93-13.38L14 75.12s10.574 26.797 50.889 17.614z"/>
        <path fill="#fff" d="M64.889 92.734s-20.81 1.904-25.106-1.754c-4.31-3.646-7.93-13.38-7.93-13.38L14 75.12s10.574 26.797 50.889 17.614z" opacity=".7"/>
        <path fill="#068AE2" d="M158.962 107.58s-1.315-21.436-9.133-32.711H121.34s9.421 16.236 9.547 31.596l28.075 1.115z"/>
        <path fill="#fff" d="M158.962 107.58s-1.315-21.436-9.133-32.711H121.34s9.421 16.236 9.547 31.596l28.075 1.115z" opacity=".8"/>
        <path fill="#263238" d="M190.47 119.143v-3.658l-28-13.355h-35.379v17.013h63.379z"/>
        <path fill="#fff" d="M127.09 119.144h34.264v-16.387H127.09v16.387z" opacity=".1"/>
        <path fill="#263238" d="M127.642 107.58h32.209c2.193 0 3.959-1.779 3.959-3.959 0-2.193-1.779-3.96-3.959-3.96h-32.209c-2.193 0-3.959 1.78-3.959 3.96-.013 2.192 1.766 3.959 3.959 3.959z"/>
        <path fill="#fff" d="M127.642 107.58h32.209c2.193 0 3.959-1.779 3.959-3.959 0-2.193-1.779-3.96-3.959-3.96h-32.209c-2.193 0-3.959 1.78-3.959 3.96-.013 2.192 1.766 3.959 3.959 3.959z" opacity=".2"/>
        <path fill="#fff" d="M158.962 107.58h.889c2.193 0 3.959-1.779 3.959-3.959 0-2.193-1.779-3.96-3.959-3.96h-.889c-2.193 0-3.959 1.78-3.959 3.96-.013 2.192 1.766 3.959 3.959 3.959z" opacity=".2"/>
        <path fill="#263238" d="M160.616 101.767h5.074l4.084 12.64h-1.616l-3.721-8.306h-3.846s-1.93-1.115-1.829-2.48c.112-1.366 1.854-1.854 1.854-1.854z"/>
        <path fill="#fff" d="M160.616 101.767h5.074l4.084 12.64h-1.616l-3.721-8.306h-3.846s-1.93-1.115-1.829-2.48c.112-1.366 1.854-1.854 1.854-1.854z" opacity=".2"/>
        <path fill="#263238" d="M141.323 320.105h-7.668l-1.265-191.992h10.185l-1.252 191.992z"/>
        <path fill="#fff" d="M142.588 128.426l-.201 30.982-1.052 160.697h-7.68l-1.052-160.697-.201-30.982h10.186z" opacity=".4"/>
        <path fill="#000" d="M142.588 128.426l-.201 31.32h-9.784l-.201-31.32h10.186z" opacity=".2"/>
        <path fill="#263238" d="M222.805 320.105h-7.667l-13.793-191.992h10.185l11.275 191.992z"/>
        <path fill="#fff" d="M222.817 320.105h-7.679l-13.117-182.446-.677-9.233h10.186l.551 9.233 10.736 182.446z" opacity=".5"/>
        <path fill="#000" d="M212.081 137.196h-10.06l-.677-8.77h10.186l.551 8.77z" opacity=".2"/>
        <path fill="#263238" d="M59.84 320.105h-7.68l11.288-191.992h10.185L59.84 320.105z"/>
        <path fill="#fff" d="M73.633 128.426l-.676 9.233L59.84 320.105h-7.667l10.724-182.446.551-9.233h10.185z" opacity=".5"/>
        <path fill="#000" d="M63.448 128.426h10.185l-.676 8.77h-10.06l.551-8.77z" opacity=".2"/>
        <path fill="#263238" d="M216.892 132.924H58.086c-1.49 0-2.706-1.216-2.706-2.706v-6.903h164.218v6.903c0 1.49-1.215 2.706-2.706 2.706z"/>
        <path fill="#fff" d="M216.892 132.924H58.086c-1.49 0-2.706-1.216-2.706-2.706v-6.903h164.218v6.903c0 1.49-1.215 2.706-2.706 2.706z" opacity=".6"/>
        <path fill="#000" d="M219.598 123.315H55.38v4.81h164.218v-4.81z" opacity=".2"/>
        <path fill="#263238" d="M224.359 124.568H50.619c-1.49 0-2.705-1.216-2.705-2.706 0-1.491 1.215-2.706 2.706-2.706h173.739c1.49 0 2.706 1.215 2.706 2.706 0 1.49-1.216 2.706-2.706 2.706z"/>
        <path fill="#fff" d="M224.359 124.568H50.619c-1.49 0-2.705-1.216-2.705-2.706 0-1.491 1.215-2.706 2.706-2.706h173.739c1.49 0 2.706 1.215 2.706 2.706 0 1.49-1.216 2.706-2.706 2.706z" opacity=".6"/>
    </g>
  </svg>
)

export default Illustration
