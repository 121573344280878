import React, { useContext } from 'react'
import queryString from 'query-string'
import { AccountContext } from '../../../../../providers/accountProvider'

const roleTabMapping = {
  'Author': ['Published', 'Unpublished'],
  'Writing Agency': ['Published', 'Unpublished','Concepts'],
  'VO Artist': ['Published', 'Auditions', 'Recording and Production'],
  Publisher: [
    'Published',
    'Unpublished',
    'Auditions',
    'Recording and Production',
  ],
  'Production House': ['Published', 'Auditions', 'Recording and Production'],
  localised_agency: ['Published', 'Unpublished'],
  recording_studio: ['Published', 'Auditions', 'Recording and Production'],
}
const allTabs = ['Published', 'Unpublished', 'Auditions', 'Recording and Production','Concepts']

const AudiobooksContainer = props => {
  const { user: {account_type = 'Author'} } = useContext(AccountContext)
  const accTypeArr = account_type.split(',')
  const tabs = accTypeArr.reduce((acc, c) => [...new Set([...acc, ...roleTabMapping[c]])], [])
  // const tabs = roleTabMapping[account_type] || []
  const { location: { search } } = props
  const qParams = queryString.parse(search)
  const { tab: tabFromQueryParam = '' } = qParams
  let tabWithSpaces = tabFromQueryParam.replace(/-/g, ' ')
  const tab = allTabs.find(t => t.toLowerCase() === tabWithSpaces.toLowerCase())
  const active = tab || tabs[0];
  const child = React.Children.only(props.children)
  return (
    React.cloneElement(child, {
        ...props,
        tabs,
        active,
        userType: account_type
    })
  )
}

export default AudiobooksContainer
