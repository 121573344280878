import React from 'react'

const LikeOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0D1536"
      fillOpacity=".8"
      fillRule="evenodd"
      d="M12 6.48c-2.068-1.982-5.395-1.973-7.451.027-2.065 2.01-2.065 5.27 0 7.28L11.962 21l.038-.037.038.037 7.413-7.214c2.065-2.01 2.065-5.269 0-7.279-2.056-2-5.383-2.01-7.451-.027zm-.038 12.427L5.595 12.71c-1.46-1.42-1.46-3.707 0-5.129 1.476-1.436 3.882-1.443 5.367-.02L12 8.559l1.038-.995c1.485-1.424 3.89-1.417 5.367.02 1.46 1.42 1.46 3.707 0 5.128l-6.367 6.196L12 18.87l-.038.037z"
      clipRule="evenodd"
    />
  </svg>
)

export default LikeOutlined
