import { useState, useEffect } from 'react'
import { debounce } from '../utils'

const useInfiniteScroll = (callback, { threshold, offset } = {}) => {
  const [isFetching, setIsFetching] = useState(false)
  
  // this step is very important. dont write debounce function call inline on add and 
  // remove listener callback, instead assign it to a const and use that for both.
  // otherwise eventlistener will never get removed, instead it will keep on piling up
  // because each debounce call will create a new function and react wont know which
  // listener to remove.
  const debouncedHanleScroll = debounce(handleScroll, 800)

  useEffect(() => {
    if (!isFetching) return;
    callback(setIsFetching)
  }, [isFetching])

  useEffect(() => {
    window.addEventListener('scroll', debouncedHanleScroll)
    return () => window.removeEventListener('scroll', debouncedHanleScroll)
  }, [])

  
  function handleScroll() {
    let body = document.body
    let html = document.documentElement
    const viewHeight = window.innerHeight

    // this should have simply been html.scrollTop, this is a hack for safari
    const scroll = Math.max( window.pageYOffset, html.scrollTop, body.scrollTop )
    
    // this should have simply been html.scrollHeight, this is a hack for safari
    const docScrollHeight = Math.max( body.scrollHeight, body.offsetHeight, 
      html.clientHeight, html.scrollHeight, html.offsetHeight )
    const percentScrollForFetch = threshold || 80
    const currentPercentScroll = ((viewHeight + scroll) * 100) / (docScrollHeight + (offset || 0))
    if (currentPercentScroll < percentScrollForFetch) return
    setIsFetching(true)
  }

  return isFetching
}

export default useInfiniteScroll
