import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import {
  FloatingInput as Input,
  Upload,
} from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import Axios from 'axios'
import FileIcon from '../../../../../components/Images/File'
import { post, get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { Popup } from '../../../../../components/Popup'
import Dustbin from '../../../../../components/Images/Dustbin'
import BigCrossIcon from '../../../../../components/Images/CrossIcon'
import { convertUrlToHttps, getNameFromUrl } from '../../../../../lib/utils'
import CrossIcon from '../../../../../components/Images/SmallCross'
import AddIcon from '../../../../../components/Images/AddIcon'
import InfoIcon from '../../../../../components/InfoIcon'
import { withRouter } from 'react-router-dom'
import ReadOnlyEditor from '../../../../../components/TextEditor/readOnlyEditor'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button } from '../../../../../designSystem'
import moment from 'moment'
import FileViewer from 'react-file-viewer'

const StyledFormCloseIcon = styled(BigCrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const Details = styled.div`
  padding: 28px 24px 24px 24px;
`
const Actions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  // background-color: var(--Grey100);
`
const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Punch500);
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
`
export const EditHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
`
const FileContainer = styled.div`
  padding: 14px;
  border-radius: 4px;
  border: ${({ hasError }) =>
    hasError ? 'solid 1px var(--Punch500)' : 'dashed 1px var(--Azure500)'};
  background-color: var(--Grey300);
`
const FilePlaceHolder = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Azure500);
`
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;

  @media (max-width: 600px) {
    display: flex;
    position: unset;
    justify-content: flex-start;
  }
`
const FileNameDiv = styled.div`
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  &:hover ${HoverActions} {
    display: flex;
  }
`
const TopDiv = styled.div`
  display: flex;
`
const BottomDiv = styled.div``

const FileIconDiv = styled.div`
  margin-right: 8px;
`

const FileDetailsDiv = styled.div``

const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const FileMeta = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text300);
`

const DownloadButton = styled.a`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const OpenEditorButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
`
const DownloadPdfButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const UploadButton = styled.button`
  outline: none;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  background: none;
  cursor: pointer;
`
const ProgressDiv = styled.div`
  padding: 14px 16px;
  background-color: var(--Grey100);
  border-radius: 4px;
  display: flex;
  align-items: center;
`
const ProgressPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
  margin-right: 10px;
`
const LinearProgress = styled.div`
  width: calc(100% - 140px);
  height: 8px;
  border-radius: 2px;
  background-color: var(--Grey900);
  overflow: hidden;
  margin-right: 16px;
`
const ProgressBar = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: var(--Azure500);
`
const DeleteHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const DeleteSpan = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
`
const DeleteBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`
export const StatusContainer = styled.div`
  position: relative;
`
export const SelectedStatus = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 24px;
  border: ${({ selectedStatus }) =>
    selectedStatus === 'pending'
      ? 'solid 1px rgba(242,198,2,0.2)'
      : selectedStatus === 'approved'
      ? 'solid 1px rgba(121,186,16,0.2)'
      : 'solid 1px rgba(221, 54, 35, 0.2)'};
  background-color: ${({ selectedStatus }) =>
    selectedStatus === 'pending'
      ? 'rgba(242,198,2,0.1)'
      : selectedStatus === 'approved'
      ? 'rgba(121,186,16,0.1)'
      : 'rgba(221, 54, 35, 0.1)'};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${({ selectedStatus }) =>
    selectedStatus === 'pending'
      ? 'var(--Corn800)'
      : selectedStatus === 'approved'
      ? 'var(--Lime800)'
      : 'var(--Punch800)'};
  padding: 8px 12px;
`
export const StatusesDropdownBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  background-color: #ffffff;
  width: 230px;
  z-index: 10;
`
export const StatusSingle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ middle }) => (middle ? '#f0f1f5' : '#ffffff')};
  padding: 16px 24px;
`
export const StatusSingleText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-left: 8px;
  text-transform: capitalize;
`
export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ issues, review }) =>
    review ? '#f2c602' : issues ? '#dd3623' : '#79ba10'};
`
const StyledCrossIcon = styled(CrossIcon)`
  z-index: 2;
  cursor: pointer;
`
const CTAContainer = styled.div`
  padding: 14px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(6, 138, 226, 0.1);
  border: ${({ hasError }) =>
    hasError ? 'solid 1px var(--Punch500)' : 'none'};
  svg {
    margin-right: 4px;
  }
`
const CTASpan = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Azure500);
  cursor: pointer;
`
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const FileReadMode = styled.div`
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  z-index: 9;
  overflow: scroll;
  scroll-behavior: smooth;
  user-select: none;
  height: 300px;
  -webkit-user-select: none;
  &:hover ${HoverActions} {
    display: flex;
  }
`

export const FileUploadComponent = ({
  readOnly,
  isLoading,
  progress,
  imageUrl,
  placeholder,
  handleReUpload = () => null,
  fileMeta,
  title,
  hasError,
  cancelRequest,
  showReadOption,
  isDownloadOptionRemoved,
}) => {
  const [showDoc, setShowDoc] = useState(false)
  let uploadTime = moment(fileMeta?.created_time).format(
    'MMMM Do YYYY, h:mm:ss a'
  )

  const handleDownloadClick = () => {
    const extension = (imageUrl || '').split('.').pop()
    fetch(convertUrlToHttps(imageUrl, true)).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of  file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        const alink = document.createElement('a')
        alink.href = fileURL
        alink.download = `${title}.${extension}`
        alink.click()
      })
    })
  }
  return (
    <>
      {!imageUrl && !isLoading && (
        <FileContainer hasError={hasError}>
          {placeholder && <FilePlaceHolder>{placeholder}</FilePlaceHolder>}
        </FileContainer>
      )}
      {isLoading && (
        <ProgressDiv>
          <LinearProgress>
            <ProgressBar value={progress >= 100 ? 100 : progress} />
          </LinearProgress>
          <ProgressPercent>
            {(progress >= 100 ? '100' : progress.toFixed(0)) + '% Uploaded'}
          </ProgressPercent>
          <StyledCrossIcon onClick={cancelRequest} />
        </ProgressDiv>
      )}
      {imageUrl && !isLoading && (
        <FileNameDiv>
          <TopDiv>
            <FileIconDiv>
              <FileIcon />
            </FileIconDiv>
            <FileDetailsDiv>
              <FileName>{getNameFromUrl(imageUrl, title)}</FileName>
              {fileMeta && (
                <FileMeta>
                  {fileMeta.created_time ? `Last updated : ${uploadTime}` : ''}
                  {/* {fileMeta.words && fileMeta.size && ' • '} */}
                  {/* {fileMeta.size} */}
                </FileMeta>
              )}
            </FileDetailsDiv>
          </TopDiv>
          <BottomDiv>
            <HoverActions>
              {!isDownloadOptionRemoved && (
                <DownloadButton onClick={handleDownloadClick} type="button">
                  Download
                </DownloadButton>
              )}
              {!readOnly && (
                <UploadButton type="button" onClick={handleReUpload}>
                  Upload
                </UploadButton>
              )}
              {showReadOption && (
                <DownloadButton
                  type="button"
                  onClick={() => setShowDoc(!showDoc)}
                >
                  Read
                </DownloadButton>
              )}
            </HoverActions>
          </BottomDiv>
        </FileNameDiv>
      )}
      {showDoc && (
        <Box my={16} height={300} width="100%">
          <FileViewer
            filePath={convertUrlToHttps(imageUrl, true)}
            fileType="docx"
          />
        </Box>
      )}
    </>
  )
}

export const ScriptEditComponent = ({
  readOnly,
  resourceUrl,
  handleOpenEditor,
  handleOpenFreshEditor,
  id,
  name,
  fileMeta,
  title,
  hasError,
  infoTitle,
  label,
  showReadOption,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [fileContent, setFileContent] = useState(null)
  const [isReadOnlyEditorShowing, setIsReadOnlyEditorShowing] = useState(false)

  const handleDownloadTxt = () => {
    setIsProcessing(true)
    get('/content_api/book.chapter/pdf', {
      params: {
        file_url: resourceUrl,
      },
    }).then((response) => {
      const { results: link } = response
      let hiddenElement = document.createElement('a')
      hiddenElement.href = link
      hiddenElement.target = '_blank'
      hiddenElement.download = 'script.pdf'
      hiddenElement.click()
      setIsProcessing(false)
    })
  }

  const handleDownLoadContent = () => {
    if (fileContent) {
      setIsReadOnlyEditorShowing(true)
      return
    }
    setIsProcessing(true)
    Axios.get(resourceUrl)
      .then((response) => {
        setFileContent(response.data)
        setIsReadOnlyEditorShowing(true)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const handleRead = () => {
    Axios.get(resourceUrl)
      .then((response) => {
        setFileContent(response.data)
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const extension = (resourceUrl || '').split('.').pop()
  const nonTXT = extension !== 'txt'
  return (
    <>
      {isReadOnlyEditorShowing && fileContent && (
        <ReadOnlyEditor
          content={fileContent}
          title={title}
          close={() => setIsReadOnlyEditorShowing(false)}
        />
      )}
      {label && (
        <LabelContainer>
          <LabelSpan htmlFor={id || name}>{label}</LabelSpan>
          <InfoIcon title={infoTitle} />
        </LabelContainer>
      )}
      {!resourceUrl && (
        <CTAContainer hasError={hasError} onClick={handleOpenEditor}>
          <AddIcon />
          <CTASpan>
            {readOnly ? 'No Script File' : 'Create Script File'}
          </CTASpan>
        </CTAContainer>
      )}
      {resourceUrl && (
        <FileNameDiv>
          <TopDiv>
            <FileIconDiv>
              <FileIcon />
            </FileIconDiv>
            <FileDetailsDiv>
              <FileName>
                {!nonTXT ? 'Editor File' : getNameFromUrl(resourceUrl, title)}
              </FileName>
              {fileMeta && (
                <FileMeta>
                  {fileMeta.words ? `${fileMeta.words} words` : ''}
                  {/* {fileMeta.words && fileMeta.size && ' • '} */}
                  {/* {fileMeta.size} */}
                </FileMeta>
              )}
            </FileDetailsDiv>
          </TopDiv>
          <BottomDiv>
            <HoverActions>
              {readOnly && nonTXT && (
                <DownloadButton
                  href={convertUrlToHttps(resourceUrl, true)}
                  download
                  type="button"
                >
                  Download
                </DownloadButton>
              )}
              {!readOnly && nonTXT && (
                <DownloadButton
                  href={convertUrlToHttps(resourceUrl, true)}
                  download
                  type="button"
                >
                  Download
                </DownloadButton>
              )}
              {!readOnly && nonTXT && (
                <OpenEditorButton type="button" onClick={handleOpenFreshEditor}>
                  Create Script File
                </OpenEditorButton>
              )}
              {/* {readOnly && !nonTXT && <DownloadPdfButton type="button" onClick={handleDownloadTxt}>
                  {isProcessing ? 'Downloading...' : 'Download as PDF'}
                </DownloadPdfButton>} */}
              {readOnly && !nonTXT && (
                <DownloadPdfButton
                  type="button"
                  onClick={handleDownLoadContent}
                >
                  {isProcessing ? 'Opening...' : 'View File'}
                </DownloadPdfButton>
              )}
              {showReadOption && resourceUrl && !nonTXT && (
                <DownloadButton type="button" onClick={handleRead}>
                  READ
                </DownloadButton>
              )}
              {!readOnly && !nonTXT && (
                <OpenEditorButton type="button" onClick={handleOpenEditor}>
                  Open in Editor
                </OpenEditorButton>
              )}
            </HoverActions>
          </BottomDiv>
        </FileNameDiv>
      )}
      {fileContent && showReadOption && (
        <FileReadMode dangerouslySetInnerHTML={{ __html: fileContent }} />
      )}
    </>
  )
}
const AddEditEpisode = ({
  mode,
  book_id,
  created_for = '',
  episodeNumber,
  successCallback = () => null,
  deleteSuccessCB,
  handleCancel,
  data = {},
  history,
  book_type,
  language,
}) => {
  const [isDeletePopupShown, setDeletePopupShow] = useState(false)
  const [isStatusSelectshown, toggleStatusSelectShown] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(data.status || '')
  const chapterStatusRef = useRef()
  const handleDelete = () => {
    let deleteApi = '/content_api/book.delete_chapter'
    get(deleteApi, {
      params: {
        chapter_id: data.chapter_id,
        book_id: book_id || data.book_id,
        view: 'dashboard',
      },
    }).then(() => {
      setDeletePopupShow(false)
      deleteSuccessCB(data.chapter_id)
    })
  }
  const handleClick = (e) => {
    if (
      chapterStatusRef.current &&
      !chapterStatusRef.current.contains(e.target)
    ) {
      toggleStatusSelectShown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])
  return (
    <Box mb={8} borderRadius={4}>
      <Box variant="flex" p={20}>
        <StyledFormCloseIcon onClick={handleCancel} />
      </Box>

      <Formik
        initialValues={{
          chapter_title: data['chapter_title'] || '',
          file_url: data['file_url'] || '',
          description: data['description'] || '',
        }}
        validationSchema={Yup.object({
          chapter_title: Yup.string().required('Required'),
          // description: Yup.string().required('Required'),
          file_url: Yup.string().required('Required'),
        })}
        isInitialValid={mode === 'edit'}
        onSubmit={(values, { setSubmitting }) => {
          // TODO: change this later when edit put is implemented
          let formData = { ...values, book_id, content_unchanged: 1 }
          formData['status'] = selectedStatus
          if (mode === 'edit') {
            let { chapter_id } = data
            formData['chapter_id'] = chapter_id
            formData['book_id'] = data.book_id
          }
          if (values.file_url !== data.file_url) {
            if (selectedStatus === 'issues to be resolved') {
              formData.status = 'issues resolved in script'
            } else {
              formData.status = 'pending'
            }
          }
          formData['view'] = 'dashboard'
          let createBookApi = '/content_api/book.create_chapter?is_novel=0'
          post(createBookApi, { data: formData }).then(() => {
            setSubmitting(false)
            successCallback({ ...values, status: selectedStatus })
          })

          // if scipt is uploaded from file uploader and not from editor page
          // (in the case of oversees writers), we need to
          // call book.version api. otherwise file_url woudnt get saved
          // this flow change has been made after oversees writers support release
          if (mode === 'edit' && values.file_url !== data.file_url) {
            const dataForVersion = {
              chapter_id: data.chapter_id,
              word_count: 0,
              file_url: values.file_url,
              chapter_title: values.chapter_title,
              book_id: data.book_id,
              view: 'dashboard',
            }
            post('/content_api/book.version?is_novel=0', {
              data: dataForVersion,
            })
          }
        }}
      >
        {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
          <Form>
            <Popup
              show={isDeletePopupShown}
              close={() => setDeletePopupShow(false)}
              renderActions={
                <>
                  <button
                    className="action-button"
                    type="button"
                    onClick={() => setDeletePopupShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="action-button danger"
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </>
              }
            >
              <Box
                variant="flex"
                alignItems="center"
                mb={8}
                justifyContent="flex-start"
              >
                <Dustbin />
                <Text variant="titleSecondary" ml={8}>
                  Delete this episode?
                </Text>
              </Box>
              <Text variant="caption" lineHeight={1.8} fontWeight={500}>
                Are you sure you want to permanently delete this episode, once
                an episode is deleted, you can’t undo it.
              </Text>
            </Popup>
            <Box p={24}>
              <Box variant="flex" mb={[16, 24]}>
                <Title variant="titleSecondary">
                  Episode{' '}
                  {episodeNumber || data['sn'] || data['seq_number'] || 1}
                </Title>
              </Box>
              {/* <HR /> */}
              <Box variant="flex" flexDirection="column">
                <Box width="100%" mb={[16, 24]}>
                  <Input
                    label="Title (‘Ep - # <title>’)"
                    name="chapter_title"
                    hasError={submitCount > 0 && errors['chapter_title']}
                    type="text"
                    infoTitle="Give a title to this chapter"
                    placeholder="Chapter title goes here"
                    maxLength={50}
                  />
                </Box>
                <Box width="100%" mb={[16, 24]} mt={-24}>
                  {language !== 'english' ? (
                    <ScriptEditComponent
                      hasError={submitCount > 0 && errors['file_url']}
                      label="Script Doc File"
                      name="file_url"
                      title={data['chapter_title']}
                      resourceUrl={values['file_url']}
                      handleOpenEditor={() =>
                        history.push(
                          `${menuItemObj.audiobooks}/edit-book-chapter/${
                            data['book_id'] || book_id
                          }?title=${values['chapter_title']}${
                            data['chapter_id']
                              ? '&chapter_id=' + data['chapter_id']
                              : ''
                          }&book_type=${book_type}&language=${language}`
                        )
                      }
                      handleOpenFreshEditor={() =>
                        history.push(
                          `${menuItemObj.audiobooks}/edit-book-chapter/${
                            data['book_id'] || book_id
                          }?title=${values['chapter_title']}${
                            data['chapter_id']
                              ? '&chapter_id=' + data['chapter_id']
                              : ''
                          }&create_new=true&book_type=${book_type}&language=${language}`
                        )
                      }
                    />
                  ) : (
                    <Upload
                      label="Episode Document"
                      name="file_url"
                      hasError={submitCount > 0 && errors['file_url']}
                      hideInputOnValue={true}
                      showProgress={true}
                      readOnly={selectedStatus === 'approved' ? true : false}
                      placeholder="Upload Episode Document"
                      renderComp={FileUploadComponent}
                      fileTypeTags="file"
                      accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                      extraParams={{
                        file_dir: 'episodedocumentupload',
                        title: 'episodedocumentupload',
                      }}
                      uploadedFileMeta={{
                        created_time: `${data?.create_time}`,
                      }}
                      cloud_dir_file={'episodedocumentupload'}
                      // uploadedFileMeta={{words: '10,000 words', size: '456 KB'}}
                    />
                  )}
                </Box>
              </Box>
              <Box width="100%" mb={[16, 24]}>
                <Input
                  label="Description"
                  name="description"
                  type="text"
                  infoTitle="Add the description of this chapter"
                  placeholder="Write something that describes this episode"
                />
              </Box>
            </Box>
            <Actions>
              {mode === 'edit' && (
                <DeleteButton
                  onClick={() => setDeletePopupShow(true)}
                  type="button"
                >
                  Delete
                </DeleteButton>
              )}
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              {/* {!isSubmitting && <SaveButton type="submit" isValid={isValid}>
                  Save
              </SaveButton>}
              {isSubmitting && <SaveButton type="button" isValid={isValid}>
                  <Loader invertColor inline size="sm" />
              </SaveButton>} */}
              <Button
                type={isSubmitting ? 'button' : 'submit'}
                isLoading={isSubmitting}
                disabled={selectedStatus === 'approved'}
              >
                Save Chapter
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default withRouter(AddEditEpisode)
