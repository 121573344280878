import React, { useEffect, useState } from 'react'
import { emptyStateText } from '../../constants'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import { get, put } from '../../../../../lib/api'
import EmptyState from '../emptyState'
import { useDidUpdate } from '../../../../../lib/customHooks'
import { CongratulationsCard, AcknowledgementCard } from './notificationCard'
import { FullScreenNotice } from '../../../../../components/Popup'
import { descriptionText } from '../../constants'
import { convertUrlToHttps } from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import { useInfiniteScroll } from '../../../../../lib/customHooks'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Image, Text} from '../../../../../designSystem'
import Filters from '../../../../../components/Filters'
import { WithFilters } from '../../../../../HOC/withFiltersHOC'

const SingleBook = styled.div`
  display: flex;
  padding: 24px;
  margin-bottom: 8px;
  background-color: var(--Dove);
  cursor: pointer;
  flex-direction: row;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }

  @media (max-width:600px){
    padding: 16px;
    flex-direction: column;
  }
`

const Requirement = styled.div`
  width: 190px;
  border-radius: 4px;
  background-color: rgba(242, 198, 2, 0.2);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  padding: 8px 16px;
  margin-right: 16px;
  border-radius: 24px;

  @media (max-width:600px){
    &:first-child {
      margin-bottom: 8px;
    }
  }
`

const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`

const Participants = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media(max-width:600px){
    justify-content: flex-start;
    margin: 16px;
  }
`
const ParticipantImage = styled.img`
  height: 32px;
  width: 32px;
  display: inline-block;
  border: solid 2px var(--Dove);
  border-radius: 50%;
  margin-left: -12px;
  &:last-of-type {
    margin-right: 8px;
  }
`
export const TNCPara = styled.p`
  margin-bottom: 16px;
`
export const TNCTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text500);
  margin-bottom: 16px;
`
export const TNCContent = styled.div`
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--Text500);
  }
`

const Auditions = ({ history, filterObj, setFilterObj, qFilters, }) => {
  const [isAllSelected, setAllSelected] = useState(true)
  const [categoriesSelected, setCategories] = useState([])
  const [otherFiltersSelected, setOtherFilters] = useState('')
  const [
    isAlreadyParticipatedSelected,
    setAlreadyParticipatedSelected,
  ] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState(null)
  const [selectedForBooks, setSelectedForBooks] = useState([])
  const [AcceptedBooks, setAcceptedBooks] = useState([])
  const [isTNCPopupShown, setTNCPopupDisplay] = useState(false)
  const [acceptedBookId, setAcceptedBookId] = useState(null)
  const [apiError, toggleApiError] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const isMobile = window.screen.width < 600
  const fetchData = () => {
    setIsLoading(true)
    let lang = '';
    if(filterObj['language'] && filterObj['language'][0]){
      lang = filterObj['language'][0];
    }
    const alreadyParticipated = isAlreadyParticipatedSelected
      ? 'already participated'
      : ''
    const params = {
      categories: categoriesSelected.join(',') || null,
      filter: [otherFiltersSelected, alreadyParticipated].filter(Boolean).join(',') || null,
      language: lang
    }
    const auditionBookListApi = '/content_api/book.audition_books'
    get(auditionBookListApi, { params: params || {} })
      .then((response) => {
        const { result } = response
        const { books, next_url } = result
        setBooks(books)
        setSelectedForBooks(
          books.filter((book) => book.is_approved && !book.is_accepted)
        )
        setNextUrl(next_url)
        setIsLoading(false)
      })
      .catch((error) => {
        // TODO: remove this hack for failing api
        if (error.code === 400) {
          setBooks([])
        } else {
          throw error
        }
      })
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result } = response
        const { next_url, books: newBooks } = result
        setBooks(books.concat(newBooks))
        setSelectedForBooks(selectedForBooks.concat(newBooks.filter((book) => book.is_approved && !book.is_accepted)))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  useEffect(() => {
    fetchData()
  }, [qFilters])

  useDidUpdate(() => {
    setIsLoading(false)
  }, [books])

  useDidUpdate(() => {
    fetchData()
  }, [
    isAllSelected,
    categoriesSelected,
    otherFiltersSelected,
    isAlreadyParticipatedSelected,
  ])

  const setFilters = (obj) => {
    const QString = setFilterObj(obj);
    history.push(`${menuItemObj.audiobooks}?tab=auditions&${QString}`);
  };

  const handleAcceptBook = () => {
    setTNCPopupDisplay(false)
    // TODO: make accept api call here
    const bookDetailsApi = '/content_api/book.book_auditions'
    get(bookDetailsApi, { params: { book_id: acceptedBookId } })
      .then((response) => {
        const { results } = response
        const { has_participated, auditions = [] } = results
        if (!has_participated) return
        const { audition_id } = auditions[0] || {}
        put('/content_api/book.accept_audition', {
          data: {
            audition_id,
            is_accepted: 1,
            view: "dashboard",
          },
        })
      })
      .then(() => {
        // on success:
        fetchData()
        setAcceptedBooks(
          books.filter((book) => book.book_id === acceptedBookId)
        )
      })
  }

  const handleAcceptBookPopup = (id) => {
    setAcceptedBookId(id)
    setTNCPopupDisplay(true)
  }

  const handleAckButtonClick = (id) => {
    history.push(`${menuItemObj.audiobooks}?tab=recording-and-production`)
  }

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    )
  }

  const isFiltersApplied = !isAllSelected

  return (
    <>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      <Filters
          filterObj={filterObj}
          setFilters={setFilters}
          options={[
            {
              name: "Hindi",
              value: "hindi",
              filterKey: "language",
            },
            {
              name: "English",
              value: "english",
              filterKey: "language",
            },
            {
              name: "Malayalam",
              value: "malayalam",
              filterKey: "language",
            },
            {
              name: "Telugu",
              value: "telugu",
              filterKey: "language",
            },
            {
              name: "Tamil",
              value: "tamil",
              filterKey: "language",
            },
            {
              name: "Kannada",
              value: "kannada",
              filterKey: "language",
            },
            {
              name: "Marathi",
              value: "marathi",
              filterKey: "language",
            },
            {
              name: "Bengali",
              value: "bengali",
              filterKey: "language",
            },
            {
            name: "Spanish",
            value: "spanish",
            filterKey: "language",
          },
          ]}
        />
      {selectedForBooks
        .filter(
          (book) => !AcceptedBooks.find((b) => b.book_id === book.book_id)
        )
        .map(({ book_id, book_title, image_url }) => (
          <CongratulationsCard
            image_url={image_url}
            book_title={book_title}
            key={book_id}
            onAccept={() => handleAcceptBookPopup(book_id)}
            id={book_id}
          />
        ))}
      {AcceptedBooks.map(({ book_id, book_title, image_url }) => (
        <AcknowledgementCard
          image_url={image_url}
          key={book_id}
          book_title={book_title}
          onButtonClick={handleAckButtonClick}
          id={book_id}
        />
      ))}
      <FullScreenNotice
        show={isTNCPopupShown}
        close={() => setTNCPopupDisplay(false)}
        renderActions={
          <>
            <button
              className="action-button"
              type="button"
              onClick={() => setTNCPopupDisplay(false)}
            >
              Cancel
            </button>
            <button
              className="action-button primary-button"
              type="button"
              onClick={handleAcceptBook}
            >
              Accept Audiobook
            </button>
          </>
        }
      >
        <TNCTitle>Terms and Conditions for Company Name</TNCTitle>
        <TNCContent>
          {descriptionText.acceptAudition.map((text, i) => (
            <TNCPara key={'audition-accept-terms-' + i}>{text}</TNCPara>
          ))}
          {descriptionText.acceptConditions.map((text, i) => (
            <p key={'audition-accept-conditions-' + i}>{text}</p>
          ))}
        </TNCContent>
      </FullScreenNotice>
      {books.length ? (
        books.map((book) => {
          return (
            <SingleBook
              key={book.book_id}
              onClick={() =>
                history.push(`${menuItemObj.audiobooks}?tab=auditions&id=${book.book_id}`)
              }
            >
              <Box variant="flex" flexDirection="row" justifyContent="flex-start">
                <Image height={[80,144]} width={[80,144]} src={convertUrlToHttps(book.image_url,true)} mr={[8, 24]}/>
                <Box>
                  <Text variant="title" fontWeight="r">{book.book_title}</Text>
                  {!isMobile && book.category_obj.topic_name && <Text mt={8} variant="subtext">{book.category_obj.topic_name}</Text>}
                  {/* <RateDiv>{book['audition_info']['base_price']}</RateDiv> */}
                </Box>
              </Box>
              <Box variant="flex"  flexDirection="column" minWidth="23%" ml={!isMobile && 'auto'} mt={[16, 0]}>
                <Text variant="caption" width="100%" bg="surface.sf300" pt={8} pr={16} pb={8} pl={16} borderRadius={4}>
                  Participate before{' '}
                  {book['audition_info']['last_participation_date']}
                </Text>
                <Participants>
                  {book['top_auditions_image_urls'].map((url, i) => (
                    <ParticipantImage
                      src={url}
                      key={'participant-thumbnail' + i}
                    />
                  ))}
                  {`${book['auditions_count'] || 'No'} ${
                    book['auditions_count'] > 1 ? 'Participants' : 'Participant'
                  }`}
                </Participants>
              </Box>
            </SingleBook>
          )
        })
      ) : isFiltersApplied ? (
        <EmptyState
          filtersApplied
          text={'No matching results!'}
          subtext={
            'There were no results matching your criteria, try using different combination of filters.'
          }
        />
      ) : (
        <EmptyState
          text={emptyStateText['Auditions']['text']}
          subtext={emptyStateText['Auditions']['subtext']}
        />
      )}
      {isFetching && <Loader />}
    </>
  )
}

export default withRouter(WithFilters(Auditions))
