import React from 'react'

const Redo = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
        <path fill="#0D1536" d="M16.673 4.21c-.146-.147-.367-.191-.559-.112-.192.08-.317.268-.317.476V6.44H6.811c-2.836 0-5.144 2.318-5.144 5.167 0 2.85 2.308 5.168 5.144 5.168h5.177c.283 0 .512-.23.512-.515 0-.284-.23-.515-.512-.515H6.81c-2.271 0-4.119-1.856-4.119-4.138 0-2.281 1.848-4.137 4.119-4.137h8.986v1.835c0 .209.125.397.317.476.191.08.412.036.56-.112l2.344-2.365c.199-.201.199-.526 0-.727L16.673 4.21z"/>
    </svg>


)

export default Redo
