import React from "react";
import styled from 'styled-components';
import './style.css';
const SvgLoader=styled.div`
  display:flex;
  align-items:center;
  margin-left:10px;
`
const LoadPreviousDiv = styled.div`
  display: flex;
  justify-content:center;
  background-color: var(--Dove);
  color: var(--Azure500);
  height: 30px;
  border:1px solid #fdfdfd;
  align-items:center;
  cursor:pointer;
  font-weight: 600;
  margin: 3px 0px 3px 0px;
`
function LoadPrevious({loadPreviousPage,showPreviousLoader}) {
  return (
    <LoadPreviousDiv onClick={loadPreviousPage}>
      Load Previous Episode{" "}
      <SvgLoader>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={showPreviousLoader ? "LoaderRotate" : ""}
            d="M15.094 7.828h-1.607A5.489 5.489 0 0 0 8 2.666c-.943 0-1.87.248-2.674.696l-.293.17c-.124.077-.17.232-.093.355l.464.711c.077.109.216.155.34.078l.247-.14a4.13 4.13 0 0 1 6.135 3.276h-1.56c-.201 0-.325.217-.217.387l2.257 3.478a.246.246 0 0 0 .417 0l2.256-3.478c.124-.154.016-.37-.185-.37zM10.597 11.738c-.077-.108-.216-.154-.34-.077l-.247.14a4.19 4.19 0 0 1-2.01.525c-2.178 0-3.956-1.685-4.141-3.802h1.56c.202 0 .325-.217.217-.387L3.38 4.645a.246.246 0 0 0-.417 0L.705 8.122a.251.251 0 0 0 .216.386h1.592a5.5 5.5 0 0 0 5.487 5.162c.927 0 1.854-.247 2.673-.695l.294-.17c.124-.078.17-.232.093-.356l-.464-.71z"
            fill="#068AE2"
          />
        </svg>
      </SvgLoader>
    </LoadPreviousDiv>
  );
}

export default LoadPrevious;
