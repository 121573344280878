import React, { useState, useEffect } from 'react'
import AddEditEpisode from './addEditEpisode'
import SingleEpisodeView from './singleEpisodeView'
import { Popup } from '../../../../../components/Popup'
const EpisodeComponent = ({data, handleEpisodeDeleteFromState, successCallback, ...props}) => {
  const [mode, setMode] = useState('view')
  const [dataFromProps, setData] = useState(data || {})
  const isMobile = window.screen.width < 600
  const handleChangeToEditMode = () => setMode('edit')

  const handleEditSuccess = (values) => {
    // setData({...dataFromProps, ...values})
    setMode('view')
    successCallback()
  }
  
  return mode === 'view' ? <SingleEpisodeView {...props} data={dataFromProps} isMobile={isMobile} handleOnClick={handleChangeToEditMode} /> : (
    <Popup show={true} className="right-drawer-episode-form" close={() => setMode('view')}>
      <AddEditEpisode {...props} data={dataFromProps} mode='edit' successCallback={handleEditSuccess} deleteSuccessCB={handleEpisodeDeleteFromState} handleCancel={() => setMode('view')}/>
    </Popup> 
  )
}


const EpisodeList = ({ bookData,setBookDetails, thumbnail, book_id, created_for, ...props }) => {

  const handleEpisodeDeleteFromState = chapter_id => {
    let newData = bookData.chapters.filter(chapter => chapter.chapter_id !== chapter_id)
    setBookDetails({...bookData, chapter_count: bookData.chapter_count-1, chapters: newData})
  }
  return bookData.chapters.map((episode, i) => {
    return <EpisodeComponent key={episode.chapter_id} data={{...episode, sn: i + 1, thumbnail, book_id, created_for}} handleEpisodeDeleteFromState={handleEpisodeDeleteFromState} {...props}/>
  })
}

export default EpisodeList

