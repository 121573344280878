import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Billboard from './billboard'
import Banner from './banner'
import Notification from './notification'
import { get } from '../../../../../lib/api'
import PlusIcon from '../../../../../components/Images/PlusIcon'

const AddButtons = styled.div`
  display: flex;
  margin-top: 16px;
`
const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 48px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(73, 83, 89, 0.16);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-right: 16px;
  svg {
    margin-right: 8px;
  }
`
const Spacer = styled.div`
  height: 200px;
`
const NovelProgrammatic = ({ book_id ,language}) => {
  const [banner, setBanner] = useState({})
  const [notification, setNotification] = useState({})
  const [billboard, setBillboard] = useState({})
  const [controls, setControls] = useState({
    billboard: false,
    notification: false,
    variant: false,
    banner: false,
  })

  const toggleEntityAddCard = (entity) => {
    setControls({ ...controls, [entity]: !controls[entity] })
  }

  const fetchNotification = () => {
    get('/content_api/notifications/', {
      params: {
        book_id: book_id,
      },
    }).then((res) => {
      setNotification(res)
    }).catch(error => {
      if (error.code === 404) {
        setNotification({})
      } else {
        throw error
      }
    })
  }
  const fetchBillboard = () => {
    get(`/content_api/billboards/?book_id=${book_id}`).then((res) => {
      setBillboard(res)
    }).catch(error => {
      if (error.code === 404) {
        setBillboard({})
      } else {
        throw error
      }
    })
  }
  const fetchBanner = () => {
    get('/content_api/banners/', {
      params: {
        book_id: book_id,
      },
    }).then((response) => {
      setBanner(response)
    })
  }

  const refreshBillboard = () => {
    fetchBillboard()
  }

  const refreshNotification = () => {
    fetchNotification()
  }

  const refreshBanner = () => {
    fetchBanner()
  }

  useEffect(() => {
    fetchBillboard()
    fetchNotification()
    fetchBanner()
  }, [])

  const noBillboard = billboard.image_url
  const noNotification = notification.image_url
  const noBanner = banner.banner_image_url
  return (
    <>
      <Billboard
        isShowAdd={controls['billboard']}
        data={billboard}
        book_id={book_id}
        cancelAdd={() => toggleEntityAddCard('billboard')}
        refreshCB={refreshBillboard}
        language={language}
      />
      <Notification
        isShowAdd={controls['notification']}
        data={notification}
        book_id={book_id}
        cancelAdd={() => toggleEntityAddCard('notification')}
        refreshCB={refreshNotification}
        language={language}
      />
      <Banner
        isShowAdd={controls['banner']}
        data={banner}
        book_id={book_id}
        cancelAdd={() => toggleEntityAddCard('banner')}
        refreshCB={refreshBanner}
        language={language}
      />
      <AddButtons>
        {noBillboard==="" && (
          <AddButton onClick={() => toggleEntityAddCard('billboard')}>
            <PlusIcon />
            Billboard
          </AddButton>
        )}
        {noNotification==="" && (
          <AddButton onClick={() => toggleEntityAddCard('notification')}>
            <PlusIcon />
            Notification
          </AddButton>
        )}
        {noBanner==="" && (
          <AddButton onClick={() => toggleEntityAddCard('banner')}>
            <PlusIcon />
            Banner
          </AddButton>
        )}
      </AddButtons>
      <Spacer />
    </>
  )
}

export default NovelProgrammatic
