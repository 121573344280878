import React, { useContext, useState, useEffect } from 'react'
import PrivatePage from '../../../components/PrivatePage'
import WritersBenefitContainer from './WritersBenefit.container'
import WritersBenefitUI from './WritersBenefit.UI'
import { AccountContext } from '../../../providers/accountProvider'
import {v4}  from 'uuid'
import { get } from '../../../lib/api'

const isWebIPFromIndia = ip => ['IN','BD','PK','NP','LK'].includes(ip)
const isWebIPFromUS = ip => ip === 'US'

const WritersBenefit = (props) => {
  const [language,setLanguage] = useState('hindi')
  useEffect(() => {
    if(!localStorage.getItem('uuid')){
      const uniqueId = v4().split('-').join('')
      localStorage.setItem('uuid', uniqueId)
    }
    get(`/experiments/wbp_page_experiment?device_id=${localStorage.getItem('uuid')}&is_novel=1`)
    .then((res) => setLanguage(res.language))
  },[])
  const { user } = useContext(AccountContext)
  const isUserIndian = user?.login_type !== 'email_login' || (user?.login_type === 'email_login' && user?.location === 'IN')
  if(props.location.pathname.includes('/author-welfare') && props.countryCode){
    // if(isWebIPFromUS(props.countryCode)) window.location.href = 'https://writer.pocketnovel.com/author-welfare-usa'
    // else if(!isWebIPFromUS(props.countryCode) && !isWebIPFromIndia(props.countryCode)) window.location.href = 'https://writer.pocketnovel.com/author-welfare-global'
    return <WritersBenefitUI language={language} {...props} />
  } 

  return (
    <PrivatePage>
      <WritersBenefitContainer {...props}>
        <WritersBenefitUI language={language} {...props} />
      </WritersBenefitContainer>
    </PrivatePage>
  )
}

export default WritersBenefit
