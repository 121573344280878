import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { Box } from '../../../designSystem'

const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #d5d6dd;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #a1a6bb;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #2d8cd6;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background: #ffffff;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`

const InputLabel = styled.p`
  display: inline-block;
  align-items: center;
  font-size: 1rem;
  margin-left: 0.5rem;
  color: var(--Text500);
  line-height: 1.5;
  font-weight: 500;
`
function ToggleSwitch({
  onToggleClick = () => {},
  disabled = false,
  ...props
}) {
  const [field] = useField(props)
  const { onChange, value } = field
  const newHandleChange = (val) => {
    if (!val) {
      onToggleClick()
    }
    onChange({ target: { name: props.name, value: val } })
  }

  return (
    <Box my=".6rem">
      <CheckBoxWrapper>
        <CheckBox
          id={props.name}
          type="checkbox"
          checked={field.value}
          onChange={() => newHandleChange(!value)}
          disabled={disabled}
        />
        <CheckBoxLabel htmlFor={props.name} />
        <InputLabel>{props.label}</InputLabel>
      </CheckBoxWrapper>
    </Box>
  )
}

export default ToggleSwitch
