import React from 'react'

const CircleCheck = props => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0003 17.3333C14.0504 17.3333 17.3337 14.0501 17.3337 10C17.3337 5.94992 14.0504 2.66667 10.0003 2.66667C5.95024 2.66667 2.66699 5.94992 2.66699 10C2.66699 14.0501 5.95024 17.3333 10.0003 17.3333ZM10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 10C18.3337 5.39763 14.6027 1.66667 10.0003 1.66667C5.39795 1.66667 1.66699 5.39763 1.66699 10C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z" fill="white" fillOpacity="0.98"/>
        <path d="M8.83111 13.3333C8.66203 13.3333 8.49277 13.2661 8.36379 13.1316L6.02663 10.6955C5.76847 10.4264 5.76847 9.9902 6.02663 9.72111C6.28479 9.45202 6.70329 9.45202 6.96145 9.72111L8.83111 11.6699L13.0379 7.28511C13.2961 7.01611 13.7147 7.01603 13.9727 7.28511C14.2309 7.5542 14.2309 7.99051 13.9727 8.2595L9.29853 13.1316C9.16945 13.266 9.00027 13.3333 8.83111 13.3333Z" fill="white" fillOpacity="0.98"/>
    </svg>
)

export default CircleCheck
