import React, {useState, useEffect,useContext} from 'react'
import styled from 'styled-components'
import { get, post } from '../../lib/api'
import WBPSteps from './WBPSteps'
import { getTokenAndUid } from '../../lib/utils'
import { BookCard, MonthlyBookCard, ProgramNotificationCard, WithdrawalCard, RequestWithdrawal,ProfessionalSignupCard } from './Cards'
import OpenedNotifications from './openedNotification'
import Tracker from './Tracker'
import { Wallet } from '../Income/Author/Income.UI'
import TNC from './termsAndConditions'
import WriterPopup from '../Shows/Novels/WriterPopup'
import { AccountContext } from '../../providers/accountProvider'
import { Box, Flex } from '../../designSystem'
import StepIndicator from '../../components/StepIndicator'
import ViewScreen from './ViewScreen'
const WidgetsContainer = styled.div`
  padding: 8px 120px;
  background-color: var(--Grey300);

  @media (max-width: 600px) {
    padding: 8px 0;
  }
`



const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const HomeUI = props => {
  const [WBPDetails, setWBPDetails] = useState(null)
  const [notificationsArr, setNotificationsArr] = useState([])
  const [walletDetails, setWalletDetails] = useState({})
  const [isMobile, setIsMobile] = useState(false)
  const [appliedNovelID, setAppliedNovelID] = useState(null)
  const [reappliedNovelProgramId, setReappliedNovelProgramId] = useState(null)
  const [showTNC, setShowTNC] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [openedNotification, setOpenedNotification] = useState(null)
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false)
  const [isTrackerShown, setIsTrackerShown] = useState(false)
  const [datesMissing, setDatesMissing] = useState([])
  const [hiddenIds, setHiddwnIds] = useState([])
  const [isPopupShown,setIsPopupShown] = useState(false)
  const { user } = useContext(AccountContext)
  const [assessmentStatus, setAssessmentStatus ] = useState(false)

  useEffect(() => {
    if (window.screen.width < 600) {
      setIsMobile(true)
    }
  }, [])
  useEffect(()=>{
    const uploadAssignmentStatus = user?.professional_creator_details?.professional_creator_details?.assessment_submission
    if(!uploadAssignmentStatus){
      setAssessmentStatus(false)
    }else { 
      setAssessmentStatus(true)
    }
  },[user])
  useEffect(() => { 
    // fetchCBPBooks()
    fetchNotifications()
    fetchWalletDetails()
  }, [])
  const reFetchBooksAndNotifications = () => {
    // fetchCBPBooks()
    fetchNotifications()
  }
  // const fetchCBPBooks = () => {
    // get('/content_api/book.creator_ben_prog/?is_novel=0').then(response => {
    //   setWBPDetails(response)
      // setWBPDetails(newBookRes)
    // })
    // .catch(() => {
    //   setWBPDetails(booksRes)
    //   // TODO: remove this after test
    // })
  // }
  const fetchNotifications = () => {
    let { uid } = getTokenAndUid()
    get(`/content_api/book.user_application/${uid}/`, {
      params: {
        view: 'home'
      }
    }).then(response => {
      setNotificationsArr(response.result)
    })
  }
  const fetchWalletDetails = () => {
    let { uid } = getTokenAndUid()
    get(`/content_api/book.user_wallet/${uid}/`).then(response => {
      setWalletDetails(response.result)
    })
  }
  const novelApply = book_id => {
    setIsPopupShown(true)
    return
    setAppliedNovelID(book_id)
  }
  const viewApplication = book_id => {
    setAppliedNovelID(book_id)
    setCurrentStep(2)
  }
  const resetSteps = () => {
    setCurrentStep(1)
    setAppliedNovelID(null)
    setOpenedNotification(null)
    setIsOpenWithdrawal(false)
    // fetchCBPBooks()
    fetchNotifications()
    fetchWalletDetails()
  }
  const handleSetTracker = dates => {
    setDatesMissing(dates)
    setIsTrackerShown(true)
  }
  const hideCardAfterView = id => {
    setHiddwnIds([...hiddenIds, id])
  }
  const handleViewStatusClick = (program, dontOpenCard, setViewed) => {
    if (dontOpenCard) {
      if (setViewed) {
        const { uid } = getTokenAndUid()
        post('/content_api/book.user_application/', { data: {
          uid,
          id: program.id,
          is_viewed: 1
        } })
        hideCardAfterView(program.id)
      }
      return
    }
    setOpenedNotification(program)
  }
  const handleWithdrawAmount = () => {
    if (walletDetails.balance && walletDetails.withdrawable) {
      setIsOpenWithdrawal(true)
    }
  }


  const viewComponents = [{ Assessment: 2 }, { Submission: 3 }, { Review: 4 }]
  let componentScreen = 'Assessment'
  if (assessmentStatus === false) {
    componentScreen = 'Assessment'
  } else if (user?.professional_creator_details?.status === 'approved' || user?.professional_creator_details?.status === 'rejected' ) {
    componentScreen = 'Review'
  } else {
    componentScreen = 'Submission'
  }
  const viewScreen = viewComponents
    .map((data, index) => Object.keys(data))
    .flat()
  const currentScreen = viewComponents.find(
    (data) => Object.keys(data)[0] === componentScreen
  )
  if(user?.professional_creator === 1 && user?.account_type === 'Author' ){
  return (  <>
    <Box my={ ['1rem', '1.5rem']} mx={['.8rem', '4rem']} bg={'var(--Dove)'} p={['1rem']} >
        <StepIndicator
          steps={viewScreen}
          current={Object.values(currentScreen)[0]}
          primaryColor={'#79ba10'}
          armLength={isMobile ? 85 : 150}
        />
     </Box>  
    <Box  p={['0rem', '1.8rem'] } my={'1.5rem'}  mx={['.8rem', '4rem']} bg={'var(--Dove)'} minHeight={'30rem'}>
      <ViewScreen screenName={componentScreen} setAssessmentStatus={setAssessmentStatus} reviewStatus = {user?.professional_creator_details?.status} />
    </Box>
    </>)
   }

   return null;
}
export default HomeUI
