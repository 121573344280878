import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Popup } from '../Popup'
import SpeakerIcon from '../Images/Megaphone'
import Loader from '../Loader'
import CheckedIcon from '../Images/Checked'

export const MessageHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
export const MessageSpan = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.8);
`
export const MessageBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`
const FeedbackDiv = styled.div`
  margin: 16px 0 40px;
`
const FeedbackLabel = styled.div`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 8px;
`
const FeedbackBody = styled.textarea`
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: var(--Grey300);
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  padding: 12px;
  width: 100%;
`
const RadioOption = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 14px;
  margin-right: 16px;
`
const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  border: ${({ isActive }) =>
    isActive ? 'solid 1px var(--TextDark500)' : 'solid 1px var(--Text500)'};
  padding: 3px;
  background-color: ${({ isActive }) => isActive ? 'var(--azure)' : 'var(--Dove)'};
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
`
const RadioNameSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--Text500);
`
const OptionsDiv = styled.div`
  display: flex;
`
const MessagePopup = ({selectOptionBeforeSave, selectOptions, confirmWarning, takeFeedback, show, onClose, onConfirm, renderIcon, cancelText, messageTitle = '', confirmText = 'Confirm', messageBody = ''}) => {
  const [isLoading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [optionSelected, setOptionSelected] = useState('')

  useEffect(() => {
    setLoading(false)
  }, [show])

  const handleConfirm = () => {
    setLoading(true)
    if (takeFeedback) {
      if (!feedback) {
        setLoading(false)
        return
      }
      onConfirm(feedback)
    } else if (selectOptionBeforeSave) {
      if (!optionSelected) {
        setLoading(false)
        return
      }
      onConfirm(optionSelected)
    } else {
      onConfirm()
    }
  }
  return (
    <Popup
      show={show}
      close={onClose}
      renderActions={
        <>
          {cancelText && <button
            className="action-button"
            type="button"
            onClick={onClose}
          >
            {cancelText}
          </button>}
          {!isLoading && <button
            className={`action-button primary-button${confirmWarning ? "-red" : ""}`}
            type="button"
            onClick={handleConfirm}
          >
            {confirmText}
          </button>}
          {isLoading && <button
            className={`action-button primary-button${confirmWarning ? "-red" : ""}`}
            type="button"
          >
            <Loader invertColor inline size="sm" />
          </button>}
        </>
      }
    >
      <MessageHeading>
        {renderIcon || <SpeakerIcon />}
        <MessageSpan>{messageTitle}</MessageSpan>
      </MessageHeading>
      <MessageBody>
        {messageBody}
      </MessageBody>
      {takeFeedback && <FeedbackDiv>
        <FeedbackLabel>
          Feedback
        </FeedbackLabel>
        <FeedbackBody
          onChange={e => setFeedback(e.target.value)}
          value={feedback}
          placeholder="Feedback about this novel"
          rows="5"
        />
      </FeedbackDiv>}
      {selectOptionBeforeSave && (
        <OptionsDiv>
          {selectOptions && selectOptions.map(option => {
            return (
              <RadioOption
                onClick={() => setOptionSelected(option.value)}
                key={option.name}
              >
                <RadioIcon isActive={optionSelected === option.value}>
                  <CheckedIcon />
                </RadioIcon>
                <RadioNameSpan>{option.name}</RadioNameSpan>
              </RadioOption>
            )
          })}
        </OptionsDiv>
      )}
    </Popup>
  )
}

export default MessagePopup
