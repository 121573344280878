import { useState, useEffect } from "react";
import { get } from '../api'
import { isAdminAccess } from '../../config'

function useCreatorProfile() {
  const [isDrawerShowing, setDrawerShowing] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [uid, setUid] = useState(null)

  useEffect(() => {
    if (!uid) return
    get('/user_api/user.get_creator_details?is_novel=0', {
      params: {
        with_stats: 'true',
        user_id: uid
      }
    }).then((res) => {
      setUserInfo(res.result)
      setDrawerShowing(true)
    })
  }, [uid]);

  const handleClickOnUser = user_id => {
    if (!isAdminAccess) return
    if (uid === user_id && Object.keys(userInfo).length) {
      setDrawerShowing(true)
    } else {
      setUid(user_id)
      setUserInfo({})
    }
  }

  return {
    isDrawerShowing,
    userInfo,
    onUserClick: handleClickOnUser,
    setDrawerShowing
  }
}

export default useCreatorProfile;