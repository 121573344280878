import React, { useContext, useState, useEffect } from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import { AccountContext } from '../../../../../providers/accountProvider'
import { get } from '../../../../../lib/api'
import Loader from "../../../../../components/Loader";

const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`;

const roleTabMapping = {
  'Author': ['Published', 'Unpublished','Concepts'],
}
const allTabs = ['Published', 'Unpublished','Concepts']

const AudiobooksContainer = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpblishedPresent , setIsUpblishedPresent] =  useState(false) 
  
  const { user: {account_type = 'Author'} } = useContext(AccountContext)
  const accTypeArr = account_type.split(',')
  const tabs = accTypeArr.reduce((acc, c) => [...new Set([...acc, ...roleTabMapping[c]])], [])

  if(!isUpblishedPresent ){
     tabs.splice(tabs.indexOf("Published"),1);
     tabs.splice(tabs.indexOf("Unpublished"),1);
  }

  
  const { location: { search } } = props
  const qParams = queryString.parse(search)

  const { tab: tabFromQueryParam = '' } = qParams

  const tabWithSpaces = tabFromQueryParam.replace(/-/g, ' ')
  const tab = allTabs.find(t => t.toLowerCase() === tabWithSpaces.toLowerCase())
  const active = tab || tabWithSpaces.includes("concepts") && 'Concepts' || qParams.filters && 'Concepts' || tabs[0];

  const fetchData = () => {
    setIsLoading(true);
    const conceptsListApi = "/content_api/concept_notes";
    get(conceptsListApi,{
      params: {
        view:'dashboard',
        // status: {},
        page_no:1
      },
    })
      .then((response) => {
        const { result } = response;        
        if (result.length) {
          let provedConcepts = result.find((data)=>data.status == 'approved')
          provedConcepts ? setIsUpblishedPresent(true): setIsUpblishedPresent(false)
          setIsLoading(false);
        } else {
          setIsUpblishedPresent(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    );
  }

  const child = React.Children.only(props.children)
  return (
    React.cloneElement(child, {
        ...props,
        tabs,
        active,
        userType: account_type,
    })
  )
}

export default AudiobooksContainer
