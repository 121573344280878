import React from 'react'
import styled from 'styled-components'
import { mileStoneNotices, rewardNotices } from '../constants'
import CardGraphic from './TrophyGuyIcon'

const Card = styled.div`
  background-color: var(--Dove);
  display: ${({ hide }) => hide ? 'none' : 'flex'};
  margin-top: 24px;
`
const Details = styled.div`
`
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 32px;
`
const ActionButton = styled.div`
  border-radius: 4px;
  border: solid 2px var(--LD10);
  cursor: pointer;
  padding: 14px;
  min-width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  display: table;
`
const ArtContainer = styled.div``

const NotificatonCard = ({ hidden = false, handleClick = () => null, isMilestone, action_type, no_button, button_text='Okay', toReplace1='{{novel_title}}', withReplace1='', toReplace2='', withReplace2='' }) => {
  const entityForConstants = isMilestone ? mileStoneNotices : rewardNotices
  return (
    <Card hide={hidden}>
      <Details>
        <Title>{entityForConstants[action_type].title.replace(toReplace1, withReplace1).replace(toReplace2, withReplace2)}</Title>
        <Description>{entityForConstants[action_type].message.replace(toReplace1, withReplace1).replace(toReplace2, withReplace2)}</Description>
        {!no_button && <ActionButton onClick={handleClick}>{button_text}</ActionButton>}
      </Details>
      {isMilestone && <ArtContainer>
        <CardGraphic />
      </ArtContainer>}
    </Card>
  )
}
export default NotificatonCard
