import React,{useContext} from 'react'
import AudiobooksContainer from './AudioWriter/Audiobooks.container'
import AudiobooksUI from './AudioWriter/Audiobooks.UI'
import ProAudiobooksContainer from './ProAudioWriter/ProAudiobooks.container'
import ProAudiobooksUI from './ProAudioWriter/ProAudiobooks.UI'
import { AccountContext } from "../../../../providers/accountProvider";

const AudiobooksList = props => {
  const { user } = useContext(AccountContext);

  if(user.professional_creator === undefined){
    return null
  }
  if(user.professional_creator === 1 && user.account_type === "Author" ){
    return (
      <ProAudiobooksContainer {...props}>
        <ProAudiobooksUI />
      </ProAudiobooksContainer>
    )
  }
  return (
    <AudiobooksContainer {...props}>
      <AudiobooksUI />
    </AudiobooksContainer>
  )
}

export default AudiobooksList
