import React, { useState } from 'react'
import { Popup } from '../../../../../../components/Popup'
import './drawer.css'
import styled from 'styled-components'
import ArrowRight from '../../../../../../components/Images/ChevronRight'
import { PrimaryButton, SecondaryButton } from '../../../../../../components/Button'
import WordsIcon from '../../../../../../components/Images/WordCount'

const Container = styled.div`
  padding: 16px;
`
const CloseDiv = styled.div`
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 48px;
  line-height: 0.5;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 4px;
`
const WordCount = styled.div`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`
const Options = styled.div`
  padding: 8px 0;
`
const HR = styled.div`
  background-color: var(--LD10);
  height: 1px;
  margin: 0 -16px;
`

const Revisions = styled.div`
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: var(--Text500);
  }
`
const ButtonsContainer = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`
const RightArrow=styled(ArrowRight)`
  fill:#0D1536;
`
const RightDrawerEditorControls = ({ close, title, wordCount, showRevisionsIcon, handleShowRevision, actionButtons: {primary, secondary}={}, ...props }) => {


  const handleClick = (e) => {
    close()
  }

  return (
    <Popup
      {...props}
      className="right-drawer-mobile-editor"
      close={handleClick}
    >
      <Container>
        <CloseDiv onClick={close}>˟</CloseDiv>
        <Title>{title}</Title>
        <WordCount>
        <WordsIcon />
        {wordCount}{' '}
        {wordCount > 1 ? 'words' : 'word'}
        </WordCount>
        {showRevisionsIcon && <HR/>}
        {showRevisionsIcon && <Options>
            <Revisions onClick={handleShowRevision}>
              <span>Revisions</span>
              <RightArrow />
            </Revisions>
          </Options>}
        <HR/>
        <ButtonsContainer>
          {secondary && secondary.show && <ButtonWrapper>
            <SecondaryButton
              active={secondary.enabled}
              onClick={secondary.onClick}
              fullWidth
            >
              {secondary.icon()}
              <span>{secondary.label}</span>
            </SecondaryButton>
          </ButtonWrapper>}
          {primary && primary.show && <ButtonWrapper>
            <PrimaryButton
              active={primary.enabled}
              onClick={primary.onClick}
              fullWidth
            >
              {primary.icon()}
              <span>{primary.label}</span>
            </PrimaryButton>
          </ButtonWrapper>}
        </ButtonsContainer>
      </Container>
    </Popup>
  )
}

export default RightDrawerEditorControls
