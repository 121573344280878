import React from 'react'
import styled from 'styled-components'
import CheckedIcon from '../../Images/RadioSelected'
import UnCheckedIcon from '../../Images/RadioUnselected'

const Container = styled.div`
  padding: 22px 16px;
  border-radius: 4px;
  border: solid 1px #e7e8eb;
  background-color: #f3f3f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const OptionsContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 16px;
  }
`
const Option = styled.span`
  margin-left: 4px;
`

const InlineRadioToggle = ({ value, options, label, onChange }) => {
  return (
    <Container>
      <Label>
        {label || 'select'}
      </Label>
      <OptionsContainer>
        {options && options.map(option => (
          <React.Fragment key={option.label}>
            {value === option.name && <CheckedIcon onClick={() => onChange(option.name)}/>}
            {value !== option.name && <UnCheckedIcon onClick={() => onChange(option.name)}/>}
            <Option onClick={() => onChange(option.name)}>
              {option.label}
            </Option>
          </React.Fragment>
        ))}
      </OptionsContainer>
    </Container>
  )
}

export default InlineRadioToggle
