import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TimeLine from '../timeLine'
import { monthlyRewardText, monthlyReward5000, monthlyReward8000 } from '../constants'
import { numFormatter, getTokenAndUid } from '../../../lib/utils'
import { withRouter } from 'react-router-dom'
import DownArrow from '../../../components/Images/ChevronDown'
import UpArrow from '../../../components/Images/ChevronUp'
import MonthlyRewardArt from './monthlyRewardCalendar'
import MilestoneArt from './TrophyGuyIcon'
import NotificationCard from './InnerNotificationCard'
import { post } from '../../../lib/api'
import moment from 'moment'
import { menuItemObj } from '../../../components/PrivatePage'
import { Box, Image } from '../../../designSystem'

const NotificationCardHolder = styled.div``

const BookAction = styled.div`
  cursor: pointer;
  min-width: 130px;
  padding: 14px;
  border-radius: 4px;
  background-color: ${({ enabled }) => enabled ? "var(--Azure500)" : "#e6e6e6"};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: ${({ enabled }) => enabled ? "rgba(255, 255, 255, 0.98)" : "var(--Text100)"};
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
  }
`
const BookCard = styled.div`
  margin-bottom: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    padding: 16px;
  }
`
const TopSection = styled.div`
  display: flex;
`
export const ThumbnailDiv = styled.div`
  width: 112px;
  min-width: 112px;
  height: 168px;
  border-radius: 4px;
  background-color: var(--Grey300);
  margin-right: 24px;

  img {
    width: 112px;
    height: 168px;
    border-radius: 4px;
  }
`
export const DetailsDiv = styled.div``
export const BookTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 8px;
`
const AuthorName = styled.div`
  font-size: 20px;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 24px;
`
const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 8px;
`
const StatsDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
`
const Metric = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 64px;

  &:last-child {
    margin-right: 0;
  }
`
const Count = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 4px;
`
const Label = styled.div`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  margin-top: auto;
`
export const CtaBox = styled.div`
  display: flex;
`
const DaysMissedDiv = styled(BookAction)`
  font-weight: bold;
  color: var(--Azure500);
  background-color: rgba(6, 138, 226, 0.1);
  margin-left: 16px;
`
const TimeLeftDiv = styled.div`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: var(--lime-500);
  margin-top: 6px;
`
const MonthlyRewardBox = styled.div``
const MilestoneRewardBox = styled.div``
const HR = styled.div`
  height: 2px;
  background-color: var(--Grey300);
  margin-top: 24px;
  margin-bottom: 12px;
`
const CollapsedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ExpandedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 24px 0 0 0;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 16px;
  }
`
const Title = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
`
const TitleExpanded = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--Text700);
`
const Toggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`
const TimelineDiv = styled.div`
  min-width: 670px;

  @media (max-width: 600px) {
    min-width: unset;
  }
`
const ArtDiv = styled.div`
  margin-right: -24px;
`
const HelpText = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--text-500);
  margin-bottom: 24px;
`

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const Stats = ({ views, words, chapters }) => (
  <StatsDiv>
    <Metric>
      <Count>{(views || 0).toLocaleString()}</Count>
      <Label>Views</Label>
    </Metric>
    <Metric>
      <Count>{(words || 0).toLocaleString()}</Count>
      <Label>Words</Label>
    </Metric>
    <Metric>
      <Count>{(chapters || 0).toLocaleString()}</Count>
      <Label>Chapters</Label>
    </Metric>
  </StatsDiv>
)

const Timer = () => {
  const [time, setTime] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  })
  function getTimeRemaining() {
    let now = moment()
    let timeDiff = moment(now).endOf('day') - now
    let dur = moment.duration(timeDiff)
    return {
      total: timeDiff,
      hours: dur.hours(),
      minutes: dur.minutes(),
      seconds: dur.seconds()
    };
  }
  
  function initializeClock() {
    function updateClock() {
      const t = getTimeRemaining();
      setTime({
        hours: ('0' + t.hours).slice(-2),
        minutes: ('0' + t.minutes).slice(-2),
        seconds: ('0' + t.seconds).slice(-2)
      })
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }
  
    updateClock();
    const timeinterval = setInterval(updateClock, 1000);
  }
  
  useEffect(() => {
    initializeClock()
  }, [])
  return (
    <TimeLeftDiv>Time left : {time.hours}:{time.minutes}:{time.seconds}</TimeLeftDiv>
  )
}

const MonthlyBookCard = ({ hiddenIds, hideCard, author_name, history, book, setTracker, monthly_reward_threshold, milestone_reward_threshold, handleViewStatusClick }) => {
  const [showMonthly, setShowMonthly] = useState(true)
  const [showMilestone, setShowMilestone] = useState(true)
  const isMobile = window.screen.width < 600

  const handleMonthlyCardOpen = (program, openCard) => {
    if (openCard) {
      handleViewStatusClick(program)
      return
    }
    const { uid } = getTokenAndUid()
    post('/content_api/book.user_application/', { data: {
      uid,
      id: program.id,
      is_viewed: 1
    } })
    hideCard(program.id)
  }

  const { rewards: { monthly: {cycle, word_count}= {}, milestone: {word_count: milestone_word_count}={} } = {} } = book
  const date = new Date(cycle)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  return (
    <BookCard>
      <TopSection>
        {/* <ThumbnailDiv>
          <img src={book.image_url} alt="novel thumbnail" />
        </ThumbnailDiv> */}
        <Image width={[40, 112]} height={[60, 168]} mr={[8, 24]} src={book.image_url} />
        
        <DetailsDiv>
          <BookTitle>{book.book_title}</BookTitle>
          <AuthorName>{author_name}</AuthorName>
          
          {!isMobile && <CtaBox>
            <BookAction enabled={1} onClick={() => history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)}>
              {book.daily_tracker.chapter_missed ? "Add Today’s Chapter" : "Add New Chapter"}
            </BookAction>
            {book.daily_tracker.total_days_missed !== undefined && <DaysMissedDiv onClick={() => setTracker(book.daily_tracker.dates_missed)} >Days Missed : {book.daily_tracker.total_days_missed}</DaysMissedDiv>}
          </CtaBox>}
          {!isMobile && book.daily_tracker.chapter_missed && <Timer />}
        </DetailsDiv>
        
      </TopSection>
      {isMobile && <CtaBox>
        <BookAction enabled={1} onClick={() => history.push(`${menuItemObj.novels}/id/${book.book_id}?tab=draft`)}>
          {book.daily_tracker.chapter_missed ? "Add Today’s Chapter" : "Add New Chapter"}
        </BookAction>
        {book.daily_tracker.total_days_missed !== undefined && <DaysMissedDiv onClick={() => setTracker(book.daily_tracker.dates_missed)} >Days Missed : {book.daily_tracker.total_days_missed}</DaysMissedDiv>}
      </CtaBox>}
      {isMobile && book.daily_tracker.chapter_missed && <Timer />}
      <Box variant="hr" my={24} />
      <Stats
        views={book.stats.total_views}
        chapters={book.chapters_count}
        words={book.word_count}
      />
      {book.rewards_tml && book.rewards_tml.filter(pg => pg.reward_details && pg.reward_details.reward_type === 'monthly_reward').map(program => {
        const isPending = program.action === 'pending'
        const isRejected = program.action === 'rejected'
        const isApproved = program.action === 'approved'
        let formattedTime = ""
        if (program.action_on === 'reward') {
          if (program.reward_details.props.end_date) {
            let date = new Date(program.reward_details.props.end_date)
            let year = date.getFullYear()
            let month = months[date.getMonth()]
            formattedTime = month + " " + year
          }
        }
        return (
          <NotificationCardHolder key={program.id}>
            <HR />
            <NotificationCard
              program={program}
              toReplace1="{{month}}"
              withReplace1={formattedTime}
              action_type={isPending ? 'pending' : isRejected ? 'rejected' : 'approved'}
              handleClick={() => handleMonthlyCardOpen(program, isPending)}
              button_text={isPending ? 'Reward Status' : 'Okay'}
              hidden={hiddenIds.includes(program.id)}
            />
          </NotificationCardHolder>
        )
      })
      }
      <MonthlyRewardBox>
        <HR />
        {!showMonthly && <CollapsedDiv>
          <Title> Monthly Reward</Title>
          <Toggle onClick={() => setShowMonthly(!showMonthly)}>Show <DownArrow /></Toggle>
        </CollapsedDiv>}
        {showMonthly && <ExpandedDiv>
          <TitleExpanded>{`Monthly Rewards Target - ${month} ${year}`}</TitleExpanded>
          <Toggle onClick={() => setShowMonthly(!showMonthly)}><UpArrow /></Toggle>
        </ExpandedDiv>}
        {showMonthly && <Content>
          {isMobile && <HelpText>
            Update atleast 1 chapter daily and 50,000 words monthly to achieve this reward
          </HelpText>}
          <TimelineDiv>
            {!isMobile && <HelpText>
            Update atleast 1 chapter daily and 50,000 words monthly to achieve this reward
            </HelpText>}
            <TimeLine
              word_count={word_count || 0}
              wordCountLabel={`${word_count || 0} Words`}
              mileStone1={monthly_reward_threshold[0][0]}
              mileStone2={monthly_reward_threshold[1][0]}
              mileStone1Label={`${numFormatter(monthly_reward_threshold[0][0])} Words`}
              mileStone2Label={`${numFormatter(monthly_reward_threshold[1][0])} Words`}
              mileStone1Award={monthly_reward_threshold[0][1]}
              mileStone2Award={monthly_reward_threshold[1][1]}
            />
          </TimelineDiv>
          <ArtDiv><MonthlyRewardArt /></ArtDiv>
        </Content>}
      </MonthlyRewardBox>

      {!(book.rewards_tml && book.rewards_tml.find(pg => pg.reward_details && pg.reward_details.reward_type === 'milestone')) && book.rewards.milestone && <MilestoneRewardBox>
        <HR />
        {!showMilestone && <CollapsedDiv>
          <Title> Milestone Reward</Title>
          <Toggle onClick={() => setShowMilestone(!showMilestone)}>Show <DownArrow /></Toggle>
        </CollapsedDiv>}
        {showMilestone && <ExpandedDiv>
          <TitleExpanded>Milestone Reward Target</TitleExpanded>
          <Toggle onClick={() => setShowMilestone(!showMilestone)}><UpArrow /></Toggle>
        </ExpandedDiv>}
        {showMilestone && <Content>
          <TimelineDiv>
            <TimeLine
              word_count={milestone_word_count || 0}
              wordCountLabel={`${milestone_word_count || 0} Total Words`}
              mileStone1={milestone_reward_threshold[0][0]}
              mileStone2={milestone_reward_threshold[1][0]}
              mileStone1Label={`Milestone 1 - ${numFormatter(milestone_reward_threshold[0][0])} Words`}
              mileStone2Label={`Milestone 2 - ${numFormatter(milestone_reward_threshold[1][0])} Words`}
              mileStone1Award={milestone_reward_threshold[0][1]}
              mileStone2Award={milestone_reward_threshold[1][1]}
            />
          </TimelineDiv>
          <ArtDiv><MilestoneArt /></ArtDiv>
        </Content>}
      </MilestoneRewardBox>}

      {book.rewards_tml && book.rewards_tml.filter(pg => pg.reward_details && pg.reward_details.reward_type === 'milestone').map(program => {
        return (
          <NotificationCardHolder key={program.id}>
            <HR />
            <NotificationCard
              isMilestone
              program={program}
              action_type={'approved'}
              button_text={'Okay'}
              toReplace1="{{word_count}}"
              withReplace1={numFormatter(program.reward_details.props.word_limit)}
              toReplace2="{{milestone_no}}"
              withReplace2={program.reward_details.milestone_no}
              handleClick={() => handleMonthlyCardOpen(program, false)}
              hidden={hiddenIds.includes(program.id)}
          />
          </NotificationCardHolder>
        )
      })
      }
    </BookCard>
  )
}

export default withRouter(MonthlyBookCard)
