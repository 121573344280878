import React from 'react'
import { useField } from 'formik';
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'

const Container = styled.div`
  width: 100%;
`
const ErrorSpan = styled.span``
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const StyledInput = styled.input`
  outline: none;
  resize: none;
  width: 100%;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'};
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
  padding: 12px 16px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'auto'};

  &:focus {
    border: solid 2px var(--Azure500);
    padding: 11px 15px;
    background-color: var(--Dove);
  }
`

const Input = ({ label, infoTitle, asTextArea, rowCount, ...props }) => {
  const [field, meta] = useField(props)
  const { onChange } = field
  const newHandleChange = e => {
    onChange(e)
  }
  return (
    <Container>
      {label && <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan><InfoIcon title={infoTitle}/>
      </LabelContainer>}
      {
        !asTextArea ? (
            <StyledInput {...{...field, onChange: newHandleChange}} {...props} />
          ) : (
            <StyledInput as='textarea' rows={rowCount || 5} {...{...field, onChange: newHandleChange}} {...props} />
          )}
    </Container>
  );
};

export default Input
