import React, { useState } from 'react'
import { numFormatter, monthsShort } from '../../../lib/utils'
import { get } from '../../../lib/api'
import Loader from '../../../components/Loader'
import { useInfiniteScroll } from '../../../lib/customHooks'
import { Text, Box } from '../../../designSystem'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const getMileStoneText = (entity) => {
  let words = numFormatter(entity.reward_details.props.word_limit)
  let baseText = words + ' Milestone Award'
  if (entity.action === 'approved') return baseText + ' - Added To Wallet'
  return baseText
}
const getWalletText = (entity) => {
  let amount = entity.txn_data.amount
  let baseText = 'Rs ' + amount + ' Withdrawal Request - '
  if (entity.action === 'pending') return baseText + 'Under Review'
  if (entity.action === 'rejected') return baseText + 'Rejected'
  if (entity.action === 'accepted') return baseText + 'Accepted'
  if (entity.action === 'approved') return baseText + 'Approved'
  return baseText
}
const getProgramText = (entity) => {
  let baseText = 'Writer Benefit Program - '
  if (entity.action === 'pending') return baseText + 'Pending Approval'
  if (entity.action === 'rejected') return baseText + 'Rejected'
  if (entity.action === 'ongoing') return baseText + 'Accepted'
  if (entity.action === 'updated') return baseText + 'Updated'
  if (entity.action === 'approved') return baseText + 'Approved'
  return baseText
}
const getRewardText = (entity) => {
  let monthYear = ''
  const date = new Date(entity.reward_details.props.end_date)
  const year = date.getFullYear()
  const month = monthsShort[date.getMonth()]
  monthYear = month + ' ' + year
  let baseText = `Monthly reward for ${monthYear} - `
  if (entity.action === 'pending') return baseText + 'Pending Approval'
  if (entity.action === 'accepted') return baseText + 'Accepted'
  if (entity.action === 'approved') return baseText + 'Added to Wallet'
  if (entity.action === 'rejected') return baseText + 'Rejected'
  return baseText
}

const getTextFromFeedEntity = (entity) => {
  switch (entity.action_on) {
    case 'program': {
      return getProgramText(entity)
    }
    case 'reward': {
      if (entity.reward_details.reward_type === 'milestone')
        return getMileStoneText(entity)
      return getRewardText(entity)
    }
    case 'wallet': {
      return getWalletText(entity)
    }
    default:
      return ''
  }
}

const ApplicationHistory = ({ onViewMoreClick }) => {
  const [feedArr, setFeedArr] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)

  // useEffect(() => {
  // fetchFeedArr()
  // }, [])

  // const fetchFeedArr = () => {
  //   let { uid } = getTokenAndUid()
  //   get(`/content_api/book.user_application/${uid}/`).then((response) => {
  //     const { result, next_url } = response
  //     setFeedArr(result)
  //     setNextUrl(next_url)
  //   })
  // }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result, next_url } = response
        setFeedArr((prevState) => prevState.concat(result))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  return (
    <React.Fragment>
      {feedArr.map((entity) => {
        return (
          <Box
            variant="flex"
            flexDirection="column"
            bg="surface.foreground"
            px={18}
            py={16}
            my={4}
            key={entity.id}
          >
            <Text variant="title" fontWeight="r">
              {getTextFromFeedEntity(entity)}
            </Text>
            <Text variant="subtext">
              {dayjs(entity.create_time).format('DD/MM/YYYY')}
            </Text>
          </Box>
        )
      })}
      {isFetching && <Loader />}
    </React.Fragment>
  )
}

export default ApplicationHistory
