import React from 'react'

const Undo = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
        <path fill="#0D1536" d="M4.16 4.21c.147-.147.368-.191.56-.112.192.08.317.268.317.476V6.44h8.986c2.836 0 5.144 2.318 5.144 5.167 0 2.85-2.308 5.168-5.144 5.168H8.846c-.283 0-.512-.23-.512-.515 0-.284.23-.515.512-.515h5.177c2.271 0 4.119-1.856 4.119-4.138 0-2.281-1.848-4.137-4.119-4.137H5.037v1.835c0 .209-.125.397-.317.476-.192.08-.412.036-.56-.112L1.817 7.303c-.199-.201-.199-.526 0-.727L4.161 4.21z"/>
    </svg>


)

export default Undo
