import React, { Component } from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
  cursor: pointer;
  display: flex;
  padding: 24px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
  @media (max-width: 600px) {
    padding: 16px;
    margin-bottom: 2px;
    border-radius: unset;
  }
`
class Card extends Component {
  render () {
    const { children, ...restProps } = this.props
    return (
      <StyledCard {...restProps} >
        {children}
      </StyledCard>
    )
  }
}

export {
  Card
}
