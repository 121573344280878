import React from 'react'
import CheckedIcon from '../../Images/Checked'
import styled from 'styled-components'


const RadioLabel = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ invalid }) => invalid ? 'var(--Punch500)' : 'var(--Text700)'};
`
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
`
const RadioOption = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 8px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  background-color: ${({ active }) =>
    active ? ' var(--Azure500)' : 'var(--Grey300)'};
  @media(max-width:600px){
    padding: 13px 10px;
  }
`
const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  border: ${({ active }) =>
    active ? 'solid 1px var(--TextDark500)' : 'solid 1px var(--Text500)'};
  padding: 3px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
`
const RadioNameSpan = styled.span`
  inline-size: max-content;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ active }) => (active ? 'var(--TextDark700)' : 'var(--Text500)')};

  @media(max-width:600px){
    font-size: 14px;
  }
`
const LabelErrorSpan = styled.span`
  font-style: italic;
  font-size: 12px;
`

const RadioSelect = ({ label, error, options, value, onChange, allowMultiple }) => {

  const handleChange = (val) => {
    if (!allowMultiple) {
      onChange(val)
      return
    }
    let currValues = value.split(',').filter(Boolean)
    if (currValues.includes(val)) {
      currValues = currValues.filter((v) => v !== val)
    } else {
      currValues.push(val)
    }
    const newValue = currValues.join(',')
    onChange(newValue)    
  }
  return (
    <>
      {label && <RadioLabel invalid={error}>
        {label}{' '}
        {error && <LabelErrorSpan> - required</LabelErrorSpan>}
      </RadioLabel>}
      <OptionsContainer>
        {options.map(option => {
          const optionSelected = value.includes(option.name) ? 'true' : undefined
          return (
            <RadioOption
              key={option.label}
              onClick={() => handleChange(option.name)}
              active={optionSelected}
            >
              <RadioIcon active={optionSelected}>
                <CheckedIcon active={optionSelected} />
              </RadioIcon>
              <RadioNameSpan active={optionSelected}>{option.label}</RadioNameSpan>
            </RadioOption>
          )
        })}
      </OptionsContainer>
    </>
  )
}
export default RadioSelect
