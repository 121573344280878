import React from 'react'
import styled from 'styled-components'
import RejectionCard from './Cards/rejectionCard'
import WelcomeCard from './Cards/welcomeCard'
import GreetingNotification from './Cards/greetingNotification'
import { get as localGet } from '../../lib/storage'
import { applicationUpdate } from './constants'

const Container = styled.div`
  // background-color: var(--Grey300);
  padding: 44px 180px;
  min-height: 100vh;

  @media (max-width: 600px) {
    padding: 0;
  }
`
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


const OpenedNotifications = ({ program, handleReset }) => {
  const name = localGet('name') || ''
  const { action_on, action } = program
  const isActionOnProgram = action_on === 'program'
  const isActionOnReward = action_on === 'reward'
  const isRejectedAction = action === 'rejected'
  const isUpdatededAction = action === 'updated'
  const isPendingAction = action === 'pending'
  const isAcceptedAction = action === 'accepted'
  const isApprovedAction = action === 'approved'
  const isActionOnWallet = action_on === 'wallet'
  const isMonthlyReward = isActionOnReward && program.reward_details.reward_type === 'monthly_reward'
  const isMilestoneReward = isActionOnReward && program.reward_details.reward_type === 'milestone'

  let amount = 5000
  let monthYear = ''
  let book_title = ''
  if (isActionOnReward && isMonthlyReward) {
    const date = new Date(program.reward_details.props.end_date)
    const year = date.getFullYear()
    const month = months[date.getMonth()]
    monthYear = month + ' ' + year
  }

  if (isActionOnProgram) {
    const { book_details } = program
    book_title = book_details.book_title
  }
 
  if ((isActionOnProgram) && isPendingAction) {
    return (
      <Container>
        <WelcomeCard
          action_type="applied"
          toReplace="{{novel_title}}"
          withReplace={program.book_details.book_title}
          handleReset={handleReset}
        />
      </Container>
    )
  }

  if ((isActionOnProgram || isActionOnReward) && (isRejectedAction || isUpdatededAction)) {
    return (
      <Container>
        <RejectionCard
          id={program.id}
          action_type={isActionOnReward ? 'monthly_rejected' : isRejectedAction ? 'application_rejected' : 'application_updated'}
          toReplace="{{name}}"
          withReplace={name}
          isRejected={isRejectedAction}
          isUpdated={isUpdatededAction}
          handleReset={handleReset}
          customDescription={program.description}
        />
      </Container>
    )
  }

  if ((isActionOnProgram) && (isAcceptedAction || isApprovedAction)) {
    return (
      <Container>
        <GreetingNotification
          id={program.id}
          action_type={isAcceptedAction ? 'application_accepted' : 'application_approved'}
          toReplace1="{{amount}}"
          withReplace1={amount}
          toReplace2="{{book_title}}"
          withReplace2={book_title}
          handleReset={handleReset}
          isRejected={isRejectedAction}
          isApproved={isApprovedAction}
          goldenTag={applicationUpdate['application_accepted_golden_tag']}
          notes={applicationUpdate['application_accepted_notes']}
          showViews={{
            showGoldenTag: !isApprovedAction,
            showUpsell: true,
            showNotes: !isApprovedAction,
            showHR: true
          }}
        />
      </Container>
    )
  }

  if (isMonthlyReward && (isPendingAction || isApprovedAction)) {
    return (
      <Container>
        <GreetingNotification
          id={program.id}
          action_type={isPendingAction ? 'monthly_accepted' : 'monthly_approved'}
          toReplace1="{{amount}}"
          withReplace1={amount}
          toReplace2="{{month}}"
          withReplace2={monthYear}
          handleReset={handleReset}
          isApproved={isApprovedAction}
          isRejected={isRejectedAction}
          goldenTag={applicationUpdate['monthly_accepted_golden_tag']}
          notes={applicationUpdate['monthly_accepted_notes']}
          showViews={{
            showGoldenTag: !isApprovedAction,
            showUpsell: false,
            showNotes: !isApprovedAction,
            showHR: !isApprovedAction
          }}
        />
      </Container>
    )
  }

  if (isMilestoneReward && (isApprovedAction)) {
    return (
      <Container>
        <GreetingNotification
          id={program.id}
          action_type={'milestone_approved'}
          handleReset={handleReset}
          isApproved={isApprovedAction}
          isRejected={isRejectedAction}
          goldenTag={applicationUpdate['monthly_accepted_golden_tag']}
          notes={applicationUpdate['monthly_accepted_notes']}
          showViews={{
            showGoldenTag: false,
            showUpsell: false,
            showNotes: false,
            showHR: false
          }}
        />
      </Container>
    )
  }

  if (isActionOnWallet) {
    return (
      <Container>
        <GreetingNotification
          id={program.id}
          action_type={isApprovedAction ? 'wallet_approved' : 'wallet_accepted'}
          handleReset={handleReset}
          isApproved={isApprovedAction}
          isRejected={isRejectedAction}
          notes={applicationUpdate[isApprovedAction ? 'wallet_approved_notes' : 'wallet_accepted_notes']}
          showViews={{
            showGoldenTag: !isApprovedAction,
            showUpsell: false,
            showNotes: true,
            showHR: false
          }}
        />
      </Container>
    )
  }

  return null
}

export default OpenedNotifications
