import dayjs from 'dayjs'

export function getDate(date) {
  if (!date) {
    return "01 Jan 1999"
  }
  date = dayjs(date).locale('en-IN')
  let newDate = dayjs(date).format('DD MMM YYYY')
  return newDate
}

export function getTime (time) {
  if (!time) {
    return "00:01 AM"
  }
  time = dayjs(time).locale('en-IN')
  let newTime = dayjs(time).format('hh:mm A')
  return newTime
}

export function formDataCalculateForAws(response) {
  const preSignRes = response?.result[0]
  const awsFields = preSignRes.fields
  const prefix = preSignRes.url
  const suffix = preSignRes.s3_unique_key
  const formData = new FormData()
  formData.append('key', awsFields.key)
  formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
  formData.append('x-amz-security-token', awsFields['x-amz-security-token'])
  formData.append('policy', awsFields.policy)
  formData.append('signature', awsFields.signature)
  return { formData, prefix, suffix }
}

export function paramsCal(tags, file_dir, chapter_id) {
  const params = {
    tags,
    file_dir,
    file_extension: 'txt',
    chapter_id,
  }
  return params
}

export function getHashFromFileLink(fileUrl) {
  // getting hash value from original file link
  if (!fileUrl) return null
  const str = fileUrl.split('/')?.at(-1)
  return str?.replace('.txt', '')
}

export function removeAllSpanTag(str) {
  // getting hash value from original file link, thst is aws key
  const cleanText = str.replace(/<span[^>]*>/g, '').replace(/<\/span>/g, '')
  return cleanText
}

export const ChapterFileGetPreSignedUrl = '/upload/get_presigned_url'
export const CommentsFilGetPreSignedUrl = '/upload/get_presigned_url'
export const CommentsFileCdn = 'https://d23lcr4kgl9sbx.cloudfront.net'
