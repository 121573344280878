import React from 'react'

const StudioLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="162" height="40" fill="none" viewBox="0 0 162 40" {...props}>
    <path fill="#E51A4D" d="M56.948 7.086c-4.952 0-9.434 2.715-9.434 7.53 0 4.995 3.325 6.769 9 8.18 3.073.76 4.013 1.883 4.013 3.186 0 1.376-1.374 2.317-3.181 2.317-1.952 0-3.868-1.014-4.302-3.367h-6.506c.398 5.611 4.663 8.145 10.989 8.145 5.024 0 9.795-2.534 9.795-7.53 0-3.836-2.24-6.588-7.735-7.89-4.12-.978-5.278-1.956-5.278-3.548 0-1.52 1.013-2.245 2.495-2.245 2.132 0 3.217 1.376 3.506 3.44h6.506c-.542-4.634-3.29-8.218-9.868-8.218zM77.695 17.44h4.266v-4.055h-4.266V8.389H71.19v4.996h-3.072v4.054h3.072v8.073c0 1.085 0 1.99.072 3.113.217 2.968 1.663 4.452 6.47 4.452 1.374 0 2.567-.108 3.832-.326v-4.054c-3.072.145-3.687-.29-3.832-2.244-.036-.507-.036-1.231-.036-1.883v-7.13zM103.59 13.24h-6.506v10.932c0 2.57-1.23 4.489-3.29 4.489s-2.927-1.086-3.144-2.932c-.109-.978-.145-2.027-.145-2.643V13.24H84v11.294c0 1.557.036 2.679.18 3.656.434 2.643 2.856 4.887 6.832 4.887 1.772 0 3.145-.398 4.338-1.122l1.735-2.643v3.258h6.506V13.24zM127.759 5.964h-6.506v9.774l-1.265-2.064c-.976-.579-2.856-.94-4.446-.94-4.952 0-9.145 3.728-9.145 10.606 0 5.719 3.253 9.737 8.494 9.737 1.952 0 3.832-.398 4.916-1.194l1.446-2.136v2.823h6.506V5.964zM117.132 28.66c-2.277 0-4.084-2.027-4.084-5.647 0-3.946 1.735-5.864 4.265-5.864 1.952 0 3.94 1.303 3.94 5.176v.832c0 4.055-1.735 5.503-4.121 5.503zM134.914 5.385c-2.024 0-3.361 1.23-3.361 3.185s1.337 3.186 3.361 3.186c2.025 0 3.362-1.231 3.362-3.186 0-1.954-1.337-3.185-3.362-3.185zm3.254 27.185V13.24h-6.507v19.33h6.507zM151.364 12.733c-6.326 0-10.374 3.946-10.374 10.172 0 6.19 4.048 10.172 10.374 10.172 6.326 0 10.374-3.982 10.374-10.172 0-6.226-4.048-10.172-10.374-10.172zm0 15.928c-2.313 0-3.723-1.919-3.723-5.756 0-3.873 1.41-5.756 3.723-5.756 2.313 0 3.723 1.883 3.723 5.756 0 3.837-1.41 5.756-3.723 5.756zM0 5.872C0 2.629 2.628 0 5.87 0h28.25c3.242 0 5.87 2.629 5.87 5.872v28.256c0 3.243-2.628 5.872-5.87 5.872H5.87C2.628 40 0 37.371 0 34.128V5.872z"/>
    <path fill="#fff" fillRule="evenodd" d="M19.936 6.886c6.232 0 11.283 5.045 11.283 11.269 0 6.223-5.052 11.269-11.283 11.269-1.789 0-3.486-.416-4.99-1.163l-6.293 4.76V17.43h.023c.378-5.893 5.276-10.545 11.26-10.545z" clipRule="evenodd"/>
    <path fill="#E51A4D" fillRule="evenodd" d="M16.612 14.627h.432l3.694-2.35c.054-.03.123-.015.154.032.031.054.016.123-.03.154l-3.402 2.164h5.77c.6 0 1.087.485 1.087 1.086v4.244c0 .601-.486 1.086-1.088 1.086h-6.61c-.6 0-1.087-.485-1.087-1.086v-4.244c-.007-.6.486-1.086 1.08-1.086z" clipRule="evenodd"/>
    <path fill="#fff" d="M22.64 19.959c.463-.464.463-1.215 0-1.678-.464-.463-1.216-.463-1.68 0-.464.463-.464 1.214 0 1.678.464.463 1.216.463 1.68 0zM19.203 20.312c.294 0 .532-.238.532-.531 0-.294-.238-.532-.532-.532-.294 0-.532.238-.532.532 0 .293.238.531.532.531z"/>
    <path fill="#fff" fillRule="evenodd" d="M17.437 20.304c.293 0 .533-.239.533-.531 0-.293-.24-.532-.533-.532-.293 0-.532.239-.532.532 0 .292.24.531.532.531z" clipRule="evenodd"/>
    <path fill="#fff" d="M20.645 15.798H16.79v1.132h3.856v-1.132zM22.936 15.798h-1.82v1.132h1.82v-1.132z"/>
    <path fill="#E51A4D" fillRule="evenodd" d="M23.777 14.065c.563.031 1.056.424 1.218.963.016.062-.015.123-.077.139-.062.015-.123-.016-.139-.077-.139-.455-.54-.778-1.018-.81-.061 0-.108-.053-.108-.114.008-.062.062-.108.124-.1z" clipRule="evenodd"/>
    <path fill="#E51A4D" fillRule="evenodd" d="M23.769 13.58h.216c.817 0 1.48.662 1.488 1.479 0 .062-.046.108-.108.108-.061 0-.108-.046-.108-.108 0-.693-.57-1.256-1.264-1.256h-.216c-.062 0-.108-.046-.108-.107-.008-.07.038-.116.1-.116z" clipRule="evenodd"/>
    <path fill="#E51A4D" fillRule="evenodd" d="M23.769 13.071h.162c1.08 0 1.967.886 1.967 1.965v.023c0 .061-.047.107-.108.107-.062 0-.108-.046-.108-.107v-.023c0-.955-.787-1.741-1.743-1.741h-.162c-.062 0-.108-.046-.108-.108-.008-.07.038-.116.1-.116z" clipRule="evenodd"/>
  </svg>
)
export default StudioLogo
