import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import GirlWriterIcon from "./GirlWriter.svg";
import { Formik, Form, FieldArray } from "formik";
import { Box } from "../../designSystem";
import * as Yup from "yup";
import {
  FloatingInput,
  Input,
  CheckboxInput,
  Upload,
  InlineRadioToggle,
} from "../FormikInputs";
import { get, post } from "../../lib/api";
import { withRouter } from "react-router-dom";
import CrossIcon from "../Images/CrossIcon";
import { saveSession, getTokenAndUid } from "../../lib/utils";
import GenericError from "../GenericError";
import { AccountContext } from "../../providers/accountProvider";
import { FileUploadComponent } from "../../pages/Home/WBPSteps/step1";
import PencilIcon from "../Images/PencilIcon";
import { isValidPhoneNumber } from 'libphonenumber-js';
const StyledCrossIcon = styled(CrossIcon)`
  fill: var(--DarkGrey900);
  height: 9px;
  width: 9px;
  cursor: pointer;
`;
const MainDiv = styled.div`
  background: linear-gradient(
    106deg,
    var(--storm-text-storm-700) -1%,
    #6d7ab1 108%
  );
`;
const WriterLabelDiv = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${GirlWriterIcon});
  background-position: right;
  background-repeat: no-repeat;
`;
const OuterBlockDiv = styled.div`
  background-color: #fdfdfd;
  padding: 24px;
  margin-top: 3px;
`;

const WriterDetailDiv = styled.div`
  padding: 25px;
  background-color: var(--grey-300);
`;
const DetailsTitle = styled.div`
  margin: 0px 0px 8px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--Text700);
`;
const DetailLabel = styled.label`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: var(--storm-text-storm-500);
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 2px;
  margin: 58px 0 24px;
  background-color: var(--Grey900);
`;
const ContinueButton = styled.button`
  cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  padding: 16px 32px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
  margin-top: 16px;
`;
const OtherDiv = styled.div`
  display: flex;
  width: 30%;
  margin-top: 16px;
  input {
    width: 400px;
  }
`;
const DetailDiv = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const PublishContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const AddMoreMainDiv=styled.div`
display:flex;
justify-content:flex-end;
`
const AddMore = styled.div`
  padding: 12px 20px;
  border-radius: 4px;
  border: solid 2px var(--silver-300);
  color: var(--silver-500);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
const BackDiv = styled.div`
  padding: 12px 20px;
  border-radius: 4px;
  border: ${({ uid }) => (uid ? "none" : "solid 2px var(--silver-300)")};
  color: var(--silver-500);
  font-size: 14px;
  font-weight: bold;
  float: right;
  cursor: pointer;
`;
const RequiredField = styled.span`
  color: red;
  font-size: 19px;
`;
const FooterDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InvalidDetailsDiv = styled.div`
  color: red;
`;
const BasicDetailsMainDiv = styled.div`
  background-color: #fdfdfd;
  padding: 24px;
  margin-top: 3px;
`;
const BasicDetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 200px;
`;
const ProfileImageDiv = styled.div`
  height: 112px;
  width: 112px;
  border-radius: 50%;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--LD5);
  align-self: center;
  img {
    height: 112px;
    width: 112px;
    border-radius: 50%;
  }
  .Upload input {
    display: none;
  }

  @media (max-width: 600px) {
    margin-right: 30%;
  }
`;
const EditIconDiv = styled.div`
  position: absolute;
  top: -75px;
  right: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: var(--Dove);
  }
`;
const EditProfileDiv = styled.div`
  padding: 24px;
  font-size: 20px;
  font-weight: bold;
  background-color: #fdfdfd;
`;
const BasicDetailContainer = styled.div``;
function EditWriterProfile({ history, userDetails}) {
  const [apiError, setApiError] = useState({
    show: "",
    message: "",
  });
  const { user, onUpdateUser } = useContext(AccountContext);
  const [errorMessage, setErrorMessage] = useState("");
  //const [userDetails, setUserDetails] = useState();
  const { uid, token } = getTokenAndUid();
  useEffect(() => {
 //   fetchData();
    // if (uid) {
    //   get("/user_api/user.get_creator_details?is_novel=0").then((res) => {
    //     setUserDetails(res.result.professional_creator_details);
    //   });
    // } else setUserDetails('{}');
  }, []);

 
  const addValues = (object, otherKey) => {
    let value = [];
    for (let [key] of Object.entries(object)) {
      if (key !== "other" && key !== otherKey) {
        if (object[key] !== "") {
          value.push(key);
        }
      }
    }
    return value;
  };
  const addLanguages = (object, otherKey) => {
    let value = "";
    for (let [key] of Object.entries(object)) {
      if (key !== "other" && key !== otherKey) {
        if (object[key] !== "") {
          if (value === "") value += key;
          else value += "," + key;
        }
      }
    }
    return value;
  };
  const getObject = (type, value, typeObject, other) => {
    value?.forEach((elm) => {
      typeObject[elm] = `${type}.${elm}`
    })
    if (other !== '') {
      typeObject.other = `${type}.` + `other`
    }
    return typeObject;
  };
  const removeEmptyKey = (object) => {
    for (let [key] of Object.entries(object)) {
      if (object[key] === "") {
        delete object[key];
      }
    }
    return object;
  };
  const ImgUploadCTA = ({ handleReUpload }) => (
    <EditIconDiv onClick={handleReUpload}>
      <PencilIcon />
    </EditIconDiv>
  );
  const emailValidation = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const panNumberValidation = (panCardNum) => {
    if(panCardNum === "") return true;
    const re = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return re.test(panCardNum);
  };
  return (
    <>
      {/* <MainDiv>
        <WriterLabelDiv>
          <ProfessionalWriterDiv>
            <ProfessionalWriterProgramLabel>
              Professional Writer<br></br>
              Program
            </ProfessionalWriterProgramLabel>
            <ApplicationFormLabel>Application Form</ApplicationFormLabel>
          </ProfessionalWriterDiv>
        </WriterLabelDiv>
      </MainDiv> */}
      <WriterDetailDiv>
        {userDetails && (
          <Formik
            initialValues={{
              writer_association_number:
                userDetails?.professional_creator_details
                  ?.writer_association_number || '',
              year_of_exp:
                userDetails?.professional_creator_details?.years_of_exp || '',
              about_you:
                (userDetails &&
                  userDetails.professional_creator_details
                    ?.professional_creator_details.about_you) ||
                "",
              genres: (userDetails !== "{}" &&
                getObject(
                  "genres",
                  userDetails.professional_creator_details
                    ?.professional_creator_details.genres,
                  {
                    romantic: "",
                    horror: "",
                    comedy: "",
                    drama: "",
                    fantasy: "",
                    action: "",
                    thriller: "",
                    other: "",
                  },
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_genres
                )) || {
                romantic: "",
                horror: "",
                comedy: "",
                drama: "",
                fantasy: "",
                action: "",
                thriller: "",
                other: "",
              },
              othergenres:
                (userDetails !== "{}" &&
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_genres) ||
                "",
              language: (userDetails !== "{}" &&
                getObject(
                  "language",
                  userDetails.professional_creator_details
                    ?.professional_creator_details.languages,
                  {
                    hindi: "",
                    english: "",
                    tamil: "",
                    telegu: "",
                    malayalam: "",
                    kannada:"",
                    other: "",
                  },
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_languages
                )) || {
                hindi: "",
                english: "",
                tamil: "",
                telegu: "",
                malayalam: "",
                other: "",
              },
              otherLanguage:
                (userDetails !== "{}" &&
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_languages) ||
                "",
              workExperience: (userDetails !== "{}" &&
                getObject(
                  "workExperience",
                  userDetails.professional_creator_details
                    ?.professional_creator_details.work_exp,
                  {
                    webSeries: "",
                    tvSerials: "",
                    films: "",
                    newsPaperMagazines: "",
                    audioPlatform: "",
                    other: "",
                  },
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_exp
                )) || {
                webSeries: "",
                tvSerials: "",
                films: "",
                newsPaperMagazines: "",
                audioPlatform: "",
                other: "",
              },
              otherExperience:
                (userDetails !== "{}" &&
                  userDetails.professional_creator_details
                    ?.professional_creator_details.other_exp) ||
                "",
              onlinePublishedContent: (userDetails !== "{}" &&
                userDetails.professional_creator_details
                  ?.professional_creator_details.online_published_content) || [
                {
                  title: "",
                  link: "",
                },
              ],
              address_1: userDetails["address_1"] || "",
              address_2: userDetails["address_2"] || "",
              pin_code: userDetails["pin_code"] || "",
              city: userDetails["city"] || "",
              state: userDetails["state"] || "",
              account_name: userDetails["account_name"] || "",
              bank_name: userDetails["bank_name"] || "",
              account_no: userDetails["account_no"] || "",
              ifsc_code: userDetails["ifsc_code"] || "",
              pan_card_no: userDetails["pan_card_no"] || "",
              cancelled_cheque_url: userDetails["cancelled_cheque_url"] || "",
              pan_card_url: userDetails["pan_card_url"] || "",
              contract_url: userDetails["contract_url"] || "",
              fullname: userDetails["fullname"] || "",
              phone_number: userDetails["phone_number"] || "",
              gender: userDetails["gender"] || "",
              image_url: userDetails["image_url"],
              email: userDetails["email"] || "",
            }}
            validationSchema={Yup.object({
              account_name : Yup.string().when(['cancelled_cheque_url','pan_card_url','contract_url'],
                  (cancelled_cheque_url,pan_card_url,contract_url,schema) => (pan_card_url || cancelled_cheque_url || contract_url) ? schema.required(['account_name','account_no','ifsc_code']) :  schema)
            })}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                account_type: "Publisher",
                user_id: uid,
                languages: addLanguages(values.language, "otherLanguage"),
                professional_creator: 1,
                writer_association_number: values.writer_association_number,
                years_of_exp: values.year_of_exp,
                professional_creator_details: {
                  online_published_content: values.onlinePublishedContent,
                  genres: addValues(values.genres, "othergenres"),
                  other_genres: values.othergenres,
                  work_exp: addValues(values.workExperience, "otherExperience"),
                  other_exp: values.otherExperience,
                  languages: addValues(values.language, "otherLanguage"),
                  other_languages: values.otherLanguage,
                  about_you: values.about_you,
                },
              };
              const addressPostData = {
                address_1: values.address_1,
                address_2: values.address_2,
                pin_code: values.pin_code,
                city: values.city,
                state: values.state,
                address_id: userDetails.account_id,
                uid: uid,
              };
              const userPostData = removeEmptyKey({
                fullname: values.fullname,
                email: values.email,
                phone_number: values.phone_number,
                gender: values.gender,
                image_url: values.image_url,
              });
              const bankPostData = {
                account_name: values.account_name,
                bank_name: values.bank_name,
                account_no: values.account_no,
                ifsc_code: values.ifsc_code,
                pan_card_no: values.pan_card_no,
                cancelled_cheque_url: values.cancelled_cheque_url,
                pan_card_url: values.pan_card_url,
                account_id: userDetails.account_id,
                contract_url: values.contract_url,
                uid: uid,
              };
              if ((!isValidPhoneNumber(values.phone_number))){
                setErrorMessage("Invalid Phone Number");
                return 
              }
              if (!emailValidation(values.email)){
                setErrorMessage("Invalid email");
                return 
              }
              if (!panNumberValidation(values.pan_card_no)){
                setErrorMessage("Invalid Pan Card Number");
                return 
              }
              if (
                data.years_of_exp === "" ||
                (data.professional_creator_details.genres.length === 0 &&
                  data.professional_creator_details.other_genres === "") ||
                (data.professional_creator_details.languages.length === 0 &&
                  data.professional_creator_details.other_languages === "") ||
                (data.professional_creator_details.work_exp.length === 0 &&
                  data.professional_creator_details.other_exp === "")
              ) {
                setErrorMessage("Please Enter Required Fields");
                return;
              } else setErrorMessage("");
              const allPromises = [
                post('/user_api/user.update?is_novel=0', {
                  data: userPostData,
                }),
                post('/user_api/user.create_creator_account?is_novel=0', {
                  data: data,
                  headers: {
                    uid: uid,
                    "access-token": token,
                  },
                }),
                post('/user_api/user_addresses/?is_novel=0', {
                  data: addressPostData,
                }),
                post('/user_api/user_bank_accounts/?is_novel=0', {
                  data: bankPostData,
                }),
              ]
              Promise.all(allPromises)
                .then(() => {
                  get("/user_api/user.get_creator_details?is_novel=0").then((res) => {
                    onUpdateUser(res.result);
                  });
                  // history.location.info &&
                  //   saveSession({
                  //     ...history.location.info,
                  //     name: history.location.info.fullname,
                  //   });
                  history.push("/home");
                })
                .catch((error) => {
                  //setIsRegisterProcessing(false)
                  console.log(error);
                  setApiError({
                    show: true,
                    message:
                      "Something went wrong while setting up your account.",
                  });
                });
            }}
          >
            {({ values = {}, isSubmitting,dirty,submitCount,errors }) => (
              <Form>
                <GenericError
                  show={apiError.show}
                  onClose={() => setApiError({ ...apiError, show: false })}
                  message={apiError.message}
                />
                <EditProfileDiv>Edit Profile</EditProfileDiv>
                <BasicDetailsMainDiv>
                  <BasicDetailsDiv>
                    <BasicDetailContainer>
                      <DetailsTitle>Basic Details</DetailsTitle>
                      <DetailLabel>{userDetails.creator_alias}</DetailLabel>
                    </BasicDetailContainer>
                    <ProfileImageDiv>
                      <img src={values["image_url"]} />
                      <Upload
                        name="image_url"
                        // renderComp={ImageUploadComponent}
                        renderComp={ImgUploadCTA}
                        imageValidator={(width, height) => width === height}
                        dimensionErrorMsg="The image does not follow the dimensions of the thumbnail. Please try with a different image."
                        fileTypeTags="image"
                        extraParams={{ title: "thumbnail" }}
                      />
                      {/* <EditIconDiv><PencilIcon /></EditIconDiv> */}
                    </ProfileImageDiv>
                  </BasicDetailsDiv>

                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="fullname"
                        label="Your Name"
                        size="lg"
                      />
                    </Box>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="phone_number"
                        label="Mobile Number"
                        size="lg"
                        required
                      />
                    </Box>
                  </Box>
                  <Box variant="flex" flexDirection={['column', 'row']}>
                    <Box width={['100%', '60%']} mb={16}>
                      <InlineRadioToggle
                        name="gender"
                        label="Gender"
                        options={[
                          {
                            label: "Male",
                            name: "male",
                          },
                          {
                            label: "Female",
                            name: "female",
                          },
                          {
                            label: 'Others',
                            name: 'other',
                          },
                          {
                            label: "Don't wish to answer",
                            name: 'unrevealed',
                          },
                          {
                            label: 'Others',
                            name: 'other',
                          },
                          {
                            label: "Don't wish to answer",
                            name: 'unrevealed',
                          },
                        ]}
                      />
                    </Box>
                    <Box width={['100%', '38%']} mt={[16, 0]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="email"
                        label="Email ID"
                        size="lg"
                        required
                      />
                    </Box>
                  </Box>
                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Input
                      type="text"
                      asTextArea
                      name="about_you"
                      placeholder="About You"
                    />
                  </Box>
                  {/* <DetailsTitle>
                  Languages<RequiredField>*</RequiredField>
                </DetailsTitle> */}
                  <DetailLabel>
                    Language proficiencies<RequiredField>*</RequiredField>
                  </DetailLabel>
                  <DetailDiv>
                    <CheckboxInput
                      name="language.hindi"
                      label="Hindi"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="language.english"
                      label="English"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="language.tamil"
                      label="Tamil"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="language.telegu"
                      label="Telegu"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="language.malayalam"
                      label="Malayalam"
                    ></CheckboxInput>
                     <CheckboxInput
                    name="language.kannada"
                    label="Kannada"
                  ></CheckboxInput>
                    <CheckboxInput
                      name="language.other"
                      label="Other"
                    ></CheckboxInput>
                  </DetailDiv>
                  {values.language.other !== "" && (
                    <OtherDiv>
                      <FloatingInput
                        type="text"
                        name="otherLanguage"
                        label="Other Languages"
                        size="lg"
                        border
                      />
                    </OtherDiv>
                  )}

                  {/* <DetailsTitle>
                  Genres<RequiredField>*</RequiredField>
                </DetailsTitle> */}
                  <DetailLabel>
                    Genres<RequiredField>*</RequiredField>
                  </DetailLabel>
                  <DetailDiv>
                    <CheckboxInput
                      name="genres.romantic"
                      label="Romantic"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.horror"
                      label="Horror"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.comedy"
                      label="Comedy"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.drama"
                      label="Drama"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.fantasy"
                      label="Fantasy"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.action"
                      label="Action"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.thriller"
                      label="Thriller"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="genres.other"
                      label="Other"
                    ></CheckboxInput>
                  </DetailDiv>
                  {values.genres.other !== "" && (
                    <OtherDiv>
                      <FloatingInput
                        type="text"
                        name="othergenres"
                        label="Other genres"
                        size="lg"
                        border
                      />
                    </OtherDiv>
                  )}
                </BasicDetailsMainDiv>
                <OuterBlockDiv>
                  <DetailLabel>Professional Details</DetailLabel>

                  <Box
                    variant="flex"
                    flexDirection={["column", "row"]}
                    mt={16}
                    mb={16}
                  >
                    <Box width={["100%", "49%"]}>
                      <FloatingInput
                        type="text"
                        name="writer_association_number"
                        label="Referral code (Mobile number)"
                        size="lg"
                      />
                    </Box>
                    <Box width={["100%", "49%"]}>
                      <FloatingInput
                        type="number"
                        name="year_of_exp"
                        label="Experience In Years"
                        size="lg"
                        required
                      />
                    </Box>
                  </Box>

                  {/* <DetailsTitle>
                  Work Experience<RequiredField>*</RequiredField>
                </DetailsTitle> */}
                  <DetailLabel>
                    Your past experience in the industries<RequiredField>*</RequiredField>
                  </DetailLabel>
                  <DetailDiv>
                    <CheckboxInput
                      name="workExperience.webSeries"
                      label="Web Series"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="workExperience.tvSerials"
                      label="TV Serials"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="workExperience.films"
                      label="Films"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="workExperience.newsPaperMagazines"
                      label="News Paper/Magazines"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="workExperience.audioPlatform"
                      label="Audio Platform"
                    ></CheckboxInput>
                    <CheckboxInput
                      name="workExperience.other"
                      label="Other"
                    ></CheckboxInput>
                  </DetailDiv>
                  {values.workExperience.other !== "" && (
                    <OtherDiv>
                      <FloatingInput
                        type="text"
                        name="otherExperience"
                        label="Other Experiences"
                        size="lg"
                        border
                      />
                    </OtherDiv>
                  )}
                  {/* <DetailsTitle>Online Published Content</DetailsTitle> */}
                  <DetailLabel>
                    Share link for your published content online
                  </DetailLabel>
                  <FieldArray
                    name="onlinePublishedContent"
                    render={(arrayHelpers) => (
                      <>
                        {values.onlinePublishedContent.map((elm, index) => (
                          <>
                            <PublishContentDiv key={index}>
                              {index > 0 && (
                                <StyledCrossIcon
                                  onClick={() => arrayHelpers.remove(index)}
                                ></StyledCrossIcon>
                              )}
                              <Box
                                variant="flex"
                                flexDirection={["column", "row"]}
                                mt={16}
                                mb={16}
                              >
                                <Box width={["100%", "49%"]}>
                                  <FloatingInput
                                    type="text"
                                    name={`onlinePublishedContent[${index}].title`}
                                    label="Title"
                                    size="lg"
                                  />
                                </Box>
                                <Box width={["100%", "49%"]}>
                                  <FloatingInput
                                    type="text"
                                    name={`onlinePublishedContent[${index}].link`}
                                    label="Link (optional)"
                                    size="lg"
                                  />
                                </Box>
                              </Box>
                            </PublishContentDiv>
                          </>
                        ))}
                        <AddMoreMainDiv>
                        <AddMore
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ title: "", link: "" })
                          }
                        >
                          Add More
                        </AddMore>
                        </AddMoreMainDiv>
                      </>
                    )}
                  />
                </OuterBlockDiv>
                <OuterBlockDiv>
                  <DetailsTitle>Bank Details</DetailsTitle>
                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="account_name"
                        label="Full Name ( Account Owner )"
                        size="lg"
                        hasError={submitCount > 0 && errors['account_name']}
                      />
                    </Box>

                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="bank_name"
                        label="Bank Name"
                        size="lg"
                      />
                    </Box>
                  </Box>

                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="account_no"
                        label="Account Number"
                        size="lg"
                      />
                    </Box>

                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="ifsc_code"
                        label="IFSC Code"
                        size="lg"
                      />
                    </Box>
                  </Box>

                  <Box variant="flex" flexDirection={['column','row']}>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="pan_card_no"
                        label="Pan Card Number"
                        size="lg"
                      />
                    </Box>
                  </Box>
                </OuterBlockDiv>
                <OuterBlockDiv>
                  <DetailsTitle>Documents</DetailsTitle>
                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "49%"]}>
                      <Upload
                        label="Cancelled Cheqeue"
                        name="cancelled_cheque_url"
                        privateDownload
                        hideInputOnValue={true}
                        cloud_dir_file={getTokenAndUid().uid}
                        saveWithoutCDN={1}
                        showProgress={true}
                        placeholder="Drop your doc file here to upload or click to select"
                        renderComp={FileUploadComponent}
                        fileTypeTags="user_doc"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                        extraParams={{
                          title: "Bank-Document",
                        }}
                      />
                      <Upload
                        label="Signed Contract"
                        name="contract_url"
                        privateDownload
                        cloud_dir_file={getTokenAndUid().uid}
                        saveWithoutCDN={1}
                        hideInputOnValue={true}
                        showProgress={true}
                        placeholder="Drop your doc file here to upload or click to select"
                        renderComp={FileUploadComponent}
                        fileTypeTags="user_doc"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                        extraParams={{
                          title: "Pan Card",
                        }}
                      />
                    </Box>
                    <Box width={["100%", "49%"]}>
                      <Upload
                        label="Pan Card"
                        name="pan_card_url"
                        privateDownload
                        cloud_dir_file={getTokenAndUid().uid}
                        saveWithoutCDN={1}
                        hideInputOnValue={true}
                        showProgress={true}
                        placeholder="Drop your doc file here to upload or click to select"
                        renderComp={FileUploadComponent}
                        fileTypeTags="user_doc"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc, image/jpeg, image/jpg, image/png, application/pdf"
                        extraParams={{
                          title: "Pan Card",
                        }}
                      />
                    </Box>
                  </Box>
                </OuterBlockDiv>
                <OuterBlockDiv>
                  <DetailsTitle>Mailing Address</DetailsTitle>
                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="address_1"
                        label="House No, Apt No, Building No"
                        size="lg"
                      />
                    </Box>

                    <Box width={["100%", "49%"]} mb={16}>
                      <FloatingInput
                        type="text"
                        name="address_2"
                        label="Address Line 2"
                        size="lg"
                      />
                    </Box>
                  </Box>

                  <Box variant="flex" flexDirection={["column", "row"]}>
                    <Box width={["100%", "32%"]} mb={16}>
                      <FloatingInput
                        type="number"
                        name="pin_code"
                        label="Pincode"
                        size="lg"
                      />
                    </Box>

                    <Box variant="flex" width={["100%", "67%"]}>
                      <Box width="49%" mb={16}>
                        <FloatingInput
                          type="text"
                          name="city"
                          label="City"
                          size="lg"
                        />
                      </Box>

                      <Box width="49%" mb={16}>
                        <FloatingInput
                          type="text"
                          name="state"
                          label="State"
                          size="lg"
                        />
                      </Box>
                    </Box>
                  </Box>
                </OuterBlockDiv>
                {/* <HorizontalBar></HorizontalBar> */}
                {errorMessage !== "" && (
                  <InvalidDetailsDiv>{errorMessage}</InvalidDetailsDiv>
                )}
                <FooterDiv>
                  <BackDiv uid={uid} onClick={() => history.goBack()}>
                    {!uid && "Back"}
                  </BackDiv>
                  <ContinueButton type="submit" disabled={!dirty}>
                    {uid && "Save Profile"}
                    {!uid && "Submit Application"}
                  </ContinueButton>
                </FooterDiv>
              </Form>
            )}
          </Formik>
        )}
      </WriterDetailDiv>
    </>
  );
}

export default withRouter(EditWriterProfile);
