import React, { useState, useEffect, useCallback } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { get, put } from '../../../lib/api'
import InfoIcon from '../../InfoIcon'
import MessagePopup from '../../MessagePopup'
import debounce from 'lodash.debounce'
const Container = styled.div``
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 99999999;
`
const Option = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);

  &:hover {
    background-color: var(--Grey500);
  }
`
const Name = styled.div``
const Email = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.4);
`
const Input = styled.input`
  width: 100%;
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
  margin-top: 3px;
  min-height: 36px;
  border-radius: 4px;
  border: ${({ hasError }) =>
    hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'};
  padding: 12px 16px;
  &:focus {
    outline: none;
    background-color: var(--Dove);
    border: solid 2px var(--Azure500);
    padding: 11px 15px;
  }
`
const DropDownHolder = styled.div`
  position: relative;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const UserImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 16px;
`
const UserSearch = ({
  placeholder,
  label,
  hasError,
  infoTitle,
  authorSearch,
  clearFieldOnChange,
  ...props
}) => {
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [field, meta] = useField(props)
  const { value = '', onChange } = field
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [authorChangePermission, setAuthorChangePermission] = useState(false)
  const [newAuthorInfo, setNewAuthorInfo] = useState({
    name: '',
    id: ''
  })

  const newHandleChange = (option) => {
    toggleDropDownOpen(false)
    if (props.authorChangeOnSelect) {
      setAuthorChangePermission(true)
      setNewAuthorInfo({ name: option.title, id: option.entity_id })
    } 
    else {
      setInputValue(option.title)
      onChange({ target: { name: props.name, value: option.entity_id } })
    }
  }

  const setValue = (value) => {
    fetchUsersByName(value)
    if (clearFieldOnChange)
      onChange({ target: { name: props.name, value: '' } })
  }
  const handleInputChange = (e) => {
    // toggleDropDownOpen(true)
    setInputValue(e.target.value)
    debounceHandleChange(e.target.value)
  }
  const debounceHandleChange = useCallback(
    debounce((value) => setValue(value), 500),
    []
  )

  const fetchUsersByName = (name) => {
    if (!name) return
    get('/search/user.search', {
      params: {
        query: name,
        view: 'cms',
        filter: authorSearch ? 'author' : 'vo',
      },
    }).then((response) => {
      const { users } = response
      setOptions(users)
      toggleDropDownOpen(true)
    })
  }

  const handleClick = () => {
    toggleDropDownOpen(false)
  }

  const handleAuthorChange = () => {
    let data = {
      created_by: newAuthorInfo.id,
      view: 'cms',
    }
    if (props.book_id) data['book_id'] = props.book_id
    if (props.show_id) data['show_id'] = props.show_id
    put('/content_api/book.update_entity_author', {
      data,
    })
      .then(() => {
        setAuthorChangePermission(false)
        onChange({ target: { name: props.name, value: newAuthorInfo.id } })
        setInputValue(newAuthorInfo.name)
      })
      .catch(() => {
        setAuthorChangePermission(false)
      })
  }

  useEffect(() => {
    if (value) {
      get(`/user_api/user.get_creator_details`, {
        params: {
          user_id: value,
          is_novel: 0,
        }
      }).then((res) => {
        setInputValue(res.result.fullname || '')
      })
    }
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  return (
    <>
      <MessagePopup
        show={authorChangePermission}
        onClose={() => setAuthorChangePermission(false)}
        onConfirm={handleAuthorChange}
        cancelText="Cancel"
        confirmText={'Change Author'}
        messageTitle="Confirm Author changes?"
        messageBody={
          'Are you sure you want to change the author of this entity?'
        }
      />
      <Container>
        <LabelContainer>
          <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan>
          <InfoIcon title={infoTitle} />
        </LabelContainer>
        <Input
          onChange={handleInputChange}
          value={inputValue}
          hasError={hasError}
          placeholder={placeholder}
        />
        <DropDownHolder>
          <OptionsDropDown isOpen={isOpen}>
            {options.map((option) => (
              <Option
                key={'user-search-' + option.entity_id}
                onClick={() => newHandleChange(option)}
              >
                {option.image_url && <UserImg src={option.image_url} />}
                <Name>{option.title}</Name>
                {/* <Email>
              {option.email}
            </Email> */}
              </Option>
            ))}
          </OptionsDropDown>
        </DropDownHolder>
      </Container>
    </>
  )
}

export default UserSearch
