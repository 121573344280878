import React from 'react'
import PrivatePage from './../../../components/PrivatePage'
import List from './List'
import CreateNew from './CreateNew'
import queryString from 'query-string'

const Novels = (props) => {
  const { process } = queryString.parse(props.location.search)
  const Comp = process === 'create-new' ? CreateNew : List
  return (
    <PrivatePage>
      <Comp />
    </PrivatePage>
  )
}

export default Novels
