import React, { useState, useEffect, useRef } from 'react'
import { Revisions } from '../../../../../components/TextEditor/revisions'
import { Preview } from '../../../../../components/TextEditor/preview'
import PrivatePage, { menuItemObj } from '../../../../../components/PrivatePage'
import Tooltip from '../../../../../components/Tooltip'
// import Loader from '../../../../../components/Loader'
import { get, post } from '../../../../../lib/api'
import Axios from 'axios'
import GenericError from '../../../../../components/GenericError'
import queryString from 'query-string'
import Loader from '../../../../../components/Loader'
import SaveIcon from '../../../../../components/Images/Save'
import { getAudiobookWordCountLimit } from './helpers'
import { isAdminAccess } from '../../../../../config'
import { Provider } from '../../../../../context/editorContext'
import { BackButton as Back } from '../../../../../components/WithSubHeaderBG'
import CircleCheck from '../../../../../components/Images/CircleCheck'
import History from '../../../../../components/Images/History'
import Undo from '../../../../../components/Images/Undo'
import Redo from '../../../../../components/Images/Redo'
import WordCount from '../../../../../components/Images/WordCount'
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../components/Button'
import {
  Tool,
  ToolInfo,
  ToolContainer,
} from '../../../../../components/TextEditor/styles'
import styled from 'styled-components'
import {
  TextEditor,
  getContent,
  isContentChanged,
  isUndoEmpty,
  isRedoEmpty,
  handleRedo as onRedo,
  handleUndo as onUndo,
} from '../../../../../components/TextEditor'
import Vellip from '../../../../../components/Images/Vellip'
import MobileEditorOptions from './MobileEditorOptions'
import {
  // ChapterFileGetPreSignedUrl,
  CommentsFileCdn,
  CommentsFilGetPreSignedUrl,
  formDataCalculateForAws,
  paramsCal,
  removeAllSpanTag,
} from '../../../../../components/TextEditor/helper'

const ControlsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`
const LeftDiv = styled.div`
  padding: 8px 0 8px 48px;

  @media (max-width: 600px) {
    padding: 8px 0 8px 0px;
  }
`
const RightDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
const TopDiv = styled.div`
  display: flex;
  align-items: center;
  svg: {
    margin-right: 8px;
  }
`
const BottomDiv = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`
const TitleInput = styled.input`
  width: 390px;
  padding: 8px;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--Text700);
  border-radius: 4px;
  border: 2px solid var(--Grey300);

  @media (max-width: 600px) {
    width: 150px;
  }
`
const PrefixInput = styled.input`
  width: 64px;
  padding: 8px;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--Text700);
  border-radius: 4px;
  border: 2px solid var(--Grey300);
  margin-right: 4px;
`

const BackButton = styled(Back)`
  margin-right: 8px;
`
const PrefixStr = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
  margin-right: 4px;
`
const ButtonWrapper = styled.div`
  margin-left: 16px;
`
const VellipContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background-color: #ebecf1;
`

export const EditorControls = ({
  title: titleParam,
  regex,
  onBack,
  onTitleChange,
  wordCount,
  undoActive,
  redoActive,
  handleUndo,
  handleRedo,
  showRevisionsIcon,
  handleShowRevision,
  actionButtons: { primary, secondary } = {},
}) => {
  const [isMobileOptionsShowing, setIsMobileOptionsShowing] = useState(false)
  let title = titleParam
  let prefix = ''
  let prefixStart = ''
  let prefixEnd = ''
  let prefixChapterNumber = ''
  if (regex) {
    const result = titleParam.match(regex)
    if (result) {
      prefix = result[0]
      title = titleParam.replace(regex, '')
      let ResultStrArr = prefix.match(/\s\d+\s/)
      let numberMatchStr = ResultStrArr[0]
      prefixChapterNumber = numberMatchStr.trim()
      let prefixArr = prefix.split(prefixChapterNumber)
      prefixStart = prefixArr[0]
      prefixEnd = prefixArr[1]
    }
  }
  const handleTitleChange = (title) => {
    onTitleChange(prefix + title)
  }
  const handlePrefixChange = (e) => {
    const number = e.target.value
    if (!number) return
    const newPrefix = prefixStart + number + prefixEnd
    onTitleChange(newPrefix + title)
  }
  const isMobile = window.screen.width < 600
  return (
    <>
      <MobileEditorOptions
        show={isMobileOptionsShowing}
        close={() => setIsMobileOptionsShowing(false)}
        title={titleParam}
        wordCount={wordCount}
        actionButtons={{ primary, secondary }}
        showRevisionsIcon={showRevisionsIcon}
        handleShowRevision={handleShowRevision}
      />
      <ControlsDiv>
        <LeftDiv>
          <TopDiv>
            <BackButton onClick={onBack} />
            {!isAdminAccess && (
              <>
                {prefix && <PrefixStr>{prefix}</PrefixStr>}
                <TitleInput
                  value={title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                />
              </>
            )}
          </TopDiv>
          <BottomDiv>
            <ToolContainer>
              <Tool>
                <WordCount />
                <ToolInfo>
                  {wordCount} {wordCount > 1 ? 'words' : 'word'}
                </ToolInfo>
              </Tool>
              {showRevisionsIcon && (
                <Tool onClick={handleShowRevision}>
                  <History />
                  <ToolInfo>Revisions</ToolInfo>
                </Tool>
              )}
              <Tool onClick={handleUndo}>
                <Undo fillOpacity={undoActive ? 0.65 : 0.3} />
                <ToolInfo
                  style={{
                    color: undoActive
                      ? 'rgba(13, 21, 54, 0.65)'
                      : 'rgba(13, 21, 54, 0.3)',
                  }}
                >
                  Undo
                </ToolInfo>
              </Tool>
              <Tool onClick={handleRedo}>
                <Redo fillOpacity={redoActive ? 0.65 : 0.3} />
                <ToolInfo
                  style={{
                    color: redoActive
                      ? 'rgba(13, 21, 54, 0.65)'
                      : 'rgba(13, 21, 54, 0.3)',
                  }}
                >
                  Redo
                </ToolInfo>
              </Tool>
            </ToolContainer>
          </BottomDiv>
        </LeftDiv>
        <RightDiv>
          {!isMobile && secondary && secondary.show && (
            <Tooltip title={secondary.tooltip || ''} arrow>
              <SecondaryButton
                active={secondary.enabled}
                onClick={secondary.onClick}
              >
                {secondary.icon()}
                <span>{secondary.label}</span>
              </SecondaryButton>
            </Tooltip>
          )}

          {!isMobile && primary && primary.show && (
            <ButtonWrapper>
              <Tooltip title={primary.tooltip || ''} arrow>
                <PrimaryButton
                  active={primary.enabled}
                  onClick={primary.onClick}
                >
                  {primary.icon()}
                  <span>{primary.label}</span>
                </PrimaryButton>
              </Tooltip>
            </ButtonWrapper>
          )}
          {isMobile && (
            <VellipContainer>
              <Vellip onClick={() => setIsMobileOptionsShowing(true)} />
            </VellipContainer>
          )}
        </RightDiv>
      </ControlsDiv>
    </>
  )
}

const AddEditChapter = (props) => {
  const {
    history,
    match: {
      params: { id },
    },
    location: { search },
  } = props
  const qParams = queryString.parse(search)
  const {
    chapter_id,
    title: titleFromQpram,
    create_new,
    language,
    book_type,
  } = qParams
  const [content, setContent] = useState('')
  const [initialContent, setInitialContent] = useState('')
  const [wordCount, setWordCount] = useState(0)
  const [editorState, setEditorState] = useState(null)
  const [title, setTitle] = useState(titleFromQpram || '')
  const [isLoading, setLoading] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [showPreview, toggleShowPreview] = useState(false)
  const [showVersion, toggleShowVersion] = useState(false)
  const [episodeTag, setEpisodeTag] = useState(false)
  const [editorToolbarChange, setEditorToolbarChange] = useState(false)
  const [commentedFileUrl, setCommentedFileUrl] = useState('')
  const [originalFileUrl, setOriginalFileUrl] = useState('')

  useEffect(() => {
    if (create_new === 'true') return
    if (!chapter_id) return
    setLoading(true)
    get(`/content_api/book.get_chapter_comment_file?chapter_id=${chapter_id}`)
      .then((commentedFileResponse) => {
        const { doc_details } = commentedFileResponse
        setCommentedFileUrl(doc_details?.file_url || '')
      })
      .catch((err) => {
        throw err
      })
    get('/content_api/book.get_chapter_details', {
      params: {
        chapter_id,
        book_id: id,
        view: isAdminAccess ? 'cms' : 'dashboard',
        is_novel: 0,
      },
    })
      .then((response) => {
        const { result } = response
        if (result?.status !== 'approved') {
          setEpisodeTag(true)
        }
        const { file_url, chapter_title } = result
        setOriginalFileUrl(file_url || '')
        if (!title) setTitle(chapter_title)
        if (file_url) {
          Axios.get(file_url)
            .then((response) => {
              setContent(response.data)
              setInitialContent(response.data)
            })
            .catch((err) => {
              throw err
            })
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        throw err
      })
  }, [])

  useEffect(() => {
    if (title && content) setLoading(false)
  }, [title, content])

  const createPreview = (content) => {
    return {
      __html: content,
    }
  }

  const updateState = (editor) => {
    setEditorState(editor)
  }

  const updateTitle = (title) => {
    setTitle(title)
  }

  const updateWordCount = (count) => {
    setWordCount(count)
  }

  const handleChapterSave = () => {
    const isValid =
      isContentChanged(editorState) &&
      wordCount >= getAudiobookWordCountLimit(language, book_type) &&
      editorToolbarChange
    if (!isValid) return

    const content = getContent(editorState)
    const commentedInnerHtml = content
    const originalFileData = removeAllSpanTag(content)

    setLoading(true)
    const ContentDoc = document.implementation.createHTMLDocument('Content')
    ContentDoc.body.innerHTML = originalFileData
    // const allHighlights = ContentDoc.getElementsByClassName('highlight')
    // const len = allHighlights.length
    // for (let i = 0; i < len; i++) {
    //   allHighlights[0].outerHTML = allHighlights[0].innerHTML
    // }
    const originalData = ContentDoc.body.innerHTML

    // create a text file
    const file = new Blob([originalData], {
      type: 'text/plain;charset=utf-8',
    })

    let url = ''

    // upload to s3
    let params = {
      title,
      tags: 'file',
      file_dir: id,
      image_extension: 'txt',
    }
    let getPreSignedUrl = '/upload/get_presigned_url'
    get(getPreSignedUrl, { params }).then((response) => {
      const imageUrl = response.result[0]
      const awsFields = imageUrl.fields
      const prefix = imageUrl.url
      const suffix = imageUrl.s3_unique_key
      const hashValueForCommentsFile = suffix
      const formData = new FormData()

      formData.append('key', awsFields.key)
      formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
      formData.append('x-amz-security-token', awsFields['x-amz-security-token'])
      formData.append('policy', awsFields.policy)
      formData.append('signature', awsFields.signature)
      formData.append('file', file)
      const cdn = 'https://d31b0xt3oaqqjh.cloudfront.net'
      post(prefix, { data: formData })
        .then((response) => {
          url = `${cdn}/${id}/${suffix}`

          if (chapter_id) {
            const dataForVersion = {
              chapter_id,
              word_count: wordCount,
              file_url: url,
              chapter_title: title,
              book_id: id,
              // status: 'pending',
              view: isAdminAccess ? 'cms' : 'dashboard',
            }
            return post('/content_api/book.version?is_novel=0', {
              data: dataForVersion,
            })
              .then(() => {
                if (commentedFileUrl) {
                  const file = new Blob([commentedInnerHtml], {
                    type: 'text/plain;charset=utf-8',
                  })
                  const params = paramsCal(
                    'chapter_comment',
                    chapter_id,
                    chapter_id
                  )
                  let url = ''
                  const commentedPreSignedUrl = CommentsFilGetPreSignedUrl
                  get(commentedPreSignedUrl, { params }).then((response) => {
                    const { formData, prefix, suffix } =
                      formDataCalculateForAws(response)
                    formData.append('file', file)
                    const cdn = CommentsFileCdn
                    post(prefix, { data: formData })
                      .then(() => {
                        url = `${cdn}/${chapter_id}/${suffix}`
                        const hashValue = hashValueForCommentsFile
                        const dataForCommentedChapter = {
                          chapter_id,
                          file_url: `${url}?hash=${hashValue}`,
                        }
                        return post(
                          `/content_api/book.create_or_update_chapter_comment_file`,
                          { params: dataForCommentedChapter }
                        )
                          .then(() => {
                            history.push(`${menuItemObj.audiobooks}/edit/${id}`)
                          })
                          .catch((err) => {
                            throw err
                          })
                      })
                      .finally(() => {
                        setLoading(false)
                      })
                  })
                }
                history.push(`${menuItemObj.audiobooks}/edit/${id}`)
              })
              .catch((err) => {
                throw err
              })
          }
          const dataForChapter = {
            book_id: id,
            chapter_title: title,
            file_url: url,
            word_count: wordCount,
            view: isAdminAccess ? 'cms' : 'dashboard',
          }

          return post('/content_api/book.create_chapter?is_novel=0', {
            data: dataForChapter,
          })
            .then(() => {
              history.push(`${menuItemObj.audiobooks}/edit/${id}`)
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((err) => {
          throw err
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }
  const saveButtonEnabled = () => {
    if (
      isContentChanged(editorState) &&
      wordCount >= getAudiobookWordCountLimit(language, book_type) &&
      editorToolbarChange
    ) {
      return true
    } else {
      return false
    }
  }
  // convert the child - Fragment of privatePage to a different component in order to receive props passed by it
  // in future if it it intented to use the received props
  return (
    <Provider
      value={() => (
        <EditorControls
          title={title}
          onBack={() => history.goBack()}
          onTitleChange={setTitle}
          wordCount={wordCount}
          undoActive={!isUndoEmpty(editorState)}
          redoActive={!isRedoEmpty(editorState)}
          handleUndo={() => onUndo(editorState, setEditorState)}
          handleRedo={() => onRedo(editorState, setEditorState)}
          handleShowRevision={() => toggleShowVersion(true)}
          showRevisionsIcon={chapter_id}
          actionButtons={{
            secondary: {
              label: 'Save',
              tooltip:
                wordCount < getAudiobookWordCountLimit(language, book_type)
                  ? `Please type atleast ${getAudiobookWordCountLimit(
                      language,
                      book_type
                    )} words to continue`
                  : !isContentChanged(editorState)
                  ? 'Please make some changes first'
                  : '',
              icon: SaveIcon,
              show: true,
              // enabled: isContentChanged(editorState) && wordCount >= getAudiobookWordCountLimit(language, book_type),
              enabled: saveButtonEnabled(),
              onClick: handleChapterSave,
            },
          }}
        />
      )}
    >
      <PrivatePage isProfileNameShown={false}>
        <>
          {isLoading && !apiError && <Loader />}
          {!isLoading && apiError && (
            <GenericError
              show={apiError}
              onClose={() => toggleApiError(false)}
            />
          )}
          {!isLoading && (
            <TextEditor
              content={content}
              initialContent={initialContent}
              setContent={setContent}
              editorState={editorState}
              updateState={updateState}
              updateWordCount={updateWordCount}
              chapterId={chapter_id}
              bookId={id}
              episodeTag={episodeTag}
              setEditorToolbarChange={setEditorToolbarChange}
              setCommentedFileUrl={setCommentedFileUrl}
              originalFileUrl={originalFileUrl}
              commentedFileUrl={commentedFileUrl}
              isContentChangedValid={saveButtonEnabled()}
              handleChapterSave={handleChapterSave}
            />
          )}
          {!isLoading && showVersion && (
            <Revisions
              open={showVersion}
              close={() => toggleShowVersion(false)}
              chapter_id={chapter_id}
            />
          )}
          {/* <Preview
            open={showPreview}
            close={() => toggleShowPreview(false)}
            title={title}
            content={content}
          /> */}
        </>
      </PrivatePage>
    </Provider>
  )
}

export default AddEditChapter
