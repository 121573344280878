import React from 'react'
import { CheckboxInput } from '../../FormComponents'
import { useField } from 'formik';

const FormikCheckboxInput = props => {
  const [field] = useField(props)
  const { onChange } = field

  const newHandleChange = val => {
    onChange({target: { name: props.name, value: val}})
  }

  const { placeholder, ...newProps } = props

  return (
    <CheckboxInput
      {...{...field, onChange: newHandleChange}}
      {...newProps}
    />
  )
}

export default FormikCheckboxInput