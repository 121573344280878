import React from 'react'
import styled from 'styled-components'
import Arrow from '../../../../../components/Images/ArrowRight'
import backgroundImg from './congratulations_bg.svg'

const NotificationDiv = styled.div`
  padding: 24px;
  display: flex;
  background-image: url(${backgroundImg});
  margin-bottom: 8px;
`
const ImageDiv = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  margin-right: 24px;
  height: 192px;

  @media (max-width: 600px) {
    display: none;
  }
`
const BookImage = styled.img`
  width: 192px;
  height: 192px;
`
const Details = styled.div``
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--Text700);
  margin-bottom: 4px;
`
const Desc = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 24px;
`

const CTA = styled.div`
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--Azure500);
  padding: 16px 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  display: inline-block;

  svg {
    margin-left: 5px;
    vertical-align: bottom;
  }
`
const CongratulationsCard = ({ image_url, book_title, onAccept, id }) => (
  <NotificationDiv>
    <ImageDiv>
      {image_url && <BookImage src={image_url} alt=""/>}
    </ImageDiv>
    <Details>
      <Title>Congratulations!</Title>
      <Desc>
        You have been selected as a voice over artist for the audiobook titled "{book_title}". <br />Click on accept audiobook to start recording.
      </Desc>
      <CTA onClick={() => onAccept(id)}>
        Accept Audiobook
        <Arrow />
      </CTA>
    </Details>
  </NotificationDiv>
)

const AcknowledgementCard = ({ image_url, book_title, onButtonClick }) => (
  <NotificationDiv>
    <ImageDiv>
      {image_url && <BookImage src={image_url} alt=""/>}
    </ImageDiv>
    <Details>
      <Title>You Accepeted the audiobook titled "{book_title}"</Title>
      <Desc>
        Go to Recording and Production tab to star creating the Audiobook
      </Desc>
      <CTA onClick={onButtonClick}>
        Go to Recording and Production
        <Arrow />
      </CTA>
    </Details>
  </NotificationDiv>
)

export {
  CongratulationsCard,
  AcknowledgementCard 
}
