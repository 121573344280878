import React from 'react'
import queryString from 'query-string'
import { convertToQueryString } from '../lib/utils'

export function WithFilters (WrappedCompopnent) {
  return props => {
    const { location } = props
    const { filters: qFilters } = queryString.parse(location.search)
    let filterObj
    try {
      filterObj = JSON.parse(qFilters)
    } catch (e) {
      filterObj = {}
    }
    const setFilterObj = obj => {
      return convertToQueryString(obj,'filters')
    }
    return <WrappedCompopnent
        {...props}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        qFilters={qFilters}
      />
  }
}
