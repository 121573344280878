import React, { useState, useEffect, useCallback } from 'react'
import { Popup } from '../../../../../components/Popup'
import './override.css'
import styled from 'styled-components'
import { get } from '../../../../../lib/api'
import { withRouter } from 'react-router-dom'
import Search from './search.svg'
import ShowCard from '../../Published/List/BookCardCms'
import NoResults from '../../../../../components/Images/DrawerNoResults'
import debounce from 'lodash.debounce'
import { menuItemObj } from '../../../../../components/PrivatePage'

const Head = styled.div`
  width: 700px;
  height: 114px;
  background-color: #e0e2eb;
`
const InnerContent = styled.div`
  width: calc(100% - 96px);
  margin: auto;
  padding-top: 32px;
`

const Title = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  color: #0d1536;
`
const SearchBox = styled.div`
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  backface-visibility: hidden;
  margin-bottom: 32px;
`
const OptionsDropDown = styled.div`
  width: calc(100% - 32px);
  max-height: 352px;
  overflow-y: auto;
  background-color: var(--Dove);
  position: absolute;
  top: 0;
  left: 16px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1;
`
const Option = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);

  &:hover {
    background-color: var(--Grey500);
  }
`
const Name = styled.div``

const Input = styled.input`
  width: 100%;
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);
  margin-top: 3px;
  min-height: 36px;
  border-radius: 4px;
  border: solid 1px var(--Grey900);
  padding: 12px 16px;
  // background-image: url(images/comment-author.gif);
  background-image: url(${Search});
  background-position: 98%;
  background-repeat: no-repeat;
  &:focus {
    outline: none;
    border: solid 2px var(--Azure500);
    padding: 11px 15px;
    background-color: var(--Dove);
  }
`
const DropDownHolder = styled.div`
  position: relative;
`
const BottomBGSection = styled.div`
  background-color: #f6f7f9;
  height: 100vh;
`
const BottomSectionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: rgba(13, 21, 54, 0.6);
  margin-bottom: 16px;
`
const EmptyState = styled.div`
  width: 340px;
  text-align: center;
  margin: auto;
`
const NoResultsText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.6);
  margin-top: 32px;
  margin-bottom: 10px;
  text-align: center;
`
const NoResultsSubtext = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  text-align: center;
`

const RightDrawerSearch = (props) => {
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [isResultsShown, setResultsShown] = useState(false)
  const handleOptionClick = ({entity_id}) => {
    toggleDropDownOpen(false)
    props.history.push(`${menuItemObj.audiobooks}/?tab=published&id=${entity_id}`)
  }

  const setValue = (value) => {
    fetchUsersByName(value)
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    debounceHandleChange(e.target.value)
  }
  const debounceHandleChange = useCallback(
    debounce((value) => setValue(value), 500),
    []
  )


  const fetchUsersByName = name => {
    if (!name) return
    get('/search/user.search', {
      params: {
        query: name
      }
    }).then(response => {
      const { stories } = response
      setOptions(stories.filter(({entity_type}) => entity_type === 'show').slice(0, 10))
      toggleDropDownOpen(true)
    })
  }

  const handleClick = () => {
    toggleDropDownOpen(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      toggleDropDownOpen(false)
      setResultsShown(true)
    }
  }

  return (
    <Popup
      {...props}
      className="right-drawer-search"
      renderCloseButton={() => <div className="close-button-drawer" onClick={props.close}>˟</div>}
    >
      <Head>
        <InnerContent>
          <Title>
            Search
          </Title>
          <SearchBox>
            <Input
              onChange={e => handleInputChange(e)}
              value={inputValue}
              placeholder="Search for Audiobooks"
              onKeyDown={handleKeyDown}
            />
          </SearchBox>
          <DropDownHolder>
            <OptionsDropDown isOpen={isOpen} >
              {options.map((option) => (
                <Option key={"show-search-" + option.entity_id} onClick={() => handleOptionClick(option)}>
                <Name>
                  {option.title}
                </Name>
                </Option>
              ))}
            </OptionsDropDown>
          </DropDownHolder>
          {!isOpen && isResultsShown && !!options.length && <BottomSectionTitle>Search Results</BottomSectionTitle>}
          {!isOpen && isResultsShown && options.map(show => (
              <ShowCard
                hideStatus
                data = {{
                  show_id: show.entity_id,
                  image_url: show.image_url,
                  show_title: show.title,
                  vo_artist_info: {
                    fullname: show.creator_name,
                  }
                }}
                key={show.entity_id}
                history={props.history}
                userType=""
              />
            ))}
         {!isOpen && isResultsShown && !options.length && <EmptyState>
              <NoResults />
              <NoResultsText>No Result Found</NoResultsText>
              <NoResultsSubtext>
                We can’t find anything matching your search
              </NoResultsSubtext>
            </EmptyState>}
        </InnerContent>
      </Head>
      <BottomBGSection />
    </Popup>
  )
}

export default withRouter(RightDrawerSearch)
