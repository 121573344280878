import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import ClampLines from 'react-clamp-lines'
import {
  useGenericInfiniteScroller,
  useCreatorProfile,
  useDidUpdate,
  useInfiniteScroll,
  usePaginationNavigator,
} from '../../../../../lib/customHooks'
import { unset } from '../../../../../lib/storage'
import SubHeader from '../../../../../components/WithSubHeaderBG'
import { get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import CreatorIcon from '../../../../../components/Images/CreatorIcon'
import { removeHtmlTags } from '../../../../../lib/utils'
import Stats from './stats'
import EpisodeListing from './episodeList'
import AddNewEpisode from './addEditEpisode'
import GenericError from '../../../../../components/GenericError'
import { getTokenAndUid } from '../../../../../lib/utils'
import { Link } from 'react-router-dom'
import CreatorInfo from '../../../../../components/CreatorInfo'
import Vellip from '../../../../../components/Images/Vellip'
import PencilIcon from '../../../../../components/Images/PencilIcon'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button, Image } from '../../../../../designSystem'
import { Popup } from '../../../../../components/Popup'
import Icon from '../../../../../components/Images/ChevronRight'
import PaginationDrawer from '../../../../../components/PaginationDrawer'
import LoadPrevious from '../../../../../components/LoadPrevious'
import ViewDetails from '../../Unpublished/Edit/viewDetails'
import moment from 'moment'
import AudioWarningPopup from './audioWarningPopup'
import AssetMangementTab from './AssetManagement'

const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.text500};
  margin-bottom: 12px;
  margin-top: 8px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text.text500};
    outline: none;
  }
`
const ArtistsContainer = styled.div`
  margin-top: 16px;
  display: flex;
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
  @media (max-width: 600px) {
    margin-right: 0;
  }
`
const ProfileImageDiv = styled.div`
  position: relative;
  margin-right: 8px;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const StyledCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`
export const AddEpisodeButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
`

export const TabHolder = styled.div`
  padding: 10px 24px 0 24px;
  display: flex;
  height: 56px;
  background-color: var(--Dove);
  position: sticky;
  top: 56px;
  z-index: 1;
`
export const Tab = styled.div`
  margin-right: ${({ last }) => (!last ? '48px' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  color: ${({ active }) => (active ? 'var(--Azure500)' : 'var(--Text500)')};
`
export const UnderLine = styled.div`
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ active }) => (active ? 'var(--Azure500)' : 'none')};
`
export const HR = styled.div`
  height: 1px;
  background-color: var(--Grey100);
`

const VellipIcon = styled(Vellip)`
  display: block;
`

const VellipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #ebecf1;
  }
`
const BookOptionsBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  width: 224px;
`
const EditBookDiv = styled.div`
  background-color: #f0f1f5;
  cursor: pointer;
`
const EditButton = styled(Link)`
  border: none;
  outline: none;
  background: none;
  padding: 16px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`
const EditBookSpan = styled.span`
  margin-left: 8px;
`

const RightIcon = styled(Icon)`
  fill: ${({ active }) => (active ? 'var(--Azure500)' : 'var(--Text500)')};
  margin-left: 6px;
`
const ViewDetailButton = styled.button`
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--Azure500);
  padding: 10px;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: bold;
  color: var(--Dove);
  cursor: pointer;
`

const ViewDetailButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const EpisodeDiv = styled.div`
  display: flex;
  align-items: center;
`

export const allTabs = {
  episodeListing: 'All Episodes',
  toBeRecorded: 'To Be Recorded',
  assetManagement: 'Asset Management',
}

const HeaderView = ({
  episodes_count,
  selectedTab,
  setSelectedTab,
  handleAddNewEpisode,
  onAllEpisodeClick,
  onToBeRecordedEpisodeClick,
  all_audios_uploaded,
  pending_chapters_count = 0,
}) => {

  const onTabClick = (tab) => {
    unset('asset_id')
    setSelectedTab(tab)
  }
  return (
    <>
      <TabHolder>
        {!all_audios_uploaded && (
          <Tab
            active={selectedTab === allTabs.toBeRecorded}
            onClick={() => onTabClick(allTabs.toBeRecorded) }
          >
            <EpisodeDiv onClick={onToBeRecordedEpisodeClick}>
              <span>
                {allTabs.toBeRecorded} ({pending_chapters_count})
              </span>
              <RightIcon active={selectedTab === allTabs.toBeRecorded} />
            </EpisodeDiv>
            <UnderLine active={selectedTab === allTabs.toBeRecorded} />
          </Tab>
        )}
        <Tab
          active={selectedTab === allTabs.episodeListing}
          onClick={() => onTabClick(allTabs.episodeListing) }
        >
          <EpisodeDiv onClick={onAllEpisodeClick}>
            {allTabs.episodeListing} ({episodes_count - pending_chapters_count})
            <RightIcon
              active={selectedTab === allTabs.episodeListing}
            ></RightIcon>
          </EpisodeDiv>
          <UnderLine active={selectedTab === allTabs.episodeListing} />
        </Tab>
        <Tab
          active={selectedTab === allTabs.assetManagement}
          onClick={() => setSelectedTab(allTabs.assetManagement)}
        >
          <span>{allTabs.assetManagement}</span>
          <UnderLine active={selectedTab === allTabs.assetManagement} />
        </Tab>
        {selectedTab !== allTabs.assetManagement && (
          <AddEpisodeButton onClick={handleAddNewEpisode}>
            <Button variant="outlinePrimary">New Episode</Button>
          </AddEpisodeButton>
        )}
      </TabHolder>
      <HR />
    </>
  )
}

const ShowDetails = (props) => {
  const {
    location: { search },
    history,
  } = props
  const qParams = queryString.parse(search)
  const { id, selected_tab } = qParams
  const tab_chosen = selected_tab?.split('_')?.join(' ')
  const [auditionbook, setBookDetails] = useState(null)
  const [bookEpisodes, setBookEpisodes] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isAddEntryBoxShown, setAddEntryBoxDisplay] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [addNewError, toggleAddNewError] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const [prevUrl, setPrevUrl] = useState(null)
  const [audioRepetionWarning, setAudioRepitionWarning] = useState(null)
  // const isFetching = useInfiniteScroll(fetchMoreData)
  const [selectedTab, setSelectedTab] = useState(
    tab_chosen || allTabs.episodeListing
  )
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } =
    useCreatorProfile()
  const [isBookOptionsShown, toggleShowBookOptions] = useState(false)
  const [episodeLoader, setEpisodeLoader] = useState(false)
  //const [isPopup, togglePopup] = useState(false)
  const [isViewDetailsSelected, toggleViewDetails] = useState(false)
  const bookOptionsRef = useRef()
  const isMobile = window.screen.width < 600

  const isFetchingData = useGenericInfiniteScroller(fetchMoreData, { nextUrl })
  let isFetching, lastNodeEleRef
  if (
    typeof isFetchingData === 'object' &&
    !Array.isArray(isFetchingData) &&
    isFetchingData !== null
  ) {
    //  using useLatest Infinte scroll
    isFetching = isFetchingData?.isFetching
    lastNodeEleRef = isFetchingData?.lastNodeEleRef
  } else {
    isFetching = isFetchingData
  }
  const {
    currentPaginationNo,
    setCurrentPaginationNo,
    episodeRange,
    handleEpisodeRangeData,
    episodesCount,
    setEpisodesCount,
    showPaginationDrawer,
    setShowPaginationDrawer,
    showPreviousLoader,
    setShowPreviousLoader,
    handlePaginationApiCall,
  } = usePaginationNavigator()

  const {
    currentPaginationNo: toBeRecordCurrentPaginationNo,
    setCurrentPaginationNo: toBeRecordSetCurrentPaginationNo,
    episodeRange: toBeRecordEpisodeRange,
    handleEpisodeRangeData: toBeRecordHandleEpisodeRangeData,
    episodesCount: toBeRecordEpisodesCount,
    setEpisodesCount: toBeRecordSetEpisodesCount,
    showPaginationDrawer: toBeRecordShowPaginationDrawer,
    setShowPaginationDrawer: toBeRecordSetShowPaginationDrawer,
    showPreviousLoader: toBeRecordShowPreviousLoader,
    setShowPreviousLoader: toBeRecordSetShowPreviousLoader,
    handlePaginationApiCall: toBeRecordHandlePaginationApiCall,
  } = usePaginationNavigator()

  // function fetchMoreData(setIsFetching) {
  //   if (!nextUrl) {
  //     setIsFetching(false)
  //     return
  //   }
  //   get(nextUrl)
  //     .then((response) => {
  //       const { result } = response
  //       const { next_url, episodes, episodes_count } = result
  //       setBookDetails((prevState) => ({
  //         ...result,
  //         episodes: prevState.episodes.concat(episodes),
  //       }))
  //       setBookEpisodes(bookEpisodes.concat(episodes))
  //       setNextUrl(next_url)
  //       setIsFetching(false)
  //       const nextNumber = next_url
  //         ? Number(next_url.split('page_no')[1].split('&')[0].slice(1)) - 1
  //         : Math.ceil(episodes_count / 10)
  //       setCurrentPaginationNo(nextNumber)
  //     })
  //     .catch((error) => {
  //       setIsFetching(false)
  //       throw error
  //     })
  // }

  const fetchData = (isTabChange = false) => {
    setEpisodeLoader(true)
    !isTabChange && setLoading(true)
    setNextUrl(null)
    setPrevUrl(null)
    const bookDetailsApi = '/content_api/book.show_episodes'
    get(bookDetailsApi, {
      params: {
        show_id: id,
        view: 'dashboard',
        paginate_chapters: selectedTab === allTabs.toBeRecorded ? 'true' : null,
      },
    })
      .then((response) => {
        const { result } = response
        const { next_url, prev_url } = result
        setBookDetails(result)
        setBookEpisodes(result?.episodes)
        setEpisodesCount(result.episodes_count - result.pending_chapters_count)
        toBeRecordSetEpisodesCount(result.pending_chapters_count)
        handleEpisodeRangeData(
          result.episodes_count - result.pending_chapters_count,
          'reverse'
        )
        toBeRecordHandleEpisodeRangeData(
          result?.episodes[0]?.natural_sequence_number,
          'reverse',
          result?.episodes[0]?.natural_sequence_number -
            result.pending_chapters_count +
            1
        )

        setCurrentPaginationNo(1)
        setNextUrl(next_url)
        setEpisodeLoader(false)
        setPrevUrl(prev_url)
      })
      .catch((error) => {
        setEpisodeLoader(false)
        setLoading(false)
        if (error.code === 403) {
          throw new Error(error.message)
        } else {
          throw error
        }
      })
  }

  useEffect(() => {
    fetchData()
  }, [id])
  useEffect(() => {
    setBookEpisodes([])
    fetchData(true)
  }, [selectedTab])

  const handleClick = (e) => {
    if (bookOptionsRef.current && !bookOptionsRef.current.contains(e.target)) {
      toggleShowBookOptions(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  useDidUpdate(() => {
    setLoading(false)
  }, [auditionbook])

  const successAddEntryCB = (chapterAndStory) => {
    setAddEntryBoxDisplay(false)
    setAudioRepitionWarning(chapterAndStory?.audioWarningMessage)
    fetchData(true)
  }

  const onPaginationRangeClick = (number, index) => {
    setShowPaginationDrawer(false)
    setNextUrl(null)

    setShowPaginationDrawer(false)
    toBeRecordSetShowPaginationDrawer(false)
    setNextUrl(null)
    if (selectedTab === allTabs.episodeListing) {
      handlePaginationApiCall(
        '/content_api/book.show_episodes',
        {
          show_id: id,
          view: 'dashboard',
          page_no: number,
          paginate_chapters: null,
        },
        setLoading,
        setPaginationRangeData
      )
    } else if (selectedTab === allTabs.toBeRecorded) {
      toBeRecordHandlePaginationApiCall(
        '/content_api/book.show_episodes',
        {
          show_id: id,
          view: 'dashboard',
          page_no: index + 1,
          paginate_chapters: 'true',
        },
        setLoading,
        setPaginationRangeData
      )
    }
  }

  const setPaginationRangeData = (response) => {
    const { next_url, prev_url } = response.result
    setBookDetails(response.result)
    setBookEpisodes(response.result.episodes)
    setNextUrl(next_url)
    setPrevUrl(prev_url)
  }
  const LoadPreviousPage = () => {
    if (selectedTab === allTabs.episodeListing && !showPreviousLoader) {
      handlePaginationApiCall(
        prevUrl,
        '{}',
        setShowPreviousLoader,
        setLoadPreviousData
      )
    }
    if (selectedTab === allTabs.toBeRecorded && !toBeRecordShowPreviousLoader) {
      toBeRecordHandlePaginationApiCall(
        prevUrl,
        '{}',
        toBeRecordSetShowPreviousLoader,
        setLoadPreviousData
      )
    }
  }
  const setLoadPreviousData = (response) => {
    const { episodes, prev_url } = response.result
    setBookDetails((prevState) => ({
      ...response.result,
      episodes: [...episodes, ...prevState.episodes],
    }))
    setBookEpisodes([...episodes, ...bookEpisodes])
    setPrevUrl(prev_url)
    const prevNumber = prev_url
      ? Number(prev_url.split('page_no')[1].split('&')[0].slice(1)) + 1
      : 1
    if (selectedTab === allTabs.episodeListing)
      setCurrentPaginationNo(prevNumber)
    if (selectedTab === allTabs.toBeRecorded)
      toBeRecordSetCurrentPaginationNo(prevNumber)
  }

  const successEditEpisodeCB = (chapterAndStoryData, mediaUrl) => {
    const index = bookEpisodes?.findIndex(
      (elm) => elm.chapter_details?.chapter_id === chapterAndStoryData.chapter_details?.chapter_id
    )
    if (index === -1) fetchData(true)
    else{
      let book = JSON.parse(JSON.stringify(auditionbook))
      book.episodes[index].chapter_details.chapter_title = chapterAndStoryData?.chapter_details?.chapter_title
      if(selectedTab === 'All Episodes')book.episodes[index].duration = chapterAndStoryData?.story_details?.duration
      if(book.episodes[index]?.story_details){
        book.episodes[index].story_details.story_title = chapterAndStoryData?.story_details?.story_title
      }
      book.episodes[index].story_title = chapterAndStoryData?.story_details?.story_title
      if((chapterAndStoryData?.story_details?.audio_status || chapterAndStoryData?.chapter_details?.audio_status) && mediaUrl !== ''){
        book.episodes[index].chapter_details.audio_status=chapterAndStoryData?.story_details?.audio_status;
        book.episodes[index].audio_available = true
      }
      if((new Date(chapterAndStoryData?.story_details?.schedule_time) - new Date(book.episodes[index].schedule_date) !== 0) && selectedTab === 'All Episodes')
          book.episodes[index].schedule_date = moment(chapterAndStoryData?.story_details?.schedule_time).format('DD MMM YYYY, hh:mm A');
      setBookDetails(book)
      setAudioRepitionWarning(chapterAndStoryData?.audioWarningMessage)
      setBookEpisodes(book.episodes)
    }
  }

  const handleDiscard = () => {
    setAddEntryBoxDisplay(false)
  }
  const onAllEpisodeClick = () => {
    if (selectedTab !== allTabs.episodeListing) return
    setShowPaginationDrawer(true)
  }

  const onToBeRecordedEpisodeClick = () => {
    if (selectedTab !== allTabs.toBeRecorded) return
    toBeRecordSetShowPaginationDrawer(true)
  }

  const handleAddNewEpisode = () => {
    setAddEntryBoxDisplay(true)
  }

  const {
    image_url,
    show_id,
    show_title,
    ops_alias,
    category_obj: { topic_name, book_type } = {},
    language,
    show_desc,
    created_for,
    show_desc_html,
    episodes_count,
    stats,
    book_id,
    moderation_required,
    all_audios_uploaded,
    pending_chapters_count,
    author_info: { author_id, author_name, image_url: author_image } = {},
    agent_info: { agent_id } = {},
    creator_info: { creator_id, creator_name, image_url: artist_image } = {},
    creator_info_list,
    translator_info: {
      translator_name,
      image_url: translator_image,
      translator_id,
    } = {},
    chapter_created_info,
    associated_authors_info = [],
    production_house_info: {
      production_house_name,
      image_url: production_house_image,
      production_house_id,
    } = {},
  } = auditionbook || {}

  if (isLoading) {
    return (
      <div style={{ marginTop: '200px' }}>
        <Loader />
      </div>
    )
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl || selectedTab === allTabs.assetManagement) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { result } = response
        const { next_url, episodes } = result
        setBookDetails((prevState) => ({
          ...result,
          episodes: prevState.episodes.concat(episodes),
        }))
        setBookEpisodes(bookEpisodes.concat(episodes))
        setNextUrl(next_url)
        setIsFetching(false)
        const nextNumber = next_url
          ? Number(next_url.split('page_no')[1].split('&')[0].slice(1)) - 1
          : Math.ceil(episodes_count / 10)
        setCurrentPaginationNo(nextNumber)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }
  // const isFetching = useInfiniteScroll(fetchMoreData)
  const { uid } = getTokenAndUid()
  const isAuthorAssociatedWithBook =
    associated_authors_info.filter((ele) => ele?.author_id === uid).length > 0
  const isBookOwner =
    author_id === uid ||
    translator_id === uid ||
    agent_id ||
    isAuthorAssociatedWithBook
// check awareded for this

const isVoaAssociatedWithAudio =
   creator_info_list.filter((ele) => ele?.creator_id === uid && ele?.status==="awarded").length > 0 
   const isAudioOwner = isVoaAssociatedWithAudio || production_house_id === uid
  // const isAudioOwner = creator_id === uid || production_house_id === uid

  return (
    <>
      <PaginationDrawer
        selectedTab={selectedTab}
        title={
          selectedTab === allTabs.episodeListing
            ? 'Select Episode Range'
            : selectedTab === allTabs.toBeRecorded
            ? 'Select To Be Recorded Episode Range'
            : null
        }
        show={
          selectedTab === allTabs.episodeListing
            ? showPaginationDrawer
            : selectedTab === allTabs.toBeRecorded
            ? toBeRecordShowPaginationDrawer
            : null
        }
        close={() => {
          setShowPaginationDrawer(false)
          toBeRecordSetShowPaginationDrawer(false)
        }}
        onPaginationRangeClick={onPaginationRangeClick}
        episodeRange={
          selectedTab === allTabs.episodeListing
            ? episodeRange
            : selectedTab === allTabs.toBeRecorded
            ? toBeRecordEpisodeRange
            : null
        }
        episodesCount={
          selectedTab === allTabs.episodeListing
            ? episodesCount
            : selectedTab === allTabs.toBeRecorded
            ? toBeRecordEpisodesCount
            : null
        }
        currentPaginationNo={
          selectedTab === allTabs.episodeListing
            ? currentPaginationNo
            : selectedTab === allTabs.toBeRecorded
            ? toBeRecordCurrentPaginationNo
            : null
        }
        setCurrentPaginationNo={
          selectedTab === allTabs.episodeListing
            ? setCurrentPaginationNo
            : selectedTab === allTabs.toBeRecorded
            ? toBeRecordSetCurrentPaginationNo
            : null
        }
        order="reverse"
      />
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      <GenericError
        show={addNewError}
        title={'Cannot Add New Episode'}
        message={
          'Please make sure all the existing episodes contain audio files before adding new episode'
        }
        onClose={() => {
          toggleAddNewError(false)
        }}
      />
      {auditionbook && (
        <SubHeader title="Audiobook Details" showBackButton>
          <Box
            p={[16, 24]}
            mb={8}
            width={['100vw', '100%']}
            position="relative"
            bg="surface.foreground"
          >
            <Box
              variant="flex"
              mb={24}
              justifyContent="flex-start"
              flexDirection={['column', 'row']}
            >
              {!isMobile && (
                <Image
                  width={[60, 144]}
                  height={[60, 144]}
                  src={image_url}
                  mr={24}
                />
              )}
              {isMobile && (
                <Box variant="flex" justifyContent="flex-start">
                  <Image
                    width={[60, 144]}
                    height={[60, 144]}
                    src={image_url}
                    mr={8}
                  />
                  <Text variant="title">{show_title}</Text>
                </Box>
              )}
              <Box mt={[8, 0]}>
                {!isMobile && <Text variant="title">{show_title}</Text>}
                <Text variant="subtext" mt={[0, 4]}>
                  {ops_alias}{' '}
                  {ops_alias && topic_name && <strong>&middot;</strong>}{' '}
                  {topic_name}
                </Text>
                {(show_desc || show_desc_html) && (
                  <BookDescription>
                    <ClampLines
                      lines={2}
                      text={
                        show_desc ||
                        removeHtmlTags(show_desc_html) ||
                        'No description available'
                      }
                      id="recording-show-description"
                    />
                  </BookDescription>
                )}

                <ArtistsContainer>
                  {author_name && (
                    <ArtistDetails onClick={() => onUserClick(author_id)}>
                      {!isMobile && author_image && (
                        <ProfileImageDiv>
                          <ProfileImage src={author_image} />
                          <StyledAuthorIcon />
                        </ProfileImageDiv>
                      )}
                      {author_name && <ArtistName>{author_name}</ArtistName>}
                    </ArtistDetails>
                  )}
                  {author_name && creator_name && isMobile && (
                    <strong> &nbsp; &middot; &nbsp; </strong>
                  )}
                  {creator_name && (
                    <ArtistDetails onClick={() => onUserClick(creator_id)}>
                      {!isMobile && artist_image && (
                        <ProfileImageDiv>
                          <ProfileImage src={artist_image} />
                          <StyledCreatorIcon />
                        </ProfileImageDiv>
                      )}
                      <ArtistName>{creator_name}</ArtistName>
                    </ArtistDetails>
                  )}
                  {author_name &&
                    creator_name &&
                    translator_name &&
                    isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                  {translator_name && (
                    <ArtistDetails onClick={() => onUserClick(translator_id)}>
                      {!isMobile && translator_image && (
                        <ProfileImageDiv>
                          <ProfileImage src={translator_image} />
                          <StyledAuthorIcon />
                        </ProfileImageDiv>
                      )}
                      {translator_name && (
                        <ArtistName>{translator_name}</ArtistName>
                      )}
                    </ArtistDetails>
                  )}
                  {author_name &&
                    creator_name &&
                    translator_name &&
                    production_house_name &&
                    isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
                  {production_house_name && (
                    <ArtistDetails
                      onClick={() => onUserClick(production_house_id)}
                    >
                      {!isMobile && production_house_image && (
                        <ProfileImageDiv>
                          <ProfileImage src={production_house_image} />
                          <StyledCreatorIcon />
                        </ProfileImageDiv>
                      )}
                      {production_house_name && (
                        <ArtistName>{production_house_name}</ArtistName>
                      )}
                    </ArtistDetails>
                  )}
                </ArtistsContainer>
              </Box>
            </Box>
            <Stats
              isMobile={isMobile}
              stats={stats}
              show_id={show_id}
              history={history}
            />
            {auditionbook?.book_docs?.length > 0 && (
              <ViewDetailButtonContainer>
                <ViewDetailButton
                  type="button"
                  onClick={() => toggleViewDetails(true)}
                >
                  View Documents
                </ViewDetailButton>
              </ViewDetailButtonContainer>
            )}
            {isBookOwner && (
              <VellipContainer ref={bookOptionsRef}>
                <VellipIcon
                  onClick={() => toggleShowBookOptions(!isBookOptionsShown)}
                />
                <BookOptionsBox show={isBookOptionsShown}>
                  <EditBookDiv>
                    <EditButton
                      to={`${menuItemObj.audiobooks}/edit-show-details/${show_id}`}
                    >
                      <PencilIcon />
                      <EditBookSpan>Edit Audiobook</EditBookSpan>
                    </EditButton>
                  </EditBookDiv>
                </BookOptionsBox>
              </VellipContainer>
            )}
          </Box>
          <AudioWarningPopup
            audioRepetionWarning={audioRepetionWarning}
            setAudioRepitionWarning={setAudioRepitionWarning}
          />
          {isViewDetailsSelected && (
            <Popup
              show={true}
              className="right-drawer-episode-form"
              close={() => toggleViewDetails(false)}
            >
              <ViewDetails
                book={auditionbook}
                handleCancel={() => toggleViewDetails(false)}
              />
            </Popup>
          )}
          <HeaderView
            selectedTab={selectedTab}
            all_audios_uploaded={all_audios_uploaded}
            setSelectedTab={setSelectedTab}
            handleAddNewEpisode={handleAddNewEpisode}
            episodes_count={episodes_count}
            onAllEpisodeClick={onAllEpisodeClick}
            onToBeRecordedEpisodeClick={onToBeRecordedEpisodeClick}
            pending_chapters_count={pending_chapters_count}
          />
          {episodeLoader && <Loader />}
          {prevUrl && (
            <LoadPrevious
              loadPreviousPage={LoadPreviousPage}
              showPreviousLoader={
                selectedTab === allTabs.episodeListing
                  ? showPreviousLoader
                  : selectedTab === allTabs.toBeRecorded
                  ? toBeRecordShowPreviousLoader
                  : null
              }
            ></LoadPrevious>
          )}
          {isAddEntryBoxShown && (
            <Popup
              show={true}
              close={handleDiscard}
              className="right-drawer-episode-form"
            >
              <AddNewEpisode
                book_id={book_id}
                show_id={show_id}
                thumbnail={image_url}
                data={{ natural_sequence_number: episodes_count + 1 }}
                created_for={created_for}
                successCallback={successAddEntryCB}
                handleCancel={handleDiscard}
                isBookOwner={isBookOwner}
                isAudioOwner={isAudioOwner}
                creator_id={creator_id}
                book_type={
                  book_type === 'audiobook' ? 'Audiobook' : 'Audiobook Series'
                }
                language={language}
                mode="add"
                moderation_required={moderation_required}
              />
            </Popup>
          )}
          {selectedTab !== allTabs.assetManagement && (
            <EpisodeListing
              episodes={bookEpisodes}
              thumbnail={image_url}
              book_id={book_id}
              show_id={show_id}
              creator_id={creator_id}
              translator_name={translator_name}
              chapter_created_info={chapter_created_info}
              created_for={created_for}
              successCallback={successEditEpisodeCB}
              isBookOwner={isBookOwner}
              isAudioOwner={isAudioOwner}
              book_type={
                book_type === 'audiobook' ? 'Audiobook' : 'Audiobook Series'
              }
              language={language}
              moderation_required={moderation_required}
              isFetching={isFetching}
              lastNodeEleRef={lastNodeEleRef}
            />
          )}
          {selectedTab === allTabs.assetManagement && (
            <AssetMangementTab
              setSelectedTab={setSelectedTab}
              auditionbook={auditionbook}
            />
          )}
          {/* {isFetching && <Loader />} */}
        </SubHeader>
      )}
    </>
  )
}

export default ShowDetails
