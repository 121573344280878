import * as React from "react";
const ExpandMore = (props) => (
  <svg
    height={20}
    viewBox="0 0 48 48"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);
export default ExpandMore;