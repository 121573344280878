import React from 'react'
import ClampLines from 'react-clamp-lines'
import { getTopicNameFromId, removeHtmlTags } from '../../../../../lib/utils'
import styled from 'styled-components'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import CreatorIcon from '../../../../../components/Images/CreatorIcon'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Image } from '../../../../../designSystem'

const BookSummary = styled.div`
  padding: 24px;
  cursor: pointer;
  display: flex;
  background-color: var(--Dove);
  margin-bottom: 8px;
  position: relative;

  &:last-child {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
    margin-bottom: 100px;
  }
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  }

  @media(max-width:600px){
    width: 100vw;
    padding: 16px;
  }
`
const BookImageDiv = styled.div`
  width: 144px;
  height: 144px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--Grey900);
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  flex: none;
`
const BookImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`
const BookDetails = styled.div`
  margin-right: 16px;
`
const PendingCountInfo = styled.div`
  margin-left: auto;
  min-width: 20%;
  text-align: right;
`
const Count = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
  border-radius: 24px;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  display: inline-block;
`
const BookTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.text500};
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 16px;
`
const ArtistsContainer = styled.div`
  display: flex;
  display: -webkit-inline-box;
  overflow: auto;

  &::-webkit-scrollbar-thumb{
    background: transparent;
  }
  @media(max-width:600px){
    margin-top: 8px;
  }
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  flex-shrink: 0;
  @media(max-width:600px){
    margin-right: 0;
  }
`
const ProfileImageDiv = styled.div`
  position: relative;
  margin-right: 8px;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const StyledCreatorIcon = styled(CreatorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
`
const BookCard = ({
  data: {
    show_id,
    image_url,
    show_title,
    category_obj: { topic_name } = {},
    show_desc,
    show_desc_html,
    author_info: { fullname: author_name, image_url: author_image } = {},
    new_episodes_to_record,
    vo_artist_info: {
      fullname: artist_name,
      image_url: artist_image,
    },
    translator_info: {
      fullname: translator_name,
      image_url: translator_image
    } = {},
    production_house_info: {
      production_house_name,
      image_url: production_house_image
    } = {}
  },
  isMobile,
  history,
  userType
}) => (
  <BookSummary
    key={show_id}
    onClick={() =>
      history.push(
        `${menuItemObj.audiobooks}?tab=published&id=${show_id}`
      )
    }
  > 
  
    <Image width={[80, 144]} height={[80, 144]} mr={[8, 24]} src={image_url} />
    <Box mr={[0, 14]}>
      <Text variant="title" fontWeight="r">
        {show_title}
      </Text>
      {!isMobile && topic_name && <Text variant="subtext">{topic_name}</Text>}
      {!isMobile && <BookDescription><ClampLines text={show_desc || removeHtmlTags(show_desc_html) || "No description available"} lines={2} id="published-show-list" buttons={false}></ClampLines></BookDescription>}
      <ArtistsContainer>
        {author_name && <ArtistDetails>
          {!isMobile && author_image && <ProfileImageDiv>
            <ProfileImage src={author_image} />
            <StyledAuthorIcon />
          </ProfileImageDiv>}
          {author_name && <ArtistName>{author_name}</ArtistName>}
        </ArtistDetails>}
        {author_name && artist_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
        {artist_name && <ArtistDetails>
          {!isMobile && artist_image &&<ProfileImageDiv>
            <ProfileImage src={artist_image} />
            <StyledCreatorIcon />
          </ProfileImageDiv>}
          <ArtistName>{artist_name}</ArtistName>
        </ArtistDetails>}
        {author_name && artist_name && translator_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
        {translator_name && <ArtistDetails>
          {!isMobile && translator_image && <ProfileImageDiv>
            <ProfileImage src={translator_image} />
            <StyledAuthorIcon />
          </ProfileImageDiv>}
          {translator_name && <ArtistName>{translator_name}</ArtistName>}
        </ArtistDetails>}
        {author_name && artist_name && translator_name && production_house_name && isMobile && <strong> &nbsp; &middot; &nbsp; </strong>}
        {production_house_name && <ArtistDetails>
          {!isMobile && production_house_image && <ProfileImageDiv>
            <ProfileImage src={production_house_image} />
            <StyledCreatorIcon />
          </ProfileImageDiv>}
          {production_house_name && <ArtistName>{production_house_name}</ArtistName>}
        </ArtistDetails>}
      </ArtistsContainer>
    </Box>
    {(userType.includes('VO Artist') || userType.includes('Publisher') || userType.includes('Production House')) && !!new_episodes_to_record && <Box ml="auto" minWidth="20%" textAlign="right">
      <Count>{`${new_episodes_to_record} new ${new_episodes_to_record > 1 ? 'Episodes' : 'Episode'} to record`}</Count>
    </Box>}
  </BookSummary>
)
export default BookCard
