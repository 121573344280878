import React from 'react'
import WriterPopup from '../WriterPopup'
import NovelsUI from './Novels.UI'

const AudiobooksList = (props) => {
  return (
    <>
      <NovelsUI {...props} />
      <WriterPopup />
    </>
  )
}

export default AudiobooksList
