// import { lighten, darken } from 'polished';

export const fonts = {
  body: '"Noto Sans", sans-serif',
  heading: '"Noto Sans", sans-serif',
  monospace: '"Noto Sans", sans-serif',
};

// export const fontSizes = [
//   '0.625rem',
//   '0.75rem',
//   '1rem',
//   '1.125rem',
//   '1.25rem',
//   '1.5rem',
//   '1.75rem',
//   '2rem',
//   '2.5rem',
//   '3rem',
// ];

export const fontSizes = {
  D: {
    XXL: '40px',
    XL: '32px',
    L: '24px',
    M: '20px',
    S: '16px',
    XS: '14px',
    XXS: '12px'
  },
  M: {
    XXL: '28px',
    XL: '24px',
    L: '20px',
    M: '18px',
    S: '16px',
    XS: '14px',
    XXS: '12px'
  },
  BT: {
    XL: '18px',
    L: '16px',
    M: '14px',
    S: '12px',
    XS: '10px'
  },
  P: {
    L: '18px',
    M: '16px',
    S: '14px',
    XS: '12px',
  }
};

export const fontWeights = {
  r: 'normal',
  s: 600,
  b: 'bold',
};

export const lineHeights = {
  P: {
    L: '32px',
    M: '28px',
    S: '24px',
    XS: '20px',
  },
  D: {
    XXL: '48px',
    XL: '40px',
    L: '32px',
    M: '24px',
    S: '24px',
    XS: '20px',
    XXS: '16px',
  },
  M: {
    XXL: '40px',
    XL: '32px',
    L: '24px',
    M: '24px',
    S: '24px',
    XS: '20px',
    XXS: '16px',
  },
  BT: {
    XL: '24px',
    L: '20px',
    M: '16px',
    S: '16px',
    XS: '12px'
  },
};

export const letterSpacings = {
  default: 'normal',
  tracked: '0.04em',
};

// export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];
export const space = [];
export const sizes = [8, 16, 32, 64, 128, 256, 512, 768, 1024, 1536];

export const breakpoints = ['600px'];

export const staticInteractiveColors = {
  crimsonMuted8: 'var(--crimson-op-8)',
  crimsonMuted16: 'var(--crimson-op-16)',
  crimsonMuted24: 'var(--crimson-op-24)',
  crimsonMuted32: 'var(--crimson-op-32)',
  crimsonMuted40: 'var(--crimson-op-40)',
  azureMuted8: 'var(--azure-op-8)',
  azureMuted16: 'var(--azure-op-16)',
  azureMuted24: 'var(--azure-op-24)',
  azureMuted32: 'var(--azure-op-32)',
  azureMuted40: 'var(--azure-op-40)',
  limeMuted8: 'var(--lime-op-8)',
  limeMuted16: 'var(--lime-op-16)',
  limeMuted24: 'var(--lime-op-24)',
  limeMuted32: 'var(--lime-op-32)',
  limeMuted40: 'var(--lime-op-40)',
  cormMuted8: 'var(--corn-op-8)',
  cormMuted16: 'var(--corn-op-16)',
  cormMuted24: 'var(--corn-op-24)',
  cormMuted32: 'var(--corn-op-32)',
  cormMuted40: 'var(--corn-op-40)',
  punchMuted8: 'var(--punch-op-8)',
  punchMuted16: 'var(--punch-op-16)',
  punchMuted24: 'var(--punch-op-24)',
  punchMuted32: 'var(--punch-op-32)',
  punchMuted40: 'var(--punch-op-40)',
}

export const constant = {
  whiteMuted96: 'var(--white-op-96)',
  whiteMuted88: 'var(--white-op-88)',
  whiteMuted80: 'var(--white-op-80)',
  ravenMuted96: 'var(--raven-op-96)',
  ravenMuted88: 'var(--raven-op-88)',
  ravenMuted80: 'var(--raven-op-80)',
}

export default {
  light: {
    space,
    sizes,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    breakpoints,
    colors: {
      brand: {
        primary: '#012E86',
        secondary: '#0064D9',
        accent: '#DB7C00',
        muted: '#C6DAF7',
      },
      surface: {
        sf100: 'var(--grey-100)',
        sf300: 'var(--grey-300)',
        sf500: 'var(--grey-500)',
        sf800: 'var(--grey-800)',
        sf900: 'var(--grey-900)',
        foreground: 'var(--Dove)',
      },
      bg: {
        primary: '#FFFFFF',
        secondary: '#F1F1F1',
      },
      text: {
        text100: 'var(--strom-text-storm-100)',
        text300: 'var(--storm-text-storm-300)',
        text500: 'var(--storm-text-storm-500)',
        text700: 'var(--storm-text-storm-700)',
        text900: 'var(--storm-text-storm-900)',
        
        textMuted8: 'var(--storm-op-8)',
        textMuted16: 'var(--storm-op-16)',
        textMuted24: 'var(--storm-op-24)',
        textMuted32: 'var(--storm-op-32)',
        textMuted40: 'var(--storm-op-40)',
      },
      interactive: {
        crimson1: 'var(--crimson-300)',
        crimson2: 'var(--crimson-500)',
        crimson3: 'var(--crimson-800)',

        azure1: 'var(--azure-300)',
        azure2: 'var(--azure-500)',
        azure3: 'var(--azure-800)',

        lime1: 'var(--lime-300)',
        lime2: 'var(--lime-500)',
        lime3: 'var(--lime-800)',

        corn1: 'var(--corn-300)',
        corn2: 'var(--corn-500)',
        corn3: 'var(--corn-800)',  
        
        punch1: 'var(--punch-300)',
        punch2: 'var(--punch-500)',
        punch3: 'var(--punch-800)',

        mint1: 'var(--mint-300)',
        mint2: 'var(--mint-500)',
        mint3: 'var(--mint-800)',

        sand1: 'var(--sand-300)',
        sand2: 'var(--sand-500)',
        sand3: 'var(--sand-800)',
        
        silver1: 'var(--silver-300)',
        silver2: 'var(--silver-500)',
        silver3: 'var(--silver-800)',
        ...staticInteractiveColors
      },
      constant
      // highlights: {
      //   primaryHighlight: darken(0.1, '#012E86'),
      //   primaryExtraHighlight: darken(0.2, '#012E86'),
      //   bgHighlight: darken(0.1, '#FFFFFF'),
      // },
    },
  },
  dark: {
    space,
    sizes,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    breakpoints,
    colors: {
      brand: {
        primary: '#298DFF',
        secondary: '#7CAEE8',
        accent: '#FDB447',
        muted: '#B7CBEA',
      },
      surface: {
        sf100: 'var(--dark-grey-100)',
        sf300: 'var(--dark-grey-300)',
        sf500: 'var(--dark-grey-500)',
        sf800: 'var(--dark-grey-800)',
        sf900: 'var(--dark-grey-900)',
        foreground: 'var(--Raven)'
      },
      bg: {
        primary: '#111111',
        secondary: '#262626',
      },
      text: {
        text100: 'var(--clay-text-clay-900)',
        text300: 'var(--clay-text-clay-700)',
        text500: 'var(--clay-text-clay-500)',
        text700: 'var(--clay-text-clay-300)',
        text900: 'var(--clay-text-clay-100)',
        
        textMuted8: 'var(--clay-op-8)',
        textMuted16: 'var(--clay-op-16)',
        textMuted24: 'var(--clay-op-24)',
        textMuted32: 'var(--clay-op-32)',
        textMuted40: 'var(--clay-op-40)',
      },
      interactive: {
        crimson1: 'var(--crimson-800)',
        crimson2: 'var(--crimson-500)',
        crimson3: 'var(--crimson-300)',

        azure1: 'var(--azure-800)',
        azure2: 'var(--azure-500)',
        azure3: 'var(--azure-300)',

        lime1: 'var(--lime-800)',
        lime2: 'var(--lime-500)',
        lime3: 'var(--lime-300)',

        corn1: 'var(--corn-800)',
        corn2: 'var(--corn-500)',
        corn3: 'var(--corn-300)',  
        
        punch1: 'var(--punch-800)',
        punch2: 'var(--punch-500)',
        punch3: 'var(--punch-300)',

        mint1: 'var(--mint-800)',
        mint2: 'var(--mint-500)',
        mint3: 'var(--mint-300)',

        sand1: 'var(--sand-800)',
        sand2: 'var(--sand-500)',
        sand3: 'var(--sand-300)',
        
        silver1: 'var(--silver-800)',
        silver2: 'var(--silver-500)',
        silver3: 'var(--silver-300)',
        ...staticInteractiveColors
      },
      constant
      // highlights: {
      //   primaryHighlight: lighten(0.1, '#298DFF'),
      //   primaryExtraHighlight: lighten(0.2, '#298DFF'),
      //   bgHighlight: lighten(0.1, '#111111'),
      // },
    },
  },
};
