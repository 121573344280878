import React, { useEffect, useState } from "react";
import { emptyStateText } from "../constants";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import GenericError from "../../../../components/GenericError";
import Loader from "../../../../components/Loader";
import ConceptsCardCms from "./ConceptsCard";
import { get } from "../../../../lib/api";
import { getTokenAndUid } from '../../../../lib/utils';
import Filters from '../../../../components/Filters'
import { WithFilters } from "../../../../HOC/withFiltersHOC";
import { menuItemObj } from '../../../../components/PrivatePage'
const LoaderDiv = styled.div`
  padding-top: 50px;
  height: 100vh;
  background-color: var(--Dove);
`;
const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
`;
const Spacer = styled.div`
  height: 80px;
`;

const ConceptList = ({ history, renderEmptyState, userType,filterObj, setFilterObj,qFilters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [concepts, setConcepts] = useState([]);
  const [apiError, toggleApiError] = useState(false);

  const setFilters = (obj) => {
    const QString = setFilterObj(obj);
    history.push(`${menuItemObj.audiobooks}?tab=concepts&${QString}`);
  };

  const fetchData = () => {
    setIsLoading(true);
    const conceptsListApi = "/content_api/concept_notes";
    get(conceptsListApi,{
      params: {
        view:'dashboard',
        status: (filterObj.status || [])[0],
        page_no:1
      },
    })
      .then((response) => {
        const { result } = response;
        if (Array.isArray(result)) {
          setConcepts(result);
        } else {
          setConcepts(result.concepts);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        throw error;
      });
  };

  useEffect(() => {
    fetchData();
  }, [qFilters]);

  if (isLoading) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    );
  }
  return (
    <>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      <Filters
          filterObj={filterObj}
          setFilters={setFilters}
          options={[
            {
              name: "Approved",
              value: "approved",
              filterKey: "status",
            },
            {
              name: "Rejected",
              value: "rejected",
              filterKey: "status",
            },
            {
              name: "Under Review",
              value: "under_review",
              filterKey: "status",
            },
          ]}
        />
      {!concepts && <BlankPaper />}
      {concepts &&
        !!concepts.length &&
        concepts.map((concept, index) => {
          //   <ConceptsList>{concept.show_title}</ConceptsList>
          if (concept.length === index + 4) {
            return (
              <ConceptsCardCms
                // ref={lastNovelElemRef}
                key={concept.concept_id}
                data={concept}
                history={history}
                userType={userType}
              />
            );
          } else {
            return (
              <ConceptsCardCms
                key={concept.concept_id}
                data={concept}
                history={history}
                userType={userType}
              />
            );
          }
        })}
      {concepts && !!concepts.length && <Spacer></Spacer>}
      {concepts &&
        !concepts.length &&
        renderEmptyState(
          emptyStateText["Concepts"]["text"],
          emptyStateText["Concepts"]["subtext"]
        )}
    </>
  );
};

export default withRouter(WithFilters(ConceptList));
