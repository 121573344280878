import React from 'react'
import styled from 'styled-components'
import { novelNotices } from '../constants'
import { Text } from '../../../designSystem'

export const Container = styled.div`
  padding: 40px 48px 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    padding: 24px 16px;
  }
`
export const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: var(--Text700);
  margin-bottom: 40px;
`
// const Text = styled.div`
//   font-size: 16px;
//   line-height: 1.75;
//   text-align: left;
//   color: var(--Text700);
//   margin-bottom: 16px;
// `
const OkayButtonDiv = styled.div`
  margin-top: 16px;
`
const OkayButton = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  width: 200px;
  display: flex;
  justify-content: center;
  margin: auto;
`
const Card = ({action_type, toReplace, withReplace, handleReset}) => {

  return (
    <Container>
      <Text textAlign="center" variant="titleMedium" mb={16}>Application Update</Text>
      <Text mb={8}>
        {novelNotices[action_type].title.replace(toReplace, withReplace)}
      </Text>
      <Text mb={24}>
        {novelNotices[action_type].message.replace(toReplace, withReplace)}
      </Text>
      <OkayButtonDiv>
        <OkayButton onClick={handleReset}>Okay</OkayButton>
      </OkayButtonDiv>
    </Container>
  )
}

export default Card
