import React from 'react'

const DownloadWithoutCircle = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.748 9.627a.986.986 0 0 0-1.382-.077l-2.884 2.545V3.976A.98.98 0 0 0 11.5 3a.979.979 0 0 0-.982.976v8.12L7.634 9.55a.986.986 0 0 0-1.382.077.972.972 0 0 0 .078 1.383l4.037 3.562a1.71 1.71 0 0 0 2.266 0l4.037-3.563a.972.972 0 0 0 .078-1.382z" fill="#068ae2"/>
        <path d="M20.026 15a.968.968 0 0 0-.974.962v1.766c0 .73-.615 1.348-1.396 1.348H5.344c-.781 0-1.396-.617-1.396-1.348v-1.766A.968.968 0 0 0 2.974 15a.968.968 0 0 0-.974.962v1.766C2 19.544 3.512 21 5.344 21h12.312C19.488 21 21 19.544 21 17.728v-1.766a.968.968 0 0 0-.974-.962z" fill="#068ae2"/>
    </svg>

)

export default DownloadWithoutCircle
