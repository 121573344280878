import React from 'react'
import { useField } from 'formik'
import GenericUpload from '../../GenericUpload'

const Upload = ({ ...props}) => {
  const [field, meta] = useField(props)
  const { value :  imageUrl } = field
  const { onChange } = field
  
  const handleOnChange = url => {
    onChange({ target: { name: props.name, value: url } })
  }

  return(
    <GenericUpload
      reducedLabelMargin
      onChange={handleOnChange}
      imageUrl={imageUrl}
      {...props}
    />
  ) 
}

Upload.defaultProps = {
  accept: 'image/jpeg,image/jpg,image/png',
  maxFileSize: '20971520'
}

export default Upload
