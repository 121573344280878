import React from 'react'

const Podcasts = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" d="M12 21h7c1.105 0 2-.895 2-2v-8h-1v8c0 .552-.448 1-1 1h-7v1z"/>
    <path fill="#fff" fillRule="evenodd" d="M5 3c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2V5c0-1.105-.895-2-2-2H5zm4.6 10.914l4.152-2.546c.33-.203.33-.533 0-.736L9.6 8.086c-.332-.203-.6-.035-.6.374v5.08c0 .41.268.577.6.374z" clipRule="evenodd"/>
  </svg>
)

export default Podcasts
