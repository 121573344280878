import React from 'react'

const LikeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#E51A4D"
      d="M3.79 5.423c-1.72 1.675-1.72 4.39 0 6.066L9.969 17.5l.032-.031.032.031 6.177-6.011c1.721-1.675 1.721-4.391 0-6.066C14.496 3.756 11.723 3.748 10 5.4c-1.723-1.652-4.496-1.644-6.21.023z"
    />
  </svg>
)

export default LikeIcon
