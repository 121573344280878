import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { FloatingInput as Input, Upload } from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import Axios from 'axios'
import FileIcon from '../../../../../components/Images/File'
import { post, get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { Popup } from '../../../../../components/Popup'
import Dustbin from '../../../../../components/Images/Dustbin'
import BigCrossIcon from '../../../../../components/Images/CrossIcon'
import { convertUrlToHttps, getNameFromUrl } from '../../../../../lib/utils'
import CrossIcon from '../../../../../components/Images/SmallCross'
import AddIcon from '../../../../../components/Images/AddIcon'
import InfoIcon from '../../../../../components/InfoIcon'
import { withRouter } from 'react-router-dom'
import ReadOnlyEditor from '../../../../../components/TextEditor/readOnlyEditor'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button } from '../../../../../designSystem'
import moment from "moment";

const StyledFormCloseIcon = styled(BigCrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const Details = styled.div`
  padding: 28px 24px 24px 24px;
`
const Actions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  // background-color: var(--Grey100);
`
const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Punch500);
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
`
export const EditHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
`
const FileContainer = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Azure500)'};
  background-color: var(--Grey300);
  cursor: pointer;
`
const FilePlaceHolder = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Azure500);
`
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;

  @media (max-width:600px){
    display: flex;
    position: unset;
    justify-content: flex-start;
  }
`

const UploadContainer = styled(Upload)`
  position: static !important;
`

export const StatusContainer = styled.div`
  position: relative;
`
export const SelectedStatus = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 24px;
  border: ${({ selectedStatus }) => selectedStatus === 'pending' ? 'solid 1px rgba(242,198,2,0.2)' : selectedStatus === 'approved' ? 'solid 1px rgba(121,186,16,0.2)' : 'solid 1px rgba(221, 54, 35, 0.2)'};
  background-color: ${({ selectedStatus }) => selectedStatus === 'pending' ? 'rgba(242,198,2,0.1)' : selectedStatus === 'approved' ? 'rgba(121,186,16,0.1)' : 'rgba(221, 54, 35, 0.1)'};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${({ selectedStatus }) => selectedStatus === 'pending' ? 'var(--Corn800)' : selectedStatus === 'approved' ? 'var(--Lime800)' : 'var(--Punch800)'};
  padding: 8px 12px;
`
export const StatusesDropdownBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  background-color: #ffffff;
  width: 230px;
  z-index: 10;
`
export const StatusSingle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ middle }) => (middle ? '#f0f1f5' : '#ffffff')};
  padding: 16px 24px;
`
export const StatusSingleText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-left: 8px;
  text-transform: capitalize;
`
export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ issues, review }) =>
    review ? '#f2c602' : issues ? '#dd3623' : '#79ba10'};
`

const FileLabelDownloadable = styled.a.attrs(props => ({
  href: props.href,
  download: props.download,
}))`
  border: 1px solid #e0e2eb;
  padding: 10px;
  color: #000;
  margin-top: 5px;
  display: inline-block;
`;

const FileIconStyled = styled(FileIcon)`
  vertical-align: middle;
  margin-right: 5px;
`;

const ProgressDiv = styled.div`
  padding: 14px 16px;
  background-color: var(--Grey100);
  border-radius: 4px;
  display: flex;
  align-items: center;
`
const ProgressPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
  margin-right: 10px;
`
const LinearProgress = styled.div`
  width: calc(100% - 140px);
  height: 8px;
  border-radius: 2px;
  background-color: var(--Grey900);
  overflow: hidden;
  margin-right: 16px;
`
const ProgressBar = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: var(--Azure500);
`
const StyledCrossIcon = styled(CrossIcon)`
  z-index: 2;
  cursor: pointer;
`

const FileNameDiv = styled.div`
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  margin: 10px;
  width: 100%;
  &:hover ${HoverActions} {
    display: flex;
  }
`

const ParentUpload = styled.div`
  &:hover ${HoverActions} {
    display: flex;
  }
`

const TopDiv = styled.div`
  display: flex;
`
const BottomDiv = styled.div``

const FileIconDiv = styled.div`
  margin-right: 8px;
`

const FileDetailsDiv = styled.div``

const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const UploadTime = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text300);
`
const FileMeta = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text300);
`

const DownloadButton = styled.a`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const OpenEditorButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
`
const DownloadPdfButton = styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const UploadButton = styled.button`
  outline: none;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  background: none;
  cursor: pointer;
`

export const FileUploadComponent = ({
  readOnly,
  isLoading,
  progress,
  imageUrl,
  placeholder,
  handleReUpload = () => null,
  fileMeta,
  title,
  hasError,
  cancelRequest
}) => {
  return (
    <>
      {/* {!imageUrl && !isLoading && (
        <FileContainer hasError={hasError}>
          {placeholder && <FilePlaceHolder>{placeholder}</FilePlaceHolder>}
        </FileContainer>
      )} */}
      {isLoading && (
        <ProgressDiv>
          <LinearProgress>
            <ProgressBar value={progress >= 100 ? 100 : progress} />
          </LinearProgress>
          <ProgressPercent>
            {(progress >= 100 ? '100' : progress.toFixed(0)) + '% Uploaded'}
          </ProgressPercent>
          <StyledCrossIcon onClick={cancelRequest}/>
        </ProgressDiv>
      )}
      {!isLoading && (
        <ParentUpload>
          <TopDiv>
            <FileIconDiv>
              <FileIcon />
            </FileIconDiv>
            <FileDetailsDiv>
              <FileName>{title}</FileName>
              {fileMeta && <FileMeta>
                {fileMeta.words ? `${fileMeta.words} words` : ''}
                {/* {fileMeta.words && fileMeta.size && ' • '} */}
                {/* {fileMeta.size} */}
              </FileMeta>}
            </FileDetailsDiv>
          </TopDiv>
          <BottomDiv>
            <HoverActions>
              <DownloadButton href={title} download type="button">
                Download
              </DownloadButton>
              {!readOnly && <UploadButton type="button" onClick={handleReUpload}>
                Upload
              </UploadButton>}
            </HoverActions>
          </BottomDiv>
        </ParentUpload>
      )}
    </>
  )
}

const ViewDetails = ({
  book,
  handleCancel,
  data={}
}) => {

  useEffect(() => {
    
  }, []);

  return (
    <Box mb={8} borderRadius={4}>
      <Box variant="flex" p={20}>
        <StyledFormCloseIcon onClick={handleCancel} />
      </Box>
        <Formik
          initialValues={{
            chapter_title: data['chapter_title'] || '',
            file_url: data['file_url'] || '',
            description: data['description'] || '',
          }}
          validationSchema={Yup.object({
            chapter_title: Yup.string().required('Required'),
            // description: Yup.string().required('Required'),
            file_url: Yup.string().required('Required'),
          })}
          // isInitialValid={mode === 'edit'}
          onSubmit={(values, { setSubmitting }) => {
          }}
        >
        {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
          <Form>
            <Box variant="flex" alignItems="center" mb={8} p={24} justifyContent="flex-start" flexDirection="column">
        <Title variant="titleSecondary">Documents to Download:</Title>
        {book.book_docs.length && book.book_docs.map((bookDoc) =>
         <FileNameDiv>
            <TopDiv>
              <FileIconDiv>
                <FileIcon />
              </FileIconDiv>
              <FileDetailsDiv>
                <FileName>{bookDoc.document_type}</FileName>
                <UploadTime>{`Last updated : ${moment(bookDoc.create_time).format('MMMM Do YYYY, h:mm:ss a')}`}</UploadTime>
              </FileDetailsDiv>
            </TopDiv>
            <BottomDiv>
              <HoverActions>
                <DownloadButton href={bookDoc.file_url} download={bookDoc.file_url} type="button">
                  Download
                </DownloadButton>
              </HoverActions>
            </BottomDiv>
          </FileNameDiv>)}
        </Box>
            <Actions>
              {/* {mode === 'edit' && <DeleteButton onClick={() => setDeletePopupShow(true)} type="button">Delete</DeleteButton>} */}
              {/* <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton> */}
              {/* {!isSubmitting && <SaveButton type="submit" isValid={isValid}>
                  Save
              </SaveButton>}
              {isSubmitting && <SaveButton type="button" isValid={isValid}>
                  <Loader invertColor inline size="sm" />
              </SaveButton>} */}
              {/* <Button type={isSubmitting ? "button" : "submit"} isLoading={isSubmitting}>
                Save
              </Button> */}
            </Actions>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default withRouter(ViewDetails)
