import React from 'react'
import studioLogo from './logo-dark.svg'

export default class NavBarBranding extends React.Component {

    render(){
        return(
            <div className= 'brading-main'>
                <img src={studioLogo}>
                </img>
            </div>
        )
    }
}
