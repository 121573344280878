import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import ClampLines from 'react-clamp-lines'
import { useDidUpdate, useCreatorProfile, useInfiniteScroll } from '../../../../../lib/customHooks'
import SubHeader from '../../../../../components/WithSubHeaderBG'
import { get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { getMockData } from './mock'
import AuthorIcon from '../../../../../components/Images/AuthorIcon'
import InfoIcon from '../../../../../components/InfoIcon'
import FileIcon from '../../../../../components/Images/File'
import { getNameFromUrl } from '../../../../../lib/utils'
import RjGirl from '../../../../../components/Images/RjGirl'
import Arrow from '../../../../../components/Images/ArrowRight'
import AuditionCard from './auditionCard'
import AddEditEntry from './addEditEntry'
import { getTopicNameFromId } from '../../../../../lib/utils'
import GenericError from '../../../../../components/GenericError'
import { TNCContent, TNCPara, TNCTitle } from '../List'
import { FullScreenNotice } from '../../../../../components/Popup'
import { descriptionText } from '../../constants'
import RequirementForm from '../../Unpublished/SetupAudition/RequirementsForm'
import { isAdminAccess } from '../../../../../config'
import CreatorInfo from '../../../../../components/CreatorInfo'
import { ScriptEditComponent } from '../../Unpublished/Edit/addEditEpisode'
import moment from 'moment'
import { Box,Image, Text } from '../../../../../designSystem'

const CategorySpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
`
const BookDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-bottom: 12px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    line-height: 1.8;
    color: var(--Text500);
    outline: none;
  }
`
const AuthorDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ProfileImageDiv = styled.div`
  position: relative;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const AuthorName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 8px;
`
const InfoText = styled.span`
  display: inline-block;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgba(242, 198, 2, 0.2);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Corn800);
`
const RateDivContainer = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: var(--Grey100);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const DownLoadScriptTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-bottom: 8px;
`
const DownloadScriptDiv = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  border: solid 1px var(--Grey900);
`
const FileNameDiv = styled.div`
  display: flex;
`
const DownloadButton = styled.a`
  outline: none;
  padding: 8px 12px;
  font-size: 14px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  border-radius: 4px;
  background-color: var(--Azure500);
`
const FileDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const FileName = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const FileMeta = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text300);
`
const RateDiv = styled.div``
const AgreementLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Azure500);
`
const BasePrice = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--Text700);
  margin-bottom 4px;
`
const PriceInfo = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`
const EditRequiremtButton = styled.div`
  cursor: pointer;
  margin-left: 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #068ae2;
  border-radius: 4px;
  background-color: rgba(6, 138, 226, 0.1);
  padding: 8px 12px;  

  @media(max-width:600px){
    width: 30%;
    margin-right: 16px;
  }
`
const Timeline = styled.div`
  border-radius: 4px;
  background-color: var(--Grey300);
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: auto;

  @media(max-width: 600px){
    width : 100%;
    margin: 8px 8px 8px 0px;
  }
`
const FileIconDiv = styled.div`
  margin-right: 8px;
`
const SubmitCta = styled.div`
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 16px 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  display: inline-block;

  svg {
    margin-left: 5px;
    vertical-align: bottom;
  }
`
const EntryCount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Spacer = styled.div`
  height: 80px;
`
const Submit = (props) => {
  const {
    location: { search },
  } = props
  const qParams = queryString.parse(search)
  const { id } = qParams
  const [auditionbook, setBookDetails] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isAddEntryBoxShown, setAddEntryBoxDisplay] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [isTNCPopupShown, setTNCPopupDisplay] = useState(false)
  const [editId, setEditId] = useState(null)
  const [editRequirement, setEditRequirement] = useState(false)
  const { isDrawerShowing, setDrawerShowing, onUserClick, userInfo } = useCreatorProfile()
  const [nextUrl, setNextUrl] = useState(null)
  const isFetching = useInfiniteScroll(fetchMoreData)
  const isMobile  = window.screen.width < 600
  useEffect(() => {
    fetchData()
  }, [])

  useDidUpdate(() => {
    setLoading(false)
  }, [auditionbook])

  const fetchData = () => {
    setLoading(true)
    const bookDetailsApi =
      '/content_api/book.book_auditions'
    get(bookDetailsApi, { params: { book_id: id } })
      .then((response) => {
        const { results } = response
        const { next_url } = results
        setBookDetails(results)
        setNextUrl(next_url)
      })
      .catch((error) => {
        setLoading(false)
        throw error
      })
  }

  function fetchMoreData(setIsFetching) {
    if (!nextUrl) {
      setIsFetching(false)
      return
    }
    get(nextUrl)
      .then((response) => {
        const { results } = response
        const { next_url, auditions } = results
        setBookDetails(prevState => ({...results, auditions: prevState.auditions.concat(auditions)}))
        setNextUrl(next_url)
        setIsFetching(false)
      })
      .catch((error) => {
        setIsFetching(false)
        throw error
      })
  }

  const successAddEntryCB = () => {
    setAddEntryBoxDisplay(false)
    fetchData()
  }

  const successEditEntryCB = () => {
    setEditMode(false)
    fetchData()
  }

  const successRatingsSubmit = () => {
    fetchData()
  }

  const handleEditClick = (id) => {
    setEditMode(true)
    setEditId(id)
  }
  const successRequirementSubmit = () => {
    setEditRequirement(false)
    fetchData()
  }

  const {
    has_participated,
    image_url,
    book_id,
    book_title,
    category_obj: { topic_name } = {},
    description,
    auditions_count,
    auditions,
    author_info: { author_id, fullname, image_url: authorImage } = {},
    audition_info: {
      last_participation_date,
      description: requirementDesc,
      requirements,
      base_price,
      audition_script_url,
      created_by } = {},
  } = auditionbook || {}
  if (isLoading) {
    return (
      <div style={{ marginTop: '200px' }}>
        <Loader />
      </div>
    )
  }

  const requirementArr = (requirements || '').split(',')
  const req1 = requirementArr[0] || ''
  const req2 = requirementArr[1] || ''
  const hasLastDatePassed = moment().isAfter(moment(last_participation_date))
  return (
    <>
      <CreatorInfo
        show={isDrawerShowing}
        userInfo={userInfo}
        close={() => setDrawerShowing(false)}
      />
      <FullScreenNotice
        show={isTNCPopupShown}
        close={() => setTNCPopupDisplay(false)}
        withCloseButton
      >
        <TNCTitle>Terms and Conditions for Company Name</TNCTitle>
        <TNCContent>
          {descriptionText.acceptAudition.map((text, i) => (
            <TNCPara key={'audition-accept-terms-' + i}>{text}</TNCPara>
          ))}
          {descriptionText.acceptConditions.map((text, i) => (
            <p key={'audition-accept-conditions-' + i}>{text}</p>
          ))}
        </TNCContent>
      </FullScreenNotice>
      <GenericError 
        show={apiError}
        onClose={() => toggleApiError(false)}
      />
      {/* {!books && (
        <BlankPaper />
      )} */}
      {auditionbook && (
        <Box width={["100vw", "100%"]}>
          <SubHeader title="Audiobook Details" showBackButton>
          <Box p={[16, 24]} mb={8} position="relative" bg="surface.foreground" >
            <Box variant="flex" justifyContent="flex-start" flexDirection={["column","row"]}>
              {!isMobile && <Image width={[60,144]} height={[60,144]} src={image_url} mr={24}/>}
              {isMobile && <Box variant="flex" flexDirection="row" justifyContent="flex-start">
              <Image width={[60,144]} height={[60,144]} src={image_url} mr={8}/>
              <Text variant="titleSecondary">
                  {book_title}
                  {topic_name && <CategorySpan>{topic_name}</CategorySpan>}
                </Text>
              </Box>}
              <Box mt={[8,0]}>
                {!isMobile && <Text variant="titleSecondary">
                  {book_title}
                  {topic_name && <CategorySpan>{topic_name}</CategorySpan>}
                </Text>}
                {description && <BookDescription><ClampLines lines={2} text={description} id="audition-description"/></BookDescription>}
                <AuthorDetails onClick={() => onUserClick(author_id)}>
                  <ProfileImageDiv>
                    <ProfileImage src={authorImage} />
                    <StyledAuthorIcon />
                  </ProfileImageDiv>
                  <AuthorName>{fullname}</AuthorName>
                </AuthorDetails>
              </Box>
            </Box>
          </Box>
          {editRequirement && (
            <RequirementForm 
              id={book_id}
              enableCmSearch
              mode="edit"
              data={{
                last_participation_date,
                description: requirementDesc,
                requirements,
                base_price,
                audition_script_url,
                created_by
              }}
              cancelCB={() => setEditRequirement(false)}
              successCB={successRequirementSubmit}
            />
          )}
          {!editRequirement && <Box p={[16, 24]} mb={8} bg="surface.foreground" justifyContent="flex-start" >
            <Box variant="flex" pb={12} mb={8} borderBottom="1px solid var(--Grey900)" flexDirection={["column","row"]} justifyContent="flex-start">
              <Text variant="title">Audiobook Requirement</Text>
              {isAdminAccess && <EditRequiremtButton onClick={() => setEditRequirement(true)}>Edit Details</EditRequiremtButton>}
              {last_participation_date && <Timeline>Participate before {moment(last_participation_date).format('DD MMM YYYY, hh:mm A')}</Timeline>}
            </Box>
            <Text variant="titleSecondary" fontWeight={500} >{requirementDesc}</Text>
            <Box mb={16} mt={10}>
              {req1 && <InfoText>{req1}</InfoText>}
              {req2 && <InfoText>{req2}</InfoText>}
            </Box>
            {/* <RateDivContainer>
              <RateDiv>
                <BasePrice>{base_price}</BasePrice>
                <PriceInfo>
                  Basic Compensation and Revenue Share
                  <InfoIcon title="Complete details about this audition" />
                </PriceInfo>
              </RateDiv>
              <AgreementLink onClick={() => setTNCPopupDisplay(true)}>View recording agreement</AgreementLink>
            </RateDivContainer> */}
            <Text variant="caption" mb={12}>
              Download the script given below and then upload your entry.
            </Text>
            {/* <DownloadScriptDiv>
              <FileNameDiv>
                <FileIconDiv>
                  <FileIcon />
                </FileIconDiv>
                <FileDetailsDiv>
                  <FileName>{getNameFromUrl(audition_script_url)}</FileName>
                </FileDetailsDiv>
              </FileNameDiv>
              <DownloadButton href={audition_script_url} download>Download</DownloadButton>
            </DownloadScriptDiv> */}
            <ScriptEditComponent
              readOnly
              resourceUrl={audition_script_url}
              handleOpenEditor={() => null}
              handleOpenFreshEditor={() => null}
              title="audition-requirement"
            />

          </Box>}
          {!hasLastDatePassed && (!has_participated || isAdminAccess) && !isAddEntryBoxShown && (
            <Box variant="flex" flexDirection={["column","row"]} bg="#fdfdfd" mb={8}>
              <Box p={[16, 24]}>
                <Text variant="title" fontSize={18}>Submit your Entry to Participate</Text>
                <Text variant="caption" mt={8} mb={[16, 24]}>
                  Once you have read the audiobook details and you are ready to submit your entry, simply click on the "Participate Now" button to participate
                </Text>
                <SubmitCta onClick={() => setAddEntryBoxDisplay(true)}>
                  Participate Now
                  <Arrow />
                </SubmitCta>
              </Box>
              {!isMobile && <Box>
                <RjGirl />
              </Box>}
            </Box>
          )}
          {isAddEntryBoxShown && (
            <AddEditEntry
              book_id={book_id}
              successCallback={successAddEntryCB}
              cancelCallback={() => setAddEntryBoxDisplay(false)}
              book_title={book_title}
              mode="add"
            />
          )}
          {/* {auditions_count > 0 && <EntryCount>
            {auditions_count}{auditions_count > 1 ? ' Entries' : ' Entry'}
          </EntryCount>} */}
          {auditions_count > 0 && <>
            <Box variant="flexCard" p={[16, 24]} borderTopLeftRadius="4px" borderTopRightRadius="4px">
              <Text variant="heading">
              {auditions_count}{auditions_count > 1 ? ' Entries' : ' Entry'}
              </Text>
            </Box>
            <Box variant="hr"></Box>
          </>
          }
          {
            auditions.length > 0 && auditions.map((audition, index) => {
              const isOwner = has_participated && index === 0
              const isThisBeingEditedByAdmin = isAdminAccess && (editId === audition.audition_id)
              if (((isOwner && !isAdminAccess) || isThisBeingEditedByAdmin) && isEditMode) {
                return (
                  <AddEditEntry
                    data={audition}
                    key={audition.audition_id}
                    book_id={book_id}
                    mode='edit'
                    cancelCallback={() => setEditMode(false)}
                    successCallback={successEditEntryCB}
                    book_title={book_title}
                  />
                )
              }
              return (
                <AuditionCard data={audition} key={audition.audition_id} isOwner={isOwner} handleEditClick={() => handleEditClick(audition.audition_id)} successCallback={successRatingsSubmit}/>
              )
            })
          }
          {auditions &&
            !!auditions.length && <Spacer>
            {isFetching && <Loader />}
          </Spacer>}
        </SubHeader>
      </Box>
      )}
    </>
  )
}

export default Submit
