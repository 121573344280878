import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import CaretDowm from '../Images/CaretDown';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '../Images/ExpandLess'
import ExpandMore from '../Images/ExpandMore'
import Analytics from '../Images/Analytics'
import Audiobooks from '../Images/Audiobooks'
import Comments from '../Images/Comments'
import Podcasts from '../Images/Podcasts'
import Star from '../Images/Star'
import Audience from '../Images/Audience'
import NovelsIcon from '../Images/NovelsIcon';
import Tools from '../Images/Tools';
import Beaker from '../Images/Beaker';
import Campaign from '../Images/Campaign';

const drawerWidth = 256;

const SvgCss = css`
  height: 24px;
  width: 24px;
  margin-right: 16px;
`

const AnalyticsIcon = styled(Analytics)`
  ${SvgCss}
`
const AudienceIcon = styled(Audience)`
  ${SvgCss}
`
const PodcastsIcon = styled(Podcasts)`
  ${SvgCss}
`
const AudiobooksIcon = styled(Audiobooks)`
  ${SvgCss}
`
const NovelIcon = styled(NovelsIcon)`
  ${SvgCss}
`
const ToolIcon = styled(Tools)`
  ${SvgCss}
`
const StarIcon = styled(Star)`
  ${SvgCss}
`
const CommentsIcon = styled(Comments)`
  ${SvgCss}
`
const ExperimentsIcon = styled(Beaker)`
  ${SvgCss}
`
const CampaignIcon = styled(Campaign)`
  ${SvgCss}
`
const Main = styled.main`
  padding: 0;
  flex-grow: 1;
  // background-color: var(--Grey300);
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.surface.sf300};
`
const MenuTextDiv = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2px;
  justify-content: space-between;
`
const MenuTextLabelBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const SubLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  color: var(--TextDark300);
`
const ParentMenuText = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  color: var(--TextDark700);
`
const ChildMenuText = styled(ParentMenuText)`
  font-size: 14px;
  line-height: 1.35;
`

const StyledExpandMore = styled(ExpandMore)`
  fill: var(--TextDark700);
  display: none;
`
const StyledExpandLess = styled(ExpandLess)`
  fill: var(--TextDark700);
  display: none;
`

const StyledListItemText = styled(ListItemText)`
  font-size: 14px;
  padding-top: ${({ parent }) => parent ? '8px' : '0px'};
  padding-bottom: ${({ parent }) => parent ? '8px' : '0px'};
  font-weight: bold;
  line-height: 1.35;
  color: var(--TextDark700);
`
const StyledListItem = styled.div`
  background-color: ${({ active, parent }) => active && !parent ? '#242630' : active ? 'var(--DarkGrey100)' : 'var(--Raven)'};
  margin-bottom: ${({ parent, expanded }) => parent && !expanded ? '2px' : '0'};
  border-left: ${({ active, hasChildren }) => active && !hasChildren ? '4px solid var(--Azure500)' : 'none'};
  &:hover {
    background-color: #2f323b;
    ${StyledExpandLess}, ${StyledExpandMore} {
      display: inline-block;
    }
  }
  ${SubLabel} {
    display: ${({ expanded }) => expanded ? 'none' : 'block'};
  }
`
const AccountOptionscontainer = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  width: 160px;
  padding: 16px;
  position: absolute;
  top: 35px;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  border-radius: 4px;

  @media (max-width:600px){
    right: -40px;
  }
`
const ProfileOption = styled.div`
  border-radius: 4px;
  // box-shadow: 0 12px 24px 0 rgba(73, 83, 89, 0.16);
  // background-color: var(--Dove);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.35;
  color: var(--Text500);
  padding: 10px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`
const LogoContainer = styled.div`
  height: 88px;
  padding: 24px 18px;
`
const ToolbarContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const ProfilePicNameContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 32px;
  position: relative;
  margin-left: auto;

  @media (max-width:600px){
    margin-right: 16px;
  }
`
const ProfilePicInner = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0;
  @media (max-width:600px){
    margin-right: 0;
  }
`
const BellContainer = styled.div`
  position: relative;
  align-self: center;
  display: flex;
  cursor: pointer;
  margin-left: 24px;
`
const NotificationCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  margin: 0 0 8px 12px;
  border: solid 1px #ffffff;
  border-radius: 50%;
  background-color: #068ae2;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  color: rgba(255, 255, 255, 0.99);
`

const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
`
const ProfileImageContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 12px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--Dove);
  overflow: hidden;

  @media (max-width:600px){
    margin-right: 0;
  }
`
const NameSpan = styled.span`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.35;
  color: var(--Text500);
  text-transform: capitalize;
`

const CaretIcon = styled(CaretDowm)`
  width: 10px;
  height: 9px;
  fill: var(--Text500);
`

// write material ui styles below

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: 'var(--Dove);',
    boxShadow: 'none',
    borderBottom: '1px solid var(--Grey900)',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: '56px'
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'var(--DarkGrey100)',
    zIndex: '3200'
  },
  content: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: 'var(--Grey300)',
    // height: '100vh',
    // marginTop: '57px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  stickyHead: {
    minHeight: '56px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  menuExpand: {
    // fill: 'var(--TextDark700)'
    fontSize: '22px'
  },
  paddingParent: {
    paddingTop: '17px',
    paddingBottom: '17px',
    paddingLeft: '24px'
  },
  paddingChild: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '24px'
  },
  paddingChildActive: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px'
  },
  extraNode: {
    padding: 0
  },
  entered: {
    width: '100%'
  }
}));

export {
  useStyles,
  Main,
  StyledListItemText,
  LogoContainer,
  ProfilePicNameContainer,
  ProfilePicInner,
  ProfileImageContainer,
  ProfileImage,
  NameSpan,
  CaretIcon,
  StyledListItem,
  StyledExpandMore,
  StyledExpandLess,
  AudienceIcon,
  AudiobooksIcon,
  AnalyticsIcon,
  PodcastsIcon,
  StarIcon,
  CommentsIcon,
  ParentMenuText,
  ChildMenuText,
  MenuTextDiv,
  MenuTextLabelBox,
  SubLabel,
  AccountOptionscontainer,
  ProfileOption,
  NovelIcon,
  BellContainer,
  NotificationCount,
  ToolbarContentDiv,
  ToolIcon,
  ExperimentsIcon,
  CampaignIcon
}
