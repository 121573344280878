import React from 'react'

const AuthorIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
    <circle cx="7" cy="7" r="6.5" fill="#068AE2" stroke="#fff"/>
    <path fill="#fff" d="M11.142 5.692c.213-.306.139-.728-.166-.942l-2.66-1.862c-.306-.214-.727-.14-.942.166l-.513.733 3.768 2.637.513-.732zM7.127 8.442c-.196.278-.58.346-.859.151-.278-.195-.346-.58-.151-.858.195-.279.58-.347.858-.152.279.196.347.58.152.859z"/>
    <path fill="#fff" d="M9.454 6.152L7.472 4.765l-1.38.523c-.704.265-1.202.899-1.295 1.645l-.516 4.117 1.577-2.217c-.381-.39-.406-1.005-.058-1.425.348-.42.957-.51 1.411-.21.455.302.61.898.358 1.382-.251.484-.827.7-1.335.502l-1.57 2.206 3.668-1.88c.67-.343 1.094-1.028 1.103-1.78l.019-1.476z"/>
  </svg>
)

export default AuthorIcon
