import React from 'react'

const Audience = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" fillOpacity=".9" d="M12 9.797c1.877 0 3.398-1.522 3.398-3.399C15.398 4.522 13.877 3 12 3c-1.877 0-3.398 1.522-3.398 3.398 0 1.877 1.521 3.399 3.398 3.399zM18.875 9.797c1.186 0 2.148-.962 2.148-2.149 0-1.186-.962-2.148-2.148-2.148-1.187 0-2.148.962-2.148 2.148 0 1.187.961 2.149 2.148 2.149zM5.125 9.797c1.187 0 2.148-.962 2.148-2.149 0-1.186-.961-2.148-2.148-2.148-1.187 0-2.148.962-2.148 2.148 0 1.187.961 2.149 2.148 2.149zM7.242 11.726c-.846-.693-1.612-.601-2.59-.601C3.19 11.125 2 12.308 2 13.76v4.266c0 .631.515 1.145 1.149 1.145 2.735 0 2.406.05 2.406-.118 0-3.023-.358-5.24 1.687-7.328z"/>
    <path fill="#fff" fillOpacity=".9" d="M12.93 11.14c-1.708-.142-3.192.002-4.473 1.06-2.143 1.716-1.73 4.027-1.73 6.854 0 .748.608 1.368 1.368 1.368 8.245 0 8.573.266 9.062-.817.16-.366.116-.25.116-3.753 0-2.782-2.409-4.711-4.343-4.711zM19.348 11.125c-.983 0-1.745-.09-2.59.601 2.03 2.073 1.687 4.138 1.687 7.328 0 .168-.273.118 2.365.118.657 0 1.19-.532 1.19-1.185V13.76c0-1.453-1.19-2.636-2.652-2.636z"/>
  </svg>
)

export default Audience
