import React from 'react'
import styled from 'styled-components'
import Done from '../Images/DoneStep'

const Label = styled.span`
  width: ${({ stepsCount, length }) => 30 + length - (length / stepsCount)}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.text500};
  font-size: ${({ theme }) => theme.fontSizes.D.XS};
  line-height: ${({ theme }) => theme.lineHeights.D.XS};
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`
const StepLabels = styled.div`
  display: flex;
  justify-content: space-between;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-width: 600px){
    margin-top: 24px;
  }
`
const StepsIcons = styled.div`
  display: flex;
  align-items: center;
  // padding: 0 22px 8px 22px;
  padding-bottom: 8px;

`
const StepIcon = styled.div`
  width: 20px;
  height: 20px;
  border: solid 2px ${({ color }) => color};
  border-radius: 50%;
  background-color: ${({ isCurrent, color }) => isCurrent ? color : 'transparent'};
`
const ProgressLine = styled.div`
  width: ${({ length }) => length}px;
  height: 2px;
  background-color:  ${({ color }) => color};
`
const DoneIcon = styled(Done)`
  fill: ${({ fill }) => fill};
`

const StepIndicator = ({ steps = [], current, primaryColor, hideLabels, armLength = 129 }) => (
  <Container>
  <StepsIcons>
    {steps.map((step, i) => (
      <React.Fragment key={step}>
        {i + 2 > current && <StepIcon isCurrent={current === (i + 1)} color={primaryColor || "var(--Azure500)"} />}
        {i + 1 < current && <DoneIcon fill={primaryColor || "var(--Azure500)"} />}
        {(i !== (steps.length - 1)) && <ProgressLine color={primaryColor || "var(--Azure500)"} length={armLength} />}
      </React.Fragment>
    ))}
  </StepsIcons>
  {!hideLabels && <StepLabels>
    {steps.map(step => (
      <Label key={step} stepsCount={steps.length} length={armLength}>
        {step}
      </Label>
    ))}
  </StepLabels>}
  </Container>
)

export default StepIndicator
