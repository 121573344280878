export const addValues = (object, otherKey) => {
  let value = []
  for (let [key] of Object.entries(object)) {
    if (key !== 'other' && key !== otherKey) {
      if (object[key] !== '') {
        value.push(key)
      }
    }
  }
  return value
}

export const getVoiceObject = (type, value, typeObject) => {
  value.forEach((elm) => typeObject[elm.voice_range_type] = type + '.' + elm.voice_range_type)
  return typeObject
}

export const getExperienceObject = (type, value, typeObject) => {
  value.forEach((elm) => {
    if(typeObject[elm.experience_type] === '') typeObject[elm.experience_type] = type + '.' + elm.experience_type
    else typeObject['other'] = type + '.other'
  })
  return typeObject
}

export const getVTextureObject = (type, value, typeObject) => {
  value.forEach((elm) => typeObject[elm.voice_texture_type] = type + '.' + elm.voice_texture_type)
  return typeObject
}
