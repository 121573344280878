import React from 'react'
import { Box, Text } from '../../../designSystem'
import { EntityImageDiv, EntityImage } from '../styles'

const EntityDetail = ({ comment, isComment }) => {
  const commentInfo = isComment
    ? comment.story_show_info && comment.story_show_info.story_details
    : comment.show_info
  return (
    <Box
      variant="flex"
      mt={16}
      ml={[0, 24]}
      px={[8, 0]}
      mr={16}
      pb={16}
      width={['100vw', 315]}
      justifyContent="flex-start"
    >
      <EntityImageDiv>
        {commentInfo && <EntityImage src={commentInfo.image_url} />}
      </EntityImageDiv>
      <Box
        variant="flex"
        flexDirection="column"
        ml={8}
        justifyContent="flex-start"
      >
        <Box>
          {commentInfo && (
            <Text variant="title">
              {isComment ? commentInfo.story_title : commentInfo.show_title}
            </Text>
          )}
        </Box>
        <Box mt={4}>
          {isComment && commentInfo && (
            <Text variant="subtext">
              {comment.story_show_info &&
                comment.story_show_info.show_details &&
                comment.story_show_info.show_details.show_title}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EntityDetail
