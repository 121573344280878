import styled from 'styled-components'
import AuthorIcon from '../../../components/Images/AuthorIcon'
import Arrow from '../../../components/Images/ArrowRight'
import AddIcon from '../../../components/Images/AddIcon'
import Search from '../../../components/Images/Search'
import CalendarIcon from '../../../components/Images/CalendarOutlineIcon'
import Eye from '../../../components/Images/Eye'
import EmptyNovel from '../../../components/Images/EmptyNovel'
import Vellip from '../../../components/Images/Vellip'
import { Link } from 'react-router-dom'
import { Button } from '../../../designSystem'

const EmptyBody = styled.div`
  margin-top: 17px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--Text500);
  @media (max-width: 600px) {
    max-width: 350px;
    margin: auto;
    margin-top: 40px;
  }
`
const EmptyButtonText = styled.label`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--TextDark700);
`
const EmptyButton = styled.button`
  cursor: pointer;
  width: 232px;
  height: 48px;
  margin-top: 26px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 4px 12px 0 rgba(6, 138, 226, 0.16);
  background-color: var(--Azure500);
`
const EmptyHeader = styled.span`
  margin-top: 40px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--Text700);
`
const EmptyChapterHeader = styled.span`
  margin-top: 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--Text700);
  @media (max-width: 600px) {
    max-width: 350px;
  }
`
const SingleBook = styled.div`
  cursor: pointer;
  display: flex;
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
`
const CMSSingleBook = styled.div`
  cursor: pointer;
  margin-top: 1px;
  display: flex;
  padding: 12px;
  height: 80px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
`
const SingleBookDetail = styled.div`
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`
const BookDetailTopSection = styled.div`
  display: flex;

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`
const ThumbnailDiv = styled.div`
  margin-right: 24px;
  width: 96px;
  height: 144px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  background-color: var(--Grey900);

  @media (max-width: 600px) {
    margin-right: 10px;
    width: 40px;
    height: 60px;
  }
`
const CMSThumbnailDiv = styled.div`
  margin-right: 15px;
  margin-left: 12px;
  width: 40px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  background-color: var(--Grey900);
`
const Thumbnail = styled.img`
  width: 96px;
  height: 144px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);

  @media (max-width: 600px) {
    width: 40px;
    height: 60px;
  }
`
const CMSThumbnail = styled.img`
  width: 40px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
`
const ThumbnailUpload = styled.img`
  width: 170px;
  height: 256px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
`
const Details = styled.div`
  width: 60%;
`
const Title = styled.div`
  margin-bottom: 8px;
`
const TitleText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text700);

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.P.S};
  line-height: ${({ theme }) => theme.lineHeights.P.S};
  // color: var(--Text500);
  color: ${({ theme }) => theme.colors.text.text500};
  margin-bottom: 8px;

  .clamp-lines__button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.P.S};
    line-height: ${({ theme }) => theme.lineHeights.P.S};
    color: ${({ theme }) => theme.colors.text.text700};
    outline: none;
  }
`
const MobileDescription = styled(Description)`
  margin-bottom: 16px;
`

const CategorySpan = styled.span`
  margin-left: 8px;
  border-radius: 24px;
  background-color: var(--Fjord500);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  padding: 4px 8px;
`
const CategoryDiv = styled.div`
  border-radius: 24px;
  background-color: var(--Fjord500);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--TextDark700);
  padding: 4px 8px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ArtistsContainer = styled.div`
  display: flex;
`
const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
`
const ProfileImageDiv = styled.div`
  position: relative;
  top: 3px;
`
const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
const ArtistName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--Text500);
  margin-left: 8px;
`
const StyledAuthorIcon = styled(AuthorIcon)`
  position: absolute;
  bottom: 6px;
  right: -3px;
`
const Container = styled.div``
const ChapterContainer = styled.div`
  margin-top: '8px';
`
const UpsellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  padding: 24px;
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`
const Right = styled.div`
  text-align: center;
  svg {
    height: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`
const UpsellTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 8px;
`
const UpsellDetails = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text500);
`

const TabHolder = styled.div`
  padding: 16px 24px 0 24px;
  display: flex;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`
const Tab = styled.div`
  margin-right: ${({ last }) => (!last ? '48px' : '0')};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.D.S};
  font-weight: 600;
  line-height: 1.5;
  color: ${({ active, theme }) => (active ? theme.colors.interactive.azure2 : theme.colors.text.text300)};

  span {
    margin-bottom: 16px;
  }

  @media (max-width: 600px) {
    span {
      margin-bottom: 12px;
    }
  }
`
const UnderLine = styled.div`
  width: 32px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ active, theme }) => (active ? theme.colors.interactive.azure2 : 'none')};
`
const HR = styled.div`
  margin-top: 15px;
  height: 1px;
  background-color: var(--Grey900);
`
const ArrowRight = styled(Arrow)`
  margin-bottom: -5px;
`
const ContinueButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`
const FlexChildLeft = styled.div`
  width: 63%;

  @media (max-width: 600px) {
    width: 100%;
  }
`
const FlexChildRight = styled.div``
const FormContainer = styled.div`
  padding: 24px;
  margin-bottom: 32px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`
const FormActionDiv = styled.div`
  text-align: right;
`
const LoaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  .loader {
    margin: 0;
  }
`
const ImageUploadDiv = styled.div`
  margin-top: 24px;
  width: 170px;
  height: 256px;
  border-radius: 4px;
  box-shadow: inset 0 0 32px 0 rgba(0, 0, 0, 0.08);
  border: dashed 1px var(--Azure500);
  background-color: var(--Grey100);
  display: flex;
  align-items: center;
  position: relative;
`
const OverlayDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 170px;
  height: 36px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
`
const UpdateText = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: var(--TextDark700);
  margin-left: 4px;
`
const ThumbnailPlaceHolder = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text300);
  text-align: center;
  flex: 1;
`
const StepHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  color: var(--Text500);
`
const EditButtonDiv = styled.div`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 20px;
  text-align: center;
`
const SearchDiv = styled(EditButtonDiv)``
const ChapterButtonDiv = styled.div`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
  width: 192px;
  height: 36px;
  text-align: center;
  background-color: rgba(6, 138, 226, 0.1);
  position: relative;
  bottom: 6px;

  @media (max-width: 600px) {
    display: none;
  }
`
const RecoverButtonDiv = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  height: 52px;
  border-radius: 4px;
  text-align: center;
  width: 128px;
  background-color: rgba(6, 138, 226, 0.1);
  animation: fading 2s;
  @keyframes fading {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`
const EditAnotherCTA = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Azure500);
  margin-left: 4px;
`
const AddAnotherCTA = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Azure500);
  margin-left: 2px;
`
const DeleteButtonDiv = styled.div`
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 52px;
  text-align: center;
  width: 128px;
  background-color: rgba(221, 54, 35, 0.1);
  animation: fading 2s;
  @keyframes fading {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`
const ButtonWrapper = styled.div`
  display: none;
  margin-left: auto;
`
const ButtonWrapperRight = styled(ButtonWrapper)`
  margin-left: 16px;
`
const DeleteAnotherCTA = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #dd3623;
`
const PublishChanges = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 36px;
  width: 179px;
  border-radius: 24px;
  padding-left: 12px;
  text-align: center;
  border: solid 1px rgba(242, 198, 2, 0.2);
  background-color: rgba(242, 198, 2, 0.1);
  color: #ae8701;
  font-size: 14px;
`
const Calendar = styled(CalendarIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const EyeIcon = styled(Eye)`
  margin-top: 1px;
`
const Add = styled(AddIcon)`
  margin-left: 7.7px;
`
const EmptyNovelIcon = styled(EmptyNovel)`
  margin-top: 5%;

  @media (max-width: 600px) {
    width: 240px;
    height: 202px;
  }
`
const EmptyContainer = styled.div`
  text-align: center;
`
const EmptyNovelsContainer = styled.div`
  margin-top: 1px;
  border-radius: 4px;
  min-height: 70vh;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
`
const EmptyChapterContainer = styled(EmptyContainer)`
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  height: 512px;
`
const InfoLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--Text500);
  margin-left: 4px;
  margin-right: 16px;
`
const ChapterTitle = styled.label`
  color: rgba(13, 21, 54, 0.65);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
`
const ChapterCount = styled.label`
  margin-top: 15px;
  font-weight: 600;
  color: rgba(13, 21, 54, 0.65);
  font-size: 14px;
  width: 45px;
`
const DragHandler = styled.label`
  margin-top: 15px;
  font-weight: 600;
  color: rgba(13, 21, 54, 0.65);
  width: 45px;
`
const ChapterHolder = styled.div`
  cursor: pointer;
  padding: 16px 24px 0 24px;
  display: flex;
  height: 80px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
  :hover ${ButtonWrapper} {
    display: block;
  }
`
const InfoHolder = styled.div`
  display: flex;
  margin-top: 6px;
`
const PopupCancelButton = styled.button`
  width: 88px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
`
const PopupDeleteTrashButton = styled.button`
  width: 164px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--Punch500);
  color: #fff;
`
const PopupDeleteDraftButton = styled.button`
  width: 160px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--Punch500);
  color: #fff;
`
const DeleteHeader = styled.label`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
  position: relative;
  bottom: 10px;
  margin-left: 8px;
`
const DeleteMessage = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
  margin-top: 13px;
`
const SaveAlert = styled.label`
  transform: none;
  max-width: 170px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 12px 16px 0 rgba(24, 27, 37, 0.12);
  position: absolute;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate3d(0, -50%, 0);
  text-align: center;
  padding: 10px 10px 10px 10px;
`
const SearchIcon = styled(Search)`
  cursor: pointer;
  margin-top: 2px;
  margin-left: 24px;
`
const VellipContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  padding: 8px;
  border-radius: 4px;

  &: hover {
    background-color: #ebecf1;
  }
`
const VellipIcon = styled(Vellip)`
  display: block;
`
const BookOptionsBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 40px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  width: 184px;
`
const EditBookDiv = styled.div`
  display: flex;
  background-color: #f0f1f5;
  cursor: pointer;
`
const EditButton = styled(Link)`
  border: none;
  outline: none;
  background: none;
  padding: 16px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
`
const EditBookSpan = styled.span`
  margin-left: 8px;
`
const StatusContainer = styled.div`
  width: 140px;
  position: relative;
`
const SelectedStatus = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  border: ${({ selectedStatus }) =>
    selectedStatus === 'unpublished'
      ? 'solid 1px rgba(242,198,2,0.2)'
      : selectedStatus === 'published'
      ? 'solid 1px rgba(121,186,16,0.2)'
      : 'solid 1px rgba(221, 54, 35, 0.2)'};
  background-color: ${({ selectedStatus }) =>
    selectedStatus === 'unpublished'
      ? 'rgba(242,198,2,0.1)'
      : selectedStatus === 'published'
      ? 'rgba(121,186,16,0.1)'
      : 'rgba(221, 54, 35, 0.1)'};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${({ selectedStatus }) =>
    selectedStatus === 'unpublished'
      ? 'var(--Corn800)'
      : selectedStatus === 'published'
      ? 'var(--Lime800)'
      : 'var(--Punch800)'};
  padding: 8px 24px;
  text-align: center;
`
const StatusesDropdownBox = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  background-color: #ffffff;
  width: 230px;
  z-index: 10;
`
const StatusSingle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ middle }) => (middle ? '#f0f1f5' : '#ffffff')};
  padding: 16px 24px;
`
const StatusSingleText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  margin-left: 8px;
  cursor: pointer;
`
const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ danger, warn }) =>
    warn ? '#f2c602' : danger ? '#dd3623' : '#79ba10'};
`
export {
  EmptyNovelsContainer,
  SelectedStatus,
  StatusesDropdownBox,
  StatusSingle,
  StatusSingleText,
  Indicator,
  StatusContainer,
  EditBookSpan,
  EditButton,
  EditBookDiv,
  BookOptionsBox,
  VellipIcon,
  VellipContainer,
  SaveAlert,
  EmptyButtonText,
  EmptyButton,
  EmptyHeader,
  EmptyBody,
  DeleteHeader,
  DeleteMessage,
  PopupCancelButton,
  PopupDeleteDraftButton,
  PopupDeleteTrashButton,
  Add,
  ChapterContainer,
  InfoHolder,
  ChapterHolder,
  ChapterCount,
  ChapterTitle,
  InfoLabel,
  EmptyContainer,
  SingleBookDetail,
  BookDetailTopSection,
  SingleBook,
  ThumbnailDiv,
  Thumbnail,
  Details,
  Title,
  TitleText,
  Description,
  MobileDescription,
  CategoryDiv,
  CategorySpan,
  ArtistsContainer,
  ArtistDetails,
  ProfileImageDiv,
  ProfileImage,
  ArtistName,
  StyledAuthorIcon,
  Container,
  TabHolder,
  Tab,
  UnderLine,
  HR,
  ArrowRight,
  ContinueButton,
  FlexContainer,
  FlexChildLeft,
  FlexChildRight,
  FormContainer,
  FormActionDiv,
  LoaderDiv,
  ImageUploadDiv,
  ThumbnailPlaceHolder,
  StepHeader,
  EditButtonDiv,
  ChapterButtonDiv,
  AddAnotherCTA,
  DeleteButtonDiv,
  ButtonWrapper,
  ButtonWrapperRight,
  DeleteAnotherCTA,
  RecoverButtonDiv,
  PublishChanges,
  Calendar,
  EditAnotherCTA,
  ThumbnailUpload,
  EmptyChapterHeader,
  EmptyNovelIcon,
  EmptyChapterContainer,
  CMSThumbnailDiv,
  CMSThumbnail,
  CMSSingleBook,
  SearchIcon,
  EyeIcon,
  DragHandler,
  SearchDiv,
  UpdateText,
  OverlayDiv,
  UpsellContainer,
  Left,
  Right,
  UpsellTitle,
  UpsellDetails
}
