import React from 'react'
import SubHeader from '../../../../../components/WithSubHeaderBG' 
import { Formik, Form } from 'formik'
import { FloatingInput, Input, Upload, OptionSelect, DropDownSelect, SearchableInput } from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import StepIndicator from '../../../../../components/StepIndicator'
import Loader from '../../../../../components/Loader'
import { errorMessages } from '../../constants'
import { get, post } from '../../../../../lib/api'
import Tooltip from '../../../../../components/Tooltip'
import FileIcon from '../../../../../components/Images/File'
import queryString from 'query-string'
import { get as getFromLocal } from '../../../../../lib/storage'
import { FileUploadComponent } from '../Edit/addEditEpisode'
import { FileUploadComponent as FileUploadComponentDummy } from '../Edit/viewDetails'
import { menuItemObj } from '../../../../../components/PrivatePage'
import { Box, Text, Button } from '../../../../../designSystem'
import { FormActionDiv, ImageUploadDiv, ThumbnailPlaceHolder, Thumbnail } from './styles'
import { getTokenAndUid } from '../../../../../lib/utils'
import DependentDropDown from '../../../../../components/FormikInputs/DropDownSelect/DropDown'
import styled from 'styled-components'

export const ImageUploadComponent = ({ isLoading, imageUrl, placeholder }) => {
  return (
    <ImageUploadDiv>
      {isLoading && <Loader size="md" />}
      {!isLoading && imageUrl && <Thumbnail src={imageUrl} alt="" />}
      {!isLoading && !imageUrl && placeholder && (
        <ThumbnailPlaceHolder>
          {placeholder || 'Click here to upload or drag your file'}
        </ThumbnailPlaceHolder>
      )}
  </ImageUploadDiv>
  )
}

const getBookTypeObjFromCategory = id => {
  if (!id) return {}
  const allTopics = JSON.parse(getFromLocal('allTopics')) || []
  const selectedTopic = allTopics.find(topicObj => !!topicObj.sub_topics.find(topic => topic.topic_id === id)) || {}
  return selectedTopic
}
export const getBookTypeIdFromCategory = id => {
  return getBookTypeObjFromCategory(id).topic_id || ''
}
export const getBookTypeNameFromCategory = id => {
  return getBookTypeObjFromCategory(id).type || ''
}

const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;

  @media (max-width:600px){
    display: flex;
    position: unset;
    justify-content: flex-start;
  }
`
const FileDetailsDiv = styled.div``
const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const FileNameDiv = styled.div`
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  margin: 10px;
  margin-left: 0;
  width: 100%;
  &:hover ${HoverActions} {
    display: flex;
  }
`
const FileIconDiv = styled.div`
  margin-right: 8px;
`
const TopDiv = styled.div`
  display: flex;
`
const DownloadButton = styled.a`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
  cursor: pointer;
`
const BottomDiv = styled.div``

const UploadContainer = styled(Upload)`
  position: static !important;
`

const New = ({
  location: { search },
  history,
  data = {},
  mode,
  headerAction = () => null,
  _onSubmit
}) => {
  const qParams = queryString.parse(search)
  const { process } = qParams
  const createBookMode = process === 'createNew'
  let showTypes = [
    {topic_id: 'audiobook', name: 'Audiobook'},
    {topic_id: 'audiobook_series', name: 'Audiobook Series'}
  ]
  if (data.is_show_edit) {
    showTypes = [
      {topic_id: 'audiobook', name: 'Audiobook'},
      {topic_id: 'audiobook_series', name: 'Audiobook Series'},
      {topic_id: 'podcast', name: 'Podcast'}
    ]
  }

  // const allTopics = JSON.parse(getFromLocal('allTopics')) || []
  const isMobile = window.screen.width < 600
  return (
    <SubHeader
      title={createBookMode ? "Submit New Audiobook" : 'Edit Audiobook'}
      showBackButton
    >
      <Formik
        initialValues={{
          book_title: data['book_title'] || '',
          language: data['language'] || '',
          book_type: (data['category_obj'] || {}).book_type || '',
          description: data['description'] || '',
          description_en: data['description_en'] || '',
          synopsis_url: data['synopsis_url'] || '',
          category: (data['category_obj'] || {}).topic_id || '',
          tags: data['tags'] || '',
          image_url: data['image_url'] || '',
          created_by: data['author_id'] || data['created_by'] || '',
          translator_id: data['translator_id'] || '',
          graphic_detail: data['graphic_detail'] || ''
        }}
        validationSchema={Yup.object({
          book_title: Yup.string()
            .required('Required'),
          language: Yup.string()
            .required('Required'),
          book_type: Yup.string()
            .required('Required'),
          category: Yup.string()
            .required('Required'),
          description: Yup.string()
            .required('Required'),
          created_by: Yup.string(),
          synopsis_url: Yup.string().when('language', {
            is: (val) => val !== 'english',
            then: Yup.string().required('Required')
          })
        })}
        onSubmit={(values, { setSubmitting }) => {
          const { uid } = getTokenAndUid()
          const formData = values
          formData.view = 'dashboard'
          if (formData.language === 'english') {
            formData.description_en = data.description
            delete formData.synopsis_url
            formData.created_for = 'overseas_studio_user'
            formData.book_docs = {}
            data?.book_docs?.forEach((bookDoc) => {
              if (bookDoc.document_type === 'localisation_sheet')
                formData.book_docs[bookDoc.document_type] = {
                  file_url: bookDoc.file_url,
                  is_edited: true,
                }
              else
                formData.book_docs[bookDoc.document_type] = {"file_url" : bookDoc.file_url, "is_edited": false};
            });
          } else {
            delete formData.book_docs;
            delete formData.created_for;
          }
          if (mode !== 'edit') {
            formData['created_by'] = uid
          }
          if (mode === 'edit') {
            formData.book_id = data.book_id
          }

          let createBookApi = '/content_api/book.create_book?is_novel=0'
          post(createBookApi, { data: formData }).then(response => {
            setSubmitting(false)
            const { book_id } = response.results
            history.push(`${menuItemObj.audiobooks}/edit/${book_id}${process ? '?process=' + process : ''}`)
          })
        }}
      >
        {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
          <Form autoComplete='off'>
            <Box variant="flexCard" p="16px" borderTopLeftRadius="4px" borderTopRightRadius="4px" flexDirection={["column", "row"]}>
                <Text variant="heading">
                  {createBookMode ? "Submit New Audiobook" : 'Edit Audiobook'}
                </Text>

                {createBookMode && <StepIndicator
                  steps={['Add Details', 'Add Episodes', 'Submit']}
                  current={1}
                />}
                {headerAction()}
            </Box>
            <Box variant="hr"/>
            <Box p={[16, 24]} mb={32} bg="surface.foreground">
              <Box variant="flex" flexDirection={['column-reverse', 'row']}>
                <Box variant="flex" flexDirection="column" width={['100%', '63%']}>
                  {isMobile && <Text variant="titleSecondary" my={16}>Audiobook Details</Text>}
                  <FloatingInput
                    label="Title"
                    name="book_title"
                    type="text"
                    hasError={submitCount > 0 && errors['book_title']}
                    placeholder="Example - Kitni Mohabatt hai - कितनी मोहबत्त है"
                    infoTitle='Give a title to this audiobook'
                  />
                  <Box mt={16}>
                    <DropDownSelect
                      label="Language"
                      name="language"
                      selectByName
                      infoTitle='Add the language of this audiobook'
                      placeholder="Select a Language"
                      hasError={submitCount > 0 && errors['language']}
                      selectOptions={
                        [
                          {id: 1, name: 'hindi'},
                          {id: 2, name: 'tamil'},
                          {id: 3, name: 'bengali'},
                          {id: 4, name: 'malayalam'},
                          {id: 5, name: 'telugu'},
                          {id: 6, name: 'marathi'},
                          {id: 7, name: 'kannada'},
                          {id: 8, name: 'english'},
                          {id: 9, name: 'spanish'},
                        ]
                      }
                      idKey='id'
                      nameKey='name'
                    />
                  </Box>
                  <OptionSelect
                    label="type"
                    name="book_type"
                    dependentOn={values['language'] || 'hindi'}
                    hasError={submitCount > 0 && errors['book_type']}
                    selectKey="topic_id"
                    infoTitle='Select the type of this audiobook'
                    selectOptions={showTypes}
                  />
                  <Box mt={16}>
                    <DependentDropDown
                      label="Category"
                      name="category"
                      hasError={submitCount > 0 && errors['category']}
                      dependentOn={values['book_type']}
                      infoTitle='Select the category of this audiobook'
                      placeholder="Choose a Category"
                      idKey='topic_id'
                      nameKey='topic_name'
                      language={values['language']}
                      tooltip={{
                        title: !values['book_type'] ? 'Please select a type first' : '',
                        placement: 'top'
                      }}
                    />
                  </Box>
                  
                  <Box mt={16}>
                    <SearchableInput
                      label="Tags - optional"
                      name="tags"
                      selectKey="tag_id"
                      nameKey='tag_name'
                      infoTitle='You can select multiple tags'
                      placeholder="Select Tags"
                      apiUrl={values['category'] ? `/content_api/tag.get_topic_tags?topic_id=${values['category']}&is_novel=0` : null}
                      tooltip={{
                        title: !values['category'] ? 'Please select a category first' : '',
                        placement: 'top'
                      }}
                    />
                  </Box>
                  
                  {values['language'] !== 'english' && <Upload
                    label="Synopsis"
                    name="synopsis_url"
                    hasError={submitCount > 0 && errors['synopsis_url']}
                    hideInputOnValue={true}
                    showProgress={true}
                    placeholder="Drop your doc file here to upload or click to select"
                    renderComp={FileUploadComponent}
                    fileTypeTags="file"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/doc, application/ms-doc"
                    extraParams={{
                      file_dir: "synopsis",
                      title: "synopsis",
                    }}
                    cloud_dir_file={"synopsis"}
                    // uploadedFileMeta={{words: '10,000 words', size: '456 KB'}}
                  />}
                  {data.book_docs?.length && data.book_docs.map((bookDoc) => bookDoc.document_type === "localisation_sheet" ? 
                    <FileNameDiv>
                      <UploadContainer
                        name="localisation_sheet_url"
                        hasError={submitCount > 0 && errors['localisation_sheet_url']}
                        hideInputOnValue={true}
                        showProgress={true}
                        placeholder={bookDoc.document_type}
                        renderComp={FileUploadComponentDummy}
                        fileTypeTags="file"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        extraParams={{
                          file_dir: "localisationsheet",
                          title: "localisationsheet",
                        }}
                        cloud_dir_file={"localisationsheet"}
                        // uploadedFileMeta={{words: '10,000 words', size: '456 KB'}}
                      />
                    </FileNameDiv>
                  : <FileNameDiv>
                      <TopDiv>
                        <FileIconDiv>
                          <FileIcon />
                        </FileIconDiv>
                        <FileDetailsDiv>
                          <FileName>{bookDoc.document_type}</FileName>
                        </FileDetailsDiv>
                      </TopDiv>
                      <BottomDiv>
                        <HoverActions>
                          <DownloadButton href={bookDoc.file_url} download={bookDoc.file_url} type="button">
                            Download
                          </DownloadButton>
                        </HoverActions>
                      </BottomDiv>
                    </FileNameDiv>)}
                </Box>
                <Box variant="flex" justifyContent={["center", "flex-end"]}>
                  <Upload
                    name="image_url"
                    placeholder="Upload a Thumnail"
                    renderComp={ImageUploadComponent}
                    dimensionErrorMsg={errorMessages.dimension}
                    fileTypeTags='image'
                    extraParams={{title: values.book_title || 'general'}}
                  />
                </Box>
              </Box>
              <Input
                asTextArea
                infoTitle="Description in language selected is required"
                label={`Description ${values['language'] ? ' in ' + values['language'] : ''}`}
                name="description"
                hasError={submitCount > 0 && errors['description']}
                placeholder="Write a description regarding this audiobook"
              />
              {values['language'] !== 'english' && <Input
                asTextArea
                infoTitle="Description in English"
                label={"Description in English"}
                name="description_en"
                placeholder="Write a description in english regarding this audiobook"
              />}
              {((mode !== 'edit') || (data['graphic_detail'] !== undefined)) && <Box mt={16}>
                <FloatingInput
                  asTextArea
                  infoTitle="Graphic details - Max 500 characters allowed"
                  label={"Graphic details"}
                  name="graphic_detail"
                  maxLength={500}
                  placeholder="Write graphic details here"
                />
              </Box>}
              <Box variant="hr" my={16} />
              <FormActionDiv>
                <Tooltip title={isValid ? '' : 'Enter all the details to continue'} arrow>
                  <Button width={["100%", "unset"]} type={isSubmitting ? "button" : "submit"} isLoading={isSubmitting}>
                    Continue
                  </Button>
                </Tooltip>
              </FormActionDiv>
            </Box>
          </Form>
        )}
      </Formik>
    </SubHeader>
  );
};

export default New
