import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import MenuIcon from '../Images/MenuIcon'
import Toolbar from '@material-ui/core/Toolbar'
import { useTheme } from '@material-ui/core/styles'
import NavBarBrand from '../NavBarBranding'
import { withRouter } from 'react-router-dom'
import { get } from '../../lib/storage'
import LogoutIcon from '../Images/LogoutIcon'
import EditIcon from '../Images/PencilIcon'
import { Consumer } from '../../context/editorContext'
import Back from '../Images/BackButton'
import HeaderLogo from '../Images/StudioHeader'
import { AccountContext } from '../../providers/accountProvider'
import { menuItemObj } from '../PrivatePage'
import {
  useStyles,
  Main,
  StyledListItemText,
  LogoContainer,
  ProfilePicNameContainer,
  ToolbarContentDiv,
  ProfilePicInner,
  ProfileImage,
  NameSpan,
  CaretIcon,
  StyledListItem,
  StyledExpandMore,
  StyledExpandLess,
  AudienceIcon,
  AudiobooksIcon,
  AnalyticsIcon,
  PodcastsIcon,
  NovelIcon,
  StarIcon,
  CommentsIcon,
  ParentMenuText,
  ChildMenuText,
  MenuTextDiv,
  MenuTextLabelBox,
  SubLabel,
  AccountOptionscontainer,
  ProfileOption,
  ProfileImageContainer,
  BellContainer,
  NotificationCount,
  ToolIcon,
  ExperimentsIcon,
  CampaignIcon
} from './styles'
import BellIcon from '../Images/BellIcon'
import { ControlsContext } from '../../providers/controlsProvider'
import PencilIcon from '../Images/PencilIcon'

const menuLabelIconMapping = {
  engagement: AnalyticsIcon,
  audience: AudienceIcon,
  audiobooks: AudiobooksIcon,
  comments: CommentsIcon,
  reviews: StarIcon,
  podcasts: PodcastsIcon,
  novels: NovelIcon,
  'power-tools': ToolIcon,
  'ab-experiments': ExperimentsIcon,
  'campaign': CampaignIcon
}

const BackButton = styled(Back)`
  margin-right: 8px;
`
const StyledHeaderLogo = styled(HeaderLogo)`
  height: 24px;
  width: unset;
  margin-left: 8px;
`

const isMobile = window.screen.width < 600

const isIndianMobileNumber = number => number && number[0] === '+' && number[1] === '9' && number[2] === '1'

const ResponsiveDrawer = ({ match, history, children, menuItemsArray = [], isProfileNameShown = true }) => {
  const activeRoute = match.path.split('/')[1] || 'root'
  const activeSubRoute = match.path.split('/')[2] || ''
  const { user: { account_type = 'Author', professional_creator, author_visibility, phone_number } } = useContext(AccountContext)
  const isUserIndian = isIndianMobileNumber(phone_number)
  menuItemsArray = isUserIndian ? menuItemsArray : menuItemsArray.filter(menu => menu?.slug !== 'writers-benefit')
  const isAuthor = account_type === 'Author'
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [menuExpandedState, setMenuExpanded] = useState({ [activeRoute]: true })
  const [isLogoutShown, toggleShowLogout] = useState(false)
  const { isAlertsShown, toggleIsAlertsShown, alertsCount } = useContext(ControlsContext)
  const profileInfoRef = useRef()
  const checkRowUserWithWriterItem = (menuItemSlug) => menuItemSlug === 'writers-benefit' && author_visibility === 'row'
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMenuItemExpand = ({ slug, submenu, link }) => {
    if (!submenu) {
      history.push(link)
      return
    }
    setMenuExpanded({ ...menuExpandedState, [slug]: !menuExpandedState[slug] })
  }

  const handleClick = (e) => {
    if (!profileInfoRef) return
    if (!profileInfoRef.current) return
    if (!profileInfoRef.current.contains(e.target)) {
      toggleShowLogout(false)
    }
  }
  const onEditClick = () => {
    if (professional_creator === 1 && account_type === 'Author') {
      history.push('/edit-writer-profile')
    }
    else
      history.push('/edit-profile')
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const profileImage = get('image_url')
  const profileName = get('name')

  const drawer = (
    <div>
      <LogoContainer>
        <NavBarBrand />
      </LogoContainer>
      <List>
        {menuItemsArray.map((item) => (
          !checkRowUserWithWriterItem(item.slug) &&
          <React.Fragment key={item.slug}>
            <StyledListItem
              className={classes.paddingParent}
              active={activeRoute === item.slug ? 'true' : undefined} // TODO fix dom props drill issue
              parent='true'
              hasChildren={item.submenu ? 1 : 0}
              button
              expanded={menuExpandedState[item.slug] ? 'true' : undefined}
              onClick={() => handleMenuItemExpand(item)}
            >
              <MenuTextLabelBox>
                <MenuTextDiv>
                  <ParentMenuText parent>{item.label}</ParentMenuText>
                  {item.submenu ? (
                    menuExpandedState[item.slug] ? (
                      <StyledExpandLess className={classes.menuExpand} />
                    ) : (
                      <StyledExpandMore className={classes.menuExpand} />
                    )
                  ) : null}
                </MenuTextDiv>
                {item.subLabel && (
                  <SubLabel>{item.subLabel}</SubLabel>
                )}
              </MenuTextLabelBox>
            </StyledListItem>
            {item.submenu && (
              <StyledListItem className={classes.extraNode} button>
                <Collapse
                  in={menuExpandedState[item.slug]}
                  timeout="auto"
                  unmountOnExit
                  className={classes.entered}
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((childItem) => {
                      const Icon = menuLabelIconMapping[childItem.slug]
                      return (
                        <StyledListItem
                          button
                          active={activeSubRoute === childItem.slug ? 'true' : undefined}
                          className={activeSubRoute === childItem.slug ? classes.paddingChildActive : classes.paddingChild}
                          key={childItem.slug}
                          onClick={() => history.push(childItem.link)}
                        >
                          <Icon />
                          <ChildMenuText>{childItem.label}</ChildMenuText>
                        </StyledListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </StyledListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  )

  return (
    <Consumer>
      {editorControlsComp => {
        return (
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar className={classes.stickyHead}>
                {!editorControlsComp() && <IconButton
                  color="default"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>}
                <ToolbarContentDiv>
                  {editorControlsComp()}
                  {!editorControlsComp() && isMobile && <BackButton onClick={() => history.goBack()} />}
                  {isMobile && !editorControlsComp() && <StyledHeaderLogo onClick={() => {
                    history.push(isAuthor ? menuItemObj.novels : menuItemObj.audiobooks)
                  }} />}
                  {isProfileNameShown && <ProfilePicNameContainer>
                    <ProfilePicInner onClick={() => toggleShowLogout(!isLogoutShown)} ref={profileInfoRef}>
                      <ProfileImageContainer>
                        {profileImage && <ProfileImage src={profileImage} />}
                      </ProfileImageContainer>
                      {isProfileNameShown && !isMobile && (<><NameSpan>
                        {profileName || ''}
                      </NameSpan>
                        <CaretIcon /></>)}
                      <AccountOptionscontainer show={isLogoutShown} >
                        <ProfileOption onClick={() => history.push('/logout')}>
                          <LogoutIcon />
                          <span>Logout</span>
                        </ProfileOption>
                        <ProfileOption onClick={onEditClick}>
                          <PencilIcon />
                          <span>Edit Profile</span>
                        </ProfileOption>
                      </AccountOptionscontainer>
                    </ProfilePicInner>
                  </ProfilePicNameContainer>}
                </ToolbarContentDiv>
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  anchor="right"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <Main>
              <div className={classes.toolbar} />
              {children}
            </Main>
          </div>
        )
      }}
    </Consumer>
  )
}

export default withRouter(ResponsiveDrawer)
