import React, { useEffect, useState } from 'react'
import SubHeader from '../../../../components/WithSubHeaderBG'
import NovelList from './listing'
import CreateNew from '../CreateNew'
import { get } from '../../../../lib/api'
import GenericError from '../../../../components/GenericError'
import Loader from '../../../../components/Loader'
import queryString from 'query-string'
import { Text, Box, Button } from '../../../../designSystem'

const NovelsUI = (props) => {
  const {
    history,
    location: { search }
  } = props
  const [novels, setNovels] = useState([])
  const [showLoader, setShowLoader] = useState(true)
  const [apiError, toggleApiError] = useState(false)
  const [showNewDialog, setShowNewDialog] = useState(queryString.parse(search)['open-new'] || false)

  useEffect(() => {
    get('/content_api/book.creator_books', {
      params: {
        is_novel: 1,
        page_no: 1,
      },
    })
      .then((response) => {
        setNovels(response.results)
        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        throw err
      })
  }, [])

  if (showLoader) {
    return <Loader />
  }
  if (showNewDialog) {
    return <CreateNew />
  }

  return (
    <>
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
      {(novels.length) && (
        <SubHeader
          title="Novels"
        >
          <Box variant="flexCard" p={16} borderTopLeftRadius="4px" borderTopRightRadius="4px">
            <Text variant="heading">
              Novels
            </Text>
            <Button variant="outlinePrimary" onClick={() => setShowNewDialog(true)} >
              New Novel
            </Button>
          </Box>
          <Box variant="hr" />
          <NovelList novels={novels} />
        </SubHeader>
      )}
      {!(novels.length) && <CreateNew />}
    </>
  )
}
export default NovelsUI
