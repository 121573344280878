import React from 'react'
import { getWordCountFromText } from '../../../lib/utils'
import {
  Editor,
  EditorState,
  ContentState,
  convertFromHTML,
  Modifier
} from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'

export default class MyEditor extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)

    this.initialState = this.props.content
    ? EditorState.createWithContent(stateFromHTML(this.props.content))
    : EditorState.createEmpty()
  }

  getContent = (body) => {
    let blocksFromHTML = convertFromHTML(body)
    let content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return content
  }

  onChange(editorState) {
    this.handleWordCount(editorState)
    this.props.updateState(editorState)
  }

  componentDidCatch(error) {
    this.forceUpdate()
  }

  componentDidMount() {
    this.props.updateState(this.initialState)
    this.handleWordCount(this.initialState)
  }

  // check wordcount
  handleWordCount = (editorState) => {
    const count = this.getWordCount(editorState)
    this.props.updateWordCount(count)
  }
  
  getWordCount(editorState) {
    const plainText = editorState.getCurrentContent().getPlainText('');
    return getWordCountFromText(plainText);
  }

  handlePastedText = (text, html, editorState) => {
    const pastedBlocks = ContentState.createFromText(text).blockMap
    const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        pastedBlocks,
    );
    const newEditorState = EditorState.push(editorState, newState, "insert-fragment")
    this.onChange(newEditorState)
    return "handled"
};

  render() {
    return (
      //
      <Editor
        // ref={this.setEditor}
        editorState={this.props.editorState || this.initialState}
        placeholder="Start typing chapter content here..."
        onChange={this.onChange}
        handlePastedText={this.handlePastedText}
        readOnly={this.props.readOnly || false}
        spellCheck={true}
        allowUndo={true}
        tabIndex={this.props.tabIndex || undefined}
      />
    )
  }
}

export function exportHTML (editorState) {
  if (!editorState) return ''
  return stateToHTML(editorState.getCurrentContent())
}

export const getContent = editorState => {
  if (!editorState) return ''
  return stateToHTML(editorState.getCurrentContent())
}

export const isContentChanged = editorState => {
  if (!editorState) return false
  return editorState._immutable.undoStack.size
}
export const resetIsContentChanged = () => {

}
export const isRedoEmpty = editorState => {
  if (!editorState) return true
  return !editorState._immutable.redoStack.size
}
export const isUndoEmpty = editorState => {
  if (!editorState) return true
  return !editorState._immutable.undoStack.size
}
export const handleUndo = (editorState, updateState=() => null) => {
  if (!editorState) return
  let newState = EditorState.undo(editorState)
  updateState(newState)
}
export const handleRedo = (editorState, updateState=() => null) => {
  if (!editorState) return
  let newState = EditorState.redo(editorState)
  updateState(newState)
}
