import React from 'react'

const RjGirl = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="492" height="260" fill="none" viewBox="0 0 492 260" {...props}>
    <mask id="4jvyla00ya" width="492" height="260" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#C4C4C4" d="M50.669 0H492v260H0l62.437-46L50.669 0z"/>
    </mask>
    <g mask="url(#4jvyla00ya)">
        <path fill="url(#paint0_linear)" d="M136.376 293.883s-10.497-48.096-2.785-80.802l90.401 1.71s3.427.214 2.784 1.71c-.428 1.283-2.784 1.069-2.784 1.069l-79.69-.641s-7.498 33.133 4.713 76.954h-12.639z"/>
        <path fill="url(#paint1_linear)" d="M132.091 214.792l93.4.427s1.499-8.337 0-16.887H135.09s-3.213 6.627-2.999 16.46z"/>
        <path fill="url(#paint2_linear)" d="M132.091 214.792l14.781.214s.857-10.902 3.428-13.039c2.356-2.352 75.405-3.421 75.405-3.421h-90.4c-.215-.213-2.357 4.489-3.214 16.246z"/>
        <path fill="url(#paint3_linear)" d="M149.014 293.884h2.999s-13.067-35.698-5.569-76.954h-2.143s-8.354 30.568 4.713 76.954z"/>
        <path fill="url(#paint4_linear)" d="M131.02 230.61l10.497 10.261s.214 21.162 2.356 30.568l-11.996-5.772c.214 0-1.928-24.155-.857-35.057z" opacity=".8"/>
        <path fill="url(#paint5_linear)" d="M345.454 132.066l-32.133-.213H279.26l25.064-55.15c21.636 5.13 31.918 19.451 36.631 32.277 1.5 3.634 2.357 7.268 2.999 10.688v.214c.214 1.496.643 2.993.643 4.489.857 4.489.857 7.695.857 7.695z"/>
        <path fill="url(#paint6_linear)" d="M334.743 114.964s13.282 10.047 24.421 26.934c0 0-41.987 77.168-71.335 179.774l-26.992-2.351c-7.069-7.268 73.906-204.357 73.906-204.357z"/>
        <path fill="url(#paint7_linear)" d="M188.431 274.005s14.352-97.476 40.059-160.108c0 0 60.195-27.147 106.252.855 0 0-43.486 135.312-68.55 204.357l-77.761-45.104z"/>
        <path fill="url(#paint8_linear)" d="M322.318 108.765s-8.569 6.199-20.779 23.087c-16.924 23.513-40.916 67.762-59.553 145.358l-5.998-.642v-.427c.642-2.138 3.213-11.757 6.426-26.507v-.213c7.498-33.989 18.423-93.842 14.139-144.29 0 0 14.138-25.224 36.845-29.285 4.07-.641 7.498-.427 10.497.641 10.283 3.42 14.781 15.177 16.709 23.514 1.5 4.917 1.714 8.764 1.714 8.764z"/>
        <path fill="url(#paint9_linear)" d="M261.694 224.198s23.135 17.315 37.702-11.543c14.567-28.858 21.422-100.254 21.422-100.254s-29.562 26.72-59.124 111.797z" opacity=".8"/>
        <path fill="url(#paint10_linear)" d="M228.49 113.896s18.851 11.971 19.708 44.89c1.071 33.561-2.999 56.006-2.999 56.006l-43.915-5.986s6.64-48.096 27.206-94.91z" opacity=".8"/>
        <path fill="url(#paint11_linear)" d="M322.318 108.766s-8.569 6.199-20.779 23.086c-16.924 23.514-40.916 67.763-59.553 145.358l-5.998-.641v-.427l6.426-26.507v-.214c1.285-3.634 28.063-81.443 49.699-112.438 1.285-1.924 2.571-3.634 3.642-5.344 19.065-27.148 24.849-31.637 24.849-31.637 1.5 4.916 1.714 8.764 1.714 8.764z"/>
        <path fill="url(#paint12_linear)" d="M256.981 105.347s1.071 10.474 22.279 11.543c21.422 1.069 39.202-11.543 39.202-11.543s-7.712-26.72-25.064-25.652c-17.351 1.07-36.417 25.652-36.417 25.652z" opacity=".8"/>
        <path fill="url(#paint13_linear)" d="M245.199 302.861s-10.496-48.096-2.785-80.802l90.401 1.71s3.427.214 2.785 1.71c-.429 1.283-2.785 1.069-2.785 1.069l-79.69-.641s-7.497 33.133 4.713 76.954h-12.639z"/>
        <path fill="url(#paint14_linear)" d="M240.915 223.77l93.399.427s1.5-8.336 0-16.887h-90.4s-3.213 6.627-2.999 16.46z"/>
        <path fill="url(#paint15_linear)" d="M240.915 223.771l14.781.213s.857-10.901 3.428-13.039c2.356-2.351 75.405-3.42 75.405-3.42h-90.401c-.214-.214-2.356 4.275-3.213 16.246z"/>
        <path fill="url(#paint16_linear)" d="M257.838 302.862h3s-13.068-35.698-5.57-76.954h-2.142s-8.355 30.568 4.712 76.954z"/>
        <path fill="url(#paint17_linear)" d="M239.844 239.374l10.496 10.261s.215 21.162 2.357 30.568l-11.996-5.772c.214.214-1.928-23.941-.857-35.057z" opacity=".8"/>
        <path fill="url(#paint18_linear)" d="M123.094 47.844s-8.997 14.322-8.997 16.673c0 2.352 2.999 15.605 8.997 22.659 2.999 3.634-11.354 53.013-11.996 65.625-.643 12.612.214 21.162 1.713 23.727 1.5 2.565 34.061-8.336 43.059-33.988 0 0-10.711-15.177-15.638-15.177-2.785 10.261-7.712 15.819-11.354 18.811 0 0 .857-46.814 1.499-54.937.857-7.909 4.499-8.336 6.855-14.322 2.357-5.985 2.785-9.833 5.356-13.04 0 0-2.785-.854-5.356.428-2.57 1.283-3.427 6.2-4.07 7.055-.642.855-4.284-5.344-4.498-11.971-.214-6.627 1.071-14.108 1.071-14.108s-2.785-2.138-6.641 2.565z"/>
        <path fill="#6D2C9C" d="M197.214 119.241s-5.355-11.757-17.78-11.116c-15.21.855-20.351.855-20.351.855l-8.14 25.652 24.207 6.199c.214 0 20.565-10.047 22.064-21.59z"/>
        <path fill="url(#paint19_linear)" d="M195.928 36.942c-1.928.428-3.856.428-5.569-.427-7.498-3.42-16.067-19.88-16.067-19.88 0-.214.214-.428.214-.641 1.072-2.138 4.499-8.55 11.783-8.55 8.783-.215 10.925 6.198 10.925 6.198s1.285 0 2.999.855c.857.428 2.142 1.497 3.213 2.78l.214.213c0 .214.215.214.429.428.428.427.857 1.068 1.071 1.71.428.64.642 1.282 1.071 2.137 2.785 6.413-3.856 13.895-10.283 15.177z"/>
        <path fill="url(#paint20_linear)" d="M195.929 36.941l-21.636-20.307-.643-.641h1.071c.429.214 10.283 4.489 17.78 3.42 7.712-1.069 4.927-5.985 4.927-5.985s1.286 0 3 .855c.428.213.642.641 1.071 1.069 2.356 2.992-6.213 9.191-6.213 9.191s5.784 3.207 9.426.428c1.928-1.497 1.499-3.848.643-5.558.428.641.642 1.283 1.071 2.138 2.57 6.626-4.07 14.108-10.497 15.39z" opacity=".83"/>
        <path fill="url(#paint21_linear)" d="M174.935 68.578s.857 22.445 10.496 39.119c9.64 16.673 14.996 54.509 14.996 54.509s-40.488 13.253-49.271 11.115c-8.997-2.137-1.713-16.246-1.928-31.636-.214-15.391-7.069-23.514-2.57-30.782 0 0 6.641.427 13.281.427 6.427.214 9.426-4.489 9.854-7.267.429-2.779-.856-10.047-2.999-13.895-2.142-3.848 8.141-21.59 8.141-21.59z"/>
        <path fill="url(#paint22_linear)" d="M153.085 131.211s-1.286 10.902-2.142 16.674c-.857 5.771 2.142 11.543 3.856 11.543 1.713.214 3.855-3.634 3.855-3.634s-1.285 5.344-.642 8.337c.642 2.992-3.642 1.71-3.642 1.71l-5.784-4.703-1.928-8.978 6.427-20.949z"/>
        <path fill="#6D2C9C" d="M204.069 149.808s-11.996 12.185-24.635 11.971c.214.214 10.282-18.384 24.635-11.971z"/>
        <path fill="url(#paint23_linear)" d="M271.762 272.508c-.642 2.778-7.283 13.894-26.991 17.742-.857.214-1.5.214-2.357.427-21.636 3.634-97.469-5.557-97.469-5.557 5.998 17.1 6.212 35.911 6.212 35.911s-28.491-20.948-29.776-21.162c0 0-28.492-35.057-25.707-39.76 3-4.702 8.569-5.13 10.925-5.985 2.143-.855 2.357-2.779 19.066-6.84 16.709-4.062 64.48-2.779 66.408-3.848.428-.214.643-.428 1.071-.855.643-1.069.857-3.206.428-6.627-.214-1.71-.642-3.634-1.285-5.985-2.356-8.764-21.208-34.843-33.846-41.684-12.854-6.84-19.709-14.535-19.923-19.666-.214-4.916 8.14-10.688 8.783-21.376 0-.641.429-1.496 0-2.137-3.427-4.489-14.353-10.688-14.353-10.688 8.998-13.895 10.926-25.438 14.353-26.293 3.428-.855 11.568 1.069 11.568 1.069-.428 1.068-1.071 3.206-1.928 5.985-1.928 5.985-3.428 10.261-2.999 13.894.428 4.703 14.781 4.062 21.85-4.916 7.07-9.192 18.423 7.909 18.423 7.909s2.571 8.764 5.57 22.659v.214c.428 2.137.857 4.489 1.499 7.054v.214c0 .427.215.641.215 1.068.856 4.276 2.356 8.978 4.284 13.895 0 0 0 .214.214.214 3.213 8.336 8.141 17.101 13.71 25.651 5.784 8.764 12.639 16.887 19.708 23.514 18.637 16.032 34.061 37.836 32.347 45.959z"/>
        <path fill="url(#paint24_linear)" d="M213.066 241.085c-7.497 4.489-20.565 2.351-20.565 2.351 1.928-.855 2.571-4.917 0-13.467-2.356-8.55-21.207-34.843-33.846-41.684-12.639-6.84-19.709-14.535-19.923-19.666 0 0 10.925 15.819 33.418 11.971 0 0 5.57-1.069 8.141-13.681l19.922-12.612v.214c0 .641.643 3.207 1.5 7.054v.214c.856 3.848 2.356 9.192 4.498 14.75 0 .213 0 .213.214.427 3 8.337 7.498 17.742 13.71 25.438-.428-.214.429 34.202-7.069 38.691z" opacity=".3"/>
        <path fill="url(#paint25_linear)" d="M195.714 216.288c-14.781 7.696-69.621 24.155-69.621 24.155s-5.784 4.489-15.852 4.489c-10.069 0-17.566-3.206-17.566-3.206s-2.357 1.069-6.641 2.565-13.496.428-13.067-.641c.428-1.069 3.213-1.924 9.211-3.207 5.57-1.069 12.21-2.137 17.78-2.779.429 0 .857-.213 1.286-.213 4.927-.428 17.994-4.062 23.135-4.489.857 0 1.5 0 1.714.213 1.928 1.069 55.697-37.835 55.697-37.835v-.642c.857-7.909-1.071-23.514-3.213-37.408-1.5-10.047-3.214-19.239-3.856-24.155-1.714-11.543 14.138-17.529 14.138-17.529.857 2.138 6.427 16.888 9.426 33.133.428 2.352.857 4.917 1.071 7.482v.214l.428 3.848c1.714 16.673-2.356 55.15-4.07 56.005z"/>
        <path fill="#D35950" d="M169.151 98.078s8.141-1.068 9.212-7.909c0 0-2.357-8.978-2.785-14.535l-1.714-.642s-3.642 9.406-7.283 15.177c.214 0 2.356 5.558 2.57 7.91z"/>
        <path fill="url(#paint26_linear)" d="M131.663 69.863s-3.428 2.137-4.07 8.337c0 0 2.785-4.49 5.784-6.413l-1.714-1.924z"/>
        <path fill="url(#paint27_linear)" d="M181.576 164.558l2.142 30.782s5.355-.214 5.355 6.84c0 0-4.498-5.13-7.069-7.695.857-7.91-1.071-23.514-3.213-37.409l2.785-8.336L194 146.388l4.499 2.138.428.214.857 7.267v.214l.429 3.848c-.215 0-12.211-4.917-18.637 4.489z"/>
        <path fill="url(#paint28_linear)" d="M173.436 130.57s.642-6.841 3.856-12.398c4.284-7.268 12.21-12.399 20.136.855 3.428 5.771 4.713 24.582 6.855 30.567 0 0-16.066-6.84-24.635 11.971l-6.212-30.995z"/>
        <path fill="url(#paint29_linear)" d="M105.1 240.23s-11.568-5.558-13.71-4.916c-2.143.641-7.927 9.405-7.927 9.405s3.214.214 5.142-.427c1.928-.855 4.07-4.276 4.07-4.276s6.64 3.634 10.711 4.062c3.856.641 1.714-3.848 1.714-3.848z"/>
        <path fill="url(#paint30_linear)" d="M106.385 267.591c1.714 9.833-.643 21.589-.643 21.589-.214-1.923-12.639-24.368-9.854-29.071 2.785-4.703 8.569-5.13 10.925-5.985-.214.427-2.142 3.847-.428 13.467z" opacity=".83"/>
        <path fill="url(#paint31_linear)" d="M219.921 240.229c-5.356 4.062-27.42 3.421-27.42 3.421 1.499-.642 2.142-2.993 1.285-7.482 4.927 5.985 26.135 4.061 26.135 4.061z" opacity=".83"/>
        <path fill="url(#paint32_linear)" d="M122.023 49.554s1.285 6.626-2.785 11.33l-1.928 3.633.857 4.062s5.784-2.993 11.568-4.062c0 0-1.286-4.703 0-11.115 1.285-6.413-4.07-7.696-4.07-7.696s-2.785 2.565-3.642 3.848z"/>
        <path fill="#FBDBBA" d="M210.923 244.505s-31.49 5.13-59.553 3.847c-28.062-1.282-44.557 5.986-44.557 5.986 2.142-.855 2.356-2.779 19.065-6.841 16.709-4.061 64.48-2.779 66.408-3.848.429-.213.643-.427 1.071-.855l17.566 1.711z"/>
        <path fill="#FFDBAE" d="M124.593 232.961c-1.285 1.069-21.636 6.413-21.636 6.413l-2.999-1.282c0-.214.214-.214.214-.428.429 0 .857-.214 1.286-.214 4.927-.427 17.994-4.275 23.135-4.489z"/>
        <path fill="url(#paint33_linear)" d="M127.807 62.807c-.857 9.192 4.713 17.315 12.425 17.956 7.497.641 14.352-6.413 15.209-15.604.857-9.192-4.713-17.315-12.424-17.956-7.498-.642-14.353 6.412-15.21 15.604z"/>
        <path fill="url(#paint34_linear)" d="M184.147 74.564c-4.713 10.26-15.21 20.949-23.993 21.59-8.783.641-23.35-10.902-24.849-18.17-.215-1.282-.429-2.351-.429-3.42 0-2.993.643-5.558 1.5-7.91.642-1.923 1.071-3.633 1.285-5.343.429-5.13-1.714-26.934 0-31.21 2.356-6.412 7.712-12.825 15.424-13.253 18.637-.855 31.919 18.811 32.561 22.873.643 4.275 3.213 24.582-1.499 34.843z"/>
        <path fill="url(#paint35_linear)" d="M137.018 37.37s3.856-5.986 17.138-3.848c13.281 2.138 25.063 22.231 25.92 30.995.857 8.764-1.713 19.025-1.713 19.025s3.641-5.344 4.712-7.054c.857-1.924 4.07-18.384 4.07-18.384l-25.92-37.835-15.638-.855s-7.283 7.695-7.926 10.474c-.857 2.993-.643 7.482-.643 7.482z"/>
        <path fill="url(#paint36_linear)" d="M146.658 25.185s-6.641 0-8.997 4.703c0 0-.643.854-.643 5.985 0 0 2.999 13.894 3.214 23.941.214 5.772-3.428 7.696-10.283-4.489-5.141-9.192-5.57-22.872-5.57-22.872l7.07-16.888 13.495 1.283 1.714 8.337z"/>
        <path fill="url(#paint37_linear)" d="M205.997 49.127v1.923c-.215 3.207-2.357 16.674-14.996 7.696-.214-.214-.642-.427-.857-.641-13.71-10.26-23.778-30.354-29.562-33.774-5.998-3.42-13.71.427-13.71.427s-2.785-3.848-8.14-4.489c-5.57-.641-15.424 10.902-11.568 25.01 4.284 15.177 10.497 15.391 10.711 15.391l-.857 4.275s-5.784-.855-10.925-10.474c-1.071-1.924-3.213-8.764-3.642-16.46-.428-4.916 0-10.047 1.5-14.75 4.07-11.756 15.638-18.597 16.495-17.528 0 .214.214.214.214.428 3.213 3.634 6.855 5.771 10.711 7.054 5.141 1.71 10.925 2.138 16.923 2.779H170.865c4.927-.214 11.996-1.71 19.494 2.137 8.783 4.703 9.64 17.101 9.64 17.101 5.355 4.917 5.998 11.116 5.998 13.895z"/>
        <path fill="url(#paint38_linear)" d="M191.001 59.174c-.214-.213-.642-.427-.857-.64-8.568-7.91-20.565-24.156-23.35-36.768 0 0 13.282 22.873 33.419 14.322-.215.428-5.57 6.413-25.278.641 0 0 10.282 9.406 18.637 13.681 8.14 4.275 12.639-1.069 12.639-1.069v1.497s-1.071 18.383-15.21 8.336z" opacity=".83"/>
        <path fill="url(#paint39_linear)" d="M183.29 70.93s2.571 1.924 7.926.214c5.998-1.924 9.212-10.902 5.57-15.604-4.07-5.558-11.14-.642-13.496 1.71-2.142 2.351-2.571 11.115 0 13.68z"/>
        <path fill="url(#paint40_linear)" d="M183.718 65.8s1.928-4.916 11.354-9.192c0 0-2.999 2.138-3.642 3.42 0 0 3.428 5.558 1.071 8.123l-.428-1.068s-7.069 4.489-8.355-1.283z"/>
        <path fill="url(#paint41_linear)" d="M146.658 25.186s1.928-8.55 10.283-5.772c8.354 2.78 29.99 34.202 30.633 35.912.643 1.71-.428 7.696-.428 7.696s-6.641-10.902-15.21-23.941c-8.569-12.826-14.353-17.743-25.278-13.895z"/>
        <path fill="#FFA490" d="M153.941 39.294s-.214 12.612 2.571 22.872c0 0 4.499 2.993 9.854.642 5.356-2.138 7.926-7.482 7.926-7.482s.643-6.627-3.642-11.757c0 .214-11.567-4.703-16.709-4.275z"/>
        <path fill="url(#paint42_linear)" d="M156.941 49.769l1.714-3.42s7.926-1.283 12.853 8.764c0 0-6.212-4.49-14.567-5.344z"/>
        <path fill="url(#paint43_linear)" d="M156.941 60.883s1.714 2.993 6.427 2.993c4.713 0 8.783-7.481 8.783-7.481l-15.21 4.488z"/>
        <path fill="#FFA490" d="M137.019 41.217s.856 18.598.642 19.88l-.428 2.565s.428 2.993 1.928 2.993c1.499 0 5.998-3.42 7.069-7.268 1.071-3.848-1.714-13.68-1.714-13.68s-4.713-8.979-5.784-8.979c-1.285-.214-1.713 4.49-1.713 4.49z"/>
        <path fill="url(#paint44_linear)" d="M138.089 61.524s0 2.78.429 4.062c0 0 5.569-2.138 6.426-7.054l-6.855 2.992z"/>
        <path fill="url(#paint45_linear)" d="M137.018 41.218s0-3.42 1.5-3.634c1.714-.214 5.784 8.123 5.784 8.123s-3.642-11.97-5.998-11.757c-1.714.214-1.286 4.917-1.286 4.917v2.351z"/>
        <path fill="url(#paint46_linear)" d="M153.942 39.294s1.071 2.137 2.999 2.778c2.142.642 7.283-.855 13.496 1.71.214 0-5.784-4.061-16.495-4.488z"/>
        <path fill="#FFEDD1" d="M144.945 58.532s0 .214 0 0c-.429.855-.857 1.497-1.286 2.138-.428.641-.857 1.069-1.285 1.496-1.928 1.924-3.856 1.71-3.856 1.71-1.285-3.42-1.071-13.253-1.071-13.253 1.928-.641 3.427.214 4.713 1.71.428.642.857 1.069 1.285 1.71.857 1.71 1.5 3.634 1.5 4.276v.213zM156.94 50.623s-1.928 5.13 0 10.26c0 0 2.999 2.78 7.498 1.71 4.499-1.068 7.498-6.412 7.498-6.412s-5.57-8.978-14.996-5.558z"/>
        <path fill="#927708" d="M144.945 58.319v.427c-.857 1.71-1.714 2.78-2.571 3.42h-.214c-2.142-3.633-1.714-10.688-1.714-10.688.214 0 1.285.856 1.714.856 1.714 1.923 2.57 5.13 2.785 5.985zM159.94 49.982s-1.714 8.336 2.356 10.474c2.571 1.283 7.712-.641 7.712-6.627 0 0-5.355-5.13-10.068-3.847z"/>
        <path fill="#fff" d="M161.439 55.54c-.214.855-.857 1.282-1.714 1.068-.857-.213-1.285-.855-1.071-1.71.214-.855.857-1.282 1.714-1.069.857.214 1.285 1.07 1.071 1.71z"/>
        <path fill="#FFA490" d="M156.084 70.288s-5.569-5.985-9.425-4.489c-3.642 1.497-4.07 6.413-4.07 6.413s8.354.641 13.495-1.924z"/>
        <path fill="#D16365" d="M143.873 68.15s-2.142 2.352-1.714 4.062c.429 1.71 3.428 3.42 6.427 3.207 0 0-5.355-.855-5.784-3.634-.214-1.924 1.071-3.634 1.071-3.634z"/>
        <path fill="url(#paint47_linear)" d="M156.084 70.289s-1.071 2.565-7.497 4.916c0 0-4.927.214-5.784-3.634 0 .214 2.142-1.069 13.281-1.282z"/>
        <path fill="#CB5254" d="M155.227 71.358s-3.428-.428-4.07 2.993c0 0 2.356-.855 4.07-2.993z"/>
        <path fill="#831F3D" d="M162.724 73.71s-.428 1.71-.857 3.633c-.428 1.497-.856 4.062-1.713 5.344-1.714 2.993-7.284 5.13-8.997 4.49-1.072-.428-1.928-4.276-1.928-5.558v-1.07l13.495-6.84z"/>
        <path fill="#5E1030" d="M162.725 73.71s-.429 1.71-.857 3.633l-.643.855c-6.855 2.138-10.925 4.49-10.925 4.49l-.857-1.283v-1.069l13.282-6.626z"/>
        <path fill="#FF695C" d="M146.658 79.695s1.5.855 2.142 2.779c.643 1.71.857 3.206.857 3.206s1.928 5.13 5.356 4.275c6.212-1.282 7.069-10.474 8.354-17.1l-1.285 1.068s.643 2.565-1.928 7.696c-3.427 6.84-8.783 4.275-9.211 3.847-.429-.427-.429-3.847-1.714-5.557-1.714-1.71-2.571-.214-2.571-.214z"/>
        <path fill="#FFEDD1" d="M148.8 79.909l1.928 1.496s7.069-1.282 10.283-6.413L148.8 79.91z"/>
        <path fill="#CE545C" d="M149.228 80.336s-2.57 0-3.856-1.924c0 0 3.642.214 5.356 0 0 0 2.142-1.71 5.141-2.351s7.498-3.207 7.498-3.207-6.641 6.413-14.139 7.482z"/>
        <path fill="url(#paint48_linear)" d="M161.225 72.855s1.5-1.283 3.642-.428l-1.714.855c-.857.214-1.928-.427-1.928-.427z"/>
        <path fill="#FFA490" d="M161.011 67.296c-.857 1.496 4.927 3.634 8.997 1.924 4.498-1.924 6.855-7.91 5.998-8.337-1.5-.855-12.639 2.565-14.995 6.413zM134.876 74.35c0-2.993.643-5.558 1.5-7.91 2.57.215 4.713.215 5.141 1.284.428.855-2.785 7.054-6.641 6.626z"/>
        <path fill="url(#paint49_linear)" d="M138.089 49.768l1.714-3.42s4.713 5.344 5.355 12.185c-.214 0-.642-5.13-7.069-8.765z"/>
        <path fill="url(#paint50_linear)" d="M146.658 25.186s-2.785-3.848-8.14-4.49c3.642-9.405 2.785-12.825 2.142-13.894 3.213 3.634 6.855 5.772 10.711 7.054-3.642 2.993-4.713 11.33-4.713 11.33z" opacity=".83"/>
        <path fill="#FFFFF4" d="M205.997 49.127v.642c-.215-4.49-5.784-13.895-5.784-13.895s-2.357-11.116-10.283-15.39c-6.212-3.207-14.995-4.062-18.851-3.848 4.927-.214 11.996-1.71 19.494 2.137 8.783 4.703 9.64 17.101 9.64 17.101 5.141 4.275 5.784 10.474 5.784 13.253z"/>
        <path fill="#722C23" d="M168.08 54.685c0 2.137-1.5 3.847-3.213 3.847-1.714 0-3.214-1.71-3.214-3.847 0-2.138 1.5-3.848 3.214-3.848 1.713 0 3.213 1.71 3.213 3.848zM144.944 58.532s0 .214 0 0c-.428.855-.857 1.496-1.285 2.138-.857-.642-1.928-1.283-1.928-4.062 0-1.282.642-2.779 1.285-3.42.857 1.71 1.928 4.275 2.142 4.916l-.214.428z"/>
        <path fill="#392F51" d="M144.944 58.532s-1.499-9.191-9.426-9.619c0 0-.214 1.924 1.928 3.848l.215-1.497s3.427-1.282 7.283 7.268z"/>
        <path fill="#fff" d="M141.945 57.89c-.214.855-.856 1.283-1.713 1.069-.857-.214-1.286-.855-1.071-1.71.214-.855.856-1.283 1.713-1.069.857.214 1.286.855 1.071 1.71z"/>
        <path fill="url(#paint51_linear)" d="M146.658 25.186s3.856-4.061 7.712-2.779c3.642 1.069 21.422 43.394 29.348 42.111 7.926-1.282 11.354-10.688 11.354-10.688s-7.712-2.565-11.354-4.916c-3.856-2.352-15.209-27.576-25.064-29.5-11.782-2.35-11.996 5.772-11.996 5.772z"/>
        <path fill="#392F51" d="M156.298 52.761s-1.928-2.779-1.5-3.847c.429-1.07 12.639-1.497 17.352 7.481 0 0-5.356-7.695-15.21-5.558l-.642 1.924z"/>
        <path fill="url(#paint52_linear)" d="M129.092 146.388s1.071 15.177-2.356 22.018c0 0 7.926-11.116 5.57-25.438l3.213-4.061s6.855 2.351 11.996 7.481v-1.496s-4.07-5.772-10.497-8.337c0 0-5.784 8.764-7.926 9.833z"/>
        <path fill="url(#paint53_linear)" d="M177.506 59.602c-.857 9.191 4.713 17.314 12.424 17.956 7.712.64 14.353-6.413 15.21-15.605.857-9.192-4.713-17.315-12.425-17.956-7.712-.641-14.567 6.413-15.209 15.605z"/>
        <path fill="url(#paint54_linear)" d="M180.933 59.815c-.642 7.91 4.07 14.964 10.497 15.391 6.426.641 12.21-5.558 13.067-13.467.643-7.909-4.07-14.963-10.496-15.39-6.641-.642-12.425 5.343-13.068 13.466z"/>
        <path fill="url(#paint55_linear)" d="M186.932 60.242c-.429 4.703 2.356 8.55 5.998 8.978 3.641.214 7.069-3.207 7.497-7.696.429-4.702-2.356-8.55-5.998-8.978-3.642-.427-7.069 3.207-7.497 7.696z"/>
        <path fill="url(#paint56_linear)" d="M193.786 45.707s1.285-26.72-4.713-32.065c-5.998-5.344-26.991 2.352-26.991 2.352 2.784.855 6.855 0 10.496 1.924 0 0 11.782 5.344 16.495 26.934l4.713.855z"/>
        <path fill="url(#paint57_linear)" d="M193.787 45.706l-3.428-.642s-.857-11.115-5.355-21.59c-4.927-10.901-14.353-10.046-14.353-10.046s15.424-5.13 19.708 1.068c4.713 7.268 3.428 31.21 3.428 31.21z"/>
        <path fill="url(#paint58_linear)" d="M196.357 45.707l-.215-8.978s-4.07-2.78-8.997-2.138l1.071 10.688c0-.214 2.143-.214 8.141.428z"/>
        <path fill="url(#paint59_linear)" d="M185.003 35.233l2.143-.856 1.071 10.688-2.785.642-.429-10.474z"/>
        <path fill="url(#paint60_linear)" d="M159.083 108.98c-.428 1.069-1.071 3.206-1.928 5.985-3.427 10.688-9.854 30.14-9.854 30.14-3.427-4.489-14.353-10.688-14.353-10.688 8.998-13.894 10.926-25.437 14.353-26.292 3.642-.855 11.782.855 11.782.855z" opacity=".5"/>
        <path fill="url(#paint61_linear)" d="M163.153 108.979s-1.928 0-4.07 3.634c-2.142 3.634-4.713 12.826-2.142 15.178 2.785 2.565 13.924.427 16.281-4.917 2.57-5.344 4.712-11.116 12.853-13.681 0 0 2.785.428 5.784 3.42 0 0-6.213-2.778-10.497 1.497s-5.356 11.115-8.997 15.818c-3.214 4.062-19.066 7.054-19.28-2.351-.214-9.406 5.998-18.384 5.998-18.384s3.428-.214 4.07-.214z"/>
        <path fill="#D0CBFF" d="M147.729 149.809s-2.143 9.192-4.499 14.108c0 0 6.641 3.848 25.278 1.71l.643-13.253c-.215 0-13.068 1.924-21.422-2.565z"/>
        <path fill="#FFDBAE" d="M123.094 87.175s1.714 2.565 1.285 7.054c-.428 4.49-13.067 56.434-13.067 56.434s11.782-56.861 11.996-58.999c.428-2.137-.214-4.489-.214-4.489zM114.097 64.518s3.427 13.039 6.855 19.666c0 0-5.356-7.482-6.855-19.666zM151.371 13.856c-3.642.214-11.139-5.985-11.139-5.985-18.209 5.558-17.995 29.5-17.995 30.568-.428-4.916 0-10.047 1.5-14.75 4.07-11.756 15.638-18.597 16.495-17.528 0 .214.214.214.214.428 3.427 3.847 7.069 5.985 10.925 7.267z"/>
        <path fill="url(#paint62_linear)" d="M395.367 103.421c3.428 2.351 2.571 104.957.214 107.308-2.356 2.352-166.019 1.924-170.09-1.282-3.856-2.993 6.213-107.309 8.569-110.515 2.571-2.78 158.308 2.351 161.307 4.489z"/>
        <path fill="url(#paint63_linear)" d="M397.509 103.422c3.428 2.352 2.571 104.957.214 107.309-2.356 2.351-162.806 2.351-166.877-.641-3.855-2.993 3-107.95 5.356-110.943 2.571-2.992 158.308 2.138 161.307 4.275z"/>
        <path fill="url(#paint64_linear)" d="M397.509 103.421s-19.066 2.779-70.692 2.565c-51.627-.214-90.401-6.84-90.401-6.84s88.473-1.069 161.093 4.275z" opacity=".8"/>
        <path fill="url(#paint65_linear)" d="M396.224 105.986s3.642 49.379-5.141 100.04l-7.284.855s-19.708-56.86-67.479-100.895h79.904z" opacity=".8"/>
        <path fill="url(#paint66_linear)" d="M236.416 99.147s-10.496 91.917 4.07 101.109c21.422 13.467 136.458 11.543 136.458 11.543s-140.099 1.496-146.097-1.71c-5.999-3.207 3.213-98.544 5.569-110.942z" opacity=".2"/>
        <path fill="url(#paint67_linear)" d="M346.739 210.517c-.856 3.42-59.981 1.496-62.337-.856-2.357-2.351 1.071-38.69 2.999-42.324 1.928-3.634 54.626-1.71 56.982.213 2.356 1.711 3.213 39.546 2.356 42.967z"/>
        <path fill="url(#paint68_linear)" d="M349.31 210.517c-.857 3.42-59.981 1.496-62.338-.856-2.356-2.351 1.071-38.69 2.999-42.324 1.928-3.634 54.626-1.71 56.983.213 2.356 1.711 3.213 39.546 2.356 42.967z"/>
        <path fill="url(#paint69_linear)" d="M339.027 200.255s-15.638-11.329-32.775 0l10.711 23.301h26.992l-4.928-23.301z"/>
        <path fill="url(#paint70_linear)" d="M286.329 223.556l4.284-4.489s43.487-2.565 62.767 2.138l3.856 2.137h-70.907v.214z"/>
        <path fill="url(#paint71_linear)" d="M346.954 167.336s-12.425 2.779-28.277 2.993c-15.638.213-28.92-2.993-28.92-2.993s19.066-3.848 57.197 0z" opacity=".8"/>
        <path fill="url(#paint72_linear)" d="M306.252 200.254l-.643 4.062 4.927 14.108h4.07l-8.354-18.17z"/>
        <path fill="url(#paint73_linear)" d="M305.609 204.316l-12.639 7.91 15.424.213s-2.356-6.84-2.785-8.123z" opacity=".3"/>
        <path fill="url(#paint74_linear)" d="M237.702 223.129s-9.212-7.268-20.565-9.619c-11.354-2.352-16.71 9.619-16.71 9.619h37.275z"/>
        <path fill="url(#paint75_linear)" d="M437.569 248.994l15.209 231.932h-6.855l-24.849-231.932h16.495z"/>
        <path fill="url(#paint76_linear)" d="M394.082 241.726l3.641 239.199h-5.141l-18.423-239.199h19.923z"/>
        <path fill="url(#paint77_radial)" d="M463.918 223.128l-5.57 25.866h-619.522l-5.356-25.866h630.448z"/>
        <path fill="url(#paint78_linear)" d="M450.85 431.119l-3.213-182.125h-592.745l4.927 177.636 336.11 1.496 254.921 2.993z"/>
        <path fill="url(#paint79_linear)" d="M205.141 428.126l1.928-179.132h-1.5l-4.07 178.919 3.642.213z"/>
        <path fill="#B6616D" d="M-59.635 281.272c64.695 0 168.162-23.3 288.125-32.278l-.214-1.282c-202.651 15.391-372.099 31.423-373.598 31.423l-.215 1.282c.857-.213 41.345.855 85.902.855z" opacity=".2"/>
        <path fill="url(#paint80_linear)" d="M448.065 295.165s-99.398-13.466-271.416-14.108c-172.018-.641-321.114 20.094-321.114 20.094l-42.63 4.916-2.142-57.502h636.874l.428 46.6z" opacity=".47"/>
        <path fill="url(#paint81_linear)" d="M-215.372 223.128s5.998 12.612 12.425 16.888c6.426 4.275 40.701 4.702 40.701 4.702s299.693 1.497 373.599 2.779c73.905 1.283 248.494-5.771 248.494-5.771l-1.499 7.054h-670.293l-3.427-25.652z" opacity=".47"/>
        <path fill="url(#paint82_linear)" d="M224.634 48.698c-7.498.855-12.639 10.902-11.568 22.232 1.285 11.543 8.355 20.093 16.067 19.238 7.497-.855 12.853-10.902 11.567-22.231-1.285-11.33-8.354-19.88-16.066-19.239zm5.141 39.76c-6.855.855-13.496-7.054-14.567-17.528-1.071-10.475 3.642-19.667 10.497-20.308 6.855-.855 13.496 7.054 14.567 17.529 1.071 10.474-3.642 19.666-10.497 20.307z"/>
        <path fill="url(#paint83_linear)" d="M240.272 68.15c1.071 10.475-3.641 19.667-10.496 20.308-6.855.855-13.496-7.054-14.567-17.528-1.071-10.475 3.641-19.666 10.496-20.308 6.855-.855 13.282 7.054 14.567 17.529z" opacity=".32"/>
        <path fill="url(#paint84_linear)" d="M224.634 48.699l25.707-.428v1.069l-24.85 1.069c0 .213-2.142.64-.857-1.71z"/>
        <path fill="url(#paint85_linear)" d="M251.84 50.622s2.57 1.069 2.57 5.344h6.641l-1.071-5.344-2.785-2.138-5.355 2.138z"/>
        <path fill="url(#paint86_linear)" d="M260.194 46.989s1.928 3.848 2.571 6.84l-2.571.428s-1.713-2.993-2.999-3.634l2.999-3.634z"/>
        <path fill="url(#paint87_linear)" d="M440.781 213.723L419.788 80.977l-3.428 1.282 20.779 132.532 3.642-1.068z"/>
        <path fill="url(#paint88_linear)" d="M427.285 217.998L407.577 85.252l4.713-1.497 19.708 133.174-4.713 1.069z"/>
        <path fill="url(#paint89_linear)" d="M434.783 214.791l-20.78-132.532 3.214-1.282 20.993 132.746-3.427 1.068z"/>
        <path fill="url(#paint90_linear)" d="M411.005 223.129l1.071-5.131s27.42-9.619 41.344-2.565c.214 12.185-6.212 26.934-12.425 38.263h-29.99l-12.853-4.702h29.133s5.999-13.895 5.142-25.652l-21.422-.213z"/>
        <path fill="url(#paint91_linear)" d="M423.643 214.791L405.435 77.342h4.713l17.137 137.022-3.642.427z"/>
        <path fill="url(#paint92_linear)" d="M406.506 66.227l-157.237-44.89v3.42L403.721 72l2.785-5.772z"/>
        <path fill="url(#paint93_linear)" d="M405.435 77.344l-156.166-48.31v3.42l154.024 51.303 2.142-6.413z"/>
        <path fill="url(#paint94_linear)" d="M421.716 77.343c0 6.84-5.57 12.184-12.211 12.184-6.64 0-12.21-5.558-12.21-12.184 0-6.84 5.57-12.185 12.21-12.185 6.641 0 12.211 5.558 12.211 12.185z"/>
        <path fill="url(#paint95_linear)" d="M408.22 88.459c6.152 0 11.139-4.977 11.139-11.116 0-6.139-4.987-11.115-11.139-11.115-6.153 0-11.14 4.976-11.14 11.115 0 6.14 4.987 11.116 11.14 11.116z"/>
        <path fill="url(#paint96_linear)" d="M247.342 40.149v10.26h2.999l2.142-9.405s-2.142-1.069-5.141-.855z"/>
        <path fill="url(#paint97_linear)" d="M268.549 51.264l-36.846 5.344s-11.139 5.344-9.64 15.604c1.928 11.971 14.139 11.33 14.139 11.33l34.703-5.13c0-.214.643-20.522-2.356-27.148z"/>
        <path fill="url(#paint98_linear)" d="M275.619 64.09c.856 7.48-1.286 13.68-4.499 14.108-3.213.427-6.641-5.345-7.498-12.826-.857-7.482 1.286-13.681 4.499-14.108 3.427-.428 6.641 5.344 7.498 12.825z"/>
        <path fill="url(#paint99_linear)" d="M260.409 79.694s-5.57-4.703-6.213-14.322c-.642-9.62 3.214-12.612 3.214-12.612l11.353-1.71s7.069.427 8.997 11.33c1.928 11.115-5.784 15.39-5.784 15.39l-11.567 1.924z"/>
        <path fill="url(#paint100_linear)" d="M268.549 51.263c-3.213.428-4.07 7.268-3.427 13.467.642 6.2 4.498 12.612 7.283 12.826 2.785.214 6.427-8.337 5.141-15.605-1.499-7.481-6.64-11.115-8.997-10.688z"/>
        <path fill="url(#paint101_linear)" d="M238.558 48.058s-2.785 11.115 2.571 19.238l2.356-.214s-1.499-13.894 2.785-20.093l-7.712 1.069z"/>
        <path fill="url(#paint102_linear)" d="M243.699 66.868h1.286s-1.5-8.978 1.499-15.819l15.424-1.069-1.499-3.206h-13.925c-.214.214-5.141 6.199-2.785 20.094z"/>
        <path fill="url(#paint103_linear)" d="M439.068 213.722c0-.428 5.57-14.536 2.356-29.499-2.999-13.895-16.709-25.224-19.065-27.148.643-4.062 5.57-32.064 3.427-45.745-2.356-13.68-11.782-21.162-13.281-22.231-.857-2.138-8.997-20.094-29.991-28.217-14.781-5.771-5.57-9.833-25.064-5.985-9.639 1.924-17.78 3.634-21.207 3.206-28.491-3.206-55.269-21.803-67.908-30.568-4.713-3.206-6.641-4.703-7.712-4.703-.642 0-1.499 0-2.356.856-2.785 2.779-4.07 12.184-3.642 15.177.643 4.061 5.356 6.199 19.494 9.191 12.639 2.78 18.637 7.055 19.066 9.833.214 1.07-.643 2.138-2.143 2.993l.643 1.283c2.785-1.283 3.213-3.207 2.999-4.276-.642-4.275-8.569-8.55-20.136-11.115-14.782-3.207-17.995-5.344-18.423-8.123-.429-2.779.857-11.543 3.213-14.108.643-.642 1.071-.642 1.071-.428.643 0 3.642 2.138 7.069 4.49 12.853 8.977 39.631 27.574 68.551 30.78 3.641.428 11.567-1.068 21.636-3.206 19.279-3.847 9.854.214 24.206 5.772 21.637 8.337 29.134 27.361 29.134 27.575v.214l.214.214s10.283 7.481 12.854 21.376c2.356 14.108-3.428 45.317-3.428 45.531v.428l.429.213c.214.214 15.852 12.185 19.065 26.721 3.213 14.749-2.571 28.644-2.571 29.071l1.5.428z"/>
        <path fill="url(#paint104_linear)" d="M272.619 62.166s-1.071 4.703 1.928 7.696c0 0 13.924-1.497 17.78-7.054v-2.566s-8.355-2.565-19.708 1.924z"/>
        <path fill="url(#paint105_linear)" d="M264.265 29.032c0 6.84-5.57 12.185-12.211 12.185-6.64 0-12.21-5.558-12.21-12.185 0-6.627 5.355-12.184 11.996-12.184s12.425 5.344 12.425 12.184z"/>
        <path fill="url(#paint106_linear)" d="M250.769 40.148c6.152 0 11.139-4.976 11.139-11.115 0-6.14-4.987-11.116-11.139-11.116-6.152 0-11.14 4.977-11.14 11.116 0 6.139 4.988 11.115 11.14 11.115z"/>
        <path fill="url(#paint107_linear)" d="M431.999 253.482s3.855-10.474 8.782-40.828c0 0 8.569 0 12.639 2.565 0 0 3.642 18.811-9.425 38.263h-11.996z"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear" x1="178.814" x2="178.814" y1="293.92" y2="213.123" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="132.01" x2="226.088" y1="206.726" y2="206.726" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7567FF"/>
            <stop offset="1" stopColor="#87B3FF"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="178.731" x2="178.731" y1="214.98" y2="198.336" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8376FF"/>
            <stop offset="1" stopColor="#9FCAFF"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="146.809" x2="146.809" y1="293.92" y2="216.98" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#15142D"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="137.358" x2="137.358" y1="271.366" y2="230.577" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#557775"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="312.245" x2="312.245" y1="132.099" y2="76.524" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7567FF"/>
            <stop offset="1" stopColor="#87B3FF"/>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="309.816" x2="309.816" y1="321.626" y2="114.899" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="261.63" x2="261.63" y1="319.189" y2="102.214" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="279.215" x2="279.215" y1="277.136" y2="75.557" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8376FF"/>
            <stop offset="1" stopColor="#9FCAFF"/>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="291.263" x2="291.263" y1="229.352" y2="112.542" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#594357"/>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="224.931" x2="224.931" y1="214.809" y2="113.976" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#594357"/>
        </linearGradient>
        <linearGradient id="paint11_linear" x1="279.215" x2="279.215" y1="277.137" y2="100.087" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9D868D"/>
            <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="287.611" x2="287.611" y1="116.925" y2="79.572" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#FFA775"/>
        </linearGradient>
        <linearGradient id="paint13_linear" x1="287.616" x2="287.616" y1="302.822" y2="222.025" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint14_linear" x1="240.812" x2="334.89" y1="215.628" y2="215.628" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7567FF"/>
            <stop offset="1" stopColor="#87B3FF"/>
        </linearGradient>
        <linearGradient id="paint15_linear" x1="287.533" x2="287.533" y1="223.883" y2="207.238" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8376FF"/>
            <stop offset="1" stopColor="#9FCAFF"/>
        </linearGradient>
        <linearGradient id="paint16_linear" x1="255.611" x2="255.611" y1="302.823" y2="225.883" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#15142D"/>
        </linearGradient>
        <linearGradient id="paint17_linear" x1="246.16" x2="246.16" y1="280.267" y2="239.479" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#557775"/>
        </linearGradient>
        <linearGradient id="paint18_linear" x1="133.505" x2="133.505" y1="176.946" y2="44.992" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset=".989" stopColor="#FF9A81"/>
        </linearGradient>
        <linearGradient id="paint19_linear" x1="187.882" x2="192.934" y1="36.365" y2="8.203" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C07461"/>
            <stop offset="1" stopColor="#FFA072"/>
        </linearGradient>
        <linearGradient id="paint20_linear" x1="172.723" x2="206.646" y1="20.948" y2="27.059" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#EF9D9D"/>
        </linearGradient>
        <linearGradient id="paint21_linear" x1="172.684" x2="172.684" y1="173.401" y2="68.507" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset=".989" stopColor="#FF9A81"/>
        </linearGradient>
        <linearGradient id="paint22_linear" x1="146.589" x2="158.658" y1="148.795" y2="148.795" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint23_linear" x1="183.848" x2="183.848" y1="321.149" y2="107.808" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF30AA"/>
            <stop offset="1" stopColor="#C259FF"/>
        </linearGradient>
        <linearGradient id="paint24_linear" x1="179.243" x2="179.243" y1="243.962" y2="154.257" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#8E5E6F"/>
        </linearGradient>
        <linearGradient id="paint25_linear" x1="136.737" x2="136.737" y1="244.985" y2="115.468" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset="1" stopColor="#FFC5A4"/>
        </linearGradient>
        <linearGradient id="paint26_linear" x1="126.845" x2="132.644" y1="77.447" y2="70.567" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint27_linear" x1="178.829" x2="200.087" y1="174.345" y2="174.345" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF937F"/>
            <stop offset="1" stopColor="#E8868D"/>
        </linearGradient>
        <linearGradient id="paint28_linear" x1="188.761" x2="188.761" y1="161.92" y2="110.946" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C259FF"/>
            <stop offset="1" stopColor="#FF66EF"/>
        </linearGradient>
        <linearGradient id="paint29_linear" x1="94.524" x2="94.524" y1="244.859" y2="235.373" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset="1" stopColor="#FFC5A4"/>
        </linearGradient>
        <linearGradient id="paint30_linear" x1="101.31" x2="101.31" y1="289.209" y2="254.183" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#8E5E6F"/>
        </linearGradient>
        <linearGradient id="paint31_linear" x1="206.129" x2="206.129" y1="243.551" y2="235.945" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8E5E6F"/>
            <stop offset="1" stopColor="#D1978B"/>
        </linearGradient>
        <linearGradient id="paint32_linear" x1="116.879" x2="131.641" y1="67.545" y2="50.032" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint33_linear" x1="140.249" x2="143.057" y1="80.73" y2="47.245" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint34_linear" x1="151.624" x2="168.416" y1="94.601" y2="19.23" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset="1" stopColor="#FFC5A4"/>
        </linearGradient>
        <linearGradient id="paint35_linear" x1="154.9" x2="167.497" y1="78.247" y2="21.705" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA88C"/>
            <stop offset="1" stopColor="#E8868D"/>
        </linearGradient>
        <linearGradient id="paint36_linear" x1="128.878" x2="139.302" y1="62.278" y2="15.488" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C07461"/>
            <stop offset="1" stopColor="#C26172"/>
        </linearGradient>
        <linearGradient id="paint37_linear" x1="164.037" x2="164.037" y1="65.277" y2="6.161" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C07461"/>
            <stop offset="1" stopColor="#FFC090"/>
        </linearGradient>
        <linearGradient id="paint38_linear" x1="163.117" x2="206.98" y1="37.615" y2="47.429" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#CC9B9D"/>
        </linearGradient>
        <linearGradient id="paint39_linear" x1="193.578" x2="185.699" y1="69.909" y2="55.16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA98E"/>
            <stop offset="1" stopColor="#FFB19D"/>
        </linearGradient>
        <linearGradient id="paint40_linear" x1="192.919" x2="188.623" y1="67.851" y2="59.809" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF937F"/>
            <stop offset="1" stopColor="#E8868D"/>
        </linearGradient>
        <linearGradient id="paint41_linear" x1="163.911" x2="171.737" y1="57.781" y2="22.65" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C07461"/>
            <stop offset="1" stopColor="#C26172"/>
        </linearGradient>
        <linearGradient id="paint42_linear" x1="163.999" x2="165.347" y1="53.442" y2="47.39" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint43_linear" x1="163.339" x2="165.415" y1="64.084" y2="54.763" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint44_linear" x1="140.165" x2="141.951" y1="65.907" y2="57.893" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint45_linear" x1="140.573" x2="141.106" y1="45.476" y2="34.118" gradientUnits="userSpaceOnUse">
            <stop stopColor="#631F3B"/>
            <stop offset="1" stopColor="#7C3B33"/>
        </linearGradient>
        <linearGradient id="paint46_linear" x1="162.153" x2="162.325" y1="43.328" y2="39.663" gradientUnits="userSpaceOnUse">
            <stop stopColor="#631F3B"/>
            <stop offset="1" stopColor="#7C3B33"/>
        </linearGradient>
        <linearGradient id="paint47_linear" x1="150.72" x2="148.807" y1="74.525" y2="69.438" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint48_linear" x1="163.734" x2="162.524" y1="71.658" y2="73.63" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint49_linear" x1="140.721" x2="143.054" y1="57.62" y2="47.146" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint50_linear" x1="138.779" x2="150.547" y1="14.875" y2="17.508" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#CC9B9D"/>
        </linearGradient>
        <linearGradient id="paint51_linear" x1="166.131" x2="174.537" y1="60.681" y2="22.945" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C07461"/>
            <stop offset="1" stopColor="#FFC090"/>
        </linearGradient>
        <linearGradient id="paint52_linear" x1="122.563" x2="143.15" y1="164.845" y2="140.424" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D68498"/>
            <stop offset="1" stopColor="#FF817F"/>
        </linearGradient>
        <linearGradient id="paint53_linear" x1="189.786" x2="192.594" y1="77.493" y2="44.009" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint54_linear" x1="191.355" x2="193.787" y1="75.241" y2="46.249" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint55_linear" x1="193.011" x2="194.41" y1="69.178" y2="52.501" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7567FF"/>
            <stop offset="1" stopColor="#87B3FF"/>
        </linearGradient>
        <linearGradient id="paint56_linear" x1="186.965" x2="175.275" y1="47.872" y2="11.675" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#353458"/>
        </linearGradient>
        <linearGradient id="paint57_linear" x1="191.176" x2="179.566" y1="46.511" y2="10.559" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint58_linear" x1="193.921" x2="189.769" y1="46.478" y2="33.62" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ABB8B5"/>
            <stop offset="1" stopColor="#698294"/>
        </linearGradient>
        <linearGradient id="paint59_linear" x1="187.93" x2="184.703" y1="45.247" y2="35.256" gradientUnits="userSpaceOnUse">
            <stop stopColor="#596F91"/>
            <stop offset="1" stopColor="#649396"/>
        </linearGradient>
        <linearGradient id="paint60_linear" x1="146.145" x2="146.145" y1="145.031" y2="107.808" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCFC5"/>
            <stop offset="1" stopColor="#8E5E6F"/>
        </linearGradient>
        <linearGradient id="paint61_linear" x1="171.367" x2="173.601" y1="134.537" y2="107.92" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D0CBFF"/>
            <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="paint62_linear" x1="311.186" x2="311.186" y1="212.243" y2="98.164" gradientUnits="userSpaceOnUse">
            <stop stopColor="#455E84"/>
            <stop offset="1" stopColor="#9FAEBA"/>
        </linearGradient>
        <linearGradient id="paint63_linear" x1="314.757" x2="314.757" y1="212.503" y2="98.166" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8196A5"/>
            <stop offset="1" stopColor="#DDE5DB"/>
        </linearGradient>
        <linearGradient id="paint64_linear" x1="397.459" x2="236.365" y1="102.5" y2="102.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA775"/>
            <stop offset="1"/>
        </linearGradient>
        <linearGradient id="paint65_linear" x1="356.617" x2="356.617" y1="206.959" y2="105.966" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#557775"/>
        </linearGradient>
        <linearGradient id="paint66_linear" x1="302.965" x2="302.965" y1="212.158" y2="99.098" gradientUnits="userSpaceOnUse">
            <stop stopColor="#989AA9"/>
            <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="paint67_linear" x1="315.298" x2="315.298" y1="212.479" y2="165.166" gradientUnits="userSpaceOnUse">
            <stop stopColor="#455E84"/>
            <stop offset="1" stopColor="#9FAEBA"/>
        </linearGradient>
        <linearGradient id="paint68_linear" x1="317.806" x2="317.806" y1="212.479" y2="165.166" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8196A5"/>
            <stop offset="1" stopColor="#DDE5DB"/>
        </linearGradient>
        <linearGradient id="paint69_linear" x1="325.07" x2="325.07" y1="223.523" y2="195.142" gradientUnits="userSpaceOnUse">
            <stop stopColor="#596F91"/>
            <stop offset="1" stopColor="#8BAEB0"/>
        </linearGradient>
        <linearGradient id="paint70_linear" x1="357.181" x2="286.32" y1="220.966" y2="220.966" gradientUnits="userSpaceOnUse">
            <stop stopColor="#748B97"/>
            <stop offset="1" stopColor="#3D6381"/>
        </linearGradient>
        <linearGradient id="paint71_linear" x1="318.378" x2="318.378" y1="170.311" y2="165.599" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA775"/>
            <stop offset="1"/>
        </linearGradient>
        <linearGradient id="paint72_linear" x1="310.117" x2="310.117" y1="218.406" y2="200.197" gradientUnits="userSpaceOnUse">
            <stop stopColor="#455E84"/>
            <stop offset="1" stopColor="#6F86BA"/>
        </linearGradient>
        <linearGradient id="paint73_linear" x1="308.482" x2="293.046" y1="208.444" y2="208.444" gradientUnits="userSpaceOnUse">
            <stop stopColor="#04092E"/>
            <stop offset="1" stopColor="#fff"/>
        </linearGradient>
        <linearGradient id="paint74_linear" x1="219.129" x2="219.129" y1="223.161" y2="213.317" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint75_linear" x1="436.893" x2="436.893" y1="480.927" y2="248.935" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DDE5DB"/>
            <stop offset="1" stopColor="#8196A5"/>
        </linearGradient>
        <linearGradient id="paint76_linear" x1="386.02" x2="386.02" y1="480.927" y2="241.811" gradientUnits="userSpaceOnUse">
            <stop stopColor="#8196A5"/>
            <stop offset="1" stopColor="#DDE5DB"/>
        </linearGradient>
        <linearGradient id="paint78_linear" x1="152.859" x2="152.859" y1="431.059" y2="248.935" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9F2F3F"/>
            <stop offset="1" stopColor="#E05A2C"/>
        </linearGradient>
        <linearGradient id="paint79_linear" x1="204.336" x2="204.336" y1="428.17" y2="248.935" gradientUnits="userSpaceOnUse">
            <stop stopColor="#631F3B"/>
            <stop offset="1" stopColor="#CF4E47"/>
        </linearGradient>
        <linearGradient id="paint80_linear" x1="129.368" x2="129.368" y1="306.369" y2="248.934" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9ACBA"/>
            <stop offset="1" stopColor="#A94057"/>
        </linearGradient>
        <linearGradient id="paint81_linear" x1="122.287" x2="122.287" y1="248.934" y2="223.16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9ACBA"/>
            <stop offset="1" stopColor="#A94057"/>
        </linearGradient>
        <linearGradient id="paint82_linear" x1="229.246" x2="224.73" y1="90.427" y2="48.799" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint83_linear" x1="229.702" x2="225.584" y1="88.527" y2="50.568" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint84_linear" x1="237.378" x2="237.378" y1="50.615" y2="48.451" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint85_linear" x1="256.547" x2="256.547" y1="55.94" y2="48.451" gradientUnits="userSpaceOnUse">
            <stop stopColor="#455E84"/>
            <stop offset="1" stopColor="#6F86BA"/>
        </linearGradient>
        <linearGradient id="paint86_linear" x1="260.044" x2="260.044" y1="54.127" y2="46.95" gradientUnits="userSpaceOnUse">
            <stop stopColor="#596F91"/>
            <stop offset="1" stopColor="#8BAEB0"/>
        </linearGradient>
        <linearGradient id="paint87_linear" x1="428.588" x2="428.588" y1="214.748" y2="81.083" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint88_linear" x1="419.808" x2="419.808" y1="217.906" y2="83.794" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint89_linear" x1="426.165" x2="426.165" y1="214.748" y2="81.083" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint90_linear" x1="425.765" x2="425.765" y1="253.573" y2="212.776" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint91_linear" x1="416.368" x2="416.368" y1="214.747" y2="77.418" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint92_linear" x1="249.198" x2="406.521" y1="46.657" y2="46.657" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint93_linear" x1="249.198" x2="405.441" y1="56.405" y2="56.405" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint94_linear" x1="409.371" x2="409.371" y1="89.647" y2="65.188" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint95_linear" x1="408.183" x2="408.183" y1="88.463" y2="66.373" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint96_linear" x1="249.91" x2="249.91" y1="50.364" y2="40.047" gradientUnits="userSpaceOnUse">
            <stop stopColor="#574361"/>
            <stop offset="1" stopColor="#402F3F"/>
        </linearGradient>
        <linearGradient id="paint97_linear" x1="246.451" x2="246.451" y1="83.232" y2="51.193" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint98_linear" x1="270.979" x2="268.16" y1="78.105" y2="51.267" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint99_linear" x1="254.004" x2="277.715" y1="65.435" y2="65.435" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ABB8B5"/>
            <stop offset="1" stopColor="#698294"/>
        </linearGradient>
        <linearGradient id="paint100_linear" x1="264.861" x2="277.704" y1="64.346" y2="64.346" gradientUnits="userSpaceOnUse">
            <stop stopColor="#596F91"/>
            <stop offset="1" stopColor="#8BAEB0"/>
        </linearGradient>
        <linearGradient id="paint101_linear" x1="236.928" x2="246.972" y1="64.728" y2="47.293" gradientUnits="userSpaceOnUse">
            <stop stopColor="#698294"/>
            <stop offset="1" stopColor="#ABB8B5"/>
        </linearGradient>
        <linearGradient id="paint102_linear" x1="252.365" x2="252.365" y1="66.95" y2="46.949" gradientUnits="userSpaceOnUse">
            <stop stopColor="#596F91"/>
            <stop offset="1" stopColor="#8BAEB0"/>
        </linearGradient>
        <linearGradient id="paint103_linear" x1="348.469" x2="348.469" y1="213.645" y2="22.957" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint104_linear" x1="272.489" x2="292.298" y1="64.683" y2="64.683" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint105_linear" x1="251.913" x2="251.913" y1="41.242" y2="16.783" gradientUnits="userSpaceOnUse">
            <stop stopColor="#291628"/>
            <stop offset="1" stopColor="#352558"/>
        </linearGradient>
        <linearGradient id="paint106_linear" x1="250.725" x2="250.725" y1="40.058" y2="17.968" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <linearGradient id="paint107_linear" x1="442.894" x2="442.894" y1="253.572" y2="212.775" gradientUnits="userSpaceOnUse">
            <stop stopColor="#402F3F"/>
            <stop offset="1" stopColor="#574361"/>
        </linearGradient>
        <radialGradient id="paint77_radial" cx="0" cy="0" r="1" gradientTransform="matrix(-223.087 0 0 -222.611 148.71 236.047)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFE3D0"/>
            <stop offset="1" stopColor="#FFB69A"/>
        </radialGradient>
    </defs>
  </svg>
)

export default RjGirl
