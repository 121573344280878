import React, { useState } from 'react'

const AccountContext = React.createContext({
  user: {},
  onUpdateUser: () => {}
})

const AccountProvider = ({ userData, children }) => {
  const [user, updateUser] = useState(userData || {})

  const handleUpdateUser = data => updateUser(data)

  return (
    <AccountContext.Provider
      value={{
        user,
        onUpdateUser: handleUpdateUser
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export { AccountContext }
export default AccountProvider
