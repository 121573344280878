import React, { useState, useContext } from 'react'
import { CommentContext } from '../../../providers/commentProvider'
import {
  ReplyProfileImage,
  ProfileImageContainer,
  TypeReplyHere,
  TypeReplyDiv,
  ReplyButton,
  CancelButton,
  ExtendCommentLink,
  LinkCommentReplyCurve,
} from '../styles'
import { post } from '../../../lib/api'
import GenericError from '../../../components/GenericError'
import { getTokenAndUid } from '../../../lib/utils'
import { Popup } from '../../../components/Popup'
import { Box } from '../../../designSystem'

const ReplyContainerDiv = (props) => {
  const isMobile = window.screen.width < 600
  const { replyText, handleContentChange, close, createReplies } = props
  return (
    <Box height={132} position="relative" mr={120}>
      <TypeReplyDiv>
        <TypeReplyHere
          as="textarea"
          value={replyText}
          placeholder="Reply"
          rows={3}
          onChange={(e) => handleContentChange(e)}
        />
      </TypeReplyDiv>
      {!isMobile && (
        <Box position="absolute" right="0px">
          <CancelButton onClick={() => close()}>Cancel</CancelButton>
          <ReplyButton onClick={createReplies}>Reply</ReplyButton>
        </Box>
      )}
    </Box>
  )
}

const NewReply = ({
  index,
  show,
  close,
  entityKey,
  createReply,
  commentIndex,
  userInfo,
  moreReplies,
}) => {
  const { allComments, onUpdateComments } = useContext(CommentContext)
  const [replyText, setReplyText] = useState('')
  const [errorPopup, toggleErrorPopup] = useState(false)
  const { uid } = getTokenAndUid()

  const handleContentChange = (e) => {
    setReplyText(e.target.value)
  }

  const createReplies = () => {
    if (replyText.length === 0) return
    post('/social/comment.create', {
      data: {
        comment_creator_uid: uid,
        story_id: allComments[entityKey].story_id,
        comment: replyText,
        entity_type: 'story',
        parent_comment_id: allComments[entityKey].result[commentIndex]._id.$oid,
        show_id: allComments[entityKey].result[commentIndex].show_id,
        author_id: allComments[entityKey].result[commentIndex].author_id,
        creator_id: allComments[entityKey].result[commentIndex].creator_id,
        is_creator_note: false,
        is_replied: 1,
        comment_creator_name: userInfo.fullname,
        comment_creator_image: userInfo.image_url,
      },
    }).then((res) => {
      let comments = JSON.parse(JSON.stringify(allComments))
      let replies = comments[entityKey].result[commentIndex].comments
      replies.splice(index, 0, res.result.comment_obj)

      createReply()
      onUpdateComments(comments)
    })
    setReplyText('')
  }

  const isMobile = window.screen.width < 600
  return (
    <>
      <GenericError show={errorPopup} onClose={() => toggleErrorPopup(false)} />
      {show && (
        <Box
          variant="flex"
          position="relative"
          mt={16}
          ml={48}
          onClick={(e) => e.stopPropagation()}
        >
          {moreReplies && (
            <ExtendCommentLink>
              <LinkCommentReplyCurve />
            </ExtendCommentLink>
          )}
          <ProfileImageContainer>
            <ReplyProfileImage src={userInfo.image_url} />
          </ProfileImageContainer>
          {!isMobile ? (
            <ReplyContainerDiv
              replyText={replyText}
              handleContentChange={handleContentChange}
              createReplies={createReplies}
              close={close}
            />
          ) : (
            <Popup
              show={show}
              close={close}
              renderActions={
                <>
                  <CancelButton onClick={() => close()}>Cancel</CancelButton>
                  <ReplyButton onClick={createReplies}>Reply</ReplyButton>
                </>
              }
            >
              <ReplyContainerDiv
                replyText={replyText}
                handleContentChange={handleContentChange}
                createReplies={createReplies}
                close={close}
              />
            </Popup>
          )}
        </Box>
      )}
    </>
  )
}

export default NewReply
