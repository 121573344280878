import styled from 'styled-components'

const Input = styled.input`
  display: block;
  width: 100%;
  margin-top: 8px;
  background-color: var(--Grey300);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  transition: all .2s ease;
  border-radius: 4px;
  border: ${({ invalid }) => invalid ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'}; 
  padding-left: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--Text500);
  line-height: 1.4;

    :focus {
      border-color: var(--Azure500);
    }
`
export {
  Input
}
