import React from 'react'

const Eye =  props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
        <path fill="#0D1536" fillOpacity=".65" d="M19.05 8.81c-.164-.223-4.066-5.477-9.05-5.477-4.984 0-8.887 5.254-9.05 5.477-.156.213-.156.5 0 .713C1.112 9.746 5.015 15 10 15c4.984 0 8.886-5.254 9.05-5.477.155-.212.155-.5 0-.712zM10 13.794c-3.672 0-6.851-3.487-7.793-4.627C3.147 8.026 6.32 4.54 10 4.54c3.67 0 6.85 3.487 7.792 4.627-.94 1.141-4.113 4.626-7.792 4.626z"/>
        <path fill="#0D1536" fillOpacity=".65" d="M10 5.546c-2 0-3.627 1.624-3.627 3.62 0 1.997 1.627 3.621 3.627 3.621 2 0 3.626-1.624 3.626-3.62 0-1.997-1.627-3.621-3.626-3.621zm0 6.035c-1.333 0-2.418-1.083-2.418-2.414 0-1.331 1.085-2.414 2.418-2.414 1.333 0 2.417 1.083 2.417 2.414 0 1.33-1.084 2.414-2.417 2.414z"/>
    </svg>
)

export default Eye
