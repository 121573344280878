import { useRef, useEffect } from 'react'
import useInfiniteScroll from './useInfiniteScroll'
import useCreatorProfile from './useCreatorProfile'
import useSearchInput from './useSearchInput'
import usePaginationNavigator from './usePaginationNavigator'
import useInfiniteScrollLatest from './useInfiniteScrollLatest'

const useDidUpdate = (callback, deps) => {
  const hasMount = useRef(false)
  useEffect(() => {
    if (hasMount.current) {
      callback()
    } else {
      hasMount.current = true
    }
  }, deps)
}

// Check if the Intersection Observer API is supported
const useGenericInfiniteScroller =
  'IntersectionObserver' in window ? useInfiniteScrollLatest : useInfiniteScroll

export {
  useDidUpdate,
  useInfiniteScroll,
  useGenericInfiniteScroller,
  useCreatorProfile,
  useSearchInput,
  usePaginationNavigator
}
