import React from 'react'
import styled from 'styled-components'
import Listener from '../images/Listener.js'
import Money from '../images/Money'
import Name from '../images/Name'
import PilotOfStory from '../images/PilotOfStory'

const SectionContainer = styled.section`
  width: 75%;
  margin: 0px auto;
  padding: 2rem;
  @media (max-width: 600px) {
    width: 100%;
    padding: 1rem;
  }
`
const BenefitForWriterHeading = styled.h3`
  text-transform: capitalize;
  -webkit-background-clip: text;
  background-image: linear-gradient(100deg, #b0d5ff 5%, #0068e1 65%);
  background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: bold;
`
const BenefitGallery = styled.div`
  margin: 3rem auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const Benefit = styled.div`
  width: 20%;
  min-width: 8rem;
  svg {
    width: 100%;
    height: 8rem;
  }
`
const BenefitText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: var(--Text500);
  width: 90%;
  padding-top: 0.5rem;
`
function Index() {
  return (
    <>
      <SectionContainer>
        <BenefitForWriterHeading>Benefits for Writers </BenefitForWriterHeading>
        <BenefitGallery>
          <Benefit>
            <Money />
            <BenefitText> Earn upto INR 30 LAKHS in a year</BenefitText>
          </Benefit>
          <Benefit>
            <Name />
            <BenefitText> Have YOUR NAME featured on the story</BenefitText>
          </Benefit>
          <Benefit>
            <Listener />
            <BenefitText> Reach out to MILLIONS OF LISTENERS</BenefitText>
          </Benefit>
          <Benefit>
            <PilotOfStory />
            <BenefitText> Be the PILOT and HERO of your story</BenefitText>
          </Benefit>
        </BenefitGallery>
      </SectionContainer>
    </>
  )
}

export default Index
