import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { get } from '../../../lib/api'
import Step1 from './step1'
import Step2 from './step2'
// import Step3 from './step3'
// import Step4 from './step4'

const StepsDiv = styled.div`
  padding: 48px 168px;
  background-color: var(--Grey300);
  @media (max-width: 600px) {
    padding: 0;
  }
`
export const BackButton = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  cursor: pointer;
`
export const NextButton = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  background-color: ${({ isValid }) => isValid ? 'var(--Azure500)' : 'rgba(6,138,226, 0.3)'};
  padding: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  width: 200px;
  margin-left: auto;
  display: flex;
  justify-content: center;
`
export const ActionButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`
const WBPSteps = ({ currentStep, setCurrentStep, id, resetSteps, showTerms, reappliedProgramId }) => {
  return (
    <StepsDiv>
      {/* {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} />} */}
      {/* {currentStep === 2 && <Step2 setCurrentStep={setCurrentStep} />} */}
      {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} resetSteps={resetSteps} id={id} showTerms={showTerms} reappliedProgramId={reappliedProgramId} />}
      {currentStep === 2 && <Step2 resetSteps={resetSteps} />}
    </StepsDiv>
  )
}

export default WBPSteps
