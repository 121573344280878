import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import InfoIcon from '../../InfoIcon'
import DownArrow from '../../Images/DownArrow'
import CrossIcon from '../../Images/CrossIcon'
import Tooltip from '../../Tooltip'

const Container = styled.div`
  position: relative;
`
const ErrorSpan = styled.span``
const LabelSpan = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text500);
  margin-right: 6px;
`
const LabelContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const DropDownField = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : 'solid 1px var(--Grey900)'};
  background-color: var(--Grey300);
  padding: 12px 16px;
  cursor: pointer;
`
const DropDownFieldSpan = styled.span`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text300);
`
const OptionsDropDown = styled.div`
  width: 320px;
  max-height: 352px;
  overflow-y: auto;
  box-shadow: 0 20px 40px 0 rgba(24, 27, 37, 0.16);
  background-color: var(--Dove);
  position: absolute;
  top: 78px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`
const Option = styled.div`
  text-transform: capitalize;
  background-color: var(--Dove);
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--Text500);

  &:hover {
    background-color: var(--Grey500);
  }
`
const StyledDownArrow = styled(DownArrow)`
  align-self: center;
`
const StyledCrossIcon = styled(CrossIcon)`
  // align-self: center;
  fill: var(--TextDark700);
  height: 9px;
  width: 9px;
`
const CrossIconSpan = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: auto;
  text-align: center;
  margin-left: 8px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
const SelectionField = styled.div`
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 8px;
`
const SelectedValue = styled.div`
  border-radius: 4px;
  background-color: var(--Fjord800);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--TextDark700);
  padding-right: 6px;
  margin-right: 8px;
  margin-top: 3px;
`

// TODO: modify this component to take options object as well as options array

const MultiSelectDropDown 
= ({
  label,
  infoTitle,
  placeholder,
  options,
  tooltip,
  idKey='id',
  nameKey='name',
  selectKey='name',
  hasError,
  ...props
}) => {
  // const { values: {book_type: type} } = useFormikContext()
  // const [type, changeType] = useState(type)
  const [isOpen, toggleDropDownOpen] = useState(false)
  const [field, meta] = useField(props)
  const { value, onBlur, onChange } = field
  const dropDownRef = useRef()
  const dropDownFieldRef = useRef()

  const newHandleChange = (name) => {
    // do something with this val
    toggleDropDownOpen(false)
    const currentValues = value.split(',').map(each => each.trim())
    if (currentValues.indexOf(name) > -1) return
    currentValues.push(name)
    const newValues = currentValues.filter(Boolean).join(',')
    onChange({ target: { name: props.name, value: newValues } })
  }

  const handleDropDownClick = () => {
    toggleDropDownOpen(true)
  }

  const handleRemoveValue = v => {
    const currentValues = value.split(',').map(each => each.trim()).filter(Boolean)
    const newValues = currentValues.filter(val => val !== v).join(',')
    onChange({ target: { name: props.name, value: newValues } })
  }

  const handleClick = (e) => {
    if (!dropDownRef.current.contains(e.target) && !dropDownFieldRef.current.contains(e.target)) {
      toggleDropDownOpen(false)
    }
  }

  const getOptions = () => {
    const currentValues = value.split(',').map(each => each.trim()).filter(Boolean)
    return options.filter(option => currentValues.indexOf(option[selectKey]) === -1)
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const Field = (
    <DropDownField ref={dropDownFieldRef} hasError={hasError}>
      <DropDownFieldSpan onClick={handleDropDownClick}>
        {placeholder || 'Choose a Category'}
      </DropDownFieldSpan>
      <StyledDownArrow  onClick={handleDropDownClick}/>
    </DropDownField>
  )

  const selectedValues = value.split(',').map(each => each.trim()).filter(Boolean)
  const selectedValuesObjArr = selectedValues.map(v => options.find(opt => opt[selectKey] === v)).filter(Boolean)
  return (
    <Container>
      <LabelContainer>
        <LabelSpan htmlFor={props.id || props.name}>{label}</LabelSpan>
        <InfoIcon title={infoTitle} />
      </LabelContainer>
      {
        tooltip ? (
          <Tooltip title={tooltip.title}  placement={tooltip.placement} arrow>
            {Field}
          </Tooltip>
        ) : Field
      }
      <OptionsDropDown isOpen={isOpen} ref={dropDownRef}>
        {getOptions().map(option => (
          <Option key={option[idKey] + option[nameKey]} onClick={() => newHandleChange(option[selectKey])}>
            {option[nameKey]}
          </Option>
        ))}
      </OptionsDropDown>
      {!!selectedValuesObjArr && selectedValuesObjArr.length > 0 && <SelectionField>
        {selectedValuesObjArr.map((valObj) => (
          <SelectedValue key={valObj.name}>
            {valObj.name}
            <CrossIconSpan onClick={() => handleRemoveValue(valObj[selectKey])}>
              <StyledCrossIcon />
            </CrossIconSpan>
          </SelectedValue>
        ))}
      </SelectionField>}
    </Container>
  )
}


export default MultiSelectDropDown
