import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Loader from '../Loader'
import { Popup } from '../Popup'
import { get } from '../../lib/api'
import AddTaskAssets from '../AddTaskAssets'
import IndividualTaskDetail from '../IndividualTask'

function IndividualEpisode({
  redirectUrl,
  fetchTaskDetail,
  taskDetail,
  asset_id,
  task_id,
  locate,
  ...props
}) {
  const [mode, setMode] = useState(props?.mode)
  const [isLoading, setLoading] = useState(false)
  const [assetData, setAssetData] = useState(taskDetail)
  const queryParams = new URLSearchParams(window.location.search)
  const selected_tab = queryParams.get('selected_tab')
  const history = useHistory()
  useEffect(() => {
    if (mode === 'edit') {
      setLoading(true)
      get(`/content_api/assets_management/assets/${asset_id}/`)
        .then((response) => {
          setAssetData(response)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          throw error
        })
    }
  }, [mode])

  const onIndividualTaskClick = () => {
    setMode('edit')
  }

  const handleCancel = () => {
    if (selected_tab) {
      history.push(redirectUrl)
      setMode('view')
    } else {
      setMode('view')
    }
  }

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && mode === 'edit' && (
        <Popup show className="right-drawer-episode-form" close={handleCancel}>
          <AddTaskAssets
            handleCancel={handleCancel}
            fetchTaskDetail={fetchTaskDetail}
            locate={locate}
            assetData={assetData}
            task_id={task_id}
            asset_id={asset_id}
            mode={mode}
          />
        </Popup>
      )}
      {!isLoading && mode === 'view' && (
        <IndividualTaskDetail
          sequence_number={taskDetail?.seq_number}
          title={taskDetail?.story_title || taskDetail?.title}
          onIndividualTaskClick={onIndividualTaskClick}
        />
      )}
    </>
  )
}

export default IndividualEpisode
