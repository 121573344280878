import React from 'react'
import styled from 'styled-components'
import { Popup } from '../Popup'
import InfoIcon from '../Images/BigInfoIcon'

const ErrorHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const ErrorSpan = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text700);
`
const ErrorBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);

  a {
    text-decoration: underline;
    color: unset;
  }
`
const GenericErrorPopup = ({renderIcon, show, onClose, title, message}) => (
  <Popup
    show={show}
    close={onClose}
    renderActions={
        <button
          className="action-button primary-button"
          type="button"
          onClick={onClose}
        >
          Okay
        </button>
    }
  >
    <ErrorHeading>
      {renderIcon || <InfoIcon />}
      <ErrorSpan>{title || "Something went Wrong!"}</ErrorSpan>
    </ErrorHeading>
    <ErrorBody>
      {!!message && message}
      {!message && (
        <>It looks like something went wrong on our end, you can try again, if this continues to happen, <a href="mailto:care@pocketfm.in">Please let us know.</a></>
      )}
    </ErrorBody>
  </Popup>
)

export default GenericErrorPopup
