import * as React from "react";
const ExpandLess = (props) => (
  <svg
    height={20}
    viewBox="0 0 48 48"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24 16l-12 12 2.83 2.83 9.17-9.17 9.17 9.17 2.83-2.83z" />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);
export default ExpandLess;