import React, { useContext, useEffect, useState } from 'react'
import { get } from '../../lib/api'
import GenericError from '../GenericError'
import { checkOverseasUser, getParticularDocument, getTokenAndUid } from '../../lib/utils'
import Loader from '../Loader'
import { AccountContext } from '../../providers/accountProvider'

const ProfileContainer = (props) => {
  const [userData, setUserData] = useState({})
  const [apiError, toggleApiError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AccountContext)
  const isOverseasUser =  checkOverseasUser(user)
  useEffect(() => {
    fetchData()
  }, [user])

  
  const fetchData = async function () {
    setIsLoading(true)
    const { uid } = getTokenAndUid()
    const allPromises = [
      get('/user_api/user.get_details', {
        params: {
          uid,
          is_novel: 0,
        },
      }),
      get('/user_api/user.get_creator_details?is_novel=0'),
      get(`/user_api/user_addresses/${uid}/?is_novel=0`),
      isOverseasUser
        ? get(`/user_api/row_creator/${uid}/`)
        : get(`/user_api/user_bank_accounts/${uid}/?is_novel=0`),
      get(`/user_api/voice_sample/?creator_id=${uid}`),
    ]
    Promise.all(allPromises)
      .then((responseArray) => {
        const [userDetails, creatorAccDetails, addressDetails, bankDetails, voiceSamples] =
          responseArray
        const { fullname, gender, email, phone_number, bio, image_url } =
          userDetails.result[0]
        let {
          account_type,
          languages,
          voice_artist_creator_details = {},
          alias,
        } = creatorAccDetails.result

        let pop_filter = voice_artist_creator_details.pop_filter ? 'yes' : 'no'
        if (
          voice_artist_creator_details.pop_filter === undefined ||
          voice_artist_creator_details.pop_filter === ''
        )
          pop_filter = ''

        let micro_phone_stand = voice_artist_creator_details.micro_phone_stand
          ? 'yes'
          : 'no'
        if (
          voice_artist_creator_details.micro_phone_stand === undefined ||
          voice_artist_creator_details.micro_phone_stand === ''
        )
          micro_phone_stand = ''

        let basic_sound_proofing =
          voice_artist_creator_details.basic_sound_proofing ? 'yes' : 'no'
        if (
          voice_artist_creator_details.basic_sound_proofing === undefined ||
          voice_artist_creator_details.basic_sound_proofing === ''
        )
          basic_sound_proofing = ''

        let basic_sound_proofing_img = JSON.parse(
          voice_artist_creator_details.basic_sound_proofing_img || '{}'
        )
        let image_url1 = (basic_sound_proofing_img.urls || [])[0]
        let image_url2 = (basic_sound_proofing_img.urls || [])[1]
        let image_url3 = (basic_sound_proofing_img.urls || [])[2]
        voice_artist_creator_details = {
          ...voice_artist_creator_details,
          pop_filter,
          micro_phone_stand,
          basic_sound_proofing,
          image_url1,
          image_url2,
          image_url3,
          basic_sound_proofing_img,
        }

        const { address_id, address_1, address_2, pin_code, city, state } =
          addressDetails.result[0] || {}
          setIsLoading(true)
        if(isOverseasUser){
          if(bankDetails.documents){
            var cancelled_cheque_url = getParticularDocument(bankDetails.documents,'cancelled_cheque_url','url')
            var f10 = getParticularDocument(bankDetails.documents,'f10','url')
            var tin = getParticularDocument(bankDetails.documents,'tin','url')
            var npec = getParticularDocument(bankDetails.documents,'npec','url')
            var trc = getParticularDocument(bankDetails.documents,'trc','url')
            var id_proof = getParticularDocument(bankDetails.documents,'id_proof','url')
          }
          setIsLoading(true)
          var {
            account_name,
            bank_name,
            account_no,
            swift_code,
            id,
            routing_number,
            bank_address,
          } = bankDetails || {}
        }else{
          var {
            account_id,
            account_name,
            bank_name,
            account_no,
            ifsc_code,
            pan_card_no,
            cancelled_cheque_url,
            pan_card_url,
            gst_number,
          } = bankDetails.result[0] || {}
        }

        setUserData({
          fullname,
          gender,
          email,
          phone_number,
          bio,
          image_url,
          account_type,
          alias,
          languages,
          address_id,
          address_1,
          address_2,
          pin_code,
          city,
          state,
          account_id,
          account_name,
          bank_name,
          account_no,
          ifsc_code,
          pan_card_no,
          gst_number,
          cancelled_cheque_url,
          pan_card_url,
          swift_code,
          f10,
          tin,
          voice_artist_creator_details,
          npec,
          trc,
          id,
          routing_number,
          bank_address,
          id_proof,
          bankDetailDocuments : bankDetails.documents || [],
          experience: creatorAccDetails?.result?.experience,
          voice_range: creatorAccDetails?.result?.voice_range,
          voice_texture: creatorAccDetails?.result?.voice_texture,
          years_of_exp : creatorAccDetails?.result?.years_of_exp,
          voice_samples: voiceSamples?.result,
          other_accents: creatorAccDetails?.result?.other_accents,
          major_professional_details: creatorAccDetails?.result?.major_professional_details
        })
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })
  }

  const child = React.Children.only(props.children)

  if (isLoading) {
    return <Loader />
  }

  if (apiError) {
    return (
      <GenericError show={apiError} onClose={() => toggleApiError(false)} />
    )
  }
  return React.cloneElement(child, {
    ...props,
    userData,
    reset: fetchData,
    isOverseasUser : isOverseasUser
  })
}

export default ProfileContainer
