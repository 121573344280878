import { useEffect } from 'react'
import { getTokenAndUid } from '../../lib/utils'

const FreshChat = () => {
  useEffect(() => {
    if (window.fcWidget && process.env.REACT_APP_DEPLOY_ENV === 'production') {
      window.fcWidget.user.get(function(resp) {
        let status = resp && resp.status
        // if (status !== 200) {
        // }
        if (getTokenAndUid().uid) {
          const uid = getTokenAndUid().uid
          const firstName = window.localStorage.getItem('name') || ''
          const phone = window.localStorage.getItem('phone') || ''
          window.fcWidget.setExternalId(uid)
          window.fcWidget.user.setProperties({
            firstName,
            phone
          })
        }
      })
    }
  }, [])
  return null
}

export default FreshChat
