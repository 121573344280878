import React from 'react'
import IncomeContainer from './Income.container'
import IncomeUI from './Income.UI'
import PrivatePage from '../../../components/PrivatePage'

const Income = (props) => {
  return (
    <PrivatePage>
      <IncomeContainer {...props}>
        <IncomeUI />
      </IncomeContainer>
    </PrivatePage>
  )
}

export default Income
