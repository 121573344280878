import React from 'react'

const BrandLogoIcon = (props) => {
  return (
    <svg
      width="110"
      height="28"
      viewBox="0 0 129 32"
      {...props}
    >
      <path
        d="M0 4.697C0 2.103 2.093 0 4.674 0H27.17c2.582 0 4.675 2.103 4.675 4.697v22.606c0 2.594-2.093 4.697-4.675 4.697H4.674C2.093 32 0 29.897 0 27.303V4.697z"
        fill="#068AE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.875 5.509c4.962 0 8.984 4.036 8.984 9.015 0 4.98-4.022 9.015-8.984 9.015a8.876 8.876 0 0 1-3.973-.93L6.89 26.417V13.945h.019c.3-4.714 4.2-8.436 8.966-8.436z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.228 11.702h.344l2.942-1.88c.043-.024.098-.012.122.025.025.043.013.099-.024.123l-2.708 1.732h4.593c.48 0 .866.388.866.869v3.395c0 .48-.387.869-.866.869h-5.263a.866.866 0 0 1-.866-.869v-3.395a.861.861 0 0 1 .86-.87z"
        fill="#068AE2"
      />
      <path
        d="M18.028 15.967a.951.951 0 0 0 0-1.342.944.944 0 0 0-1.337 0 .951.951 0 0 0 0 1.342c.37.37.968.37 1.337 0zM15.291 16.25a.424.424 0 1 0 0-.85.424.424 0 0 0 0 .85z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.885 16.243a.425.425 0 0 0 0-.85.425.425 0 0 0 0 .85z"
        fill="#fff"
      />
      <path
        d="M16.44 12.639h-3.07v.905h3.07v-.905zM18.264 12.639h-1.45v.905h1.45v-.905z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.933 11.252c.448.025.841.339.97.77.013.05-.012.099-.061.111-.05.013-.098-.012-.11-.061a.909.909 0 0 0-.811-.647c-.05 0-.086-.043-.086-.093a.09.09 0 0 1 .098-.08z"
        fill="#068AE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.927 10.864h.172c.65 0 1.179.53 1.185 1.183 0 .05-.037.086-.086.086a.084.084 0 0 1-.086-.086c0-.555-.454-1.004-1.007-1.004h-.172a.084.084 0 0 1-.086-.087c-.006-.055.03-.092.08-.092z"
        fill="#068AE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.927 10.457h.129c.86 0 1.566.709 1.566 1.571v.019c0 .05-.037.086-.086.086a.084.084 0 0 1-.086-.086v-.019c0-.764-.627-1.392-1.388-1.392h-.129a.084.084 0 0 1-.086-.086c-.006-.056.03-.093.08-.093z"
        fill="#068AE2"
      />
      <path
        d="M43.267 26.4h5.416l3.542-11.7h.163l3.543 11.7h5.415l6.15-20.945h-6.312l-2.85 12.927h-.164L54.79 5.455h-4.967l-3.38 12.886h-.163L43.43 5.455h-6.312L43.267 26.4zM68.142 26.4h5.62v-8.182c0-1.8 1.231-2.986 2.89-2.986.581 0 1.518.092 2.078.286v-4.817a5.058 5.058 0 0 0-1.426-.215c-1.67 0-2.972.982-3.542 3.191h-.163v-2.986h-5.457V26.4zM80.285 26.4h5.62V10.69h-5.62V26.4zm2.81-17.345c1.527 0 2.769-1.156 2.769-2.578 0-1.421-1.242-2.577-2.769-2.577-1.527 0-2.769 1.156-2.769 2.577 0 1.422 1.242 2.578 2.77 2.578zM97.806 10.69h-2.688V6.928H89.5v3.764h-1.995v4.09h1.995v7.099c-.04 3.293 1.985 4.96 5.864 4.745a11.445 11.445 0 0 0 2.728-.49l-.814-3.97c-.214.052-.764.165-1.1.165-.733 0-1.059-.328-1.059-.982v-6.566h2.688V10.69zM107.137 26.686c4.336 0 7.126-2.086 7.655-5.359h-5.131c-.325.89-1.252 1.391-2.402 1.391-1.67 0-2.647-1.104-2.647-2.618v-.245h10.18v-1.31c0-5-3.054-8.059-7.777-8.059-4.846 0-7.94 3.232-7.94 8.1 0 5.083 3.053 8.1 8.062 8.1zm-2.525-9.94c.031-1.36 1.151-2.291 2.525-2.291 1.394 0 2.453.94 2.484 2.29h-5.009zM116.798 26.4h5.62v-8.182c0-1.8 1.232-2.986 2.891-2.986.58 0 1.517.092 2.077.286v-4.817a5.062 5.062 0 0 0-1.426-.215c-1.669 0-2.972.982-3.542 3.191h-.163v-2.986h-5.457V26.4z"
        fill="#068AE2"
      />
    </svg>
  )
}

export default BrandLogoIcon
