import React, { useEffect } from 'react'
import styled from 'styled-components'
import { applicationUpdate } from '../constants'
import { getTokenAndUid } from '../../../lib/utils'
import { post } from '../../../lib/api'
import { Text } from '../../../designSystem'

export const Container = styled.div`
  padding: 40px 48px 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    padding: 24px 16px;
  }
`
export const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: var(--Text700);
  margin-bottom: 40px;
`
// const Text = styled.div`
//   font-size: 16px;
//   line-height: 1.75;
//   text-align: left;
//   color: var(--Text700);
//   margin-bottom: 16px;
// `
const UpsellDiv = styled.div`
  margin-top: 24px;
  padding: 16px;
  padding: 16px;
  border-radius: 4px;
  border: solid 2px var(--Grey900);
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text700);
`
const OkayButtonDiv = styled.div`
  margin-top: 16px;
`
const OkayButton = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  width: 200px;
  display: flex;
  justify-content: center;
  margin: auto;
`
const RejectionCard = ({id, customDescription, isRejected, isUpdated, action_type, toReplace, withReplace, handleReset}) => {
  useEffect(() => {
    if (!id) return
    const { uid } = getTokenAndUid()
    if (isRejected || isUpdated) {
      post('/content_api/book.user_application/', { data: {
        uid,
        id,
        is_viewed: 1
      } })
    }
  }, [])

  return (
    <Container>
      <Text textAlign="center" variant="titleMedium" mb={16}>{isRejected ? 'Application rejected' : 'Application Update'}</Text>
      {!customDescription && applicationUpdate[action_type].map((text, i) => {
        return <Text mb={16} key={'application-update-text' + i}>
          {text.replace(toReplace, withReplace)}
        </Text>
      })}
      {customDescription && (
        <>
          <Text mb={8}>
            {'Dear {{name}},'.replace(toReplace, withReplace)}
          </Text>
          <Text mb={24}>
            {customDescription}
          </Text>
        </>
      )}
      {/* <UpsellDiv>
        {applicationUpdate.milestone_upsell}
      </UpsellDiv> */}
      <OkayButtonDiv>
        <OkayButton onClick={handleReset}>Okay</OkayButton>
      </OkayButtonDiv>
    </Container>
  )
}

export default RejectionCard
