import React from 'react'

const Save = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
        <path fill="#068AE2" fillOpacity="0.8" d="M3.06 10.283V3.51c.009-1.328.78-2.106 2.123-2.106h9.88c1.389 0 2.16.77 2.16 2.151 0 4.532.01 9.064-.008 13.595 0 .257-.083.559-.24.742-.266.302-.597.174-.9-.037-1.765-1.235-3.53-2.48-5.304-3.698-.772-.531-.468-.513-1.231.009-1.756 1.208-3.493 2.426-5.24 3.644-.266.183-.533.375-.882.174-.35-.193-.358-.504-.358-.834v-6.866zm1.296 5.896c.193-.128.294-.192.395-.256 1.608-1.117 3.217-2.225 4.816-3.36.414-.293.754-.293 1.158 0 .8.586 1.627 1.144 2.445 1.712.901.622 1.793 1.245 2.767 1.922v-.723V3.62c0-.724-.193-.925-.92-.925H5.276c-.726 0-.92.192-.92.925v12.56z"/>
        <path fill="#068AE2" fillOpacity="0.8" d="M10.137 5.248c-.413 0-.827.01-1.24 0-.414-.009-.69-.284-.69-.64 0-.358.276-.632.69-.642.827-.009 1.645-.009 2.472 0 .414.01.69.275.69.641 0 .357-.276.623-.69.641-.405.018-.818.01-1.232 0z"/>
    </svg>
)

export default Save
