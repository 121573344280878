import React from 'react'
import SubHeader from '../../../../../components/WithSubHeaderBG' 
import RequirementForm from './RequirementsForm'
import { menuItemObj } from '../../../../../components/PrivatePage'


const SetupAudition = (props) => {
  const { match: { params: { id }}, history} = props

  return (
    <SubHeader
      title='Setup Audiobook Audition'
      showBackButton
    >
      <RequirementForm 
        id={id}
        cancelCB={() => history.goBack()}
        successCB={() => history.push(`${menuItemObj.audiobooks}?tab=auditions`)}
      />
    </SubHeader>
  )
}

export default SetupAudition
