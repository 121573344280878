import React from 'react'

const CheckboxAddIcon = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.071 4.929A9.935 9.935 0 0 0 12 2a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 2 12a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 12 22a9.935 9.935 0 0 0 7.071-2.929A9.935 9.935 0 0 0 22 12a9.935 9.935 0 0 0-2.929-7.071zM12 20.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.219h3.516v1.563H12.78v3.515H11.22v-3.515H7.703v-1.563h3.516V7.702h1.562v3.516z" fill="#0D1536" fillOpacity=".65"/>
    </svg>


)

export default CheckboxAddIcon
