import React, { useState } from 'react'
import styled from 'styled-components'
import CalendarIcon from '../../../../components/Images/CalendarOutlineIcon'
import { VellipContainer, VellipIcon } from '../styles'
import { Text, Button } from '../../../../designSystem'
import { Popup } from '../../../../components/Popup'
import { getDate } from '../../../../components/TextEditor/helper'
import Recover from '../../../../components/Images/Redo'
import { Dustbin, ChapterOptionsBox, Option } from './commonStyles'

const ButtonWrapper = styled.div`
  display: none;
`
const ButtonWrapperLeft = styled(ButtonWrapper)`
  margin-right: 0;
  margin-right: 16px;
  margin-left: auto;
`
const ChapterHolder = styled.div`
  cursor: pointer;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  :hover {
    transition: 200ms;
    box-shadow: 0 4px 8px 0 rgba(73, 83, 89, 0.16);
  }
  :hover ${ButtonWrapper} {
    display: block;
  }
`
const Container = styled.div``
const InfoHolder = styled.div`
  display: flex;
  margin-top: 6px;
`
const Calendar = styled(CalendarIcon)`
  fill: rgba(13, 21, 54, 0.65);
`
const isMobile = window.screen.width < 600

const DraftChapter = ({ chapter, onClick, handleDraftDelete, handleTrashDelete, handleChapterRecover }) => {
  const [isChapterOptionsShown, toggleShowChapterOptions] = useState(false)

  const handleOptionDelete = (e, mobile) => {
    if (mobile) toggleShowChapterOptions(false)
    if (handleDraftDelete) handleDraftDelete(e, 'draft', chapter.chapter_id)
    if (handleTrashDelete) handleTrashDelete(e, chapter.chapter_id)
  }
  const handleOptionRecover = (e, mobile) => {
    if (mobile) toggleShowChapterOptions(false)
    handleChapterRecover(e, chapter.chapter_id)
  }

  const handleVellipClick = (e) => {
    e.stopPropagation()
    toggleShowChapterOptions(!isChapterOptionsShown)
  }

  return (
    <>
      <Popup
        className="bottom-sheet no-padding"
        show={isChapterOptionsShown}
        close={() => toggleShowChapterOptions(false)}
      >
        <ChapterOptionsBox>
          {handleChapterRecover && <Option onClick={e => handleOptionRecover(e, true)}>
            <Recover />
            <Text variant="title" fontWeight="r">Recover</Text>
          </Option>}
          <Option onClick={e => handleOptionDelete(e, true)}>
            <Dustbin />
            <Text variant="title" fontWeight="r">Delete</Text>
          </Option>
        </ChapterOptionsBox>
      </Popup>
      <ChapterHolder onClick={onClick}>
        <Container>
            <Text variant="titleSecondary" >
              {chapter.chapter_title}
            </Text>
          <InfoHolder>
            <Calendar />
              <Text variant="caption" ml="4px">
                {getDate(chapter.create_time)}
              </Text>
          </InfoHolder>
        </Container>
        {!isMobile && handleChapterRecover && <ButtonWrapperLeft  variant="semiFillPrimary" onClick={handleOptionRecover}>
          <Button variant="semiFillPrimary">Recover</Button>
        </ButtonWrapperLeft>}
        {!isMobile && <ButtonWrapper onClick={handleOptionDelete}>
          <Button variant="semiFillDanger">Delete</Button>
        </ButtonWrapper>}
        {isMobile && <VellipContainer>
          <VellipIcon
            onClick={handleVellipClick}
          />
        </VellipContainer>}
      </ChapterHolder>
    </>
  )
}

export default DraftChapter
