import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Axios from 'axios'
import queryString from 'query-string'

import GenericError from '../../components/GenericError'
import SaveIcon from '../../components/Images/Save'
import Loader from '../../components/Loader'
import PrivatePage from '../../components/PrivatePage'
import {
  TextEditor,
  getContent,
  isContentChanged,
  isUndoEmpty,
  isRedoEmpty,
  handleRedo as onRedo,
  handleUndo as onUndo,
} from '../../components/TextEditor'
import { Provider } from '../../context/editorContext'
import { get, post } from '../../lib/api'
import { set } from '../../lib/storage'
import { isWordCountZero } from '../../lib/utils'
import { EditorControls } from '../../../src/pages/Shows/Audiobooks/Unpublished/Edit/chapterEditor'
import { getAudiobookWordCountLimit } from '../../../src/pages/Shows/Audiobooks/Unpublished/Edit/helpers'

const EpisodeScriptEditor = (props) => {
  const {
    history,
    match: {
      params: { id },
    },
    location: { search },
  } = props
  const qParams = queryString.parse(search)
  const { story_title, task_id, entity_id, asset_id } = qParams
  const [content, setContent] = useState('')
  const [initialContent, setInitialContent] = useState('')
  const [wordCount, setWordCount] = useState(0)
  const [editorState, setEditorState] = useState(null)
  const [title, setTitle] = useState(story_title || 'Untitled Chapter')
  const [isLoading, setLoading] = useState(false)
  const [apiError, toggleApiError] = useState(false)
  const [editorToolbarChange, setEditorToolbarChange] = useState(false)

  const location = useLocation()
  const tab = location?.state?.tab?.split(' ')?.join('_')
  const redirectUrl = `${location?.state?.locate?.pathName}?tab=${location?.state?.locate?.tab}&id=${location?.state?.locate.id}&page_no=${location?.state?.pageNo}&selected_tab=${tab}&task_id=${task_id}`
  useEffect(() => {
    set('asset_id', asset_id)
    if (!asset_id) return
    setLoading(true)
    get(`/content_api/assets_management/assets/${asset_id}/`).then(
      (response) => {
        const { script_url } = response
        // if (!title) setTitle(title)
        if (script_url) {
          Axios.get(script_url).then((response) => {
            setContent(response.data)
            setInitialContent(response.data)
          })
        } else {
          setLoading(false)
        }
      }
    )
  }, [])

  useEffect(() => {
    if (title && content) setLoading(false)
  }, [title, content])

  const updateState = (editor) => {
    setEditorState(editor)
  }

  const handleChapterSave = () => {
    const isValid =
      isContentChanged(editorState) &&
      wordCount >= getAudiobookWordCountLimit() &&
      editorToolbarChange
    if (!isValid) return

    const content = getContent(editorState)

    setLoading(true)
    const ContentDoc = document.implementation.createHTMLDocument('Content')
    ContentDoc.body.innerHTML = content
    const allHighlights = ContentDoc.getElementsByClassName('highlight')
    const len = allHighlights.length
    for (let i = 0; i < len; i++) {
      allHighlights[0].outerHTML = allHighlights[0].innerHTML
    }
    const modifiedContent = ContentDoc.body.innerHTML

    // create a text file
    const file = new Blob([modifiedContent], {
      type: 'text/plain;charset=utf-8',
    })

    let url = ''
    const params = {
      title,
      tags: 'file',
      file_dir: id,
      image_extension: 'txt',
    }
    const getPreSignedUrl = '/upload/get_presigned_url'
    if (isWordCountZero(wordCount)) return
    get(getPreSignedUrl, { params }).then((response) => {
      const imageUrl = response.result[0]
      const awsFields = imageUrl.fields
      const prefix = imageUrl.url
      const suffix = imageUrl.s3_unique_key
      const formData = new FormData()
      formData.append('key', awsFields.key)
      formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
      formData.append('x-amz-security-token', awsFields['x-amz-security-token'])
      formData.append('policy', awsFields.policy)
      formData.append('signature', awsFields.signature)
      formData.append('file', file)
      const cdn = 'https://d31b0xt3oaqqjh.cloudfront.net'
      post(prefix, { data: formData })
        .then(() => {
          url = `${cdn}/${id}/${suffix}`

          if (asset_id) {
            const dataForVersion = {
              asset_id,
              entity_assets_tasks_id: task_id,
              entity_id,
              word_count: wordCount,
              script_url: url,
              title,
            }
            return post('/content_api/assets_management/assets/', {
              data: dataForVersion,
            }).then(() => {
              history.push(redirectUrl)
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }
  const saveButtonEnabled = () => {
    if (
      isContentChanged(editorState) &&
      wordCount >= getAudiobookWordCountLimit() &&
      editorToolbarChange
    ) {
      return true
    }
    return false
  }

  const handleback = () => {
    history.push(redirectUrl)
  }

  const getTooltipText = () => {
    if(wordCount < getAudiobookWordCountLimit()){
      return `Please type atleast ${getAudiobookWordCountLimit()} words to continue`
    }
    else if(!isContentChanged(editorState)){
      return 'Please make some changes first'
    }
    return ''
  }

  return (
    <Provider
      value={() => (
        <EditorControls
          title={title}
          onBack={handleback}
          onTitleChange={setTitle}
          wordCount={wordCount}
          undoActive={!isUndoEmpty(editorState)}
          redoActive={!isRedoEmpty(editorState)}
          handleUndo={() => onUndo(editorState, setEditorState)}
          handleRedo={() => onRedo(editorState, setEditorState)}
          showRevisionsIcon={asset_id}
          actionButtons={{
            secondary: {
              label: 'Save',
              tooltip: getTooltipText(),
              icon: SaveIcon,
              show: true,
              enabled: saveButtonEnabled(),
              onClick: handleChapterSave,
            },
          }}
        />
      )}
    >
      <PrivatePage isProfileNameShown={false}>
        <>
          {isLoading && !apiError && <Loader />}
          {!isLoading && apiError && (
            <GenericError
              show={apiError}
              onClose={() => toggleApiError(false)}
            />
          )}
          {!isLoading && (
            <TextEditor
              content={content}
              initialContent={initialContent}
              setContent={setContent}
              updateState={updateState}
              editorState={editorState}
              updateWordCount={setWordCount}
              chapterId={asset_id}
              bookId={id}
              setEditorToolbarChange={setEditorToolbarChange}
            />
          )}
        </>
      </PrivatePage>
    </Provider>
  )
}

export default EpisodeScriptEditor
