import React, { useEffect } from 'react'
import styled from 'styled-components'
import GoldenBadge from './GoldenBadge'
import topBackground from './confetti_background.svg'
import { applicationUpdate } from '../constants'
import CalenderIcon from './CalenderIconWB'
import MilestoneIcon from './MilestoneIconWB'
import ArrowRight from '../../../components/Images/ArrowRight'
import { post } from '../../../lib/api'
import { getTokenAndUid } from '../../../lib/utils'
import WBPReviewImg from '../../../components/Images/WBPReview'

const Container = styled.div`
  padding: 48px 48px 24px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};

  @media (max-width: 600px) {
    padding: 24px 16px;
  }
`
const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: var(--Text500);
  margin-bottom: 56px;
`
const Desc = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 24px;
`
const TopSection = styled.div`
  background-image: url(${topBackground});
  padding: 40px 40px 85px;
  position: relative;
  background-color: var(--FjordDark500);
`
const Badge = styled.div`
  position: absolute;
  bottom: -90px;
  left: calc(50% - 70px);
`
const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: rgba(255, 255, 255, 0.91);
`
const BottomSection = styled.div`
  padding: 108px 48px 24px;
  background: var(--Dove);
`

const GoldenTag = styled.div`
  padding: 8px;
  background-image: linear-gradient(to left, rgba(242, 198, 2, 0), rgba(242, 198, 2, 0.25), rgba(242, 198, 2, 0));
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  color: var(--Corn800);
  margin-bottom: 24px;
`
const UpsellHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: var(--Text700);
  margin-bottom: 24px;
  text-align: center;
`
const UpsellDivs = styled.div`
  display: flex;
  justify-content: space-between;
`
const MonthlyUpsell = styled.div`
  border-radius: 4px;
  border: solid 2px rgba(6, 138, 226, 0.2);
  background-color: rgba(6, 138, 226, 0.1);
  padding: 24px 16px;
  display: flex;
  width: 47%;
`
const MilestoneUpsell = styled(MonthlyUpsell)``
export const IconHolder = styled.div`
  margin-right: 16px;
`

const UpsellDetails = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
`
const NoteForAuthor = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: solid 2px var(--Grey900);
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 56px;
`
export const ExtraNotesHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: var(--Text700);
  margin-top: 16px;
  margin-bottom: 16px;
`
export const ExtraNote = styled.div`
  display: flex;
  margin-bottom: 16px;
  svg {
    fill: var(--Text700);
    margin-bottom: -10px;
  }
`
export const NoteText = styled.span`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text700);
`
const ArtDiv = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    svg {
      width: 300px;
      height: 200px;
    }
  }
`
const HR = styled.div`
  background-color: var(--Grey900);
  height: 2px;
  margin-bottom: 24px;
`
const OkayDiv = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  border: solid 2px var(--LD10);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: var(--Text500);
  margin-left: auto;
  display: table;
  cursor: pointer;
`
export const OkayButtonDiv = styled.div`
  margin-top: 24px;
`
export const OkayButton = styled.div`
  padding: 14px 32px;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(255, 255, 255, 0.98);
  width: 200px;
  display: flex;
  justify-content: center;
  margin: auto;
`
const Card = ({id, action_type, toReplace1='', toReplace2, withReplace1='', withReplace2, handleReset, isApproved, isRejected, goldenTag}) => {
  useEffect(() => {
    const { uid } = getTokenAndUid()
      post('/content_api/book.user_application/', { data: {
        uid,
        id,
        is_viewed: 1
      } })
  }, [])
  return (
    <Container>
      {action_type === 'monthly_accepted' && (
        <Heading>
          {'Monthly Reward for {{month}} is under review'.replace(toReplace2, withReplace2)}
        </Heading>
      ) }
      <Desc>
      {applicationUpdate[action_type][0].replace(toReplace1, withReplace1).replace(toReplace2, withReplace2)}
      </Desc>
      {goldenTag && <GoldenTag>
        {goldenTag}
      </GoldenTag>}
      <NoteForAuthor>
        For the benefit of your readers, we urge you to keep adding new episodes while the process is underway
      </NoteForAuthor>
      <ArtDiv>
        <WBPReviewImg />
      </ArtDiv>
      <HR />
      <OkayDiv onClick={handleReset} >Okay</OkayDiv>
    </Container>
  )
}

export default Card
