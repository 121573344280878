import React from 'react'

const WordCount = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.2319 2.70841H3.98185V17.2917H15.2319V2.70841ZM2.94019 1.66675V18.3334H16.2735V1.66675H2.94019Z" fill="#0D1536" fillOpacity="0.65"/>
    <path d="M6.27368 5.83325H12.9403V6.87492H6.27368V5.83325Z" fill="#0D1536" fillOpacity="0.65"/>
    <path d="M6.27368 8.33325H12.9403V9.37492H6.27368V8.33325Z" fill="#0D1536" fillOpacity="0.65"/>
    <path d="M6.27368 10.8333H12.9403V11.8749H6.27368V10.8333Z" fill="#0D1536" fillOpacity="0.65"/>
    <path d="M6.27368 13.3333H9.60701V14.3749H6.27368V13.3333Z" fill="#0D1536" fillOpacity="0.65"/>
  </svg>
)

export default WordCount
