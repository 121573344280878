import React from 'react'
import styled from 'styled-components'


const InputHolder = styled.div`
  position: relative;
`
const SelectedColor = styled.div`
  position: absolute;
  left: 10px;
  bottom: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color || 'transparent'};
`
const Input = styled.input`
  width: 117px;
  border-radius: 4px;
  border: solid 1px #e0e2eb;
  background-color: #f0f1f5;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(13, 21, 54, 0.65);
  padding: 12px 12px 12px 24px;

  &:focus {
    outline: none;
    border: solid 2px var(--Azure500);
    padding: 11px 11px 11px 23px;
    background-color: var(--Dove);
  }
`
const InputLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(13, 21, 54, 0.65);
`
const HexInput = ({onChange, ...props}) => {
  const handleInputChange = e => {
    const { name, value } = e.target
    if (value.length > 7) return
    let newVal = value
    if (value !== '' && !value.includes('#')) {
      newVal = '#' + value
    }
    onChange({name, value: newVal})
  }
  return (
    <>
      <InputLabel>Hex Code</InputLabel>
      <InputHolder>
        <SelectedColor color={props.value} />
        <Input
          {...props}
          onChange={handleInputChange}
        />
      </InputHolder>
    </>
  )
}

export default HexInput
