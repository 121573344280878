import React from 'react'

const Comments = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="#fff" fillOpacity=".9" d="M6.426 17.476c-1.617-1.589-2.606-3.647-2.836-5.868-.86 1.087-1.326 2.42-1.324 3.818.002.91.207 1.814.595 2.632l-.8 2.328c-.138.4-.037.835.262 1.135.21.21.488.322.774.322.12 0 .241-.02.36-.06l2.328-.8c.818.388 1.723.593 2.632.594 1.427.002 2.784-.482 3.882-1.374-2.208-.2-4.267-1.15-5.873-2.727z"/>
    <path fill="#fff" fillOpacity=".9" d="M21.928 17.697l-1.162-3.38c.56-1.145.856-2.418.858-3.699.004-2.227-.858-4.333-2.426-5.93-1.569-1.596-3.659-2.495-5.885-2.53C11.004 2.12 8.834 3 7.203 4.63c-1.632 1.632-2.51 3.802-2.474 6.11.035 2.227.934 4.317 2.531 5.885 1.593 1.565 3.694 2.427 5.916 2.427h.013c1.281-.002 2.554-.298 3.699-.858l3.38 1.162c.141.048.286.072.428.072.34 0 .67-.134.92-.384.356-.356.475-.872.312-1.348zm-7.936-4.16H9.63c-.329 0-.595-.267-.595-.596 0-.329.266-.595.595-.595h4.362c.329 0 .595.266.595.595 0 .329-.266.595-.595.595zm2.73-2.449H9.63c-.329 0-.595-.266-.595-.595 0-.329.266-.595.595-.595h7.092c.329 0 .595.266.595.595 0 .329-.266.595-.595.595zm0-2.448H9.63c-.329 0-.595-.266-.595-.595 0-.329.266-.595.595-.595h7.092c.329 0 .595.266.595.595 0 .329-.266.595-.595.595z"/>
  </svg>
)

export default Comments
