import React from 'react'
import styled from 'styled-components'
import CheckedIcon from '../../Images/RadioSelected'
import CheckboxAddIcon from '../../Images/CheckboxAddIcon';

const Container = styled.div`
  padding: 16px 12px;
  border-radius: 4px;
  border: solid 1px var(--storm-op-16);
  background-color: rgba(13, 21, 54, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  
`
const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--Text300);
  svg {
    margin-left: 16px;
  }
`
const Option = styled.span`
  margin-left: 4px;
`

const CheckboxInput = ({ value, label, name, onChange }) => {
  return (
    <Container>
      <OptionsContainer>
          <React.Fragment key={label}>
           <Option >
              {label}
            </Option>
            {value === name && <CheckedIcon onClick={() => onChange('')}/>}
            {value !== name && <CheckboxAddIcon onClick={() => onChange(name)}/>}
            
          </React.Fragment>
      </OptionsContainer>
    </Container>
  )
}

export default CheckboxInput
