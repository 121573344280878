import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="240" height="208" fill="none" viewBox="0 0 240 208">
    <mask id="szx4ktufda" width="240" height="208" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M0 0H240V208H0z"/>
    </mask>
    <g mask="url(#szx4ktufda)">
        <path fill="#F0F1F5" d="M164.441 45.036C155.15 27.748 136.88 16 115.84 16c-25.597 0-47.128 17.422-53.382 41.097C42.938 64.423 29 83.274 29 105.341c0 10.409 3.082 20.102 8.398 28.187-.045.983-.09 1.966-.09 2.949 0 25.551 20.728 46.234 46.235 46.234 10.14 0 19.521-3.261 27.16-8.845 10.185 5.763 21.978 9.068 34.53 9.068 38.819 0 70.267-31.448 70.267-70.267.045-32.163-21.576-59.278-51.059-67.631z"/>
        <path fill="#D33327" d="M171.008 87.43c0 .09-.045.134-.09.179-.134.178-.402.223-.625.089-1.34-.849-4.825-1.876-11.972 2.189-.491.268-.938.536-1.429.804-9.069 4.824-15.769 5.092-20.638 3.127-3.887-1.608-7.594 3.037-9.068 5.27-.268.358-.804.224-.849-.222l-.983-10.766-1.876-20.504v-.09l-3.842-11.882c-.089-.268.09-.58.402-.625 1.608-.223 6.254-.625 8.711 1.34 2.993 2.368 5.271 8.488 6.209 11.57.938 3.082 6.299 10.587 9.336 12.91 3.038 2.322 7.103 4.69 12.642 3.841 5.584-.893 10.721-1.25 12.195-.402.804.447 1.385.804 1.698 1.474.089.626.134 1.207.179 1.698z"/>
        <path fill="#B22724" d="M156.847 90.69c-9.069 4.825-15.769 5.093-20.638 3.127-3.887-1.608-7.594 3.038-9.068 5.271-.268.358-.805.224-.849-.223l-.983-10.766c1.385-9.738 2.993-20.906 2.993-20.906s3.484 11.525 4.958 17.958c1.475 6.433 7.148 9.425 11.257 9.202 0 0-5.181-7.102-6.164-13.044-.938-5.94 1.921 10.498 11.168 10.096 2.948-.179 5.405-.447 7.326-.715z"/>
        <path fill="#B74821" d="M97.3 120.573s.135.894-2.724 2.189c-2.86 1.251-5.406 2.234-5.406 3.887 0 .893 7.371 1.027 9.694.625 2.323-.402 5.494-.313 6.477-.625 1.028-.313.045-6.031-2.055-7.505-2.144-1.474-5.986 1.429-5.986 1.429z"/>
        <path fill="#0D2B4D" d="M103.107 113.963c-.223-.447-.402-1.429-.58-2.457-.179-1.206-.313-2.457-.447-3.663-.536-5.316-.223-8.755-.759-10.81 5.807-2.77 10.229-2.502 16.036-6.656l-5.584-10.408s-16.17 6.254-18.985 8.89c-2.1 2.01-3.886 3.573-2.948 7.191.804 3.038 2.904 10.542 3.931 14.831 0 0 1.698 9.515 2.636 9.738.938.223 5.807-.402 6.879-1.072 1.117-.67.357-1.34.134-2.636-.134-1.34.179-1.876-.313-2.948z"/>
        <path fill="#D15828" d="M132.412 135.984s-.402 2.279-1.117 3.44c-.759 1.162-5.762 1.474-5.718 3.35 0 1.072 3.127 1.072 7.818.983 4.69-.089 5.048-2.725 7.102-3.886 2.055-1.162 3.127-2.457-1.34-6.656s-6.745 2.769-6.745 2.769z"/>
        <path fill="#1C3E60" d="M139.158 129.955c.044-1.295-.134-2.144-1.296-3.574-.447-.536-.715-1.25-.983-2.099-.044-.224-.134-.492-.178-.715-.224-.849-.536-1.787-.894-2.77-.223-.625-.491-1.295-.849-2.054-2.054-4.691-4.467-8.8-4.601-9.739-.178-1.34-.223-11.346-.178-14.07.044-2.726-.76-9.605-.76-9.605l-16.17-2.278s2.456 15.635 3.797 21.084c1.34 5.495 1.161 8.041 5.047 15.054 2.368 4.333 4.333 7.416 5.986 10.453 0 0 1.385 3.038 1.876 4.333.447 1.206.938 2.681 2.636 2.725h.402c2.01-.223 3.172-1.072 3.172-1.072s3.216-1.295 3.439-2.233c.179-1.028-.491-2.145-.446-3.44z"/>
        <path fill="#CCC8C9" d="M112.578 55.624c-.268-.045-4.511 2.278-5.316 2.188-.893-.134-2.144-.982-3.618-1.518-1.429-.536-5.182-3.976-6.298-5.048-1.117-1.028-1.564-1.876-2.949-.804-1.385 1.027-2.233 2.055-2.055 2.77.18.67 2.279 3.886 3.217 5.315.938 1.385 7.772 5.807 10.453 6.12 2.68.313 9.157-.134 9.157-.357 0-.268-2.591-8.666-2.591-8.666z"/>
        <path fill="#EDB73E" d="M129.777 85.554c-.179 1.206-2.502 2.546-5.718 2.77-1.206.089-2.591-.045-3.976-.358-3.618-.893-5.986-3.171-7.013-4.377-.402-.492-.849-.939-1.296-1.385-.759-.76-2.189-1.34-1.563-4.378.313-1.43.536-2.59.67-3.574.045-.312.089-.58.089-.848v-.224c.045-.536.045-1.027 0-1.519-.044-.67-.223-1.295-.446-2.01-.224-.714-.402-1.518-.536-2.322-.537-3.395-.313-7.64.178-9.828.626-2.725 9.336-3.395 12.821-1.161 0 0 2.769.67 4.02 4.6.357 1.117.67 2.413.938 3.842.089.536.223 1.117.313 1.698.491 3.171.849 6.745.893 10.095v.626c0 .58.045 1.117.09 1.653.268 3.752.759 5.27.536 6.7z"/>
        <path fill="#F2C56D" d="M124.818 73.493c0 .491-.402.849-.849.849-.491 0-.848-.403-.848-.85 0-.446.402-.848.848-.848.447-.045.849.357.849.849zM116.733 76.218c0 .491-.402.849-.849.849-.447 0-.849-.402-.849-.85 0-.49.402-.848.849-.848.447 0 .849.358.849.849zM118.252 84.259c0 .491-.402.849-.849.849-.447 0-.849-.402-.849-.85 0-.49.402-.848.849-.848.447 0 .849.358.849.849zM125.667 82.159c0 .491-.402.848-.849.848-.491 0-.849-.401-.849-.848 0-.492.402-.849.849-.849.491-.045.849.357.849.849zM129.285 78.81c-.357-.09-.625-.448-.625-.85 0-.357.223-.67.536-.804.044.581.044 1.117.089 1.653zM128.302 66.434c-.044 0-.134.045-.178.045-.492 0-.849-.402-.849-.849 0-.447.313-.804.759-.849.09.492.179 1.072.268 1.653zM111.819 72.509c0 .446-.357.804-.804.848v-.223c.045-.536.045-1.027 0-1.519.447.045.804.447.804.894zM112.668 60.493c0 .492-.402.849-.849.849-.447 0-.849-.402-.849-.849 0-.446.402-.849.849-.849.447 0 .849.358.849.85z"/>
        <path fill="#EAA43F" d="M124.059 88.279c-1.206.089-2.591-.045-3.976-.358-3.618-.893-5.986-3.171-7.013-4.378-.402-.49-.849-.938-1.296-1.34-.759-.759-2.188-1.34-1.563-4.377.849-4.02 1.117-5.718.357-8.175-.223-.715-.402-1.519-.536-2.323.581-.447 1.296-.715 2.01-.625 2.502.268 10.677 1.161 10.677 1.161s-.536 2.1-5.629 3.842c-5.092 1.742-4.735 5.494-2.144 7.683s4.378 2.77 5.763 6.254c.714 1.564 2.099 2.323 3.35 2.636z" opacity=".39"/>
        <path fill="#D39735" d="M81.666 61.297l-1.652-1.742 3.573-3.44 1.608 1.742-3.528 3.44z"/>
        <path fill="#EDB73E" d="M83.944 58.795l-1.072-1.117c-2.323-2.457-2.77-6.12-1.116-9.068.357-.67.848-1.25 1.384-1.787l8.934-8.532 11.615 12.195-8.934 8.532c-.581.536-1.206.983-1.921 1.34h-.045c-2.948 1.52-6.522.85-8.845-1.563zM81.89 61.386l-1.966-2.054c-.179-.224-.536-.224-.76 0l-.58.536 2.68 2.814.58-.536c.224-.224.269-.536.046-.76z"/>
        <path fill="#474F54" d="M78.495 59.733l-4.288-1.698 8.71 9.158-1.608-4.512-2.814-2.948z"/>
        <path fill="#2F373D" d="M82.917 67.193l-4.154 3.975-2.68-2.77-6.076-6.387.983-.938 2.323-2.234.893-.804 6.03 6.343 2.681 2.815z"/>
        <path fill="#1E2326" d="M82.917 67.193l-4.154 3.976-2.68-2.77 4.154-4.02 2.68 2.814z"/>
        <path fill="#EDB73E" d="M73.324 58.868l-2.327 2.22 3.855 4.04 2.327-2.22-3.855-4.04z"/>
        <path fill="#D39735" d="M103.734 50.53c-.358.313-3.217-2.144-6.433-5.494-3.216-3.35-5.54-6.343-5.182-6.7.358-.313 3.217 2.144 6.433 5.494 3.216 3.35 5.539 6.343 5.182 6.7zM87.92 57.365c-.804 0-1.295-.313-1.608-.626-.268-.268-.268-.67 0-.938.268-.268.67-.268.938 0 .357.358 1.117.268 2.1-.134 2.858-1.206 6.03-4.6 6.03-5.986 0-.536-.134-.759-.179-.759-.134-.045-.446.045-.67.179-.312.178-.715.09-.938-.224-.178-.312-.09-.714.224-.893.134-.09 1.161-.715 2.01-.223.58.312.848.938.893 1.92.045 2.234-3.842 5.986-6.835 7.237-.804.313-1.429.447-1.965.447z"/>
        <path fill="#F28A5C" d="M97.256 57.097l-1.608-1.608s-.894-2.01-1.25-2.814c-.403-.76-.939-.179-.939-.179s-.804-1.608-1.787-.134c0 0-.983-.223-1.027 1.564 0 0-.447 0-.536 1.161-.134 1.162 2.323 2.948 3.26 3.306l1.698 1.831 2.19-3.127z"/>
        <path fill="#F0F1F5" d="M122.718 60.716c-1.563-.536-6.119 1.564-8.13 1.877-2.01.312-3.707 0-4.824.312-1.117.313-1.072-1.117-2.68-.58-1.608.491-3.083-1.207-6.21-3.038-3.171-1.831-2.992-2.77-4.243-2.725-1.251.045-2.68 3.172-2.86 4.11-.178.938 6.925 6.611 9.471 8.04 2.546 1.385 4.556 2.055 6.567 1.743 1.965-.268 7.504-.447 10.274-1.028 2.769-.58 3.663-2.68 3.842-5.45.178-2.77-1.207-3.26-1.207-3.26z"/>
        <path fill="#E84A46" d="M171.008 87.43c0 .088-.045.133-.09.178-.357-1.43-2.457-3.842-13.624-1.564-15.054 3.083-22.827-17.153-24.659-22.29-1.876-5.137-4.288-7.55-7.772-5.852-2.457 1.206-1.742-.447-1.742-.447 1.876-1.519 3.797-1.831 5.583-1.117 2.502 1.028 4.825 4.2 6.522 9.202 2.949 8.756 9.113 14.697 9.113 14.697 8.041 7.416 16.752 1.028 23.363 2.77 1.831.491 2.68 1.608 3.038 2.725.178.625.223 1.206.268 1.697z"/>
        <path fill="#D33327" d="M123.076 57.455s.134-1.475-.536-2.636l-.134-.58s4.601.803 6.12 2.054c0 0-2.77-.983-5.45 1.162zM114.722 55.4s-1.206 1.117-1.563.894c-.402-.224.223-1.162.804-1.743.581-.58 1.608-.938 1.608-.938s.357.536 1.027.894c-.044-.045-1.027.134-1.876.893z"/>
        <path fill="#F28A5C" d="M123.433 50.264c-.045.089-.223.625-.491 1.563-.134.447-.268.983-.358 1.564-.134.714-.312 1.563-.402 2.501-1.563 1.787-3.841 2.68-6.745 2.234-.045-.67.134-1.34.491-2.01.224-.447.536-.85.894-1.207l.223-1.965 6.388-2.68z"/>
        <path fill="#DD5C43" d="M123.433 50.264c-.045.089-.223.625-.491 1.563-.134.447-.09.268-.224.894-.402 2.1-3.216 4.69-6.834 3.44.223-.447.536-.85.893-1.207l.224-1.965 6.432-2.725z"/>
        <path fill="#F0F1F5" d="M115.392 58.123l1.653.403s1.385.714 1.34 1.92c0 0 4.556-2.501 4.646-3.484.134-.983-.67-2.59-.67-2.59l-.268 1.563c0 .044-3.708 1.831-6.701 2.188z"/>
        <path fill="#F0F1F5" d="M116.822 54.418s-1.117 0-2.368 1.206c-1.206 1.206-1.474 3.261-1.474 3.261l1.608-1.072.804.313c.268-1.117.715-2.19 1.385-3.217l.045-.491z"/>
        <path fill="#841A1A" d="M126.694 47.583s0 4.02-4.11 5.629c-.134.044-.223.09-.357.134-.402.134-.759.178-1.117.134-.938-.045-1.697-.536-2.367-1.296-2.011-2.278-2.77-6.745-2.77-6.745s-1.831-1.072-.625-2.948c0 0-.179-2.055 1.116-2.815 1.296-.804 2.145-.714 2.904-1.295.759-.58 2.144-.313 3.663 1.92 1.519 2.234 3.529 1.162 4.646 3.62 1.072 2.411-.983 3.662-.983 3.662z"/>
        <path fill="#9E4026" d="M122.54 53.256c.089-.536.223-.983.313-1.385.268-.938.446-1.519.491-1.563l-3.038 1.295c-.134-.312-.223-.58-.223-.58 0-.045 1.876-5.36 1.876-5.36s1.921 1.875 4.691 2.01c.044-.09.134 4.02-4.11 5.583z"/>
        <path fill="#F28A5C" d="M116.062 44.276s-2.323 7.594-.179 9.694c1.564 1.518 4.289.893 5.406.223 1.116-.625 2.144-2.591 2.144-2.591s1.519.447 2.01-1.43c.313-1.16-1.028-1.786-1.787-.401l-.804-.09s-.179-.849.268-1.563c.759-1.206-.938-1.162-1.027-2.1-.045-.938-.581-1.161-2.457-1.161-2.234 0-3.44-1.162-3.44-1.162l-.134.581z"/>
        <path fill="#841A1A" d="M116.911 47.047c-.089.268-.312.447-.491.358-.179-.045-.223-.313-.134-.581.089-.268.313-.447.491-.357.179.044.224.312.134.58zM119.636 47.94c-.089.268-.313.447-.491.357-.179-.044-.224-.312-.135-.58.09-.268.313-.447.492-.358.179.045.223.313.134.58z"/>
        <path stroke="#DD5C43" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".5" d="M116.911 48.61s-.268.535-.804.982c-.446.358-.625.447.268.983"/>
        <path stroke="#841A1A" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".5" d="M119.413 46.734s1.251-.268 1.519 1.027M117.09 46.02s-.67-.849-1.519-.134"/>
        <path fill="#fff" d="M116.599 52.183s1.652-.178 2.099-.76c0 0 .224 1.118-.447 1.34-.67.269-1.652.135-1.652-.58z"/>
        <path fill="#F28A5C" d="M123.433 50.264c-.045.089-.223.625-.491 1.608-.09.402-.224.893-.358 1.385-.134.044-.223.089-.357.134-.402.134-.759.178-1.117.134-.938-.045-1.697-.536-2.367-1.296l4.69-1.965z"/>
        <path fill="#E3E4E5" d="M119.369 192.808c43.963 0 79.603-1.42 79.603-3.172 0-1.751-35.64-3.171-79.603-3.171-43.964 0-79.603 1.42-79.603 3.171 0 1.752 35.64 3.172 79.603 3.172z"/>
        <path fill="#D33327" d="M108.514 126.648H74.385v62.807h34.129v-62.807zM136.567 143.178h-28.053v46.234h28.053v-46.234zM157.83 161.672h-21.263v27.74h21.263v-27.74z"/>
        <path fill="#B22724" d="M119.101 126.648h-10.587v16.529h10.587v-16.529zM146.618 143.178h-10.051v18.493h10.051v-18.493zM166.809 161.672h-8.934v27.74h8.934v-27.74z"/>
        <path fill="#D8872E" d="M155.463 189.414s-2.099-14.027 1.161-15.054c3.217-.983 2.904 9.783.849 15.054h-2.01z"/>
        <path fill="#EDB73E" d="M156.222 189.414h-2.233s-3.663-6.254-3.172-9.961c.492-3.753 5.629 1.027 5.405 9.961zM159.082 189.414h-2.859s2.01-9.247 5.762-8.577c3.708.715-2.903 8.577-2.903 8.577z"/>
        <path fill="#D8872E" d="M152.872 180.747c.045.045 1.965 4.244 2.591 8.666h-.402c-.581-4.333-2.502-8.487-2.546-8.532-.045-.089 0-.223.089-.268.089 0 .223 0 .268.134zM161.628 182.399c-1.564 1.609-2.949 4.736-3.753 7.014h-.446c.848-2.323 2.278-5.629 3.931-7.282.089-.089.223-.089.268 0 .089.045.089.179 0 .268zM74.341 189.411s2.814-18.851-1.519-20.191c-4.333-1.34-3.886 13.089-1.161 20.191h2.68z"/>
        <path fill="#EDB73E" d="M73.313 189.412h2.993s4.914-8.398 4.289-13.401c-.67-4.959-7.55 1.385-7.282 13.401zM69.427 189.412h3.886s-2.725-12.419-7.728-11.481c-5.003.939 3.842 11.481 3.842 11.481z"/>
        <path fill="#D8872E" d="M77.78 177.752c-.045.09-2.636 5.718-3.44 11.659h.536c.804-5.807 3.35-11.346 3.395-11.435.045-.134 0-.313-.134-.358-.134-.044-.268 0-.357.134zM66.032 179.987c2.1 2.144 3.93 6.343 5.047 9.425h.581c-1.117-3.127-3.037-7.504-5.271-9.782-.09-.09-.268-.09-.357 0-.134.089-.134.268 0 .357z"/>
    </g>
  </svg>
)

export default Icon
