import React from 'react'
import styled, { css } from 'styled-components'
import Microphone from './images/Microphone'
import CardBoard from './images/CardBoard'
import { Link } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  div {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`
const Heading = styled.h3`
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.2;
  width: fit-content;
  margin-bottom: 1.6rem;
  -webkit-background-clip: text;
  background-image: linear-gradient(145deg, #b0d5ff, #0068e1);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SubHeading = styled.h5`
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.3;
  color: var(--Text700);
  margin: 1.5rem auto;
`
const Note = styled.p`
  font-size: 1rem;
  line-height: 1.2;
  color: #000;
`
const btn = css`
  border-radius: 4px;
  font-family: Inter;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  width: fit-content;
  color: var(--bg-alt);
  box-shadow: 0 8px 16px 0 rgba(73, 83, 89, 0.16);
  border: none;
  background-image: linear-gradient(145deg, #b0d5ff, #0068e1);
  cursor: pointer;
`
const Info = styled.div`
  width: 72%;
  @media(max-width: 600px){
    width: 100%;
  }
`
const Button = styled.button`
  ${btn}
  padding: .8rem 3.1rem;
  align-self: flex-end;
  margin: 2rem;
  text-transform: capitalize;
`

const ImgContainer = styled.div`
  width: 28%;
  svg {
    width: 90%;
  }
  @media(max-width: 600px){
    display:none;
  }
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export default function ReviewUI(props) {
  const reviewStatus = props.reviewStatus

  const handleClickButton = () =>
    (window.location.href = `https://writer.pocketnovel.com/`);

  return (
    <Container>
      <ImgContainer>
        {reviewStatus == 'approved' && <Microphone />}
        {reviewStatus == 'rejected' && <CardBoard />}
      </ImgContainer>
      <Info>
        <Heading>Review</Heading>
        {reviewStatus == 'approved' && (
          <>
            {' '}
            <SubHeading>
              Congratulations! <br />
              You are a perfect fit for us.
            </SubHeading>
            <Note>
              We looked into your application &amp; decided to work with you on
              one of our upcoming projects. Our team will reach out to you once
              your profile matches our requirements. Till then you can submit
              new concepts for Audio Shows.
            </Note>
            <BtnContainer>
              <Link to={`/shows/audiobooks?tab=concepts`}>
                <Button> {'submit new concepts'}</Button>
              </Link>
            </BtnContainer>
          </>
        )}
        {reviewStatus == 'rejected' && (
          <>
            {' '}
            <SubHeading>
              Welcome back!
              <br />
              We think you have a good shot at Pocket Novels!
            </SubHeading>
            <Note>
              We appreciate you taking time off to apply to be a ProAuthor for
              Pocket FM.
              <br />
              <br />
              We believe the requirements that we have are different from the
              ones that you currently offer expertise in, which is why we will
              not be able to proceed with your application for now.
              <br />
              <br />
              Meanwhile we encourage you to write on our Novels platform in
              order to improve your chances of getting a call back from us.
            </Note>
            <BtnContainer>
              <Button onClick={handleClickButton}>
                {' '}
                {'Go to Pocket Novels'}
              </Button>
            </BtnContainer>
          </>
        )}
      </Info>
    </Container>
  )
}
