import React from 'react'
import styled from 'styled-components'
import CardGraphic from './WBGirl'
import { novelNotices, rewardNotices } from '../constants'
import { Subtext } from './bookCard'
import { ThumbnailDiv, DetailsDiv, CtaBox, BookTitle } from './monthlyBookCard'
import { Image, Text } from '../../../designSystem'
const Card = styled.div`
  margin-bottom: 8px;
  background-color: var(--Dove);
  display: flex;
  padding: 24px;
`
const Details = styled.div`
  padding: 24px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--Text700);
  margin-bottom: 16px;
`
const Description = styled.div`
  font-size: 16px;
  line-height: 1.75;
  text-align: left;
  color: var(--Text500);
  margin-bottom: 24px;
`
const ActionButton = styled.div`
  border-radius: 4px;
  border: solid 2px var(--LD10);
  cursor: pointer;
  padding: 14px;
  width: 180px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
`
const ArtContainer = styled.div``

const NotificatonCard = ({ handleClick = () => null, program, action_type, no_button, button_text='Okay', toReplace='{{novel_title}}', withReplace }) => {
  const entityForConstants = program.action_on === 'program' ? novelNotices : rewardNotices
  return (
    <Card>
      {/* <Details>
        <Title>{entityForConstants[action_type].title.replace(toReplace, withReplace)}</Title>
        <Description>{entityForConstants[action_type].message.replace(toReplace, withReplace)}</Description>
        {!no_button && <ActionButton onClick={handleClick}>{button_text}</ActionButton>}
      </Details> */}
      {/* <ArtContainer>
        <CardGraphic />
      </ArtContainer> */}
        {/* <ThumbnailDiv>
          {program.book_details.image_url && <img src={program.book_details.image_url} alt="novel thumbnail" />}
        </ThumbnailDiv> */}
        <Image width={[40, 112]} src={program.book_details.image_url} height={[60, 168]} mr={[8, 24]} />
        
        <DetailsDiv>
          <Text variant="titleMedium" mb={8}>{entityForConstants[action_type].title.replace(toReplace, withReplace)}</Text>
          <Text variant="bodyLarge" mb={8}>
            {program.book_details.book_title}
          </Text>
          <Text mb={24}>{entityForConstants[action_type].message.replace(toReplace, withReplace)}</Text>
          <CtaBox>
            <ActionButton onClick={handleClick}>
              {button_text}
            </ActionButton>
          </CtaBox>
        </DetailsDiv>
    </Card>
  )
}
export default NotificatonCard
