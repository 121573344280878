import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import InfoIcon from '../Images/InfoIcon'
import Tooltip from '../Tooltip'

const StyledInfoIcon = styled(InfoIcon)`
  margin-bottom: -2px;
  cursor: pointer;
`
const Info = ({ title }) => {
  const [isInfoOpen, setInfoOpen] = useState(false)
  const tooltipRef = useRef()
  
  const handleClick = (e) => {
    if (!tooltipRef.current.contains(e.target)) {
      setInfoOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  return (
    <Tooltip  open={isInfoOpen} title={title || ''} placement='top-start' arrow>
      <StyledInfoIcon ref={tooltipRef} onClick={() => setInfoOpen(!isInfoOpen)} />
    </Tooltip>
  )
}

export default Info
