import React, { useState } from 'react'

const CommentContext = React.createContext({
  allComments: {},
  onUpdateComments: () => {},
})

const CommentProvider = ({ children }) => {
  const [allComments, setAllComments] = useState({})

  const handleUpdateComment = data => {
    setAllComments(data)
  }

  return (
    <CommentContext.Provider
      value={{
        allComments,
        onUpdateComments: handleUpdateComment,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

export { CommentContext }
export default CommentProvider
