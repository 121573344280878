import React from 'react'

const RatingIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"  {...props}>
    <path fill="#F2C602" d="M17.482 8.183c-.061-.176-.199-.304-.367-.336l-4.623-.704-2.082-4.37c-.076-.16-.245-.273-.413-.273-.169 0-.337.112-.414.272L7.517 7.16l-4.623.704c-.168.032-.321.16-.367.336-.062.177-.016.369.122.497l3.352 3.41-.796 4.819c-.03.176.046.368.184.48.138.112.337.128.49.032l4.133-2.273 4.133 2.273c.061.032.138.048.215.048.26 0 .459-.224.459-.48 0-.048 0-.08-.016-.112l-.78-4.787 3.352-3.41c.107-.144.153-.336.107-.513z"/>
</svg>

)

export default RatingIcon
