import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Input, Upload, UserSearch } from '../../../../../components/FormikInputs'
import * as Yup from 'yup'
import FileIcon from '../../../../../components/Images/File'
import { post, get } from '../../../../../lib/api'
import Loader from '../../../../../components/Loader'
import { Popup } from '../../../../../components/Popup'
import Dustbin from '../../../../../components/Images/Dustbin'
import CrossIcon from '../../../../../components/Images/CrossIcon'
import { getNameFromUrl } from '../../../../../lib/utils'
import { getTokenAndUid, convertUrlToHttps } from '../../../../../lib/utils'
import { isAdminAccess } from '../../../../../config'
import GenericError from '../../../../../components/GenericError'
import { Box, Text, Button } from '../../../../../designSystem'

const StyledCrossIcon = styled(CrossIcon)`
  fill: rgba(13, 21, 54, 0.65);
`

const Details = styled.div`
  padding: 28px 24px 24px 24px;
`
const Actions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  background-color: var(--Grey100);
`
const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Punch500);
`
const SaveButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  opacity: ${({ isValid }) => (isValid ? '1' : '0.3')};
  border-radius: 4px;
  background-color: var(--Azure500);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  padding: 16px;
  min-width: 128px;
  margin-left: 8px
`
const CancelButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin-left: auto;
  width: 128px;
  display: inline-block;
  &:hover {
    background-color: rgba(13, 21, 54, 0.1);
  }
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--Text500);
  margin-bottom: 15px;
`
const HR = styled.div`
  height: 1px;
  background-color: var(--Grey900);
`
const FileContainer = styled.div`
  padding: 14px;
  border-radius: 4px;
  border: ${({ hasError }) => hasError ? 'solid 1px var(--Punch500)' : 'dashed 1px var(--Azure500)'};
  background-color: var(--Grey300);
`
const FilePlaceHolder = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Azure500);
`
const InputContainer = styled.div`
  width: 49%;
`
const UploadContainer = styled.div`
  width: 49%;
`
const HoverActions = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(
    to left,
    var(--Dove),
    var(--Dove),
    rgba(255, 255, 255, 0)
  );
  padding: 10px 8px;
  @media (max-width:600px){
    display: flex;
    position: unset;
    justify-content: flex-start;
  }
`
const FileNameDiv = styled.div`
  border-radius: 2px;
  border: solid 1px var(--Grey900);
  padding: 11px 12px 9px 12px;
  position: relative;
  &:hover ${HoverActions} {
    display: flex;
  }
`
const TopDiv = styled.div`
  display: flex;
`
const BottomDiv = styled.div``

const FileIconDiv = styled.div`
  margin-right: 8px;
`

const FileDetailsDiv = styled.div``

const FileName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const FileMeta = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--Text300);
`

const DownloadButton = styled.a`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--Azure500);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Dove);
  margin-right: 8px;
`
const UploadButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--Text500);
  background: none;
`
const ProgressDiv = styled.div`
  padding: 14px 16px;
  background-color: var(--Grey100);
  border-radius: 4px;
  display: flex;
  align-items: center;
`
const ProgressPercent = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--Text500);
`
const LinearProgress = styled.div`
  width: 352px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--Grey900);
  overflow: hidden;
  margin-right: 16px;
`
const ProgressBar = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: var(--Azure500);
`
const DeleteHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const DeleteBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--Text500);
`

const FileUploadComponent = ({
  isLoading,
  progress,
  imageUrl,
  placeholder,
  handleReUpload = () => null,
  title,
  hasError
}) => {
  return (
    <>
      {!imageUrl && !isLoading && (
        <FileContainer hasError={hasError}>
          {placeholder && <FilePlaceHolder>{placeholder}</FilePlaceHolder>}
        </FileContainer>
      )}
      {isLoading && (
        <ProgressDiv>
          <LinearProgress>
            <ProgressBar value={progress >= 100 ? 100 : progress} />
          </LinearProgress>
          <ProgressPercent>
            {(progress >= 100 ? '100' : progress.toFixed(0)) + '% Uploaded'}
          </ProgressPercent>
        </ProgressDiv>
      )}
      {!isLoading && imageUrl && (
        <FileNameDiv>
          <TopDiv>
            <FileIconDiv>
              <FileIcon />
            </FileIconDiv>
            <FileDetailsDiv>
              <FileName>{getNameFromUrl(imageUrl, title)}</FileName>
              {/* <FileMeta>700 KB</FileMeta> */}
            </FileDetailsDiv>
          </TopDiv>
          <BottomDiv>
            <HoverActions>
              <DownloadButton href={convertUrlToHttps(imageUrl, true)} download type="button">
                Download
              </DownloadButton>
              <UploadButton type="button" onClick={handleReUpload}>
                Upload
              </UploadButton>
            </HoverActions>
          </BottomDiv>
        </FileNameDiv>
      )}
    </>
  )
}
const AddEditEpisode = ({
  mode,
  book_id,
  successCallback = () => null,
  cancelCallback = () => null,
  data = {},
  book_title,
  without_meta
}) => {
  const [isDeletePopupShown, setDeletePopupShow] = useState(false)
  const [apiError, toggleApiError] = useState(false)

  const handleDelete = () => {
    let deleteApi = '/content_api/book.delete_audition'
    get(deleteApi, { params: {audition_id: data.audition_id} }).then(res => {
      setDeletePopupShow(false)
      successCallback()
    })
  }
  return (
    <Popup show={true} className="right-drawer-episode-form" close={cancelCallback}>
      <Box >
          <Box variant="flex" p={20}>
            <StyledCrossIcon onClick={cancelCallback} />
          </Box>
        <GenericError
          show={apiError}
          onClose={() => toggleApiError(false)}
        />
        <Formik
          initialValues={{
            // description: data['description'] || '',
            // file_url: 'https://djhonz7dexnot.cloudfront.net/name-of-the-file-goes-here.docx'
            file_url: data['file_url'] || '',
            created_by: ''
          }}
          validationSchema={Yup.object({
            // description: Yup.string().required('Required'),
            file_url: Yup.string().required('Required'),
            created_by: isAdminAccess && (mode === 'add') ? Yup.string().required('Required') : Yup.string()
          })}
          isInitialValid={mode === 'edit'}
          onSubmit={(values, { setSubmitting }) => {
            // TODO: change this later when edit put is implemented
            let { uid } = getTokenAndUid()
            let formData = { ...values, book_id}
            if (mode === 'add') {
              if (!isAdminAccess) {
                formData['created_by'] = uid
              }
            }
            if (mode === 'edit') {
              let { audition_id, created_by } = data
              formData['audition_id'] = audition_id
              formData['created_by'] = created_by
            }
            if (without_meta) {
              formData['meta_required'] = false
            }
            let createBookApi = '/content_api/book.create_audition'
            post(createBookApi, { data: formData })
              .then((response) => {
                setSubmitting(false)
                successCallback(values)
              })
              .catch((error) => {
                setSubmitting(false)
                throw error
              })
          }}
        >
          {({ values = {}, isValid, isSubmitting, submitCount, errors }) => (
            <Form>
              <Popup
                show={isDeletePopupShown}
                close={() => setDeletePopupShow(false)}
                renderActions={
                  <>
                    <button
                      className="action-button"
                      type="button"
                      onClick={() => setDeletePopupShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="action-button danger"
                      type="button"
                      onClick={handleDelete}
                    >
                      {`${isAdminAccess ? 'Delete' : 'Withdraw'} Entry`}
                    </button>
                  </>
                }
              >
                <DeleteHeading>
                  <Dustbin />
                  <Text variant="heading">{`${isAdminAccess ? 'Delete' : 'Withdraw'}  Your Entry?`}</Text>
                </DeleteHeading>
                <DeleteBody>
                  Are you sure you want to withdraw your entry, this will delete your submitted voice over file as well.
                </DeleteBody>
              </Popup>
              <Box pt={28} pb={24} pl={24} pr={24}>
                <Text variant="title" >{mode === 'edit' ? 'Update Your Entry' : 'Submit Your Entry'}</Text>
                <Box variant="hr" mt={5} />
                <Box variant="flex" flexDirection="column">
                  {/* <InputContainer>
                    <Input
                      label="Title"
                      name="description"
                      type="text"
                      infoTitle="Add title for your entry"
                      placeholder="Write something that describes this episode"
                    />
                  </InputContainer> */}
                  <Box>
                    <Upload
                      label="Voice Over"
                      name="file_url"
                      maxFileSize={52428800}
                      hasError={submitCount > 0 && errors['file_url']}
                      hideInputOnValue={true}
                      showProgress={true}
                      placeholder="Drop your audio file here to upload or click to select"
                      renderComp={FileUploadComponent}
                      // fileTypeTags="file"
                      fileTypeTags="media"
                      accept="audio/mpeg, audio/mp3, audio/ogg, audio/wav"
                      extraParams={{
                        // file_dir: book_id || data.book_id,
                        title: book_title || 'audition-entry',
                      }}
                      // cloud_dir_file={book_id || data.book_id}
                    />
                  </Box>
                </Box>
                
              </Box>
              <Box variant="flex" pt={16} pb={16} pr={24} pl={24}>
                {mode === 'edit' && <DeleteButton onClick={() => setDeletePopupShow(true)} type="button">{`${isAdminAccess ? 'Delete' : 'Withdraw'}`}</DeleteButton>}
                <CancelButton onClick={() => cancelCallback()}>
                  Cancel
                </CancelButton>
                {!isSubmitting && <SaveButton type="submit" isValid={isValid}>
                    {mode === 'edit' ? 'Update' : 'Submit Entry'}
                </SaveButton>}
                {isSubmitting && <SaveButton type="button" isValid={isValid}>
                    <Loader invertColor inline size="sm" />
                </SaveButton>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Popup>
  )
}

export default AddEditEpisode
