import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import ChevronRight from '../../../components/Images/ChevronRight'
import Machine from '../images/machine.png'

const SectionContainer = styled.section`
  width: 100%;
  background-color: #030303;
  padding-bottom: 3rem;
`
const Wrapper = styled.div`
  width: 75%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
const LeftFooter = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`
const FooterHeading = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
  color: var(--bg-alt);
  margin: 2rem auto;
`

const chevronArrowAnimation = keyframes`
  0%{
    transform: translateX(0px)
  }
  100%{
    transform: translateX(5px)
  }
`

const proButton = css`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  height: 64px;
  width: 100%;
  max-width: 360px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  color: var(--bg-alt);
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
  -webkit-tap-highlight-color: transparent;
  margin: 0px auto;
  svg {
    margin-left: 16px;
    animation: ${chevronArrowAnimation} 0.25s linear infinite alternate;
  }
`
const BecomeAProWriterBtn = styled.button`
  ${proButton}
  z-index: 1;
  margin: 0px;
  transition: all 0.2s;
  background: linear-gradient(215deg, #0068e1, #b0d5ff);
`
const FooterParaText = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 2rem;
  color: var(--TextDark500);
`
const RightFooter = styled.div`
  img {
    max-width: 320px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`
function Index({ createNewNovelHandler }) {
  return (
    <>
      <SectionContainer>
        <Wrapper>
          <LeftFooter>
            <FooterHeading>
              Write at Home &amp; earn Stable Income
            </FooterHeading>

            <FooterParaText>
              Become a Pocket FM audio writer and turn your passion into your
              dream. Earn upto Rs. 30 lacs in a year
            </FooterParaText>
            <BecomeAProWriterBtn onClick={createNewNovelHandler}>
              <span>Become a ProAuthor</span>
              <ChevronRight fillOpacity="1" />
            </BecomeAProWriterBtn>
          </LeftFooter>
          <RightFooter>
            <img src={Machine} alt={'Pocket FM pro writer story'} />
          </RightFooter>
        </Wrapper>
      </SectionContainer>
    </>
  )
}

export default Index
