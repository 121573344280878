import React from 'react'

const Logo = (props) => (
  <svg width="162" height="40" viewBox="0 0 162 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M56.9486 7.08597C51.9965 7.08597 47.5144 9.8009 47.5144 14.6154C47.5144 19.6109 50.8399 21.3846 56.5148 22.7964C59.5873 23.5566 60.5271 24.6787 60.5271 25.9819C60.5271 27.3575 59.1535 28.2986 57.3462 28.2986C55.3943 28.2986 53.4785 27.2851 53.0448 24.9321H46.5385C46.9361 30.543 51.2013 33.0769 57.5269 33.0769C62.5513 33.0769 67.3226 30.543 67.3226 25.5475C67.3226 21.7104 65.0815 18.9593 59.5873 17.6561C55.4666 16.6787 54.3099 15.7014 54.3099 14.1086C54.3099 12.5882 55.322 11.8643 56.804 11.8643C58.9366 11.8643 60.021 13.2398 60.3102 15.3032H66.8165C66.2743 10.6697 63.5272 7.08597 56.9486 7.08597Z" fill="#E51A4D"/>
  <path d="M77.6957 17.4389H81.961V13.3846H77.6957V8.38914H71.1894V13.3846H68.117V17.4389H71.1894V25.5113C71.1894 26.5973 71.1894 27.5023 71.2617 28.6244C71.4786 31.5928 72.9244 33.0769 77.7319 33.0769C79.1054 33.0769 80.2983 32.9683 81.5634 32.7511V28.6968C78.4909 28.8416 77.8765 28.4072 77.7319 26.4525C77.6957 25.9457 77.6957 25.2217 77.6957 24.5701V17.4389Z" fill="#E51A4D"/>
  <path d="M103.591 13.2398H97.0843V24.1719C97.0843 26.7421 95.8553 28.6606 93.795 28.6606C91.7346 28.6606 90.8671 27.5747 90.6502 25.7285C90.5418 24.7511 90.5056 23.7014 90.5056 23.086V13.2398H83.9993V24.5339C83.9993 26.0905 84.0354 27.2127 84.18 28.19C84.6138 30.8326 87.0356 33.0769 91.0117 33.0769C92.7829 33.0769 94.1564 32.6787 95.3492 31.9548L97.0843 29.3122V32.5701H103.591V13.2398Z" fill="#E51A4D"/>
  <path d="M127.76 5.9638H121.253V15.7376L119.988 13.6742C119.012 13.095 117.133 12.733 115.542 12.733C110.59 12.733 106.397 16.4615 106.397 23.3394C106.397 29.0588 109.65 33.0769 114.892 33.0769C116.844 33.0769 118.723 32.6787 119.808 31.8824L121.253 29.7466V32.5701H127.76V5.9638ZM117.133 28.6606C114.856 28.6606 113.048 26.6335 113.048 23.0136C113.048 19.0679 114.783 17.1493 117.313 17.1493C119.265 17.1493 121.253 18.4525 121.253 22.3258V23.1584C121.253 27.2127 119.518 28.6606 117.133 28.6606Z" fill="#E51A4D"/>
  <path d="M134.915 5.38461C132.891 5.38461 131.553 6.61538 131.553 8.57013C131.553 10.5249 132.891 11.7557 134.915 11.7557C136.939 11.7557 138.276 10.5249 138.276 8.57013C138.276 6.61538 136.939 5.38461 134.915 5.38461ZM138.168 32.5701V13.2398H131.662V32.5701H138.168Z" fill="#E51A4D"/>
  <path d="M151.364 12.733C145.039 12.733 140.99 16.6787 140.99 22.905C140.99 29.095 145.039 33.0769 151.364 33.0769C157.69 33.0769 161.738 29.095 161.738 22.905C161.738 16.6787 157.69 12.733 151.364 12.733ZM151.364 28.6606C149.051 28.6606 147.641 26.7421 147.641 22.905C147.641 19.0317 149.051 17.1493 151.364 17.1493C153.678 17.1493 155.088 19.0317 155.088 22.905C155.088 26.7421 153.678 28.6606 151.364 28.6606Z" fill="#E51A4D"/>
  <path d="M0 5.87156C0 2.62879 2.62811 0 5.87005 0H34.1197C37.3616 0 39.9897 2.62879 39.9897 5.87156V34.1284C39.9897 37.3712 37.3616 40 34.1197 40H5.87005C2.62811 40 0 37.3712 0 34.1284V5.87156Z" fill="#E51A4D"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M19.9357 6.8862C26.1672 6.8862 31.2187 11.9316 31.2187 18.1552C31.2187 24.3789 26.1672 29.4242 19.9357 29.4242C18.1467 29.4242 16.4499 29.0083 14.946 28.2611L8.65295 33.0213V18.1552V17.4312H8.67607C9.05395 11.5385 13.9512 6.8862 19.9357 6.8862Z" fill="white"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M16.6119 14.6274H17.0439L20.738 12.278C20.7919 12.2472 20.8613 12.2626 20.8921 12.3088C20.9231 12.3628 20.9075 12.4321 20.8613 12.463L17.4603 14.6274H23.2289C23.8304 14.6274 24.3164 15.1127 24.3164 15.7134V19.9576C24.3164 20.5585 23.8304 21.0437 23.2289 21.0437H16.6196C16.0181 21.0437 15.5322 20.5585 15.5322 19.9576V15.7134C15.5245 15.1127 16.0181 14.6274 16.6119 14.6274Z" fill="#E51A4D"/>
  <path d="M22.6402 19.9584C23.1039 19.4951 23.1039 18.7441 22.6402 18.2808C22.1763 17.8176 21.4243 17.8176 20.9606 18.2808C20.4967 18.7441 20.4967 19.4951 20.9606 19.9584C21.4243 20.4217 22.1763 20.4217 22.6402 19.9584Z" fill="white"/>
  <path d="M19.2032 20.3121C19.497 20.3121 19.7353 20.0741 19.7353 19.7807C19.7353 19.4871 19.497 19.2491 19.2032 19.2491C18.9093 19.2491 18.671 19.4871 18.671 19.7807C18.671 20.0741 18.9093 20.3121 19.2032 20.3121Z" fill="white"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M17.4372 20.3043C17.7301 20.3043 17.9693 20.0655 17.9693 19.7729C17.9693 19.48 17.7301 19.2413 17.4372 19.2413C17.1441 19.2413 16.905 19.48 16.905 19.7729C16.905 20.0655 17.1441 20.3043 17.4372 20.3043Z" fill="white"/>
  <path d="M20.6453 15.7982H16.7892V16.9304H20.6453V15.7982Z" fill="white"/>
  <path d="M22.9359 15.7982H21.1158V16.9304H22.9359V15.7982Z" fill="white"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M23.7765 14.0652C24.3394 14.0961 24.8331 14.4887 24.9951 15.028C25.0105 15.0896 24.9797 15.1513 24.9178 15.1667C24.8562 15.1821 24.7946 15.1513 24.7792 15.0896C24.6403 14.6351 24.2393 14.3117 23.7611 14.2808C23.6994 14.2808 23.6532 14.2269 23.6532 14.1654C23.6609 14.1038 23.7148 14.0575 23.7765 14.0652Z" fill="#E51A4D"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M23.7687 13.58H23.9846C24.8022 13.58 25.4654 14.2423 25.4731 15.0589C25.4731 15.1205 25.4268 15.1667 25.3652 15.1667C25.3036 15.1667 25.2571 15.1205 25.2571 15.0589C25.2571 14.3656 24.6865 13.8033 23.9923 13.8033H23.7764C23.7148 13.8033 23.6686 13.7572 23.6686 13.6956C23.6609 13.6262 23.7071 13.58 23.7687 13.58Z" fill="#E51A4D"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M23.7687 13.0714H23.9307C25.0104 13.0714 25.8973 13.9573 25.8973 15.0356V15.0587C25.8973 15.1204 25.8511 15.1666 25.7893 15.1666C25.7277 15.1666 25.6814 15.1204 25.6814 15.0587V15.0356C25.6814 14.0806 24.8947 13.2949 23.9384 13.2949H23.7764C23.7148 13.2949 23.6686 13.2486 23.6686 13.187C23.6609 13.1176 23.7071 13.0714 23.7687 13.0714Z" fill="#E51A4D"/>
  </svg>
)

export default Logo