import React from 'react'

const EmptyRecordings = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="585" height="288" fill="none" viewBox="0 0 585 288" {...props}>
    <path fill="#E0E2EB" fillRule="evenodd" d="M378.5 267c101.083 0 183.5-8.306 183.5-18.5S479.555 230 378.5 230c-101.083 0-183.5 8.306-183.5 18.5-.028 10.167 82.417 18.5 183.5 18.5z" clipRule="evenodd"/>
    <path fill="#E0E2EB" d="M167.5 257c66.55 0 120.5-5.149 120.5-11.5S234.05 234 167.5 234 47 239.149 47 245.5 100.95 257 167.5 257z"/>
    <g opacity=".3">
        <g opacity=".3">
            <path fill="#A33746" d="M300.888 218.923h-.488v9.158h.488v-9.158zM300.888 234.901h-.488v19.973h.488v-19.973zM300.888 268.904h-.488v18.999h.488v-18.999z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M220.359 253.412l-.293.487-6.816 14.225-.194.487h-.585l.195-.487 6.914-14.225.194-.487h.585zM232.725 227.593l-.195.487-3.505 7.21-.195.487h-.584l.195-.487 3.505-7.21.195-.487h.584zM239.444 213.661l-2.337 4.871-.292.487h-.487l.195-.487 2.434-5.066.487.195z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M132.624 267.442h.584l-.292.488L118.7 288l-.39-.195 14.022-19.875.292-.488zM155.605 235.681l-.293.487-11.587 16.368-.292.487h-.585l.293-.487 11.587-16.368.292-.487h.585zM168.068 218.144l-.292.487-6.426 8.963-.293.487h-.681l.389-.487 6.33-9.061.389-.389h.584z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M65.728 251.659l-.584.487-18.599 14.517-.584.487h-.78l.585-.487 18.599-14.517.584-.487h.779zM96.498 227.594l-.584.487-10.224 7.989-.682.487h-.779l.682-.487 10.224-7.989.584-.487h.78zM116.363 212.103l-7.206 5.65-.584.488h-.779l.584-.488 7.692-6.04.293.39z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M362.039 219.313l.194.487h-.487l-.292-.487-2.726-5.651.487-.195 2.824 5.846zM369.536 234.998h-.486l-.293-.487-3.116-6.43-.194-.487h.584l.195.487 3.116 6.43.194.487zM386.48 270.17h-.585l-.194-.487-6.719-13.932-.195-.487h.487l.292.487 6.621 13.932.293.487z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M437.796 228.081h-.584l-.39-.487-5.161-7.307-.389-.487h.584l.39.487 5.16 7.307.39.487zM457.952 256.726h-.584l-.389-.585-15.191-21.532-.292-.487h.584l.293.487 15.19 21.629.389.488zM479.862 287.805l-.39.195-12.074-17.05-.292-.487h.584l.292.487 11.88 16.855z" opacity=".3"/>
        </g>
        <g opacity=".3">
            <path fill="#A33746" d="M492.91 220.092l.682.584-.877-.097-.584-.487-10.322-7.989.292-.39 10.809 8.379zM510.34 233.732l.682.487h-.877l-.584-.487-7.303-5.651-.584-.487h.779l.681.487 7.206 5.651zM558.93 271.729h-.779l-.584-.488h-.098l-17.43-13.64-.584-.487h.779l.682.487 17.43 13.64.584.488z" opacity=".3"/>
        </g>
    </g>
    <path fill="#4F3F77" d="M147.23 4.19l3.311 118.765h292.903V0L147.23 4.19z"/>
    <path fill="#478BEC" d="M152.294 6.138l3.116 114.771h283.068V2.046L152.294 6.138z"/>
    <path fill="#2475D1" d="M152.294 6.138l13.048 24.26h257.653l15.483-28.352-286.184 4.092z"/>
    <path fill="#479DFF" d="M422.995 30.398l.584 89.634 14.899.877V2.046l-15.483 28.352zM165.342 30.398l1.266 90.121-11.198.39-3.116-114.771 13.048 24.26z"/>
    <path fill="url(#paint0_linear)" d="M152.294 6.138l3.116 114.771h283.068V2.046L152.294 6.138z" opacity=".41"/>
    <path fill="#83B1E7" d="M258.963 9H323L210.829 120.576 198 121 258.963 9zM332.761 9H352L230.082 120.672 217 121 332.761 9z" opacity=".41"/>
    <path fill="#68185C" d="M155.41 120.909l-.877-110.582 279.563-2.143 4.382 112.725V2.046L152.294 6.138l3.116 114.771z" opacity=".2"/>
    <path fill="#24548B" d="M325.426 121.983l-.974-.098s.292-4.579-1.071-9.645c-.682-2.338-1.656-3.702-2.727-5.164-1.266-1.559-2.531-3.215-3.213-6.333-.487-2.143-.292-4.871 0-7.794.389-4.872.779-9.84-2.045-12.763-4.479-4.482-12.756-2.046-12.853-2.046l-.292-.975c.389-.097 8.958-2.63 13.827 2.241 3.116 3.118 2.726 8.38 2.337 13.445-.195 2.826-.39 5.457 0 7.502.681 2.923 1.752 4.385 3.018 5.944 1.071 1.461 2.24 2.923 2.922 5.456 1.363 5.553 1.071 10.035 1.071 10.23z"/>
    <path fill="#24389A" d="M318.902 108.342l-.487.195c-1.071.487-2.24-.097-2.727-1.072l-13.243-31.274c-.487-1.072.098-2.241 1.071-2.728l.487-.195c1.071-.487 2.24.097 2.727 1.072l13.243 31.274c.487 1.072 0 2.339-1.071 2.728z"/>
    <path fill="#243C9C" d="M303.809 76.678c8.658 0 15.677-7.023 15.677-15.686s-7.019-15.686-15.677-15.686c-8.659 0-15.678 7.023-15.678 15.686s7.019 15.686 15.678 15.686z" opacity=".5"/>
    <path fill="#2452A6" d="M316.639 63.945c1.659-7.072-2.726-14.15-9.795-15.808-7.068-1.658-14.144 2.73-15.803 9.803-1.659 7.072 2.727 14.15 9.795 15.808 7.069 1.658 14.144-2.73 15.803-9.803z" opacity=".5"/>
    <path fill="#245AC9" d="M315.883 120.813l-.195-13.348 4.479-.779.682 14.127h-4.966z"/>
    <path fill="#2452A6" d="M313.643 105.224l.877 3.994 7.595-.682-1.363-5.748-7.109 2.436z"/>
    <path fill="#24386F" d="M303.809 76.678c-3.311 0-5.94-2.728-5.94-5.944V59.14c0-3.312 2.726-5.943 5.94-5.943 3.31 0 5.939 2.728 5.939 5.943v11.497c0 3.313-2.629 6.04-5.939 6.04z"/>
    <path fill="#24389A" d="M302.737 76.678c-2.726 0-4.868-2.241-4.868-4.872v-13.64c0-2.728 2.239-4.871 4.868-4.871 2.727 0 4.869 2.24 4.869 4.871v13.64c0 2.631-2.142 4.872-4.869 4.872z"/>
    <path fill="#247CB5" d="M310.235 60.991h-12.464c-.389 0-.681-.292-.681-.682 0-.39.292-.682.681-.682h12.464c.39 0 .682.292.682.682 0 .39-.292.682-.682.682zM310.235 57.678h-12.464c-.389 0-.681-.292-.681-.681 0-.39.292-.682.681-.682h12.464c.39 0 .682.292.682.681 0 .39-.292.682-.682.682zM310.235 64.304h-12.464c-.389 0-.681-.292-.681-.682 0-.39.292-.682.681-.682h12.464c.39 0 .682.293.682.682 0 .39-.292.682-.682.682zM310.235 67.616h-12.464c-.389 0-.681-.292-.681-.682 0-.39.292-.682.681-.682h12.464c.39 0 .682.292.682.682 0 .39-.292.682-.682.682zM310.235 70.93h-12.464c-.389 0-.681-.293-.681-.683 0-.39.292-.682.681-.682h12.464c.39 0 .682.293.682.682 0 .39-.292.682-.682.682z"/>
    <path fill="#24BFD6" d="M302.348 60.991h-3.7c-.195 0-.39-.195-.39-.39v-.584c0-.195.195-.39.39-.39h3.7c.195 0 .389.195.389.39v.584c0 .195-.194.39-.389.39zM302.348 57.678h-3.7c-.195 0-.39-.194-.39-.39v-.584c0-.195.195-.39.39-.39h3.7c.195 0 .389.195.389.39v.585c0 .195-.194.39-.389.39zM302.348 64.304h-3.7c-.195 0-.39-.194-.39-.39v-.584c0-.195.195-.39.39-.39h3.7c.195 0 .389.195.389.39v.585c0 .195-.194.39-.389.39zM302.348 67.616h-3.7c-.195 0-.39-.195-.39-.39v-.584c0-.195.195-.39.39-.39h3.7c.195 0 .389.195.389.39v.584c0 .195-.194.39-.389.39zM302.348 70.93h-3.7c-.195 0-.39-.195-.39-.39v-.585c0-.195.195-.39.39-.39h3.7c.195 0 .389.195.389.39v.585c0 .195-.194.39-.389.39z"/>
    <path fill="#245AC9" d="M301.472 76.482l3.31 5.359 4.48-1.462-.682-6.138s-4.187-1.558-7.108 2.241z"/>
    <path fill="#24386F" d="M309.262 80.088l.097.488s-3.116.194-4.285 1.948l-.292-.682s.195-.877 1.656-1.461c1.46-.682 2.824-.293 2.824-.293z"/>
    <path fill="#24548B" d="M211.109 5.554h-1.072V24.65h1.072V5.554z"/>
    <path fill="#2452BD" d="M190.562 35.464s7.498-12.569 20.059-12.569c12.562 0 19.183 12.569 19.183 12.569h-39.242z"/>
    <path fill="#24BFD6" d="M190.562 35.465c-2.629 1.462 37.879 1.462 39.242 0 1.461-1.461-35.25-2.143-39.242 0z"/>
    <path fill="#24548B" d="M378.593 2.923h-1.072v21.726h1.072V2.924z"/>
    <path fill="#2452BD" d="M357.949 35.464s7.498-12.569 20.059-12.569 19.183 12.569 19.183 12.569h-39.242z"/>
    <path fill="#24BFD6" d="M357.949 35.464c-2.629 1.462 37.879 1.462 39.242 0 1.46-1.461-35.25-2.143-39.242 0z"/>
    <path fill="#1C0739" d="M143.822 172.353h25.123l3.992 66.738h-33.399l4.284-66.738z"/>
    <path fill="#301D5F" d="M165.342 170.307l4.966 68.784h2.629l-3.797-68.784h-3.798zM141.777 170.307l-2.239 68.784h2.142l3.798-68.784h-3.701z"/>
    <path fill="#11001E" d="M141.777 170.306l9.251-3.994 21.812-.292-3.7 4.286h-3.798l3.798-3.41-15.872-.097-7.79 3.507h-3.701z"/>
    <path fill="#11001E" d="M165.439 172.353l1.948-4.482h-13.925l-8.082 4.482h20.059z"/>
    <path fill="#1D0422" d="M165.439 172.352l-.097-2.046 2.045-2.435h-13.925l-8.082 4.481.098-2.046 7.79-3.507 15.872.097-3.798 3.41.097 2.046z"/>
    <path fill="#6C5F8E" d="M145.478 170.307l-3.798 68.784h2.142l2.337-66.738-.681-2.046z"/>
    <path fill="url(#paint1_linear)" d="M144.601 185.311l21.812.487.097.389-21.909-.487v-.389z"/>
    <path fill="#6C5F8E" d="M143.822 199.828l22.202 13.056 1.655 26.208h1.461l-2.63-26.598-22.59-13.932-.098 1.266z"/>
    <path fill="#45346F" d="M145.185 200.606l20.839 12.276 1.655 26.209h-1.169l-.973-26.209-20.352-12.276z"/>
    <path fill="#361B41" d="M145.38 185.798l21.13.39v.389l-21.032-.389-.098-.39z"/>
    <path fill="#73FFE5" d="M149.08 175.859l.585 1.364h11.49l.779-1.364h-3.603l-1.071-.974h-3.992l-.779.974h-3.409z"/>
    <path fill="#0088A6" d="M149.665 177.224l-.39.195h12.172l-.292-.195h-11.49z"/>
    <path fill="#301D5F" d="M144.114 194.664l23.954 13.932-.486-6.917-23.176-13.446-.292 6.431z"/>
    <path fill="#6C5F8E" d="M144.114 194.664l1.071 1.851 22.883 13.835v-1.754l-23.954-13.932z"/>
    <path fill="#45346F" d="M145.478 170.307l-1.656 18.414 23.857 13.835-.097-.877-23.176-13.445 1.072-17.927z"/>
    <path fill="#1D0422" d="M168.263 166.896l-2.921 3.41 2.337 32.249-1.655-32.249 2.239-3.41z"/>
    <path fill="#73FFE5" d="M148.983 236.461l1.169 1.267h11.977l.681-1.267h-13.827z"/>
    <path fill="url(#paint2_linear)" d="M139.538 239.091v-.779l33.399.389v.39h-33.399z"/>
    <path fill="#1C0739" d="M172.84 166.02l3.408 60.99-3.311 12.081-3.797-68.785 3.7-4.286z"/>
    <path fill="url(#paint3_linear)" d="M134.377 150.625l319.973-.682 22.396 12.958-362.331-.584 19.962-11.692z"/>
    <path fill="#31091D" d="M114.415 162.317v6.626h362.039l.292-6.041-362.331-.585z"/>
    <path fill="#31091D" d="M114.707 165.629l-2.824 74.533h5.356l20.059-74.436-22.591-.097z"/>
    <path fill="#22000E" d="M136.422 169.234l-22.007-.097v1.169h21.812l.195-1.072z"/>
    <path fill="#31091D" d="M476.162 165.629l2.823 74.533h-5.355l-20.059-74.436 22.591-.097z"/>
    <path fill="#22000E" d="M454.447 169.04l22.007-.098v1.267h-21.812l-.195-1.169z"/>
    <path fill="#22000E" d="M454.642 169.624l4.966-.39 14.022 70.929-18.988-70.539zM136.422 168.942l-5.161.682-14.022 70.539 19.183-71.221z"/>
    <path fill="#1C0739" d="M158.818 138.544v-9.743l289.884-.877.974 10.62H158.818z"/>
    <path fill="url(#paint4_linear)" d="M158.818 128.801l1.363-.584 286.768-1.267 1.753.974-289.884.877z"/>
    <path fill="url(#paint5_linear)" d="M442.859 138.544H152.002l-12.756 10.717h314.325l-10.712-10.717z"/>
    <path fill="#1C0739" d="M453.571 149.262v1.656l-314.325.097v-1.753h314.325zM189.101 148.579h-2.434l5.94-10.035h2.337l-5.843 10.035zM201.273 148.579h-2.434l5.355-10.035h2.24l-5.161 10.035zM213.445 148.579h-2.434l4.674-10.035h2.337l-4.577 10.035zM225.617 148.579h-2.435l4.09-10.035h2.24l-3.895 10.035zM237.789 148.579h-2.435l3.408-10.035h2.337l-3.31 10.035zM249.96 148.579h-2.434l2.727-10.035h2.337l-2.63 10.035zM262.132 148.579h-2.434l2.142-10.035h2.24l-1.948 10.035zM274.304 148.579h-2.434l1.46-10.035h2.337l-1.363 10.035zM286.476 148.579h-2.434l.876-10.035h2.24l-.682 10.035zM298.648 148.579h-2.435l.195-10.035h2.337l-.097 10.035zM310.82 148.579h-2.435l-.487-10.035h2.337l.585 10.035zM322.991 148.579h-2.434l-1.071-10.035h2.239l1.266 10.035zM335.163 148.579h-2.434l-1.753-10.035h2.337l1.85 10.035zM347.335 148.579h-2.434l-2.337-10.035h2.239l2.532 10.035zM359.507 148.579h-2.435l-3.018-10.035h2.337l3.116 10.035zM371.679 148.579h-2.435l-3.7-10.035h2.337l3.798 10.035zM383.85 148.579h-2.434l-4.284-10.035h2.337l4.381 10.035zM396.022 148.579h-2.434l-4.966-10.035h2.337l5.063 10.035zM408.194 148.579h-2.434l-5.551-10.035h2.24l5.745 10.035z"/>
    <path fill="#FCB600" d="M200.007 144.585h4.187l-1.85 3.995h-4.479l2.142-3.995z"/>
    <path fill="#FC343A" d="M226.396 138.544l-1.266 2.533h3.992l.974-2.533h-3.7z"/>
    <path fill="#7434CA" d="M248.305 143.025h3.603l-.974 3.605h-3.797l1.168-3.605zM319.194 143.902h3.797l.39 3.605h-3.895l-.292-3.605z"/>
    <path fill="#FC343A" d="M311.306 145.073h-3.602v-2.728h3.31l.292 2.728z"/>
    <path fill="#7434CA" d="M366.42 142.149h3.214l1.363 3.216h-3.311l-1.266-3.216z"/>
    <path fill="#FC343A" d="M391.835 145.948l1.363 2.631h3.408l-1.363-2.631h-3.408z"/>
    <path fill="#FCB600" d="M405.468 148.579l-1.461-2.631h3.116l1.558 2.631h-3.213z"/>
    <path fill="#FC343A" d="M283.457 144.78h3.701v3.8h-3.993l.292-3.8z"/>
    <path fill="#7434CA" d="M272.454 140.493l-.974 4.287h4.187l.39-4.287h-3.603z"/>
    <path fill="url(#paint6_linear)" d="M247.818 138.544l2.921-48.715h87.93l4.284 48.909-95.135-.194z"/>
    <path fill="url(#paint7_linear)" d="M250.545 136.4h89.487l-3.213-44.623h-83.84l-2.434 44.623z"/>
    <path fill="#1C0739" d="M247.818 138.544l2.727 2.338 90.558-.195 1.85-1.948-95.135-.195z"/>
    <path fill="#00BAFF" d="M250.545 136.4l3.408-43.55h81.6l4.479 43.55-3.213-44.623h-83.84l-2.434 44.623z"/>
    <path fill="#fff" d="M250.546 136.4l-1.071.585h91.532l-.974-.585h-89.487z"/>
    <path fill="#A8A6D6" d="M284.334 91.778l3.213-1.949h22.201l-2.044 1.949h-23.37zM310.917 91.778l2.337-1.949h17.235l-2.532 1.949h-17.04z"/>
    <path fill="#404DAB" d="M335.163 94.31s2.24 38.388 1.363 39.167c-.876.779-84.91 2.143-84.91 2.143h87.442l-3.895-41.31z" opacity=".3"/>
    <path fill="#1C0739" d="M366.518 157.736H210.621v3.215h155.897v-3.215z"/>
    <path fill="url(#paint8_linear)" d="M366.518 157.737l-5.258-6.82-144.31.097-6.329 6.723h155.897z"/>
    <path fill="#1C0739" d="M217.924 155.398h-1.558l3.895-5.066h1.461l-3.798 5.066zM225.909 155.398h-1.655l3.505-5.066h1.558l-3.408 5.066zM233.796 155.398h-1.558l3.019-5.066h1.558l-3.019 5.066zM241.781 155.398h-1.655l2.629-5.066h1.558l-2.532 5.066zM249.668 155.398h-1.558l2.24-5.066h1.461l-2.143 5.066zM257.653 155.398h-1.655l1.85-5.066h1.46l-1.655 5.066zM265.54 155.398h-1.558l1.364-5.066h1.46l-1.266 5.066zM273.525 155.398h-1.655l.973-5.066h1.558l-.876 5.066zM281.412 155.398h-1.558l.487-5.066h1.558l-.487 5.066zM289.301 155.398h-1.559l.097-5.066h1.559l-.097 5.066zM297.284 155.398h-1.557l-.293-5.066h1.461l.389 5.066zM305.172 155.398h-1.558l-.682-5.066h1.461l.779 5.066zM313.157 155.398h-1.558l-1.169-5.066h1.461l1.266 5.066zM321.044 155.398h-1.558l-1.558-5.066h1.558l1.558 5.066zM329.029 155.398h-1.558l-2.045-5.066h1.558l2.045 5.066zM336.916 155.398h-1.558l-2.434-5.066h1.558l2.434 5.066zM344.901 155.398h-1.656l-2.726-5.066h1.46l2.922 5.066zM352.788 155.398h-1.558l-3.213-5.066h1.46l3.311 5.066zM360.773 155.398h-1.656l-3.603-5.066h1.461l3.798 5.066z"/>
    <path fill="#360032" d="M405.857 155.009l22.494 7.794 46.545.098-18.112-6.723-50.927-1.169z" opacity=".5"/>
    <path fill="url(#paint9_linear)" d="M405.857 155.009l1.948-42.284 33.496-1.657v46.279l-35.444-2.338z"/>
    <path fill="url(#paint10_linear)" d="M441.301 157.347l15.483-1.169-1.266-43.551-14.217-1.559v46.279z"/>
    <path fill="#fff" d="M406.831 134.841l33.302-10.425-33.496 14.517-.098 1.461 34.763-13.347v-15.979l-33.497 1.657-.974 22.116z" opacity=".3"/>
    <path fill="url(#paint11_radial)" d="M423.579 150.039c7.852 0 14.217-7.284 14.217-16.27s-6.365-16.271-14.217-16.271c-7.851 0-14.216 7.285-14.216 16.271s6.365 16.27 14.216 16.27z"/>
    <path fill="#fff" d="M415.4 147.02l.876-1.169 8.082 2.533-.292 1.559c0 .097-4.382.779-8.666-2.923z" opacity=".5"/>
    <path fill="url(#paint12_linear)" d="M423.579 148.481c7.099 0 12.854-6.587 12.854-14.712s-5.755-14.711-12.854-14.711c-7.099 0-12.853 6.586-12.853 14.711s5.754 14.712 12.853 14.712z"/>
    <path fill="#fff" d="M414.037 125.488l-.39-.974s2.727-3.702 5.356-4.482l.389.877-5.355 4.579z" opacity=".5"/>
    <path fill="url(#paint13_radial)" d="M423.579 147.507c6.615 0 11.977-6.15 11.977-13.737s-5.362-13.738-11.977-13.738c-6.614 0-11.977 6.151-11.977 13.738s5.363 13.737 11.977 13.737z"/>
    <path fill="#fff" d="M415.4 143.804l7.498-7.307 2.239.682-5.453 9.646c.098 0-2.824-1.17-4.284-3.021zM421.534 147.312l4.285-10.425.876-.195-3.116 10.815-2.045-.195z" opacity=".5"/>
    <path fill="#1C0739" d="M428.935 133.769c0 2.144-1.558 3.897-3.603 3.897-1.947 0-3.603-1.753-3.603-3.897 0-2.143 1.558-3.897 3.603-3.897s3.603 1.754 3.603 3.897z"/>
    <path fill="url(#paint14_linear)" d="M428.059 133.769c0 1.949-1.364 3.605-3.116 3.605-1.753 0-3.116-1.559-3.116-3.605 0-1.949 1.363-3.605 3.116-3.605 1.752 0 3.116 1.656 3.116 3.605z"/>
    <path fill="#fff" d="M424.164 133.574c0 .974-.487 1.754-1.169 1.754s-1.168-.78-1.168-1.754.486-1.754 1.168-1.754c.682 0 1.169.78 1.169 1.754z" opacity=".3"/>
    <path fill="#40368B" d="M405.857 154.132l35.152-.682.39-16.076-.098 19.973-35.444-2.338v-.877z" opacity=".3"/>
    <path fill="#40368B" d="M441.301 141.661l14.704-11.399.779 25.916-15.483 1.169v-15.686z" opacity=".3"/>
    <path fill="#63FFFF" d="M405.857 154.132l1.948-41.407 33.496-1.657 14.217 1.559-14.119-1.169-31.939 2.338-3.603 40.336z"/>
    <path fill="#fff" d="M439.938 111.166l1.364 13.348v-13.446l-1.364.098z"/>
    <path fill="#63FFFF" d="M436.725 153.352h-4.577c-.389 0-.779-.292-.779-.779v-.293c0-.389.292-.779.779-.779h4.577c.389 0 .779.292.779.779v.293c0 .389-.39.779-.779.779z"/>
    <path fill="#360032" d="M182.869 154.717l-12.756 7.697-46.642-.097 16.748-9.451 42.65 1.851z" opacity=".5"/>
    <path fill="url(#paint15_linear)" d="M182.869 154.716l-1.168-41.309-32.134-1.559v45.109l33.302-2.241z"/>
    <path fill="url(#paint16_linear)" d="M149.567 156.957l-14.801-1.169 1.266-42.479 13.535-1.461v45.109z"/>
    <path fill="#fff" d="M182.772 135.036l-32.036-10.133 32.036 11.497.097.487-33.302-9.451v-15.588l32.134 1.559 1.071 21.629z" opacity=".3"/>
    <path fill="url(#paint17_radial)" d="M166.608 149.845c7.529 0 13.632-7.11 13.632-15.881s-6.103-15.881-13.632-15.881-13.633 7.11-13.633 15.881 6.104 15.881 13.633 15.881z"/>
    <path fill="#fff" d="M174.495 146.922l-.876-1.071-7.79 2.533.292 1.461s4.187.682 8.374-2.923z" opacity=".5"/>
    <path fill="url(#paint18_linear)" d="M166.608 148.384c6.83 0 12.366-6.456 12.366-14.42 0-7.963-5.536-14.419-12.366-14.419s-12.367 6.456-12.367 14.419c0 7.964 5.537 14.42 12.367 14.42z"/>
    <path fill="#fff" d="M175.858 125.878l.39-.974s-2.629-3.605-5.161-4.384l-.292.876 5.063 4.482z" opacity=".5"/>
    <path fill="url(#paint19_radial)" d="M166.608 147.41c6.346 0 11.49-6.02 11.49-13.445 0-7.426-5.144-13.445-11.49-13.445s-11.49 6.019-11.49 13.445c0 7.425 5.144 13.445 11.49 13.445z"/>
    <path fill="#fff" d="M174.495 143.805l-7.206-7.113-2.142.585 5.258 9.353c-.097.098 2.727-1.072 4.09-2.825zM168.653 147.214l-4.187-10.23-.779-.097 2.921 10.522 2.045-.195z" opacity=".5"/>
    <path fill="#1C0739" d="M164.855 137.764c1.882 0 3.408-1.702 3.408-3.8 0-2.099-1.526-3.8-3.408-3.8s-3.408 1.701-3.408 3.8c0 2.098 1.526 3.8 3.408 3.8z"/>
    <path fill="url(#paint20_linear)" d="M162.323 133.964c0 1.948 1.364 3.507 3.019 3.507s3.019-1.559 3.019-3.507c0-1.949-1.364-3.508-3.019-3.508s-3.019 1.559-3.019 3.508z"/>
    <path fill="#fff" d="M166.024 133.769c0 .975.486 1.754 1.168 1.754.584 0 1.169-.779 1.169-1.754 0-.974-.487-1.753-1.169-1.753-.584.097-1.168.779-1.168 1.753z" opacity=".3"/>
    <path fill="#40368B" d="M182.869 153.84l-33.01-.682-.389-15.686.097 19.485 33.302-2.24v-.877z" opacity=".3"/>
    <path fill="#40368B" d="M149.567 141.662l-14.022-11.107-.779 25.234 14.801 1.169v-15.296z" opacity=".3"/>
    <path fill="#63FFFF" d="M182.87 154.716l-1.168-41.309-32.134-1.559-13.535 1.461 13.438-1.072 30.77 2.241 2.629 40.238z"/>
    <path fill="#fff" d="M150.834 111.848l-1.267 13.055v-13.055h1.267z"/>
    <path fill="#63FFFF" d="M160.668 153.84h-7.108c-.682 0-1.169-.487-1.169-1.169v-.487c0-.682.487-1.169 1.169-1.169h7.108c.682 0 1.168.487 1.168 1.169v.487c-.097.585-.584 1.169-1.168 1.169z"/>
    <path fill="#2B0950" d="M314.52 237.045c1.183 0 2.142-1.44 2.142-3.216 0-1.775-.959-3.215-2.142-3.215-1.183 0-2.142 1.44-2.142 3.215 0 1.776.959 3.216 2.142 3.216z"/>
    <path fill="#2B0950" d="M312.572 237.239l1.948-.194 1.46-4.482-1.752-1.949-2.143.195.487 6.43z"/>
    <path fill="#581119" d="M312.378 237.24c1.183 0 2.142-1.44 2.142-3.215 0-1.776-.959-3.215-2.142-3.215-1.184 0-2.143 1.439-2.143 3.215 0 1.775.959 3.215 2.143 3.215z"/>
    <path fill="url(#paint21_linear)" d="M326.886 233.732l-7.984-2.241-3.798 1.364.097 1.462 3.311-.585 10.711 4.287-2.337-4.287z"/>
    <path fill="url(#paint22_linear)" d="M333.995 191.447l-4.674 50.566-3.408.487-2.435-51.54 10.517.487z"/>
    <path fill="#2B0950" d="M346.069 238.701c0 1.753.974 3.215 2.142 3.215 1.169 0 2.143-1.462 2.143-3.215 0-1.754-.974-3.216-2.143-3.216-1.168 0-2.142 1.462-2.142 3.216z"/>
    <path fill="#2B0950" d="M350.062 242.208l-1.85-.292-1.461-4.482 1.655-1.949 2.143.293-.487 6.43z"/>
    <path fill="#581119" d="M348.211 238.992c0 1.754.974 3.215 2.143 3.215 1.168 0 2.142-1.461 2.142-3.215 0-1.753-.974-3.215-2.142-3.215-1.169-.097-2.143 1.364-2.143 3.215z"/>
    <path fill="url(#paint23_linear)" d="M330.1 233.926l11.587-.78 5.843 3.898-.098 1.461-5.745-2.436-11.49 1.462-.097-3.605z"/>
    <path fill="#2C0047" d="M330.1 232.952s-1.948 1.169-4.674-.098c0 0-2.921 2.047-3.993 5.846-1.265 4.774 12.172 5.456 12.172 1.169 0-4.287-3.505-6.917-3.505-6.917z"/>
    <path fill="#2B0950" d="M355.904 255.068l-.39.195-.779-2.826.877-3.312 1.947-.877 1.948.195v.487l-3.603 6.138z"/>
    <path fill="#2B0950" d="M333.021 202.067l-8.861 4.579-.292-7.502 9.445-.195-.292 3.118z" opacity=".2"/>
    <path fill="#6B0063" d="M332.339 209.667l-.876 1.461-2.434 22.506-1.558.293 1.265-22.312-4.284.293-.584-7.307 8.471 5.066z" opacity=".3"/>
    <path fill="url(#paint24_linear)" d="M331.56 241.916l17.528 2.436 6.232 4.481 3.603-.974-7.109-7.307-20.351-6.236-.487.293c-2.142 1.851.584 7.307.584 7.307z"/>
    <path fill="url(#paint25_linear)" d="M364.376 248.248c1.655-.195 3.31 2.046 3.7 5.164.389 3.02-.584 5.748-2.24 5.943l-6.232.877-1.46-11.107 6.232-.877z"/>
    <path fill="#fff" d="M367.978 256.529l-6.134.585-.487 1.364 6.427-.974.194-.975z"/>
    <path fill="#2B0950" d="M359.799 249.613l6.329-.682.682.877-6.232.876-.779-1.071z" opacity=".5"/>
    <path fill="#581119" d="M355.905 255.069c.389 3.02 2.045 5.358 3.7 5.164 1.655-.195 2.629-2.923 2.24-5.944-.39-3.02-2.045-5.358-3.701-5.163-1.655.194-2.629 2.825-2.239 5.943z"/>
    <path fill="#7E0A0E" d="M357.17 254.874c.195 1.754 1.168 3.02 2.045 2.923.097 0 .194 0 .292-.097.779-.39 1.168-1.754.974-3.216-.195-1.364-.877-2.533-1.558-2.825-.195-.098-.39-.097-.487-.097-.877.097-1.461 1.558-1.266 3.312z"/>
    <path fill="#2B0950" d="M357.949 254.873c.195 1.364.779 2.534 1.558 2.826.779-.39 1.168-1.754.974-3.215-.195-1.364-.877-2.534-1.558-2.826-.682.292-1.169 1.657-.974 3.215z"/>
    <path fill="#640F49" d="M354.638 255.263h.876s-.779-4.189.195-5.456c.877-1.169 2.727-1.753 4.479-.292l7.206-.682v-1.169s-4.869-.487-6.816-.487c-1.655 0-3.895.584-4.966 1.364-.195.097-.292.292-.39.389-1.46 1.754-.584 6.333-.584 6.333z"/>
    <path fill="#fff" d="M355.613 248.541c2.239-.975 4.576-.293 4.576-.293l7.206-.584s-4.869-.487-6.816-.487c-1.656 0-3.895.487-4.966 1.364z"/>
    <path fill="#2B0950" d="M331.56 241.916l17.528 2.436 6.232 4.481.973-.584-7.595-6.138s-16.943-3.215-17.43-3.8c-1.753-2.143.195-3.995.195-3.995l-.487.293c-2.142 1.851.584 7.307.584 7.307z" opacity=".2"/>
    <path fill="#2B0950" d="M298.258 246.203l-2.531-.682-5.648-6.333s3.797-1.364 5.745 0c1.947 1.364 2.824 2.728 2.434 7.015z"/>
    <path fill="url(#paint26_linear)" d="M291.832 249.125c2.312 0 4.187-2.224 4.187-4.969 0-2.744-1.875-4.968-4.187-4.968-2.313 0-4.187 2.224-4.187 4.968 0 2.745 1.874 4.969 4.187 4.969z"/>
    <path fill="url(#paint27_linear)" d="M293.877 239.481l-2.045-.195-3.31 4.677 1.46 4.384 3.019 1.267.876-10.133z"/>
    <path fill="url(#paint28_linear)" d="M297.869 240.844v1.169l.389 1.072 7.303-2.241 18.696-1.266c.195-.39 2.337-3.703.487-5.651l-.389-.39-20.936 3.995-5.55 3.312z"/>
    <path fill="#2B0950" d="M297.869 242.306l.389.779 7.303-2.241 18.696-1.266c.779-1.657 1.753-4.287.487-5.651l-.389-.39s1.655 2.046-.293 3.995c-.486.487-17.722 1.851-17.722 1.851l-8.471 2.923z" opacity=".2"/>
    <path fill="#581119" d="M289.397 244.644c0 2.826 1.85 5.067 4.187 5.067s4.187-2.241 4.187-5.067c0-2.825-1.85-5.066-4.187-5.066-2.337-.097-4.187 2.241-4.187 5.066z"/>
    <path fill="#2B0950" d="M291.345 244.644c0 1.461.973 2.63 2.142 2.63 1.168 0 2.142-1.169 2.142-2.63 0-1.462-.974-2.631-2.142-2.631-1.169-.097-2.142 1.169-2.142 2.631z"/>
    <path fill="#640F49" d="M298.94 245.814l-.682.389s.292-2.338-.389-4.189c-.682-1.949-1.85-2.728-3.895-2.923-1.169-.097-2.824.487-2.824.487h-3.798l-.194-.584s4.284-1.462 7.595-.585c3.7.78 4.187 3.995 4.187 7.405z"/>
    <path fill="#2B0950" d="M301.082 255.264l-3.408-.779-2.532-5.651s3.506-1.072 5.161 0c1.655 1.071 1.169 5.358.779 6.43z"/>
    <path fill="url(#paint29_linear)" d="M325.036 241.916l-17.527 2.436-6.232 4.481-.39.293-3.603-1.267 7.498-7.307 20.352-6.236.486.293c2.143 1.851-.584 7.307-.584 7.307z"/>
    <path fill="url(#paint30_linear)" d="M292.124 248.248c-1.656-.195-3.311 2.046-3.701 5.164-.389 3.02.585 5.748 2.24 5.943l6.232.877 1.461-11.107-6.232-.877z"/>
    <path fill="#fff" d="M288.521 256.529l6.232.585.487 1.364-6.427-.974-.292-.975z"/>
    <path fill="#2B0950" d="M296.798 249.613l-6.329-.682-.779.877 6.329.876.779-1.071z" opacity=".5"/>
    <path fill="#581119" d="M300.693 255.069c-.39 3.02-2.045 5.358-3.701 5.164-1.655-.195-2.629-2.923-2.239-5.944.389-3.02 2.045-5.358 3.7-5.163 1.655.194 2.629 2.825 2.24 5.943z"/>
    <path fill="#7E0A0E" d="M299.329 254.874c-.194 1.754-1.168 3.02-2.045 2.923-.097 0-.194 0-.292-.097-.779-.39-1.168-1.754-.973-3.216.194-1.364.876-2.533 1.558-2.825.194-.098.389-.097.486-.097.974.097 1.558 1.558 1.266 3.312z"/>
    <path fill="#2B0950" d="M298.55 254.873c-.194 1.364-.779 2.534-1.558 2.826-.779-.39-1.168-1.754-.973-3.215.194-1.364.876-2.534 1.558-2.826.779.292 1.168 1.657.973 3.215z"/>
    <path fill="#640F49" d="M301.959 255.263h-.876s.779-4.189-.195-5.456c-.877-1.169-2.727-1.753-4.479-.292l-7.206-.682v-1.169s4.869-.487 6.816-.487c1.655 0 3.895.584 4.966 1.364.195.097.292.292.39.389 1.46 1.754.584 6.333.584 6.333z"/>
    <path fill="#fff" d="M300.985 248.541c-2.239-.975-4.576-.293-4.576-.293l-7.206-.584s4.869-.487 6.816-.487c1.655 0 3.895.487 4.966 1.364z"/>
    <path fill="#2B0950" d="M325.036 241.916l-17.527 2.436-6.232 4.481-.974-.584 7.595-6.138s16.943-3.215 17.43-3.8c1.753-2.143-.194-3.995-.194-3.995l.486.293c2.143 1.851-.584 7.307-.584 7.307z" opacity=".2"/>
    <path fill="url(#paint31_linear)" d="M326.399 176.444c.098 1.948 1.072 3.41 2.143 3.41 1.071 0 1.85-1.657 1.85-3.605-.098-1.949-1.071-3.41-2.142-3.41-1.169 0-1.948 1.656-1.851 3.605z"/>
    <path fill="url(#paint32_linear)" d="M330.1 172.741l-1.948.098.292 7.015 1.948-.098-.292-7.015z"/>
    <path fill="url(#paint33_radial)" d="M328.347 176.346c.097 1.949 1.071 3.41 2.142 3.41s1.85-1.656 1.85-3.605c0-1.948-1.071-3.41-2.142-3.41s-1.947 1.657-1.85 3.605z"/>
    <path fill="url(#paint34_linear)" d="M329.71 176.249c0 .779.39 1.364.78 1.266.389 0 .682-.682.682-1.364 0-.779-.39-1.364-.78-1.266-.389 0-.779.682-.682 1.364z"/>
    <path fill="url(#paint35_linear)" d="M328.25 174.982s-20.352-2.825-36.418-2.143l-.39 1.851s24.344-.098 36.905 2.436l-.097-2.144z"/>
    <path fill="url(#paint36_linear)" d="M286.671 173.033c13.146-.584 25.804-.292 41.579 1.949 0 0-3.213-2.923-6.329-4.969-1.169-.779-2.337-1.364-3.311-1.656-3.506-1.072-22.007-3.313-27.655-2.046-.681.195-1.265.39-1.752.682-.487.292-.877.584-1.169.877-2.045 2.046-1.363 5.163-1.363 5.163z"/>
    <path fill="#2C0047" d="M286.671 173.034l5.161-.195s-3.506-2.436-2.629-5.749c0-.097 0-.194.097-.194-3.603 1.948-2.629 6.138-2.629 6.138z"/>
    <path fill="url(#paint37_linear)" d="M291.832 172.839l-5.843-.098v.975l5.453.974.39-1.851z"/>
    <path fill="url(#paint38_linear)" d="M295.337 209.667s-22.688-1.851-22.688-4.287c0-7.989 14.314-21.045 32.912-16.173 19.962 5.261 17.917-2.144 17.917-2.144l39.34 14.517-67.481 8.087z"/>
    <path fill="#2C0047" d="M362.818 202.555s.486-19.973 3.602-28.839c3.701-10.523 7.985-18.804 10.614-23.383 1.558-2.631 1.753-5.749.39-8.087-8.569-13.835-38.268-11.594-49.174 2.825 5.745 19.973 2.337 31.47-3.214 53.099l37.782 4.385z"/>
    <path fill="#fff" d="M377.424 142.246l-6.33.682v6.625l-4.089 7.892s4.966-8.769 5.647-8.769c.682 0 5.453-.584 5.453-.584s.779-3.8-.681-5.846z" opacity=".5"/>
    <path fill="url(#paint39_linear)" d="M370.315 150.917c-2.629 4.676-6.621 12.665-10.127 22.798-2.823 8.184-3.213 26.014-3.31 28.547v.292l-37.879-4.482c4.966-19.875 8.082-31.177 3.992-48.422-.779-3.215.293-6.722 2.922-9.158 9.932-9.061 30.186-8.964 39.826-3.41l.389.195c1.948 1.169 3.408 2.63 4.285 4.189.681 1.072 1.071 2.241 1.266 3.41.097 2.046-.292 4.092-1.364 6.041z"/>
    <path fill="#2B0950" d="M371.581 144.877c-3.895 1.364-17.235 6.625-21.714 16.173-5.258 11.204-2.824 33.418-2.824 33.418s-5.356-15.199-.682-31.567c4.674-16.465 23.954-21.434 23.954-21.434.682 1.072 1.072 2.241 1.266 3.41z" opacity=".34"/>
    <path fill="#2C0047" d="M333.703 136.108c1.265-5.261 5.453-17.634 15.969-19.973 2.337-.487 4.966-.487 7.887.098 15.97 3.605 11.685 18.998 11.685 18.998-7.011 3.897-13.048 5.749-18.014 6.431-2.434.389-4.577.389-6.524.292-7.595-.39-11.393-3.605-11.393-3.605s0-.779.39-2.241zm12.853-13.932c-1.947 1.169-5.648 6.722-3.895 9.353.39.585 4.479 1.364 9.056 1.559 4.382-.292 8.082-1.364 8.179-3.995.098-2.143-1.85-5.358-4.284-7.015-.974-.682-2.629-1.071-4.479-1.364-1.753.39-3.408.78-4.577 1.462z"/>
    <path fill="url(#paint40_linear)" d="M330.587 133.867c2.823-6.43 9.153-17.343 19.085-17.732 1.071 0 2.142 0 3.311.195 16.261 3.02 11.782 18.998 11.782 18.998-.584.39-1.071.78-1.655 1.072-4.285 2.728-8.082 4.287-11.88 5.261-1.947.487-3.895.78-5.842.974-10.517 1.072-16.554-4.189-16.554-4.189s.487-1.851 1.753-4.579zm16.359-10.815c-.585.39-1.461 1.267-2.143 2.339-1.168 1.753-1.947 3.994-.973 5.261.292.487 3.7 1.071 7.4 1.266 1.266-.097 2.532-.292 3.603-.487 1.168-.292 2.045-.779 2.532-1.364.292-.39.486-.779.584-1.364.097-1.754-1.558-4.384-3.506-5.748-.876-.585-2.142-.877-3.603-1.072-1.558.292-2.921.585-3.894 1.169z"/>
    <path fill="url(#paint41_linear)" d="M356.878 202.555l-.585 7.502-65.046-.78s2.727-4.774 9.543-9.548c3.895-2.728 14.606-9.645 25.609-5.261 20.936 8.379 30.479 8.087 30.479 8.087z"/>
    <path fill="#2C0047" d="M371.679 145.949s-16.944 2.825-22.299 17.05c-5.356 14.127-.682 37.9-.682 37.9l-.973-.195s-4.188-29.132 2.044-40.726c6.33-11.594 21.91-15.101 21.91-15.101v1.072z"/>
    <path fill="#2B0950" d="M323.478 143.902s10.225 5.943 10.225 18.024c0 11.984-3.019 21.824-3.019 21.824s7.206-16.758 4.869-27.865c-2.337-11.107-10.127-14.809-10.127-14.809l-1.948 2.826z" opacity=".34"/>
    <path fill="#2C0047" d="M325.815 140.492s11.296 4.774 10.127 21.824c-1.168 16.855-10.127 35.075-10.127 35.075h-.876s11.295-23.676 10.614-37.413c-.682-14.322-10.225-18.999-10.225-18.999l.487-.487z"/>
    <path fill="#63FFFF" d="M327.276 139.323s-3.311 2.144-4.577 6.041c-1.266 3.897 2.337 14.906 2.045 24.065-.292 8.379-4.187 22.701-4.187 22.701s4.966-16.563 4.966-24.552c0-9.354-2.434-17.538-1.655-21.63.779-3.799 3.408-6.625 3.408-6.625z"/>
    <path fill="url(#paint42_linear)" d="M327.957 138.836s12.367 4.969 9.446 29.326c0 0 1.266-23.48-11.004-28.157l1.558-1.169z" opacity=".5"/>
    <path fill="#2C0047" d="M362.818 201.58l-1.364 8.477-14.119-.098-51.998-.292c0-.098.097-.195.292-.39.876-1.364 4.09-5.943 10.419-9.45 1.85-1.072 6.135-3.605 11.198-5.067.195-.097.292-.097.487-.097 4.187-1.072 8.959-1.462 13.34.487 20.546 9.061 31.745 6.43 31.745 6.43zM311.209 190.376s-27.654 1.754-33.984 10.425c0 0 6.135-12.569 17.528-12.569 10.127 0 16.456 2.144 16.456 2.144z"/>
    <path fill="#2B0950" d="M336.721 131.918c7.206 2.728 14.996.877 18.014-.487-1.071.292-2.239.487-3.602.487-3.701-.195-7.109-.877-7.401-1.267-.876-1.364-.097-3.507.974-5.261l-1.85-2.338c-5.356 2.63-6.135 8.866-6.135 8.866z" opacity=".34"/>
    <path fill="#2C0047" d="M339.837 130.749c.39.585 6.427 1.559 11.393 1.267-3.798-.195-7.108-.877-7.4-1.267-1.461-2.143 1.558-6.722 3.213-7.697.974-.584 2.337-.876 3.797-1.071-2.726-.293-6.134 0-7.789 1.071-1.656.975-4.772 5.554-3.214 7.697z"/>
    <path fill="#2B0950" d="M328.736 138.544s2.922 7.307 13.438 7.404c12.561.098 19.962-6.82 26.973-10.717l-4.479.097c-.098.098-.585.488-1.656 1.072-2.239 1.267-6.719 3.02-14.411 3.215-6.914.195-11.977-1.851-14.996-3.605-2.045-1.169-3.116-2.24-3.116-2.24-1.168 2.825-1.753 4.774-1.753 4.774zM361.066 210.057l-1.267.097c.487 0 .974 0 1.267-.097zM347.335 209.96l-51.998-.293c0-.097.097-.194.292-.389.876-1.364 4.09-5.943 10.419-9.451 1.85-1.072 6.135-3.605 11.198-5.066 3.019 3.994 8.569 10.035 15.872 12.666 4.187 1.461 9.446 2.143 14.217 2.533z" opacity=".34"/>
    <path fill="#55375E" d="M362.233 189.985c1.183 0 2.143-1.701 2.143-3.8 0-2.098-.96-3.799-2.143-3.799-1.183 0-2.142 1.701-2.142 3.799 0 2.099.959 3.8 2.142 3.8z"/>
    <path fill="#55375E" d="M364.376 182.386h-2.143v7.502h2.143v-7.502z"/>
    <path fill="url(#paint43_linear)" d="M364.376 189.985c1.183 0 2.142-1.701 2.142-3.8 0-2.098-.959-3.799-2.142-3.799-1.184 0-2.143 1.701-2.143 3.799 0 2.099.959 3.8 2.143 3.8z"/>
    <path fill="url(#paint44_linear)" d="M364.571 187.647c.43 0 .779-.654.779-1.461s-.349-1.461-.779-1.461c-.431 0-.78.654-.78 1.461s.349 1.461.78 1.461z"/>
    <path fill="url(#paint45_linear)" d="M362.233 184.724s-21.812-3.995-39.144-3.995l-.487 1.949s26.194 1.072 39.729 4.287l-.098-2.241z"/>
    <path fill="url(#paint46_linear)" d="M323.089 180.729h-5.55l-.39 1.072 5.453.975.487-2.047z"/>
    <path fill="#2C0047" d="M317.538 180.73h5.551s17.138 0 39.144 3.995l-.973-.975s-.195-.195-.585-.487c-2.045-1.948-5.842-5.261-8.471-6.138-3.798-1.364-25.415-3.994-31.647-2.825-2.142.389-3.116 1.364-3.505 2.338-.682 1.754.292 3.8.486 4.092z"/>
    <path fill="#2C0047" d="M317.538 180.73h5.551s-3.7-2.826-2.629-6.333c0-.098.097-.195.097-.293-5.745 1.17-3.213 6.236-3.019 6.626z"/>
    <path fill="#fff" d="M317.052 176.541c5.939 1.949 27.264-.097 32.912.877 4.674.78 9.153 4.384 10.711 5.748-2.045-1.948-5.842-5.261-8.471-6.138-3.798-1.364-25.415-3.994-31.647-2.825-2.045.39-3.116 1.267-3.505 2.338z" opacity=".3"/>
    <path fill="#63FFFF" d="M328.736 138.544s4.967-12.082 11.685-17.343c6.817-5.358 13.925-5.456 13.925-5.456s-5.843-1.266-13.632 3.41c-7.79 4.579-11.978 19.389-11.978 19.389zM273.526 201.581s4.381-9.743 16.748-13.251c10.127-2.923 19.962 1.851 23.078 2.533 0 0-1.266.098-10.03-2.046-8.763-2.143-22.883.877-29.796 12.764zM286.573 171.377s1.266-3.216 4.577-4.482c3.408-1.267 25.804-.098 29.699 2.436 0 0-1.168-.877-2.921-1.364-1.753-.488-24.441-3.508-28.239-1.462-3.797 2.046-3.116 4.872-3.116 4.872zM316.954 179.075s-.195-2.534 3.019-4.092c3.213-1.559 26.972.974 26.972.974s-24.051-3.215-27.751-1.559c-3.798 1.656-2.24 4.677-2.24 4.677z"/>
    <defs>
        <linearGradient id="paint0_linear" x1="295.37" x2="295.37" y1="120.894" y2="2.07" gradientUnits="userSpaceOnUse">
            <stop stopColor="#458DBA"/>
            <stop offset="1"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="155.527" x2="155.527" y1="186.235" y2="185.287" gradientUnits="userSpaceOnUse">
            <stop stopColor="#005665"/>
            <stop offset=".533" stopColor="#005074"/>
            <stop offset="1" stopColor="#004D7B"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="156.258" x2="156.258" y1="239.063" y2="238.302" gradientUnits="userSpaceOnUse">
            <stop stopColor="#005665"/>
            <stop offset=".533" stopColor="#005074"/>
            <stop offset="1" stopColor="#004D7B"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="114.42" x2="476.764" y1="156.411" y2="156.411" gradientUnits="userSpaceOnUse">
            <stop stopColor="#541D4C"/>
            <stop offset="1" stopColor="#51364B"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="158.808" x2="448.734" y1="127.835" y2="127.835" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2B1652"/>
            <stop offset="1" stopColor="#301C56"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="139.204" x2="453.562" y1="143.87" y2="143.87" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2B1652"/>
            <stop offset="1" stopColor="#301C56"/>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="295.387" x2="295.387" y1="138.731" y2="89.838" gradientUnits="userSpaceOnUse">
            <stop stopColor="#493560"/>
            <stop offset="1" stopColor="#73719D"/>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="295.316" x2="295.316" y1="136.366" y2="91.769" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3BB6CC"/>
            <stop offset="1" stopColor="#6FD"/>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="210.621" x2="366.523" y1="154.347" y2="154.347" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CAC4D1"/>
            <stop offset="1" stopColor="#AFADBD"/>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="439.938" x2="409.605" y1="158.234" y2="111.551" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3B2654"/>
            <stop offset="1" stopColor="#5E77A9"/>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="449.041" x2="449.041" y1="157.319" y2="111.08" gradientUnits="userSpaceOnUse">
            <stop stopColor="#120439"/>
            <stop offset="1" stopColor="#2F316A"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="411.49" x2="435.689" y1="128.627" y2="138.894" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5E527F"/>
            <stop offset="1" stopColor="#130036"/>
        </linearGradient>
        <linearGradient id="paint14_linear" x1="421.807" x2="428.05" y1="133.765" y2="133.765" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5E527F"/>
            <stop offset="1" stopColor="#130036"/>
        </linearGradient>
        <linearGradient id="paint15_linear" x1="150.459" x2="179.909" y1="157.539" y2="112.215" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3B2654"/>
            <stop offset="1" stopColor="#5E77A9"/>
        </linearGradient>
        <linearGradient id="paint16_linear" x1="142.169" x2="142.169" y1="156.955" y2="111.827" gradientUnits="userSpaceOnUse">
            <stop stopColor="#120439"/>
            <stop offset="1" stopColor="#2F316A"/>
        </linearGradient>
        <linearGradient id="paint18_linear" x1="178.273" x2="154.958" y1="129.017" y2="138.909" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5E527F"/>
            <stop offset="1" stopColor="#130036"/>
        </linearGradient>
        <linearGradient id="paint20_linear" x1="168.327" x2="162.331" y1="133.967" y2="133.967" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5E527F"/>
            <stop offset="1" stopColor="#130036"/>
        </linearGradient>
        <linearGradient id="paint21_linear" x1="315.146" x2="329.246" y1="234.747" y2="234.747" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint22_linear" x1="334.037" x2="323.446" y1="216.701" y2="216.701" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint23_linear" x1="347.532" x2="330.145" y1="235.841" y2="235.841" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#ADA4B8"/>
        </linearGradient>
        <linearGradient id="paint24_linear" x1="358.888" x2="330.145" y1="241.543" y2="241.543" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BFB8C8"/>
            <stop offset="1" stopColor="#797691"/>
        </linearGradient>
        <linearGradient id="paint25_linear" x1="368.139" x2="358.887" y1="253.418" y2="254.652" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9D185C"/>
            <stop offset=".371" stopColor="#9B185C"/>
            <stop offset=".556" stopColor="#93185C"/>
            <stop offset=".701" stopColor="#86175C"/>
            <stop offset=".824" stopColor="#73165C"/>
            <stop offset=".933" stopColor="#5B155D"/>
            <stop offset="1" stopColor="#48145D"/>
        </linearGradient>
        <linearGradient id="paint26_linear" x1="291.864" x2="291.864" y1="249.098" y2="239.257" gradientUnits="userSpaceOnUse">
            <stop stopColor="#48145D"/>
            <stop offset=".067" stopColor="#5B155D"/>
            <stop offset=".176" stopColor="#73165C"/>
            <stop offset=".299" stopColor="#86175C"/>
            <stop offset=".444" stopColor="#93185C"/>
            <stop offset=".629" stopColor="#9B185C"/>
            <stop offset="1" stopColor="#9D185C"/>
        </linearGradient>
        <linearGradient id="paint27_linear" x1="291.206" x2="291.206" y1="249.656" y2="239.258" gradientUnits="userSpaceOnUse">
            <stop stopColor="#48145D"/>
            <stop offset=".067" stopColor="#5B155D"/>
            <stop offset=".176" stopColor="#73165C"/>
            <stop offset=".299" stopColor="#86175C"/>
            <stop offset=".444" stopColor="#93185C"/>
            <stop offset=".629" stopColor="#9B185C"/>
            <stop offset="1" stopColor="#9D185C"/>
        </linearGradient>
        <linearGradient id="paint28_linear" x1="325.57" x2="297.859" y1="238.288" y2="238.288" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ADA4B8"/>
            <stop offset="1" stopColor="#635F7F"/>
        </linearGradient>
        <linearGradient id="paint29_linear" x1="297.247" x2="326.428" y1="241.686" y2="241.686" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BFB8C8"/>
            <stop offset="1" stopColor="#797691"/>
        </linearGradient>
        <linearGradient id="paint30_linear" x1="288.434" x2="297.686" y1="253.418" y2="254.652" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9D185C"/>
            <stop offset=".371" stopColor="#9B185C"/>
            <stop offset=".556" stopColor="#93185C"/>
            <stop offset=".701" stopColor="#86175C"/>
            <stop offset=".824" stopColor="#73165C"/>
            <stop offset=".933" stopColor="#5B155D"/>
            <stop offset="1" stopColor="#48145D"/>
        </linearGradient>
        <linearGradient id="paint31_linear" x1="328.469" x2="328.171" y1="179.819" y2="172.831" gradientUnits="userSpaceOnUse">
            <stop stopColor="#652FC4"/>
            <stop offset=".996" stopColor="#9E5C96"/>
        </linearGradient>
        <linearGradient id="paint32_linear" x1="329.45" x2="329.152" y1="179.777" y2="172.789" gradientUnits="userSpaceOnUse">
            <stop stopColor="#652FC4"/>
            <stop offset=".996" stopColor="#9E5C96"/>
        </linearGradient>
        <linearGradient id="paint34_linear" x1="331.194" x2="329.682" y1="176.202" y2="176.267" gradientUnits="userSpaceOnUse">
            <stop stopColor="#480633"/>
            <stop offset="1" stopColor="#6B2147"/>
        </linearGradient>
        <linearGradient id="paint35_linear" x1="328.286" x2="291.534" y1="174.219" y2="175.781" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint36_linear" x1="286.575" x2="328.274" y1="170.428" y2="170.428" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C145D"/>
            <stop offset=".145" stopColor="#431C6F"/>
            <stop offset=".321" stopColor="#48227C"/>
            <stop offset=".548" stopColor="#4B2684"/>
            <stop offset="1" stopColor="#4C2786"/>
        </linearGradient>
        <linearGradient id="paint37_linear" x1="288.991" x2="288.897" y1="174.819" y2="172.624" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint38_linear" x1="272.591" x2="362.812" y1="198.333" y2="198.333" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C145D"/>
            <stop offset=".015" stopColor="#3D165F"/>
            <stop offset=".162" stopColor="#442971"/>
            <stop offset=".334" stopColor="#48367D"/>
            <stop offset=".556" stopColor="#4B3E84"/>
            <stop offset="1" stopColor="#4C4086"/>
        </linearGradient>
        <linearGradient id="paint39_linear" x1="345.301" x2="345.301" y1="202.554" y2="133.226" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C145D"/>
            <stop offset=".145" stopColor="#431C6F"/>
            <stop offset=".321" stopColor="#48227C"/>
            <stop offset=".548" stopColor="#4B2684"/>
            <stop offset="1" stopColor="#4C2786"/>
        </linearGradient>
        <linearGradient id="paint40_linear" x1="328.744" x2="365.277" y1="129.495" y2="129.495" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4C4086"/>
            <stop offset=".444" stopColor="#4B3E84"/>
            <stop offset=".666" stopColor="#48367D"/>
            <stop offset=".838" stopColor="#442971"/>
            <stop offset=".985" stopColor="#3D165F"/>
            <stop offset="1" stopColor="#3C145D"/>
        </linearGradient>
        <linearGradient id="paint41_linear" x1="291.205" x2="356.876" y1="201.555" y2="201.555" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C2771"/>
            <stop offset=".061" stopColor="#3F3282"/>
            <stop offset=".17" stopColor="#44419A"/>
            <stop offset=".294" stopColor="#484DAD"/>
            <stop offset=".44" stopColor="#4A56BA"/>
            <stop offset=".627" stopColor="#4C5BC2"/>
            <stop offset="1" stopColor="#4C5CC4"/>
        </linearGradient>
        <linearGradient id="paint42_linear" x1="337.884" x2="326.45" y1="153.448" y2="153.448" gradientUnits="userSpaceOnUse">
            <stop/>
            <stop offset="1" stopColor="#6966FF"/>
        </linearGradient>
        <linearGradient id="paint43_linear" x1="366.513" x2="362.274" y1="186.168" y2="186.168" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint44_linear" x1="365.379" x2="363.745" y1="186.168" y2="186.168" gradientUnits="userSpaceOnUse">
            <stop stopColor="#480633"/>
            <stop offset="1" stopColor="#6B2147"/>
        </linearGradient>
        <linearGradient id="paint45_linear" x1="362.333" x2="322.63" y1="183.894" y2="183.894" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <linearGradient id="paint46_linear" x1="320.112" x2="320.112" y1="182.737" y2="180.761" gradientUnits="userSpaceOnUse">
            <stop stopColor="#635F7F"/>
            <stop offset="1" stopColor="#7A6B8C"/>
        </linearGradient>
        <radialGradient id="paint11_radial" cx="0" cy="0" r="1" gradientTransform="matrix(15.3862 0 0 15.1504 421.44 147.505)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#425CA7"/>
            <stop offset="1" stopColor="#402C58"/>
        </radialGradient>
        <radialGradient id="paint13_radial" cx="0" cy="0" r="1" gradientTransform="matrix(13.026 0 0 12.8264 421.77 145.399)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#425CA7"/>
            <stop offset="1" stopColor="#402C58"/>
        </radialGradient>
        <radialGradient id="paint17_radial" cx="0" cy="0" r="1" gradientTransform="matrix(-14.7779 0 0 -14.7861 168.679 147.378)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#425CA7"/>
            <stop offset="1" stopColor="#402C58"/>
        </radialGradient>
        <radialGradient id="paint19_radial" cx="0" cy="0" r="1" gradientTransform="matrix(-12.5109 0 0 -12.5179 168.363 145.321)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#425CA7"/>
            <stop offset="1" stopColor="#402C58"/>
        </radialGradient>
        <radialGradient id="paint33_radial" cx="0" cy="0" r="1" gradientTransform="rotate(177.563 163.25 90.345) scale(5.02919 5.03202)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#95A7FF"/>
            <stop offset=".996" stopColor="#8D7C96"/>
        </radialGradient>
    </defs>
  </svg>
)

export default EmptyRecordings
