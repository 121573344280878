import React from 'react'
import {
  Container,
  StartText,
  DescriptionText,
  EmptyIcon
} from './styles'

const EmptyState = ({ text, subtext = '' }) => {
  const subtextArray = Array.isArray(subtext) ? subtext : [subtext]
  return (
    <Container>
      <EmptyIcon />
      <StartText>{ text }</StartText>
      {subtext && subtextArray.map((txt, i, arr) => (
        <DescriptionText key={i} firstIndex={i === 0}>
          {txt}
        </DescriptionText>
      ))}
    </Container>
  )
}
export default EmptyState
