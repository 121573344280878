import React from 'react'
import PrivatePage from '../../components/PrivatePage'
import CommentsUI from './Comments.UI'
import CommentProvider from '../../providers/commentProvider'

const Comments = ({ history, ...props }) => {
  return (
    <PrivatePage>
      <CommentProvider>
        <CommentsUI history={history} {...props} />
      </CommentProvider>
    </PrivatePage>
  )
}

export default Comments
