import React from 'react'
import {
  EmptyChapterContainer,
  EmptyChapterHeader,
  EmptyBody,
  EmptyNovelIcon,
} from '../styles'
import { Text, Box } from '../../../../designSystem'

export default function EmptyChapters(props) {
  return (
    <EmptyChapterContainer>
      <EmptyNovelIcon />
      <br />
      <Box mt="40px" variant="flex" justifyContent="center">
        <Text maxWidth={['350px', '100%']} variant="heading">
          {props.status === 'draft'
            ? "You don't have any saved drafts"
            : props.status === 'published'
            ? "You haven't Published any chapters"
            : 'No Chapters in Trash'}
        </Text>
      </Box>
      <Box m="8px" variant="flex" justifyContent="center">
        <Text maxWidth={['350px', '512px']} >
        {props.status === 'draft'
          ? "Saving a draft allows you to keep chapters that you aren't ready to publish yet."
          : props.status === 'published'
          ? "Saving a draft allows you to keep chapters that you aren't ready to publish yet."
          : `Deleted chapters can be recovered from trash, chapters that have been in Trash
          more than 30 days will be automatically deleted.`}
        </Text>
      </Box>
      
    </EmptyChapterContainer>
  )
}
