import React, { useRef, useState, useEffect } from 'react'
import { TextEditor, getContent, isContentChanged, resetIsContentChanged, isUndoEmpty, isRedoEmpty, handleRedo as onRedo, handleUndo as onUndo} from '../../../../components/TextEditor'
import { get, post, put } from '../../../../lib/api'
import SaveIcon from '../../../../components/Images/Save'
import CircleCheck from '../../../../components/Images/CircleCheck'
import { Revisions } from '../../../../components/TextEditor/revisions'
import queryString from 'query-string'
import Loader from '../../../../components/Loader'
import GenericError from '../../../../components/GenericError'
import Axios from 'axios'
import AlertSnack from '../../../../components/AlertSnackBar'
import PrivatePage from '../../../../components/PrivatePage'
import { Provider } from '../../../../context/editorContext'
import { EditorControls } from '../../Audiobooks/Unpublished/Edit/chapterEditor'
import { menuItemObj } from '../../../../components/PrivatePage'

export default function ChapterAddEdit(props) {
  const {
    history,
    match: {
      params: { id, action },
    },
    location: { search },
  } = props
  const qParams = queryString.parse(search)
  const { proceed_to_details, redirect_to_home } = qParams

  const [chapter_id, setChapter_id] = useState(
    queryString.parse(search).chapter_id
  )
  const [content, setContent] = useState('')
  const [wordCount, setWordCount] = useState(0)
  const [editorState, setEditorState] = useState(null)
  const [title, setTitle] = useState('Untitled Chapter')
  const [showVersion, toggleShowVersion] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [chapterDetails, setChapterDetails] = useState()
  const [apiError, toggleApiError] = useState(false)
  const [titleChanged, setTitleChanged] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [savedChanges, setSavedChanges] = useState(false)

  const fetchChapterDetails = () => {
    setLoading(true)
    get('/content_api/book.get_chapter_details', {
      params: {
        chapter_id,
        book_id: id,
        view: 'dashboard'
      },
    })
      .then((response) => {
        setChapterDetails(response)
        const { result } = response
        const { file_url, chapter_title } = result
        Axios.get(file_url)
          .then((response) => {
            setTitle(chapter_title)
            setContent(response.data)
          })
          .catch((err) => {
            props.history.goBack()
            throw err
          })
      })
      .catch((err) => {
        props.history.goBack()
        throw err
      })
  }

  useEffect(() => {
    if (!chapter_id) return
    fetchChapterDetails()
  }, [])

  useEffect(() => {
    if (title && content) setLoading(false)
  }, [title, content])
  
  const updateState = (editor) => {
    setEditorState(editor)
  }

  const createChapterOrVersion = (status, url) => {
    if (chapter_id && status === chapterDetails.result.status) {
      if (titleChanged) {
        const dataForVersion = {
          book_id: id,
          chapter_id,
          word_count: wordCount,
          file_url: url,
          chapter_title: title,
          status: status,
          view: 'dashboard'
        }
        post('/content_api/book.create_chapter?is_novel=0', {
          data: dataForVersion,
        }).then()
      }
      const dataForVersion = {
        chapter_id,
        word_count: wordCount,
        file_url: url,
        chapter_title: title,
        book_id: id,
        view: 'dashboard'
      }
      return post('/content_api/book.version?is_novel=0', {
        data: dataForVersion,
      })
        .then(() => {
          if (status !== 'draft') {
            props.history.goBack()
          } else {
            setTitleChanged(false)
            resetIsContentChanged(false)
            setSavedChanges(true)
            setIsSubmitting(false)
          }
        })
        .catch((err) => {
          setIsSubmitting(false)
          throw err
        })
    } else if (
      chapter_id &&
      status !== chapterDetails.result.status &&
      status === 'published'
    ) {
      const dataForVersion = {
        book_id: id,
        chapter_id,
        word_count: wordCount,
        file_url: url,
        chapter_title: title,
        status: proceed_to_details ? 'draft' : status,
        view: 'dashboard'
      }
      const dataForChapterVersion = {
        chapter_id,
        word_count: wordCount,
        file_url: url,
        chapter_title: title,
        book_id: id,
        view: 'dashboard'
      }
      const createChapterPromise = post('/content_api/book.create_chapter?is_novel=0', {
        data: dataForVersion,
      })
      const updateVersionPromise = post('/content_api/book.version?is_novel=0', {
        data: dataForChapterVersion,
      })
      Promise.all([createChapterPromise, updateVersionPromise]).then(() => {
        if (proceed_to_details) {
          history.push(`${menuItemObj.novels}/edit-novel-details/${id}?publish_chapter_id=${chapter_id}&redirect_to_home=${redirect_to_home ? '1' : ''}`)
        } else {
          props.history.goBack()
        }
      }).catch((err) => {
        setIsSubmitting(false)
        throw err
      })
    } else {
      const dataForChapter = {
        book_id: id,
        chapter_title: title,
        file_url: url,
        word_count: wordCount,
        status: proceed_to_details ? 'draft' : status,
        view: 'dashboard'
      }

      return post('/content_api/book.create_chapter?is_novel=0', {
        data: dataForChapter,
      })
        .then((res) => {
          if (status !== 'draft') {
            if (proceed_to_details) {
              history.push(`${menuItemObj.novels}/edit-novel-details/${id}?publish_chapter_id=${res.results.chapter_id}&redirect_to_home=${redirect_to_home ? '1' : ''}`)
              return
            } else {
              props.history.goBack()
            }
          } else {
            resetIsContentChanged(false)
            setChapter_id(res.results.chapter_id)
            get('/content_api/book.get_chapter_details', {
              params: {
                chapter_id: res.results.chapter_id,
                book_id: id,
                view: 'dashboard'
              },
            }).then((response) => {
              setChapterDetails(response)
            })
            setSavedChanges(true)
            setIsSubmitting(false)
          }
        })
        .catch((err) => {
          setIsSubmitting(false)
          throw err
        })
    }
  }

  const handleVersionSave = () => {
    if (
      isContentChanged(editorState) &&
      title.length > 0 &&
      wordCount > 0 &&
      !isSubmitting
    ) {
      setIsSubmitting(true)
      uploadFile('draft')
    } else if (chapter_id && titleChanged && title.length > 0) {
      post('/content_api/book.create_chapter?is_novel=0', {
        data: {
          book_id: id,
          chapter_id,
          word_count: wordCount,
          file_url: chapterDetails.result.file_url,
          chapter_title: title,
          status: chapterDetails.result.status,
          content_unchanged: 1,
          view: 'dashboard'
        },
      })
        .then(() => {
          setTitleChanged(false)
          setIsSubmitting(false)
          setSavedChanges(true)
        })
        .catch((err) => {
          setIsSubmitting(false)
          throw err
        })
    } else if (title.length > 0 && wordCount > 0) {
      setSavedChanges(true)
    } else {
      return
    }
  }

  const updateChapterInfo = () => {
    post('/content_api/book.create_chapter?is_novel=0', {
      data: {
        book_id: id,
        chapter_id,
        word_count: wordCount,
        file_url: chapterDetails.result.file_url,
        chapter_title: title,
        status: chapterDetails.result.status,
        content_unchanged: 1,
        view: 'dashboard'
      },
    })
      .then(() => {
        setTitleChanged(false)
        props.history.goBack()
      })
      .catch((err) => {
        setIsSubmitting(false)
        throw err
      })
  }

  const handleVersionPublish = () => {
    if (wordCount > 5000) return
    if (
      isContentChanged(editorState) &&
      wordCount > 1499 &&
      !isSubmitting
    ) {
      setIsSubmitting(true)
      uploadFile('published')
    } else if (
      chapter_id &&
      chapterDetails.result.status === 'draft' &&
      !isContentChanged(editorState) &&
      wordCount > 1499 &&
      !titleChanged
    ) {
      put('/content_api/book.update_chapter_status', {
        data: {
          chapter_id,
          status: proceed_to_details ? 'draft' : 'published',
          book_id: id,
          view: 'dashboard'
        },
      })
        .then(() => {
          if (proceed_to_details) {
            history.push(`${menuItemObj.novels}/edit-novel-details/${id}?publish_chapter_id=${chapter_id}&redirect_to_home=${redirect_to_home ? '1' : ''}`)
            return
          }
          props.history.goBack()
        })
        .catch((err) => {
          setIsSubmitting(false)
          throw err
        })
    } else if (
      chapter_id &&
      chapterDetails.result.status === 'draft' &&
      !isContentChanged(editorState) &&
      wordCount > 1499 &&
      titleChanged
    ) {
      post('/content_api/book.create_chapter?is_novel=0', {
        data: {
          book_id: id,
          chapter_id,
          word_count: wordCount,
          file_url: chapterDetails.result.file_url,
          chapter_title: title,
          status: proceed_to_details ? 'draft' : 'published',
          view: 'dashboard'
        },
      })
        .then(() => {
          setTitleChanged(false)
          if (proceed_to_details) {
            history.push(`${menuItemObj.novels}/edit-novel-details/${id}?publish_chapter_id=${chapter_id}&redirect_to_home=${redirect_to_home ? '1' : ''}`)
            return
          }
          props.history.goBack()
        })
        .catch((err) => {
          setIsSubmitting(false)
          throw err
        })
    }
  }

  const handleVersionUpdate = () => {
    if (wordCount > 5000) return
    if (
      isContentChanged(editorState) &&
      wordCount > 1499 &&
      !isSubmitting
    ) {
      setIsSubmitting(true)
      uploadFile('published')
    } else if (titleChanged && wordCount > 1499 && !isSubmitting) {
      setIsSubmitting(true)
      updateChapterInfo()
    } else {
      return
    }
  }

  const uploadFile = (status) => {
    //create a text file

    const content = getContent(editorState)

    let file = new Blob([content], {
      type: 'text/plain;charset=utf-8',
    })

    // upload to s3
    let params = {
      title,
      tags: 'file',
      file_dir: id,
      image_extension: 'txt',
    }

    get(`/upload/get_presigned_url`, {
      params: params,
    })
      .then((response) => {
        let imageUrl = response.result[0]
        let awsFields = imageUrl.fields
        let prefix = imageUrl.url
        let suffix = imageUrl.s3_unique_key
        const formData = new FormData()
        formData.append('key', awsFields.key)
        formData.append('AWSAccessKeyId', awsFields.AWSAccessKeyId)
        formData.append(
          'x-amz-security-token',
          awsFields['x-amz-security-token']
        )
        formData.append('policy', awsFields.policy)
        formData.append('signature', awsFields.signature)
        formData.append('file', file)
        const cdn = 'https://d31b0xt3oaqqjh.cloudfront.net'
        post(prefix, { data: formData })
          .then((response) => {
            let url = `${cdn}/${id}/${suffix}`
            createChapterOrVersion(status, url)
          })
          .catch((error) => {
            setIsSubmitting(false)
            throw error
          })
      })
      .catch((err) => {
        setIsSubmitting(false)
        throw err
      })
  }

  const updateWordCount = count => {
    setWordCount(count)
  }

  const handleTitleChange = title => {
    setTitle(title)
    setTitleChanged(true)
  }

  const conditionForSaveAndPublish = (action === 'add-chapter' || (action === 'edit-chapter' && chapterDetails && chapterDetails.result.status === 'draft'))

  return (
    <Provider value={() => (
      <EditorControls
        title={title}
        regex={/^Ch\s\d+\s-\s/}
        onBack={() => history.goBack()}
        onTitleChange={handleTitleChange}
        wordCount={wordCount}
        undoActive={!isUndoEmpty(editorState)}
        redoActive={!isRedoEmpty(editorState)}
        handleUndo={() => onUndo(editorState, setEditorState)}
        handleRedo={() => onRedo(editorState, setEditorState)}
        handleShowRevision={() => toggleShowVersion(true)}
        showRevisionsIcon={chapter_id}
        actionButtons={{
          secondary: {
            label: 'Save',
            tooltip: title.length === 0
            ? 'Please type the title first'
            : wordCount === 0
            ? 'Please type the content'
            : '',
            icon: SaveIcon,
            show: conditionForSaveAndPublish,
            enabled: title.length > 0 && wordCount > 0 && !isSubmitting,
            onClick: handleVersionSave
          },
          primary: {
            label: conditionForSaveAndPublish ? 'Publish' : chapterDetails ? 'Update' : 'Publish',
            tooltip: title.length === 0
            ? 'Please type the title first'
            : wordCount < 1500
            ? 'Please type atleast 1500 words to continue'
            : wordCount > 5000
            ? 'Maximum 5000 words allowed'
            : '',
            icon: CircleCheck,
            show: true,
            enabled: title.length > 0 && wordCount > 1499 && wordCount <= 5000 && !isSubmitting,
            onClick: conditionForSaveAndPublish ? handleVersionPublish : handleVersionUpdate
          }
        }}
      />)
    }>
      <PrivatePage isProfileNameShown={false}>
        <>
          {isLoading && !apiError && <Loader />}
          <GenericError show={apiError} onClose={() => toggleApiError(false)} />
          {!isLoading && <TextEditor
            content={content}
            editorState={editorState}
            updateState={updateState}
            updateWordCount={updateWordCount}
          />}
          {!isLoading && showVersion && (
            <Revisions
              open={showVersion}
              close={() => toggleShowVersion(false)}
              chapter_id={chapter_id}
              book_id={id}
              restoreSuccessCB={fetchChapterDetails}
              {...props}
            />
          )}
          {/* <Preview
            open={showPreview}
            close={() => toggleShowPreview(false)}
            title={title}
            content={content}
          /> */}
          {!isLoading && savedChanges && <AlertSnack text="Changes Saved" close={() => setSavedChanges(false)} />}
        </>
      </PrivatePage>
    </Provider>
  )
}
